import * as Yup from "yup";
export const SOMNOLENCY_SCHEMA_YUP = Yup.object().shape({
  evaluator:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  operator: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  project: Yup.string().required("Requiredo"),
  date: Yup.date().required("Requirido"),
  timeSleep: Yup.number()
    .integer("El número debe ser un número entero")
    .required("Requiredo"),
  timeElapsed: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  criterion: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        question: Yup.string(),
        observation: Yup.string(),
      })
    )
    .required("Requiredo")
    .min(20),
});
