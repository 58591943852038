import { Box, IconButton, Modal, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from "@apollo/client";
import { GET_USER_INFO } from "./QueryUser";
import UserUpdate from "./UserUpdate";


const UserInfo = () => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const { data } = useQuery(GET_USER_INFO)

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      p='1.5rem'
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={3}
      height='100%'
      position='relative'
    >
      {
        open ? (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <UserUpdate
              fullname={data?.getUserByToken.fullname}
              lastname={data?.getUserByToken.lastname}
              setOpen={setOpen}
            />
          </Modal>
        ) : <></>
      }
      <IconButton
        onClick={() => setOpen(true)}
        aria-label="edit"
        sx={{
          position: 'absolute',
          top: 2,
          right: 2
        }}
      >
        <EditIcon
          fontSize="large"
          sx={{
            color: theme.palette.secondary[300]
          }}
        />
      </IconButton>
      <Typography
        variant="h5"
        fontWeight='bold'
      >
        Datos del Usuario
      </Typography>

      <Box width='100%' display='flex' flexDirection='column' gap={2}>
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Nombres
          </Typography>

          <Typography
            variant="h5"
            textTransform='uppercase'
          >
            {data?.getUserByToken.fullname}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Apellidos
          </Typography>

          <Typography
            variant="h5"
            textTransform='capitalize'
          >
            {data?.getUserByToken.lastname}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Email
          </Typography>

          <Typography
            variant="h5"
          >
            {data?.getUserByToken.email}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Rol
          </Typography>

          <Typography
            variant="h5"
          >
            {data?.getUserByToken.role}
          </Typography>
        </Box>

      </Box>

    </Box>
  )
}

export default UserInfo