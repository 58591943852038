import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useApolloClient } from "@apollo/client";
import { GET_LANGUAGE_BY_PERSONAL_ID } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
const ViewLanguage = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [languageFicha, setlanguageFicha] = useState(null);
  useEffect(() => {
    const getlanguageFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_LANGUAGE_BY_PERSONAL_ID,
          variables: {
            getLanguageByPersonalIdId: id,
          },
          fetchPolicy: "network-only",
        });      
        setlanguageFicha(data.getLanguageByPersonalId);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getlanguageFicha(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        {" "}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">IDIOMA</TableCell>
                  <TableCell align="">NIVEL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languageFicha.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">{row.language.description}</TableCell>
                      <TableCell align="">{row.level?.description}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewLanguage;
