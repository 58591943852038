import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Avatar,
} from "@mui/material";
import { useApolloClient, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import AvatarUser from "../assets/Avatar.jpg";
import { GET_USER_DATA } from "graphql/UserQueries";
import { useUser } from "hooks/useUser";
import { useEffect } from "react";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();
  const client = useApolloClient();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const { user, loading } = useUser();
  console.log(user);
  const handleLogOut = () => {
    localStorage.clear();
    client.resetStore();
    navigate("/");
  };

  // TEMPORAL
  const userImg = null;

  if (loading) return <h1>Loading</h1>;

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          {/*    <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween> */}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/*   <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton> */}

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt="profile"
                  src={user?.image ? userImg : AvatarUser}
                />
              </IconButton>

              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              sx={{ padding: "20px" }}
            >
              <Typography
                p=".5rem 1rem"
                variant="h5"
                fontWeight="bold"
                sx={{ color: theme.palette.secondary[100] }}
              >
                {user?.personal?.postulant?.name +
                  " " +
                  user?.personal.postulant.surname}
              </Typography>
              <Typography
                px="1rem"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: theme.palette.grey[500] }}
              >
                {user?.personal.postulant.email}
              </Typography>

              <MenuItem sx={{ marginTop: ".5rem" }} onClick={handleLogOut}>
                Log Out
              </MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
