import * as Yup from "yup";
export const CISTERN_SCHEMA_YUP = Yup.object().shape({
  driver: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  capacity: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  date: Yup.date().required("Requirido"),
  cisternplate: Yup.string(),
  tractplate: Yup.string(),
  activity: Yup.string().required("Requiredo"),
  kmBegin: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  kmEnd: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  timeBegin: Yup.string().required("Requirido"),
  timeEnd: Yup.string().required("Requirido"),
  declarations: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        question: Yup.string(),
        observation: Yup.string(),
      })
    )
    .required("Requiredo")
    .min(10),
  components: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        question: Yup.string(),
        observation: Yup.string(),
      })
    )
    .required("Requiredo")
    .min(82),
});
