import * as Yup from "yup";
export const INTERVIEW_SCHEMA_YUP = Yup.object().shape({
  salary: Yup.number()
    .min(0, "El precio no puede ser negativo")
    .max(9999.99, "El precio es demasiado alto"),
  position: Yup.string().required("Requiredo"),
  postulant: Yup.string(),
  formation: Yup.array()
    .of(
      Yup.object().shape({
        score: Yup.number()
        .min(1, "Selecciona una opcion valida")
        .max(3, "Selecciona una opcion valida"),
        formations: Yup.string(),
      })
    )
    .required("Required"),
  other: Yup.array()
    .of(
      Yup.object().shape({
        score: Yup.number()
        .min(1, "Selecciona una opcion valida")
        .max(3, "Selecciona una opcion valida"),
        others: Yup.string(),
      })
    )
    .required("Required"),
});
