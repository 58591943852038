import { useFormik } from "formik"
import { GET_USER_INFO, UPDATE_USER } from "./QueryUser"
import  { useState } from "react";
import { useMutation } from "@apollo/client"
import { USER_SCHEMA_UPDATE_YUP } from "./YupValidation"
import { Box, Button, TextField } from "@mui/material"
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
const UserUpdate = ({ fullname, lastname, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setOpen(false)
    },
    refetchQueries: [
      {
        query: GET_USER_INFO
      }
    ]
  })

  const userFormik = useFormik({
    initialValues: {
      fullname: fullname || '',
      lastname: lastname || '',
    },
    validationSchema: USER_SCHEMA_UPDATE_YUP,
    onSubmit: async(values,{ setSubmitting }) => {
      setSubmitting(true);
      try {
        updateUser({ variables: values })
      } catch (error) {
        console.log(error)
      }finally {
        setSubmitting(false);
      }
    }
  })

  return (
    <Box
      component='form'
      onSubmit={userFormik.handleSubmit}
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <TextField
        id="fullname"
        label="NOMBRES"
        type="fullname"
        name="fullname"
        value={userFormik.values.fullname}
        onChange={userFormik.handleChange}
        onBlur={userFormik.handleBlur}
        error={userFormik.touched.fullname && Boolean(userFormik.errors.fullname)}
        helperText={userFormik.touched.fullname && userFormik.errors.fullname}
        fullWidth
      />
      <TextField
        id="lastname"
        label="APELLIDOS"
        type="lastname"
        name="lastname"
        value={userFormik.values.lastname}
        onChange={userFormik.handleChange}
        onBlur={userFormik.handleBlur}
        error={userFormik.touched.lastname && Boolean(userFormik.errors.lastname)}
        helperText={userFormik.touched.lastname && userFormik.errors.lastname}
        fullWidth
      />
      <Button variant="contained" color="success" type="submit" sx={{ width: '100%' }}  disabled={userFormik.isSubmitting}>
      {userFormik.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
      </Button>
    </Box>
  )
}

export default UserUpdate