import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import StatBoxOne from "components/StatBoxOne";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { DataGrid,   GridToolbarContainer,esES,
  GridToolbarFilterButton,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import CreateSomnolency from "./CreateSomnolency";
import {
  GET_QUESTION_SOMNOLENCY,
  GET_SOMNOLENCY,
  GET_MAIN_DATA,
} from "./QuerySomnolency";
import { GET_ALL_EVALUATOR,GET_ALL_DRIVER } from "./../../operation/operation/QueryOperation";
import ViewSomnolency from "./ViewSomnolency";
import ViewPdf from "components/ViewPdf";
import PDFSomnolency from "./PDFSomnolency";
const Somnolency = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [question, setQuestion] = useState([]);
  const [operator, setOperator] = useState([]);
  const [evaluator, setEvaluator] = useState([]);
  const [newSomnolency, setNewSomnolency] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [id, setId] = useState(null);
  const [somnolency, setSomnolency] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setId(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "project",
      headerName: "PROYECTO",
      flex: 1,
      renderCell: (params) => params.row.project,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.date).toLocaleDateString("es-ES"),
    },
    {
      field: "timeElapsed",
      headerName: "TIEMPO",
      flex: 1,
      renderCell: (params) => params.row.timeElapsed,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          <LocalPrintshopIcon
            onClick={() => handleOpenModalPDF(params.row.id)}
          />
          {/*       <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_OPERATOR = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DRIVER,
            fetchPolicy: "network-only",
        });
        setOperator(data.getAllDriver);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_EVALUATOR= async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_EVALUATOR,
            fetchPolicy: "network-only",
        });
        setEvaluator(data.getAllEvaluator);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_QUESTION_SOMNOLENCY = async () => {
      try {
        const { data } = await client.query({
          query: GET_QUESTION_SOMNOLENCY,
          fetchPolicy: "network-only",
        });
        setQuestion(data.getQuestionSomnolency);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_SOMNOLENCY = async () => {
      try {
        const { data } = await client.query({
          query: GET_SOMNOLENCY,
          fetchPolicy: "network-only",
        });
        setSomnolency(data.getSomnolency);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        console.log(data);
        setMainData(data.getSumarizeSomnolency);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_SOMNOLENCY();
    FUNCTION_QUESTION_SOMNOLENCY();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_OPERATOR();
    FUNCTION_GET_ALL_EVALUATOR();
  }, [newSomnolency]);
  return (
    <>
      {" "}
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewSomnolency}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: PDFSomnolency,
            title: "Entrevista",
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header
            title="SEGURIDAD"
            subtitle="seguridad/somnolencia"
          />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Total"
            value={mainData?.amount}
            description={"Cantidad de registros de Somnolencia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Proyecto / Operación"
            value={mainData?.lastsubject}
            description={"Ultima Somnolencia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Cantidad Respuestas Verdaderas"
            value={mainData?.amounttrue}
            /*   increase={mainData?.cargo} */
            description={"Ultima Somnolencia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Cantidad Respuestas Falsas"
            value={mainData?.amountfalse}
            /*   increase={mainData?.cargo} */
            description={"Ultima Somnolencia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 1 */}

          {/* ROW 2 */}

          <Box
            gridColumn="span 12"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <CreateSomnolency
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
              question={question}
              operator={operator}
              evaluator={evaluator}
              setNewSomnolency={setNewSomnolency}
            />
          </Box>

          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={!somnolency}
              getRowId={(row) => row.id}
              rows={somnolency || []}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}  
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Somnolency;
