import { gql } from "@apollo/client";
export const CREATE_BUY_ORDER = gql`
  mutation CreateBuyOrder(
    $igv: Int
    $subject: String
    $priority: ID
    $area: String
    $personal: ID
    $requeriment: ID
    $buyorderItem: [BuyOrdertItem]
  ) {
    createBuyOrder(
      igv: $igv
      subject: $subject
      priority: $priority
      area: $area
      personal: $personal
      requeriment: $requeriment
      buyorderItem: $buyorderItem
    ) {
      id
      igv
      priority {
        description
      }
      state {
        description
      }
    }
  }
`;

export const GET_ALL_BUY_ORDER = gql`
  query GetBuyOrder {
    getBuyOrder {
      id
      igv
      code
      priority {
        description
      }
      state {
        description
      }
      createdAt
    }
  }
`;

export const UPDATE_BUY_ORDER_TO_INIT = gql`
  mutation UpdateBuyOrderStateToInit(
    $updateBuyOrderStateToInitId: ID
    $emitiondate: Date
    $attentiondate: Date
    $currenc: ID
    $condition: ID
    $subtotal: Float
    $total: Float
    $provider: ID
    $formpay: String
    $conditionpay: String
    $items: [BuyOrderItem]
  ) {
    updateBuyOrderStateToInit(
      id: $updateBuyOrderStateToInitId
      emitiondate: $emitiondate
      attentiondate: $attentiondate
      currenc: $currenc
      condition: $condition
      subtotal: $subtotal
      total: $total
      provider: $provider
      formpay: $formpay
      conditionpay: $conditionpay
      items: $items
    ) {
      id
      igv
      subtotal
      total
      createdAt
      attentiondate
    }
  }
`;
export const GET_MAIN_DATA = gql`
  query GetSumarizeBuyOrder {
    getSumarizeBuyOrder {
      aproved
      begining
      pending
    }
  }
`;

export const GET_BUY_ORDER_VIEW_BY_ID = gql`
query GetBuyOrderItemById($getBuyOrderItemByIdId: ID) {
  getBuyOrderItemById(id: $getBuyOrderItemByIdId) {
    total
    subtotal
    subject
    priority {
      description
    }
    personal {
      postulant {
        name
        surname
        doi
      }
    }
    requeriment {
      code
      subject
    }
    observation
    item {
      unitvalue
      discount
      current
      cost
      condition {
        description
      }
      amount
      complete
      item {
        active
        description
        unity{
          description
        }
      }
    }
    igv
    formpay
    currency {
      description
    }
    conditionpay
    attentiondate
    provider {
      businessname
    }
    area {
      description
    }
  }
}
`;

export const GET_ITEM_BY_REQUERIMENT_ID = gql`
  query GetRequerimentAndItemForBuyOrderById(
    $getRequerimentAndItemForBuyOrderByIdId: ID
  ) {
    getRequerimentAndItemForBuyOrderById(
      id: $getRequerimentAndItemForBuyOrderByIdId
    ) {
      id
      amount
      description
      correlative
      coloquial
      amountfree
      specification
      unity {
        description
      }
      active
      group {
        description
      }
      classs {
        description
      }
    }
  }
`;
