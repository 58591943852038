import { gql } from "@apollo/client";

export const GET_TRAINING_TYPE = gql`
  query GetTrainingType {
    getTrainingType {
      id
      description
    }
  }
`;
export const GET_TRAINING = gql`
  query GetTraining {
    getTraining {
      id
      subject
      place
      tools
      company
      aggrement
    }
  }
`;
export const CREATE_TRAINING = gql`
  mutation CreateTraining(
    $personal: ID
    $subject: String
    $type: ID
    $description: String
    $date: Date
    $beginTime: Date
    $endTime: Date
    $goal: String
    $company: String
    $tools: String
    $place: String
    $area: ID
    $observation: String
    $aggrement: String
    $participant: [ID]
  ) {
    createTraining(
      personal: $personal
      subject: $subject
      type: $type
      description: $description
      date: $date
      beginTime: $beginTime
      endTime: $endTime
      goal: $goal
      company: $company
      tools: $tools
      place: $place
      area: $area
      observation: $observation
      aggrement: $aggrement
      participant: $participant
    ) {
      id
      goal
      date
    }
  }
`;
export const GET_MAIN_DATA = gql`
  query GetSumarizeTraining {
    getSumarizeTraining {
      amount
      amountPersonal
      lastdate
      lastsubject
    }
  }
`;

export const GET_TRAINING_BY_ID = gql`
 query GetTrainingById($getTrainingByIdId: ID) {
  getTrainingById(id: $getTrainingByIdId) {
   id
      goal
      endTime
      description
      date
      company
      beginTime
      area {
        description
      }
      amountTime
      aggrement
      observation
      place
      subject
      tools
      type {
        description
      }
      asistance {
        postulant {
          name
          surname
          doi
        }
      }
    personal {
      postulant {
        name
        surname
        doi
      }
    }
  }
}
`;
