import { gql } from "@apollo/client";

export const GET_WORK_ORDER_ALL = gql`
  query GetWorkOrder {
    getWorkOrder {
      id
      date
      requeriment {
        active {
          description
        }
        description
        date
        priority {
          description
        }
        type {
          description
        }
      }
      state {
        description
      }
      timeBegin
      timeEnd
    }
  }
`;

export const GET_WORK_ORDER_BY_ID = gql`
  query GetWorkOrderById($getWorkOrderByIdId: ID) {
    getWorkOrderById(id: $getWorkOrderByIdId) {
      date
      id
      requeriment {
        comment
        date
        priority {
          description
        }
        type {
          description
        }
      }
      state {
        id
        description
      }
      timeBegin
      timeEnd
      item {
        active {
          id
          description
        }
      }
    }
  }
`;
export const GET_STATE_BY_NAME = gql`
  query GetStateByName($name: String, $current: String) {
    getStateByName(name: $name, current: $current) {
      id
      description
    }
  }
`;

export const UPDATE_WORK_ORDER_ = gql`
  mutation UpdateWorkOrderState(
    $updateWorkOrderStateId: ID
    $state: ID
    $active: [String]
    $date: Date
    $timeBegin: String
    $timeEnd: String
  ) {
    updateWorkOrderState(
      id: $updateWorkOrderStateId
      state: $state
      active: $active
      date: $date
      timeBegin: $timeBegin
      timeEnd: $timeEnd
    ) {
      date
      id
      timeBegin
      timeEnd
    }
  }
`;
export const GET_MAIN_DATA = gql`
  query GetSumarizeWorkOrderMaintance {
    getSumarizeWorkOrderMaintance {
      closed
      planned
      programed
    }
  }
`;
