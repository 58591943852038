
import { AssignmentIndOutlined, DescriptionOutlined, Groups2Outlined, HowToVoteOutlined, LocalGroceryStore, PeopleAltOutlined, PersonAddAlt1Outlined, PlaylistAddCheckCircleOutlined, RecordVoiceOverOutlined, ShoppingCartOutlined, StoreMallDirectoryOutlined, WorkHistoryOutlined } from "@mui/icons-material"

export const ALL_LIST = [
  "ADMIN_GENERAL",
  "ADMIN_GESTION_DE_PERSONAS",
  "ADMIN_LOGISTICA",
  "ADMIN_OPERACIONES",
  "ADMIN_MANTENIMIENTO",
  "ADMIN_SEGURIDAD",
  "ADMIN_REQUERIMIENTO",
  "USUARIO_GENERICO",
  "NONE"
]

export const items = [
  {
    title: "gestión de personas",
    roles: ["ADMIN_GENERAL", "ADMIN_GESTION_DE_PERSONAS", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 0,
        title: "requerimiento personal",
        icon: <AssignmentIndOutlined />,
        url: "gp/requeriment",
      },
      {
        id: 1,
        title: "postulante",
        icon: <PersonAddAlt1Outlined />,
        url: "gp/postulant",
      },
      {
        id: 2,
        title: "entrevista",
        icon: <RecordVoiceOverOutlined />,
        url: "gp/interview",
      },
      {
        id: 3,
        title: "referencia laboral",
        icon: <WorkHistoryOutlined />,
        url: "gp/jobreference",
      },
      {
        id: 4,
        title: "personal",
        icon: <Groups2Outlined />,
        url: "gp/personal",
      },
      {
        id: 5,
        title: "ficha personal",
        icon: <AssignmentIndOutlined />,
        url: "gp/fichapersonal",
      },
      {
        id: 6,
        title: "estado de documentos",
        icon: <DescriptionOutlined />,
        url: "gp/statedocument",
      },
      {
        id: 7,
        title: "documentos",
        icon: <DescriptionOutlined />,
        url: "gp/document",
      },
      {
        id: 8,
        title: "requerimiento de item",
        icon: <PlaylistAddCheckCircleOutlined />,
        url: "gp/requerimentitem",      
      },
    ]
  },
  {
    title: "logística",
    roles: ["ADMIN_GENERAL", "ADMIN_LOGISTICA", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 9,
        title: "requerimiento de item",
        icon: <PlaylistAddCheckCircleOutlined />,
        url: "l/requerimentitem",    
      },
      {
        id: 10,
        title: "grupos y clases",
        icon: <PeopleAltOutlined />,
        url: "l/groupclass",
      },
      {
        id: 11,
        title: "item",
        icon: <LocalGroceryStore />,
        url: "l/item",
      },
      {
        id: 12,
        title: "orden de compra",
        icon: <ShoppingCartOutlined />,
        url: "l/buyorder",
      },
      {
        id: 13,
        title: "proveedor",
        icon: <StoreMallDirectoryOutlined />,
        url: "l/provider",
      },
      {
        id: 14,
        title: "selección y evaluación de proveedor",
        icon: <HowToVoteOutlined />,
        url: "l/providerselectionevaluation",
      },        
      {
        id: 15,
        title: "requerimiento personal",
        icon: <AssignmentIndOutlined />,
        url: "l/requeriment",
       // allowedRoles: L_ROLES,
      },
    ]
  },
  {
    title: "operación",
    roles: ["ADMIN_GENERAL", "ADMIN_OPERACIONES", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 16,
        title: "reporte",
        icon: <ShoppingCartOutlined />,
        url: "ope/report",
      },
      {
        id: 17,
        title: "operación",
        icon: <ShoppingCartOutlined />,
        url: "ope/operation",
      },
      {
        id: 18,
        title: "flota",
        icon: <ShoppingCartOutlined />,
        url: "ope/flota",
      },
      {
        id: 19,
        title: "requerimiento mantenimiento",
        icon: <ShoppingCartOutlined />,
        url: "ope/requerimentmaintance",       
      },
      {
        id: 20,
        title: "conductor",
        icon: <ShoppingCartOutlined />,
        url: "ope/driver",
      },
      {
        id: 21,
        title: "estado de documentos",
        icon: <DescriptionOutlined />,
        url: "ope/statedocument",
      },
      {
        id: 22,
        title: "documento",
        icon: <ShoppingCartOutlined />,
        url: "ope/document",
      },
      {
        id: 23,
        title: "requerimiento personal",
        icon: <AssignmentIndOutlined />,
        url: "ope/requeriment",       
      },
      {
        id: 24,
        title: "requerimiento de item",
        icon: <PlaylistAddCheckCircleOutlined />,
        url: "ope/requerimentitem",      
      },
    ]
  },
  {
    title: "mantenimiento",
    roles: ["ADMIN_GENERAL", "ADMIN_MANTENIMIENTO", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 25,
        title: "requerimiento mantenimiento",
        icon: <ShoppingCartOutlined />,
        url: "man/requerimentmaintance",     

      },
      {
        id: 26,
        title: "orden de trabajo",
        icon: <ShoppingCartOutlined />,
        url: "man/joborder",
      },
      {
        id: 27,
        title: "garantias y pm",
        icon: <ShoppingCartOutlined />,
        url: "man/guarantee",
      },
      {
        id: 28,
        title: "indicadores",
        icon: <ShoppingCartOutlined />,
        url: "man/indicators",
      },
      {
        id: 29,
        title: "requerimiento personal",
        icon: <AssignmentIndOutlined />,
        url: "man/requeriment",        
      },
      {
        id: 30,
        title: "requerimiento de item",
        icon: <PlaylistAddCheckCircleOutlined />,
        url: "man/requerimentitem",        
      },
    ]
  },
  {
    title: "seguridad",
    roles: ["ADMIN_GENERAL", "ADMIN_SEGURIDAD", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 31,
        title: "capacitación",
        icon: <ShoppingCartOutlined />,
        url: "seg/training",
      },
      {
        id: 32,
        title: "somnolencia",
        icon: <ShoppingCartOutlined />,
        url: "seg/somnolency",
      },
      {
        id: 33,
        title: "checklist camioneta",
        icon: <ShoppingCartOutlined />,
        url: "seg/van",
      },
      {
        id: 34,
        title: "checklist cisterna",
        icon: <ShoppingCartOutlined />,
        url: "seg/cistern",
      },
      {
        id: 35,
        title: "sintomas e incidentes",
        icon: <ShoppingCartOutlined />,
        url: "seg/sintomas",
      
      },
      {
        id: 36,
        title: "control de pernocte",
        icon: <ShoppingCartOutlined />,
        url: "seg/pernoct",
      
      },
      {
        id: 37,
        title: "requerimiento personal",
        icon: <AssignmentIndOutlined />,
        url: "seg/requeriment",
       
      },
      {
        id: 38,
        title: "requerimiento de item",
        icon: <PlaylistAddCheckCircleOutlined />,
        url: "seg/requerimentitem",
       
      },
    ]
  },
  {
    title: "administración",
    roles: ["ADMIN_GENERAL", "ADMIN_REQUERIMIENTO", "USUARIO_GENERICO"],
    subItems: [
      {
        id: 39,
        title: "personal",
        icon: <ShoppingCartOutlined />,
        url: "adm/personal",
      },
      {
        id: 40,
        title: "logística",
        icon: <ShoppingCartOutlined />,
        url: "adm/logistic",
      },
      {
        id: 41,
        title: "mantenimiento",
        icon: <ShoppingCartOutlined />,
        url: "adm/maintance",
      },
    ]
  },
  {
    title: "configuración",
    roles: ["ADMIN_GENERAL", "USUARIO_GENERICO"],
    subItems: [
   /*    {
        id: 40,
        title: "perfil de usuario",
        icon: <ShoppingCartOutlined />,
        url: "config/profile",
      }, */
      {
        id: 42,
        title: "usuarios",
        icon: <ShoppingCartOutlined />,
        url: "config/user",
      },
      {
        id: 43,
        title: "empresa",
        icon: <ShoppingCartOutlined />,
        url: "config/company",
      },
      {
        id: 44,
        title: "general",
        icon: <ShoppingCartOutlined />,
        url: "config/general",
      },
    ]
  }
]