import React,{ useState } from "react";
import { STATE_SCHEMA_YUP } from "./YupValidation";
import { Box, TextField, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoopIcon from '@mui/icons-material/Loop';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CREATE_STATE } from "./QueryFlota";
const CreateState = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleCloseModalState,
    setNewState,
    typeState,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(true);
  const formikState = useFormik({
    initialValues: {
      state: "",
      subject: "",
    },
    validationSchema: STATE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { state, subject } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_STATE,
          variables: {
            active: id,
            type: state,
            description: subject,
          },
        });
        setNewState(response.data.createStateActive);
        handleCloseModalState();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return visibility ? (
    <form onSubmit={formikState.handleSubmit}>
   
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="20px">
        {" "}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ESTADO</InputLabel>
          <Select
            id="state"
            value={formikState.values.state}
            name="state"
            onChange={formikState.handleChange}
            sx={{
              border:"1px solid rgba(0, 0, 0, 0.23)",            
            }}   
            error={
              formikState.touched.state && Boolean(formikState.errors.state)
            }
          >
            {typeState?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikState.touched.state && formikState.errors.state ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikState.touched.state && formikState.errors.state}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="subject"
          name="subject"
          label="MOTIVO"
          variant="outlined"
          value={formikState.values.subject}
          onChange={formikState.handleChange}
          error={
            formikState.touched.subject && Boolean(formikState.errors.subject)
          }
          helperText={formikState.touched.subject && formikState.errors.subject}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikState.isSubmitting}
        >
          {formikState.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LoopIcon />
    </Box>
  );
};

export default CreateState;
