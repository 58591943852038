import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import StatBox from "components/StatBox";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import {
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  SettingsOutlined,
} from "@mui/icons-material";

import { useApolloClient } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import ModalUtil from "components/ModalUtil";

import DialogUtil from "components/DialogUtil";
import CreateItem from "./CreateItem";
import { GET_GROUPS } from "./../groupclass/QueryGroup";
import AddActive from "./AddActive";
import ViewActiveVehicle from "./ViewActiveVehicle";
import ViewActiveNoVehicle from "./ViewActiveNoVehicle";
import {
  GET_ALL_ITEMS_VEHICLE,
  GET_ALL_ITEMS_NO_VEHICLE,
  GET_UNITY,
  GET_CONDITION,
  GET_ALL_ACTIVE_VEHICLE,
  GET_SYSTEMTYPE,
  GET_ALL_ACTIVE_NO_VEHICLE,
  GET_MAIN_DATA,
  GET_BUY_ORDER_BEGIN,
} from "./QueryItem";
import CreateAssembly from "./CreateAssembly";
import EditActive from "./EditActive";
import EditComponent from "./EditComponent";
import { useUser } from "hooks/useUser";
const Item = () => {
  const client = useApolloClient();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [groups, setgroups] = useState([]);
  const [itemsVehicle, setAllItemsVehicle] = useState([]);
  const [itemsNoVehicle, setAllItemsNoVehicle] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [id, setId] = useState(false);
  const [newActive, setNewActive] = useState([]);
  const [allUnity, setAllUnity] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [systemType, setSystemType] = useState([]);
  const [activeVehicle, setActiveVehicle] = useState([]);
  const [activeNoVehicle, setActiveNoVehicle] = useState([]);
  const [buyOrder, setbuyOrder] = useState([]);
  const [newAssembly, setNewAssembly] = useState([]);
  const { user } = useUser();
  const [modalEditVehicle, setmodalEditVehicle] = useState(null);
  const [modalEditNoVehicle, setmodalEditNoVehicle] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const theme = useTheme();
  const handleOpenModalAdd = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const handleCloseModalAdd = () => {
    setModalOpen(false);
  };
  const handleOpenModalView = (id) => {
    setModalView(1);
    setId(id);
  };
  const handleOpenModalViewComponent = (id) => {
    setModalView(2);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalView(false);
  };

  const handleOpenEditVehicle = (id) => {
    setmodalEditVehicle(true);
    setId(id);
  };

  const handleCloseEditVehicle = () => {
    setmodalEditVehicle(false);
  };
  const handleOpenEditNoVehicle = (id) => {
    setmodalEditNoVehicle(true);
    setId(id);
  };

  const handleCloseEditNoVehicle = () => {
    setmodalEditNoVehicle(false);
  };
  const columnsVehicle = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "NOMBRE",
      flex: 1,
    },
    /* {
      field: "group",
      headerName: "GRUPO",
      flex: 1,
      renderCell: (params) => params.row.group.description,
    }, */
    {
      field: "classs",
      headerName: "CLASE",
      flex: 1,
      renderCell: (params) => params.row.classs.description,
    },
    {
      field: "unity",
      headerName: "UNIDAD",
      flex: 1,
      renderCell: (params) => params.row.unity.description,
    },
    {
      field: "amountfree",
      headerName: "CANTIDAD DISPONIBLE",
      flex: 1,   
    },
    {
      field: "amount",
      headerName: "CANTIDAD TOTAL",
      flex: 1,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
       {/*    {user.admin ? (
            <>
              {" "} */}
              <AddCircleIcon
                onClick={() => handleOpenModalAdd(params.row.id)}
              />
             {/*  <CreateIcon
                onClick={() => handleOpenEditVehicle(params.row.id)}
              /> */}
         {/*    </>
          ) : null} */}
        </Box>
      ),
    },
  ];
  const columnsNoVehicle = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "NOMBRE",
      flex: 1,
    },
    /*  {
      field: "group",
      headerName: "GRUPO",
      flex: 1,
      renderCell: (params) => params.row.group.description,
    }, */
    {
      field: "classs",
      headerName: "CLASE",
      flex: 1,
      renderCell: (params) => params.row.classs.description,
    },
    {
      field: "unity",
      headerName: "UNIDAD",
      flex: 1,
      renderCell: (params) => params.row.unity.description,
    },
    {
      field: "amountfree",
      headerName: "CANTIDAD DISPONIBLE",
      flex: 1,   
    },
    {
      field: "amount",
      headerName: "CANTIDAD TOTAL",
      flex: 1,
    },
    {
      field: "ope",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewComponent(params.row.id)}
          />
        {/*   {user.admin ? (
            <> */}
              <AddCircleIcon
                onClick={() => handleOpenModalAdd(params.row.id)}
              />

            {/*   <CreateIcon
                onClick={() => handleOpenEditNoVehicle(params.row.id)}
              /> */}
         {/*    </>
          ) : (
            null
          )} */}
          {/*<DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_GROUP = async () => {
      try {
        const { data } = await client.query({
          query: GET_GROUPS,
          fetchPolicy: "network-only",
        });
        setgroups(data.getGroup);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_ITEMS_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEMS_VEHICLE,
          fetchPolicy: "network-only",
        });
        setAllItemsVehicle(data.getAllActiveNoConsumable);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_ITEMS_NO_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEMS_NO_VEHICLE,
          fetchPolicy: "network-only",
        });
        setAllItemsNoVehicle(data.getAllActiveConsumable);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_UNITY = async () => {
      try {
        const { data } = await client.query({
          query: GET_UNITY,
          fetchPolicy: "network-only",
        });
        setAllUnity(data.getUnity);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_CONDITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_CONDITION,
          fetchPolicy: "network-only",
        });
        setAllCondition(data.getCondition);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ACTIVE_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        setActiveVehicle(data.getAllActiveVehicle);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ACTIVE_NO_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_NO_VEHICLE,
          fetchPolicy: "network-only",
        });
        setActiveNoVehicle(data.getAllActiveNoVehicle);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_SYSTEM_TYPE = async () => {
      try {
        const { data } = await client.query({
          query: GET_SYSTEMTYPE,
          fetchPolicy: "network-only",
        });

        console.log(data);
        setSystemType(data.getTypeSystem);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_BUY_ORDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_BUY_ORDER_BEGIN,
          fetchPolicy: "network-only",
        });
        setbuyOrder(data.getBuyOrderStateBegin);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumanrizeItemActive);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_BUY_ORDER();
    FUNCTION_GET_GROUP();
    FUNCTION_GET_ALL_ITEMS_VEHICLE();
    FUNCTION_GET_ALL_ITEMS_NO_VEHICLE();
    FUNCTION_GET_CONDITION();
    FUNCTION_GET_UNITY();
    FUNCTION_GET_ACTIVE_VEHICLE();
    FUNCTION_GET_ACTIVE_NO_VEHICLE();
    FUNCTION_GET_SYSTEM_TYPE();
    FUNCTION_MAIN_DATA();
  }, [newItem, newActive, newAssembly]);
  return (
    <>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModalAdd}
        Component={AddActive}
        sizewidth={900}
        params={
          new Object({
            condition: allCondition,
            unity: allUnity,
            buyorder: buyOrder,
            setAlertCustom,
            alertCustom,
            setNewActive,
            handleCloseModalAdd,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalView}
        onClose={handleCloseModalView}
        sizewidth={900}
        Component={modalView == 1 ? ViewActiveVehicle : ViewActiveNoVehicle}
        params={
          new Object({
            setAlertCustom,
            alertCustom,
            handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditVehicle}
        onClose={handleCloseEditVehicle}
        Component={EditActive}
        params={
          new Object({
            setAlertCustom,
            alertCustom,
            handleClose: handleCloseEditVehicle,
            condition: allCondition,
            setNewRegister: setNewActive,
            unity: allUnity,
          })
        }
        id={id}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditNoVehicle}
        onClose={handleCloseEditNoVehicle}
        Component={EditComponent}
        params={
          new Object({
            setAlertCustom,
            alertCustom,
            handleClose: handleCloseEditNoVehicle,
            condition: allCondition,
            setNewRegister: setNewActive,
            unity: allUnity,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="LOGISTICA" subtitle="logistica/item" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Items"
            value={mainData?.item}
            /*   increase="+14%" */
            /*   description="Items creados" */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Activos"
            value={mainData?.active}
            /*  increase="+21%" */
            /*     description="Activos dentro de la empresa" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreateItem
              setNewItem={setNewItem}
              groups={groups}
              unity={allUnity}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
            />
          </Box>

          <StatBox
            title="Total de vehiculos"
            value={mainData?.vehicle}
            /*  increase="+43%" */
            /*   description={"Vehiculos dentro de la empresa"} */
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total de componentes"
            value={mainData?.novehicle}
            /*   increase="+5%" */
            /*    description={"Todos los activos que no sean vehiculos"} */
            icon={
              <PersonAdd
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 2 */}
          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE ITEMS
            </Box>
            <DataGrid
              loading={!itemsVehicle}
              getRowId={(row) => row.id}
              rows={itemsVehicle || []}
              columns={columnsVehicle}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE COMPONENTES
            </Box>
            <DataGrid
              loading={!itemsNoVehicle}
              getRowId={(row) => row.id}
              rows={itemsNoVehicle || []}
              columns={columnsNoVehicle}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreateAssembly
              activeVehicle={activeVehicle}
              activeNoVehicle={activeNoVehicle}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              systemType={systemType}
              setNewAssembly={setNewAssembly}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Item;
