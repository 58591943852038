import { gql } from "@apollo/client";

export const GET_LISTDOCUMENT_BY_ACTIVE_ID = gql`
  query GetDocumentListByActive($getDocumentListByActiveId: ID) {
    getDocumentListByActive(id: $getDocumentListByActiveId) {
      id
      kind
      type
      weight
      description
    }
  }
`;

export const GET_DOCUMENT_BY_ACTIVE_ID = gql`
  query GetDocumentActiveByActiveId($getDocumentActiveByActiveIdId: ID) {
    getDocumentActiveByActiveId(id: $getDocumentActiveByActiveIdId) {
      document {
        kind
        type
        weight
        description
      }
      id
      caducitydate
      emitiondate
      name
      url
      state {
        description
      }
    }
  }
`;

export const CREATE_DOCUMENT_ACTIVE = gql`
  mutation CreateActiveDocument($filex: Upload, $document: ID, $active: ID) {
    createActiveDocument(filex: $filex, document: $document, active: $active) {
      id
      name
      url
    }
  }
`;

export const GET_DOCUMENT_ACTIVE_BY_ID = gql`
  query GetDocumentActiveById($getDocumentActiveByIdId: ID) {
    getDocumentActiveById(id: $getDocumentActiveByIdId) {
      caducitydate
      document {
        description
        type
        kind
      }
      emitiondate
      finaldate
      id
      name
      state {
        description
      }
      url
    }
  }
`;

export const UPDATE_DOCUMENT_CADUCITY_EMITION_BY_ACTIVE_ID = gql`
  mutation UpdateCaducityEmitionDateActive(
    $updateCaducityEmitionDateActiveId: ID
    $emitiondate: Date
    $caducitydate: Date
    $finaldate: Date
  ) {
    updateCaducityEmitionDateActive(
      id: $updateCaducityEmitionDateActiveId
      emitiondate: $emitiondate
      caducitydate: $caducitydate
      finaldate: $finaldate
    ) {
      id
      emitiondate
      caducitydate
      name
      state {
        description
      }
      url
    }
  }
`;

export const DELETE_VEHICLE_DOCUMENT = gql`
  mutation DeleteDocumentVechicle($deleteDocumentVechicleId: ID) {
    deleteDocumentVechicle(id: $deleteDocumentVechicleId) {
      id
      url
      name
    }
  }
`;
