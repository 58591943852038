import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { GET_MOVILITY_BY_PERSONAL_ID } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
const ViewMovility = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [movilityFicha, setmovilityFicha] = useState(null);
  useEffect(() => {
    const getmovilityFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_MOVILITY_BY_PERSONAL_ID,
          variables: {
            getMovilityByPersonalIdId: id,
          },
          fetchPolicy: "network-only",
        });    
        setmovilityFicha(data.getMovilityByPersonalId);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getmovilityFicha(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={movilityFicha?.type?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROPIO</label>
            <TextField disabled value={movilityFicha?.own ? "SI" : "NO"} />
          </Box>
          <Box sx={style}>
            <label for="my-input">LICENCIA</label>
            <TextField disabled value={movilityFicha?.licence} />
          </Box>
          <Box sx={style}>
            <label for="my-input">MARCA</label>
            <TextField disabled value={movilityFicha?.brand} />
          </Box>

          <Box sx={style}>
            <label for="my-input">AÑO</label>
            <TextField disabled value={movilityFicha?.year} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PLACA</label>
            <TextField disabled value={movilityFicha?.plate} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewMovility;
