import { gql } from "@apollo/client";

export const GET_OPERATOR_PERSONAL = gql`
  query GetOperatorPosition {
    getOperatorPosition {
      id
      postulant {
        doi
        name
        surname
        id
      }
    }
  }
`;

export const GET_TYPE_CONTROL = gql`
  query GetTypeControl {
    getTypeControl {
      description
      id
    }
  }
`;
export const GET_QUESTION_SINTOMATOLOGY = gql`
  query GetQuestionSintomatology {
    getQuestionSintomatology {
      id
      description
    }
  }
`;

export const CREATE_SINTOMATOLOGY = gql`
mutation CreateSintomatology($operator: ID, $criterion: [SintomatologyQuestion]) {
  createSintomatology(operator: $operator, criterion: $criterion) {
    id
    operator
  }
}
`;

export const CREATE_INCIDENT = gql`
  mutation CreateIncident(
    $personal: ID
    $description: String
    $type: ID
    $date: Date
  ) {
    createIncident(
      personal: $personal
      description: $description
      type: $type
      date: $date
    ) {
      id
      description
      date
      type {
        description
      }
    }
  }
`;

export const GET_SINTOMATOLOGY = gql`
  query GetSintomatology {
    getSintomatology {
      id
      operator {
        postulant {
          doi
          name
          surname
          id
        }
      }
    }
  }
`;



export const GET_INCIDENT = gql`
  query GetIncident {
    getIncident {
      id
      description
      date
      personal {
        postulant {
          name
          surname
          doi
        }
      }
      type {
        description
      }
    }
  }
`;

export const GET_INCIDENT_BY_ID = gql`
  query GetIncidentById($getIncidentByIdId: ID) {
    getIncidentById(id: $getIncidentByIdId) {
      date
      description
      id
      type {
        description
      }
      personal {
        postulant {
          doi
          name
          surname
          id
        }
      }
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeIncidentSintomatology {
    getSumarizeIncidentSintomatology {
      amountincident
      amountsintomatology
      personalincident
      personalsintomatology
    }
  }
`;

export const GET_SINTOMATOLOGY_BY_ID = gql`
query GetSintomatologyByID($getSintomatologyByIdId: ID) {
  getSintomatologyByID(id: $getSintomatologyByIdId) {
    sintomatology {
      id
      operator {
        id
        address
        postulant {
          name
          surname
        }
      }
    }
    questions {
      answer
     
      sintomatology
      question {
        id
        description
      }
    }
  }
}`;