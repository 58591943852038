import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Radio from "@mui/material/Radio";
import TableRow from "@mui/material/TableRow";
import {
  GET_LANGUAGE_BY_ID,
  GET_LANGUAGE_BY_PERSONAL_ID,
  EDIT_IDIOMS_BY_ID,
} from "./QueryPersonal";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { LANGUAGE_SCHEMA_YUP } from "./YupValidation";
import LoopIcon from '@mui/icons-material/Loop';
import FormControl from "@mui/material/FormControl";
const EditIdioms = ({ params }) => {
  const {
    handleCloseModal,
    language,
    languageLevel,
    setAlertCustom,
    alertCustom,
    setNewRegister,
    idPersonal
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [languageFicha, setlanguageFicha] = useState(null);
  const [onelanguage, setonelanguage] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null); // Estado para rastrear el radio seleccionado
  const [component, setComponent] = useState(false);
  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;
  const isDate = (numero) => {
    return !isNaN(new Date(numero).getTime());
  };
  const converObj = (obj) => {
    const resultado = {};
    for (const clave in obj) {
      if (typeof obj[clave] === "object" && obj[clave] !== null) {
        if (Array.isArray(obj[clave])) {
          resultado[clave] = obj[clave].map(
            (el) =>
              new Object({
                answer: el.answer,
                sentence: el.sentence.id,
              })
          );
        } else {
          resultado[clave] = obj[clave].id;
        }
      } else if (typeof obj[clave] === "number" && isDate(obj[clave])) {
        resultado[clave] = new Date(obj[clave]);
      } else {
        resultado[clave] = obj[clave];
      }
    }
    return resultado;
  };
  const handleChangeRadio = async (event) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
    const { data } = await client.query({
      query: GET_LANGUAGE_BY_ID,
      variables: {
        getLanguageByIdId: event.target.value,
      },
      fetchPolicy: "network-only",
    });
    console.log(data.getLanguageById);
    setonelanguage(data.getLanguageById);
    setComponent(true);
  };

  useEffect(() => {
    const getlanguageFicha = async (idPersonal) => {
      try {
        const { data } = await client.query({
          query: GET_LANGUAGE_BY_PERSONAL_ID,
          variables: {
            getLanguageByPersonalIdId: idPersonal,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getLanguageByPersonalId);
        setlanguageFicha(data.getLanguageByPersonalId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getlanguageFicha(idPersonal);
  }, []);
  const formik = useFormik({
    initialValues: converObj(onelanguage),
    validationSchema: LANGUAGE_SCHEMA_YUP,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (selectedValue) {
        setSubmitting(true);
        const { id, language, level } = values;
        try {
          const response = await client.mutate({
            mutation: EDIT_IDIOMS_BY_ID,
            variables: {
              editIdiomsByIdId: id,
              personal: idPersonal,
              language,
              level,
            },
          });
          setNewRegister(response.data.editIdiomsById);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          handleCloseModal();
          resetForm();

        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <form onSubmit={formik.handleSubmit}>
        {" "}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">IDIOMA</TableCell>
                  <TableCell align="">NIVEL</TableCell>
                  <TableCell align=""></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languageFicha.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">{row.language.description}</TableCell>
                      <TableCell align="">{row.level?.description}</TableCell>
                      <TableCell align="">
                        <Radio
                          onChange={handleChangeRadio}
                          name="groupIdioms"
                          value={row.id} // Valor único para este radio
                          checked={selectedValue === row.id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {component && (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              {(
                <FormControl fullWidth>
                  <label for="my-input">IDIOMA</label>
                  <Select
                    id="language"
                    value={formik.values.language}
                    name="language"
                    onChange={(e) => {
                      formik.setFieldValue("language", e.target.value);
                    }}
                  >
                    {language?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.language && formik.errors.language ? (
                    <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                    >
                      {formik.touched.language && formik.errors.language}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Box>
            <Box sx={style}>
              {(
                <FormControl fullWidth>
                  <label for="my-input">NIVEL</label>
                  <Select
                    id="level"
                    value={formik.values.level}
                    name="level"
                    onChange={(e) => {
                      formik.setFieldValue("level", e.target.value);
                    }}
                  >
                    {languageLevel?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.level && formik.errors.level ? (
                    <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                    >
                      {formik.touched.level && formik.errors.level}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Box>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"} gap={"15px"}>
          <Button variant="contained" color="success" type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </form>
    ) : <Box sx={{ display: "flex", justifyContent: "center" }}><LoopIcon /></Box>
  );
};

export default EditIdioms;
