import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  GET_ALL_ADMIN_PERSONAL_BY_ID,
  UPDATE_ADMIN_PERSONAL_BY_ID,
} from "./QueryAdminPersonal";
import Alerts from "components/Alerts";
import LoopIcon from '@mui/icons-material/Loop';
import { useApolloClient } from "@apollo/client";
const AssignPersonalRequeriment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();

  const [visibility, setvisibility] = useState(false);
  const [saved, setsaved] = useState(true);
  const [personalRequeriment, setPersonalRequeriment] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  useEffect(() => {
    const getPersonalRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ADMIN_PERSONAL_BY_ID,
          variables: {
            getPersonalRequerimentByIdId: id,
          },
        });   
        setPersonalRequeriment(data.getPersonalRequerimentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPersonalRequeriment(id);
  }, []);
  const handlePersonalRequerimentReject = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_PERSONAL_BY_ID,
        variables: {
          updateRequerimentPersonalId: id,
          type: 0,
          observation:textFieldValue
        },
      });
      setNewAssignRequeriment(response.data.updateRequerimentPersonal);

      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
      
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handlePersonalRequerimentApproved = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_PERSONAL_BY_ID,
        variables: {
          updateRequerimentPersonalId: id,
          type: 1,
          observation:textFieldValue
        },
      });      
      setNewAssignRequeriment(response.data.updateRequerimentPersonal);
      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return ( <>
 
   { visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
          /*  display="grid"
  gridTemplateColumns="repeat(2, 1fr)"
  gap="20px" */
        >
          <Box sx={style}>
            <label for="my-input">CARGO</label>
            <TextField
              disabled
              value={personalRequeriment?.position?.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PLAZO DE CONTRATO</label>
            <TextField disabled value={personalRequeriment?.term?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">MOTIVO</label>
            <TextField
              disabled
              value={personalRequeriment?.subject?.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">VACANTES</label>
            <TextField disabled value={personalRequeriment?.ngoal} />
          </Box>
          <Box sx={style}>
            <label for="my-input">LUGAR DE TRABAJO</label>
            <TextField
              disabled
              value={personalRequeriment?.workplace?.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">DURACION ESTIMADA</label>
            <TextField
              disabled
              value={personalRequeriment?.duration + " Meses"}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA DE INCORPORACIÓN</label>
            <TextField
              disabled
              value={new Date(
                personalRequeriment.incorporationdate
              ).toLocaleDateString("es-ES")}
            />{" "}
            {/* Falta arreglar las fechas */}
          </Box>
          <Box sx={style}>
            <label for="my-input">REMUNERACION</label>
            <TextField
              disabled
              value={"S/. " + personalRequeriment?.remuneration}
            />
          </Box>
        </Box>
        <Box my={"20px"}>
        <TextField
        label="OBSERVACION"
        variant="outlined"
        fullWidth
           multiline
          rows={6}
        value={textFieldValue}
        onChange={(event) => setTextFieldValue(event.target.value)}
      />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"20px"}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handlePersonalRequerimentReject}
            disabled={!saved}
          >
                {!saved ?<LoopIcon/>: "DESAPROBAR"}
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handlePersonalRequerimentApproved}
            disabled={!saved}
          >
            {!saved ?<LoopIcon/>: "APROBAR"}
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>}
    </>);
};

export default AssignPersonalRequeriment;
