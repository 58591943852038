import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { POSTULANT_SCHEMA_YUP } from "./YupValidation";
import {
  CREATE_POSTULANT_MUTATION,
  GET_MAIN_DATA,
  GET_ALL_POSTULANT,
} from "./QueryPostulant";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
const CreatePostulant = ({ alertCustom, setNewPostulant, setAlertCustom }) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;
  const client = useApolloClient();
  const { dateFormat } = useSettings();
  const formikPostulant = useFormik({
    initialValues: {
      name: "",
      surname: "",
      doi: "",
      dob: new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      ),
      email: "",
      phone: "",
    },
    validationSchema: POSTULANT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
   
      setSubmitting(true);
      const { name, surname, doi, dob, email, phone } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_POSTULANT_MUTATION,
          variables: {
            name: name.trim().toUpperCase(),
            surname: surname.trim().toUpperCase(),
            doi: doi,
            dob,
            email: email.trim().toLowerCase(),
            phone,
          },
        });
    
        setNewPostulant(response.data.createPostulant);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        console.log(error)
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formikPostulant.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>CREAR POSTULANTE</Box>
      <Box
        mt="10px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="name"
          name="name"
          label="NOMBRES"
          variant="outlined"
          value={formikPostulant.values.name}
          onChange={formikPostulant.handleChange}
          error={
            formikPostulant.touched.name && Boolean(formikPostulant.errors.name)
          }
          helperText={
            formikPostulant.touched.name && formikPostulant.errors.name
          }
        />
        <TextField
          id="surname"
          name="surname"
          label="APELLIDOS"
          variant="outlined"
          value={formikPostulant.values.surname}
          onChange={formikPostulant.handleChange}
          error={
            formikPostulant.touched.surname &&
            Boolean(formikPostulant.errors.surname)
          }
          helperText={
            formikPostulant.touched.surname && formikPostulant.errors.surname
          }
        />
        <TextField
          id="doi"
          name="doi"
          label="DNI"
          variant="outlined"
          /* type="number" */
          value={formikPostulant.values.doi}
          onChange={formikPostulant.handleChange}
          error={
            formikPostulant.touched.doi && Boolean(formikPostulant.errors.doi)
          }
          helperText={formikPostulant.touched.doi && formikPostulant.errors.doi}
        />
        <TextField
          id="phone"
          name="phone"
          label="TELEFONO"
          variant="outlined"
          
          value={formikPostulant.values.phone}
          onChange={formikPostulant.handleChange}
          error={
            formikPostulant.touched.phone &&
            Boolean(formikPostulant.errors.phone)
          }
          helperText={
            formikPostulant.touched.phone && formikPostulant.errors.phone
          }
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikPostulant.values.dob}
            id="dob"
            name="dob"
            label="FECHA DE NACIMIENTO"
            minDate={mindate}
            maxDate={
              new Date(
                today.getFullYear() - minAge,
                today.getMonth(),
                today.getDate()
              )
            }
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikPostulant.setFieldValue("dob", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>

        <TextField
          id="email"
          name="email"
          label="EMAIL"
          variant="outlined"
          value={formikPostulant.values.email}
          onChange={formikPostulant.handleChange}
          error={
            formikPostulant.touched.email &&
            Boolean(formikPostulant.errors.email)
          }
          helperText={
            formikPostulant.touched.email && formikPostulant.errors.email
          }
        />

        <Box>
          <Button variant="contained" color="success" type="submit" disabled={formikPostulant.isSubmitting}>
          {formikPostulant.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreatePostulant;
