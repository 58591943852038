import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import StatBoxOne from "components/StatBoxOne";
import StatBox from "components/StatBox";
import { useApolloClient } from "@apollo/client";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  esES
} from "@mui/x-data-grid";
import DialogUtil from "components/DialogUtil";
import { Email } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  GET_POSITIONS,
  GET_ALL_TERM,
  GET_ALL_SUBJECT,
  GET_ALL_WORKPLACE,
} from "./../../utils/QueryUtils";
import ModalUtil from "components/ModalUtil";
import { GET_ALL_PERSONAL } from "./../FichaPersonal/QueryPersonal";
import CreateRequeriment from "./CreateRequeriment";
import {
  GET_ALL_PERSONAL_REQUERIMENT,
  GET_MAIN_DATA,
} from "./QueryPersonalRequeriment";
import AssignPosition from "./AssignPosition";
import ViewRequeriment from "./ViewRequeriment";
import TemplatePDF from "./TemplatePDF";
import ViewPdf from "components/ViewPdf";
import { useLocation } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import DeleteRequeriment from "./DeleteRequeriment";
import EditRequerimentPersonal from "./EditRequerimentPersonal";
const RequerimentPersonal = () => { 
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const location = useLocation();
  const [newPersonalRequeriment, setNewPersonalRequeriment] = useState(null);
  const [term, setTerm] = useState(null);
  const [subject, setSubject] = useState(null);
  const [workplace, setWorkplace] = useState(null);
  const [position, setPosition] = useState(null);
  const [personal, setPersonal] = useState(null);
  const { currency, dateFormat } = useSettings();
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [requeriment, setrequeriment] = useState([]);
  const [requerimentselectedView, setrequerimentselectedView] = useState(null);
  const [mainData, setMainData] = useState([]);
  const { user } = useUser();
  const [modalEdit, setModalEdit] = useState(false);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModal = (id) => {
    setModalOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setrequerimentselectedView(id);
  };
  const handleOpenDialog = (id) => {
    setDialogOpen(true);
    setrequerimentselected(id);
  };
  const handleOpenModalEdit = (id) => {
    setModalEdit(true);
    setrequerimentselected(id);
  };

  const handleCloseModalEdit = () => {
    setModalEdit(false);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const columnsRequerimentApproved = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "ngoal",
      headerName: "VACANTES",
      flex: 1,
      renderCell: (params) => params.row.ngoal,
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "remuneration",
      headerName: "REMUNERACION",
      flex: 1,
      renderCell: (params) => currency + " " + params.row.remuneration,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {location.pathname?.split("/")[1] == "gp" && user.admin ? (
            <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          ) : null}

          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
        </Box>
      ),
    },
  ];
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setrequerimentselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "ngoal",
      headerName: "VACANTES",
      flex: 0.5,
      renderCell: (params) => params.row.ngoal,
    },

    {
      field: "ope",
      headerName: "",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {location.pathname?.split("/")[1] == "gp" && user.admin &&params.row.state.description == "PENDIENTE"? (
          <> <CreateIcon
           color="info"
           onClick={() => handleOpenModalEdit(params.row.id)}
         />
         <DeleteIcon
           color="error"
           onClick={() => handleOpenDialog(params.row.id)}
         /></>
          ) : null}

          {params.row.state.description == "ATENDIDO" ? (
            <LocalPrintshopIcon
              onClick={() => handleOpenModalPDF(params.row.id)}
            />
          ) : null}

          {/* <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const FUNCTION_GET_ALL_TERM = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_TERM,
        });
        console.log(data);
        setTerm(data.getTerm);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_SUBJECT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SUBJECT,
        });
        setSubject(data.getSubject);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_WORKPLACE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_WORKPLACE,
        });
        setWorkplace(data.getWorkPlace);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_POSITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSITIONS,
        });
        setPosition(data.getPosition);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_PERSONAL_REQUERIMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            acro:
              location.pathname?.split("/")[1] == "gp"
                ? "adm"
                : location.pathname?.split("/")[1],
          },
        });
        console.log(data);
        setrequeriment(data.getPersonalRequeriment);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
          variables: {
            area: location.pathname?.split("/")[1]
          },
        });
        setMainData(data.getSumarizeRequerimentPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_TERM();
    FUNCTION_GET_ALL_SUBJECT();
    FUNCTION_GET_ALL_WORKPLACE();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_POSITION();
    FUNCTION_GET_PERSONAL_REQUERIMENT();
    FUNCTION_GET_ALL_PERSONAL();

  }, [newPersonalRequeriment, newAssignRequeriment, location.pathname]);
  const getArea = (acro) => {
    const area = {
      gp: "GESTION DE PERSONAS",
      ope: "OPERACIÓN",
      l: "LOGISTICA",
      man: "MANTENIMIENTO",
      seg: "SEGURIDAD"

    };
    return area[acro]
  }
  const getAreaMinimal = (acro) => {
    const area = {
      gp: "personal",
      ope: "operacion",
      l: "logistica",
      man: "mantenimiento",
      seg: "seguridad"
    };
    return area[acro]
  }
  return (
    <>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={AssignPosition}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setrequerimentselectedView,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselectedView}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Referencia Laboral",
          })
        }
        id={requerimentselected}
      ></ModalUtil>
       <DialogUtil
        title={"ELIMINAR REQUERIMIENTO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        Component={DeleteRequeriment}
        id={requerimentselected}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewPostulant: setNewPersonalRequeriment,
          })
        }
      />
      <ModalUtil
        isOpen={modalEdit}
        onClose={handleCloseModalEdit}
        Component={EditRequerimentPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewPersonalRequeriment: setNewPersonalRequeriment,
            term:term,
            areacro:location.pathname?.split("/")[1],
            subject:subject,
            workplace:workplace,
            position:position,
            allpersonal:personal
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title={getArea(location.pathname.split("/")[1])} subtitle={getAreaMinimal(location.pathname.split("/")[1]) + "/requerimiento"} />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}

          <StatBox
            title="Total Iniciados" //ATENDIDOS
            value={mainData.begining}
            /*  increase={"Falta alguna asignacion"} */
            // description={"Falta alguna asignacion"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Pendientes"
            value={mainData.pending}
            //description={"Requieren ser aprovados por Adminsitracion"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Aprovados"
            value={mainData.aproved}
            // description={"Aprovados por administracion y no atendidos"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTOS PENDIENTES
            </Box>
            <DataGrid
              loading={!requeriment}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              rows={

                requeriment?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py={"10px"}
            >
              CREAR REQUERIMIENTO PERSONAL
            </Box>
            <CreateRequeriment
              term={term}
              areacro={location.pathname?.split("/")[1]}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              subject={subject}
              workplace={workplace}
              position={position}
              allpersonal={personal}
              setNewPersonalRequeriment={setNewPersonalRequeriment}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTO INICIADOS / APROBADOS
            </Box>
            <DataGrid
              loading={!requeriment}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              rows={
                requeriment?.filter(
                  (row) =>
                    row.state.description === "INICIADO" ||
                    row.state.description === "APROBADO"
                ) || []
              }
              columns={columnsRequerimentApproved}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTO REHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!requeriment}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              getRowId={(row) => row.id}
              rows={
                requeriment?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RequerimentPersonal;
