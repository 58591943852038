import React,{ useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useApolloClient } from "@apollo/client";
import LoopIcon from '@mui/icons-material/Loop';
const DeleteForm = ({ onClose, params, id }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewRegister,
    paramsDelete,
    mutatitionDelete,
  } = params;
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const onAcept = async () => {
    try {
      const { data } = await client.mutate({
        mutation: mutatitionDelete,
        variables: { [paramsDelete]: id },
      });     
      const responseData = Object.values(data).find(
        (value) => value && typeof value === "object"
      );

      if (responseData) {
        setNewRegister(responseData);
      }
      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setLoading(false); 
      onClose();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };

  return (
    <>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button onClick={onAcept} variant="contained" color="error" disabled={!loading}>
        {!loading ?<LoopIcon/>: "ACEPTAR"}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteForm;
