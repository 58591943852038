import React, { useState, useEffect, useMemo } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { GET_JOB_REFERENCE_BY_ID } from "./QueryJobReference";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  tableColumn: {
    flexDirection: "column",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  column: {
    flexDirection: "column",
    flex: 1,
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "9px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});
const TemplatePDF = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [jobreferenceRequeriment, setJobreferenceRequeriment] = useState([]);
  useEffect(() => {
    const getJobreferenceRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_JOB_REFERENCE_BY_ID,
          variables: {
            getJobReferenceByIdId: id,
          },
        });
        console.log(data.getJobReferenceById);
        setJobreferenceRequeriment(data.getJobReferenceById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getJobreferenceRequeriment(id);
  }, []);
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>
                  VERIFICACION DE REFERENCIA LABORAL
                </Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>INFORMACION GENERAL</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                NOMBRE Y APELLIDOS:
              </Text>
              <Text style={[styles.text, styles.cell]}>
                Persona de referencia :
              </Text>
              <Text style={[styles.text, styles.cell]}>Cargo :</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                {(
                  jobreferenceRequeriment?.postulant.name +
                  " " +
                  jobreferenceRequeriment?.postulant.surname
                ).toLocaleLowerCase()}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {" "}
                {(
                  jobreferenceRequeriment?.name +
                  " " +
                  jobreferenceRequeriment?.surname
                ).toLocaleLowerCase()}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {" "}
                {(jobreferenceRequeriment?.bossposition).toLocaleLowerCase()}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>Teléfono :</Text>
              <Text style={[styles.text, styles.cell]}>Empresa :</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>
                {jobreferenceRequeriment?.phone
                  ? jobreferenceRequeriment?.phone
                  : " "}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {jobreferenceRequeriment?.company}
              </Text>
            </View>
          </View>
          {/*    <View style={styles.title}>
          <Text>INFORMACION RELEVANTE AL CARGO</Text>
        </View> */}
          <View style={[styles.title, styles.textTitle]}>
            <Text>CUESTIONARIO</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.column, { flexGrow: 1 }]}>
                {jobreferenceRequeriment?.criterionQuestion.map((it, index) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{index + 1} </Text>
                    <Text style={[styles.text, styles.cell]}>-</Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexGrow: 8 }]}>
                {jobreferenceRequeriment?.criterionQuestion.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.criterion.description}
                    </Text>
                    <Text style={[styles.text, styles.cell]}>{it.answer}</Text>{" "}
                  </>
                ))}
              </View>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>
                Lo recomienda:
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>SI</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>
                {jobreferenceRequeriment?.recomended == false ? "X" : " "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>NO:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>
                {jobreferenceRequeriment?.recomended == true ? "X" : " "}
              </Text>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>REFERENCIA REALIZADA</Text>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                NOMBRES Y APELLIDOS
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>CARGO</Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>FIRMA</Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
