import React from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useApolloClient } from "@apollo/client";
import { DELETE_PERSONAL_BY_ID } from "./QueryPersonal";

const DeletePersonal = ({ onClose, params, id }) => {
  const { setAlertCustom, alertCustom, setNewRegister } = params;
  const client = useApolloClient();
  const onAcept = async () => {
    try {
      const response = await client.mutate({
        mutation: DELETE_PERSONAL_BY_ID,
        variables: {
          deletePersonalByIdId: id,
        },
      });
      setNewRegister(response.data.deletePersonalById);
      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      onClose();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (
    <>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button onClick={onAcept} variant="contained" color="error">
          ACEPTAR
        </Button>
      </DialogActions>
    </>
  );
};

export default DeletePersonal;
