import React, { useEffect } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { PERSONAL_REQUERIMENT_SCHEMA_YUP } from "./YupValidation";
import { useState, useRef } from "react";
import LoopIcon from "@mui/icons-material/Loop";
import { useLocation } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
//import { CREATE_PERSONAL_REQUERIMENT } from "./QueryPersonalRequeriment";
import {GET_PERSONAL_AND_POSITION_BY_ID} from "./QueryPersonalRequeriment";
const EditRequerimentPersonal = ({id,params}) => {
    const {
        setAlertCustom,
        alertCustom,
        term,
        subject,
        areacro,
        workplace,
        position,
        allpersonal,
        setNewPersonalRequeriment,
      }=params;
    const client = useApolloClient();
    const location = useLocation();
    const [personalRequeriment, setPersonalRequeriment] = useState([]);
    const autocompleteRef = useRef(null);
    const { user } = useUser();
    const { currency,dateFormat } = useSettings(); 
    const initialValues = {
      personal: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      area: areacro,
      term: "",
      subject: "",
      duration: "",
      incorporationdate: new Date(),
      remuneration: "",
      ngoal: "",
      workplace: "",
      observations: "",
      position: "",
    };
    const formikPersonalRequeriment = useFormik({
      initialValues,
      validationSchema: PERSONAL_REQUERIMENT_SCHEMA_YUP,
      onSubmit: async (values, { setSubmitting, resetForm }) => {     
        setSubmitting(true);
        const {
          personal,
          area,
          term,
          subject,
          duration,
          incorporationdate,
          remuneration,
          ngoal,
          workplace,
          observations,
          position,
        } = values;
        try {
         /*  const response = await client.mutate({
            mutation: CREATE_PERSONAL_REQUERIMENT,
            variables: {
              personal: personal.id,
              area,
              term,
              subject,
              duration: Number(duration),
              incorporationdate,
              remuneration: parseFloat(remuneration),
              ngoal: Number(ngoal),
              workplace,
              observations: observations ? [observations] : [],
              position,
            },
          });
          console.log(response.data);
          setNewPersonalRequeriment(response.data.createPersonalRequeriment);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          if (user.admin) {
            autocompleteRef.current.value = "";
          }
  
          resetForm(); */
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      },
    });

  useEffect(() => {
    const getPersonalRequeriment = async (id) => {
        try {
          const { data } = await client.query({
            query: GET_PERSONAL_AND_POSITION_BY_ID,
            variables: {
              getPersonalRequerimentAndPersonalByIdId: id,
            },
            fetchPolicy: "network-only",
          });
  
          setPersonalRequeriment(data.getPersonalRequerimentAndPersonalById);
          console.log(data)
         /*  formikPersonalRequeriment.setValues({
            personal: {
                label: "",
                id: !user.admin ? user.personal.id : "",
              },
              area: areacro,
              term: "",
              subject: "",
              duration: "",
              incorporationdate: new Date(),
              remuneration: "",
              ngoal: "",
              workplace: "",
              observations: "",
              position: "",
          }) */
       //   setvisibility(true);
        } catch (error) {
            setAlertCustom({
                ...alertCustom,
                type: "error",
                message: error.message,
                state: true,
              });
        }
      };
      getPersonalRequeriment(id);

  }, []);
  return (
    <form onSubmit={formikPersonalRequeriment.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      {user.admin ? (
        <>
          <Box mb={"20px"}>
            {allpersonal?.length ? (
              <Autocomplete
                freeSolo
                id="personal"
                name="personal"
                ref={autocompleteRef}
                value={formikPersonalRequeriment.values.personal.label}
                disableClearable
                options={allpersonal?.map(
                  (option) =>
                    new Object({
                      label:
                        option.postulant.name +
                        " " +
                        option.postulant.surname +
                        " / " +
                        "dni : " +
                        option.postulant.doi,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikPersonalRequeriment.setFieldValue("personal", {
                    label: newValue.label,
                    id: newValue.value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar por nombre/apellido/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    name={"personal"}
                    error={
                      formikPersonalRequeriment.touched.personal?.id &&
                      Boolean(formikPersonalRequeriment.errors.personal?.id)
                    }
                    helperText={
                      formikPersonalRequeriment.touched.personal?.id &&
                      formikPersonalRequeriment.errors.personal?.id
                    }
                  />
                )}
              />
            ) : (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>
          {formikPersonalRequeriment.touched.personal?.id &&
          formikPersonalRequeriment.errors.personal?.id ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {" "}
                {formikPersonalRequeriment.touched.personal?.id &&
                  formikPersonalRequeriment.errors.personal?.id}
              </p>
            </FormHelperText>
          ) : null}
        </>
      ) : null}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">CARGO</InputLabel>
          <Select
            id="position"
            label="CARGO"
            value={formikPersonalRequeriment.values.position}
            name="position"
            onChange={formikPersonalRequeriment.handleChange}
            error={
              formikPersonalRequeriment.touched.position &&
              Boolean(formikPersonalRequeriment.errors.position)
            }
          >
            {position?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonalRequeriment.touched.position &&
          formikPersonalRequeriment.errors.position ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonalRequeriment.touched.position &&
                formikPersonalRequeriment.errors.position}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            PLAZO DE CONTRATO
          </InputLabel>
          <Select
            id="term"
            value={formikPersonalRequeriment.values.term}
            name="term"
            label="PLAZO DE CONTRATO"
            onChange={formikPersonalRequeriment.handleChange}
            error={
              formikPersonalRequeriment.touched.term &&
              Boolean(formikPersonalRequeriment.errors.term)
            }
          >
            {term?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonalRequeriment.touched.term &&
          formikPersonalRequeriment.errors.term ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonalRequeriment.touched.term &&
                formikPersonalRequeriment.errors.term}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            MOTIVO DEL PEDIDO
          </InputLabel>
          <Select
            label="MOTIVO DEL PEDIDO"
            id="subject"
            value={formikPersonalRequeriment.values.subject}
            name="subject"
            onChange={formikPersonalRequeriment.handleChange}
            error={
              formikPersonalRequeriment.touched.subject &&
              Boolean(formikPersonalRequeriment.errors.subject)
            }
          >
            {subject?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonalRequeriment.touched.subject &&
          formikPersonalRequeriment.errors.subject ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonalRequeriment.touched.subject &&
                formikPersonalRequeriment.errors.subject}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="ngoal"
          name="ngoal"
          label="VACANTES"
          variant="outlined"
          value={formikPersonalRequeriment.values.ngoal}
          onChange={formikPersonalRequeriment.handleChange}
          error={
            formikPersonalRequeriment.touched.ngoal &&
            Boolean(formikPersonalRequeriment.errors.ngoal)
          }
          helperText={
            formikPersonalRequeriment.touched.ngoal &&
            formikPersonalRequeriment.errors.ngoal
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            LUGAR DEL TRABAJO
          </InputLabel>
          <Select
            id="workplace"
            value={formikPersonalRequeriment.values.workplace}
            name="workplace"
            label="LUGAR DEL TRABAJO"
            onChange={formikPersonalRequeriment.handleChange}
            error={
              formikPersonalRequeriment.touched.workplace &&
              Boolean(formikPersonalRequeriment.errors.workplace)
            }
          >
            {workplace?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonalRequeriment.touched.workplace &&
          formikPersonalRequeriment.errors.workplace ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonalRequeriment.touched.workplace &&
                formikPersonalRequeriment.errors.workplace}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="duration"
          name="duration"
          label="DURACION ESTIMADA (MESES)"
          variant="outlined"
          value={formikPersonalRequeriment.values.duration}
          onChange={formikPersonalRequeriment.handleChange}
          error={
            formikPersonalRequeriment.touched.duration &&
            Boolean(formikPersonalRequeriment.errors.duration)
          }
          helperText={
            formikPersonalRequeriment.touched.duration &&
            formikPersonalRequeriment.errors.duration
          }
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikPersonalRequeriment.values.incorporationdate}
            id="incorporationdate"
            name="incorporationdate"
            label="FECHA DE INCORPORACION"
            format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
            onChange={(val) => {
              formikPersonalRequeriment.setFieldValue("incorporationdate", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel htmlFor="remuneration">REMUNERACION</InputLabel>
          <OutlinedInput
            id="remuneration"
            name="remuneration"
            type="number"
            onChange={formikPersonalRequeriment.handleChange}
            value={formikPersonalRequeriment.values.remuneration}
            startAdornment={
              <InputAdornment position="start">{currency} </InputAdornment>
            }
            label="REMUNERACION"
          />
          {formikPersonalRequeriment.touched.remuneration &&
          formikPersonalRequeriment.errors.remuneration ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonalRequeriment.touched.remuneration &&
                formikPersonalRequeriment.errors.remuneration}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      <Box mt={"20px"}>
        <TextField
          fullWidth
          id="observations"
          label="OBSERVACIONES"
          name="observations"
          multiline
          rows={6}
          value={formikPersonalRequeriment.values.observations}
          onChange={formikPersonalRequeriment.handleChange}
          error={
            formikPersonalRequeriment.touched.observations &&
            Boolean(formikPersonalRequeriment.errors.observations)
          }
          helperText={
            formikPersonalRequeriment.touched.observations &&
            formikPersonalRequeriment.errors.observations
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikPersonalRequeriment.isSubmitting}
        >
          {formikPersonalRequeriment.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default EditRequerimentPersonal;
