import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBoxOne from "components/StatBoxOne";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { DataGrid,  GridToolbarContainer,
  GridToolbarFilterButton,esES,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import CreateTraining from "./CreateTraining";
import {
  GET_TRAINING_TYPE,
  GET_TRAINING,
  GET_MAIN_DATA,
} from "./QueryTraining";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import ViewTraining from "./ViewTraining";
import ViewPdf from "../../../components/ViewPdf";
import PDFTraining from "./PDFTraining";

const Training = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [trainingType, setTrainingType] = useState([]);
  const [newtraining, setNewTraining] = useState();
  const [training, setTraining] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [id, setId] = useState(null);
  const [personal, setPersonal] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setId(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "subject",
      headerName: "TEMA",
      flex: 1,
      renderCell: (params) => params.row.subject,
    },
    {
      field: "aggrement",
      headerName: "ACUERDOS",
      flex: 1,
      renderCell: (params) => params.row.aggrement,
    },
    {
      field: "place",
      headerName: "LUGAR",
      flex: 1,
      renderCell: (params) => params.row.place,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          <LocalPrintshopIcon
            onClick={() => handleOpenModalPDF(params.row.id)}
          />
          {/*  <CreateIcon />       */}
        </Box>
      ),
    },
  ];
  const FUNCTION_MAIN_DATA = async () => {
    try {
      const { data } = await client.query({
        query: GET_MAIN_DATA,
        fetchPolicy: "network-only",
      });
      setMainData(data.getSumarizeTraining);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  useEffect(() => {
    const FUNCTION_TRAINING_TYPE = async () => {
      try {
        const { data } = await client.query({
          query: GET_TRAINING_TYPE,
          fetchPolicy: "network-only",
        });
        setTrainingType(data.getTrainingType);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_TRAINING = async () => {
      try {
        const { data } = await client.query({
          query: GET_TRAINING,
          fetchPolicy: "network-only",
        });
        console.log(data.getTraining);
        setTraining(data.getTraining);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_TRAINING_TYPE();
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_TRAINING();
    FUNCTION_MAIN_DATA();
  }, [newtraining]);
  return (
    <>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewTraining}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: PDFTraining,
            title: "Entrevista",
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="SEGURIDAD" subtitle="seguridad/capacitación" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Total de Capacitaciones"
            value={mainData?.amount}
            /*   increase={mainData?.cargo}
    description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Tema"
            value={mainData?.lastsubject}
            /*   increase={mainData?.cargo} */
            description={"Ultima capacitación"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Fecha de la capacitacion"
            value={
              mainData?.lastdate
                ? new Date(Number(mainData?.lastdate)).toLocaleDateString(
                    "es-ES"
                  )
                : null
            }
            description={"Ultima capacitación"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Asistentes"
            value={mainData?.amountPersonal}
            description={"Ultima capacitación"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 1 */}

          {/* ROW 2 */}

          <Box
            gridColumn="span 6"
            gridRow="span 9"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <CreateTraining
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              personal={personal}
              trainingType={trainingType}
              setNewTraining={setNewTraining}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={!training}
              getRowId={(row) => row.id}
              rows={training || []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}  
              columns={columns}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Training;
