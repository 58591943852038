import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { ChangeFormatDb } from "utils/DateFormatting";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { TRAINING_SCHEMA_YUP } from "./YupTraining";
import { CREATE_TRAINING } from "./QueryTraining";
import { useSettings } from "hooks/useSettings";
import { useUser } from "hooks/useUser";
import dayjs from "dayjs";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
const CreateTraining = ({
  alertCustom,
  setAlertCustom,
  trainingType,
  personal,
  setNewTraining,
}) => {
  const client = useApolloClient();
  const autocompletepersonalRef = useRef(null);
  const { user } = useUser();
  const theme = useTheme();
  const [selectionModel, setSelectionModel] = useState([]);
  const { currency, dateFormat } = useSettings();
  const formikTraining = useFormik({
    initialValues: {
      personal: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      subject: "",
      type: "",
      description: "",
      date: new Date(),
      beginTime: dayjs("2022-04-17T15:30"),
      endTime: dayjs("2022-04-17T15:30"),
      goal: "",
      company: "",
      tools: "",
      place: "",
      observation: "",
      aggrement: "",
      participant: [],
    },
    validationSchema: TRAINING_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        personal,
        subject,
        type,
        description,
        date,
        beginTime,
        endTime,
        goal,
        company,
        tools,
        place,
        observation,
        aggrement,
        participant,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_TRAINING,
          variables: {
            personal: personal.id,
            subject: subject.trim().toUpperCase(),
            type,
            description: description.trim().toUpperCase(),
            date,
            beginTime,
            endTime,
            goal: goal.trim().toUpperCase(),
            company: company.trim().toUpperCase(),
            tools: tools.trim().toUpperCase(),
            place: place.trim().toUpperCase(),
            area: "6480a9f4bd7a0140c5874ef8" /*NEED FIXED, THIS EXTRACTED BY DB*/,
            observation: observation.trim().toUpperCase(),
            aggrement: aggrement.trim().toUpperCase(),
            participant,
          },
        });
        setNewTraining(response.data.createTraining);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        setSelectionModel([]);
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const columns = [
    {
      field: "postulant.name",
      headerName: "NOMBRE",
      flex: 1,
      valueGetter: (params) => params.row.postulant.name,
    },
    {
      field: "postulant.surname",
      headerName: "APELLIDOS",
      flex: 1,
      valueGetter: (params) => params.row.postulant.surname,
    },
  ];
  return (
    <form onSubmit={formikTraining.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        {" "}
        <TextField
          id="subject"
          fullWidth
          name="subject"
          label="TEMA"
          variant="outlined"
          value={formikTraining.values.subject}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.subject &&
            Boolean(formikTraining.errors.subject)
          }
          helperText={
            formikTraining.touched.subject && formikTraining.errors.subject
          }
        />
        {user.admin ? (
          <Box>
            {personal?.length ? (
              <Autocomplete
                freeSolo
                id="personal"
                name="personal"
                disableClearable
                ref={autocompletepersonalRef}
                value={formikTraining.values.personal.label}
                options={personal?.map(
                  (option) =>
                    new Object({
                      label:
                        option.postulant.name +
                        "  " +
                        option.postulant.surname +
                        " / " +
                        "dni:" +
                        option.postulant.doi,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikTraining.setFieldValue("personal", {
                    label: newValue.label,
                    id: newValue.value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar expositor por nombre/apellido/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={
                      formikTraining.touched.personal?.id &&
                      Boolean(formikTraining.errors.personal?.id)
                    }
                    helperText={
                      formikTraining.touched.personal?.id &&
                      formikTraining.errors.personal?.id
                    }
                  />
                )}
              />
            ) : (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>
        ) : null}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            TIPO DE REGISTRO
          </InputLabel>
          <Select
            id="type"
            value={formikTraining.values.type}
            name="type"
            label="TIPO DE REGISTRO"
            onChange={formikTraining.handleChange}
            error={
              formikTraining.touched.type && Boolean(formikTraining.errors.type)
            }
          >
            {trainingType?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikTraining.touched.type && formikTraining.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikTraining.touched.type && formikTraining.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        {/*   <TextField
          id="description"
          fullWidth
          name="description"
          label="DESCRIPCION"
          variant="outlined"
          value={formikTraining.values.description}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.description &&
            Boolean(formikTraining.errors.description)
          }
          helperText={
            formikTraining.touched.description &&
            formikTraining.errors.description
          }
        /> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikTraining.values.date}
            id="date"
            name="date"
            label="FECHA"
            format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
            onChange={(val) => {
              formikTraining.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="beginTime"
            label="HORARIO INICIO"
            name="beginTime"
            value={formikTraining.values.beginTime}
            onChange={(val) => {
              formikTraining.setFieldValue("beginTime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="endTime"
            label="HORARIO FINAL"
            name="endTime"
            value={formikTraining.values.endTime}
            onChange={(val) => {
              formikTraining.setFieldValue("endTime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <TextField
          id="goal"
          fullWidth
          name="goal"
          label="OBJETIVOS"
          variant="outlined"
          value={formikTraining.values.goal}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.goal && Boolean(formikTraining.errors.goal)
          }
          helperText={formikTraining.touched.goal && formikTraining.errors.goal}
        />
        <TextField
          id="company"
          fullWidth
          name="company"
          label="EMPRESA"
          variant="outlined"
          value={formikTraining.values.company}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.company &&
            Boolean(formikTraining.errors.company)
          }
          helperText={
            formikTraining.touched.company && formikTraining.errors.company
          }
        />
        <TextField
          id="tools"
          fullWidth
          name="tools"
          label="MATERIALES USADOS"
          variant="outlined"
          value={formikTraining.values.tools}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.tools && Boolean(formikTraining.errors.tools)
          }
          helperText={
            formikTraining.touched.tools && formikTraining.errors.tools
          }
        />
        <TextField
          id="place"
          fullWidth
          name="place"
          label="LUGAR DE CAPACITACION"
          variant="outlined"
          value={formikTraining.values.place}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.place && Boolean(formikTraining.errors.place)
          }
          helperText={
            formikTraining.touched.place && formikTraining.errors.place
          }
        />
      </Box>

      <Box mb={"20px"}>
        <TextField
          id="observation"
          fullWidth
          name="observation"
          label="OBSERVACIONES"
          variant="outlined"
          multiline
          rows={3}
          value={formikTraining.values.observation}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.observation &&
            Boolean(formikTraining.errors.observation)
          }
          helperText={
            formikTraining.touched.observation &&
            formikTraining.errors.observation
          }
        />
      </Box>
      <Box mb={"20px"}>
        <TextField
          id="aggrement"
          fullWidth
          name="aggrement"
          label="ACUERDOS Y COMPROMISOS"
          variant="outlined"
          multiline
          rows={3}
          value={formikTraining.values.aggrement}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.aggrement &&
            Boolean(formikTraining.errors.aggrement)
          }
          helperText={
            formikTraining.touched.aggrement && formikTraining.errors.aggrement
          }
        />
      </Box>
      <Box
        gridColumn="span 6"
        gridRow="span 5"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!personal}
          getRowId={(row) => row.id}
          rows={personal || []}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          selectionModel={selectionModel}
          pageSize={10}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          onSelectionModelChange={(itm) => {
            setSelectionModel(itm);
            formikTraining.setFieldValue("participant", itm);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>
            ),
          }}
        />
        {formikTraining.touched.participant &&
        formikTraining.errors.participant ? (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Verica los campos esten requeridos
            </p>
          </FormHelperText>
        ) : null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikTraining.isSubmitting}
        >
          {formikTraining.isSubmitting ? <RotatingLoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateTraining;
