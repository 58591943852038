import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ModalUtil from "components/ModalUtil";
import { useApolloClient } from "@apollo/client";
import StatBoxOne from "components/StatBoxOne";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { FormHelperText } from "@mui/material";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";
import DialogUtil from "components/DialogUtil";
import TextField from "@mui/material/TextField";
import CreateDocument from "./CreateDocument";
import { GET_ALL_ACTIVE_VEHICLE } from "./../operation/QueryOperation";
import Alerts from "components/Alerts";
import {
  GET_DOCUMENT_BY_ACTIVE_ID,
  GET_LISTDOCUMENT_BY_ACTIVE_ID,
} from "./QueryDocument";
import ViewDocument from "./ViewDocument";
import AssignDocument from "./AssignDocument";
import DeleteDocumentVehicle from "./DeleteDocumentVehicle";
const Document = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [active, setActive] = useState([]);
  const client = useApolloClient();
  const [documents, setDocuments] = useState([]);
  const [id, setId] = useState(null);
  const [selectid, setselectid] = useState(null);
  const [listDocument, setlistDocument] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [updateDocument, setupdateDocument] = useState(null);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenAssign, setModalOpenAssign] = useState(false);
  const [deletedDocumentPersonal, setdeletedDocumentPersonal] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setselectid(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };

  const handleOpenModalAssign = (id) => {
    setModalOpenAssign(true);
    setselectid(id);
  };

  const handleCloseModalAssign = () => {
    setModalOpenAssign(false);
  };

  const handleOpenDeletedDocument = (id) => {
    setdeletedDocumentPersonal(true);
    setselectid(id);
  };

  const handleCloseDeletedDocument = () => {
    setdeletedDocumentPersonal(false);
  };
  useEffect(() => {
    const FUNCTION_GET_ALL_ACTIVE_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllActiveVehicle);
        setActive(data.getAllActiveVehicle);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_ACTIVE_VEHICLE();
    FUNCTION_GET_DOCUMENT_BY_ACTIVE(id);
  }, [newDocument, id, updateDocument]);
  const FUNCTION_GET_DOCUMENT_BY_ACTIVE = async (id) => {
    console.log(id);
    try {
      const documents = await client.query({
        query: GET_DOCUMENT_BY_ACTIVE_ID,
        variables: {
          getDocumentActiveByActiveIdId: id,
        },
        fetchPolicy: "network-only",
      });

      const listDocument = await client.query({
        query: GET_LISTDOCUMENT_BY_ACTIVE_ID,
        variables: {
          getDocumentListByActiveId: id,
        },
        fetchPolicy: "network-only",
        /*  fetchPolicy:'network-only' */
      });

      console.log(documents);
      setDocuments(documents.data.getDocumentActiveByActiveId);
      setlistDocument(listDocument.data.getDocumentListByActive);
    } catch (error) {
      console.log(error.message);
    }
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "document",
      headerName: "DOCUMENTO",
      flex: 1,
      renderCell: (params) => params.row.document.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "-",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        switch (params.row.state.description) {
          case "VIGENTE":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#28a745"}
              ></Box>
            );
          case "POR ACTUALIZAR":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#ffc107"}
              ></Box>
            );
          case "POR VENCER":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#dc3545"}
              ></Box>
            );
          default:
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#000"}
              ></Box>
            );
        }
      },
    },
    {
      field: "ope",
      headerName: "",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {params.row.state.description == "INASIGNADO" ? (
            <TouchAppIcon
              onClick={() => handleOpenModalAssign(params.row.id)}
            />
          ) : (
            <>
            <VisibilityIcon
              onClick={() => handleOpenModalView(params.row.id)}
            />
             <DeleteIcon
                color="error"
                onClick={() => handleOpenDeletedDocument(params.row.id)}
              />
             </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      {alertCustom?.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
       <DialogUtil
        title={"ELIMINAR DOCUMENTO PERSONAL"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={deletedDocumentPersonal}
        onClose={handleCloseDeletedDocument}
        Component={DeleteDocumentVehicle}
        id={selectid}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewDocument,
          })
        }
      />
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewDocument}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={selectid}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenAssign}
        onClose={handleCloseModalAssign}
        Component={AssignDocument}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setupdateDocument,
            handleCloseModal: handleCloseModalAssign,
          })
        }
        id={selectid}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/documento" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 6"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {active.length ? (
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={active?.map(
                  (option) =>
                    new Object({
                      label:
                        option.description + " / " + "placa:" + option.plate,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setId(newValue.value);
                  FUNCTION_GET_DOCUMENT_BY_ACTIVE(newValue.value);
                  // formikFuel.setFieldValue("active", newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar por nombre/apellido/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            ) : (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={!documents}
              getRowId={(row) => row.id}
              rows={documents || []}
              columns={columns}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>

          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box>
              <CreateDocument
                newDocument={newDocument}
                setNewDocument={setNewDocument}
                data={listDocument}
                alertCustom={alertCustom}
                setAlertCustom={setAlertCustom}
                id={id}
              />
              {/*  <CreateJobReference bossPosition={bossPosition} criterion={criterion} /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Document;
