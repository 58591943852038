import { Box, Collapse, List, ListItemButton, ListItemText, Typography, useTheme } from "@mui/material"
import { items } from "consts/allowedRoles"
import { useState } from "react"
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const StructureModules = ({ module }) => {
  console.log(module)
  const theme = useTheme()
  const [openList, setOpenList] = useState(Array(items.length).fill(false))

  const toogleArrList = (index) => {
    const list = openList.map((item, i) => i === index ? !item : item)
    setOpenList(list)
  }

  const modules = items.filter(item => {
    return item.subItems.some(subItem => module.includes(subItem.id));
  });

  const allowedModules = modules.map(item => {
    return {
      title: item.title,
      subItems: item.subItems.filter(subitem => module.includes(subitem.id))
    }
  })

  return (
    <Box
      border={1}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: '28rem',
        borderColor: theme.palette.secondary[300],
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll'
      }}
    >
      <List>
        {
          allowedModules.map((module, index) => {
            return (
              <>
                <ListItemButton
                  onClick={() => toogleArrList(index)}
                  sx={{
                    display: 'flex',
                    px: 0,
                    mb: 1,
                  }}
                >
                  {
                    openList[index] ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />
                  }

                  <Typography
                    variant="h6"
                  >
                    {module.title}
                  </Typography>
                </ListItemButton>
                <Collapse
                  in={openList[index]}
                  timeout='auto'
                  unmountOnExit
                  sx={{
                    padding: 0,
                  }}
                >
                  <List disablePadding>
                    {
                      module.subItems.map(submodule => {
                        return (
                          <ListItemText
                            primary={submodule.title}
                            sx={{
                              ml: 3
                            }}
                          />
                        )
                      })
                    }
                  </List>
                </Collapse>
              </>
            )
          })
        }
      </List>
    </Box>
  )
}

export default StructureModules