import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useApolloClient } from "@apollo/client";
import { DELETE_TRAINING_DOCUMENT } from "./QueryDocument";
import LoopIcon from "@mui/icons-material/Loop";

const DeletedDocumentTraining = ({ onClose, params, id }) => {
  const { setAlertCustom, alertCustom, setNewRegister } = params;
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const onAcept = async () => {
    try {
      const response = await client.mutate({
        mutation: DELETE_TRAINING_DOCUMENT,
        variables: {
            deleteDocumentTrainingId: id,
        },
      });
      setNewRegister(response.data.deleteDocumentTraining);
      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setLoading(false);
      onClose();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (
    <>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button
          onClick={onAcept}
          variant="contained"
          color="error"
          disabled={!loading}
        >
          {!loading ? <LoopIcon /> : "ACEPTAR"}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeletedDocumentTraining;
