import React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import LoopIcon from '@mui/icons-material/Loop';
import { PARENTS_SCHEMA_YUP } from "./YupValidation";
import Alerts from "components/Alerts";

import { CREATE_PARENTS } from "./QueryPersonal";
const CreateParents = ({
  id,
  setNewParents,
  alertCustom,
  setAlertCustom,
  setErrorId,
}) => {

  const client = useApolloClient();

  const formikParents = useFormik({
    initialValues: {
      personal: "",
      dadname: "",
      dadsurname: "",
      dadoccupation: "",
      dadworkplace: "",
      dadaddress: "",
      dadphone: "",
      momname: "",
      momsurname: "",
      momoccupation: "",
      momworkplace: "",
      momaddress: "",
      momphone: "",
    },
    validationSchema: PARENTS_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const {
          dadname,
          dadsurname,
          dadoccupation,
          dadworkplace,
          dadaddress,
          dadphone,
          momname,
          momsurname,
          momoccupation,
          momworkplace,
          momaddress,
          momphone,
        } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_PARENTS,
            variables: {
              personal: id,
              dadname: dadname.toUpperCase(),
              dadsurname: dadsurname.toUpperCase(),
              dadoccupation: dadoccupation.toUpperCase(),
              dadworkplace: dadworkplace.toUpperCase(),
              dadaddress: dadaddress.toUpperCase(),
              dadphone,
              momname: momname.toUpperCase(),
              momsurname: momsurname.toUpperCase(),
              momoccupation: momoccupation.toUpperCase(),
              momworkplace: momworkplace.toUpperCase(),
              momaddress: momaddress.toUpperCase(),
              momphone,
            },
          });    
          setNewParents(response.data.createParent);     
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikParents.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap="20px"
      >
        <TextField
          id="dadname"
          name="dadname"
          label="NOMBRES DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadname}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadname &&
            Boolean(formikParents.errors.dadname)
          }
          helperText={
            formikParents.touched.dadname && formikParents.errors.dadname
          }
        />
        <TextField
          id="dadsurname"
          name="dadsurname"
          label="APELLIDOS DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadsurname}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadsurname &&
            Boolean(formikParents.errors.dadsurname)
          }
          helperText={
            formikParents.touched.dadsurname && formikParents.errors.dadsurname
          }
        />
        <TextField
          id="dadoccupation"
          name="dadoccupation"
          label="CENTRO DE TRABAJO DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadoccupation}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadoccupation &&
            Boolean(formikParents.errors.dadoccupation)
          }
          helperText={
            formikParents.touched.dadoccupation &&
            formikParents.errors.dadoccupation
          }
        />
        <TextField
          id="dadworkplace"
          name="dadworkplace"
          label="OCUPACION DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadworkplace}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadworkplace &&
            Boolean(formikParents.errors.dadworkplace)
          }
          helperText={
            formikParents.touched.dadworkplace &&
            formikParents.errors.dadworkplace
          }
        />
        <TextField
          id="dadaddress"
          name="dadaddress"
          label="DIRECCION DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadaddress}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadaddress &&
            Boolean(formikParents.errors.dadaddress)
          }
          helperText={
            formikParents.touched.dadaddress && formikParents.errors.dadaddress
          }
        />
        <TextField
          id="dadphone"
          name="dadphone"
          label="TELEFONO DEL PADRE"
          variant="outlined"
          value={formikParents.values.dadphone}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.dadphone &&
            Boolean(formikParents.errors.dadphone)
          }
          helperText={
            formikParents.touched.dadphone && formikParents.errors.dadphone
          }
        />
        <TextField
          id="momname"
          name="momname"
          label="NOMBRES DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momname}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momname &&
            Boolean(formikParents.errors.momname)
          }
          helperText={
            formikParents.touched.momname && formikParents.errors.momname
          }
        />
        <TextField
          id="momsurname"
          name="momsurname"
          label="APELLIDOS DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momsurname}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momsurname &&
            Boolean(formikParents.errors.momsurname)
          }
          helperText={
            formikParents.touched.momsurname && formikParents.errors.momsurname
          }
        />
        <TextField
          id="momoccupation"
          name="momoccupation"
          label="CENTRO DE TRABAJO DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momoccupation}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momoccupation &&
            Boolean(formikParents.errors.momoccupation)
          }
          helperText={
            formikParents.touched.momoccupation &&
            formikParents.errors.momoccupation
          }
        />
        <TextField
          id="momworkplace"
          name="momworkplace"
          label="OCUPACION DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momworkplace}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momworkplace &&
            Boolean(formikParents.errors.momworkplace)
          }
          helperText={
            formikParents.touched.momworkplace &&
            formikParents.errors.momworkplace
          }
        />
        <TextField
          id="momaddress"
          name="momaddress"
          label="DIRECCION DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momaddress}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momaddress &&
            Boolean(formikParents.errors.momaddress)
          }
          helperText={
            formikParents.touched.momaddress && formikParents.errors.momaddress
          }
        />
        <TextField
          id="momphone"
          name="momphone"
          label="TELEFONO DE LA MADRE"
          variant="outlined"
          value={formikParents.values.momphone}
          onChange={formikParents.handleChange}
          error={
            formikParents.touched.momphone &&
            Boolean(formikParents.errors.momphone)
          }
          helperText={
            formikParents.touched.momphone && formikParents.errors.momphone
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikParents.isSubmitting}>
        {formikParents.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateParents;
