import { gql } from "@apollo/client";

export const GET_QUESTION_SOMNOLENCY = gql`
  query GetQuestionSomnolency {
    getQuestionSomnolency {
      id
      description
    }
  }
`;

export const GET_SOMNOLENCY = gql`
  query GetSomnolency {
    getSomnolency {
      id
      date
      project
      timeElapsed
      timeSleep
    }
  }
`;
export const CREATE_SOMNOLENCY = gql`
  mutation CreateSomnolency(
    $evaluator: ID
    $operator: ID
    $project: String
    $date: Date
    $criterion: [SomnolencyQuestionPersonal]
    $timeElapsed: Int
    $timeSleep: Int
  ) {
    createSomnolency(
      evaluator: $evaluator
      operator: $operator
      project: $project
      date: $date
      criterion: $criterion
      timeElapsed: $timeElapsed
      timeSleep: $timeSleep
    ) {
      id
      date
      project
      timeElapsed
      timeSleep
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeSomnolency {
    getSumarizeSomnolency {
      amount
      amountfalse
      amounttrue
      lastsubject
    }
  }
`;

export const GET_SOMNOLENCY_BY_ID = gql`
  query GetSomnolencyById($getSomnolencyByIdId: ID) {
  getSomnolencyById(id: $getSomnolencyByIdId) {
      questions {
        somnolency

        observation
        answer
        question {
          description
        }
      }
      somnolency {
        id
        date
        project
        timeElapsed
        timeSleep
        evaluator {
          postulant {
            name
            surname
            doi
          }
        }
        operator {
          postulant {
            name
            surname
            doi
          }
        }
      }
  }
}
`;
