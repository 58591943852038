import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBox from "components/StatBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import CreateProveedor from "./CreateProveedor";
import {
  GET_ALL_TYPES,
  GET_ALL_CATEGORIES,
  GET_ALL_BANK,
  GET_ALL_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  EDIT_SUPPIER_BY_ID,
  GET_CATEGORY_BY_ID,
  EDIT_SUPPLIER_CATEGORY_BY_ID,
} from "./QueryProvider";
import { GET_ALL_DEPARTAMENT } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import CreateCategory from "./CreateCategory";
import { GET_GROUPS } from "./../groupclass/QueryGroup";
import ViewProvider from "./ViewProvider";
import ViewCategory from "./ViewCategory";
import EditForm from "./EditForm";
import {
  PROVIDER_SCHEMA_YUP,
  PROVIDER_CATEGORY_SCHEMA_YUP,
} from "./YupValidation";
import { useUser } from "hooks/useUser";
import {
  DELETE_SUPPLIER_BY_ID,
  DELETE_SUPPLIER_CATEGORY_BY_ID,
} from "./QueryProvider";
import DialogUtil from "components/DialogUtil";
import DeleteForm from "./DeleteForm";
const Provider = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newProvider, setNewProvider] = useState(null);
  const [allTypes, setAllTypes] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allDepartaments, setAllDepartaments] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allBank, setAllBank] = useState([]);
  const [allSupplier, setAllSupplier] = useState([]);
  const client = useApolloClient();
  const [newCategory, setNewCategory] = useState(null);

  const [modalCategory, setmodalCategory] = useState(false);
  const [modalProvider, setmodalProvider] = useState(false);

  const [modalEditProvider, setmodalEditProvider] = useState(false);
  const [modalEditCategory, setmodalEditCategory] = useState(false);

  const [idCategory, setidCategory] = useState(false);
  const [idProvider, setidProvider] = useState(false);
  const { user } = useUser();
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const [dialogDeleteSupplier, setdialogDeleteSupplier] = useState(false);
  const [
    dialogDeleteCategorySupplier,
    setdialogDeleteCategorySupplier,
  ] = useState(false);
  const handleOpenModalDeleteSupplier = (id) => {
    setdialogDeleteSupplier(true);
    setidProvider(id);
  };

  const handleCloseModalDeleteSupplier = () => {
    setdialogDeleteSupplier(false);
  };

  const handleOpenModalDeleteCategorySupplier = (id) => {
    setdialogDeleteCategorySupplier(true);
    setidCategory(id);
  };

  const handleCloseModalDeleteCategorySupplier = () => {
    setdialogDeleteCategorySupplier(false);
  };

  const handleOpenModalProveedor = (id) => {
    setmodalProvider(true);
    setidProvider(id);
  };
  const handleCloseModalProveedor = () => {
    setmodalProvider(false);
  };
  const handleOpenModalCategory = (id) => {
    setmodalCategory(true);
    setidCategory(id);
  };

  const handleCloseModalCategory = () => {
    setmodalCategory(false);
  };

  const handleOpenModalEditProveedor = (id) => {
    setmodalEditProvider(true);
    setidProvider(id);
  };
  const handleCloseModalEditProveedor = () => {
    setmodalEditProvider(false);
  };

  const handleOpenModalEditCategory = (id) => {
    setmodalEditCategory(true);
    setidCategory(id);
  };
  const handleCloseModalEditCategory = () => {
    setmodalEditCategory(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "businessname",
      headerName: "NOMBRE",
      flex: 1,
    },
    {
      field: "category",
      headerName: "CATEGORIA",
      flex: 1,
      renderCell: (params) => params.row.category?.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    {
      field: "group",
      headerName: "GRUPO",
      flex: 1,
      renderCell: (params) =>
        params.row.group.map((el) => el.description).join(","),
    },
    ,
    {
      field: "ope",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalProveedor(params.row.id)}
          />
          {user.admin ? (
            <>
              <CreateIcon
                color="info"
                onClick={() => handleOpenModalEditProveedor(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteSupplier(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsCategories = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
    },
    {
      field: "ope",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalCategory(params.row.id)}
          />
          {user.admin ? (
            <>
              <CreateIcon
                color="info"
                onClick={() => handleOpenModalEditCategory(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() =>
                  handleOpenModalDeleteCategorySupplier(params.row.id)
                }
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_DEPARTAMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DEPARTAMENT,
        });
        setAllDepartaments(data.getAllDepartaments);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_TYPES = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_TYPES,
          fetchPolicy: "network-only",
        });
        setAllTypes(data.getAllTypes);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_CATEGORIES = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CATEGORIES,
          fetchPolicy: "network-only",
        });
        setAllCategories(data.getAllCategories);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_BANKS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_BANK,
          fetchPolicy: "network-only",
        });
        setAllBank(data.getAllBank);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_SUPPLIER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SUPPLIER,
          fetchPolicy: "network-only",
        });
        setAllSupplier(data.getAllSuplier);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_GROUPS = async () => {
      try {
        const { data } = await client.query({
          query: GET_GROUPS,
          fetchPolicy: "network-only",
        });
        setAllGroups(data.getGroup);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_GET_ALL_TYPES();
    FUNCTION_GET_ALL_CATEGORIES();
    FUNCTION_GET_ALL_BANKS();
    FUNCTION_GET_ALL_SUPPLIER();
    FUNCTION_GET_ALL_DEPARTAMENT();
    FUNCTION_GET_ALL_GROUPS();
  }, [newProvider, newCategory]);
  return (
    <>
      <ModalUtil
        isOpen={modalProvider}
        onClose={handleCloseModalProveedor}
        Component={ViewProvider}
        sizewidth={900}
        params={null}
        id={idProvider}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalCategory}
        onClose={handleCloseModalCategory}
        Component={ViewCategory}
        params={null}
       
        id={idCategory}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditProvider}
        onClose={handleCloseModalEditProveedor}
        Component={EditForm}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewProvider,
            handleCloseModal: handleCloseModalEditProveedor,
            headerQuery: {
              query: GET_SUPPLIER_BY_ID,
              variables: {
                getProviderByIdId: idProvider,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: PROVIDER_SCHEMA_YUP,

            mutationEdit: EDIT_SUPPIER_BY_ID,
            paramEdit: "editSupplierByIdId",
            selectElement: {
              type: allTypes,
              category: allCategories,
              departament: allDepartaments,
              banksoles: allBank,
              bankdollar: allBank,
            },
          })
        }
        id={idProvider}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditCategory}
        onClose={handleCloseModalEditCategory}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewCategory,
            handleCloseModal: handleCloseModalEditCategory,
            headerQuery: {
              query: GET_CATEGORY_BY_ID,
              variables: {
                getCategoryByIdId: idCategory,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: PROVIDER_CATEGORY_SCHEMA_YUP,

            mutationEdit: EDIT_SUPPLIER_CATEGORY_BY_ID,
            paramEdit: "editSupplierCategoryByIdId",
          })
        }
        id={idCategory}
      ></ModalUtil>
      <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteSupplier}
        onClose={handleCloseModalDeleteSupplier}
        Component={DeleteForm}
        id={idProvider}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewProvider,
            paramsDelete: "deleteProviderByIdId",
            mutatitionDelete: DELETE_SUPPLIER_BY_ID,
            onClose: handleCloseModalDeleteSupplier,
          })
        }
      />

      <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteCategorySupplier}
        onClose={handleCloseModalDeleteCategorySupplier}
        Component={DeleteForm}
        id={idCategory}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewCategory,
            paramsDelete: "deleteProviderCategoryByIdId",
            mutatitionDelete: DELETE_SUPPLIER_CATEGORY_BY_ID,
            onClose: handleCloseModalDeleteCategorySupplier,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="LOGISTICA" subtitle="logistica/proveedor" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 8"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreateProveedor
              allTypes={allTypes}
              allCategories={allCategories}
              allDepartaments={allDepartaments}
              allBank={allBank}
              allGroups={allGroups}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              setNewProvider={setNewProvider}
            />
          </Box>
          <StatBox
            title="Cantidad de Proveedores"
            value={allSupplier.length}
            /*   increase={mainData?.cargo}
          description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <StatBox
            title="Cantidad de Categorias"
            value={allCategories.length}
            /* increase="+21%" */
            /* description="Since last month" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 4"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <CreateCategory
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              setNewCategory={setNewCategory}
            />
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE CATEGORIAS
            </Box>
            <DataGrid
              loading={!allCategories}
              getRowId={(row) => row.id}
              rows={allCategories || []}
              columns={columnsCategories}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          {/* ROW 2 */}
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE PROVEEDORES
            </Box>
            <DataGrid
              loading={!allSupplier}
              getRowId={(row) => row.id}
              rows={allSupplier || []}
              columns={columns}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Provider;
