import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import Logo from "../assets/logo.jpeg"
const NotFound = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary[500],
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "30rem",
          height: "content-fit",
          padding: "1rem",
          paddingBottom: "2rem",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
          boxShadow: 3,
          borderRadius: 1,
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "70%",
          }}
        />
        <Box>
          <Box sx={{textAlign:"center",fontSize:"8rem",fontWeight:"700"}}>404</Box>
          <Box sx={{textAlign:"center",fontSize:"1.8rem",fontWeight:"700"}}>Page not found</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
