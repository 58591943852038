import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBoxOne from "components/StatBoxOne";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CreateFuel from "./CreateFuel";
import CreateOther from "./CreateOther";
import ModalUtil from "components/ModalUtil";
import { GET_ALL_SUPPLIER } from "./../../logistic/provider/QueryProvider";
import {
  GET_OTHER_TYPE_EXPENSE,
  GET_TYPE_FUEL,
  GET_ALL_FLUEL,
  GET_ALL_OTHER_EXPENSE,
  GET_ALL_ACTIVE_VEHICLE,
  GET_ALL_DRIVER,
  GET_MAIN_DATA,
  DELETE_FUEL_BY_ID,
  DELETE_OTHER_EXPENSE_BY_ID
} from "./QueryOperation";
import DialogUtil from "components/DialogUtil";
import ViewFuel from "./ViewFuel";
import ViewOther from "./ViewOther";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
import { useUser } from "hooks/useUser";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import DeleteForm from "./DeleteForm";
const Operations = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newFuel, setNewFuel] = useState([]);
  const [newOtherExpense, setNewOtherExpense] = useState([]);
  const [active, setActive] = useState([]);
  const [driver, setDriver] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [typeFuel, setTypeFuel] = useState([]);
  const [typeOtherExpense, setTypeOtherExpense] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [otherExpense, setOtherExpense] = useState([]);
  const [modalOpenViewFuel, setModalOpenViewFuel] = useState(false);
  const { currency, dateFormat } = useSettings();
  const [modalOpenViewOther, setModalOpenViewOther] = useState(false);
  const [modalOpenDeleteFuel, setModalOpenDeleteFuel] = useState(false);
  const [modalOpenDeleteOtherExpense, setModalOpenDeleteOtherExpense] = useState(false);
  const { user } = useUser();
  const [id, setId] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const handleCloseModalFuel = () => {
    setModalOpenViewFuel(false);
  };

  const handleOpenModalViewFuel = (id) => {
    setModalOpenViewFuel(true);
    setId(id);
  };
  const handleCloseModalOther = () => {
    setModalOpenViewOther(false);
  };

  const handleOpenModalViewOther = (id) => {
    setModalOpenViewOther(true);
    setId(id);
  };


  const handleOpenModalDeleteFuel = (id) => {
    setModalOpenDeleteFuel(true);
    setId(id);
  };
  const handleCloseModalDeleteFuel = () => {
    setModalOpenDeleteFuel(false);    
  };

  const handleOpenModalDeleteOtherExpense = (id) => {
    setModalOpenDeleteOtherExpense(true);
    setId(id);
  };
  const handleCloseModalDeleteOtherExpense = () => {
    setModalOpenDeleteOtherExpense(false);    
  };
  const columnsFuel = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) => DateFormatting(params.row.date, dateFormat),
    },
    {
      field: "cost",
      headerName: "COSTO",
      flex: 1,
      renderCell: (params) => currency + " " + params.row.cost,
    },
    {
      field: "amount",
      headerName: "CANTIDAD",
      flex: 1,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewFuel(params.row.id)}
          />
          {user.admin ? (
            <>
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteFuel(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsOtherExpense = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "NOMBRE",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "CANTIDAD",
      flex: 1,
    },
    {
      field: "cost",
      headerName: "COSTO",
      flex: 1,
      renderCell: (params) => currency + " " + params.row.cost,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewOther(params.row.id)}
          />
         {user.admin ? (
            <>
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteOtherExpense(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_ACTIVE_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        setActive(data.getAllActiveVehicle);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_DRIVER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DRIVER,
          fetchPolicy: "network-only",
        });
        setDriver(data.getAllDriver);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    /*   const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setDriver(data.getAllPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    }; */
    const FUNCTION_GET_ALL_SUPPLIER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SUPPLIER,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllSuplier);
        setSupplier(data.getAllSuplier);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_TYPE_FUEL = async () => {
      try {
        const { data } = await client.query({
          query: GET_TYPE_FUEL,
          fetchPolicy: "network-only",
        });
        console.log(data.getTypeFuel);
        setTypeFuel(data.getTypeFuel);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_TYPE_OTHER_EXPENSE = async () => {
      try {
        const { data } = await client.query({
          query: GET_OTHER_TYPE_EXPENSE,
          fetchPolicy: "network-only",
        });
        console.log(data.getTypeExpense);
        setTypeOtherExpense(data.getTypeExpense);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_FUEL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_FLUEL,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllFuel);
        setFuel(data.getAllFuel);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_OTHER_EXPENSE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_OTHER_EXPENSE,
          fetchPolicy: "network-only",
        });
        setOtherExpense(data.getAllExpense);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumarizeOperation);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_ACTIVE_VEHICLE();
    FUNCTION_GET_ALL_DRIVER();
    FUNCTION_GET_ALL_SUPPLIER();
    FUNCTION_GET_TYPE_FUEL();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_TYPE_OTHER_EXPENSE();
    FUNCTION_GET_ALL_FUEL();
    FUNCTION_GET_ALL_OTHER_EXPENSE();
  }, [newFuel, newOtherExpense]);
  return (
    <>
    <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={modalOpenDeleteFuel}
        onClose={handleCloseModalDeleteFuel}
        Component={DeleteForm}
        id={id}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewFuel,
            paramsDelete: "deleteFuelByIdId",
            mutatitionDelete: DELETE_FUEL_BY_ID,
            onClose: handleCloseModalDeleteFuel,
          })
        }
      />
       <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={modalOpenDeleteOtherExpense}
        onClose={handleCloseModalDeleteOtherExpense}
        Component={DeleteForm}
        id={id}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewFuel,
            paramsDelete: "deleteOtherExpenseByIdId",
            mutatitionDelete: DELETE_OTHER_EXPENSE_BY_ID,
            onClose: handleCloseModalDeleteOtherExpense,
          })
        }
      />
      <ModalUtil
        isOpen={modalOpenViewFuel}
        onClose={handleCloseModalFuel}
        Component={ViewFuel}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalFuel,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewOther}
        onClose={handleCloseModalOther}
        Component={ViewOther}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalOther,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/operación" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Total Combustible"
            value={mainData?.amountFuel}
            /*   increase={mainData?.cargo} */
            description={"Cantidad de registros de combustible"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Combustible Mensual"
            value={mainData?.amountLastFuel}
            /*   increase={mainData?.cargo}*/
            description={"Cantidad de registros mensual"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Total Otros Gastos"
            value={mainData?.amountOther}
            /*   increase={mainData?.cargo} */
            description={"Cantidad de registros de combustible"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Otros Gastos Mensual"
            value={mainData?.amountLastOther}
            /*   increase={mainData?.cargo}*/
            description={"Cantidad de registros mensual"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 1 */}

          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              AGREGAR COMBUSTIBLE
            </Box>
            <CreateFuel
              active={active}
              setNewFuel={setNewFuel}
              driver={driver}
              supplier={supplier}
              typeFuel={typeFuel}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="0px"
            >
              HISTORIAL DE COMBUSTIBLE
            </Box>
            <DataGrid
              loading={!fuel}
              getRowId={(row) => row.id}
              rows={fuel || []}
              columns={columnsFuel}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              AGREGAR OTROS GASTOS
            </Box>
            <CreateOther
              active={active}
              driver={driver}
              typeOtherExpense={typeOtherExpense}
              alertCustom={alertCustom}
              setNewOtherExpense={setNewOtherExpense}
              setAlertCustom={setAlertCustom}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="0px"
            >
              HISTORIAL A OTROS GASTOS
            </Box>
            <DataGrid
              loading={!otherExpense}
              getRowId={(row) => row.id}
              rows={otherExpense || []}
              columns={columnsOtherExpense}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          {/*  <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          1
        </Box> */}
          {/*   <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          2
        </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Operations;
