import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import { CHILDREN_SCHEMA_YUP } from "./YupValidation";
import { CREATE_CHILDREN } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
const CreateChildrens = ({
  gender,
  setNewChildren,
  id,
  alertCustom,
  setAlertCustom,
  setErrorId,
}) => {

  const client = useApolloClient();

  const formikChildren = useFormik({
    initialValues: {
      personal: "",
      name: "",
      surname: "",
      dob: new Date(),
      gender: "",
      doi: "",
    },
    validationSchema: CHILDREN_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if(id){
        setErrorId(false);
        setSubmitting(true);
        const { name, surname, dob, gender, doi } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_CHILDREN,
            variables: {
              personal: id,
              name,
              surname,
              dob,
              gender,
              doi:Number(doi),
            },
          });
       
          setNewChildren(response.data.createChildren);       
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      }else{
        setErrorId(true);
      }
     
    },
  });
  return (
    <form onSubmit={formikChildren.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="name"
          name="name"
          label="NOMBRES"
          variant="outlined"
          value={formikChildren.values.name}
          onChange={formikChildren.handleChange}
          error={
            formikChildren.touched.name && Boolean(formikChildren.errors.name)
          }
          helperText={formikChildren.touched.name && formikChildren.errors.name}
        />
        <TextField
          id="surname"
          name="surname"
          label="APELLIDOS"
          variant="outlined"
          value={formikChildren.values.surname}
          onChange={formikChildren.handleChange}
          error={
            formikChildren.touched.surname &&
            Boolean(formikChildren.errors.surname)
          }
          helperText={
            formikChildren.touched.surname && formikChildren.errors.surname
          }
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikChildren.values.dob}
            id="dob"
            name="dob"
            label="FECHA DE NACIMIENTO"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikChildren.setFieldValue("dob", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">SEXO</InputLabel>
          <Select
            id="gender"
            value={formikChildren.values.gender}
            name="gender"
            label="SEXO"
            onChange={formikChildren.handleChange}
            error={
              formikChildren.touched.gender &&
              Boolean(formikChildren.errors.gender)
            }
          >
            {gender?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikChildren.touched.gender && formikChildren.errors.gender ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikChildren.touched.gender && formikChildren.errors.gender}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="doi"
          name="doi"
          label="DNI"
          variant="outlined"
          value={formikChildren.values.doi}
          onChange={formikChildren.handleChange}
          error={
            formikChildren.touched.doi && Boolean(formikChildren.errors.doi)
          }
          helperText={formikChildren.touched.doi && formikChildren.errors.doi}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikChildren.isSubmitting}>
        {formikChildren.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateChildrens;
