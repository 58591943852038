import * as Yup from "yup";
import mongoose from "mongoose";
export const JOBREFERENCE_SCHEMA_YUP = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").required("Requerido"),
  surname: Yup.string().min(3, "Too Short!").required("Required"),
  bossposition: Yup.string().required("Requiredo"),
  postulant: Yup.string(),
  phone: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo"),
  company: Yup.string().min(3, "Too Short!").required("Required"),
  recomended: Yup.boolean("Select this checkbox a please"),
  jobReference: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        criterion: Yup.string(),
      })
    )
    .required("Required"),
});
