import * as Yup from "yup";
export const POSTULANT_SCHEMA_YUP = Yup.object().shape({
  name: Yup.string()
    .min(3, "La longitud del campo es muy corto")
    .required("Requerido"),
  surname: Yup.string()
    .min(3, "La longitud del campo es muy corto")
    .required("Requerido"),
  /* doi: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000, "El número debe tener exactamente 8 dígitos")
    .max(99999999, "El número debe tener exactamente 8 dígitos")
    .required("Requerido"), */
  doi: Yup.string()
    .min(8, "La longitud del campo es muy corto")
    .max(8, "La longitud del campo es muy corto")
    .required("Requerido"),
  dob: Yup.date().required("Requerido"),
  email: Yup.string().email("Formato inválido"),
  phone: Yup.string(),
});
