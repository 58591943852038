import * as Yup from "yup";

export const SETTINGS_SCHEMA_YUP = Yup.object().shape({
  currency: Yup.string()
    .oneOf(["USD", "PEN"], "Selecciona una moneda válida")
    .required("La moneda es obligatoria"),

  dateFormat: Yup.string()
    .oneOf(["YYYY_MM_DD", "DD_MM_YYYY", "MM_DD_YYYY"], "Selecciona un formato de fecha válido")
    .required("El formato de fecha es obligatorio"),

  rounding: Yup.number()
    .required("El redondeo es obligatorio")
    .min(0, "El redondeo no puede ser negativo")
    .integer("El redondeo debe ser un número entero"),

});