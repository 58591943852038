import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormHelperText } from "@mui/material";
import {
  GET_DOCUMENT_ACTIVE_BY_ID,
  UPDATE_DOCUMENT_CADUCITY_EMITION_BY_ACTIVE_ID,
} from "./QueryDocument";
import { Height } from "@mui/icons-material";
import { DOCUMENT_SHOW_SCHEMA_YUP } from "./YupValidation";

const AssignDocument = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleCloseModal,
    setNewAssignRequeriment,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [documentFicha, setdocumentFicha] = useState(null);
  const formikDocument = useFormik({
    initialValues: {
      emitiondate: new Date(),
      caducitydate: new Date(),
      finaldate: new Date(),
    },
    validationSchema: DOCUMENT_SHOW_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const { emitiondate, caducitydate, finaldate } = values;
      try {
        const response = await client.mutate({
          mutation: UPDATE_DOCUMENT_CADUCITY_EMITION_BY_ACTIVE_ID,
          variables: {
            updateCaducityEmitionDateActiveId: id,
            emitiondate: emitiondate,
            finaldate: finaldate,
            caducitydate: caducitydate,
          },
          fetchPolicy: "network-only",
        });
        console.log(response.data.updateCaducityEmitionDateActive);
        setNewAssignRequeriment(response.data.updateCaducityEmitionDateActive);
        handleCloseModal();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    console.log(id);
    const getdocumentFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_ACTIVE_BY_ID,
          variables: {
            getDocumentActiveByIdId: id,
          },
        });
        console.log(data.getDocumentActiveById);
        setdocumentFicha(data.getDocumentActiveById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getdocumentFicha(id);
  }, []);
  return (
    visibility && (
      <form onSubmit={formikDocument.handleSubmit}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          mb={"20px"}
          bgcolor={"#525659"}
        >
          <Box
            width={"400px"}
            height={"500px"}
            border={"2px solid #000"}
            bgcolor={"#fff"}
          >
            <img
              src={documentFicha?.url}
              alt={documentFicha?.document.description}
              loading="lazy"
              width={"100%"}
            />
          </Box>

          {/*   <PDFViewer>
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  <Text>Section #1</Text>
                </View>
                <View style={styles.section}>
                  <Text>Section #2</Text>
                </View>
              </Page>
            </Document>
          </PDFViewer> */}
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          flexDirection={"column"}
          gap={"10px"}
        >
         
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
             <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikDocument.values.emitiondate}
              id="emitiondate"
              name="emitiondate"
              label="FECHA DE EMISION 
              / INICIAL"
              inputFormat="dd-MM-yyyy"
              onChange={(val) => {
                formikDocument.setFieldValue("emitiondate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={formikDocument.values.caducitydate}
                id="caducitydate"
                name="caducitydate"
                label="FECHA DE CADUCIDAD"
                inputFormat="dd-MM-yyyy"
                onChange={(val) => {
                  formikDocument.setFieldValue("caducitydate", val);
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
              />
            </LocalizationProvider>
            {formikDocument.touched.caducitydate &&
            formikDocument.errors.caducitydate ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikDocument.touched.caducitydate &&
                  formikDocument.errors.caducitydate}
              </FormHelperText>
            ) : null}
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikDocument.values.finaldate}
              id="finaldate"
              name="finaldate"
              label="FECHA FINAL"
              inputFormat="dd-MM-yyyy"
              onChange={(val) => {
                formikDocument.setFieldValue("finaldate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"10px"} gap={"10px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          <Button variant="contained" color="success" type="submit">
            GUARGAR
          </Button>
        </Box>
      </form>
    )
  );
};

export default AssignDocument;
