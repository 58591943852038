import { gql } from "@apollo/client";

export const GET_MAINTANCE_PREVENT = gql`
  query GetMaintanceRequerimentPrevent {
    getMaintanceRequerimentPrevent {
      id
      description
      date
      comment
    }
  }
`;

export const GET_ACTIVE_VEHICLE = gql`
  query GetActiveVehicle {
    getActiveVehicle {
      id
      guarante
      endgurante
      beginguarante
    }
  }
`;

export const GET_ACTIVE_BY_ID = gql`
  query GetActiveById($getActiveByIdId: ID) {
    getActiveById(id: $getActiveByIdId) {
      id
      parte
      plate
      serie
      state {
        description
      }    
      guarante
      description
      endgurante
      dateadquisition
      beginguarante
      brand
      condition {
        description
      }
      correlative
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeGuarante {
    getSumarizeGuarante {
      guaranteNoguarante
      guaranteguarante
      preventCount
      preventDescription
    }
  }
`;
