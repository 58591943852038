import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import LoopIcon from "@mui/icons-material/Loop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ruLocale from "date-fns/locale/ru";
import { OTHER_EXPENSE_SCHEMA_YUP } from "./YupValidation";
import { CREATE_OTHER_EXPENSE } from "./QueryOperation";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import dayjs from 'dayjs';
import { ChangeFormatDb } from "utils/DateFormatting";
const CreateOther = ({
  active,
  driver,
  typeOtherExpense,
  setNewOtherExpense,
  setAlertCustom,
  alertCustom,
}) => {
  const client = useApolloClient();
  const theme = useTheme();
  const { user } = useUser();
  const autocompleteactiveRef = useRef(null);
  const autocompletepersonalRef = useRef(null);
  const { currency,dateFormat } = useSettings(); 
  const formikOther = useFormik({
    initialValues: {
      active: {
        label: "",
        id: "",
      },
      driver: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      name: "",
      reference: "",
      type: "",
      date: new Date(),
      time: dayjs('2022-04-17T15:30'),
      amount: "",
      cost: "",
      total: "",
    },
    validationSchema: OTHER_EXPENSE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        active,
        driver,
        name,
        reference,
        type,
        date,
        time,
        amount,
        cost,
      } = values;
      try {
        const xtotal= parseFloat(Number(amount) * parseFloat(cost))  
        const response = await client.mutate({
          mutation: CREATE_OTHER_EXPENSE,
          variables: {
            active: active.id,
            driver: driver.id,
            type,
            date,
            name: name.toUpperCase(),
            reference: reference.toUpperCase(),
            time:time.$H+":"+time.$m,
            amount: Number(amount),
            cost: parseFloat(cost),
            total:Math.ceil(xtotal*100)/100,
          },
        });
        setNewOtherExpense(response.data.createOtherExpense);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        autocompleteactiveRef.current.value = "";
        if(user.admin){
          autocompletepersonalRef.current.value = "";
        }
       
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikOther.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box mb={"20px"}>
        {active?.length && (
          <Autocomplete
            freeSolo
            id="active"
            name="active"
            disableClearable
            ref={autocompleteactiveRef}
            value={formikOther.values.active.label}
            options={active?.map(
              (option) =>
                new Object({
                  label:                   
                    option.description +
                    " " +
                    "placa:" +
                    option.plate,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikOther.setFieldValue("active", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por descripcion/placa"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikOther.touched.active?.id &&
                  Boolean(formikOther.errors.active?.id)
                }
                helperText={
                  formikOther.touched.active?.id &&
                  formikOther.errors.active?.id
                }
              />
            )}
          />
        )}
      </Box>
     { user.admin ?<Box mb={"20px"}>
        {driver?.length ? (
          <Autocomplete
            freeSolo
            id="driver"
            name="driver"
            disableClearable
            ref={autocompletepersonalRef}
            value={formikOther.values.driver.label}
            options={driver?.map(
              (option) =>
                new Object({
                  label:                   
                    option.postulant.name +
                    " " +                
                    option.postulant.surname +
                    " / " +
                    "dni:" +
                    option.postulant.doi,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikOther.setFieldValue("driver", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre/apellido/dni"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikOther.touched.driver?.id &&
                  Boolean(formikOther.errors.driver?.id)
                }
                helperText={
                  formikOther.touched.driver?.id &&
                  formikOther.errors.driver?.id
                }
              />
            )}
          />
        ) : (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
      </Box>:null}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="name"
          name="name"
          label="NOMBRE DEL GASTO"
          variant="outlined"
          value={formikOther.values.name}
          onChange={formikOther.handleChange}
          error={formikOther.touched.name && Boolean(formikOther.errors.name)}
          helperText={formikOther.touched.name && formikOther.errors.name}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">TIPO DE GASTO</InputLabel>
          <Select
            id="type"
            value={formikOther.values.type}
            name="type"
           label="TIPO DE GASTO"
            onChange={formikOther.handleChange}
            error={formikOther.touched.type && Boolean(formikOther.errors.type)}
          >
            {typeOtherExpense?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikOther.touched.type && formikOther.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikOther.touched.type && formikOther.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="reference"
          name="reference"
          label="REFERENCIA"
          variant="outlined"
          value={formikOther.values.reference}
          onChange={formikOther.handleChange}
          error={
            formikOther.touched.reference &&
            Boolean(formikOther.errors.reference)
          }
          helperText={
            formikOther.touched.reference && formikOther.errors.reference
          }
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikOther.values.date}
            id="date"
            name="date"
            label="FECHA EN QUE SUCEDIO"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikOther.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="time"
            label="HORARIO"
            name="time"
            value={formikOther.values.time}
            onChange={(val) => {
              formikOther.setFieldValue("time", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <TextField
          id="amount"
          name="amount"
          label="CANTIDAD (EN GALONES)"
          variant="outlined"
         
          value={formikOther.values.amount}
          onChange={formikOther.handleChange}
          error={
            formikOther.touched.amount && Boolean(formikOther.errors.amount)
          }
          helperText={formikOther.touched.amount && formikOther.errors.amount}
        />
        <FormControl>
          <InputLabel htmlFor="salary">PRECIO (POR GALONES)</InputLabel>
          <OutlinedInput
            id="cost"
            startAdornment={
              <InputAdornment position="start">{currency} </InputAdornment>
            }
            name="cost"
            label="PRECIO (POR GALONES)"
            variant="outlined"
            value={formikOther.values.cost}
            onChange={formikOther.handleChange}
            error={formikOther.touched.cost && Boolean(formikOther.errors.cost)}
          />
          {formikOther.touched.cost && formikOther.errors.cost ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikOther.touched.cost && formikOther.errors.cost}
            </FormHelperText>
          ) : null}
        </FormControl>

        <TextField
          label="TOTAL"
          value={currency + " " + formikOther.values.amount * formikOther.values.cost}
          disabled
        />
        {/*   <TextField
          id="total"
          name="total"
          label="TOTAL"
          variant="outlined"
          value={formikOther.values.total}
          onChange={formikOther.handleChange}
          error={formikOther.touched.total && Boolean(formikOther.errors.total)}
          helperText={formikOther.touched.total && formikOther.errors.total}
        /> */}
      </Box>

      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikOther.isSubmitting}
        >
          {formikOther.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateOther;
