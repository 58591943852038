import * as Yup from "yup";

export const USER_SCHEMA_YUP = Yup.object().shape({
  role: Yup.string()
    .oneOf(
      [
        "ADMIN_GENERAL",
        "ADMIN_GESTION_DE_PERSONAS",
        "ADMIN_LOGISTICA",
        "ADMIN_OPERACIONES",
        "ADMIN_SEGURIDAD",
        "ADMIN_MANTENIMIENTO",
        "ADMIN_REQUERIMIENTO",
        "USUARIO_GENERICO",
      ],
      "Selecciona un rol válido"
    )
    .required("El rol es obligatorio"),

  submodules: Yup.array()
    .required("este campo es obligatorio, seleccione al menos 1 opcion")
    .min(1, "debe seleccionar al menos un valor"),
  enabled: Yup.boolean().required("requerido"),
  admin: Yup.boolean().required("requerido"),
});

export const USER_UPDATE_SCHEMA_YUP = Yup.object().shape({
  role: Yup.string().oneOf(
    [
      "ADMIN_GENERAL",
      "ADMIN_GESTION_DE_PERSONAS",
      "ADMIN_LOGISTICA",
      "ADMIN_OPERACIONES",
      "ADMIN_SEGURIDAD",
      "ADMIN_MANTENIMIENTO",
      "ADMIN_REQUERIMIENTO",
      "USUARIO_GENERICO",
    ],
    "Selecciona un rol válido"
  ),

  submodules: Yup.array()
    .required("este campo es obligatorio, seleccione al menos 1 opcion")
    .min(1, "debe seleccionar al menos un valor"),
});
