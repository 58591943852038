import * as Yup from "yup";
export const ITEM_SCHEMA_YUP = Yup.object().shape({
  classs: Yup.string().required("Requirido"),
  group: Yup.string().required("Requirido"),
  description: Yup.string()
    .min(5, "Debe tener minimo 5 caracteres")
    .required("Requirido"),
  coloquial: Yup.string(),
  unity: Yup.string().required("Requirido"),
  specification:Yup.string()
});

export const ACTIVE_SCHEMA_YUP = Yup.object().shape({
  serie: Yup.string(),
  parte: Yup.string(),
  dateadquisition: Yup.date().required("Requiredo"),
  item: Yup.string().required("Requiredo"),
  condition: Yup.string().required("Requiredo"),
  guarante: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(1, "El numero debe ser mayor a 1")
    .max(240, "El número debe ser menor")
    .required("Requiredo"),
  checkbuyorder: Yup.string().required("Este campo es obligatorio"),
  buyorder: Yup.string().when("checkbuyorder", {
    is: (val) => val === "true",
    then: () => Yup.string().required("Este campo es obligatorio"),
    otherwise: () => Yup.string(),
  }),
  beginguarante: Yup.date().required("Requiredo"),
  amount: Yup.number()
  .integer("El número debe ser un número entero")
  .positive("El número debe ser positivo")
  .min(1, "El numero debe ser mayor a 1")
  .required("Requiredo"),
  brand: Yup.string(),
  plate: Yup.string(),
  itembuyorder: Yup.string().when("checkbuyorder", {
    is: (val) => val === "true",
    then: () => Yup.array().of(Yup.string()).min(1).max(1),
    otherwise: () => Yup.array().of(Yup.string()),
  }),
  // itembuyorder: Yup.array().of(Yup.string()).min(1).max(1),
  //itembuyorder: Yup.array().of(Yup.string()),
});

export const ASSEMBLY_SCHEMA_YUP = Yup.object().shape({
  type: Yup.string().required("Requirido"),
  vehicle: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  novehicle: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
});


export const ACTIVE_EDIT_SCHEMA_YUP = Yup.object().shape({
  serie: Yup.string().min(3, "Too Short!").required("Requirido"),
  parte: Yup.string().min(3, "Too Short!").required("Requirido"),
  dateadquisition: Yup.date().required("Requiredo"), 
  condition: Yup.string().required("Requiredo"),
  guarante: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(1, "El numero debe ser mayor a 1")
    .max(240, "El número debe ser menor")
    .required("Requiredo"),  
  beginguarante: Yup.date().required("Requiredo"),
  unity: Yup.string().required("Requiredo"),
  brand: Yup.string().required("Requirido"),
  plate: Yup.string().required("Requirido"),  
});
