import { useQuery } from "@apollo/client"
import { GET_SETTINGS } from "graphql/SettingsQuery"

export const useSettings = () => {

  const { data, loading } = useQuery(GET_SETTINGS)

  return {
    currency: data?.getSettings.currency,
    dateFormat: data?.getSettings.dateFormat,
    rounding: data?.getSettings.rounding,
    isLoading: loading
  }
}