import { gql } from "@apollo/client";
export const GET_JOBREFERENCE_BY_POSTULANT_ID = gql`
  query GetAllJobReferenceByPostulantId(
    $getAllJobReferenceByPostulantIdId: ID
  ) {
    getAllJobReferenceByPostulantId(id: $getAllJobReferenceByPostulantIdId) {
      id
      name
      surname
      recomended
      company
      bossposition 
    }
  }
`;

export const GET_BOSS_POSITION = gql`
  query GetBossPosition {
    getBossPosition {
      id
      description
    }
  }
`;

export const GET_JOBREFERENCE_CRITERION = gql`
  query GetCriterionJobReference {
    getCriterionJobReference {
      id
      description
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSummarizeJobReference($getSummarizeJobReferenceId: ID) {
    getSummarizeJobReference(id: $getSummarizeJobReferenceId) {
      cargo
      company
      recomended
      referent
    }
  }
`;

export const CREATE_JOBREFERENCE_MUTATION = gql`
  mutation CreateJobReference(
    $name: String
    $surname: String
    $bossposition: String
    $postulant: ID
    $phone: String
    $company: String
    $recomended: Boolean
    $jobReference: [jobReferenceCriterionInput]
  ) {
    createJobReference(
      name: $name
      surname: $surname
      bossposition: $bossposition
      postulant: $postulant
      phone: $phone
      company: $company
      recomended: $recomended
      jobReference: $jobReference
    ) {
      id
      name
      surname
      recomended
      company
    }
  }
`;

export const GET_JOB_REFERENCE_BY_ID = gql`
  query GetJobReferenceById($getJobReferenceByIdId: ID) {
    getJobReferenceById(id: $getJobReferenceByIdId) {
      company
      bossposition
      id
      name
      phone
      postulant {
        doi
        name
        surname
      }
      recomended
      surname
      criterionQuestion {
        criterion {
          description
        }
        answer
      }
    }
  }
`;

export  const DELETE_JOBREFERENCE_BY_ID = gql`
mutation DeleteJobReference($deleteJobReferenceId: ID) {
  deleteJobReference(id: $deleteJobReferenceId) {
    id
  }
}`;