import { gql } from "@apollo/client";
export const GET_PRIORITIES = gql`
  query GetPriority {
    getPriority {
      id
      description
    }
  }
`;

export const CREATE_ITEM_REQUERIMENT = gql`
  mutation CreateRequerimentIncome(
    $incomeRequerimentItem: [IncomeRequerimentItem]
    $subject: String
    $priority: ID
    $date: Date
    $costcenter:ID
    $area: String
    $personal: ID
  ) {
    createRequerimentIncome(
      incomeRequerimentItem: $incomeRequerimentItem
      subject: $subject
      priority: $priority
      costcenter:$costcenter
      date: $date
      area: $area
      personal: $personal
    ) {
      id
      date
      subject
    }
  }
`;
export const GET_ITEM_REQUERIMENT = gql`
 query GetItemRequeriment($acro: String) {
  getItemRequeriment(acro: $acro) {
      id
      date
      code
      priority {
        description
      }
      state {
        description
      }
    area {
      id
      description
      acro
    }
  }
}
`;
export const GET_REQUERIMENT_FOR_ORDER_BUY = gql`
  query GetItemRequerimentForBuyOrder {
    getItemRequerimentForBuyOrder {
      id
      date
      code
      priority {
        description
      }
      state {
        description
      }
    }
  }
`;

export const UPDATE_ITEM_REQUERIMENT = gql`
mutation UpdateRequerimentItem($requeriment: ID, $data: [RequerimentItem], $dataconsumable: [RequerimentActive]) {
  updateRequerimentItem(requeriment: $requeriment, data: $data, dataconsumable: $dataconsumable) {
    id
    date
    subject
  }
}
`;
export const GET_ITEM_AND_ACTIVE_FOR_REQUERIMENT = gql`
query GetItemsAndActiveForRequeriment($getItemsAndActiveForRequerimentId: ID) {
  getItemsAndActiveForRequeriment(id: $getItemsAndActiveForRequerimentId) {
    requeriment {
      amount
        complete
        current
        item {
          id
          description
          active
        }
    }
    item {
      unity {
        description
        id
      }
      description
      amountfree
      active
      id
    }
    consumable
    active {
      id
      item {      
        description
        unity {
          description
          id
        }
        active
      }
    }
  }
}
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeRequerimentActive($area: String) {
    getSumarizeRequerimentActive(area: $area) {
      aproved
      begining
      pending
    }
  }
`;

export const GET_ITEM_REQUERIMENT_BY_ID = gql`
query GetItemAndRequerimentById($getItemAndRequerimentByIdId: ID) {
  getItemAndRequerimentById(id: $getItemAndRequerimentByIdId) {
        subject
      priority {
        description
      }
      observation
      date
      area {
        description
      }
      item {
        active
        amount
        complete
        current
        description
        id
        observation
        unity {
          description
        }
      }
    costcenter {
      description
    }
    personal {
      postulant {
        name
        surname
        doi
      }
    }
  }
}
`;
