export const DateFormatting = (timestamp, format) => {
  const date = new Date(timestamp);
if(!timestamp||!format){
  return "";
}
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if (format.toLowerCase() === "yyyy_mm_dd") return `${year}/${month}/${day}`;

  if (format.toLowerCase() === "dd_mm_yyyy") return `${day}/${month}/${year}`;

  if (format.toLowerCase() === "mm_dd_yyyy") return `${month}/${day}/${year}`;
};
export const ChangeFormatDb = (format) => {
  const dateFormat = format
    .replaceAll("_", "/")
    .replaceAll("Y", "y")
    .replaceAll("D", "d");

  return dateFormat;
};
