import * as Yup from "yup";
const FILE_SIZE = 1024 * 1024 * 2; // 2MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const FILE_SIZE_ERROR_MESSAGE = "El archivo debe ser menor a 2MB";
const FILE_FORMAT_ERROR_MESSAGE = "Formato de archivo no soportado";

export const DOCUMENT_SCHEMA_YUP = Yup.object().shape({
  /*   filesx: Yup.array().of(
    Yup.object().shape({
      filex: Yup.mixed()

        .test(
          "fileSize",
          FILE_SIZE_ERROR_MESSAGE,
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          FILE_FORMAT_ERROR_MESSAGE,
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        )
        .required("A file is required"),
    })
  ), */
  file: Yup.mixed()
    .test(
      "fileSize",
      FILE_SIZE_ERROR_MESSAGE,
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      FILE_FORMAT_ERROR_MESSAGE,
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    )
    .required("A file is required"),
});

export const DOCUMENT_SHOW_SCHEMA_YUP = Yup.object().shape({
  emitiondate: Yup.date().required("Requiredo"),
  caducitydate: Yup.date()
    .required("Required")
    .test(
      "valid-date",
      "La fecha de caducidad no puede ser inferior a la fecha de emisión",
      function (value) {
        const emitionDate = this.resolve(Yup.ref("emitiondate"));
        return !emitionDate || !value || value >= emitionDate;
      }
    ),
});
