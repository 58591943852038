import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const IndicartorsBar = ({ data, categories }) => {
  
  const [chartData, setChartData] = useState({
    series: JSON.parse(JSON.stringify(data)),

    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },       
      },      
      dataLabels: {       
        style: {       
          colors: ['#000']
        }
      },
      stroke: {
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      yaxis: {
        min: 0,
      },
      xaxis: {
        categories: categories,
      },

    },
  });
  useEffect(() => {
    setChartData({
      ...chartData,
      series: JSON.parse(JSON.stringify(data)),
      options: {
        ...chartData.options,
        xaxis: {
          categories: categories,
        },
      },
    });
  }, [data]);
  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default IndicartorsBar;
