import React, { useState, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { GET_ACTIVE_BY_ID } from "./QueryGuarante";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
const ViewActive = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  useEffect(() => {
    const getItemRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVE_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            getActiveByIdId: id,
          },
        });
        console.log(data.getActiveById);
        setItemRequeriment(data.getActiveById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequeriment(id);
  }, []);

  const GetPorcent = async (endgurante) => {
    const startDate = new Date();
    const endDate = new Date(endgurante);

    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthDiff = endDate.getMonth() - startDate.getMonth();

    const totalMonths = yearDiff * 12 + monthDiff;
    if (totalMonths < 0) {
      return <Box>Garantia Vencida</Box>;
    }
    const porc = (totalMonths * 100) / params.row.guarante;

    return (
      <Box width={"100%"} height={"20px"} border={"1px solid #ccc"}>
        <Box width={porc} height={"100%"} backgroundColor={"#1aaf91"}>
          {porc.toFixed(0) + "%"}{" "}
        </Box>
      </Box>
    );
  };
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">ACTIVO</label>
            <TextField disabled value={itemRequeriment?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PLACA</label>
            <TextField disabled value={itemRequeriment?.plate} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PARTE</label>
            <TextField disabled value={itemRequeriment?.parte} />
          </Box>
          <Box sx={style}>
            <label for="my-input">SERIE</label>
            <TextField disabled value={itemRequeriment?.serie} />
          </Box>

          <Box sx={style}>
            <label for="my-input">FECHA DE ADQUISICION</label>
            <TextField
              disabled
              value={new Date(
                itemRequeriment?.dateadquisition
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">INICIO DE GARANTIA</label>
            <TextField
              disabled
              value={new Date(
                itemRequeriment?.beginguarante
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FIN DE GARANTIA</label>
            <TextField
              disabled
              value={new Date(itemRequeriment?.endgurante).toLocaleDateString(
                "es-ES"
              )}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">GARANTIA</label>
            <TextField disabled value={itemRequeriment?.guarante} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
       {/*    <GetPorcent endgurante={itemRequeriment?.endgurante}></GetPorcent> */}

          {/*   {
             const startDate = new Date();
             const endDate = new Date(params.row.endgurante);
     
             const yearDiff = endDate.getFullYear() - startDate.getFullYear();
             const monthDiff = endDate.getMonth() - startDate.getMonth();
     
             const totalMonths = yearDiff * 12 + monthDiff;
             if (totalMonths < 0) {
               return <Box>Garantia Vencida</Box>;
             }
             const porc = (totalMonths * 100) / params.row.guarante;
     
             return (
               <Box width={"100%"} height={"20px"} border={"1px solid #ccc"}>
                 <Box width={porc} height={"100%"} backgroundColor={"#1aaf91"}>
                   {porc.toFixed(0) + "%"}{" "}
                 </Box>
               </Box>
             );
          } */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewActive;
