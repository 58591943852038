import React from "react";
import { useState, useEffect } from "react";
import { GET_POSTULANT_BY_ID } from "./QueryPostulant";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import LoopIcon from "@mui/icons-material/Loop";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
const ViewPostulant = ({ id, onClose, params }) => {
  const { setAlertCustom, alertCustom } = params;
  const client = useApolloClient();
  const [postulant, setPostulant] = useState({});
  const { dateFormat } = useSettings();
  useEffect(() => {
    const getPostulantById = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSTULANT_BY_ID,
          variables: {
            getPostulantByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        setPostulant(data.getPostulantById);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getPostulantById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    <>
      {postulant ? (
        <>
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label for="my-input">NOMBRES</label>
              <TextField disabled value={postulant?.name} />
            </Box>
            <Box sx={style}>
              <label for="my-input">APELLIDOS</label>
              <TextField disabled value={postulant?.surname} />
            </Box>
            <Box sx={style}>
              <label for="my-input">EMAIL</label>
              <TextField disabled value={postulant?.email} />
            </Box>
            <Box sx={style}>
              <label for="my-input">DNI</label>
              <TextField disabled value={postulant?.doi} />
            </Box>
            <Box sx={style}>
              <label for="my-input">FECHA DE NACIMIENTO</label>
              <TextField
                disabled
                value={DateFormatting(postulant.dob, dateFormat)}
                /*  value={new Date(postulant.dob).toLocaleDateString("es-ES")} */
              />{" "}
            </Box>
            <Box sx={style}>
              <label for="my-input">ESTADO</label>
              <TextField disabled value={postulant?.state?.description} />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
            <Button variant="contained" onClick={onClose}>
              CERRAR
            </Button>
            {/*  */}
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoopIcon />
        </Box>
      )}
    </>
  );
};

export default ViewPostulant;
