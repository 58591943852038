import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MuiFileInput } from "mui-file-input";
import { Box, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import { FormHelperText } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { CREATE_DOCUMENT_ACTIVE } from "./QueryDocument";
import TextField from "@mui/material/TextField";
const CreateDocument = ({
  newDocument,
  setNewDocument,
  data,
  alertCustom,
  setAlertCustom,
  id,
}) => {
  const [files, setFiles] = useState(data.map(() => null)); // crear un array de estados con el mismo número de elementos que los datos
  const FILE_SIZE = 1024 * 1024; // 2MB
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE_ERROR_MESSAGE = "El archivo debe ser menor a 2MB";
  const FILE_FORMAT_ERROR_MESSAGE = "Formato de archivo no soportado";
  const handleFileChange = (newFile, index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = newFile;
      return newFiles;
    });
  };
  const client = useApolloClient();
  const checkFile = (file) => {
    if (SUPPORTED_FORMATS.includes(file.type) && file.size < FILE_SIZE) {
      return true;
    }
    return false;
  };
  const handleSubmit = async (index, document, kind) => {
    if (files[index]) {
      if (!checkFile(files[index])) {
        setError(true);
        console.log("formato o peso superior a 2MB");
      } else {
        try {
          setError(false);
          setLoading(true);
          const response = await client.mutate({
            mutation: CREATE_DOCUMENT_ACTIVE,
            variables: {
              active: id,
              document: document,
              filex: files[index],
            },
          });

          console.log(response.data);

          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = null;
            return newFiles;
          });

          setNewDocument(response.data.createActiveDocument);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setLoading(false);
        }
      }
    } else {
      setError(true);
      console.log("no tiene files");
    }
  };
  return (
    <Box>
      <TableContainer style={{ maxHeight: 750, overflowY: "auto" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="">N°</TableCell>
              <TableCell align="">Documento</TableCell>
              <TableCell align="">Tipo</TableCell>
              <TableCell align="">Archivo</TableCell>
              <TableCell align=""></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="">{index + 1}</TableCell>
                <TableCell align="">{row.description}</TableCell>
                <TableCell align="">{row.kind}</TableCell>
                <TableCell align="">
                  {/*   <MuiFileInput
                  name={`filesx[${index}].filex`}
                  value={formikDocuments.values.filesx?.[index]?.filex}                   
                  onChange={(newFile) => handleFileChange(newFile, index)}
                  error={
                    formikDocuments.touched.filesx?.[index]?.filex &&
                    Boolean(formikDocuments.errors.filex?.[index]?.filex)
                  }
                  helperText={
                    formikDocuments.touched.filesx?.[index]?.filex &&
                    formikDocuments.errors.filesx?.[index]?.filex ? (
                      <FormHelperText
                        sx={{
                          color: "#bf3333",
                          marginLeft: "16px !important",
                        }}
                      >
                        {formikDocuments.touched.filesx?.[index]?.filex &&
                          formikDocuments.errors.filesx?.[index]?.filex}
                      </FormHelperText>
                    ) : null
                  }
                />
*/}
                  <MuiFileInput
                    value={files[index]}
                    onChange={(newFile) => handleFileChange(newFile, index)}
                  />
                  {/*    {!Array.isArray(formikDocuments.errors.filesx)&&formikDocuments.errors.filesx ? (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    {
                      formikDocuments.errors.filesx}
                  </FormHelperText>
                ) : null} */}
                </TableCell>
                <TableCell align="">
                  {!loading && (
                    <Button
                      onClick={() => handleSubmit(index, row.id, row.kind)}
                      color="success"
                    >
                      <SaveIcon sx={{ cursor: "pointer" }} />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {error && (
        <FormHelperText
          sx={{ color: "#bf3333", marginLeft: "16px !important" }}
        >
          <p
            style={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            Verica los campos esten requeridos o el tamaño del archivo
          </p>
        </FormHelperText>
      )}
      {/*   <Box display={"flex"} justifyContent={"end"}>       
      <Button variant="contained" color="success" type="submit">
        GUARGAR
      </Button>
    </Box> */}
    </Box>
  );
};

export default CreateDocument;
