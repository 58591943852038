import React, { useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { FormHelperText } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SOMNOLENCY_SCHEMA_YUP } from "./YupSomnolency";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import { CREATE_SOMNOLENCY } from "./QuerySomnolency";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import { useUser } from "hooks/useUser";
const CreateSomnolency = ({
  setAlertCustom,
  alertCustom,
  question,
  operator,
  evaluator,
  setNewSomnolency,
}) => {
  const { dateFormat } = useSettings();
  const client = useApolloClient();
  const { user } = useUser();
  const autocompleteevaluatorRef = useRef(null);
  const autocompleteoperatorRef = useRef(null);
  const formikSomnolency = useFormik({
    initialValues: {
      evaluator: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      operator: {
        label: "",
        id: "",
      },
      project: "",
      date: new Date(),
      timeSleep: "",
      timeElapsed: "",
      criterion:
        "" /* question.map(
        (it) => new Object({ answer: false, question: "", observation: "" })
      ) */,
    },
    validationSchema: SOMNOLENCY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      /*  console.log(values); */
      setSubmitting(true);
      const {
        evaluator,
        operator,
        project,
        date,
        timeSleep,
        timeElapsed,
        criterion,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_SOMNOLENCY,
          variables: {
            evaluator: evaluator.id,
            operator: operator.id,
            project: project.trim().toUpperCase(),
            date,
            timeSleep: Number(timeSleep),
            timeElapsed: Number(timeElapsed),
            criterion,
          },
        });

        setNewSomnolency(response.createSomnolency);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if (user.admin) {
          autocompleteevaluatorRef.current.value = "";
        }
        autocompleteoperatorRef.current.value = "";
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikSomnolency.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
          mb={"20px"}
        >
          {user.admin ? (
            <Box>
              {evaluator?.length ? (
                <Autocomplete
                  freeSolo
                  id="evaluator"
                  name="evaluator"
                  ref={autocompleteevaluatorRef}
                  value={formikSomnolency.values.evaluator.label}
                  disableClearable
                  options={evaluator?.map(
                    (option) =>
                      new Object({
                        label:
                          option.postulant.name +
                          " " +
                          option.postulant.surname +
                          " / " +
                          "dni:" +
                          option.postulant.doi,
                        value: option.id,
                      })
                  )}
                  onChange={(event, newValue) => {
                    formikSomnolency.setFieldValue("evaluator", {
                      label: newValue.label,
                      id: newValue.value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar evaluador por nombre/dni"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={
                        formikSomnolency.touched.evaluator?.id &&
                        Boolean(formikSomnolency.errors.evaluator?.id)
                      }
                      helperText={
                        formikSomnolency.touched.evaluator?.id &&
                        formikSomnolency.errors.evaluator?.id
                      }
                    />
                  )}
                />
              ) : (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    No hay Datos para mostrar
                  </p>
                </FormHelperText>
              )}
            </Box>
          ) : null}
          <Box>
            {operator?.length && (
              <Autocomplete
                freeSolo
                id="operator"
                name="operator"
                disableClearable
                ref={autocompleteoperatorRef}
                value={formikSomnolency.values.operator.label}
                options={operator?.map(
                  (option) =>
                    new Object({
                      label:
                        option.postulant.name +
                        "  " +
                        option.postulant.surname +
                        " / " +
                        "dni:" +
                        option.postulant.doi,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikSomnolency.setFieldValue("operator", {
                    label: newValue.label,
                    id: newValue.value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar operador por nombre/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={
                      formikSomnolency.touched.operator?.id &&
                      Boolean(formikSomnolency.errors.operator?.id)
                    }
                    helperText={
                      formikSomnolency.touched.operator?.id &&
                      formikSomnolency.errors.operator?.id
                    }
                  />
                )}
              />
            )}
          </Box>
        </Box>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="20px">
          <TextField
            id="project"
            name="project"
            label="PROYECTO/OPERACION"
            variant="outlined"
            value={formikSomnolency.values.project}
            onChange={formikSomnolency.handleChange}
            error={
              formikSomnolency.touched.project &&
              Boolean(formikSomnolency.errors.project)
            }
            helperText={
              formikSomnolency.touched.project &&
              formikSomnolency.errors.project
            }
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikSomnolency.values.date}
              id="date"
              name="date"
              label="FECHA"
              format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
              onChange={(val) => {
                formikSomnolency.setFieldValue("date", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <TextField
            id="timeSleep"
            name="timeSleep"
            label="HORAS TRANSCURRIDAS"
            variant="outlined"
            value={formikSomnolency.values.timeSleep}
            onChange={formikSomnolency.handleChange}
            error={
              formikSomnolency.touched.timeSleep &&
              Boolean(formikSomnolency.errors.timeSleep)
            }
            helperText={
              formikSomnolency.touched.timeSleep &&
              formikSomnolency.errors.timeSleep
            }
          />
          <TextField
            id="timeElapsed"
            name="timeElapsed"
            label="HORAS DORMIDAS"
            variant="outlined"
            value={formikSomnolency.values.timeElapsed}
            onChange={formikSomnolency.handleChange}
            error={
              formikSomnolency.touched.timeElapsed &&
              Boolean(formikSomnolency.errors.timeElapsed)
            }
            helperText={
              formikSomnolency.touched.timeElapsed &&
              formikSomnolency.errors.timeElapsed
            }
          />
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Si</TableCell>
                  <TableCell align="">No</TableCell>
                  <TableCell align="">Observaciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {question.map((row, index) => (
                  /*        <RadioGroup
                    name={`criterion[${index}].answer`}
                    value={formikSomnolency.values.criterion?.[index]?.answer}
                    onChange={(e) => {
                      formikSomnolency.setFieldValue(
                        `criterion[${index}].answer`,
                        Boolean(e.target.value)
                      );
                      formikSomnolency.setFieldValue(
                        `criterion[${index}].question`,
                        row.id
                      );
                      console.log(formikSomnolency.touched.criterion);
                    }}
                  > */
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikSomnolency.values.criterion?.[index]?.answer ===
                          true
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].answer`,
                            Boolean(e.target.value)
                          );
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].question`,
                            row.id
                          );
                        }}
                        value={true}
                        name={`criterion[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikSomnolency.values.criterion?.[index]?.answer ===
                          false
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].answer`,
                            Boolean(!e.target.value)
                          );
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].question`,
                            row.id
                          );
                        }}
                        value={false}
                        name={`criterion[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <TextField
                        name={`criterion[${index}].observation`}
                        variant="outlined"
                        value={
                          formikSomnolency.values.criterion?.[index]
                            ?.observation
                        }
                        onChange={(e) => {
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].observation`,
                            e.target.value
                          );
                          formikSomnolency.setFieldValue(
                            `criterion[${index}].question`,
                            row.id
                          );
                        }}
                        error={
                          formikSomnolency.touched.criterion?.[index]
                            ?.observation &&
                          Boolean(
                            formikSomnolency.errors.criterion?.[index]
                              ?.observation
                          )
                        }
                        helperText={
                          formikSomnolency.touched.criterion?.[index]
                            ?.observation &&
                          formikSomnolency.errors.criterion?.[index]
                            ?.observation
                        }
                      />
                    </TableCell>
                  </TableRow>
                  /* </RadioGroup> */
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {formikSomnolency.touched.criterion &&
          formikSomnolency.errors.criterion?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Verica los campos esten requeridos
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikSomnolency.isSubmitting}
          >
            {formikSomnolency.isSubmitting ? <RotatingLoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateSomnolency;
