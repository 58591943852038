import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const IndicatorsMain = ({ data, month }) => {

  const [chartData, setChartData] = useState({
    series: JSON.parse(JSON.stringify(data)),
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      yaxis: {
        min: 0,
      
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      xaxis: {
        categories:
          month == -1
            ? [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Oct",
                "Nov",
                "Dic",
              ]
            : data[0]?.data.map((el, index) => "Semana" + (index + 1)),
      },
    },
  });
  useEffect(() => {
    setChartData({
      ...chartData,
      series: JSON.parse(JSON.stringify(data)),
      options: {
        ...chartData.options,
        xaxis: {
          categories:
            month == -1
              ? [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Oct",
                  "Nov",
                  "Dic",
                ]
              : data[0]?.data.map((el, index) => "Semana" + (index + 1)),
        },
      },
    });
  }, [data]);
  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </>
  );
};

export default IndicatorsMain;
