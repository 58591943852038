import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Radio from "@mui/material/Radio";
import TableRow from "@mui/material/TableRow";
import {
  GET_PROFESSION_BY_ID,
  GET_PROFESSION_BY_PERSONAL_ID,
  EDIT_PROFESSION_BY_ID
} from "./QueryPersonal";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { PROFESION_YUP } from "./YupValidation";
import LoopIcon from '@mui/icons-material/Loop';
import FormControl from "@mui/material/FormControl";

const EditProfesion = ({ params }) => {
  const {
    handleCloseModal,
    professionLevel,
    professionState,
    setAlertCustom,
    alertCustom,
    setNewRegister,
    idPersonal
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [professionFicha, setprofessionFicha] = useState(null);
  const [oneprofession, setoneprofession] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null); // Estado para rastrear el radio seleccionado
  const [component, setComponent] = useState(false);
 
  const isDate = (numero) => {
    return !isNaN(new Date(numero).getTime());
  };
  const converObj = (obj) => {
    const resultado = {};
    for (const clave in obj) {
      if (typeof obj[clave] === "object" && obj[clave] !== null) {
        if (Array.isArray(obj[clave])) {
          resultado[clave] = obj[clave].map(
            (el) =>
              new Object({
                answer: el.answer,
                sentence: el.sentence.id,
              })
          );
        } else {
          resultado[clave] = obj[clave].id;
        }
      } else if (typeof obj[clave] === "number" && isDate(obj[clave])) {
        resultado[clave] = new Date(obj[clave]);
      } else {
        resultado[clave] = obj[clave];
      }
    }
    return resultado;
  };
  const handleChangeRadio = async (event) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
    const { data } = await client.query({
      query: GET_PROFESSION_BY_ID,
      variables: {
        getProfessionByIdId: event.target.value,
      },
      fetchPolicy: "network-only",
    });

    console.log(data);
    setoneprofession(data.getProfessionById);
    setComponent(true);
  };

  useEffect(() => {
    const getprofessionFicha = async (idPersonal) => {
      try {
        const { data } = await client.query({
          query: GET_PROFESSION_BY_PERSONAL_ID,
          variables: {
            getProfessionByPersonalIdId: idPersonal,
          },
          fetchPolicy: "network-only",
        });    
        setprofessionFicha(data.getProfessionByPersonalId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getprofessionFicha(idPersonal);
  }, []);
  const formik = useFormik({
    initialValues: converObj(oneprofession),
    validationSchema: PROFESION_YUP,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      if (selectedValue) {
        setSubmitting(true);
        const { id,description, level, state, place } = values;
        try {
          const response = await client.mutate({
            mutation: EDIT_PROFESSION_BY_ID,
            variables: {
              editProfessionByIdId:id ,
              personal:idPersonal,
              description: description.toUpperCase(),
              level,
              state,
              place: place.toUpperCase(),
            },
          });
          setNewRegister(response.data.editProfessionById);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          handleCloseModal();
          resetForm();
          
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <form onSubmit={formik.handleSubmit}>
        {" "}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">DESCRIPCION</TableCell>
                  <TableCell align="">NIVEL</TableCell>
                  <TableCell align="">ESTADO</TableCell>
                  <TableCell align="">LUGAR</TableCell>
                  <TableCell align=""></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(professionFicha)}
                {professionFicha.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">{row.description}</TableCell>
                      <TableCell align="">{row.level.description}</TableCell>
                      <TableCell align="">{row.state?.description}</TableCell>
                      <TableCell align="">{row.place}</TableCell>
                      <TableCell align="">
                        <Radio
                          onChange={handleChangeRadio}
                          name="groupprofession"
                          value={row.id} // Valor único para este radio
                          checked={selectedValue === row.id}
                        />
                      </TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {console.log(formik.values)}
        {component && (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label>DESCRIPCION</label>
              <TextField
                id="description"
                name="description"
                variant="outlined"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>

            <Box sx={style}>
              {formik.values.level && (
                <FormControl fullWidth>
                  <label for="my-input">NIVEL</label>
                  <Select
                    id="level"
                    value={formik.values.level}
                    name="level"
                    onChange={(e) => {
                      formik.setFieldValue("level", e.target.value);
                    }}
                  >
                    {professionLevel?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.level && formik.errors.level ? (
                    <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                    >
                      {formik.touched.level && formik.errors.level}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Box>
            <Box sx={style}>
              {formik.values.level && (
                <FormControl fullWidth>
                  <label for="my-input">ESTADO</label>
                  <Select
                    id="state"
                    value={formik.values.state}
                    name="state"
                    onChange={(e) => {
                      formik.setFieldValue("state", e.target.value);
                    }}
                  >
                    {professionState?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.state && formik.errors.state ? (
                    <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                    >
                      {formik.touched.state && formik.errors.state}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Box>
            <Box sx={style}>
              <label for="my-input">LUGAR</label>
              <TextField
                id="place"
                name="place"
                variant="outlined"
                value={formik.values.place}
                onChange={formik.handleChange}
                error={formik.touched.place && Boolean(formik.errors.place)}
                helperText={formik.touched.place && formik.errors.place}
              />
            </Box>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"} gap={"15px"}>
          <Button variant="contained" color="success" type="submit"  disabled={formik.isSubmitting}>
          {formik.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </form>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default EditProfesion;
