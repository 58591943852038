import React, { useState,useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { INDICENT_SCHEMA_YUP } from "./YupSintoIncident";
import { CREATE_INCIDENT } from "./QuerySintoIncident";
import RotatingLoopIcon from './../../utils/RotatingLoopIcon'; 
import { useUser } from "hooks/useUser";
const CreateIncident = ({
  alertCustom,
  setAlertCustom,
  listPersonal,
  listTypeControl,
  setNewIncident,
}) => {
  const client = useApolloClient();
  const { user } = useUser();
  const autocompletepersonalRef = useRef(null);
  const formikIncident = useFormik({
    initialValues: {
      personal: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      description: "",
      type: "",
      date: new Date(),
    },
    validationSchema: INDICENT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { personal, description, type, date } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_INCIDENT,
          variables: {
            personal:personal.id,
            description: description.toUpperCase(),
            type,
            date,
          },
        });
        setNewIncident(response.data.createIncident);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if(user.admin){
          autocompletepersonalRef.current.value=""
        }
        

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikIncident.handleSubmit}>
     { user.admin?<Box mb={"20px"}>
        {listPersonal?.length ? (
          <Autocomplete
            freeSolo
            id="personal"
            name="personal"
            disableClearable
            ref={autocompletepersonalRef}
            value={formikIncident.values.personal.label}
            options={listPersonal?.map(
              (option) =>
                new Object({
                  label:                    
                    option.postulant.name +
                    " " +                  
                    option.postulant.surname +
                    " / " +
                    "dni:" +
                    option.postulant.doi,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikIncident.setFieldValue("personal", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre/apellido/dni"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikIncident.touched.personal?.id &&
                  Boolean(formikIncident.errors.personal?.id)
                }
                helperText={
                  formikIncident.touched.personal?.id &&
                  formikIncident.errors.personal?.id
                }
              />
            )}
          />
        ): (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
      </Box>:null}

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
      >
        <TextField
          id="description"
          name="description"
          label="DESCRIPCION DE LA ACCIÓN"
          variant="outlined"
          value={formikIncident.values.description}
          onChange={formikIncident.handleChange}
          error={
            formikIncident.touched.description &&
            Boolean(formikIncident.errors.description)
          }
          helperText={
            formikIncident.touched.description &&
            formikIncident.errors.description
          }
        />
      </Box>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">TIPO DE CONTROL</InputLabel>
          <Select
            id="type"
            value={formikIncident.values.type}
            name="type"
            onChange={formikIncident.handleChange}
            error={
              formikIncident.touched.type && Boolean(formikIncident.errors.type)
            }
          label="TIPO DE CONTROL"
          >
            {listTypeControl?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikIncident.touched.type && formikIncident.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikIncident.touched.type && formikIncident.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikIncident.values.date}
            id="date"
            name="date"
            label="FECHA DE CIERRE"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikIncident.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
      </Box>

      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit"  disabled={formikIncident.isSubmitting}>
        {formikIncident.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateIncident;
