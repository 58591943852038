import * as Yup from "yup";
export const ITEM_ORDER_BUY_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  requeriment: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  area: Yup.string(),
  /*   date: Yup.date().required("Requirido"), */
  priority: Yup.string().required("Requirido"),
  subject: Yup.string().required("Requirido"),
  igv: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requirido")
    .min(0, "El porcentaje debe ser mayor a 0")
    .max(100, "El igv no puede superar el 100%"),
  buyorderItem: Yup.array().of(Yup.string().required("Requiredo")).min(1),
});

export const BUY_ORDER_ASSIGN_SCHEMA_YUP = Yup.object().shape({
  subtotal: Yup.number()
    .positive("El número debe ser positivo")
    /* .required("Requirido") */
    .min(0, "El porcentaje debe ser mayor a 0"),
  /*  .max(100, "El igv no puede superar el 100%"), */
  total: Yup.number()
    .positive("El número debe ser positivo")
    /* .required("Requirido") */
    .min(0, "El porcentaje debe ser mayor a 0"),
  /*  .max(100, "El igv no puede superar el 100%"), */
  provider:Yup.string().required("Requirido"),
 /*  condition: Yup.string().required("Requirido"), */
  /*   date: Yup.date().required("Requirido"), */
  currenc: Yup.string().required("Requirido"),
  emitiondate: Yup.date().required("Requirido"),
  attentiondate: Yup.date().required("Requirido"),
  formpay: Yup.string(),
  conditionpay: Yup.string(),
  items: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      unitvalue: Yup.number()
        .required("Requirido")
        .positive("El número debe ser positivo")
        .min(0, "El porcentaje debe ser mayor a 0"),   
      condition: Yup.string(),
      amount: Yup.number().required("Requirido")
        .positive("El número debe ser positivo")
        .min(0, "El porcentaje debe ser mayor a 0"),
      discount: Yup.number().required("Requirido")
        .positive("El número debe ser positivo")
        .min(0, "El porcentaje debe ser mayor a 0")
        .max(100, "El porcentaje maximo es 100"),
      total: Yup.number().required("Requirido")
        .positive("El número debe ser positivo")
        .min(0, "El porcentaje debe ser mayor a 0"),
    })
  ),
});
