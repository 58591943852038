import * as Yup from "yup";
export const WORK_ASSIGN_YUP = Yup.object().shape({
  state: Yup.string().required("Requiredo"),
});

export const WORK_ASSIGN_PLANNED_YUP = Yup.object().shape({
  state: Yup.string().required("Requiredo"),
  date: Yup.date().required("Requiredo"),
});

export const WORK_ASSIGN_PROGRAMED_YUP = Yup.object().shape({
  state: Yup.string().required("Requiredo"),
  date: Yup.date().required("Requiredo"),
  timeBegin: Yup.string().required("Requerido"),
  timeEnd:  Yup.string()
  .required("Requerido")
  .test('is-greater', 'Horario Final debe ser mayor que timeBegin', function (value) {

    const { timeBegin } = this.parent; // Obtén el valor de timeBegin

    console.log(timeBegin)
    console.log(value)
    if (!timeBegin || !value) {
      // Si uno de los campos está vacío, no hagas la validación
      return true;
    }
    // Convierte las cadenas de tiempo en minutos para comparar
    const timeBeginMinutes = convertToMinutes(timeBegin);
    const timeEndMinutes = convertToMinutes(value);

   
    // Compara si timeEnd es mayor que timeBegin
    return timeEndMinutes > timeBeginMinutes;
  }),
});

function convertToMinutes(timeString) {
  const [,,,, timePart] = timeString.split(' '); // Elimina el día de la semana y obtén la parte de la hora
  console.log(timePart)
  const [hours, minutes] = timePart.split(':');
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
}