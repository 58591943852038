import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBox from "components/StatBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CreatePersonal from "../personal/CreatePersonal";
import CreateProfesion from "./CreateProfesion";
import CreateIdiom from "./CreateIdiom";
import CreateSpouse from "./CreateSpouse";
import CreateParents from "./CreateParents";
import { useApolloClient } from "@apollo/client";
import CreateChildrens from "./CreateChildrens";
import CreateExtraData from "./CreateExtraData";
import CreateContact from "./CreateContact";
import CreatePoliceRecord from "./CreatePoliceRecord";
import CreateMovility from "./CreateMovility";
import { FormHelperText } from "@mui/material";
import {
  GET_ALL_DEPARTAMENT,
  GET_ALL_CIVIL_STATUS,
  GET_ALL_GENDER,
  GET_ALL_CONTEXTURE,
  GET_ALL_PROFESSION_LEVEL,
  GET_ALL_PROFESSION_STATE,
  GET_ALL_PENSION_FUNDS,
  GET_ALL_PENSION_TYPE,
  GET_ALL_LANGUAGE,
  GET_ALL_LANGUAGE_LEVEL,
  GET_ALL_CONTACT_TYPE,
  GET_ALL_MOVILITY_TYPE,
  GET_ALL_SENTENCE_POLICE_RECORD,
  GET_FICHA_PERSONAL,
} from "./QueryPersonal";
import { GET_ALL_PERSONAL } from "./../FichaPersonal/QueryPersonal";
/* import ViewPersonal from "./ViewPersonal"; */
import ViewChildren from "./ViewChildren";
import ViewSpouse from "./ViewSpouse";
import ViewParents from "./ViewParents";
import ViewExtra from "./ViewExtra";
import ViewContact from "./ViewContact";
import ViewMovility from "./ViewMovility";
import ViewPoliceRecord from "./ViewPoliceRecord";
import ViewProfession from "./ViewProfession";
import ViewLanguage from "./ViewLanguage";
import EditSpouse from "./EditSpouse";
import EditForm from "./EditForm";
import { useUser } from "hooks/useUser";
import {
  GET_PARENT_BY_PERSONAL_ID,
  EDIT_PARENTS_BY_ID,
  GET_EXTRA_BY_PERSONAL_ID,
  EDIT_EXTRA_DATA_BY_ID,
  EDIT_CONTACT_BY_ID,
  EDIT_MOVILITY_BY_ID,
  GET_CONTACT_BY_PERSONAL_ID,
  GET_MOVILITY_BY_PERSONAL_ID,
  GET_POLICYRECORD_BY_PERSONAL_ID,
  EDIT_POLICE_RECORD_BY_ID,
} from "./QueryPersonal";
import {
  PARENTS_SCHEMA_YUP,
  EXTRA_SCHEMA_YUP,
  MOVILITY_SCHEMA_YUP,
  CONTACTTYPE_SCHEMA_YUP,
  POLICE_RECORD_SCHEMA_YUP,
} from "./YupValidation";
import EditChildren from "./EditChildren";
import EditProfesion from "./EditProfesion";
import EditIdioms from "./EditIdioms";
import EditPersonal from "../personal/EditPersonal";
import ViewPersonal from "../personal/ViewPersonal";
const FichaPersonal = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [fichaPersonal, setFichaPersonal] = useState([]);
  // const [allPostulant, setAllPostulant] = useState([]);
  const [personal, setPersonal] = useState([]);
  const client = useApolloClient();
  const [departament, setDepartament] = useState([]);
  const { user } = useUser();
  const [civilstatus, setCivilStatus] = useState([]);
  const [gender, setGender] = useState([]);
  const [contexture, setContexture] = useState([]);

  const [professionLevel, setProfessionLevel] = useState([]);
  const [professionState, setProfessionState] = useState([]);

  const [pensionFund, setPensionFund] = useState([]);
  const [pensionType, setPensionType] = useState([]);
  const [errorId, setErrorId] = useState(false);
  const [language, setLanguage] = useState([]);
  const [languageLevel, setLanguageLevel] = useState([]);
  const [id, setId] = useState(null);
  const [contactType, setContactType] = useState([]);
  const [movilityType, setMovilityType] = useState([]);
  const [newPoliceRecord, setNewPoliceRecord] = useState([]);
  const [sentencePoliceRecord, setSentencePoliceRecord] = useState([]);

  const [newPersonal, setNewPersonal] = useState(null);
  const [newSpouse, setNewSpouse] = useState(null);
  const [newParents, setNewParents] = useState(null);
  const [newExtraData, setNewExtraData] = useState(null);
  const [newContact, setNewContact] = useState(null);
  const [newMovility, setNewMovilty] = useState(null);

  const [
    requerimentselectedViewPersonal,
    setrequerimentselectedViewPersonal,
  ] = useState(null);
  //const [viewcomponent, setViewComponent] = useState(null);

  const [newNewChildren, setNewChildren] = useState(null);
  const [newProfession, setNewProfession] = useState(null);
  const [newIdiom, setNewIdiom] = useState(null);

  const [modalOpenViewPersonal, setModalOpenViewPersonal] = useState(false);
  const [modalOpenEditPersonal, setModalOpenEditPersonal] = useState(false);
  const [modalEditSpousePersonal, setModalEditSpousePersonal] = useState(false);
  const [modalEditParentPersonal, setModalEditParentPersonal] = useState(false);
  const [modalEditExtraPersonal, setModalEditExtraPersonal] = useState(false);
  const [modalEditContactPersonal, setModalEdiContactePersonal] = useState(
    false
  );
  const [modalEditMovilityPersonal, setModalEditMovilityPersonal] = useState(
    false
  );
  const [
    modalEditPoliceRecordPersonal,
    setModalEditSpPoliceRecordrsonal,
  ] = useState(false);
  const [modalEditChildrenPersonal, setModalEditSpousePeChildren] = useState(
    false
  );
  const [modalEditProfesionPersonal, setModalEditProfesionPersonal] = useState(
    false
  );
  const [modalEditLanguagePersonal, setModalEditLanguagePersonal] = useState(
    false
  );

  const handleOpenModalViewPersonal = (id) => {
    setModalOpenViewPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewPersonal = () => {
    setModalOpenViewPersonal(false);
  };

  const handleOpenModalEditSpouse = (id) => {
    setModalEditSpousePersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditSpouse = () => {
    setModalEditSpousePersonal(false);
  };

  const handleOpenModalEditParent = (id) => {
    setModalEditParentPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditParent = () => {
    setModalEditParentPersonal(false);
  };
  const handleOpenModalEditExtra = (id) => {
    setModalEditExtraPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditExtra = () => {
    setModalEditExtraPersonal(false);
  };
  const handleOpenModalEditContact = (id) => {
    setModalEdiContactePersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditContact = () => {
    setModalEdiContactePersonal(false);
  };
  const handleOpenModalEditMovility = (id) => {
    setModalEditMovilityPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditMovility = () => {
    setModalEditMovilityPersonal(false);
  };
  const handleOpenModalEditPoliceRecord = (id) => {
    setModalEditSpPoliceRecordrsonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditPoliceRecord = () => {
    setModalEditSpPoliceRecordrsonal(false);
  };
  const handleOpenModalEditChildren = (id) => {
    setModalEditSpousePeChildren(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditChildren = () => {
    setModalEditSpousePeChildren(false);
  };
  const handleOpenModalEditProfession = (id) => {
    setModalEditProfesionPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditProfession = () => {
    setModalEditProfesionPersonal(false);
  };
  const handleOpenModalEditLanguage = (id) => {
    setModalEditLanguagePersonal(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalEditLanguage = () => {
    setModalEditLanguagePersonal(false);
  };
  const [modalOpenViewSpouse, setModalOpenViewSpouse] = useState(false);
  const handleOpenModalViewSpouse = (id) => {
    setModalOpenViewSpouse(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewSpouse = () => {
    setModalOpenViewSpouse(false);
  };
  const [modalOpenViewParent, setModalOpenViewParent] = useState(false);
  const handleOpenModalViewParent = (id) => {
    setModalOpenViewParent(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewParent = () => {
    setModalOpenViewParent(false);
  };
  /**/
  const [modalOpenViewExtra, setModalOpenViewExtra] = useState(false);
  const handleOpenModalViewExtra = (id) => {
    setModalOpenViewExtra(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewExtra = () => {
    setModalOpenViewExtra(false);
  };
  const [modalOpenViewContact, setModalOpenViewContact] = useState(false);
  const handleOpenModalViewContact = (id) => {
    setModalOpenViewContact(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewContact = () => {
    setModalOpenViewContact(false);
  };
  const [modalOpenViewMovility, setModalOpenViewMovility] = useState(false);
  const handleOpenModalViewMovility = (id) => {
    setModalOpenViewMovility(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewCMovility = () => {
    setModalOpenViewMovility(false);
  };
  const [modalOpenViewPoliceRecord, setModalOpenViewPoliceRecord] = useState(
    false
  );
  const handleOpenModalViewPoliceRecord = (id) => {
    setModalOpenViewPoliceRecord(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewPoliceRecord = () => {
    setModalOpenViewPoliceRecord(false);
  };
  const [modalOpenViewChildren, setModalOpenViewChildren] = useState(false);
  const handleOpenModalViewChildren = (id) => {
    setModalOpenViewChildren(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewChildren = () => {
    setModalOpenViewChildren(false);
  };
  const [modalOpenViewProfession, setModalOpenViewProfession] = useState(false);
  const handleOpenModalViewProfession = (id) => {
    setModalOpenViewProfession(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewProfession = () => {
    setModalOpenViewProfession(false);
  };
  const [modalOpenViewLanguage, setModalOpenViewLanguage] = useState(false);
  const handleOpenModalViewLanguage = (id) => {
    setModalOpenViewLanguage(true);
    setrequerimentselectedViewPersonal(id);
  };
  const handleCloseModalViewLanguage = () => {
    setModalOpenViewLanguage(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenViewPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };

  const handleCloseModalView = () => {
    setModalOpenViewPersonal(false);
  };
  const handleOpenModalEdit = (id) => {
    setModalOpenEditPersonal(true);
    setrequerimentselectedViewPersonal(id);
  };

  const handleCloseModalEdit = () => {
    setModalOpenEditPersonal(false);
  };
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.type) + 1,
    },
    {
      field: "description",
      headerName: "INFORMACION",
      flex: 1,
    },
    {
      field: "value",
      headerName: "COMPLETADO",
      flex: 1,
      renderCell: (params) => (params.row.value.length ? "SI" : "NO"),
    },

    {
      field: "ope",
      headerName: "",
      renderCell: (params) =>
        params.row.value.length ? (
          <Box display={"flex"} gap={"5px"}>
            <VisibilityIcon
              onClick={() => {
                switch (params.row.category) {
                  case 1:
                    handleOpenModalView(params.row.personal);
                    break;
                  case 2:
                    handleOpenModalViewSpouse(params.row.personal);
                    break;
                  case 3:
                    handleOpenModalViewParent(params.row.personal);
                    break;
                  case 4:
                    handleOpenModalViewExtra(params.row.personal);
                    break;
                  case 5:
                    handleOpenModalViewContact(params.row.personal);
                    break;
                  case 6:
                    handleOpenModalViewMovility(params.row.personal);
                    break;
                  case 7:
                    handleOpenModalViewPoliceRecord(params.row.personal);
                    break;
                  case 8:
                    handleOpenModalViewChildren(params.row.personal);
                    break;
                  case 9:
                    handleOpenModalViewProfession(params.row.personal);
                    break;
                  case 10:
                    handleOpenModalViewLanguage(params.row.personal);
                    break;
                  default:
                    break;
                }
              }}
            />
            {user.admin ? (
              <CreateIcon
                color="info"
                onClick={() => {
                  switch (params.row.category) {
                    case 1:
                      handleOpenModalEdit(params.row.personal);
                      break;
                    case 2:
                      handleOpenModalEditSpouse(params.row.personal);
                      break;
                    case 3:
                      handleOpenModalEditParent(params.row.personal);
                      break;
                    case 4:
                      handleOpenModalEditExtra(params.row.personal);
                      break;
                    case 5:
                      handleOpenModalEditContact(params.row.personal);
                      break;
                    case 6:
                      handleOpenModalEditMovility(params.row.personal);
                      break;
                    case 7:
                      handleOpenModalEditPoliceRecord(params.row.personal);
                      break;
                    case 8:
                      handleOpenModalEditChildren(params.row.personal);
                      break;
                    case 9:
                      handleOpenModalEditProfession(params.row.personal);
                      break;
                    case 10:
                      handleOpenModalEditLanguage(params.row.personal);
                      break;
                    default:
                      break;
                  }
                }}
              />
            ) : null}
          </Box>
        ) : null,
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
          fetchPolicy: "network-only",
        });
        console.log(data);
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_DEPARTAMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DEPARTAMENT,
        });
        setDepartament(data.getAllDepartaments);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_CIVIL_STATUS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CIVIL_STATUS,
        });
        setCivilStatus(data.getCivilStatus);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_GENDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_GENDER,
        });
        setGender(data.getGender);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_CONTEXTURE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CONTEXTURE,
        });
        setContexture(data.getContexture);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_PROFESSION_LEVEL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PROFESSION_LEVEL,
        });
        setProfessionLevel(data.getProfessionLevel);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_PROFESSION_STATE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PROFESSION_STATE,
        });
        setProfessionState(data.getProfessionState);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_PENSION_FUNDS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PENSION_FUNDS,
        });
        setPensionFund(data.getPensionFund);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_PENSION_TYPE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PENSION_TYPE,
        });
        setPensionType(data.getPensionType);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_LANGUAGE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_LANGUAGE,
        });
        setLanguage(data.getLanguage);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_LANGUAGE_LEVEL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_LANGUAGE_LEVEL,
        });
        setLanguageLevel(data.getLanguageLevel);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_CONTACTTYPE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CONTACT_TYPE,
        });
        setContactType(data.getContactTypes);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_MOVILITYTYPE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_MOVILITY_TYPE,
        });
        setMovilityType(data.getMovilityTypes);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_SENTENCE_POLICE_RECORD = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SENTENCE_POLICE_RECORD,
        });
        setSentencePoliceRecord(data.getSentencePoliceRecord);
      } catch (error) {
        console.log(error.message);
      }
    };

    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_GET_ALL_DEPARTAMENT();
    FUNCTION_GET_ALL_CIVIL_STATUS();
    FUNCTION_GET_ALL_GENDER();
    FUNCTION_GET_ALL_CONTEXTURE();
    FUNCTION_GET_ALL_PROFESSION_STATE();
    FUNCTION_GET_ALL_PROFESSION_LEVEL();
    FUNCTION_GET_ALL_PENSION_FUNDS();
    FUNCTION_GET_ALL_PENSION_TYPE();
    FUNCTION_GET_ALL_LANGUAGE();
    FUNCTION_GET_ALL_LANGUAGE_LEVEL();
    FUNCTION_GET_ALL_CONTACTTYPE();
    FUNCTION_GET_ALL_MOVILITYTYPE();
    FUNCTION_GET_ALL_SENTENCE_POLICE_RECORD();
    FUNCTION_GET_PERSONAL_ATRIBUTES(user.admin ? id : user.personal.id);
  }, [
    newPoliceRecord,
    newMovility,
    newContact,
    newExtraData,
    newParents,
    newSpouse,
    newProfession,
    newPersonal,
    newIdiom,
    newNewChildren,
    id,
  ]);

  const FUNCTION_GET_PERSONAL_ATRIBUTES = async (id) => {
    try {
      console.log(id);
      const { data } = await client.query({
        query: GET_FICHA_PERSONAL,
        variables: {
          getFichaPersonalId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setFichaPersonal(data.getFichaPersonal);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleCloseAutocomplete = (event, reason) => {
    if (reason === "remove-option") {
      setFichaPersonal([]);
    }
  };
  return (
    <>
      {" "}
      {/*   <ModalUtil
        isOpen={modalOpenViewPersonal}
        onClose={handleCloseModalViewPersonal}
        Component={ViewPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewPersonal,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil> */}
      <ModalUtil
        isOpen={modalOpenViewPersonal}
        onClose={handleCloseModalView}
        Component={ViewPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenEditPersonal}
        onClose={handleCloseModalEdit}
        Component={EditPersonal}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewPersonal,
            civilstatus: civilstatus,
            gender: gender,
            departament: departament,
            contexture: contexture,
            handleCloseModal: handleCloseModalEdit,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewSpouse}
        onClose={handleCloseModalViewSpouse}
        Component={ViewSpouse}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewSpouse,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewParent}
        onClose={handleCloseModalViewParent}
        Component={ViewParents}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewParent,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewExtra}
        onClose={handleCloseModalViewExtra}
        Component={ViewExtra}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewExtra,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewContact}
        onClose={handleCloseModalViewContact}
        Component={ViewContact}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewContact,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewMovility}
        onClose={handleCloseModalViewCMovility}
        Component={ViewMovility}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewCMovility,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewPoliceRecord}
        onClose={handleCloseModalViewPoliceRecord}
        Component={ViewPoliceRecord}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewPoliceRecord,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewChildren}
        onClose={handleCloseModalViewChildren}
        Component={ViewChildren}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewChildren,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewProfession}
        onClose={handleCloseModalViewProfession}
        Component={ViewProfession}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewProfession,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewLanguage}
        onClose={handleCloseModalViewLanguage}
        Component={ViewLanguage}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewLanguage,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditSpousePersonal}
        onClose={handleCloseModalEditSpouse}
        Component={EditSpouse}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewSpouse,
            handleCloseModal: handleCloseModalEditSpouse,
            departament: departament,
            professionLevel: professionLevel,
            professionState: professionState,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditChildrenPersonal}
        onClose={handleCloseModalEditChildren}
        Component={EditChildren}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewChildren,
            handleCloseModal: handleCloseModalEditChildren,
            gender: gender,
            idPersonal: requerimentselectedViewPersonal,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditProfesionPersonal}
        onClose={handleCloseModalEditProfession}
        Component={EditProfesion}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewProfession,
            handleCloseModal: handleCloseModalEditProfession,
            professionLevel: professionLevel,
            professionState: professionState,
            idPersonal: requerimentselectedViewPersonal,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditLanguagePersonal}
        onClose={handleCloseModalEditLanguage}
        Component={EditIdioms}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewIdiom,
            handleCloseModal: handleCloseModalEditLanguage,
            language: language,
            languageLevel: languageLevel,
            idPersonal: requerimentselectedViewPersonal,
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditParentPersonal}
        onClose={handleCloseModalEditParent}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewParents,
            handleCloseModal: handleCloseModalEditParent,
            headerQuery: {
              query: GET_PARENT_BY_PERSONAL_ID,
              variables: {
                getParentByPersonalIdId: requerimentselectedViewPersonal,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: PARENTS_SCHEMA_YUP,
            mutationEdit: EDIT_PARENTS_BY_ID,
            paramEdit: "editParentsByIdPersonalId",
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditExtraPersonal}
        onClose={handleCloseModalEditExtra}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewExtraData,
            handleCloseModal: handleCloseModalEditExtra,
            headerQuery: {
              query: GET_EXTRA_BY_PERSONAL_ID,
              variables: {
                getExtraByPersonalIdId: requerimentselectedViewPersonal,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: EXTRA_SCHEMA_YUP,
            mutationEdit: EDIT_EXTRA_DATA_BY_ID,
            paramEdit: "editExtraDataByIdPersonalId",
            selectElement: {
              pensionfund: pensionFund,
              type: pensionType,
            },
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditContactPersonal}
        onClose={handleCloseModalEditContact}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewContact,
            handleCloseModal: handleCloseModalEditContact,
            headerQuery: {
              query: GET_CONTACT_BY_PERSONAL_ID,
              variables: {
                getContactByPersonalIdId: requerimentselectedViewPersonal,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: CONTACTTYPE_SCHEMA_YUP,
            mutationEdit: EDIT_CONTACT_BY_ID,
            paramEdit: "editContactByIdPersonalId",
            selectElement: {
              relation: contactType,
            },
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditMovilityPersonal}
        onClose={handleCloseModalEditMovility}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewMovilty,
            handleCloseModal: handleCloseModalEditMovility,
            headerQuery: {
              query: GET_MOVILITY_BY_PERSONAL_ID,
              variables: {
                getMovilityByPersonalIdId: requerimentselectedViewPersonal,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: MOVILITY_SCHEMA_YUP,
            mutationEdit: EDIT_MOVILITY_BY_ID,
            paramEdit: "editMovilityByIdPersonalId",
            selectElement: {
              type: movilityType,
              own: [
                { id: true, description: "SI" },
                { id: false, description: "NO" },
              ],
            },
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEditPoliceRecordPersonal}
        onClose={handleCloseModalEditPoliceRecord}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewPoliceRecord,
            handleCloseModal: handleCloseModalEditPoliceRecord,
            headerQuery: {
              query: GET_POLICYRECORD_BY_PERSONAL_ID,
              variables: {
                getPolicyRecordByPersonalIdId: requerimentselectedViewPersonal,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: POLICE_RECORD_SCHEMA_YUP,
            mutationEdit: EDIT_POLICE_RECORD_BY_ID,
            paramEdit: "editPoliceRecordByPersonalIdId",
            selectElement: {
              have: [
                { id: true, description: "SI" },
                { id: false, description: "NO" },
              ],
            },
            header: {
              policerecordsentence: [
                { title: "Nº" },
                { title: "Descripcion" },
                { title: "Respuesta" },
              ],
            },
          })
        }
        id={requerimentselectedViewPersonal}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="GESTION DE PERSONAS" subtitle="personal/ficha personal" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 8"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {user.admin ? (
              <>
                <Box
                  fontSize={"12px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  py="20px"
                >
                  BUSCAR PERSONAL
                </Box>
                {personal.length ? (
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={personal?.map(
                      (option) =>
                        new Object({
                          label:
                            option.postulant.name +
                            " " +
                            option.postulant.surname +
                            " / " +
                            "dni:" +
                            option.postulant.doi,
                          value: option.id,
                        })
                    )}
                    onClose={handleCloseAutocomplete}
                    onChange={(event, newValue) => {
                      setId(newValue.value);
                      FUNCTION_GET_PERSONAL_ATRIBUTES(newValue.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar por nombre/apellido/dni"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                ) : (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      No hay Datos para mostrar
                    </p>
                  </FormHelperText>
                )}
                {errorId && (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      Verica los campos esten requeridos
                    </p>
                  </FormHelperText>
                )}
              </>
            ) : null}
          </Box>

          <StatBox
            title="Has completado"
            value={
              user.admin
                ? id
                  ? fichaPersonal?.filter((el) => el.value.length !== 0).length
                  : ""
                : fichaPersonal?.filter((el) => el.value.length !== 0).length
            }
            /*   increase={mainData?.cargo}*/
            description={"bloques de la Ficha Personal"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <StatBox
            title="Te falta"
            value={
              user.admin
                ? id
                  ? fichaPersonal?.filter((el) => el.value.length === 0).length
                  : ""
                : fichaPersonal?.filter((el) => el.value.length === 0).length
            }
            /* increase="+21%" */
            description="bloques de la Ficha Personal"
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "spouse")?.value?.length ? (
              <>
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  DATOS DE CONYUGUE
                </Box>
                <CreateSpouse
                  departament={departament}
                  alertCustom={alertCustom}
                  setAlertCustom={setAlertCustom}
                  professionLevel={professionLevel}
                  professionState={professionState}
                  setNewSpouse={setNewSpouse}
                  id={user.admin ? id : user.personal.id}
                  setErrorId={setErrorId}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Los datos de Conyugue ya fueron registrados o aún no selecciono
                al personal
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              DATOS DE PROFESIÓN
            </Box>
            <CreateProfesion
              professionLevel={professionLevel}
              professionState={professionState}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              id={user.admin ? id : user.personal.id}
              setNewProfession={setNewProfession}
              setErrorId={setErrorId}
            />
          </Box>
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "parents")?.value
              ?.length ? (
              <>
                {" "}
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  DATOS DE PADRES
                </Box>
                <CreateParents
                  setNewParents={setNewParents}
                  id={user.admin ? id : user.personal.id}
                  alertCustom={alertCustom}
                  setAlertCustom={setAlertCustom}
                  setErrorId={setErrorId}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Los datos de Padres ya fueron registrados o aún no selecciono al
                personal
              </Box>
            )}

            {/*  <DataGrid
          loading={!allSupplier}
          getRowId={(row) => row.id}
          rows={allSupplier || []}
          columns={columns}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        /> */}
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              DATOS DE IDIOMAS
            </Box>
            <CreateIdiom
              language={language}
              languageLevel={languageLevel}
              id={user.admin ? id : user.personal.id}
              setNewIdiom={setNewIdiom}
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
              setErrorId={setErrorId}
            />
          </Box>
          {/* ROW 2 */}
          <Box
            gridColumn="span 8"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "policeRecord")?.value
              ?.length ? (
              <>
                {" "}
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  DATOS DE ANTECEDENTES
                </Box>{" "}
                <CreatePoliceRecord
                  sentencePoliceRecord={sentencePoliceRecord}
                  id={user.admin ? id : user.personal.id}
                  setNewPoliceRecord={setNewPoliceRecord}
                  setAlertCustom={setAlertCustom}
                  alertCustom={alertCustom}
                  setErrorId={setErrorId}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Los datos de antecedentes policiales ya fueron registrados o aún
                no selecciono al personal
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              DATOS DE HIJOS
            </Box>
            <CreateChildrens
              gender={gender}
              setNewChildren={setNewChildren}
              id={user.admin ? id : user.personal.id}
              setErrorId={setErrorId}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
            />
            {/*  <DataGrid
          loading={!allCategories}
          getRowId={(row) => row.id}
          rows={allCategories || []}
          columns={columnsCategories}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        /> */}
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "extra")?.value?.length ? (
              <>
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  DATOS EXTRA
                </Box>
                <CreateExtraData
                  pensionFund={pensionFund}
                  pensionType={pensionType}
                  id={user.admin ? id : user.personal.id}
                  alertCustom={alertCustom}
                  setAlertCustom={setAlertCustom}
                  setErrorId={setErrorId}
                  setNewExtraData={setNewExtraData}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                Los datos complementarios ya fueron registrados o aún no
                selecciono al personal
              </Box>
            )}
          </Box>

          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "contact")?.value
              ?.length ? (
              <>
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  PERSONA A LLAMAR EN CASOS DE EMERGENCIA
                </Box>
                <CreateContact
                  contactType={contactType}
                  id={user.admin ? id : user.personal.id}
                  alertCustom={alertCustom}
                  setErrorId={setErrorId}
                  setAlertCustom={setAlertCustom}
                  setNewContact={setNewContact}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                Los datos de Contacto ya fueron registrados o aún no selecciono
                al personal
              </Box>
            )}
          </Box>

          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {!fichaPersonal.find((el) => el.type == "movility")?.value
              ?.length ? (
              <>
                <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  DATOS DE MOVILIDAD
                </Box>
                <CreateMovility
                  movilityType={movilityType}
                  id={user.admin ? id : user.personal.id}
                  setNewMovilty={setNewMovilty}
                  setAlertCustom={setAlertCustom}
                  alertCustom={alertCustom}
                  setErrorId={setErrorId}
                />
              </>
            ) : (
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                Los datos de Movilidad ya fueron registrados o aún no selecciono
                al personal
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
            
            >
              HISTORIAL DE FICHA PERSONAL
            </Box>

            <DataGrid
              loading={!fichaPersonal}
              getRowId={(row) => row.type}
              rows={fichaPersonal || []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              columns={columns}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FichaPersonal;
