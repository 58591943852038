import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import StatBoxOne from "components/StatBoxOne";
import { DataGrid,  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from "@mui/x-data-grid";
import { useApolloClient } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  SettingsOutlined,
} from "@mui/icons-material";
import ModalUtil from "components/ModalUtil";
import { useLocation } from "react-router-dom";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
import { GET_ALL_MAINTANCE_REQUERIMENT } from "./../../mantenimiento/requerimientMantaince/QueryMaintance";
import AssignMaintanceRequeriment from "./AssignMaintanceRequeriment";
import ViewRequeriment from "scenes/mantenimiento/requerimientMantaince/ViewRequeriment";
const Maintance = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const { dateFormat } = useSettings();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [modalOpenView, setModalOpenView] = useState(false);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [maintance, setMaintance] = useState([]);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModal = (id) => {
    setModalOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setrequerimentselected(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) => DateFormatting(params.row.date,dateFormat ),
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/*    <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnWorkOrder = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) => params.row.date,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/*    <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_MAINTANCE_REQUERIMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_MAINTANCE_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            acro: location.pathname?.split("/")[1],
          }
        });
        console.log(data);
        setMaintance(data.getMaintanceRequeriment);
      } catch (error) {
        console.log(error.message);
      }
    };

    FUNCTION_GET_MAINTANCE_REQUERIMENT();
  }, [newAssignRequeriment]);
  return (
    <>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={AssignMaintanceRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
         <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
        <Header title="ADMINISTRACION" subtitle="admin/mantenimiento" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
        
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTO DE MANTENIMIENTO
            </Box>
            <DataGrid
              loading={!maintance}
              getRowId={(row) => row.id}
              rows={
                maintance?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>

          {/*   <StatBox
        icon={
          <Traffic
            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
          />
        }
      />
      <StatBox
        icon={
          <PersonAdd
            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
          />
        }
      /> */}
          {/* ROW 2 */}
        </Box>
      </Box>
    </>
  );
};

export default Maintance;
