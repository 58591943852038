import React, { useState, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import MenuItem from "@mui/material/MenuItem";
import {
  GET_WORK_ORDER_BY_ID,
  GET_STATE_BY_NAME,
  UPDATE_WORK_ORDER_,
} from "./QueryWorkOrder";
import { WORK_ASSIGN_PROGRAMED_YUP } from "./YupValidation";
import { FormHelperText } from "@mui/material";
import dayjs from 'dayjs';
const AssignWorkOrderProgramed = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const formikAssign = useFormik({
    initialValues: {
      state: "",
      date: new Date(),
      timeBegin: dayjs('2022-04-17T15:30'),
      timeEnd: dayjs('2022-04-17T15:30'),
    },
    validationSchema: WORK_ASSIGN_PROGRAMED_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
   
      setSubmitting(true);
      const { state, timeBegin, timeEnd } = values;
      try {
        const response = await client.mutate({
          mutation: UPDATE_WORK_ORDER_,
          variables: {
            updateWorkOrderStateId: id,
            state: state,
            date:new Date(itemRequeriment?.date),
            active: [],           
            timeBegin: timeBegin.$H+":"+timeBegin.$m,
            timeEnd: timeEnd.$H+":"+timeEnd.$m,
          },
        });
        console.log(response);
        setNewAssignRequeriment(response.data.createMaintanceRequeriment);
        handleCloseModal();
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    const getItemRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_WORK_ORDER_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            getWorkOrderByIdId: id,
          },
        });
        console.log(data.getWorkOrderById);
        setItemRequeriment(data.getWorkOrderById);
        console.log(itemRequeriment?.state);

        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getStateByName = async () => {
      try {
        const { data } = await client.query({
          query: GET_STATE_BY_NAME,
          fetchPolicy: "network-only",
          variables: {
            name: "CERRADA",
            current: "PROGRAMADA",
          },
        });
        console.log(data.getStateByName);
        setStates(data.getStateByName);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequeriment(id);
    getStateByName();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        {" "}
        <form onSubmit={formikAssign.handleSubmit}>
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label for="my-input">FECHA DE REQUERIMENTO</label>
              <TextField
                disabled
                value={new Date(
                  itemRequeriment?.requeriment.date
                ).toLocaleDateString("es-ES")}
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">PRIORIDAD DE REQUERIMIENTO</label>
              <TextField
                disabled
                value={itemRequeriment?.requeriment.priority.description}
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">TIPO DE REQUERIMIENTO</label>
              <TextField
                disabled
                value={itemRequeriment?.requeriment.type.description}
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">COMENTARIO DE REQUERIMIENTO</label>
              <TextField
                disabled
                value={itemRequeriment?.requeriment.comment}
              />
            </Box>
            {console.log(formikAssign)}
            <Box>
              <label for="my-input">ESTADO</label>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ESTADO</InputLabel>
                <Select
                  id="state"
                  value={formikAssign.values.state}
                  name="state"
                  label="ESTADO"
                  onChange={formikAssign.handleChange}
                >
                  {states?.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {formikAssign.touched.state && formikAssign.errors.state ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    {formikAssign.touched.state && formikAssign.errors.state}
                  </p>
                </FormHelperText>
              ) : null}
            </Box>

            {/* <Box sx={style}>
              <label for="my-input">ESTADO</label>
              <TextField disabled value={itemRequeriment?.state.description} />
            </Box> */}

            <Box sx={style}>
              <label for="my-input">FECHA</label>
              <TextField
                disabled
                value={new Date(itemRequeriment?.date).toLocaleDateString(
                  "es-ES"
                )}
              />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                id="timeBegin"
                label="Horário de Inicio"
                name="timeBegin"
                value={formikAssign.values.timeBegin}
                onChange={(val) => {
                  formikAssign.setFieldValue("timeBegin", val);
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
              />
                 {formikAssign.touched.timeBegin && formikAssign.errors.timeBegin ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    {formikAssign.touched.timeBegin && formikAssign.errors.timeBegin}
                  </p>
                </FormHelperText>
              ) : null}
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                id="timeEnd"
                label="Horário de Fin"
                name="timeEnd"
                value={formikAssign.values.timeEnd}
                onChange={(val) => {
                  formikAssign.setFieldValue("timeEnd", val);
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
              />
              {formikAssign.touched.timeEnd && formikAssign.errors.timeEnd ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    {formikAssign.touched.timeEnd && formikAssign.errors.timeEnd}
                  </p>
                </FormHelperText>
              ) : null}
            </LocalizationProvider>
            {/*  {itemRequeriment?.timeBegin && (
              <Box sx={style}>
                <label for="my-input">HORA DE INICIO</label>
                <TextField disabled value={itemRequeriment?.timeBegin} />
              </Box>
            )}
            {itemRequeriment?.timeEnd && (
              <Box sx={style}>
                <label for="my-input">HORA DE FIN</label>
                <TextField disabled value={itemRequeriment?.timeEnd} />
              </Box>
            )} */}
          </Box>
          <Box mt={"25px"} display={"flex"} justifyContent={"end"}>
            <Button variant="contained" color="success" type="submit" disabled={formikAssign.isSubmitting}>
            {formikAssign.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
            </Button>
          </Box>
        </form>{" "}
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default AssignWorkOrderProgramed;
