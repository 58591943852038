import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { CONTACTTYPE_SCHEMA_YUP } from "./YupValidation";
import { CREATE_CONTACT } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
import Alerts from "components/Alerts";
const CreateContact = ({
  contactType,
  id,
  setNewContact,
  setAlertCustom,
  setErrorId,
  alertCustom,
}) => {
  const client = useApolloClient();
  const formikContact = useFormik({
    initialValues: {
      personal: "",
      name: "",
      surname: "",
      relation: "",
      phone: "",
    },
    validationSchema: CONTACTTYPE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);

        const { name, surname, relation, phone } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_CONTACT,
            variables: {
              personal: id,
              name: name.toUpperCase(),
              surname: surname.toUpperCase(),
              relation,
              phone,
            },
          });
          console.log(response.data);
          setNewContact(response.data.createContact);
          //setId(response.data.createPersonal.id);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikContact.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="name"
          name="name"
          label="NOMBRES"
          variant="outlined"
          value={formikContact.values.name}
          onChange={formikContact.handleChange}
          error={
            formikContact.touched.name && Boolean(formikContact.errors.name)
          }
          helperText={formikContact.touched.name && formikContact.errors.name}
        />
        <TextField
          id="surname"
          name="surname"
          label="APELLIDOS"
          variant="outlined"
          value={formikContact.values.surname}
          onChange={formikContact.handleChange}
          error={
            formikContact.touched.surname &&
            Boolean(formikContact.errors.surname)
          }
          helperText={
            formikContact.touched.surname && formikContact.errors.surname
          }
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PARENTESCO</InputLabel>
          <Select
            id="relation"
            value={formikContact.values.relation}
            name="relation"
            label="PARENTESCO"
            onChange={formikContact.handleChange}
            error={
              formikContact.touched.relation &&
              Boolean(formikContact.errors.relation)
            }
          >
            {contactType?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikContact.touched.relation && formikContact.errors.relation ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikContact.touched.relation && formikContact.errors.relation}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="phone"
          name="phone"
          label="TELEFONO"
          variant="outlined"
          value={formikContact.values.phone}
          onChange={formikContact.handleChange}
          error={
            formikContact.touched.phone && Boolean(formikContact.errors.phone)
          }
          helperText={formikContact.touched.phone && formikContact.errors.phone}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit"  disabled={formikContact.isSubmitting}>
        {formikContact.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateContact;
