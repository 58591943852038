import React, { useState, useEffect } from "react";
import {
  Box,
  Button, 
} from "@mui/material";

import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useApolloClient } from "@apollo/client";
import { useFormik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GET_PERSONAL_BY_ID } from "./../FichaPersonal/QueryPersonal";
import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
} from "../FichaPersonal/QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
import { PERSONAL_EDIT_SCHEMA_YUP } from "./../FichaPersonal/YupValidation";
import {EDIT_PERSONAL_BY_ID} from "./QueryPersonal";
const EditPersonal = ({ id, onClose, params }) => {
  const {
    setNewRegister,
    civilstatus,
    gender,
    departament,
    contexture,
    setAlertCustom,
    alertCustom,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [personal, setpersonal] = useState(null);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
        fetchPolicy: "network-only",
      });      
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  useEffect(() => {
    const getPersonal = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_BY_ID,
          variables: {
            getPersonalByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        setpersonal(data.getPersonalByID);
        formikPersonal.setValues({
          name: data.getPersonalByID?.postulant?.name || "",
          surname: data.getPersonalByID?.postulant?.surname || "",
          doi: data.getPersonalByID?.postulant?.doi || "",
          dob: new Date(data.getPersonalByID?.postulant?.dob) || "",
          email: data.getPersonalByID?.postulant?.email || "",
          phone: data.getPersonalByID?.postulant?.phone || "",
          address: data.getPersonalByID?.address || "",
          stature: data.getPersonalByID?.stature || "",
          departament: data.getPersonalByID?.departament.id || "",
          province: data.getPersonalByID?.province.id || "",
          district: data.getPersonalByID?.district.id || "",
          civilstatus: data.getPersonalByID?.civilstatus.id || "",
          gender: data.getPersonalByID?.gender.id || "",
          contexture: data.getPersonalByID?.contexture?.id || "",
        });
        handleProvince(data.getPersonalByID?.departament.id);
        handleDistrict(data.getPersonalByID?.province.id);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPersonal(id);
  }, []);
  const formikPersonal = useFormik({
    initialValues: {
      name: "",
      surname: "",
      doi: "",
      dob: new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      ),
      email: "",
      phone: "",
      address: "",
      stature: "",
      departament: "",
      province: "",
      district: "",

      civilstatus: "",
      gender: "",

      contexture: "",
    },
    validationSchema: PERSONAL_EDIT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        name,
        surname,
        doi,
        dob,
        email,
        phone,
        address,
        stature,
        departament,
        province,
        district,
        civilstatus,
        gender,
        contexture,
      } = values;

      try {
        const response = await client.mutate({
          mutation: EDIT_PERSONAL_BY_ID,
          variables: {
            editPersonalByIdId:id,
            name: name.toUpperCase(),
            surname: surname.toUpperCase(),
            doi: doi,
            dob,
            email: email.toUpperCase(),
            phone: Number(phone),
            address: address.toUpperCase(),
            stature: stature?parseFloat(stature):null,
            departament,
            province,
            district,
            civilstatus,
            gender,
            contexture:contexture?contexture:null,
          },
        });
        console.log(response.data.editPersonalById);
        setNewRegister(response.data.editPersonalById);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });

        resetForm();
        onClose();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return visibility  ? (
    <form onSubmit={formikPersonal.handleSubmit}>
      <>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">NOMBRES</label>
            <TextField
              id="name"
              name="name"
              variant="outlined"
              value={formikPersonal.values.name}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.name &&
                Boolean(formikPersonal.errors.name)
              }
              helperText={
                formikPersonal.touched.name && formikPersonal.errors.name
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">APELLIDOS</label>
            <TextField
              id="surname"
              name="surname"
              variant="outlined"
              value={formikPersonal.values.surname}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.surname &&
                Boolean(formikPersonal.errors.surname)
              }
              helperText={
                formikPersonal.touched.surname && formikPersonal.errors.surname
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">DNI</label>
            <TextField
              id="doi"
              name="doi"
              variant="outlined"
              type="number"
             
              value={formikPersonal.values.doi}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.doi && Boolean(formikPersonal.errors.doi)
              }
              helperText={
                formikPersonal.touched.doi && formikPersonal.errors.doi
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TELEFONO</label>
            <TextField
              // value={postulant?.email}
              id="phone"
              name="phone"
              value={formikPersonal.values.phone}
              variant="outlined"
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.phone &&
                Boolean(formikPersonal.errors.phone)
              }
              helperText={
                formikPersonal.touched.phone && formikPersonal.errors.phone
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">EMAIL</label>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              value={formikPersonal.values.email}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.email &&
                Boolean(formikPersonal.errors.email)
              }
              helperText={
                formikPersonal.touched.email && formikPersonal.errors.email
              }
            />
          </Box>

          <Box sx={style}>
            <label for="my-input">FECHA DE NACIMIENTO</label>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={formikPersonal.values.dob}
                id="dob"
                name="dob"
                minDate={mindate}
                maxDate={
                  new Date(
                    today.getFullYear() - minAge,
                    today.getMonth(),
                    today.getDate()
                  )
                }
                inputFormat="dd-MM-yyyy"
                onChange={(val) => {
                  formikPersonal.setFieldValue("dob", val);
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">DEPARTAMENTOO</label>
              <Select
                id="departament"
                value={formikPersonal.values.departament}
                name="departament"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={(e) => {
                  formikPersonal.setFieldValue("departament", e.target.value);
                  handleProvince(e.target.value);
                }}
                error={
                  formikPersonal.touched.departament &&
                  Boolean(formikPersonal.errors.departament)
                }
              >
                {departament?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.departament &&
              formikPersonal.errors.departament ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.departament &&
                    formikPersonal.errors.departament}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">PROVINCIA</label>
              <Select
                id="province"
                value={formikPersonal.values.province}
                name="province"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={(e) => {
                  formikPersonal.setFieldValue("province", e.target.value);               
                  handleDistrict(e.target.value);
                }}
                error={
                  formikPersonal.touched.province &&
                  Boolean(formikPersonal.errors.province)
                }
              >
                {province?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.province &&
              formikPersonal.errors.province ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.province &&
                    formikPersonal.errors.province}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">DISTRITO</label>
              <Select
                id="district"
                value={formikPersonal.values.district}
                name="district"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={formikPersonal.handleChange}
                error={
                  formikPersonal.touched.district &&
                  Boolean(formikPersonal.errors.district)
                }
              >
                {district?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.district &&
              formikPersonal.errors.district ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.district &&
                    formikPersonal.errors.district}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>

          <Box sx={style}>
            <label for="my-input">DIRECCION</label>
            <TextField
              id="address"
              name="address"
              variant="outlined"
              value={formikPersonal.values.address}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.address &&
                Boolean(formikPersonal.errors.address)
              }
              helperText={
                formikPersonal.touched.address && formikPersonal.errors.address
              }
            />
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">ESTADO CIVIL</label>
              <Select
                id="civilstatus"
                value={formikPersonal.values.civilstatus}
                name="civilstatus"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={formikPersonal.handleChange}
                error={
                  formikPersonal.touched.civilstatus &&
                  Boolean(formikPersonal.errors.civilstatus)
                }
              >
                {civilstatus?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.civilstatus &&
              formikPersonal.errors.civilstatus ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.civilstatus &&
                    formikPersonal.errors.civilstatus}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">GENERO</label>
              <Select
                id="gender"
                value={formikPersonal.values.gender}
                name="gender"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={formikPersonal.handleChange}
                error={
                  formikPersonal.touched.gender &&
                  Boolean(formikPersonal.errors.gender)
                }
              >
                {gender?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.gender && formikPersonal.errors.gender ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.gender &&
                    formikPersonal.errors.gender}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>

          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">CONTEXTURA</label>
              <Select
                id="contexture"
                value={formikPersonal.values.contexture}
                name="contexture"
                sx={{
                  border:"1px solid rgba(0, 0, 0, 0.23)",            
                }} 
                onChange={formikPersonal.handleChange}
                error={
                  formikPersonal.touched.contexture &&
                  Boolean(formikPersonal.errors.contexture)
                }
              >
                {contexture?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikPersonal.touched.contexture &&
              formikPersonal.errors.contexture ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikPersonal.touched.contexture &&
                    formikPersonal.errors.contexture}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>

          <Box sx={style}>
            <label for="my-input">ESTATURA</label>
            <TextField
              id="stature"
              name="stature"
              variant="outlined"
              value={formikPersonal.values.stature}
              onChange={formikPersonal.handleChange}
              error={
                formikPersonal.touched.stature &&
                Boolean(formikPersonal.errors.stature)
              }
              helperText={
                formikPersonal.touched.stature && formikPersonal.errors.stature
              }
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"20px"}
        >
          <Button variant="contained" color="success" type="submit"  disabled={formikPersonal.isSubmitting}>
          {formikPersonal.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    </form>
  ) : (
    <Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default EditPersonal;
