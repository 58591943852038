import React,{ useState } from "react";
import { ODOMETER_SCHEMA_YUP } from "./YupValidation";
import { Box, TextField, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";

import { useFormik } from "formik";
import LoopIcon from '@mui/icons-material/Loop';
import { CREATE_ODOMETER } from "./QueryFlota";
const CreateOdometer = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewOdometer,
    handleCloseModalOdometer,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(true);
  const formikOdometer = useFormik({
    initialValues: {
      odometer: "",
    },
    validationSchema: ODOMETER_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { odometer } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_ODOMETER,
          variables: {
            number: parseFloat(odometer),
            active: id,
          },
        });
        setNewOdometer(response.data.createOdometer);
        handleCloseModalOdometer();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    visibility?<form onSubmit={formikOdometer.handleSubmit}>

      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="20px">
        {" "}
        <TextField
          id="odometer"
          name="odometer"
          label="ODOMETRO"
          variant="outlined"
          value={formikOdometer.values.odometer}
          onChange={formikOdometer.handleChange}
          error={
            formikOdometer.touched.odometer &&
            Boolean(formikOdometer.errors.odometer)
          }
          helperText={
            formikOdometer.touched.odometer && formikOdometer.errors.odometer
          }
        />
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="contained" color="success" type="submit" disabled={formikOdometer.isSubmitting}>
          {formikOdometer.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>: (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
    )
  );
};

export default CreateOdometer;
