import React, { useState, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { GET_WORK_ORDER_BY_ID } from "./QueryWorkOrder";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
const ViewWorkOrder = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  useEffect(() => {
    const getItemRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_WORK_ORDER_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            getWorkOrderByIdId: id,
          },
        });
        console.log(data.getWorkOrderById);
        setItemRequeriment(data.getWorkOrderById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">FECHA DE REQUERIMENTO</label>
            <TextField
              disabled
              value={new Date(
                itemRequeriment?.requeriment.date
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PRIORIDAD DE REQUERIMIENTO</label>
            <TextField
              disabled
              value={itemRequeriment?.requeriment.priority.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO DE REQUERIMIENTO</label>
            <TextField
              disabled
              value={itemRequeriment?.requeriment.type.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">COMENTARIO DE REQUERIMIENTO</label>
            <TextField disabled value={itemRequeriment?.requeriment.comment} />
          </Box>

          <Box sx={style}>
            <label for="my-input">ESTADO</label>
            <TextField disabled value={itemRequeriment?.state.description} />
          </Box>
          {itemRequeriment?.date && (
            <Box sx={style}>
              <label for="my-input">FECHA DE ASIGNACIÓN</label>
              <TextField
                disabled
                value={new Date(itemRequeriment?.date).toLocaleDateString(
                  "es-ES"
                )}
              />
            </Box>
          )}
          {itemRequeriment?.timeBegin && (
            <Box sx={style}>
              <label for="my-input">HORA DE INICIO</label>
              <TextField
                disabled
                value={itemRequeriment?.timeBegin}
              />
            </Box>
          )}
          {itemRequeriment?.timeEnd && (
            <Box sx={style}>
              <label for="my-input">HORA DE FIN</label>
              <TextField
                disabled
                value={itemRequeriment?.timeEnd}
              />
            </Box>
          )}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewWorkOrder;
