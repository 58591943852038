import { Box, IconButton, Modal, Typography, useTheme } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import CompanyUpdate from "./CompanyUpdate";
import { GET_COMPANY_INFO } from "./QueryCompany";
import { useQuery } from "@apollo/client";


const CompanyInfo = ({ role }) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const { data } = useQuery(GET_COMPANY_INFO, {
    onError: (error) => {
      alert(error)
    }
  })



  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      p='1.5rem'
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={3}
      height='100%'
      position='relative'
    >
      {
        open ? (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <CompanyUpdate
              name={data?.getCompanyInfo.name}
              address={data?.getCompanyInfo.address}
              region={data?.getCompanyInfo.region}
              setOpen={setOpen}
            />
          </Modal>
        ) : <></>
      }
      {
        role === "ADMIN_GENERAL" ? (
          <IconButton
            onClick={() => setOpen(true)}
            aria-label="edit"
            sx={{
              position: 'absolute',
              top: 2,
              right: 2
            }}
          >
            <EditIcon
              fontSize="large"
              sx={{
                color: theme.palette.secondary[300]
              }}
            />
          </IconButton>
        ) : <></>
      }
      <Typography
        variant="h5"
        fontWeight='bold'
      >
        Datos de la Empresa
      </Typography>

      <Box width='100%' display='flex' flexDirection='column' gap={2}>
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Nombre
          </Typography>

          <Typography
            variant="h5"
            textTransform='uppercase'
          >
            {data?.getCompanyInfo.name}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant="h5"
            fontWeight='bold'
          >
            Dirección
          </Typography>

          <Typography
            variant="h5"
            textTransform='capitalize'
          >
            {data?.getCompanyInfo.address}, {data?.getCompanyInfo.region}
          </Typography>

        </Box>

      </Box>

    </Box>
  )
}

export default CompanyInfo