import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
 
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";

import LoopIcon from '@mui/icons-material/Loop';
import { GET_PERSONAL_BY_ID } from "./../FichaPersonal/QueryPersonal";
const ViewPersonal = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [personal, setpersonal] = useState(null);
  useEffect(() => {
    const getPersonal = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_BY_ID,
          variables: {
            getPersonalByIdId: id,
          },
          fetchPolicy: "network-only",
        });     
        setpersonal(data.getPersonalByID);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getPersonal(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return visibility  ? (
    <>
      {" "}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
         <Box sx={style}>
          <label for="my-input">NOMBRES</label>
          <TextField  disabled value={personal.postulant?.name} />
        </Box>
        <Box sx={style}>
          <label for="my-input">APELLIDOS</label>
          <TextField disabled value={personal.postulant?.surname} />
        </Box>
        <Box sx={style}>
          <label for="my-input">EMAIL</label>
          <TextField disabled value={personal.postulant?.email} />
        </Box>
        <Box sx={style}>
          <label for="my-input">DNI</label>
          <TextField disabled value={personal.postulant?.doi} />
        </Box>
        <Box sx={style}>
          <label for="my-input">FECHA DE NACIMIENTO</label>
          <TextField
            disabled
            value={new Date(personal.postulant.dob).toLocaleDateString("es-ES")}
          />{" "}      
        </Box>
        <Box sx={style}>
          <label for="my-input">ESTADO</label>
          <TextField disabled value={personal.postulant?.state?.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">DEPARTAMENTO</label>
          <TextField disabled value={personal.departament?.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">PROVINCIA</label>
          <TextField disabled value={personal.province?.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">DISTRITO</label>
          <TextField disabled value={personal.district?.description} />
        </Box>

        <Box sx={style}>
          <label for="my-input">DIRECCION</label>
          <TextField disabled value={personal.address} />
        </Box>
        <Box sx={style}>
          <label for="my-input">ESTADO CIVIL</label>
          <TextField disabled value={personal.civilstatus?.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">GENERO</label>
          <TextField disabled value={personal.gender?.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">ESTATURA</label>
          <TextField disabled value={personal.stature} />
        </Box>
        <Box sx={style}>
          <label for="my-input">CONTEXTURA</label>
          <TextField disabled value={personal.contexture?.description} />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
        <Button variant="contained" onClick={handleCloseModal}>
          CERRAR
        </Button>
        {/*  */}
      </Box>
    </>
  ) : (<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewPersonal;
