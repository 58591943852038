import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import StatBox from "components/StatBox";
import { DataGrid,  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from "@mui/x-data-grid";

import {
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  SettingsOutlined,
} from "@mui/icons-material";
import ModalUtil from "components/ModalUtil";
import { useApolloClient } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { GET_ALL_DRIVER } from "./../operation/QueryOperation";
import { GET_MAIN_DATA } from "./QueryDriver";
import ViewDriver from "./ViewDriver";
const Drivers = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [driver, setDriver] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [id, setId] = useState(null);
  const [modalOpenDriver, setModalOpenDriver] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const handleCloseModalDriver = () => {
    setModalOpenDriver(false);
  };

  const handleOpenModalDriver = (id) => {
    setModalOpenDriver(true);
    setId(id);
  };

  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "postulant.name",
      headerName: "NOMBRE",
      flex: 1,
      renderCell: (params) => params.row.postulant.name,
    },
    {
      field: "postulant.surname",
      headerName: "APELLIDOS",
      flex: 1,
      renderCell: (params) => params.row.postulant.surname,
    },
    {
      field: "postulant.doi",
      headerName: "DNI",
      flex: 1,
      renderCell: (params) => params.row.postulant.doi,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalDriver(params.row.id)}
          />
          {/*    <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_DRIVER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DRIVER,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllDriver);
        setDriver(data.getAllDriver);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        console.log(data.getSumarizeDriver);
        setMainData(data.getSumarizeDriver);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_DRIVER();
    FUNCTION_MAIN_DATA();
  }, []);
  return (
    <>
      <ModalUtil
        isOpen={modalOpenDriver}
        onClose={handleCloseModalDriver}
        Component={ViewDriver}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setnewAssignation: null,
            handleCloseModalDriver: handleCloseModalDriver,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/conductor" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total de Conductores"
            value={mainData?.amount}
            /*   increase="+14%" */
            description="Personal con cargo de conductor"
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultimo Conductor"
            value={mainData?.lastname + " " + mainData?.lastsurname}
            /*  increase="+21%" */
            description="registrado"
            /*  icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          } */
          />
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="0px"
            >
              HISTORIAL DE CONDUCTORES
            </Box>
            <DataGrid
              loading={!driver}
              getRowId={(row) => row.id}
              rows={driver || []}
              columns={columns}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>

          {/*   <StatBox
          icon={
            <Traffic
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}
          {/* ROW 2 */}
        </Box>
      </Box>
    </>
  );
};

export default Drivers;
