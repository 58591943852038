import * as Yup from "yup";
export const ITEM_REQUERIMENT_SCHEMA_YUP = Yup.object().shape({
  type: Yup.string().required("Requirido"),
  evaluator: Yup.string().required("Requirido"),
  supplier: Yup.date().required("Requirido"),
  criteria: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        criterion: Yup.string(),
      })
    )
    .required("Required"),
});

export const SELECTION_SCHEMA_YUP = Yup.object().shape({
  type: Yup.string().required("Requirido"),
  evaluator: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  supplier1: Yup.string().required("Requirido"),
  criteria1: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
  supplier2: Yup.string().required("Requirido"),
  criteria2: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
  supplier3: Yup.string().required("Requirido"),
  criteria3: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
});
export const EVALUATION_SCHEMA_YUP = Yup.object().shape({
  evaluator: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  supplier1: Yup.string().required("Requirido"),
  criteria1: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
  supplier2: Yup.string().required("Requirido"),
  criteria2: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
  supplier3: Yup.string().required("Requirido"),
  criteria3: Yup.array().of(
    Yup.object().shape({
      score: Yup.number()
        .integer("El número debe ser un número entero")
        .positive("El número debe ser positivo")
        .min(0, "El debe estar entre 0 y 3")
        .max(3, "El debe estar entre 0 y 3")
        .required("Requiredo"),
      criterion: Yup.string(),
    })
  ),
});

/* export const ITEM_REQUERIMENT_SCHEMA_YUP = Yup.object().shape({
    evaluator: Yup.string().required("Requirido"),
    supplier: Yup.date().required("Requirido"),
    criteria: Yup.array()
      .of(
        Yup.object().shape({
          answer: Yup.string().required("Requiredo"),
          criterion: Yup.string(),
        })
      )
      .required("Required"),
  });
   */
