import { gql } from "@apollo/client";

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($description: String, $consumable: Boolean) {
    createGroup(description: $description, consumable: $consumable) {
      code
      description
      id
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroup {
    getGroup {
      id
      description
      code
      
      
    }
  }
`;

export const GET_ALL_GROUP = gql`
  query GetGroup {
    getGroup {
      code
      description
      id
      consumable
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSummarizeGroupClass {
    getSummarizeGroupClass {
      countClass
      countGroup
      lastClass
      lastGroup
    }
  }
`;
export const GET_GROUP_BY_ID = gql`
  query GetGroupById($getGroupByIdId: ID) {
    getGroupById(id: $getGroupByIdId) {
      description
    }
  }
`;

export const EDIT_GROUP_BY_ID = gql`
  mutation EditGroup($editGroupId: ID, $description: String) {
    editGroup(id: $editGroupId, description: $description) {
      description
      code
      id
    }
  }
`;

export const DELETE_GROUP_BY_ID = gql`
  mutation DeleteGroupById($deleteGroupByIdId: ID) {
    deleteGroupById(id: $deleteGroupByIdId) {
      code
      description
      id
    }
  }
`;
