import { gql } from "@apollo/client";
export const GET_INTERVIEW_BY_POSTULANT_ID = gql`
  query GetAllInterviewByPostulantId($getAllInterviewByPostulantIdId: ID) {
    getAllInterviewByPostulantId(id: $getAllInterviewByPostulantIdId) {
      id
      position {
        description
      }
      salary
    }
  }
`;

export const GET_FORMATION = gql`
  query GetFormationInterview {
    getFormationInterview {
      description
      id
    }
  }
`;

export const GET_OTHER = gql`
  query GetOtherInterview {
    getOtherInterview {
      description
      id
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeInterview($getSumarizeInterviewId: ID) {
    getSumarizeInterview(id: $getSumarizeInterviewId) {
      lastDate
      lastPosition
    }
  }
`;

export const CREATE_INTERVIEW_MUTATION = gql`
  mutation CreateInterview(
    $salary: Float
    $position: ID
    $postulant: ID
    $formation: [InterviewFormationInput]
    $other: [InterviewOtherInput]
  ) {
    createInterview(
      salary: $salary
      position: $position
      postulant: $postulant
      formation: $formation
      other: $other
    ) {
      id
      salary
    }
  }
`;

export const GET_INTERVIEW_BY_ID = gql`
  query GetInterviewById($getInterviewByIdId: ID) {
    getInterviewById(id: $getInterviewByIdId) {
      id
      position {
        description
      }
      postulant {
        name
        surname
        id
        email
        phone
        doi
        dob
      }
      salary
      otherQuestion {
        other {
          description
        }
        score
      }
      formationQuestion {
        score
        formation {
          description
        }
      }
    }
  }
`;

export const DELETE_INTERRVIEW_BY_ID = gql`
mutation DeleteInterviewById($deleteInterviewByIdId: ID) {
  deleteInterviewById(id: $deleteInterviewByIdId) {
    id
  }
}
`;