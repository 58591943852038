import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import StatBox from "components/StatBox";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  esES
} from "@mui/x-data-grid";
import CreatePostulant from "./CreatePostulant";
import {
  Email,
  PersonAdd,
  Traffic,  
} from "@mui/icons-material";
import {
  GET_ALL_POSTULANT,
  GET_POSTULANT_BY_ID,
  GET_MAIN_DATA,
} from "./QueryPostulant";
import { useApolloClient } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import ModalUtil from "components/ModalUtil";
import ViewPostulant from "./ViewPostulant";
import DialogUtil from "components/DialogUtil";
import DeletePostulant from "./DeletePostulant";

import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";

import EditPostulant from "./EditPostulant";
import { useUser } from "hooks/useUser";
const Postulant = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [postulantData, setPostulantData] = useState([]);
  const [newPostulant, setNewPostulant] = useState(null);
  const client = useApolloClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const { user } = useUser();
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [id, setId] = useState(false);

  const { dateFormat, isLoading } = useSettings(); 

  const handleOpenModalView = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = (id) => {
    setModalEdit(true);
    setId(id);
  };

  const handleCloseModalEdit = () => {
    setModalEdit(false);
  };
  const handleOpenDialog = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  /*  */
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "NOMBRE",
      flex: 1,
    },
    {
      field: "surname",
      headerName: "APELLIDOS",
      flex: 1,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
    },
    {
      field: "doi",
      headerName: "DNI",
      flex: 0.5,
      sortable: false,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 0.5,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {user.admin ? (
            <>
              <CreateIcon
                color="info"
                onClick={() => handleOpenModalEdit(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDialog(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const FUNCTION_GET_ALL_POSTULANT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_POSTULANT,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllPostulant);
        setPostulantData(data.getAllPostulant);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumarizePostulant);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_POSTULANT();
    FUNCTION_MAIN_DATA();
  }, [newPostulant]);

  return (
    <>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={ViewPostulant}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalEdit}
        onClose={handleCloseModalEdit}
        Component={EditPostulant}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewPostulant: setNewPostulant,
          })
        }
        id={id}
      ></ModalUtil>
      <DialogUtil
        title={"ELIMINAR POSTULANTE"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        Component={DeletePostulant}
        id={id}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewPostulant: setNewPostulant,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="GESTION DE PERSONAS" subtitle="personas/postulante" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Postulante"
            value={mainData?.postulant}
            /*   increase="+14%" */
            description="Aun estan en proceso de entrevista"
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />{" "}
          <StatBox
            title="Ultimo"
            value={mainData?.lastPostulant}
            /*  increase="+43%" */
            description={"Postulante"}
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreatePostulant
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              setNewPostulant={setNewPostulant}
            />
          </Box>
          <StatBox
            title="Fecha"
            value={
              mainData?.lastDate && !isLoading
                ? DateFormatting(Number(mainData?.lastDate), dateFormat)
                : null
            }
            description={"Ultimo ingreso"}
            icon={
              <PersonAdd
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 2 */}
          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              pt={"15px"}
            >
              HISTORIAL DE POSTULANTES
            </Box>
            <DataGrid
              loading={!postulantData}
              getRowId={(row) => row.id}
              rows={postulantData || []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              columns={columns}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Postulant;
