import React,{ useState ,useRef} from "react";
import { ASIGNATION_SCHEMA_YUP } from "./YupValidation";
import { Box, TextField, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import FormControl from "@mui/material/FormControl";
import Alerts from "components/Alerts";
import { useFormik } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CREATE_ASIGNATION } from "./QueryFlota";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import Autocomplete from "@mui/material/Autocomplete";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
const CreateAsignation = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setnewAssignation,
    handleCloseModalAssignation,
    type,
    driver,
  } = params;
  const [visibility, setvisibility] = useState(true);
  const { user } = useUser();
  const client = useApolloClient();
  const autocompletepersonalRef = useRef(null);
  const { currency,dateFormat } = useSettings(); 
  const formikAsignation = useFormik({
    initialValues: {
      driver: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      proyect: "",
      description: "",
      type: "",

      begindate: new Date(),
      begintime: dayjs('2022-04-17T15:30'),
      enddate: new Date(),
      endtime: dayjs('2022-04-17T15:30'),

      departure: "",
      load: "",
      download: "",
      returnn: "",

      observation: "",
    },
    validationSchema: ASIGNATION_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        driver,
        proyect,
        description,
        type,
        begindate,
        begintime,
        enddate,
        endtime,
        departure,
        load,
        download,
        returnn,
        observation,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_ASIGNATION,
          variables: {
            active: id,
            driver:driver.id,
            proyect: proyect.toUpperCase(),
            description: description.toUpperCase(),
            type,
            begindate,
            begintime:begintime.$H+":"+begintime.$m,
            enddate,
            endtime:endtime.$H+":"+endtime.$m,
            departure: departure.toUpperCase(),
            load: load.toUpperCase(),
            download: download.toUpperCase(),
            returnn: returnn.toUpperCase(),
            observation: observation.toUpperCase(),
          },
        });
        setnewAssignation(response.data.createAssignation);
        handleCloseModalAssignation();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if(user.admin){
          autocompletepersonalRef.current.value = "";
        }        
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    visibility?<form onSubmit={formikAsignation.handleSubmit}>     
      { user.admin ?<Box mb={"20px"}>
        {driver?.length && (
          <Autocomplete
            freeSolo
            id="driver"
            name="driver"
            disableClearable
            ref={autocompletepersonalRef}
            value={formikAsignation.values.driver.label}
            options={driver?.map(
              (option) =>
                new Object({
                  label:
                    "nombres:" +
                    option.postulant.name +
                    " / " +
                    "apellidos:" +
                    option.postulant.surname +
                    " / " +
                    "dni:" +
                    option.postulant.doi,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikAsignation.setFieldValue("driver", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre/apellido/dni"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikAsignation.touched.driver?.id &&
                  Boolean(formikAsignation.errors.driver?.id)
                }
                helperText={
                  formikAsignation.touched.drive?.id &&
                  formikAsignation.errors.driver?.id
                }
              />
            )}
          />
        )}
      </Box>:null}
      <Box
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
        my={"20px"}
      >
        <TextField
          id="proyect"
          name="proyect"
          label="PROYECTO / CONTRATO"
          variant="outlined"
          value={formikAsignation.values.proyect}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.proyect &&
            Boolean(formikAsignation.errors.proyect)
          }
          helperText={
            formikAsignation.touched.proyect && formikAsignation.errors.proyect
          }
        />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="20px">
        {" "}
        <TextField
          id="description"
          name="description"
          label="DESCRIPCION DE LA TAREA"
          variant="outlined"
          value={formikAsignation.values.description}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.description &&
            Boolean(formikAsignation.errors.description)
          }
          helperText={
            formikAsignation.touched.description &&
            formikAsignation.errors.description
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">TIPO</InputLabel>
          <Select
            id="type"
            value={formikAsignation.values.type}
            name="type"
            onChange={formikAsignation.handleChange}
            sx={{
              border:"1px solid rgba(0, 0, 0, 0.23)",            
            }}  
            error={
              formikAsignation.touched.type &&
              Boolean(formikAsignation.errors.type)
            }
          >
            {type?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikAsignation.touched.type && formikAsignation.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikAsignation.touched.type && formikAsignation.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikAsignation.values.begindate}
            id="begindate"
            name="begindate"
            label="FECHA INICIAL"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikAsignation.setFieldValue("begindate", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
      {/*   <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikAsignation.values.begintime}
            id="begintime"
            name="begintime"
            label="HORA INICIAL"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikAsignation.setFieldValue("begintime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="begintime"
            label="HORA INICIAL"
            name="begintime"
            value={formikAsignation.values.begintime}
            onChange={(val) => {
              formikAsignation.setFieldValue("begintime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikAsignation.values.enddate}
            id="enddate"
            name="enddate"
            label="FECHA FINAL"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikAsignation.setFieldValue("enddate", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
       {/*  <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikAsignation.values.endtime}
            id="endtime"
            name="endtime"
            label="HORA FINAL"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikAsignation.setFieldValue("endtime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider> */}
         <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="endtime"
            label="HORA FINAL"
            name="endtime"
            value={formikAsignation.values.endtime}
            onChange={(val) => {
              formikAsignation.setFieldValue("endtime", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <TextField
          id="departure"
          name="departure"
          label="PUNTO DE PARTIDA"
          variant="outlined"
          value={formikAsignation.values.departure}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.departure &&
            Boolean(formikAsignation.errors.departure)
          }
          helperText={
            formikAsignation.touched.departure &&
            formikAsignation.errors.departure
          }
        />
        <TextField
          id="load"
          name="load"
          label="PUNTO DE CARGA"
          variant="outlined"
          value={formikAsignation.values.load}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.load &&
            Boolean(formikAsignation.errors.load)
          }
          helperText={
            formikAsignation.touched.load && formikAsignation.errors.load
          }
        />
        <TextField
          id="download"
          name="download"
          label="PUNTO DE DESCARGA"
          variant="outlined"
          value={formikAsignation.values.download}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.download &&
            Boolean(formikAsignation.errors.download)
          }
          helperText={
            formikAsignation.touched.download &&
            formikAsignation.errors.download
          }
        />
        <TextField
          id="returnn"
          name="returnn"
          label="PUNTO DE RETORNO"
          variant="outlined"
          value={formikAsignation.values.returnn}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.returnn &&
            Boolean(formikAsignation.errors.returnn)
          }
          helperText={
            formikAsignation.touched.returnn && formikAsignation.errors.returnn
          }
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
        my={"20px"}
      >
        <TextField
          fullWidth
          id="observation"
          name="observation"
          label="OBSERVACIONES"
          variant="outlined"
          multiline
          rows={6}
          value={formikAsignation.values.observation}
          onChange={formikAsignation.handleChange}
          error={
            formikAsignation.touched.observation &&
            Boolean(formikAsignation.errors.observation)
          }
          helperText={
            formikAsignation.touched.observation &&
            formikAsignation.errors.observation
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Button variant="contained" color="success" type="submit" disabled={formikAsignation.isSubmitting}>
        {formikAsignation.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>:<Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
  );
};

export default CreateAsignation;
