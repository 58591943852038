import { gql } from "@apollo/client";

export const CREATE_CLASS_MUTATION = gql`
  mutation CreateClass($group: ID, $description: String) {
    createClass(group: $group, description: $description) {
      acro
      code
      description
      id
    }
  }
`;

export const GET_ALL_CLASS = gql`
  query GetClass {
    getClass {
      id
      acro
      code
      description
      id
      group {
        id
        description
        code
      }
    }
  }
`;

export const GET_CLASS_BY_ID = gql`
  query GetClassById($getClassByIdId: ID) {
    getClassById(id: $getClassByIdId) {
      acro
      code
      description
      group {
        description
      }
      id
    }
  }
`;
export const GET_EDIT_CLASS_BY_ID = gql`
  query GetClassById($getClassByIdId: ID) {
    getClassById(id: $getClassByIdId) {  
      description          
    }
  }
`;


export const EDIT_CLASS_BY_ID = gql`
mutation EditClass($editClassId: ID, $description: String) {
  editClass(id: $editClassId, description: $description) {
    id
    description
    code
    acro
  }
}`;

export const DELETE_CLASS_BY_ID= gql`
mutation DeleteClassById($deleteClassByIdId: ID) {
  deleteClassById(id: $deleteClassByIdId) {
    acro
    code
    description
    id
  }
}`;