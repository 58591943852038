import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useApolloClient } from "@apollo/client";

const ViewReportOther = ({
  items,
  itemFuel,
  yearFuel,
  setItemFuel,
  setYearFuel,
  monthFuel,
  setMonthFuel,
  actives,
  activeFuel,
  setActiveFuel,
  setActives,
}) => {
  const meses = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
  ];
  return (
    <Box
      mt="20px"
      display="grid"
      gridTemplateColumns="repeat(4, 1fr)"
      gap="20px"
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">FLOTA</InputLabel>
        <Select
          id="group"
          value={itemFuel}
          name="group"
         label="FLOTA"
          onChange={(e) => {
            setItemFuel(e.target.value);
          }}
        >
          <MenuItem key={-1} value={-1} disabled>
            {"Seleccione una opcion"}
          </MenuItem>
          {items?.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.description + " / " + option.active}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        id="anio"
        name="anio"
        label="AÑO"
        type="number"
        variant="outlined"
        value={yearFuel}
        onChange={(e) => {
          setYearFuel(e.target.value);
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">MES</InputLabel>
        <Select
          id="classs"
          value={monthFuel}
          label="MES"
          name="classs"
          onChange={(e) => {
            setMonthFuel(e.target.value);
          }}
        >
          {meses?.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">EQUIPO</InputLabel>
        <Select
          id="active"
          value={activeFuel}
          name="active"
          label="EQUIPO"
          onChange={(e) => {
            setActiveFuel(e.target.value);
          }}
        >
          <MenuItem key={-1} value={-1}>
            {"Todos los Equipos"}
          </MenuItem>
          {actives?.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ViewReportOther;
