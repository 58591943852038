import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { GET_PERSONAL_AND_POSITION_BY_ID } from "./QueryPersonalRequeriment";
import Table from "@mui/material/Table";
import LoopIcon from "@mui/icons-material/Loop";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import { DateFormatting } from "utils/DateFormatting";
const ViewRequeriment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const { currency, dateFormat } = useSettings();
  const [visibility, setvisibility] = useState(false);
  const [personalRequeriment, setPersonalRequeriment] = useState([]);
  const theme = useTheme();
  useEffect(() => {
    const getPersonalRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_AND_POSITION_BY_ID,
          variables: {
            getPersonalRequerimentAndPersonalByIdId: id,
          },
          fetchPolicy: "network-only",
        });

        setPersonalRequeriment(data.getPersonalRequerimentAndPersonalById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPersonalRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return visibility ? (
    <>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
        /*  display="grid"
gridTemplateColumns="repeat(2, 1fr)"
gap="20px" */
      >
        <Box sx={style}>
          <label for="my-input">CARGO</label>
          <TextField
            disabled
            value={personalRequeriment?.position.description}
          />
        </Box>
        <Box sx={style}>
          <label for="my-input">PLAZO DE CONTRATO</label>
          <TextField disabled value={personalRequeriment?.term.description} />
        </Box>
        <Box sx={style}>
          <label for="my-input">MOTIVO</label>
          <TextField
            disabled
            value={personalRequeriment?.subject.description}
          />
        </Box>
        <Box sx={style}>
          <label for="my-input">VACANTES</label>
          <TextField disabled value={personalRequeriment?.ngoal} />
        </Box>
        <Box sx={style}>
          <label for="my-input">LUGAR DE TRABAJO</label>
          <TextField
            disabled
            value={personalRequeriment?.workplace.description}
          />
        </Box>
        <Box sx={style}>
          <label for="my-input">DURACION ESTIMADA</label>
          <TextField
            disabled
            value={personalRequeriment?.duration + " Meses"}
          />
        </Box>
        <Box sx={style}>
          <label for="my-input">FECHA DE INCORPORACIÓN</label>
          <TextField
            disabled
            value={DateFormatting(
              personalRequeriment.incorporationdate,
              dateFormat
            )}
          />{" "}
          {/* Falta arreglar las fechas */}
        </Box>
        <Box sx={style}>
          <label for="my-input">REMUNERACION</label>
          <TextField
            disabled
            value={currency + " " + personalRequeriment?.remuneration}
          />
        </Box>
      </Box>
      <Box my={"20px"}>
        {personalRequeriment.personals.length ? (
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">PERSONAL</TableCell>
                  <TableCell align=""> DNI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personalRequeriment.personals.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row.personal.postulant.name +
                          " " +
                          row.personal.postulant.surname}
                      </TableCell>
                      <TableCell align="">
                        {row.personal.postulant.doi}
                      </TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Box my={"20px"}>
        <TextField
          label="OBSERVACION"
          variant="outlined"
          fullWidth
          multiline
          disabled
          rows={3}
          value={personalRequeriment?.observation}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
        <Button variant="contained" onClick={handleCloseModal}>
          CERRAR
        </Button>
        {/*  */}
      </Box>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LoopIcon />
    </Box>
  );
};

export default ViewRequeriment;
