import { gql } from "@apollo/client";

export const GET_TYPE_MAINTANCE = gql`
  query GetTypeMaintance {
    getTypeMaintance {
      id
      description
    }
  }
`;

export const CREATE_MAINTANCE = gql`
mutation CreateMaintanceRequeriment($comment: String, $description: String, $date: Date, $type: ID, $priority: ID, $area: String, $personal: ID, $active: ID) {
  createMaintanceRequeriment(comment: $comment, description: $description, date: $date, type: $type, priority: $priority, area: $area, personal: $personal, active: $active) {
     id
      description
      date
      comment
  }
}
`;

export const GET_ALL_MAINTANCE_REQUERIMENT = gql`
query GetMaintanceRequeriment($acro: String) {
  getMaintanceRequeriment(acro: $acro) {
      id
      description
      date
      comment
      state {
        description
      }
      priority {
        description
      }
      type {
        description
      }
    area {
      id
      description
      acro
    }
  }
}
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeRequerimentMaintance($area: String) {
    GetSumarizeRequerimentMaintance(area: $area) {
      aproved
      begining
      pending
    }
  }
`;
export const GET_MAINTANCE_REQUERIMENT_BY_ID = gql`
  query GetMaintanceRequerimentById($getMaintanceRequerimentByIdId: ID) {
    getMaintanceRequerimentById(id: $getMaintanceRequerimentByIdId) {
      active {
        description
      }
      comment
      date
      description
      priority {
        description
      }
      type {
        description
      }
      state {
        description
      }
    }
  }
`;
