import React from "react";
import Chart from "react-apexcharts";
import { useSettings } from "hooks/useSettings";
import Plot from "react-plotly.js";
const LineChart = ({ data, title, range, isSingle }) => {
  const { currency,dateFormat } = useSettings(); 
  return (
    <Plot
      data={isSingle ? [data] : data}
      layout={ {autosize:true,title:title,width:600,height:500,  xaxis: {                  // all "layout.xaxis" attributes: #layout-xaxis
            title: 'fecha'         // more about "layout.xaxis.title": #layout-xaxis-title
        }, yaxis: {                  // all "layout.xaxis" attributes: #layout-xaxis
          title: 'S/.'         // more about "layout.xaxis.title": #layout-xaxis-title
      },} }
      config={{
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
        modeBarButtonsToRemove: [
          "lasso2d",
          "zoom",
          "select",
          "zoomIn",
          "zoomOut",
          "resetScale",
          "toImage",
        ],
      }}
    />
  );
};

export default LineChart;
