import { gql } from "@apollo/client";

export const GET_ALL_TYPES = gql`
  query GetAllTypes {
    getAllTypes {
      id
      description
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      id
      description
    }
  }
`;

export const GET_ALL_BANK = gql`
  query GetAllBank {
    getAllBank {
      id
      description
    }
  }
`;

export const GET_ALL_SUPPLIER = gql`
  query GetAllSuplier {
    getAllSuplier {
      id
      businessname
      category {
        description
      }
      type {
        description
      }
      group {
        description
      }
      ruc
    }
  }
`;

export const CREATE_CATEGORY_SUPPLIER = gql`
  mutation CreateCategorySupplier($category: String) {
    createCategorySupplier(category: $category) {
      id
      description
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier(
    $numberdetraction: String
    $bankdollar: ID
    $ccidollar: String
    $numberdollar: String
    $phone: String
    $banksoles: ID
    $ccisoles: String
    $numbersoles: String
    $address: String
    $businessname: String
    $ruc: String
    $district: ID
    $province: ID
    $departament: ID
    $category: ID
    $type: ID
    $group: [ID]
  ) {
    createSupplier(
      numberdetraction: $numberdetraction
      bankdollar: $bankdollar
      ccidollar: $ccidollar
      numberdollar: $numberdollar
      phone: $phone
      banksoles: $banksoles
      ccisoles: $ccisoles
      numbersoles: $numbersoles
      address: $address
      businessname: $businessname
      ruc: $ruc
      district: $district
      province: $province
      departament: $departament
      category: $category
      type: $type
      group: $group
    ) {
      id
      businessname
      address
      ruc
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query GetCategoryById($getCategoryByIdId: ID) {
    getCategoryById(id: $getCategoryByIdId) {
      description      
    }
  }
`;

export const GET_SUPPLIER_BY_ID = gql`
  query GetProviderById($getProviderByIdId: ID) {
    getProviderById(id: $getProviderByIdId) {           
      numberdetraction
      numberdollar
      numbersoles     
      province {
        id
        description:nombre_ubigeo
      }
      district {
        id
        description:nombre_ubigeo
      }
      ruc
      type {
        id
        description
      }
      
      departament {
        id
        description:nombre_ubigeo
      }
      ccisoles
      ccidollar
      phone
      category {
        id
        description
      }
      businessname
      banksoles {
        id
        description
      }
      bankdollar {
        id
        description
      }
      address
    }
  }
`;

export const EDIT_SUPPIER_BY_ID = gql`
mutation EditSupplierById($editSupplierByIdId: ID, $type: ID, $category: ID, $departament: ID, $province: ID, $district: ID, $ruc: String, $businessname: String, $address: String, $numbersoles: String, $ccisoles: String, $banksoles: ID, $phone: String, $numberdollar: String, $ccidollar: String, $bankdollar: ID, $group: [ID], $numberdetraction: String) {
  editSupplierById(id: $editSupplierByIdId, type: $type, category: $category, departament: $departament, province: $province, district: $district, ruc: $ruc, businessname: $businessname, address: $address, numbersoles: $numbersoles, ccisoles: $ccisoles, banksoles: $banksoles, phone: $phone, numberdollar: $numberdollar, ccidollar: $ccidollar, bankdollar: $bankdollar, group: $group, numberdetraction: $numberdetraction) {
    id
    businessname
    address
    ruc
  }
}`;

export const EDIT_SUPPLIER_CATEGORY_BY_ID= gql`
mutation EditSupplierCategoryById($editSupplierCategoryByIdId: ID, $description: String) {
  editSupplierCategoryById(id: $editSupplierCategoryByIdId, description: $description) {
    description
    id
  }
}`;

export const DELETE_SUPPLIER_BY_ID= gql`
mutation DeleteProviderById($deleteProviderByIdId: ID) {
  deleteProviderById(id: $deleteProviderByIdId) {
    id
    ccisoles
    ccidollar
  }
}`;

export const DELETE_SUPPLIER_CATEGORY_BY_ID= gql`
mutation DeleteProviderCategoryById($deleteProviderCategoryByIdId: ID) {
  deleteProviderCategoryById(id: $deleteProviderCategoryByIdId) {
    description
    id
  }
}`;