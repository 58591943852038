import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_ACTIVE_BY_ID = gql`
  query GetActiveRequerimentById($getActiveRequerimentByIdId: ID) {
    getActiveRequerimentById(id: $getActiveRequerimentByIdId) {
      area {
        description
      }
      date
      priority {
        description
      }
      subject
      item {
        description
        amount
        active
        observation
      }
    }
  }
`;

export const UPDATE_ADMIN_ACTIVE_REQUERIMENT_BY_ID = gql`
 mutation UpdateRequerimentLogistic($updateRequerimentLogisticId: ID, $type: Int, $observation: String) {
  updateRequerimentLogistic(id: $updateRequerimentLogisticId, type: $type, observation: $observation) {
    id
    date
    state {
      description
    }
    subject
    area {
      description
    }
  }
}
`;

export const UPDATE_ADMIN_BUY_ORDER_BY_ID = gql`
mutation UpdateBuyOrderAdmin($updateBuyOrderAdminId: ID, $type: Int, $observation: String) {
  updateBuyOrderAdmin(id: $updateBuyOrderAdminId, type: $type, observation: $observation) {
      id
      igv
      state {
        description
      }
      createdAt
      subject {
        description
      }
  }
}
`;

export const GET_BUY_ORDER_BY_ID = gql`
  query GetBuyOrderById($getBuyOrderByIdId: ID) {
    getBuyOrderById(id: $getBuyOrderByIdId) {
      area {
        description
      }
      createdAt
      personal {
        postulant {
          name
          surname
        }
      }
      priority {
        description
      }
      requeriment {
        id
        state {
          description
        }
        date
      }
      subject
      item {
        id
        active
        amount
        description
        unity {
          description
          id
        }
      }
      igv
    }
  }
`;
