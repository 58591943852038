import { gql } from "@apollo/client";

export const GET_PREUSE_COMPONENT_CISTERN = gql`
  query GetPreUseComponenCistern {
    getPreUseComponenCistern {
      category
      element {
        type {
          description
        }
        description
        category {
          description
        }
        id
      }
    }
  }
`;

export const CREATE_PREUSE_COMPONENT_CISTERN = gql`
  mutation CreatePreUseCistern(
    $driver: ID
    $capacity: Int
    $date: Date
    $cisternplate: String
    $tractplate: String
    $activity: String
    $kmBegin: Int
    $kmEnd: Int
    $timeBegin: Date
    $timeEnd: Date
    $declarations: [DeclarationCistern]
    $components: [ComponentCistern]
  ) {
    createPreUseCistern(
      driver: $driver
      capacity: $capacity
      date: $date
      cisternplate: $cisternplate
      tractplate: $tractplate
      activity: $activity
      kmBegin: $kmBegin
      kmEnd: $kmEnd
      timeBegin: $timeBegin
      timeEnd: $timeEnd
      declarations: $declarations
      components: $components
    ) {
      id
       driver {
        postulant {
          name
          surname
        }
      }
      date
      capacity
      activity
      tractplate
      cisternplate
    }
  }
`;

export const GET_PREUSE_CISTERN = gql`
  query GetPreUseCistern {
    getPreUseCistern {   
      date
      cisternplate
      capacity
      activity
      tractplate
      id
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeCistern {
    getSumarizeCistern {
      amount
      lastdate
      lastsubject
    }
  }
`;

export const GET_CISTERN_BY_ID = gql`
 query GetPreUseCisternById($getPreUseCisternByIdId: ID) {
  getPreUseCisternById(id: $getPreUseCisternByIdId) {
      cistern {
        activity
        capacity
        cisternplate
        date
        id
        tractplate
        driver {
          postulant {
            name
            surname
            doi
          }
        }
      }
      declaration {
        answer
        cistern
        observation
        question {
          description
        }
      }
      question {
        answer
        observation
        question {
          description
        }
      }
  }
}
`;
