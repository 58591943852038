import React from "react";
import { useState, useEffect } from "react";
import { GET_STATE_BY_ID } from "./QueryFlota";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import RotatingLoopIcon from './../../utils/RotatingLoopIcon'; 
const ViewState = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [state, setstate] = useState({});
  useEffect(() => {
    const getStateById = async () => {
      try {
        const { data } = await client.query({
          query: GET_STATE_BY_ID,
          variables: {
            getStateByIdId: id,
          },
        });
        console.log(data.getStateById);
        setstate(data.getStateById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getStateById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">DESCRIPCION</label>
            <TextField disabled value={state?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={state?.type.description} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ): (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <RotatingLoopIcon />
      </Box>

))};

export default ViewState;
