import { Box } from "@mui/material"
import { useTheme } from "@mui/material";
import Logo from "../../assets/logo.jpeg"
import Form from "./Form";
import { Navigate } from "react-router-dom";

const Login = () => {
  const theme = useTheme();

  const token = localStorage.getItem("userToken")
/*   console.log(token) */
  if (token) return <Navigate to="/gp/requeriment" replace />

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary[500],
        width: "100vw",
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: "30rem",
          height: "content-fit",
          padding: "1rem",
          paddingBottom: "2rem",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: "1rem",
          boxShadow: 3,
          borderRadius: 1,
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "70%"
          }}
        />
        <Form />
      </Box>
    </Box>

  )
}

export default Login