import { gql } from "@apollo/client";
export const GET_FUEL_REPORT = gql`
query GetFuelSeriesReport($item: ID, $year: Int, $month: Int, $active: ID, $all: Boolean) {
  getFuelSeriesReport(item: $item, year: $year, month: $month, active: $active, all: $all) {
    serie {
      mode
      name
      x
      y
    }
    bar {
      categories
      serie {
        data
        name
      }
    }
  }
}
`;

export const GET_OTHER_FUEL_REPORT = gql`
  query GetSpendOperative(
    $item: ID
    $year: Int
    $month: Int
    $active: ID
    $all: Boolean
  ) {
    getSpendOperative(
      item: $item
      year: $year
      month: $month
      active: $active
      all: $all
    ) {
      mode
      name
      x
      y
    }
  }
`;
export const GET_OTHER_REPORT = gql`
query GetOtherSeriesReport($item: ID, $year: Int, $month: Int, $active: ID, $all: Boolean) {
  getOtherSeriesReport(item: $item, year: $year, month: $month, active: $active, all: $all) {
    bar {
      categories
      serie {
        data
        name
      }
    }
    serie {
      y
      name
      mode
      x
    }
  }
}
`;
export const GET_ACTIVES_BY_ITEM_ID = gql`
  query GetActiveByItemId($getActiveByItemIdId: ID) {
    getActiveByItemId(id: $getActiveByItemIdId) {
      description
      id
    }
  }
`;

export const GET_ITEMS_BY_CLASS_ID = gql`
  query GetItemsByClassId($getItemsByClassIdId: ID) {
    getItemsByClassId(id: $getItemsByClassIdId) {
      description
      active
      id
    }
  }
`;
export const GET_ALL_ASIGNATION_REGISTER = gql`
  query GetAssignationAll {
    getAssignationAll {
      id
      endtime
      enddate
      download
      description
      departure
      begintime
      begindate
      load
      observation
      proyect
      returnn
      type {
        description
      }
      driver {
        postulant {
          name
          surname
          doi
        }
      }
      active {
        description
      }
    }
  }
`;

export const GET_REPORT_FLOTA = gql`
  query GetReportFlota {
    getReportFlota {
      amount
      porcent
      state
      color
    }
  }
`;
