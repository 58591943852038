import React, { useState, useEffect, useMemo } from "react";
import { GET_PERSONAL_AND_POSITION_BY_ID } from "./QueryPersonalRequeriment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  column: {
    flexDirection: "column",
    flex: 1,
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "9px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});
const TemplatePDF = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [personalRequeriment, setPersonalRequeriment] = useState([]);

  useEffect(() => {
    const getPersonalRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_AND_POSITION_BY_ID,
          variables: {
            getPersonalRequerimentAndPersonalByIdId: id,
          },
        });
        console.log(data.getPersonalRequerimentAndPersonalById);
        setPersonalRequeriment(data.getPersonalRequerimentAndPersonalById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPersonalRequeriment(id);
  }, []);
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>
                  REQUERIMIENTO DE PERSONAL
                </Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS DEL SOLICITANTE</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                NOMBRE Y APELLIDOS:
              </Text>
              {/* <Text style={[styles.text, styles.cell]}>Cargo :</Text> */}
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                {personalRequeriment?.personal.postulant.name +
                  " " +
                  personalRequeriment?.personal.postulant.surname}
              </Text>
              {/*  <Text style={[styles.text, styles.cell]}>
              Conductor de Semitrailer
            </Text> */}
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                Area / Departamento :
              </Text>
              {/*   <Text style={[styles.text, styles.cell]}>-</Text> */}
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                {personalRequeriment?.area.description}{" "}
              </Text>
              {/*  <Text style={[styles.text, styles.cell]}>-</Text> */}
            </View>
          </View>
          {/*    <View style={styles.title}>
          <Text>INFORMACION RELEVANTE AL CARGO</Text>
        </View> */}
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS GENERALES DEL PUESTO</Text>
          </View>
          <View
            style={[
              styles.text,
              {
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderTop: "1px solid #000",
                paddingVertical: "3px",
              },
            ]}
          >
            <Text>Motivo del Pedido</Text>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                Renuncia del titular
              </Text>
              <Text style={[styles.cell, styles.text]}>
                Cancelación del contrato
              </Text>
              <Text style={[styles.cell, styles.text]}>
                Se crea un nuevo cargo*
              </Text>
              <Text style={[styles.cell, styles.text]}>
                Promoción, traslado, Licencia
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                {personalRequeriment?.subject.description ==
                "RENUNCIA DEL TITULAR"
                  ? "X"
                  : " "}{" "}
              </Text>

              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description ==
                "CANCELACION DEL CONTRATO"
                  ? "X"
                  : " "}{" "}
              </Text>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description ==
                "SE CREA UN NUEVO CARGO"
                  ? "X"
                  : " "}{" "}
              </Text>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description ==
                "PROMOCION TRASLADO LICENCIA"
                  ? "X"
                  : " "}{" "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                Licencia de Maternidad
              </Text>
              <Text style={[styles.cell, styles.text]}>Incapacidad</Text>
              <Text style={[styles.cell, styles.text]}>Vacaciones</Text>
              <Text style={[styles.cell, styles.text]}>
                Incremento de actividades
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description ==
                "LICENCIA DE MATERNIDAD"
                  ? "X"
                  : " "}{" "}
              </Text>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description == "INCAPACIDAD"
                  ? "X"
                  : " "}{" "}
              </Text>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description == "VACACIONES"
                  ? "X"
                  : " "}{" "}
              </Text>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.subject.description ==
                "INCREMENTO DE ACTIVIDADES"
                  ? "X"
                  : " "}{" "}
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>Cargo</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.position.description}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>N° de Vacantes:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.ngoal}{" "}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.text,
              {
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderTop: "1px solid #000",
                paddingVertical: "3px",
              },
            ]}
          >
            <Text>Plazo de Contrato </Text>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>Permanente</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment.term.description == "PERMANENTE"
                  ? "X"
                  : " "}{" "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>Temporal:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment.term.description == "TEMPORAL" ? "X" : ""}
                {"  "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>Otro:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment.term.description == "OTROS" ? "X" : ""}
                {"  "}
              </Text>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>CONDICIONES DEL PUESTO</Text>
          </View>
          <View
            style={[
              styles.text,
              {
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderTop: "1px solid #000",
                paddingVertical: "3px",
              },
            ]}
          >
            <Text>Lugar de trabajo: </Text>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>Moquegua:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {" "}
                {personalRequeriment.workplace.description == "MOQUEGUA"
                  ? "X"
                  : ""}
                {"  "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>Tacna:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {" "}
                {personalRequeriment?.workplace.description == "TACNA"
                  ? "X"
                  : ""}
                {"  "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>Duración Estimada:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {personalRequeriment?.duration} mes
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                Fecha de incorporación:
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                {new Date(
                  personalRequeriment?.incorporationdate
                ).toLocaleDateString("es-ES")}{" "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                Remuneración a ofrecer:{" "}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.text]}>
                S/. {personalRequeriment?.remuneration}
              </Text>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>OBSERVACIONES </Text>
          </View>

          <View
            style={[
              styles.text,
              {
                border: "1px solid #000",

                paddingVertical: "3px",
                marginBottom: "5px",
              },
            ]}
          >
            <Text> {personalRequeriment?.observation} </Text>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>PERSONAL</Text>
          </View>
          {/* <View style={[styles.title, styles.textTitle]}>
            <View style={[styles.column, { flexFlow: 1 }]}>
              <Text style={[styles.text, styles.cell]}>Nº</Text>
            </View>
            <View style={[styles.column, { flexFlow: 1 }]}>
              <Text style={[styles.text, styles.cell]}>Nombre</Text>
            </View>
            <View style={[styles.column, { flexFlow: 1 }]}>
              <Text style={[styles.text, styles.cell]}>Apellido</Text>
            </View>
            <View style={[styles.column, { flexFlow: 1 }]}>
              <Text style={[styles.text, styles.cell]}>Dni</Text>
            </View>
          </View> */}
          <View style={[styles.table]}>
            <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>
                {personalRequeriment?.personals.map((it, index) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{index + 1}</Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {personalRequeriment?.personals.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.personal.postulant.name.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {personalRequeriment?.personals.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.personal.postulant.surname.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {personalRequeriment?.personals.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.personal.postulant.doi}
                    </Text>
                  </>
                ))}
              </View>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA DEL SOLICITANTE
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA DE ENCARGADO DE AREA
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
