import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses, useTheme } from "@mui/material"
import { ALL_LIST } from "consts/allowedRoles"
import { ALL_USERS } from "./QueryUsers"
import { useQuery } from "@apollo/client"
import { useState } from "react"
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const UsersInfo = () => {

  const theme = useTheme()
  const [list, setList] = useState([])
  const { data, loading } = useQuery(ALL_USERS, {
    onCompleted: (data) => {
      setList(
        ALL_LIST.map(item => {
          return (
            {
              'name': item,
              'total': data.getAllUsers.filter(user => {
                return (
                  user.role === item
                )
              }).length
            }
          )
        })
      )


    }
  })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary[300],
      color: theme.palette.common.white,
      fontWeight: 'bold'
    }
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Box width='100%' textAlign='center'>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={2}>
                DATOS GENERALES
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="center">ROL</StyledTableCell>
              <StyledTableCell align="center">NRO PERSONAS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row" align="center" >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">{row.total}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>




      {/* <Box display='flex' flexDirection='column' gap={1} mt={2} textAlign='left'>
        {
          list.map(item => {
            return (
              <Typography
                display='flex'
                justifyContent='start'
                alignItems='center'
              >
                {item.name} <ArrowRightIcon /> {item.total} personas
              </Typography>
            )
          })
        }

      </Box> */}






    </Box>
  )
}

export default UsersInfo