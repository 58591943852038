import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useApolloClient } from "@apollo/client";
import LoopIcon from "@mui/icons-material/Loop";
import Alerts from "components/Alerts";
import { FormHelperText } from "@mui/material";
import { ITEM_SCHEMA_YUP } from "./YupValidation";
import { GET_CLASS_BY_GROUP_ID, CREATE_ITEM_MUTATION } from "./QueryItem";
const CreateItem = ({
  alertCustom,
  setAlertCustom,
  groups,
  setNewItem,
  unity,
}) => {
  const client = useApolloClient();
  const [classs, setAllClasss] = useState([]);
  const handleClass = async (group) => {
    try {
      const response = await client.mutate({
        mutation: GET_CLASS_BY_GROUP_ID,
        variables: {
          group: group,
        },
      });
      setAllClasss(response.data.getClassByGroupId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const formikItem = useFormik({
    initialValues: {
      group: "",
      classs: "",
      description: "",
      coloquial: "",
      unity:"",
      specification:""
    },
    validationSchema: ITEM_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { classs, coloquial, description, group,unity, specification} = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_ITEM_MUTATION,
          variables: {
            classs: classs,
            group: group,
            description: description.toUpperCase(),
            coloquial: coloquial?.toUpperCase(),
            unity:unity,
            specification:specification.toUpperCase()
          },
        });
        setNewItem(response.data.createItem);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  /*  const handleSelectChange = (group) => {
    formikItem.setFieldValue(`group`, group);
  }; */
  return (
    <form onSubmit={formikItem.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
          CREAR ITEM
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">GRUPO</InputLabel>
            <Select
              id="group"
              value={formikItem.values.group}
              name="group"
              label="GRUPO"
              onChange={(e) => {
                formikItem.setFieldValue("group", e.target.value);
                handleClass(e.target.value);
              }}
              error={
                formikItem.touched.group && Boolean(formikItem.errors.group)
              }
            >
              {groups?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikItem.touched.group && formikItem.errors.group ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikItem.touched.group && formikItem.errors.group}
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">CLASE</InputLabel>
            <Select
              id="classs"
              label="CLASE"
              value={formikItem.values.classs}
              name="classs"
              onChange={formikItem.handleChange}
              error={
                formikItem.touched.classs && Boolean(formikItem.errors.classs)
              }
            >
              {classs?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikItem.touched.classs && formikItem.errors.classs ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikItem.touched.classs && formikItem.errors.classs}
              </FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            id="description"
            name="description"
            label="DESCRIPCION"
            variant="outlined"
            value={formikItem.values.description}
            onChange={formikItem.handleChange}
            error={
              formikItem.touched.description &&
              Boolean(formikItem.errors.description)
            }
            helperText={
              formikItem.touched.description && formikItem.errors.description
            }
          />
        {/*   <TextField
            id="coloquial"
            name="coloquial"
            label="COLOQUIAL"
            variant="outlined"
            value={formikItem.values.coloquial}
            onChange={formikItem.handleChange}
            error={
              formikItem.touched.coloquial &&
              Boolean(formikItem.errors.coloquial)
            }
            helperText={
              formikItem.touched.coloquial && formikItem.errors.coloquial
            }
          /> */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">UNIDAD</InputLabel>
            <Select
              id="unity"
              value={formikItem.values.unity}
              name="unity"
              onChange={formikItem.handleChange}
              label="UNIDAD"
              error={
                formikItem.touched.unity && Boolean(formikItem.errors.unity)
              }
            >
              {unity?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikItem.touched.unity && formikItem.errors.unity ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikItem.touched.unity && formikItem.errors.unity}
              </FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            id="specification"
            name="specification"
            label="ESPECIFICACION"
            variant="outlined"
            value={formikItem.values.specification}
            onChange={formikItem.handleChange}
            error={
              formikItem.touched.specification &&
              Boolean(formikItem.errors.specification)
            }
            helperText={
              formikItem.touched.specification && formikItem.errors.specification
            }
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikItem.isSubmitting}
          >
            {formikItem.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateItem;
