import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import Alerts from "components/Alerts";
import TextField from "@mui/material/TextField";
import { Box, useTheme, useMediaQuery } from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  GET_DOCUMENT_TRAINING_STATES,
  GET_LIST_DOCUMENT_TRAINING,
  GET_DOCUMENT_PERSONAL_STATES,
  GET_LIST_DOCUMENT_PERSONAL,
} from "./QueryDocument";
import ViewDocumentPersonal from "./ViewDocumentPersonal";
const StateDocument = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();
  const [documentTraining, setDocumentTraining] = useState(null);
  const [documentListTraining, setDocumentListTraining] = useState(null);

  const [documentPersonal, setDocumentPersonal] = useState(null);
  const [documentListPersonal, setDocumentListPersonal] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  useEffect(() => {
    const FUNCTION_GET_DOCUMENT_TRAINING = async () => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_TRAINING_STATES,
          fetchPolicy: "network-only",
        });
        setDocumentTraining(data.getDocumentTraining);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_DOCUMENT_LIST_TRAINING = async () => {
      try {
        const { data } = await client.query({
          query: GET_LIST_DOCUMENT_TRAINING,
          fetchPolicy: "network-only",
        });
        setDocumentListTraining(data.getDocumentListTraining);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_DOCUMENT_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_PERSONAL_STATES,
          fetchPolicy: "network-only",
        });
        console.log(data.getDocumentPersonal)
        setDocumentPersonal(data.getDocumentPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_DOCUMENT_LIST_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_LIST_DOCUMENT_PERSONAL,
          fetchPolicy: "network-only",
        });
        setDocumentListPersonal(data.getDocumentListPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_DOCUMENT_TRAINING();
    FUNCTION_GET_DOCUMENT_LIST_TRAINING();
    FUNCTION_GET_DOCUMENT_LIST_PERSONAL();
    FUNCTION_GET_DOCUMENT_PERSONAL();
  }, []);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {alertCustom?.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
        <Header title="GESTION DE PERSONAS" subtitle="personal/estado de documentos" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
         
         <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab style={{fontWeight:900}} label={"DOCUMENTOS PERSONALES"} value={0} />
                <Tab style={{fontWeight:900}} label={"DOCUMENTOS DE CAPACITACIÓN"} value={1} />
              </TabList>
            </Box>
            <TabPanel value={0}>
              <Box
              /*   gridColumn="span 12"
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt} */
                p="1rem"
               /*  borderRadius="0.55rem" */
              >
               {/*  <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  HISTORIAL DOCUMENTOS PERSONALES
                </Box> */}
                {/*  <ViewDocumentPersonal
              documentListPersonal={documentPersonal}
              documentPersonal={documentListPersonal}
            /> */}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{fontWeight:900}} >Nombre y Apellidos </TableCell>
                        {documentListPersonal?.map((el) => (
                          <TableCell style={{fontWeight:900}}>{el.description} </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentPersonal?.map((row) => (
                        <TableRow
                          key={row.personal.doi}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.personal.name + " " + row.personal.surname}
                          </TableCell>
                          {documentListPersonal?.map((el) => {
                            let idx = row.document.findIndex(
                              (item) => item.document.id == el.id
                            );
                            return idx !== -1 ? (
                              <TableCell>
                                <Box
                                  borderRadius={"50%"}
                                  width={"30px"}
                                  height={"30px"}
                                  backgroundColor={
                                    row.document[idx].state.description ==
                                    "VIGENTE"
                                      ? "#28a745"
                                      : row.document[idx].state.description ==
                                        "POR ACTUALIZAR"
                                      ? "#ffc107"
                                      : row.document[idx].state.description ==
                                        "POR VENCER"
                                      ? "#dc3545"
                                      : "#000"
                                  }
                                ></Box>
                              </TableCell>
                            ) :  <TableCell>{""} </TableCell>;
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
            <TabPanel value={1}>
              <Box
            /*     gridColumn="span 12"
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt} */
                p="1rem"
             /*    borderRadius="0.55rem" */
              >
             {/*    <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                  HISTORIAL DOCUMENTOS DE ENTRENAMIENTO
                </Box> */}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell  style={{fontWeight:900}} >Nombre y Apellidos </TableCell>
                        {documentListTraining?.map((el) => (
                          <TableCell  style={{fontWeight:900}} >{el.description} </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentTraining?.map((row) => (
                        <TableRow
                          key={row.personal.doi}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" >
                            {row.personal.name + " " + row.personal.surname}
                          </TableCell>
                          {documentListTraining?.map((el) => {
                            let idx = row.document.findIndex(
                              (item) => item.document.id == el.id
                            );
                            return idx !== -1 ? (
                              <TableCell>
                                <Box
                                  borderRadius={"50%"}
                                  width={"30px"}
                                  height={"30px"}
                                  backgroundColor={
                                    row.document[idx].state.description ==
                                    "VIGENTE"
                                      ? "#28a745"
                                      : row.document[idx].state.description ==
                                        "POR ACTUALIZAR"
                                      ? "#ffc107"
                                      : row.document[idx].state.description ==
                                        "POR VENCER"
                                      ? "#dc3545"
                                      : "#000"
                                  }
                                ></Box>
                              </TableCell>
                            ) : null;
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
          </TabContext>


          </Box>
       
        </Box>
      </Box>
    </>
  );
};

export default StateDocument;
