import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoopIcon from "@mui/icons-material/Loop";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { useApolloClient } from "@apollo/client";
import { EVALUATION_SCHEMA_YUP } from "./YupValidation";
import { CREATE_EVALUATION } from "./QueryEvaluationSelection";
import { useUser } from "hooks/useUser";
const CreateEvaluationSupplier = ({
  allSupplier,
  criterionEvaluation,
  allpersonal,
  setnewEvaluation,
  setAlertCustom,
  alertCustom,
}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const today = new Date();
  const client = useApolloClient();
  const [error, setError] = useState(false);
  const { user } = useUser();
  const autocompleteRef = useRef(null);
  const formikEvaluation = useFormik({
    initialValues: {
      evaluator: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      supplier1: "",
      criteria1: [
        { score: "", criterion: "" },
        { score: "", criterion: "" },
      ],
      supplier2: "",
      criteria2: [
        { score: "", criterion: "" },
        { score: "", criterion: "" },
      ],
      supplier3: "",
      criteria3: [
        { score: "", criterion: "" },
        { score: "", criterion: "" },
      ],
    },
    validationSchema: EVALUATION_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const {
        evaluator,
        supplier1,
        criteria1,
        supplier2,
        criteria2,
        supplier3,
        criteria3,
      } = values;

      let num1 = criteria1.reduce(
        (acumulador, elemento) => acumulador + Number(elemento.score),
        0
      );
      let num2 = criteria2.reduce(
        (acumulador, elemento) => acumulador + Number(elemento.score),
        0
      );
      let num3 = criteria3.reduce(
        (acumulador, elemento) => acumulador + Number(elemento.score),
        0
      );
      let winnerscore;
      let winnersupplier;
      let uniquemax = true;

      const maximo = Math.max(num1, num2, num3);

      if (num1 === maximo) {
        if (num2 === maximo || num3 === maximo) {
          uniquemax = false;
        } else {
          winnersupplier = supplier1;
          winnerscore = (num1 / criterionEvaluation.length).toFixed(2);
        }
      } else if (num2 === maximo) {
        if (num1 === maximo || num3 === maximo) {
          uniquemax = false;
        } else {
          winnersupplier = supplier2;
          winnerscore = (num2 / criterionEvaluation.length).toFixed(2);
        }
      } else if (num3 === maximo) {
        if (num1 === maximo || num2 === maximo) {
          uniquemax = false;
        } else {
          winnersupplier = supplier3;
          winnerscore = (num3 / criterionEvaluation.length).toFixed(2);
        }
      }
      if (!uniquemax) {
        setError(true);
      } else {
        setError(false);
        const winner = {
          score: Number(winnerscore),
          supplier: winnersupplier,
        };
        console.log(winner);
        setSubmitting(true);

        try {
          const response = await client.mutate({
            mutation: CREATE_EVALUATION,
            variables: {
              evaluator: evaluator.id,
              supplier1,
              criteria1: criteria1.map(
                (el) =>
                  new Object({
                    score: Number(el.score),
                    criterion: el.criterion,
                  })
              ),
              supplier2,
              criteria2: criteria2.map(
                (el) =>
                  new Object({
                    score: Number(el.score),
                    criterion: el.criterion,
                  })
              ),
              supplier3,
              criteria3: criteria3.map(
                (el) =>
                  new Object({
                    score: Number(el.score),
                    criterion: el.criterion,
                  })
              ),
              winner,
            },
          });

          setnewEvaluation(response.data.createEvaluation);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          if (user.admin) {
            autocompleteRef.current.value = "";
          }

          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });
  return (
    <form onSubmit={formikEvaluation.handleSubmit}>
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap="20px">
          {user.admin ? (
            <>
              {allpersonal?.length ? (
                <Autocomplete
                  freeSolo
                  id="personal"
                  name="personal"
                  disableClearable
                  ref={autocompleteRef}
                  value={formikEvaluation.values.evaluator.label}
                  options={allpersonal?.map(
                    (option) =>
                      new Object({
                        label:
                          "nombres:" +
                          option.postulant.name +
                          " / " +
                          "apellidos:" +
                          option.postulant.surname +
                          " / " +
                          "dni:" +
                          option.postulant.doi,
                        value: option.id,
                      })
                  )}
                  onChange={(event, newValue) => {
                    formikEvaluation.setFieldValue("evaluator", {
                      label: newValue.label,
                      id: newValue.value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar por nombre/apellido/dni"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={
                        formikEvaluation.touched.evaluator?.id &&
                        Boolean(formikEvaluation.errors.evaluator?.id)
                      }
                      helperText={
                        formikEvaluation.touched.evaluator?.id &&
                        formikEvaluation.errors.evaluator?.id
                      }
                    />
                  )}
                />
              ) : (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    No hay Datos para mostrar
                  </p>
                </FormHelperText>
              )}
            </>
          ) : null}
        </Box>
        <Box mt={"20px"}>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        PROVEEDOR
                      </InputLabel>
                      <Select
                        id="supplier1"
                        value={formikEvaluation.values.supplier1}
                        name="supplier1"
                        onChange={formikEvaluation.handleChange}
                        error={
                          formikEvaluation.touched.supplier1 &&
                          Boolean(formikEvaluation.errors.supplier1)
                        }
                      >
                        {allSupplier?.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.businessname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formikEvaluation.touched.supplier1 &&
                      formikEvaluation.errors.supplier1 ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formikEvaluation.touched.supplier1 &&
                            formikEvaluation.errors.supplier1}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </TableCell>
                  <TableCell align="">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        PROVEEDOR
                      </InputLabel>
                      <Select
                        id="supplier2"
                        value={formikEvaluation.values.supplier2}
                        name="supplier2"
                        onChange={formikEvaluation.handleChange}
                        error={
                          formikEvaluation.touched.supplier2 &&
                          Boolean(formikEvaluation.errors.supplier2)
                        }
                      >
                        {allSupplier?.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.businessname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formikEvaluation.touched.supplier2 &&
                      formikEvaluation.errors.supplier2 ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formikEvaluation.touched.supplier2 &&
                            formikEvaluation.errors.supplier2}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </TableCell>
                  <TableCell align="">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        PROVEEDOR
                      </InputLabel>
                      <Select
                        id="supplier3"
                        value={formikEvaluation.values.supplier3}
                        name="supplier3"
                        onChange={formikEvaluation.handleChange}
                        error={
                          formikEvaluation.touched.supplier3 &&
                          Boolean(formikEvaluation.errors.supplier3)
                        }
                      >
                        {allSupplier?.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.businessname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formikEvaluation.touched.supplier3 &&
                      formikEvaluation.errors.supplier3 ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formikEvaluation.touched.supplier3 &&
                            formikEvaluation.errors.supplier3}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {criterionEvaluation.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <TextField
                        id={`criteria1[${index}].score`}
                        name={`criteria1[${index}].score`}
                        label=""
                        variant="outlined"
                        type="number"
                        value={
                          formikEvaluation.values.criteria1?.[index]?.score
                        }
                        onChange={(e) => {
                          formikEvaluation.setFieldValue(
                            `criteria1[${index}].score`,
                            e.target.value
                          );
                          formikEvaluation.setFieldValue(
                            `criteria1[${index}].criterion`,
                            row.id
                          );
                        }}
                        error={
                          formikEvaluation.touched.criteria1?.[index]?.score &&
                          Boolean(
                            formikEvaluation.errors.criteria1?.[index]?.score
                          )
                        }
                        helperText={
                          formikEvaluation.touched.criteria1?.[index]?.score &&
                          formikEvaluation.errors.criteria1?.[index]?.score
                        }
                      />
                    </TableCell>
                    <TableCell align="">
                      <TextField
                        id={`criteria2[${index}].score`}
                        name={`criteria2[${index}].score`}
                        label=""
                        variant="outlined"
                        type="number"
                        value={
                          formikEvaluation.values.criteria2?.[index]?.score
                        }
                        onChange={(e) => {
                          formikEvaluation.setFieldValue(
                            `criteria2[${index}].score`,
                            e.target.value
                          );
                          formikEvaluation.setFieldValue(
                            `criteria2[${index}].criterion`,
                            row.id
                          );
                        }}
                        error={
                          formikEvaluation.touched.criteria2?.[index]?.score &&
                          Boolean(
                            formikEvaluation.errors.criteria2?.[index]?.score
                          )
                        }
                        helperText={
                          formikEvaluation.touched.criteria2?.[index]?.score &&
                          formikEvaluation.errors.criteria2?.[index]?.score
                        }
                      />
                    </TableCell>
                    <TableCell align="">
                      <TextField
                        id={`criteria3[${index}].score`}
                        name={`criteria3[${index}].score`}
                        label=""
                        variant="outlined"
                        type="number"
                        value={
                          formikEvaluation.values.criteria3?.[index]?.score
                        }
                        onChange={(e) => {
                          formikEvaluation.setFieldValue(
                            `criteria3[${index}].score`,
                            e.target.value
                          );
                          formikEvaluation.setFieldValue(
                            `criteria3[${index}].criterion`,
                            row.id
                          );
                        }}
                        error={
                          formikEvaluation.touched.criteria3?.[index]?.score &&
                          Boolean(
                            formikEvaluation.errors.criteria3?.[index]?.score
                          )
                        }
                        helperText={
                          formikEvaluation.touched.criteria3?.[index]?.score &&
                          formikEvaluation.errors.criteria3?.[index]?.score
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {error ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                tTiene que haber un ganador en la seleccion.
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"15px"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikEvaluation.isSubmitting}
          >
            {formikEvaluation.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateEvaluationSupplier;
