import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Alerts from "components/Alerts";
import LoopIcon from '@mui/icons-material/Loop';
import {
  GET_ALL_ADMIN_ACTIVE_BY_ID,
  UPDATE_ADMIN_ACTIVE_REQUERIMENT_BY_ID,
} from "./QueryAdminLogistic";
import { useApolloClient } from "@apollo/client";
const AssignLogisticRequeriment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [saved, setsaved] = useState(true);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [logisticRequeriment, setLogisticRequeriment] = useState([]);
  useEffect(() => {
    const getLogisticRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ADMIN_ACTIVE_BY_ID,
          variables: {
            getActiveRequerimentByIdId: id,
          },
        });
        console.log(data.getActiveRequerimentById);
        setLogisticRequeriment(data.getActiveRequerimentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getLogisticRequeriment(id);
  }, []);
  const handlePersonalRequerimentReject = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_ACTIVE_REQUERIMENT_BY_ID,
        variables: {
          updateRequerimentLogisticId: id,
          type: 0,
          observation:textFieldValue
        },
      });
      console.log(response.data.updateRequerimentLogistic);
      setNewAssignRequeriment(response.data.updateRequerimentLogistic);

      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handlePersonalRequerimentApproved = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_ACTIVE_REQUERIMENT_BY_ID,
        variables: {
          updateRequerimentLogisticId: id,
          type: 1,
          observation:textFieldValue
        },
      });
      console.log(response.data.updateRequerimentLogistic);
      setNewAssignRequeriment(response.data.updateRequerimentLogistic);

      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>    
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">AREA</label>
            <TextField disabled value={logisticRequeriment?.area.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PRIORIDAD</label>
            <TextField
              disabled
              value={logisticRequeriment?.priority.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">MOTIVO</label>
            <TextField disabled value={logisticRequeriment?.subject} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA DE REQUERIMIENTO</label>
            <TextField
              disabled
              value={new Date(logisticRequeriment.date).toLocaleDateString(
                "es-ES"
              )}
            />{" "}
            {/* Falta arreglar las fechas */}
          </Box>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Activo</TableCell>
                  <TableCell align="">Descripcion</TableCell>
                  <TableCell align="">Cantidad</TableCell>                
                </TableRow>
              </TableHead>
              <TableBody>
                {logisticRequeriment.item.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.active}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">{row.amount}</TableCell>                
                  </TableRow>
                  /* </RadioGroup> */
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box my={"20px"}>
        <TextField
        label="OBSERVACION"
        variant="outlined"
        fullWidth
           multiline
          rows={6}
        value={textFieldValue}
        onChange={(event) => setTextFieldValue(event.target.value)}
      />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"20px"}
        >
          <Button
            variant="contained"
            color="error"
             disabled={!saved}
            onClick={handlePersonalRequerimentReject}
          >
              {!saved ?<LoopIcon/>: "DESAPROBAR"}
          </Button>
          <Button
            variant="contained"
            color="success"
             disabled={!saved}
            onClick={handlePersonalRequerimentApproved}
          >
             {!saved ?<LoopIcon/>: "APROBAR"}
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default AssignLogisticRequeriment;
