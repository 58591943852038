import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import Alerts from "components/Alerts";
import { DataGrid } from "@mui/x-data-grid";
import { ALL_USERS, DELETE_USER } from "./QueryUsers";
import { useState, useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserUpdate from "./UserUpdate";
import StructureModules from "./StructureModules";
import { useUser } from "hooks/useUser";
import CreateIcon from "@mui/icons-material/Create";
import EditRol from "./EditRol";
import { useApolloClient } from "@apollo/client";
import ModalUtil from "components/ModalUtil";
const UsersList = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [openDdeleteModal, setOpenDdeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [email, setEmail] = useState(null);
  const [viewStructure, setViewStructure] = useState(false);
  const [newRoleUser, setNewRoleUser] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const [module, setModule] = useState(null);
  const [id, setId] = useState(false);
  const { user: currentUser } = useUser();
  const [modalEdit, setModalEdit] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const pushEmail = (email) => {
    setOpenDdeleteModal(true);
    setEmail(email);
  };

  const popEmail = () => {
    setOpenDdeleteModal(false);
    setEmail(null);
  };

  const viewModule = (submodules) => {
    // setInfoUser(submodules)
    setModule(submodules);
    setViewStructure(true);
  };

  const updateUserData = (email, role, submodules) => {
    setOpenUpdateModal(true);
    setInfoUser({ email, role, submodules });
  };
  useEffect(() => {
    const GET_ALL_USER = async () => {
      try {
        const { data } = await client.query({
          query: ALL_USERS,
          fetchPolicy: "network-only",
        });
        setRows(data.getAllUsers);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
      /*   useQuery(ALL_USERS, {
        onCompleted: (data) => {
          setRows(
            data.getAllUsers.map((row, idx) => {
              return {
                ...row,
              };
            })
          );
        },
      }); */
    };

    GET_ALL_USER();
  }, [newRoleUser]);

  const [userDeleted, { data }] = useMutation(DELETE_USER, {
    onCompleted: () => {
      setConfirm(true);

      setTimeout(() => {
        setConfirm(false);
      }, 1500);
    },
    refetchQueries: [
      {
        query: ALL_USERS,
      },
    ],
  });

  const handleDeleted = () => {
    setOpenDdeleteModal(false);
    userDeleted({ variables: { email } });
  };
  const handleOpenModalEdit = (id) => {
    setModalEdit(true);
    setId(id);
  };

  const handleCloseModalEdit = () => {
    setModalEdit(false);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "personal.postulant.name",
      headerName: "NOMBRES",
      flex: 1,
      renderCell: (params) => params.row.personal.postulant.name,
    },
    {
      field: "personal.postulant.surname",
      headerName: "APELLIDOS",
      flex: 1,
      renderCell: (params) => params.row.personal.postulant.surname,
    },
    {
      field: "personal.postulant.email",
      headerName: "EMAIL",
      flex: 1,
      renderCell: (params) => params.row.personal.postulant.email,
    },
    {
      field: "role",
      headerName: "ROL",
      flex: 1,
    },
    {
      field: "enabled",
      headerName: "HABILITADO",
      flex: 1,
      renderCell: (params) => (params.row.enabled ? "SI" : "NO"),
    },
    {
      field: "submodules",
      headerName: "MODULOS",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => viewModule(params.row.submodules)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => handleOpenModalEdit(params.row.id)}>
              <CreateIcon color="info" />
            </IconButton>
          </>
        );
      },
    },

    /*  {
      field: 'action',
      headerName: 'ACCION',
      flex: 1,
      renderCell: (params) => {

        return params.row.email !== currentUser.email ? (
          <>
            <IconButton
              onClick={() => pushEmail(params.row.email)}
              aria-label="delete"
            >
              <DeleteForeverIcon
                fontSize="medium"
                color="error"
              />
            </IconButton>
            <IconButton
              onClick={() => updateUserData(params.row.email, params.row.role, params.row.submodules)}
              aria-label="delete"
            >
              <EditIcon
                fontSize="medium"
                sx={{ color: theme.palette.secondary[300] }}
              />
            </IconButton>
          </>
        )
          : (
            <>
              <IconButton
                onClick={() => updateUserData(params.row.email, params.row.role, params.row.submodules)}
                aria-label="delete"
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.secondary[300] }}
                />
              </IconButton>
            </>
          )

      }
    }, */
  ];

  return (
    <>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <ModalUtil
        isOpen={modalEdit}
        onClose={handleCloseModalEdit}
        Component={EditRol}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewRoleUser,
          })
        }
        id={id}
      ></ModalUtil>
      {data ? (
        <Modal
          open={confirm}
          onClose={() => setConfirm(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              USUARIO ELIMINADO!
            </Typography>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
      {openDdeleteModal ? (
        <Dialog
          open={openDdeleteModal}
          onClose={() => setOpenDdeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Eliminar Registro"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Esta seguro de eliminar este registro?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={popEmail}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleted}
              autoFocus
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      {/* ------------------------------------------------------------------------ */}
      {openUpdateModal ? (
        <Modal
          open={openUpdateModal}
          onClose={() => setOpenUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <UserUpdate user={infoUser} setUpdate={setOpenUpdateModal} />
        </Modal>
      ) : (
        <></>
      )}
      {viewStructure ? (
        <Modal
          open={viewStructure}
          onClose={() => setViewStructure(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StructureModules module={module} />
        </Modal>
      ) : (
        <></>
      )}

      <Box
        borderRadius={2}
        height="100%"
        width="100%"
        sx={{
          backgroundColor: "white",
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          pt="1.5rem"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" fontWeight="bold">
            Historial de Usuarios
          </Typography>
        </Box>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
        />
      </Box>
    </>
  );
};

export default UsersList;
