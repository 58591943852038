import * as Yup from "yup";
export const ITEM_REQUERIMENT_SCHEMA_YUP = Yup.object().shape({
  personal:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  area: Yup.string(),
  date: Yup.date().required("Requirido"),
  priority: Yup.string().required("Requirido"),
  subject: Yup.string().required("Requirido"),
  costcenter:Yup.string(),
  incomeRequerimentItem: Yup.array()
    .of(Yup.string().required("Requiredo"))
    .min(1),
});
