import * as Yup from "yup";
export const TRAINING_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  subject: Yup.string().required("Requiredo"),
  type: Yup.string().required("Requiredo"),
  description: Yup.string(),
  date: Yup.date().required("Requirido"),
  beginTime: Yup.string().required("Requiredo"),
  endTime: Yup.string().required("Requiredo"),
  goal: Yup.string().required("Requiredo"),
  company: Yup.string().required("Requiredo"),
  tools: Yup.string().required("Requiredo"),
  place: Yup.string().required("Requiredo"),
  observation: Yup.string(),
  aggrement: Yup.string(),
  participant: Yup.array().of(Yup.string()).required("Requiredo").min(1),
});
