import { gql } from "@apollo/client";


export const GET_USER_INFO = gql`
  query {
  getUserByToken {
    email
    fullname
    lastname
    image
    role
  }
}
`

export const UPDATE_USER = gql`
  mutation($fullname: String, $lastname:String){
    updateUser(
      fullname: $fullname,
      lastname: $lastname,
    )
  }
`