import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
const ViewActive = ({ activecomponent }) => {
  const { dateFormat } = useSettings();
  const [expanded, setExpanded] = useState(false);
console.log(dateFormat)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Detalles del Vehiculo
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              {/*  <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Respuesta</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                <TableRow>
                  <TableCell align="">Identificador</TableCell>
                  <TableCell align="">{activecomponent.active?.id} </TableCell>
                  <TableCell align=""> Incio de Garania</TableCell>
                  <TableCell align="">
                    {DateFormatting(activecomponent?.active?.beginguarante,dateFormat)}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="">Activo</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.description}{" "}
                  </TableCell>
                  <TableCell align=""> Fin de Garantia</TableCell>
                  <TableCell align="">
                    {" "}
                    {DateFormatting(activecomponent?.active?.endgurante,dateFormat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="">Clase</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.item?.classs.description}
                  </TableCell>
                  <TableCell align="">Numero de Serie</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.serie}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="">Grupo</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.item?.group?.description}
                  </TableCell>
                  <TableCell align="">Parte</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.parte}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="">Condicion</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.condition?.description}{" "}
                  </TableCell>
                  <TableCell align="">Marca</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.brand}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="">Placa</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.plate}{" "}
                  </TableCell>
                {/*   <TableCell align="">Precio Unitario</TableCell>
                  <TableCell align="">
                    {activecomponent.active?.cost}{" "}
                  </TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {activecomponent.system?.map((row, index) => (
        <Accordion
          expanded={expanded === "panel" + index + 2}
          onChange={handleChange("panel" + index + 2)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={"panel" + index + 2 + "a-header"}
          >
            {row.title}
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="">N°</TableCell>
                    <TableCell align="">Activo</TableCell>
                    <TableCell align="">Clase</TableCell>
                    <TableCell align="">Serie</TableCell>
                    <TableCell align="">Condicion</TableCell>
                    {/* <TableCell align="">Inicio de Garantia</TableCell> */}
                    <TableCell align="">Fin de Garantia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.data?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row.novehicle?.description}
                      </TableCell>
                      <TableCell align="">{row.novehicle?.serie}</TableCell>
                      <TableCell align="">{row.novehicle?.serie}</TableCell>
                      <TableCell align="">
                        {row.novehicle?.condition?.description}
                      </TableCell>
                      {/*  <TableCell align="">
                        {row.novehicle?.beginguarante}
                      </TableCell> */}
                      <TableCell align="">
                        {DateFormatting(row.novehicle?.endgurante,dateFormat)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ViewActive;
