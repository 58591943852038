import { Box, Button, InputAdornment, TextField } from "@mui/material"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { USER_SCHEMA_YUP } from "./YupValidation";
import { useNavigate } from "react-router-dom";
import { LOGIN_USER } from "./QueryLoginUser";
import { useMutation } from "@apollo/client";
import Loader from "components/Loader";
import { FormHelperText } from "@mui/material";
import Alerts from "components/Alerts";
const Form = () => {

  const navigate = useNavigate()
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const [login, { loading }] = useMutation(LOGIN_USER, {
    onCompleted: async (data) => {
      const { value } = data.login
      localStorage.setItem('userToken', value)
      console.log(value)
      navigate('/gp/requeriment')
    },
    onError: (error) => {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
  
    }
  })

  const userFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: USER_SCHEMA_YUP,
    onSubmit: (values) => {
      const { email, password } = values

      login({ variables: { email, password } })
    }
  })

  return (
    <Box
      component="form"
      onSubmit={userFormik.handleSubmit}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
        gap: "2rem"
      }}
    >
      {
        loading ? (
          <Loader />
        ) : <></>
      }
      
      <TextField
        id="email"
        label="email"
        type="email"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlinedIcon />
            </InputAdornment>
          )
        }}
        value={userFormik.values.email}
        onChange={userFormik.handleChange}
        onBlur={userFormik.handleBlur}
        error={userFormik.touched.email && Boolean(userFormik.errors.email)}
        helperText={userFormik.touched.email && userFormik.errors.email}
      />

      <TextField
        id="password"
        label="contraseña"
        type="password"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          )
        }}
        value={userFormik.values.password}
        onChange={userFormik.handleChange}
        onBlur={userFormik.handleBlur}
        error={userFormik.touched.password && Boolean(userFormik.errors.password)}
        helperText={userFormik.touched.password && userFormik.errors.password}
      />
 {alertCustom?.state &&   <FormHelperText
          sx={{ color: "#bf3333",textAlign:"center",width:"100%" }}
        >
          <p
            style={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            Datos incorrectos o usuario no registrado.
          </p>
        </FormHelperText>}
      <Button
        type="submit"
        variant="contained"
        color="success"
        fullWidth
        sx={{
          py: 1.5
        }}
      >
        Iniciar Sesión
      </Button>

    </Box>
  )
}

export default Form