import * as Yup from "yup";
export const SINTOMATOLOGY_SCHEMA_YUP = Yup.object().shape({
  operator: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  criterion: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Requiredo"),
        question: Yup.string(),
      })
    )
    .required("Requiredo"),
});

export const INDICENT_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  description: Yup.string().required("Requirido"),
  type: Yup.string().required("Requirido"),
  date: Yup.date().required("Requirido"),
});
