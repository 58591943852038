import React, { useState } from "react";
import {
  Box,
  Button,

} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormHelperText } from "@mui/material";
import { SPOUSE_SCHEMA_YUP } from "./YupValidation";
import LoopIcon from '@mui/icons-material/Loop';
import FormControl from "@mui/material/FormControl";
import Alerts from "components/Alerts";

import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
  CREATE_SPOUSE,
} from "./QueryPersonal";
const CreateSpouse = ({
  alertCustom,
  setAlertCustom,
  departament,
  professionLevel,
  professionState,
  setNewSpouse,
  id,
  setErrorId,
}) => {

  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;

  const client = useApolloClient();
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const formikSpouse = useFormik({
    initialValues: {
      personal: "",
      name: "",
      surname: "",
      dob: null,
      departament: "",
      province: "",
      district: "",
      doi: "",
      ruc: "",
      profession: "",
      professionlevel: "",
      professionstate: "",
      occupation: "",
      workplace: "",
      address: "",
      phone: "",
    },
    validationSchema: SPOUSE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const {
          name,
          surname,
          dob,
          departament,
          province,
          district,
          doi,
          ruc,
          profession,
          professionlevel,
          professionstate,
          occupation,
          workplace,
          address,
          phone,
        } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_SPOUSE,
            variables: {
              personal: id,
              name: name.toUpperCase(),
              surname: surname.toUpperCase(),
              dob,
              departament: departament ? departament : null,
              province: province ? province : null,
              district: district ? district : null,
              doi: doi ? Number(doi) : null,
              ruc: ruc,
              profession: profession?.toUpperCase(),
              professionlevel: professionlevel ? professionlevel : null,
              professionstate: professionstate ? professionstate : null,
              occupation: occupation?.toUpperCase(),
              workplace: workplace?.toUpperCase(),
              address: address?.toUpperCase(),
              phone,
            },
          });
          setNewSpouse(response.data.createSpouse);

          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
      });
      console.log(response);
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (
    <form onSubmit={formikSpouse.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap="20px"
      >
        <TextField
          id="name"
          name="name"
          label="NOMBRES"
          variant="outlined"
          value={formikSpouse.values.name}
          onChange={formikSpouse.handleChange}
          error={formikSpouse.touched.name && Boolean(formikSpouse.errors.name)}
          helperText={formikSpouse.touched.name && formikSpouse.errors.name}
        />
        <TextField
          id="surname"
          name="surname"
          label="APELLIDOS"
          variant="outlined"
          value={formikSpouse.values.surname}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.surname && Boolean(formikSpouse.errors.surname)
          }
          helperText={
            formikSpouse.touched.surname && formikSpouse.errors.surname
          }
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikSpouse.values.dob}
            id="dob"
            name="dob"
            label="FECHA DE NACIMIENTO"
            inputFormat="dd-MM-yyyy"
            minDate={mindate}
            maxDate={
              new Date(
                today.getFullYear() - minAge,
                today.getMonth(),
                today.getDate()
              )
            }
            onChange={(val) => {
              formikSpouse.setFieldValue("dob", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DEPARTAMENTO</InputLabel>
          <Select
            id="departament"
            value={formikSpouse.values.departament}
            name="departament"
            onChange={(e) => {
              formikSpouse.setFieldValue("departament", e.target.value);
              handleProvince(e.target.value);
            }}
            error={
              formikSpouse.touched.departament &&
              Boolean(formikSpouse.errors.departament)
            }
            label="DEPARTAMENTO"
          >
            {departament?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikSpouse.touched.departament &&
            formikSpouse.errors.departament ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikSpouse.touched.departament &&
                formikSpouse.errors.departament}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PROVINCIA</InputLabel>
          <Select
            id="province"
            value={formikSpouse.values.province}
            name="province"
            label="PROVINCIA"
            onChange={(e) => {
              formikSpouse.setFieldValue("province", e.target.value);
              handleDistrict(e.target.value);
            }}
            error={
              formikSpouse.touched.province &&
              Boolean(formikSpouse.errors.province)
            }
          >
            {province?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikSpouse.touched.province && formikSpouse.errors.province ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikSpouse.touched.province && formikSpouse.errors.province}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DISTRITO</InputLabel>
          <Select
            id="district"
            value={formikSpouse.values.district}
            name="district"

            label="DISTRITO"
            onChange={formikSpouse.handleChange}
            error={
              formikSpouse.touched.district &&
              Boolean(formikSpouse.errors.district)
            }
          >
            {district?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikSpouse.touched.district && formikSpouse.errors.district ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikSpouse.touched.district && formikSpouse.errors.district}
            </FormHelperText>
          ) : null}
        </FormControl>

        <TextField
          id="doi"
          name="doi"
          label="DNI"
          variant="outlined"
          value={formikSpouse.values.doi}
          onChange={formikSpouse.handleChange}
          error={formikSpouse.touched.doi && Boolean(formikSpouse.errors.doi)}
          helperText={formikSpouse.touched.doi && formikSpouse.errors.doi}
        />
        <TextField
          id="ruc"
          name="ruc"
          label="RUC"
          variant="outlined"
          value={formikSpouse.values.ruc}
          onChange={formikSpouse.handleChange}
          error={formikSpouse.touched.ruc && Boolean(formikSpouse.errors.ruc)}
          helperText={formikSpouse.touched.ruc && formikSpouse.errors.ruc}
        />
        <TextField
          id="profession"
          name="profession"
          label="PROFESION"
          variant="outlined"
          value={formikSpouse.values.profession}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.profession &&
            Boolean(formikSpouse.errors.profession)
          }
          helperText={
            formikSpouse.touched.profession && formikSpouse.errors.profession
          }
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">NIVEL PROFESION</InputLabel>
          <Select
            id="professionlevel"
            value={formikSpouse.values.professionlevel}
            name="professionlevel"
            onChange={formikSpouse.handleChange}
            label="NIVEL PROFESION"
            error={
              formikSpouse.touched.professionlevel &&
              Boolean(formikSpouse.errors.professionlevel)
            }
          >
            {professionLevel?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikSpouse.touched.professionlevel &&
            formikSpouse.errors.professionlevel ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikSpouse.touched.professionlevel &&
                formikSpouse.errors.professionlevel}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            ESTADO PROFESION
          </InputLabel>
          <Select
            id="professionstate"
            value={formikSpouse.values.professionstate}
            name="professionstate"
            label="ESTADO PROFESION"
            onChange={formikSpouse.handleChange}
            error={
              formikSpouse.touched.professionstate &&
              Boolean(formikSpouse.errors.professionstate)
            }
          >
            {professionState?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikSpouse.touched.professionstate &&
            formikSpouse.errors.professionstate ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikSpouse.touched.professionstate &&
                formikSpouse.errors.professionstate}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="occupation"
          name="occupation"
          label="OCUPACION"
          variant="outlined"
          value={formikSpouse.values.occupation}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.occupation &&
            Boolean(formikSpouse.errors.occupation)
          }
          helperText={
            formikSpouse.touched.occupation && formikSpouse.errors.occupation
          }
        />
        <TextField
          id="workplace"
          name="workplace"
          label="LUGAR DE TRABAJO"
          variant="outlined"
          value={formikSpouse.values.workplace}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.workplace &&
            Boolean(formikSpouse.errors.workplace)
          }
          helperText={
            formikSpouse.touched.workplace && formikSpouse.errors.workplace
          }
        />
        <TextField
          id="phone"
          name="phone"
          label="TELEFONO"
          variant="outlined"
          value={formikSpouse.values.phone}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.phone && Boolean(formikSpouse.errors.phone)
          }
          helperText={formikSpouse.touched.phone && formikSpouse.errors.phone}
        />
        <TextField
          id="address"
          name="address"
          label="DIRECCION"
          variant="outlined"
          value={formikSpouse.values.address}
          onChange={formikSpouse.handleChange}
          error={
            formikSpouse.touched.address && Boolean(formikSpouse.errors.address)
          }
          helperText={
            formikSpouse.touched.address && formikSpouse.errors.address
          }
        />
        <Box display={"flex"} justifyContent={"end"} mt={"5px"}>
          <Button variant="contained" color="success" type="submit" disabled={formikSpouse.isSubmitting}>
            {formikSpouse.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateSpouse;
