import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
const IndicatorsChart = ({ data }) => {
 
    function msToTime(s) {
    function pad(n, z) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }
  const [chartData, setChartData] = useState({
    series: JSON.parse(JSON.stringify(data)),
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          /*    distributed: true, */
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          var hini = new Date(data.date + " " + data.time_ini).getTime();
          var hfin = new Date(data.date + " " + data.time_fin).getTime();
          var dur = hfin - hini;      
       
          dur = msToTime(dur);
          moment.locale("es");
          return (
            '<div style="padding:6px;font-size: 12px;">' +         
            "<div><b>Equipo</b>: " +
            data.x +
            "</div>" +
            "<div><b>Fecha</b>: " +
            moment(data.date, "YYYY-MM-DD").format("LL") +
            "</div>" +
            "<div><b>Hora ini</b>: " +
            moment(data.time_ini, "HH:mm:ss").format("h:mm a") +
            "</div>" +
            "<div><b>Hora fin</b>: " +
            moment(data.time_fin, "HH:mm:ss.sss").format("h:mm a") +
            "</div>" +
            "<div><b>Duración</b>: " +
            moment(dur, "h:m").format("HH:mm") +
            " hrs" +
            "</div>" +
            "<div><b>Estado</b>: " +
            data.state +
            "</div>" +
            "<div><b>Tipo</b>: " +
            data.type +
            "</div>" +
            "<div><b>descripcion</b>: " +
            data.description +
            "</div>" +       
            "</div>"
          );
        },
      },
      /*     colors: ["#FF5733", "#3399FF", "#33FF33"] */
    },
  });
  useEffect(() => {    
    setChartData({...chartData,series:JSON.parse(JSON.stringify(data))})
  }, [data]);
  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="rangeBar"
        height={350}
      />
    </>
  );
};

export default IndicatorsChart;
