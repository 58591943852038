import React, { useState, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,  
} from "@mui/material";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import TextField from "@mui/material/TextField";
import { GET_MAINTANCE_REQUERIMENT_BY_ID } from "./QueryMaintance";
import { DateFormatting } from "utils/DateFormatting";
import { useSettings } from "hooks/useSettings";
const ViewRequeriment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  const { currency, dateFormat } = useSettings();
  useEffect(() => {
    const getItemRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_MAINTANCE_REQUERIMENT_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            getMaintanceRequerimentByIdId: id,
          },
        });
        console.log(data.getMaintanceRequerimentById);
        setItemRequeriment(data.getMaintanceRequerimentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">ACTIVO</label>
            <TextField disabled value={itemRequeriment?.active.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">DESCRIPCION</label>
            <TextField disabled value={itemRequeriment?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PRIORIDAD</label>
            <TextField disabled value={itemRequeriment?.priority.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={itemRequeriment?.type.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField
              disabled
              value={DateFormatting(itemRequeriment?.date,dateFormat)}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">ESTADO</label>
            <TextField disabled value={itemRequeriment?.state.description} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">COMENTARIO</label>
            <TextField
              disabled
              value={itemRequeriment?.comment}
              multiline
              rows={3}
            />
          </Box>
        </Box>
      </>
    ):(
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <RotatingLoopIcon />
      </Box>
    )
  );
};

export default ViewRequeriment;
