import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import DialogUtil from "components/DialogUtil";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import CreateClass from "./CreateClass";
import ModalUtil from "components/ModalUtil";
import CreateGroup from "./CreateGroup";
import {
  GET_ALL_CLASS,
  EDIT_CLASS_BY_ID,
  GET_EDIT_CLASS_BY_ID,
  DELETE_CLASS_BY_ID,
} from "./QueryClass";
import {
  GET_ALL_GROUP,
  GET_MAIN_DATA,
  GET_GROUPS,
  EDIT_GROUP_BY_ID,
  GET_GROUP_BY_ID,
} from "./QueryGroup";
import ViewGroup from "./ViewGroup";
import ViewClass from "./ViewClass";
import { GROUP_SCHEMA_YUP, CLASS_EDIT_SCHEMA_YUP } from "./YupValidation";
import EditForm from "./EditForm";
import DeleteForm from "./DeleteForm";
import { useUser } from "hooks/useUser";
import { DELETE_GROUP_BY_ID } from "./QueryGroup";
const GroupClass = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();
  const [groups, setgroups] = useState([]);

  const [newClass, setNewClass] = useState(null);
  const [newgroup, setNewGroup] = useState(null);

  const [allClass, setAllClass] = useState([]);
  const [allGroup, setAllGroup] = useState([]);
  const [mainData, setMainData] = useState([]);

  const [modalClassOpen, setModalClassOpen] = useState(false);
  const [modalGroupOpen, setModalGroupOpen] = useState(false);

  const [modalEditGroup, setModalEditGroup] = useState(false);
  const [modalEditClass, setModalEditClass] = useState(false);

  const [idClass, setIdClass] = useState(false);
  const [idGroup, setIdGroup] = useState(false);
  const { user } = useUser();
  const [dialogDeleteGroup, setdialogDeleteGroup] = useState(false);
  const [dialogDeleteClass, setdialogDeleteClass] = useState(false);

  const handleOpenModalDeleteGroup = (id) => {
    setdialogDeleteGroup(true);
    setIdGroup(id);
  };

  const handleCloseModalDeleteGroup = () => {
    setdialogDeleteGroup(false);
  };

  const handleOpenModalDeleteClass = (id) => {
    setdialogDeleteClass(true);
    setIdClass(id);
  };

  const handleCloseModalDeleteClass = () => {
    setdialogDeleteClass(false);
  };

  const handleOpenModalViewClass = (id) => {
    setModalClassOpen(true);
    setIdClass(id);
  };

  const handleCloseModalViewClass = () => {
    setModalClassOpen(false);
  };

  const handleOpenModalViewGroup = (id) => {
    setModalGroupOpen(true);
    setIdGroup(id);
  };

  const handleCloseModalViewGroup = () => {
    setModalGroupOpen(false);
  };

  const handleOpenModalEditGroup = (id) => {
    setModalEditGroup(true);
    setIdGroup(id);
  };

  const handleCloseModalEditGroup = () => {
    setModalEditGroup(false);
  };

  const handleOpenModalEditClass = (id) => {
    setModalEditClass(true);
    setIdClass(id);
  };

  const handleCloseModalEditClass = () => {
    setModalEditClass(false);
  };

  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const columnsGroup = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "GRUPO",
      flex: 1,
    },
    {
      field: "consumable",
      headerName: "CONSUMIBLE",
      flex: 1,
      renderCell: (params) => params.row.consumable==true?"SI":"NO"},
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewGroup(params.row.id)}
          />
          {user.admin ? (
            <>
              <CreateIcon
                color="info"
                onClick={() => handleOpenModalEditGroup(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteGroup(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsClass = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "CLASE",
      flex: 1,
    },
    {
      field: "group.id",
      headerName: "GRUPO",
      flex: 1,
      renderCell: (params) => params.row.group.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewClass(params.row.id)}
          />
          {user.admin ? (
            <>
              <CreateIcon
                color="info"
                onClick={() => handleOpenModalEditClass(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteClass(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const FUNCTION_GET_GROUP = async () => {
      try {
        const { data } = await client.query({
          query: GET_GROUPS,
          fetchPolicy: "network-only",
        });
        console.log(data.getGroup)
        setgroups(data.getGroup);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSummarizeGroupClass);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_CLASS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CLASS,
          fetchPolicy: "network-only",
        });
        setAllClass(data.getClass);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_GROUP = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_GROUP,
          fetchPolicy: "network-only",
        });
        setAllGroup(data.getGroup);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_GET_GROUP();
    FUNCTION_GET_MAIN_DATA();
    FUNCTION_GET_ALL_CLASS();
    FUNCTION_GET_ALL_GROUP();
  }, [newgroup, newClass]);
  return (
    <>
      <ModalUtil
        isOpen={modalClassOpen}
        onClose={handleCloseModalViewClass}
        Component={ViewClass}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
          })
        }
        id={idClass}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalGroupOpen}
        onClose={handleCloseModalViewGroup}
        Component={ViewGroup}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
          })
        }
        id={idGroup}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditGroup}
        onClose={handleCloseModalEditGroup}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewGroup,
            handleCloseModal: handleCloseModalEditGroup,
            headerQuery: {
              query: GET_GROUP_BY_ID,
              variables: {
                getGroupByIdId: idGroup,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: GROUP_SCHEMA_YUP,
            mutationEdit: EDIT_GROUP_BY_ID,
            paramEdit: "editGroupId",
            selectElement:{
              consumable:[
                { id: true, description: "SI" },
                { id: false, description: "NO" },
              ]
            }
          })
        }
        id={idGroup}
      ></ModalUtil>

      <ModalUtil
        isOpen={modalEditClass}
        onClose={handleCloseModalEditClass}
        Component={EditForm}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewClass,
            handleCloseModal: handleCloseModalEditClass,
            headerQuery: {
              query: GET_EDIT_CLASS_BY_ID,
              variables: {
                getClassByIdId: idClass,
              },
              fetchPolicy: "network-only",
            },
            validationFormik: CLASS_EDIT_SCHEMA_YUP,
            mutationEdit: EDIT_CLASS_BY_ID,
            paramEdit: "editClassId",
          })
        }
        id={idClass}
      ></ModalUtil>
      <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteGroup}
        onClose={handleCloseModalDeleteGroup}
        Component={DeleteForm}
        id={idGroup}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewGroup,
            paramsDelete: "deleteGroupByIdId",
            mutatitionDelete: DELETE_GROUP_BY_ID,
            onClose: handleCloseModalDeleteGroup,
          })
        }
      />

      <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteClass}
        onClose={handleCloseModalDeleteClass}
        Component={DeleteForm}
        id={idClass}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewClass,
            paramsDelete: "deleteClassByIdId",
            mutatitionDelete: DELETE_CLASS_BY_ID,
            onClose: handleCloseModalDeleteClass,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="LOGISTICA" subtitle="logistica/grupo y clase" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreateGroup
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              setNewGroup={setNewGroup}
            />
          </Box>
          <StatBox
            title="Total Grupos"
            value={mainData?.countGroup}
            /*   increase="+14%" */
            /*   description="Aun estan en proceso de entrevista" */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultimo Grupo"
            value={mainData?.lastGroup}
            /*  increase="+21%" */
            /*  description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Clases"
            value={mainData?.countClass}
            /*  increase="+21%" */
            /*    description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultima Clase"
            value={mainData?.lastClass}
            /*  increase="+21%" */
            /*    description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 2sss */}
          <Box
            gridColumn="span 4"
            gridRow="span 3"
            p="1rem"
            backgroundColor={theme.palette.background.alt}
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}             
            >
              HISTORIAL DE GRUPOS
            </Box>
            <DataGrid
              loading={!allGroup}
              getRowId={(row) => row.id}
              rows={allGroup || []}
              columns={columnsGroup}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 3"
            p="1rem"
            backgroundColor={theme.palette.background.alt}
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
             
            >
              HISTORIAL DE CLASES
            </Box>
            <DataGrid
              loading={!allClass}
              getRowId={(row) => row.id}
              rows={allClass || []}
              columns={columnsClass}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <CreateClass
              groups={groups}
              setNewClass={setNewClass}
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
            />
          </Box>
       
        </Box>
      </Box>
    </>
  );
};

export default GroupClass;
