import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Radio from "@mui/material/Radio";
import TableRow from "@mui/material/TableRow";
import {
  GET_CHILDREN_BY_PERSONAL_ID,
  GET_CHILDREN_BY_ID,
  EDIT_CHILDREN_BY_ID,
} from "./QueryPersonal";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { CHILDREN_SCHEMA_YUP } from "./YupValidation";
import { DatePicker } from "@mui/x-date-pickers";
import LoopIcon from '@mui/icons-material/Loop';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
const EditChildren = ({  params }) => {
  const {
    handleCloseModal,
    gender,
    setAlertCustom,
    alertCustom,
    setNewRegister,
    idPersonal
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [childrenFicha, setchildrenFicha] = useState(null);
  const [onechildren, setonechildren] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null); // Estado para rastrear el radio seleccionado
  const [component, setComponent] = useState(false);
 
  const isDate = (numero) => {
    return !isNaN(new Date(numero).getTime());
  };
  const converObj = (obj) => {
    const resultado = {};
    for (const clave in obj) {
      if (typeof obj[clave] === "object" && obj[clave] !== null) {
        if (Array.isArray(obj[clave])) {
          resultado[clave] = obj[clave].map(
            (el) =>
              new Object({
                answer: el.answer,
                sentence: el.sentence.id,
              })
          );
        } else {
          resultado[clave] = obj[clave].id;
        }
      } else if (typeof obj[clave] === "number" && isDate(obj[clave])) {
        resultado[clave] = new Date(obj[clave]);
      } else {
        resultado[clave] = obj[clave];
      }
    }
    return resultado;
  };
  const handleChangeRadio = async (event) => {
    setSelectedValue(event.target.value);
    const { data } = await client.query({
      query: GET_CHILDREN_BY_ID,
      variables: {
        getChildrenByIdId: event.target.value,
      },
      fetchPolicy: "network-only",
    });
    console.log(data.getChildrenById);
    setonechildren(data.getChildrenById);
    setComponent(true);
  };

  useEffect(() => {
    const getchildrenFicha = async (idPersonal) => {
      try {
        const { data } = await client.query({
          query: GET_CHILDREN_BY_PERSONAL_ID,
          variables: {
            getChildrenByPersonalIdId: idPersonal,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getChildrenByPersonalId);
        setchildrenFicha(data.getChildrenByPersonalId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getchildrenFicha(idPersonal);
  }, []);

  const formik = useFormik({
    initialValues: converObj(onechildren),
    validationSchema: CHILDREN_SCHEMA_YUP,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (selectedValue) {
        setSubmitting(true);
        const { id, name, surname, dob, gender, doi } = values;      
        try {
          const response = await client.mutate({
            mutation: EDIT_CHILDREN_BY_ID,
            variables: {
              editChildrenByIdId: id,
              personal: idPersonal,
              name: name.toUpperCase(),
              surname: surname.toUpperCase(),
              dob,
              gender,
              doi: Number(doi),
            },
          });
          setNewRegister(response.data.createSpouse);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
          handleCloseModal();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };

  return (
    visibility ? (
      <form onSubmit={formik.handleSubmit}>
        {" "}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">NOMBRES</TableCell>
                  <TableCell align="">DNI</TableCell>
                  <TableCell align="">GENERO</TableCell>
                  <TableCell align="">FECHA DE NACIMIENTO</TableCell>
                  <TableCell align=""></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childrenFicha.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row.name + " " + row.surname}
                      </TableCell>
                      <TableCell align="">{row.doi}</TableCell>
                      <TableCell align="">{row.gender.description}</TableCell>
                      <TableCell align="">
                        {new Date(row.dob).toLocaleDateString("es-ES")}
                      </TableCell>
                      <TableCell align="">
                        <Radio
                          onChange={handleChangeRadio}
                          name="groupChildren"
                          value={row.id} // Valor único para este radio
                          checked={selectedValue === row.id}
                        />
                      </TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {console.log(formik.values)}
        {component && (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label>NOMBRE</label>
              <TextField
                id="name"
                name="name"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <Box sx={style}>
              <label>APELLIDO</label>
              <TextField
                id="surname"
                name="surname"
                variant="outlined"
                value={formik.values.surname}
                onChange={formik.handleChange}
                error={formik.touched.surname && Boolean(formik.errors.surname)}
                helperText={formik.touched.surname && formik.errors.surname}
              />
            </Box>
            <Box sx={style}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <label>FECHA DE NACIMIENTO</label>
                <DatePicker
                  value={formik.values.dob}
                  id="dob"
                  name="dob"
                  inputFormat="dd-MM-yyyy"
                  onChange={(val) => {
                    formik.setFieldValue("dob", val);
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={style}>
              {formik.values.gender && (
                <FormControl fullWidth>
                  <label for="my-input">GENERO</label>
                  <Select
                    id="gender"
                    value={formik.values.gender}
                    name="gender"
                    onChange={(e) => {
                      formik.setFieldValue("gender", e.target.value);
                    }}
                  >
                    {gender?.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.gender && formik.errors.gender ? (
                    <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                    >
                      {formik.touched.gender && formik.errors.gender}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Box>
            <Box sx={style}>
              <label for="my-input">DNI</label>
              <TextField
                id="doi"
                name="doi"
                variant="outlined"
                value={formik.values.doi}
                onChange={formik.handleChange}
                error={formik.touched.doi && Boolean(formik.errors.doi)}
                helperText={formik.touched.doi && formik.errors.doi}
              />
            </Box>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"} gap={"15px"}>
          <Button variant="contained" color="success" type="submit"  disabled={formik.isSubmitting}>
          {formik.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </form>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default EditChildren;
