import { gql } from "@apollo/client";

export const GET_ALL_PERSONAL_REQUERIMENT = gql`
query GetPersonalRequeriment($acro: String) {
  getPersonalRequeriment(acro: $acro) {
     id
      incorporationdate
      ngoal
      code
      remuneration
      state {
        description
        id
      }
    area {
      acro
      description
      id
    }
  }
}
`;
export const GET_PERSONAL_WITHOUT_POSITION_FOR_REQUERIMENT = gql`
  query GetAllPersonalWithoutPositionForRequeriment(
    $getAllPersonalWithoutPositionForRequerimentId: ID
  ) {
    getAllPersonalWithoutPositionForRequeriment(
      id: $getAllPersonalWithoutPositionForRequerimentId
    ) {
      requeriment {
        id
        ncurrent
        ngoal
        position{
          description
        }
        state {
          description
        }
        subject {
          description
        }
      }
      personal {
        id
        postulant {
          id
          name
          surname
          doi
        }
      }
    }
  }
`;

export const CREATE_ASSIGN_PERSONAL_REQUERIMENT = gql`
  mutation CreateAssignPositionbyRequeriment(
    $requeriment: ID
    $personal: [ID]
  ) {
    createAssignPositionbyRequeriment(
      requeriment: $requeriment
      personal: $personal
    ) {
      id
      ngoal
      remuneration
      ncurrent
      incorporationdate
    }
  }
`;

export const CREATE_PERSONAL_REQUERIMENT = gql`
  mutation CreatePersonalRequeriment(
    $personal: ID
    $position: ID
    $area: String
    $term: ID
    $workplace: ID
    $subject: ID
    $duration: Int
    $incorporationdate: Date
    $remuneration: Float
    $ngoal: Int
    $observations: [String]
  ) {
    createPersonalRequeriment(
      personal: $personal
      position: $position
      area: $area
      term: $term
      workplace: $workplace
      subject: $subject
      duration: $duration
      incorporationdate: $incorporationdate
      remuneration: $remuneration
      ngoal: $ngoal
      observations: $observations
    ) {
      id
      incorporationdate
      ngoal
      remuneration
    }
  }
`;

export const GET_MAIN_DATA = gql`
 query GetSumarizeRequerimentPersonal($area: String) {
  getSumarizeRequerimentPersonal(area: $area) {
    aproved
    begining
    pending
  }
}
`;
export const DELETE_REQUERIMENT_BY_ID= gql`
mutation DeleteRequerimentPersonalById($deleteRequerimentPersonalByIdId: ID) {
  deleteRequerimentPersonalById(id: $deleteRequerimentPersonalByIdId) {
    id
  }
}`;


export const GET_PERSONAL_AND_POSITION_BY_ID = gql`
  query GetPersonalRequerimentAndPersonalById(
    $getPersonalRequerimentAndPersonalByIdId: ID
  ) {
    getPersonalRequerimentAndPersonalById(
      id: $getPersonalRequerimentAndPersonalByIdId
    ) {
      id
      ncurrent
      ngoal
      incorporationdate
      duration
      area {
        id
        description
      }
      position {
        id
        description
      }
      remuneration
      state {
        id
        description
      }
      subject {
        id
        description
      }
      term {
        id
        description
      }
      workplace {
        id
        description
      }
      personals {
        personal {
          postulant {
            name
            surname
            doi
          }
        }
      }
      personal {
        postulant {
          name
          surname
        }
      }
      observation
    }
  }
`;
