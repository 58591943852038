import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import FlexBetween from "components/FlexBetween"
import Header from "components/Header"
import { useFormik } from "formik"
import { SETTINGS_SCHEMA_YUP } from "./YupValidation"
import { useMutation, useQuery } from "@apollo/client"
import { GET_SETTINGS, UPDATE_SETTINGS } from "./QuerySettings"
import { useSettings } from "hooks/useSettings"
import { useEffect } from "react"


const General = () => {

  const theme = useTheme()

  const { currency, dateFormat, rounding, isLoading } = useSettings()

  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: [
      GET_SETTINGS
    ]
  })

  const settingsFormik = useFormik({
    initialValues: {
      currency: isLoading ? 'PEN' : currency,
      dateFormat: isLoading ? 'YYYY_MM_DD' : dateFormat,
      rounding: isLoading ? 2 : rounding,
    },
    validationSchema: SETTINGS_SCHEMA_YUP,
    onSubmit: (values) => {
      console.log(values)
      updateSettings({ variables: values })
    }
  })

  useEffect(() => {
    if (!isLoading) {
      settingsFormik.setValues({
        currency,
        dateFormat,
        rounding
      })
    }
  }, [isLoading, currency, dateFormat, rounding])

  return (
    <Box
      m="1.5rem 2.5rem"
    >
      {
        loading ?
          (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : <></>
      }
      <Box>
        <FlexBetween>
          <Header title="AJUSTES GENERALES" subtitle="config/general" />
        </FlexBetween>
      </Box>

      <Grid
        component='form'
        onSubmit={settingsFormik.handleSubmit}
        mt='1rem'
        container
        spacing={3}
      >
        <Grid item xs={4}>
          <Box
            bgcolor='white'
            height='100%'
            borderRadius={3}
            p='1.5rem'
            display='flex'
            flexDirection='column'
            gap={1}
          >
            <Typography
              variant="h5"
              color={theme.palette.secondary[100]}
              fontWeight="bold"
            >
              Simbolo de Moneda
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              height='100%'
            >
              <Typography
                variant="body2"
                color={theme.palette.secondary[100]}
              >
                Establece la unidad monetaria utilizada en los informes financieros, garantizando la presición y coherencia en la representación de valores monetarios
              </Typography>

              <FormControl sx={{ width: { xs: '100%', md: '100%' }, mt: 1 }}>
                <InputLabel id="label-currency"
                  sx={{
                    '&.Mui-focused, &:hover': {
                      color: theme.palette.primary[500],
                    },
                  }}
                >
                  MONEDA
                </InputLabel>
                <Select
                  labelId="label-currency"
                  id="select-currency"
                  label="currency"
                  name="currency"
                  value={settingsFormik.values.currency}
                  onChange={settingsFormik.handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary[500],
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary[500],
                    },
                  }}
                >
                  <MenuItem value='PEN'>S/. - PEN</MenuItem>
                  <MenuItem value='USD'>$- USD</MenuItem>

                </Select>
              </FormControl>
            </Box>


          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            bgcolor='white'
            height='100%'
            borderRadius={3}
            p='1.5rem'
            display='flex'
            flexDirection='column'
            gap={1}
          >
            <Typography
              variant="h5"
              color={theme.palette.secondary[100]}
              fontWeight="bold"
            >
              Formato de Fecha
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              height='100%'
            >
              <Typography
                variant="body2"
                color={theme.palette.secondary[100]}
              >
                Configura la presentación uniforme de las fechas en los informes y registro. Esto asegura la claridad y consistencia en la interpretación de las fechas en toda la plataforma
              </Typography>

              <FormControl sx={{ width: { xs: '100%', md: '100%' }, mt: 1 }}>
                <InputLabel id="demo-simple-select-label"
                  sx={{
                    '&.Mui-focused, &:hover': {
                      color: theme.palette.primary[500],
                    },
                  }}
                >
                  FORMATO
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="dateFormat"
                  name="dateFormat"
                  value={settingsFormik.values.dateFormat}
                  onChange={settingsFormik.handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary[500],
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary[500],
                    },
                  }}
                >
                  <MenuItem value='YYYY_MM_DD'>YYYY / MM / DD</MenuItem>
                  <MenuItem value='DD_MM_YYYY'>DD / MM / YYYY</MenuItem>
                  <MenuItem value='MM_DD_YYYY'>MM / DD / YYYY</MenuItem>
                </Select>
              </FormControl>

            </Box>

          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            bgcolor='white'
            height='100%'
            borderRadius={3}
            p='1.5rem'
            display='flex'
            flexDirection='column'
            gap={1}
          >
            <Typography
              variant="h5"
              color={theme.palette.secondary[100]}
              fontWeight="bold"
            >
              Redondeo de las Cifras
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              height='100%'
              gap={2}
            >
              <Typography
                variant="body2"
                color={theme.palette.secondary[100]}
              >
                Establece la precisión con la cual se presentan las cantidades numéricas en los informes financieros y contables. Esto asegura una representación coherente y legible de los valores, al tiempo que se cumplen estándares de presentación y análisis en el entorno empresarial
              </Typography>

              <TextField
                id="outlined-number"
                label="CIFRAS"
                name="rounding"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                inputProps={{
                  value: settingsFormik.values.rounding,
                  min: 1
                }}
                onChange={settingsFormik.handleChange}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item display='flex' justifyContent='center' xs={12}>
          <Button variant="contained" color="success" type="submit" size="large" sx={{ width: { xs: '100%', md: '40%' } }}>
            GUARGAR CAMBIOS
          </Button>
        </Grid>
      </Grid>



    </Box>
  )
}

export default General