import React from "react";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/logo.jpeg";
import { items } from "consts/allowedRoles";
import { useUser } from "hooks/useUser";

/*
const navItems = [
 
  {
    text: "Gestion de Personas",
    icon: null,
    url: null,
    type: 11,
  },
  {
    text: "POSTULANTE",
    icon: <ShoppingCartOutlined />,
    url: "gp/postulant",
    type: 11,
  },
  {
    text: "ENTREVISTA",
    icon: <Groups2Outlined />,
    url: "gp/interview",
    type: 11,
  },
  {
    text: "REFERENCIAL LABORAL",
    icon: <Groups2Outlined />,
    url: "gp/jobreference",
    type: 11,
  },

  {
    text: "PERSONAL",
    icon: <Groups2Outlined />,
    url: "gp/personal",
    type: 11,
  },
  {
    text: "FICHA PERSONAL",
    icon: <Groups2Outlined />,
    url: "gp/fichapersonal",
    type: 11,
  },
  {
    text: "DOCUMENTOS",
    icon: <Groups2Outlined />,
    url: "gp/document",
    type: 11,
  },
  {
    text: "REQUERIMIENTO PERSONAL",
    icon: <Groups2Outlined />,
    url: "gp/requeriment",
    type: 11,
  },
  {
    text: "Logistica",
    icon: null,
    url: null,
    type: 22,
  },
  {
    text: "GRUPOS Y CLASES",
    icon: <ShoppingCartOutlined />,
    url: "l/groupclass",
    type: 22,
  },
  {
    text: "ITEM",
    icon: <ShoppingCartOutlined />,
    url: "l/item",
    type: 22,
  },
 
  {
    text: "PROVEEDOR",
    icon: <ShoppingCartOutlined />,
    url: "l/provider",
    type: 22,
  },
  {
    text: "SELECCION Y EVALUACION DE PROVEEDOR",
    icon: <ShoppingCartOutlined />,
    url: "l/providerselectionevaluation",
    type: 22,
  },
  {
    text: "REQUERIMIENTO DE ITEM",
    icon: <ShoppingCartOutlined />,
    url: "l/requeriment",
    type: 22,
  },
  {
    text: "ORDEN DE COMPRA",
    icon: <ShoppingCartOutlined />,
    url: "l/buyorder",
    type: 22,
  },
  {
    text: "Operacion",
    icon: null,
    url: null,
    type: 33,
  },
  {
    text: "REPORTE",
    icon: <ShoppingCartOutlined />,
    url: "ope/report",
    type: 33,
  },
  {
    text: "OPERACION",
    icon: <ShoppingCartOutlined />,
    url: "ope/operation",
    type: 33,
  },
  {
    text: "FLOTA",
    icon: <ShoppingCartOutlined />,
    url: "ope/flota",
    type: 33,
  },
  {
    text: "CONDUCTOR",
    icon: <ShoppingCartOutlined />,
    url: "ope/driver",
    type: 33,
  },
  {
    text: "DOCUMENTO",
    icon: <ShoppingCartOutlined />,
    url: "ope/document",
    type: 33,
  },
  {
    text: "Mantenimiento",
    icon: null,
    url: null,
    type: 44,
  },
  {
    text: "REQUERIMIENTO MANTENIMIENTO",
    icon: <ShoppingCartOutlined />,
    url: "man/requeriment",
    type: 44,
  },
  {
    text: "ORDEN DE TRABAJO",
    icon: <ShoppingCartOutlined />,
    url: "man/joborder",
    type: 44,
  },
  {
    text: "GARANTIAS Y PM",
    icon: <ShoppingCartOutlined />,
    url: "man/guarantee",
    type: 44,
  },

  {
    text: "Seguridad",
    icon: null,
    url: null,
    type: 55,
  },
  {
    text: "CAPACITACION",
    icon: <ShoppingCartOutlined />,
    url: "seg/training",
    type: 55,
  },
  {
    text: "SOMNOLENCIA",
    icon: <ShoppingCartOutlined />,
    url: "seg/somnolency",
    type: 55,
  },
  {
    text: "CAMIONETA",
    icon: <ShoppingCartOutlined />,
    url: "seg/van",
    type: 55,
  },
  {
    text: "CISTERNA",
    icon: <ShoppingCartOutlined />,
    url: "seg/cistern",
    type: 55,
  },
  {
    text: "SISTOMAS E INCIDENTES",
    icon: <ShoppingCartOutlined />,
    url: "seg/sintomas",
    type: 55,
  },
  {
    text: "CONTROL DE PERNOCTE",
    icon: <ShoppingCartOutlined />,
    url: "seg/pernoct",
    type: 55,
  },
  {
    text: "Administracion",
    icon: null,
    url: null,
    type: 66,
  },
  {
    text: "PERSONAL",
    icon: <ShoppingCartOutlined />,
    url: "adm/personal",
    type: 66,
  },
  {
    text: "LOGISTICA",
    icon: <ShoppingCartOutlined />,
    url: "adm/logistic",
    type: 66,
  },
  {
    text: "MANTENIMIENTO",
    icon: <ShoppingCartOutlined />,
    url: "adm/maintance",
    type: 66,
  },
];
*/
const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const theme = useTheme();

  const [openIdx, setOpenIdx] = useState(0);

  const { user } = useUser();

  const modules = items.filter((item) => {
    return item.subItems.some((subItem) =>
      user.submodules.includes(subItem.id)
    );
  });

  const allowedModules = modules.map((module) => {
    return {
      title: module.title,
      subItems: module.subItems.filter((subitem) =>
        user.submodules.includes(subitem.id)
      ),
    };
  });

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    <Box
                      component="img"
                      alt="profile"
                      src={profileImage}
                      height="100px"
                      sx={{ objectFit: "cover" }}
                    />
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>

            <List>
              {allowedModules.map((item, idx) => {
                return (
                  <>
                    <ListItemButton
                      key={item.title}
                      disablePadding
                      onClick={() =>
                        openIdx !== idx ? setOpenIdx(idx) : setOpenIdx(-1)
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor:
                          openIdx === idx
                            ? theme.palette.grey[200]
                            : "transparent",
                        color:
                          openIdx === idx
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <Typography
                        key={item.title}
                        textAlign={"center"}
                        sx={{
                          m: "1rem 0.5rem",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          fontSize: 11,
                        }}
                      >
                        {" "}
                        {item.title}{" "}
                      </Typography>
                      {openIdx === idx ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse in={openIdx === idx} timeout="auto" unmountOnExit>
                      <List>
                        {item.subItems.map((subItem, idx) => {
                          return (
                            <NavLink
                              to={`${subItem.url}`}
                              style={{
                                textDecoration: "none",
                                width: "100%",
                              }}
                            >
                              {({ isActive }) => {
                                return (
                                  <ListItemButton
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: isActive
                                          ? theme.palette.secondary[300]
                                          : "",
                                      },
                                      backgroundColor: isActive
                                        ? theme.palette.secondary[300]
                                        : "transparent",
                                      color: isActive
                                        ? "white"
                                        : theme.palette.secondary[100],
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        ml: ".5rem",
                                        color: isActive
                                          ? "white"
                                          : theme.palette.secondary[200],
                                      }}
                                    >
                                      {subItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={subItem.title} />
                                    <ChevronRightOutlined sx={{ ml: "auto" }} />
                                  </ListItemButton>
                                );
                              }}
                            </NavLink>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
