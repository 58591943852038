import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_ACTIVE_NO_VEHICLE_BY_ITEM_ID,GET_ACTIVE_BY_ID,EDIT_ACTIVE_BY_ID } from "./QueryItem";
import EditForm from "./EditForm";
import {ACTIVE_EDIT_SCHEMA_YUP} from "./YupValidation";

const EditComponent = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleClose,
    condition,
    unity,
    setNewRegister,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState([]);
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    const getPostulantById = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVE_NO_VEHICLE_BY_ITEM_ID,
          fetchPolicy: "network-only",
          variables: {
            getActiveNoVehicleByItemIdId: id,
          },
        });
        setActiveVehicle(data.getActiveNoVehicleByItemId);
        console.log(data.getActiveNoVehicleByItemId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPostulantById();
  }, [activeId]);
  return (
    visibility && (
      <Box>
        <Box mb={"20px"}>
          {activeVehicle?.length ? (
            <Autocomplete
              freeSolo
              id="vehicle"
              name="vehicle"
              disableClearable
              options={activeVehicle?.map(
                (option) =>
                  new Object({
                    label:
                      "active:" +
                      option.description +
                      " / " +
                      "placa:" +
                      option.plate,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                setActiveId(newValue.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por descripcion/placa"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          ) : (
            <p>No existe Activos para el Item </p>
          )}
        </Box>
        <Box>
          {activeId && activeVehicle.length ? (
            <EditForm
              id={activeId}
              params={
                new Object({
                  setAlertCustom: setAlertCustom,
                  alertCustom: alertCustom,
                  setNewRegister: setNewRegister,
                  handleCloseModal: handleClose,
                  headerQuery: {
                    query: GET_ACTIVE_BY_ID,
                    variables: {
                      getActiveByIdId: activeId,
                    },
                    fetchPolicy: "network-only",
                  },
                  validationFormik: ACTIVE_EDIT_SCHEMA_YUP,
                  mutationEdit: EDIT_ACTIVE_BY_ID,
                  paramEdit: "editActiveByIdId",
                  selectElement: {
                    condition,
                    unity,
                  },
                })
              }
            />
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              height={"100%"}
              alignItems={"center"}
            >
              Selecciona un activo
            </Box>
          )}
        </Box>
      </Box>
    )
  );
};

export default EditComponent;
