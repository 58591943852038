import React from "react";
import { useState, useEffect } from "react";
import { GET_CATEGORY_BY_ID } from "./QueryProvider";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
const ViewCategory = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [category, setcategory] = useState({});
  useEffect(() => {
    const getCategoryById = async () => {
      try {
        const { data } = await client.query({
          query: GET_CATEGORY_BY_ID,
          variables: {
            getCategoryByIdId: id,
          },
          fetchPolicy: "network-only",
        });

        setcategory(data.getCategoryById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getCategoryById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    visibility ?(
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">CATEGORIA</label>
            <TextField disabled value={category?.description} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ) : (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
    )
  );
};

export default ViewCategory;
