import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import LoopIcon from '@mui/icons-material/Loop';
import { FormHelperText } from "@mui/material";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
} from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
const EditForm = ({ id, params }) => {
  const {
    handleCloseModal,
    setAlertCustom,
    alertCustom,
    setNewRegister,
    mutationEdit,
    headerQuery,
    validationFormik,
    paramEdit,
    selectElement,
    header,
  } = params;
  const client = useApolloClient();
  const [objectData, setObjectData] = useState(null);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [loading, setLoading] = useState(true);
  const isDate = (numero) => {
    return !isNaN(numero) && new Date(numero).toString() !== "Invalid Date";
  };
  const converObj = (obj) => {
    const resultado = {};
    for (const clave in obj) {
      if (typeof obj[clave] === "object" && obj[clave] !== null) {
        if (Array.isArray(obj[clave])) {
          resultado[clave] = obj[clave].map(
            (el) =>
              new Object({
                answer: el.answer,
                sentence: el.sentence.id,
              })
          );
        } else {
          resultado[clave] = obj[clave].id;
        }
      } else if (typeof obj[clave] === "number" && isDate(obj[clave])) {
        resultado[clave] = new Date(obj[clave]);
      } else {

     
        resultado[clave] = obj[clave];
      }
    }
   
    return resultado;
  };
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
        fetchPolicy: "network-only",
      });
      console.log(response.data);
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  useEffect(() => {
    const getData = async (id) => {
      try {
        const { loading, data } = await client.query(headerQuery);

        if (loading) return <p>Cargando...</p>;

        const responseData = Object.values(data).find(
          (value) => value && typeof value === "object"
        );

        if (responseData) {
          console.log(responseData);
          if(responseData.province&&responseData.district){
            handleProvince(responseData.departament.id);
            handleDistrict(responseData.province.id);
          }
          setObjectData(responseData);
        }
        setLoading(false); 
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getData(id);
  }, []);

  const formik = useFormik({
    initialValues: converObj(objectData) || {},
    validationSchema: validationFormik,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);      
      try {
        const { data } = await client.mutate({
          mutation: mutationEdit,
          variables: { [paramEdit]: id, ...values },
          fetchPolicy: "network-only",
        });

        const responseData = Object.values(data).find(
          (value) => value && typeof value === "object"
        );

        if (responseData) {
          setNewRegister(responseData);
        }
        
        handleCloseModal();        
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };

  return (!loading ?
    <form onSubmit={formik.handleSubmit}>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gap="20px"
      >
        {console.log(formik)}
        {objectData &&
          Object.entries(objectData).map(([clave, valor]) => {
            
            if (typeof valor === "string") {
              return (
                <Box sx={style} key={clave}>
                  <label htmlFor={clave}>{clave}</label>
                  <TextField
                    id={clave}
                    name={clave}
                    variant="outlined"
                    value={formik.values[clave]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[clave] && Boolean(formik.errors[clave])
                    }
                    helperText={formik.touched[clave] && formik.errors[clave]}
                  />
                </Box>
              );
            } else if (typeof valor === "number") {
              if (isDate(valor)) {
                return (
                  <Box sx={style} key={clave}>
                    <label htmlFor={clave}>{clave}</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        id={clave}
                        name={clave}
                        inputFormat="dd-MM-yyyy"
                        value={formik.values[clave]}
                        onChange={(val) => {
                          formik.setFieldValue(clave, val);
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField {...params} />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                    {formik.touched[clave] && formik.errors[clave] ? (
                      <FormHelperText
                        sx={{
                          color: "#bf3333",
                          marginLeft: "16px !important",
                        }}
                      >
                        {formik.touched[clave] && formik.errors[clave]}
                      </FormHelperText>
                    ) : null}
                  </Box>
                );
              } else {
                return <Box key={clave}>IS NUMBER</Box>;
              }
            } else if (typeof valor === "boolean") {
              return (
                formik.values[clave] && (
                  <Box sx={style} key={clave}>
                    <FormControl fullWidth>
                      <label for="my-input">{clave}</label>
                      <Select
                        id={clave}
                        name={clave}
                        value={formik.values[clave]}
                        onChange={(e) => {
                          formik.setFieldValue(clave, e.target.value);
                        }}
                      >
                        {selectElement[clave].map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formik.touched[clave] && formik.errors[clave] ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formik.touched[clave] && formik.errors[clave]}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Box>
                )
              );
            } else if (typeof valor === "object") {
              if (Array.isArray(valor)) {
                return (
                  <Box my={"20px"} key={clave} sx={{ gridColumnEnd: "span 2" }}>
                    <TableContainer
                      style={{ maxHeight: 550, overflowY: "auto" }}
                    >
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {header[clave].map((row, index) => {
                              return (
                                <TableCell align="">{row.title}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        
                          {valor?.map((row, index) => {
                            return (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                
                                <TableCell align="">{index + 1}</TableCell>
                                <TableCell align="">
                                  {row.sentence.description}
                                </TableCell>
                                <TableCell align="">
                                  <TextField
                                    name={`${clave}[${index}].answer`}
                                    variant="outlined"
                                    value={
                                      formik.values[clave]?.[index]?.answer
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `${clave}[${index}].answer`,
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        `${clave}[${index}].sentence`,
                                        row.sentence.id
                                      );
                                    }}
                                    error={
                                      formik.touched[clave]?.[index]?.answer &&
                                      Boolean(
                                        formik.errors[clave]?.[index]?.answer
                                      )
                                    }
                                    helperText={
                                      formik.touched[clave]?.[index]?.answer &&
                                      formik.errors[clave]?.[index]?.answer
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                );
              }else if(!Array.isArray(valor) && valor !== null){
                return (
                  formik.values[clave] && (
                    <Box sx={style} key={clave}>
                      <FormControl fullWidth>
                        <label for="my-input">{clave}</label>
                        <Select
                          id={clave}
                          name={clave}
                          value={formik.values[clave]}
                          onChange={(e) => {
                            formik.setFieldValue(clave, e.target.value);
                            if (clave == "departament") {
                              console.log("hola departament");
                              handleProvince(e.target.value);
                            } else if (clave == "province") {
                              console.log("hola province");
                              handleDistrict(e.target.value);
                            }
                          }}
                        >
                          {clave == "province"&&province
                            ? province?.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                  </MenuItem>
                                );
                              })
                            : clave == "district"&&district
                            ? district?.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                  </MenuItem>
                                );
                              })
                            : selectElement[clave]?.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                        {formik.touched[clave] && formik.errors[clave] ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {formik.touched[clave] && formik.errors[clave]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  )
                );
              }
               else {
               return(
             
                  <Box sx={style} key={clave}>
                    <FormControl fullWidth>
                      <label for="my-input">{clave}</label>
                      <Select
                        id={clave}
                        name={clave}
                        value={formik.values[clave]}
                        onChange={(e) => {
                          formik.setFieldValue(clave, e.target.value);                     
                        }}
                      >
                        {
                          selectElement[clave]?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.description}
                                </MenuItem>
                              );
                            })}
                      </Select>
                      {formik.touched[clave] && formik.errors[clave] ? (
                        <FormHelperText
                          sx={{
                            color: "#bf3333",
                            marginLeft: "16px !important",
                          }}
                        >
                          {formik.touched[clave] && formik.errors[clave]}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Box>
                
               )
              }
            } else {
              return(
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  Dato desconocido
                </p>
              </FormHelperText>)
            }
          })}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"} gap={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formik.isSubmitting}>
        {formik.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          CERRAR
        </Button>
      </Box>
    </form>:<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default EditForm;
