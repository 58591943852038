import React, { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import LoopIcon from "@mui/icons-material/Loop";
import TableRow from "@mui/material/TableRow";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import {
  GET_ITEM_AND_ACTIVE_FOR_REQUERIMENT,
  UPDATE_ITEM_REQUERIMENT,
} from "./QueryItemRequeriment";
import Alerts from "components/Alerts";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Error from "components/Error";

const AssignItem = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const [requerimentandActives, setrequerimentandActives] = useState([]);
  const client = useApolloClient();
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setdata] = useState([]);
  const [datactive, setdatactive] = useState([]);
  const [saved, setsaved] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dividirTexto = (texto, longitud) => {
    const lineas = [];
    for (let i = 0; i < texto.length; i += longitud) {
      lineas.push(texto.slice(i, i + longitud));
    }
    return lineas.map((linea, index) => <React.Fragment key={index}>{linea}<br /></React.Fragment>);
  };

  const theme = useTheme();
  const columns = [
    /*  {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    }, */
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 4,
      renderCell: (params) => params.row.description,
    },
   /*  {
      field: "active",
      headerName: "ACTIVO",
      flex: 2,
      renderCell: (params) => params.row.active,
    }, */
    {
      field: "unity.description",
      headerName: "UNIDAD",
      flex: 1.5,
      renderCell: (params) => params.row.unity.description,
    },
    {
      field: "amountfree",
      headerName: "DISPONIBLE",
      flex: 1,
      renderCell: (params) => params.row.amountfree,
    },
    {
      field: "ope",
      headerName: "CANTIDAD",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <TextField
            disabled={!params.api.isRowSelected(params.row.id)}
            id={"txt-assign-requeriment-" + params.row.id}
            required={params.api.isRowSelected(params.row.id) ? true : false}
            variant="standard"
            className="input-requeriment-assign-logistic"
          />
        </Box>
      ),
    },
  ];
  const columnsactive = [
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 2,
      renderCell: (params) => params.row.item.description,
    },
    {
      field: "active",
      headerName: "ACTIVO",
      flex: 2,
      renderCell: (params) => params.row.item.active,
    },
    {
      field: "unity.description",
      headerName: "UNIDAD",
      flex: 1,
      renderCell: (params) => params.row.item.unity.description,
    },
  ];
  useEffect(() => {
    const getAllItemsForRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ITEM_AND_ACTIVE_FOR_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            getItemsAndActiveForRequerimentId: id,
          },
        });
        console.log(data.getItemsAndActiveForRequeriment);
        setrequerimentandActives(data.getItemsAndActiveForRequeriment);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllItemsForRequeriment(id);
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const newactives = datactive.map((it) => {
      return new Object({
        ...it,
        assign: Number(
          document.getElementById("txt-assign-requeriment-" + it.item).value
        ),
      });
    });
  
    setError(false);
    const check = data.some(
      (el) => Number(el.current) + Number(el.active.length) > Number(el.amount)
    );

    const checkactive = newactives.some(
      (el) =>
        Number(el.assign) > Number(el.avalible) ||
        Number(el.assign) + Number(el.current) > Number(el.amount) ||
        Number(el.assign) < 1
    );

    if (!check && !checkactive && (data.length|| newactives.length)) {
      try {
        setsaved(true);
        const response = await client.mutate({
          mutation: UPDATE_ITEM_REQUERIMENT,
          variables: {
            requeriment: id,
            data,
            dataconsumable:newactives
          },
        });

        setNewAssignRequeriment(response.data.updateRequerimentItem);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        setsaved(false);
        handleCloseModal();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    } else {
      setError(true);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Box>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">Descripcion </TableCell>
                  <TableCell align="">Tipo </TableCell>
                  <TableCell align="">Atendido</TableCell>
                  <TableCell align="">Cantidad</TableCell>
                  <TableCell align=""> Estado</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          {requerimentandActives.map((row, index) => (
            <Accordion
              expanded={expanded === "panel" + index + 2}
              onChange={handleChange("panel" + index + 2)}
              style={{ backgroundColor: "#fff" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={"panel" + index + 2 + "a-header"}
              >
                {/*   {row.item?.description} */}
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <p align="">{/* row.requeriment.item?.description?.slice(0, 7)+"\n"+ */
                   dividirTexto(row.requeriment.item?.description,12)}
                   </p>
                  <p align="">
                    {row.consumable ? "Consumible" : "No consumible"}{" "}
                  </p>
                  {/*   <TableCell align="">{row.requeriment.item?.active} </TableCell> */}
                  <p align="">{row.requeriment.current} </p>
                  <p align="">{row.requeriment.amount} </p>
                  <p align="">
                    {row.requeriment.complete ? "COMPLETADO" : "NO COMPLETADO"}{" "}
                  </p>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {row.consumable ? (
                  <Box
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                        borderRadius: "5rem",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.alt,
                      },
                      "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                      },
                    }}
                  >
                    <hr></hr>

                    {!row.requeriment.complete ? (
                      <DataGrid
                        loading={!row.item}
                        autoHeight={true}
                        checkboxSelection
                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        rows={row.item || []}
                        columns={columns}
                        onSelectionModelChange={(itm) => {
                          //setdatactive(itm)
                          setdatactive((prevData) => {
                            const itemExists = prevData.some(
                              (obj) => obj.item === row.requeriment.item.id
                            );

                            if (itemExists) {
                              return prevData;
                            } else {
                              return [
                                ...prevData,
                                {
                                  item: row.requeriment.item.id,
                                  current: row.requeriment.current,
                                  amount: row.requeriment.amount,
                                  avalible: row.item[0].amountfree,
                                },
                              ];
                            }
                          });
                        }}
                        components={{
                          Toolbar: () => (
                            <GridToolbarContainer>
                              <GridToolbarFilterButton />
                              <GridToolbarDensitySelector />
                            </GridToolbarContainer>
                          ),
                        }}
                      />
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          border: "1px solid #bf3333",
                          borderRadius: "5px",
                          color: "#bf3333",
                          padding: "10px",
                        }}
                      >
                        El requerimiento para este Item ya esta completado
                      </p>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                        borderRadius: "5rem",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.alt,
                      },
                      "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                      },
                    }}
                  >
                    <hr></hr>
                    {!row.requeriment.complete ? (
                      <DataGrid
                        loading={!row.active}
                        autoHeight={true}
                        checkboxSelection
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
                        disableSelectionOnClick 
                        getRowId={(row) => row.id}
                        rows={row.active || []}
                        columns={columnsactive}
                        onSelectionModelChange={(itm) => {
                          setdata((prevData) => {
                            const itemExists = prevData.some(
                              (obj) => obj.item === row.requeriment.item.id
                            );

                            if (itemExists) {
                              const updatedData = prevData.map((obj) => {
                                if (obj.item === row.requeriment.item.id) {
                                  return {
                                    ...obj,
                                    active: itm,
                                  };
                                }
                                return obj;
                              });
                              const filteredData = updatedData.filter(
                                (obj) => obj.active.length > 0
                              );

                              return filteredData;
                            } else {
                              return [
                                ...prevData,
                                {
                                  item: row.requeriment.item.id,
                                  current: row.requeriment.current,
                                  amount: row.requeriment.amount,
                                  active: itm,
                                },
                              ];
                            }
                          });
                        }}
                        components={{
                          Toolbar: () => (
                            <GridToolbarContainer>
                              <GridToolbarFilterButton />
                              <GridToolbarDensitySelector />
                            </GridToolbarContainer>
                          ),
                        }}
                      />
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          border: "1px solid #bf3333",
                          borderRadius: "5px",
                          color: "#bf3333",
                          padding: "10px",
                        }}
                      >
                        El requerimiento para este Item ya esta completado
                      </p>
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
          {error && (
            <p
              style={{
                textAlign: "center",
                border: "1px solid #bf3333",
                borderRadius: "5px",
                color: "#bf3333",
                padding: "10px",
              }}
            >
              Verifique que los datos sean correctos
            </p>
          )}
        </Box>
        <Box mt={"25px"} display={"flex"} justifyContent={"end"} gap={"15px"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={saved}
          >
            {saved ? <LoopIcon /> : "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default AssignItem;
