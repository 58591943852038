import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import UsersInfo from "./UsersInfo";
import UserForm from "./UserForm";
import UsersList from "./UsersList";

const { Box, Grid } = require("@mui/material");

const User = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <FlexBetween>
          <Header title="GESTION DE USUARIOS" subtitle="config/user" />
        </FlexBetween>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box
            bgcolor="white"
            p="1.5rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            borderRadius={2}
          >
            <UsersInfo />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box bgcolor="white" height="100%" borderRadius={2}>
            <UsersList />
          </Box>
        </Grid>
        {/*  <Grid item xs={7}>
          <Box bgcolor="white" height="100%" borderRadius={2}>
            <UserForm />
          </Box>
        </Grid> */}
      </Grid>

     {/*  <Box width="100%" height="32rem" my="1rem">
        <UsersList />
      </Box> */}
    </Box>
  );
};

export default User;
