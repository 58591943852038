import React, { useState,useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import Alerts from "components/Alerts";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Autocomplete from "@mui/material/Autocomplete";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { useApolloClient } from "@apollo/client";
import { SINTOMATOLOGY_SCHEMA_YUP } from "./YupSintoIncident";
import RotatingLoopIcon from './../../utils/RotatingLoopIcon'; 
import { CREATE_SINTOMATOLOGY } from "./QuerySintoIncident";
import User from "scenes/configuration/User";
import { useUser } from "hooks/useUser";
const CreateSintomatology = ({
  alertCustom,
  setAlertCustom,
  listOperator,
  listQuestion,
  setNewSintomatology,
}) => {
  const client = useApolloClient();
  const [error, setError] = useState(false);
  const { user } = useUser();
  const autocompletepersonalRef = useRef(null);
  const formikSintomatology = useFormik({
    initialValues: {
      operator: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      criterion: [
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" },
      ],
    },
    validationSchema: SINTOMATOLOGY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { operator, criterion } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_SINTOMATOLOGY,
          variables: {
            operator:operator.id,
            criterion,
          },
        });
        setNewSintomatology(response.data.createSintomatology);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if(user.admin){
          autocompletepersonalRef.current.value=""
        }
        

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikSintomatology.handleSubmit}>
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        {user.admin?<Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap="20px">
          {listOperator?.length ? (
            <Autocomplete
              freeSolo
              id="operator"
              name="operator"
              ref={autocompletepersonalRef}
              value={formikSintomatology.values.operator.label}
              disableClearable
              options={listOperator?.map(
                (option) =>
                  new Object({
                    label:                     
                      option.postulant.name +
                      "  " +                   
                      option.postulant.surname +
                      " / " +
                      "dni:" +
                      option.postulant.doi,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                formikSintomatology.setFieldValue("operator", {
                  label: newValue.label,
                  id: newValue.value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={
                    formikSintomatology.touched.operator?.id &&
                    Boolean(formikSintomatology.errors.operator?.id)
                  }
                  helperText={
                    formikSintomatology.touched.operator?.id &&
                    formikSintomatology.errors.operator?.id
                  }
                />
              )}
            />
          ): (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                No hay Datos para mostrar
              </p>
            </FormHelperText>
          )}
        </Box>:null}
        <Box mt={"20px"}>
          <TableContainer style={{ maxHeight: 280, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Si</TableCell>
                  <TableCell align="">No</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listQuestion.map((row, index) => (
                  /*        <RadioGroup
                    name={`criterion[${index}].answer`}
                    value={formikSintomatology.values.criterion?.[index]?.answer}
                    onChange={(e) => {
                      formikSintomatology.setFieldValue(
                        `criterion[${index}].answer`,
                        Boolean(e.target.value)
                      );
                      formikSintomatology.setFieldValue(
                        `criterion[${index}].question`,
                        row.id
                      );
                      console.log(formikSintomatology.touched.criterion);
                    }}
                  > */
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikSintomatology.values.criterion?.[index]
                            ?.answer === true
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }} 
                        onChange={(e) => {
                          formikSintomatology.setFieldValue(
                            `criterion[${index}].answer`,
                            Boolean(e.target.value)
                          );
                          formikSintomatology.setFieldValue(
                            `criterion[${index}].question`,
                            row.id
                          );
                        }}
                        value={true}
                        name={`criterion[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikSintomatology.values.criterion?.[index]
                            ?.answer === false
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }} 
                        onChange={(e) => {
                          formikSintomatology.setFieldValue(
                            `criterion[${index}].answer`,
                            Boolean(!e.target.value)
                          );
                          formikSintomatology.setFieldValue(
                            `criterion[${index}].question`,
                            row.id
                          );
                        }}
                        value={false}
                        name={`criterion[${index}].question`}
                      />
                    </TableCell>
                  </TableRow>
                  /* </RadioGroup> */
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        
          {formikSintomatology.touched.criterion &&
          formikSintomatology.errors.criterion?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                tTiene que haber un ganador en la seleccion.
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"15px"}>
          <Button variant="contained" color="success" type="submit"  disabled={formikSintomatology.isSubmitting}>
          {formikSintomatology.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateSintomatology;
