import React, { useState,useRef } from "react";
import { Box, Button, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import { FormHelperText } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoopIcon from '@mui/icons-material/Loop';
import { CREATE_VEHICLE_SYSTEM } from "./QueryItem";
import { ASSEMBLY_SCHEMA_YUP } from "./YupValidation";
const CreateAssembly = ({
  activeVehicle,
  activeNoVehicle,
  alertCustom,
  setAlertCustom,
  systemType,
  setNewAssembly,
}) => {

  const client = useApolloClient();
  const autocompleteActiveRef = useRef(null);
  const autocompleteNoActiveRef = useRef(null);
  const formikAssembly = useFormik({
    initialValues: {
      type: "",
      vehicle:{
        label: "",
        id: "",
      },
      novehicle: {
        label: "",
        id: "",
      },
    },
    validationSchema: ASSEMBLY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      /*    console.log(values); */
      setSubmitting(true);
      const { type, vehicle, novehicle } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_VEHICLE_SYSTEM,
          variables: {
            type,
            vehicle:vehicle.id,
            novehicle:novehicle.id,
          },
        });
        setNewAssembly(response.data.createVehicleSystem);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        autocompleteActiveRef.current.value = "";
        autocompleteNoActiveRef.current.value = "";
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikAssembly.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        fontSize={"12px"}
        fontWeight={"700"}
        textAlign={"center"}
        py={"10px"}
      >
        HACER ENSAMBLAJE
      </Box>
      <Box mb={"20px"}>
        {activeVehicle?.length ? (
          <Autocomplete
            freeSolo
            id="vehicle"
            name="vehicle"
            disableClearable
            ref={autocompleteActiveRef}
            value={formikAssembly.values.vehicle.label}
            options={activeVehicle?.map(
              (option) =>
                new Object({
                  label:
                    "active:" +
                    option.description +
                    " / " +
                    "placa:" +
                    option.plate +
                    " / " +
                    "nombre Item:" +
                    option.item.description,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikAssembly.setFieldValue("vehicle", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por descripcion/placa"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikAssembly.touched.vehicle?.id &&
                  Boolean(formikAssembly.errors.vehicle?.id)
                }
                helperText={
                  formikAssembly.touched.vehicle?.id &&
                  formikAssembly.errors.vehicle?.id
                }
              />
            )}
          />
        ):(<FormHelperText
          sx={{ color: "#bf3333", marginLeft: "16px !important" }}
        >
          <p
            style={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            No hay Datos para mostrar
          </p>
        </FormHelperText>)}
      </Box>
      <Box mb={"20px"}>
        {activeNoVehicle?.length ? (
          <Autocomplete
            freeSolo
            id="novehicle"
            name="novehicle"
            disableClearable
            ref={autocompleteNoActiveRef}
            value={formikAssembly.values.novehicle.label}
            options={activeNoVehicle?.map(
              (option) =>
                new Object({
                  label:
                    "active:" +
                    option.description +
                    " / " +
                    "placa:" +
                    option.plate +
                    " / " +
                    "nombre Item:" +
                    option.item.description,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikAssembly.setFieldValue("novehicle", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre/apellido/dni"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikAssembly.touched.novehicle?.id &&
                  Boolean(formikAssembly.errors.novehicle?.id)
                }
                helperText={
                  formikAssembly.touched.novehicle?.id &&
                  formikAssembly.errors.novehicle?.id
                }
              />
            )}
          />
        ):(<FormHelperText
          sx={{ color: "#bf3333", marginLeft: "16px !important" }}
        >
          <p
            style={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            No hay Datos para mostrar
          </p>
        </FormHelperText>)}
      </Box>
      <Box>
        <RadioGroup
          row
          id="type"
          name="type"
          value={formikAssembly.values.type}
          onChange={formikAssembly.handleChange}
        >
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Sistema</TableCell>
                  <TableCell align=""></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {systemType.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      {" "}
                      <FormControlLabel value={row.id} control={<Radio  sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }}  />} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RadioGroup>
        {/*   <Box display={"flex"} justifyContent={"end"}>       
          <Button variant="contained" color="success" type="submit">
            GUARGAR
          </Button>
        </Box> */}
        {formikAssembly.touched.type && formikAssembly.errors.type ? (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              {formikAssembly.touched.type && formikAssembly.errors.type}
            </p>
          </FormHelperText>
        ) : null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikAssembly.isSubmitting}>
        {formikAssembly.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateAssembly;
