import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import { GET_INCIDENT_BY_ID } from "./QuerySintoIncident";

const ViewIncident = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [incident, setIncident] = useState([]);
  useEffect(() => {
    const getIncident = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_INCIDENT_BY_ID,
          variables: {
            getIncidentByIdId: id,
          },
        });
        console.log(data.getIncidentById);
        setIncident(data.getIncidentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getIncident(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">DESCRIPCION</label>
            <TextField disabled value={incident?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField
              disabled
              value={new Date(incident?.date).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PERSONA</label>
            <TextField
              disabled
              value={
                incident?.personal.postulant.name +
                " " +
                incident?.personal.postulant.surname
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={incident?.type.description} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewIncident;
