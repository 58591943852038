import { gql } from "@apollo/client";


export const GET_DOCUMENT_VEHICLES_STATES = gql`
query GetDocumentVehicles {
  getDocumentVehicles {
    document {
      state {
        id
        description
      }
      document {
        id
        description
        kind
      }
     
    }
    active {
      plate
      serie
      description
    }
  }
}
`;


export const GET_LIST_DOCUMENT_VEHICLES = gql`
query GetDocumentListVehicles {
  getDocumentListVehicles {
    description
    id
    kind
    type
    weight
  }
}
`;
