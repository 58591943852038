import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { GET_BUY_ORDER_VIEW_BY_ID } from "./QueryItemRequeriment";
import Table from "@mui/material/Table";
import LoopIcon from '@mui/icons-material/Loop';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
const ViewBuyOrder = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  const theme = useTheme();
  const { currency, dateFormat } = useSettings();
  useEffect(() => {
    const getItemRequeriment = async (id) => {     
      try {
        const { data } = await client.query({
          query: GET_BUY_ORDER_VIEW_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            getBuyOrderItemByIdId: id,
          },
        });
        console.log(data.getBuyOrderItemById);
        setItemRequeriment(data.getBuyOrderItemById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">REQUERIMIENTO</label>
            <TextField disabled value={itemRequeriment?.requeriment.code} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PRIORIDAD</label>
            <TextField disabled value={itemRequeriment?.priority.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">MOTIVO</label>
            <TextField disabled value={itemRequeriment?.subject} />
          </Box>
          <Box sx={style}>
            <label for="my-input">SOLICITANTE</label>
            <TextField disabled value={itemRequeriment?.personal.postulant.name+" "+itemRequeriment?.personal.postulant.surname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROVEEDOR</label>
            <TextField disabled value={itemRequeriment?.provider.businessname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">AREA</label>
            <TextField disabled value={itemRequeriment?.area.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FORMA DE PAGO</label>
            <TextField disabled value={itemRequeriment?.formpay} />
          </Box>
          <Box sx={style}>
            <label for="my-input">CONDICION DE PAGO</label>
            <TextField disabled value={itemRequeriment?.conditionpay} />
          </Box>
          <Box sx={style}>
            <label for="my-input">MONEDA</label>
            <TextField disabled value={itemRequeriment?.currency.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">IGV</label>
            <TextField disabled value={itemRequeriment?.igv+" %"} />
          </Box>
          <Box sx={style}>
            <label for="my-input">SUBTOTAL</label>
            <TextField disabled value={itemRequeriment?.subtotal} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TOTAL</label>
            <TextField disabled value={itemRequeriment?.total} />
          </Box>
        </Box>
        <Box my={"20px"}>
          {itemRequeriment.item.length ? (
            <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="">ACTIVO</TableCell>
                    <TableCell align="">DESCRIPCION</TableCell>
                 
                    <TableCell align="">ACTUAL</TableCell>
                    <TableCell align="">CANTIDAD</TableCell>
                    <TableCell align="">COMPLETO</TableCell>
                    <TableCell align="">VALOR UNITARIO</TableCell>
                    <TableCell align="">DESCUENTO</TableCell>
                    <TableCell align="">COSTO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemRequeriment.item.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="">{row.item.active}</TableCell>
                        <TableCell align="">{row.item.description}</TableCell>                      
                        <TableCell align="">{row.current}</TableCell>
                        <TableCell align="">{row.amount}</TableCell>
                        <TableCell align="">
                          {row.complete ? "SI" : "NO"}
                        </TableCell>
                        <TableCell align="">
                          {row.unitvalue ?  row.unitvalue : ""}
                        </TableCell>
                        <TableCell align="">
                          {row.discount ?  row.discount+"%" : ""}
                        </TableCell>
                        <TableCell align="">
                          {row.cost ?  row.cost : ""}
                        </TableCell>
                      </TableRow>
                      /* </RadioGroup> */
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box></Box>
          )}
        </Box>
        <Box my={"20px"}>
        <TextField
        label="OBSERVACION"
        variant="outlined"
        fullWidth
           multiline
           disabled
          rows={3}
          value={itemRequeriment?.observation} />
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
        <Button variant="contained" onClick={handleCloseModal}>
          CERRAR
        </Button>
        {/*  */}
      </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewBuyOrder;
