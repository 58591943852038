import React from "react";
import {
  Box,
} from "@mui/material";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ViewIndicatorMain = ({
    items,
    itemIndicators,
    yearIndicators,
    monthIndicators,    
    setItemIndicators,
    setYearIndicators,    
    setMonthIndicators,
    actives,
    activeIndicators,
    setActiveIndicators,
}) => {
    const meses = [
        { value: 1, label: "Enero" },
        { value: 2, label: "Febrero" },
        { value: 3, label: "Marzo" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Mayo" },
        { value: 6, label: "Junio" },
        { value: 7, label: "Julio" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Septiembre" },
        { value: 10, label: "Octubre" },
        { value: 11, label: "Noviembre" },
        { value: 12, label: "Diciembre" },
      ];
      return (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">FLOTA</InputLabel>
            <Select
              id="group"
              value={itemIndicators}
              name="group"
              onChange={(e) => {
                setItemIndicators(e.target.value);
              }}
            label="FLOTA"
            >
              <MenuItem key={-1} value={-1} disabled>
                {"Seleccione una opcion"}
              </MenuItem>
              {items?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description + " / " + option.active}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="anio"
            name="anio"
            label="AÑO"
            type="number"
            variant="outlined"
            value={yearIndicators}
            onChange={(e) => {
                setYearIndicators(e.target.value);
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">MES</InputLabel>
            <Select
              id="classs"
              value={monthIndicators}
              name="classs"
              label="MES" 
              onChange={(e) => {
                setMonthIndicators(e.target.value);
              }}
            >
              <MenuItem key={-1} value={-1}>
                    TODOS LOS MESES
                  </MenuItem>
              {meses?.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">EQUIPO</InputLabel>
            <Select
              id="active"
              value={activeIndicators}
              name="active"
              label="EQUIPO"
              onChange={(e) => {
                setActiveIndicators(e.target.value);
              }}
            >
              <MenuItem key={-1} value={-1}>
                {"Todos los Equipos"}
              </MenuItem>
              {actives?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      );
}

export default ViewIndicatorMain