import React, { useState, useEffect,  } from "react";
import {
  Box,
  Button,

} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import LoopIcon from '@mui/icons-material/Loop';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GET_JOB_REFERENCE_BY_ID } from "./QueryJobReference";
const ViewJobReference = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [jobreferenceRequeriment, setJobreferenceRequeriment] = useState([]);
  useEffect(() => {
    const getJobreferenceRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_JOB_REFERENCE_BY_ID,
          variables: {
            getJobReferenceByIdId: id,
          },
        });
        console.log(data.getJobReferenceById);
        setJobreferenceRequeriment(data.getJobReferenceById);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getJobreferenceRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">REFERENTE</label>
            <TextField
              disabled
              value={
                jobreferenceRequeriment?.name +
                " " +
                jobreferenceRequeriment?.surname
              }
            />
          </Box>
          
          <Box sx={style}>
            <label for="my-input">COMPAÑIA</label>
            <TextField disabled value={jobreferenceRequeriment?.company} />
          </Box>
          <Box sx={style}>
            <label for="my-input">POSICION</label>
            <TextField
              disabled
              value={jobreferenceRequeriment?.bossposition}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TELEFONO</label>
            <TextField disabled value={jobreferenceRequeriment?.phone} />
          </Box>
          <Box sx={style}>
            <label for="my-input">RECOMENDADO</label>
            <TextField
              disabled
              value={jobreferenceRequeriment?.recomended ? "SI" : "NO"}
            />
          </Box>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 300, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">CRITERIO</TableCell>
                  <TableCell align="">RESPUESTA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobreferenceRequeriment.criterionQuestion.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row.criterion.description}
                      </TableCell>
                      <TableCell align="">{row.answer}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewJobReference;
