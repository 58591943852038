import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  SettingsOutlined,
} from "@mui/icons-material";
import ModalUtil from "components/ModalUtil";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import CreatePersonal from "./CreatePersonal";
import StatBox from "components/StatBox";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useApolloClient } from "@apollo/client";
import ViewPersonal from "./ViewPersonal";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DialogUtil from "components/DialogUtil";
import { GET_PERSONAL_POSITION, GET_MAIN_DATA } from "./QueryPersonal";
import {
  GET_ALL_CONTEXTURE,
  GET_ALL_GENDER,
  GET_ALL_CIVIL_STATUS,
  GET_ALL_DEPARTAMENT,
} from "./../FichaPersonal/QueryPersonal";
import ViewPdf from "components/ViewPdf";
import TemplatePDF from "./TemplatePDF";
import { GET_ALL_POSTULANT_FOR_PERSONAL } from "./../postulant/QueryPostulant";
import DeletePersonal from "./DeletePersonal";
import EditPersonal from "./EditPersonal";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
const Personal = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const { user } = useUser();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newPersonal, setNewPersonal] = useState(null);
  const [personalPosition, setPersonalPosition] = useState(null);
  const [departament, setDepartament] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [civilstatus, setCivilStatus] = useState([]);
  const [gender, setGender] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [personalselected, setpersonalselected] = useState(null);
  const [contexture, setContexture] = useState([]);
  const [postulant, setPostulant] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const { dateFormat, isLoading } = useSettings();
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setpersonalselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenDialog = (id) => {
    setDialogOpen(true);
    setpersonalselected(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleOpenModalEdit = (id) => {
    setModalEdit(true);
    setpersonalselected(id);
  };

  const handleCloseModalEdit = () => {
    setModalEdit(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "postulant.name",
      headerName: "NOMBRE",
      flex: 1,
      renderCell: (params) => params.row.postulant.name,
    },
    {
      field: "postulant.surname",
      headerName: "APELLIDOS",
      flex: 1,
      renderCell: (params) => params.row.postulant.surname,
    },
    {
      field: "postulant.doi",
      headerName: "DNI",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.row.postulant.doi,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {/*   <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} /> */}
          {user.admin ? (
            <>
              <LocalPrintshopIcon
                color="success"
                onClick={() => handleOpenModalPDF(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDialog(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setpersonalselected(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  useEffect(() => {
    const FUNCTION_GET_PERSONAL_POSITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_POSITION,
          fetchPolicy: "network-only",
        });
        console.log(data.getPersonalPosition);
        setPersonalPosition(data.getPersonalPosition);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_CONTEXTURE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CONTEXTURE,
        });
        setContexture(data.getContexture);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_GENDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_GENDER,
        });
        setGender(data.getGender);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_CIVIL_STATUS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_CIVIL_STATUS,
        });
        setCivilStatus(data.getCivilStatus);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_DEPARTAMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DEPARTAMENT,
        });
        setDepartament(data.getAllDepartaments);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_POSTULANT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_POSTULANT_FOR_PERSONAL,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllPostulantforPersonal);
        setPostulant(data.getAllPostulantforPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumarizePersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_PERSONAL_POSITION();
    FUNCTION_GET_ALL_DEPARTAMENT();
    FUNCTION_GET_ALL_CONTEXTURE();
    FUNCTION_GET_ALL_GENDER();
    FUNCTION_GET_ALL_POSTULANT();
    FUNCTION_GET_ALL_CIVIL_STATUS();
    FUNCTION_MAIN_DATA();
  }, [newPersonal]);
  return (
    <>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Referencia Laboral",
          })
        }
        id={personalselected}
      ></ModalUtil>

      <DialogUtil
        title={"ELIMINAR PERSONAL"}
        description={
          "¿ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO?. PODRIA TRAER IMPLICANCIAS IRREPARABLES"
        }
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        Component={DeletePersonal}
        id={personalselected}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewPersonal,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="GESTION DE PERSONAS" subtitle="personal/personal" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Personal"
            value={mainData?.personal}
            /*   increase="+14%" */
            description="Forman parte de la empresa"
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultimo"
            value={mainData?.lastPersonal}
            /*  increase="+21%" */
            description={"Personal"}
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}           
            >
              CREAR PERSONAL
            </Box>
            <CreatePersonal
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              setNewPersonal={setNewPersonal}
              postulant={postulant}
              civilstatus={civilstatus}
              gender={gender}
              departament={departament}
              contexture={contexture}
            />
          </Box>
          <StatBox
            title="Fecha"
            value={
              mainData?.lastDate && !isLoading
                ? DateFormatting(Number(mainData?.lastDate), dateFormat)
                : null
            }
            /*   increase="+5%" */
            description={"Ultimo ingreso"}
            icon={
              <PersonAdd
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          {/* ROW 2 */}
          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="20px"
            >
              HISTORIAL DE PERSONAL
            </Box>
            <DataGrid
              loading={!personalPosition}
              getRowId={(row) => row.id}
              rows={personalPosition || []}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Personal;
