import React from "react";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { LANGUAGE_SCHEMA_YUP } from "./YupValidation";
import LoopIcon from '@mui/icons-material/Loop';
import Alerts from "components/Alerts";
import { CREATE_IDIOMS } from "./QueryPersonal";
const CreateIdiom = ({
  setErrorId,
  language,
  languageLevel,
  id,
  setNewIdiom,
  setAlertCustom,
  alertCustom,
}) => {
  const client = useApolloClient();

  const formikProfesion = useFormik({
    initialValues: {
      personal: "",
      language: "",
      level: "",
    },
    validationSchema: LANGUAGE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { personal, language, level } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_IDIOMS,
            variables: {
              personal: id,
              language,
              level: level ? level : null,
            },
          });
          //console.log(response.data);
          setNewIdiom(response.data.createIdioms);
          //setId(response.data.createPersonal.id);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikProfesion.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">IDIOMA</InputLabel>
          <Select
            id="language"
            value={formikProfesion.values.language}
            name="language"
            label="IDIOMA"
            onChange={formikProfesion.handleChange}
            error={
              formikProfesion.touched.language &&
              Boolean(formikProfesion.errors.language)
            }
          >
            {language?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProfesion.touched.language &&
            formikProfesion.errors.language ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProfesion.touched.language &&
                formikProfesion.errors.language}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">NIVEL</InputLabel>
          <Select
            id="level"
            value={formikProfesion.values.level}
            name="level"

            label="NIVEL"
            onChange={formikProfesion.handleChange}
            error={
              formikProfesion.touched.level &&
              Boolean(formikProfesion.errors.level)
            }
          >
            {languageLevel?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProfesion.touched.level && formikProfesion.errors.level ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProfesion.touched.level && formikProfesion.errors.level}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikProfesion.isSubmitting}>
          {formikProfesion.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateIdiom;
