import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ModalUtil from "components/ModalUtil";
import StatBoxOne from "components/StatBoxOne";
import StatBox from "components/StatBox";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";

import { GET_WORK_ORDER_ALL, GET_MAIN_DATA } from "./QueryWorkOrder";
import ViewWorkOrder from "./ViewWorkOrder";
import AssignWorkOrderWait from "./AssignWorkOrderWait";
import AssignWorkOrderPlanned from "./AssignWorkOrderPlanned";
import AssignWorkOrderProgramed from "./AssignWorkOrderProgramed";

const OrdenTrabajo = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [workOrder, setworkOrder] = useState([]);
  const [modalViewWait, setmodalViewWait] = useState(null);
  const [modalViewWaitAssign, setmodalViewWaitAssign] = useState(null);
  const [modalViewProgramedAssign, setmodalViewProgramedAssign] = useState(
    null
  );
  const [mainData, setMainData] = useState([]);
  const [modalViewPlannedAssign, setmodalViewPlannedAssign] = useState(null);
  const [id, setId] = useState(null);
  const [newAssignWorkOrderWait, setnewAssignWorkOrderWait] = useState(null);
  const [newAssignWorkOrderPlanned, setnewAssignWorkOrderPlanned] = useState(
    null
  );
  const [
    newAssignWorkOrderProgramed,
    setnewAssignWorkOrderProgramed,
  ] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalViewWait = (id) => {
    setmodalViewWait(true);
    setId(id);
  };

  const handleCloseModalViewWait = () => {
    setmodalViewWait(false);
  };
  const handleOpenModalViewWaitAssign = (id) => {
    setmodalViewWaitAssign(true);
    setId(id);
  };

  const handleCloseModalViewWaitAssign = () => {
    setmodalViewWaitAssign(false);
  };
  const handleOpenModalViewPlannedAssign = (id) => {
    setmodalViewPlannedAssign(true);
    setId(id);
  };

  const handleCloseModalViewPlannedAssign = () => {
    setmodalViewPlannedAssign(false);
  };

  const handleOpenModalViewProgramadedAssign = (id) => {
    setmodalViewProgramedAssign(true);
    setId(id);
  };

  const handleCloseModalViewProgramadedAssign = () => {
    setmodalViewProgramedAssign(false);
  };

  const columnsWorkOrderWait = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "requeriment.active.description",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.requeriment?.active?.description,
    },
    {
      field: "requeriment.type.description",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.requeriment?.type?.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewWait(params.row.id)}
          />
          <TouchAppIcon
            onClick={() => handleOpenModalViewWaitAssign(params.row.id)}
          />
          {/*    <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnsWorkOrderPlanned = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "requeriment.active.description",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.requeriment?.active?.description,
    },
    {
      field: "requeriment.type.description",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.requeriment?.type?.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewWait(params.row.id)}
          />
          <TouchAppIcon
            onClick={() => handleOpenModalViewPlannedAssign(params.row.id)}
          />
          {/*    <VisibilityIcon />
          <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnsWorkOrderProgramaded = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "requeriment.active.description",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.requeriment.active.description,
    },
    {
      field: "requeriment.type.description",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.requeriment.type.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewWait(params.row.id)}
          />
          <TouchAppIcon
            onClick={() => handleOpenModalViewProgramadedAssign(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  const columnsWorkOrderClosed = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "requeriment.active.description",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.requeriment.active.description,
    },
    {
      field: "requeriment.type.description",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.requeriment.type.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewWait(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_WORK_ORDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_WORK_ORDER_ALL,
          fetchPolicy: "network-only",
        });
        console.log(data.getWorkOrder);
        setworkOrder(data.getWorkOrder);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumarizeWorkOrderMaintance);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_WORK_ORDER();
    FUNCTION_MAIN_DATA();
  }, [
    newAssignWorkOrderWait,
    newAssignWorkOrderPlanned,
    newAssignWorkOrderProgramed,
  ]);
  return (
    <>
      <ModalUtil
        isOpen={modalViewWait}
        onClose={handleCloseModalViewWait}
        Component={ViewWorkOrder}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewWait,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalViewWaitAssign}
        onClose={handleCloseModalViewWaitAssign}
        Component={AssignWorkOrderWait}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setnewAssignWorkOrderWait,
            handleCloseModal: handleCloseModalViewWaitAssign,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalViewPlannedAssign}
        onClose={handleCloseModalViewPlannedAssign}
        Component={AssignWorkOrderPlanned}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setnewAssignWorkOrderPlanned,
            handleCloseModal: handleCloseModalViewPlannedAssign,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalViewProgramedAssign}
        onClose={handleCloseModalViewProgramadedAssign}
        Component={AssignWorkOrderProgramed}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setnewAssignWorkOrderProgramed,
            handleCloseModal: handleCloseModalViewProgramadedAssign,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header
            title="MANTENIMIENTO"
            subtitle="mantenimiento/requerimiento"
          />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Planeados"
            value={mainData.planned}
            increase={""}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Programados"
            value={mainData.programed}
            increase={"Tienen un fecha programda"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Cerrados"
            value={mainData.closed}
            increase={"Tienen una hora de inicio y fin"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE ORDENES DE TRABAJO PLANIFICADOS
            </Box>
            <DataGrid
              loading={!workOrder}
              getRowId={(row) => row.id}
              rows={
                workOrder?.filter(
                  (row) => row.state.description === "PLANIFICADA"
                ) || []
              }
              columns={columnsWorkOrderPlanned}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE ORDENES DE TRABAJO EN ESPERA DE PLANIFICACION
            </Box>
            <DataGrid
              loading={!workOrder}
              getRowId={(row) => row.id}
              rows={
                workOrder?.filter(
                  (row) =>
                    row.state.description === "EN ESPERA DE PLANIFICACION"
                ) || []
              }
              columns={columnsWorkOrderWait}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE ORDENES DE TRABAJO PROGRAMADAS
            </Box>
            <DataGrid
              loading={!workOrder}
              getRowId={(row) => row.id}
              rows={
                workOrder?.filter(
                  (row) => row.state.description === "PROGRAMADA"
                ) || []
              }
              columns={columnsWorkOrderProgramaded}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE ORDENES DE TRABAJO CERRADAS
            </Box>
            <DataGrid
              loading={!workOrder}
              getRowId={(row) => row.id}
              rows={
                workOrder?.filter(
                  (row) => row.state.description === "CERRADA"
                ) || []
              }
              columns={columnsWorkOrderClosed}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrdenTrabajo;
