import React, { useState, useEffect, useMemo } from "react";
import { GET_SOMNOLENCY_BY_ID } from "./QuerySomnolency";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  tableColumn: {
    flexDirection: "column",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  column: {
    flexDirection: "column",
    flex: 1,
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    paddingLeft: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellFlex: {
    flex: 1,
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});

const PDFSomnolency = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [somnolency, setSomnolency] = useState([]);

  useEffect(() => {
    const getSomnolency = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_SOMNOLENCY_BY_ID,
          variables: {
            getSomnolencyByIdId: id,
          },
        });     
        setSomnolency(data.getSomnolencyById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };

    getSomnolency(id);
    setvisibility(true);
  }, []);
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>CONTROL DE FATIGA Y SOMNOLENCIA</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS GENERALES</Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              PROYECTO:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {somnolency.somnolency?.project}{" "}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              FECHA:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {new Date(somnolency.somnolency?.date).toLocaleDateString(
                "es-ES"
              )}{" "}
            </Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              EVALUADOR:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {somnolency.somnolency?.evaluator.postulant.name+" "+somnolency.somnolency?.evaluator.postulant.surname}{" "}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              OPERADOR:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
            {somnolency.somnolency?.operator.postulant.name+" "+somnolency.somnolency?.operator.postulant.surname}{" "}
            </Text>
          </View>
        
          <View style={[styles.textTitle]}>
            <Text>
              El objetivo del presente formato es evaluar el nivel de
              operatividad del Conductor, por lo que deberá concluir si este
              "PROCEDE PARA INICIAR RUTA" O "NO PROCEDE PARA INICIAR RUTA"
            </Text>
          </View>

          <View style={[styles.title, styles.textTitle]}>
            <Text>EVIDENCIAS NOTABLES EN EL OPERADOR</Text>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, { width: "25px" }, styles.cell]}>
                N°:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                CRITERIO
              </Text>
              <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                {" "}
                RESPUESTA
              </Text>
              <Text style={[styles.text, styles.cell, { width: "120px" }]}>
                {" "}
                OBSERVACIÓN
              </Text>
            </View>
            {somnolency.questions?.slice(0, 10).map((el, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.text, styles.cell, { width: "25px" }]}>
                  {index + 1}
                </Text>
                <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                  {el.question?.description}
                </Text>
                <Text
                  style={[
                    styles.text,
                    styles.cell,
                    { width: "60px" },
                    { textAlign: "center" },
                  ]}
                >
                  {el.answer ? "SI" : "NO"}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "120px" }]}>
                  {el.observation}
                </Text>
              </View>
            ))}
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>EVALUADOR LE CONSULTA A OPERADOR Y/O CONDUCTOR DIRECTAMENTE</Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              HORAS TRANSCURRIDAS:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {somnolency.somnolency?.timeElapsed}{" "}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              HORAS QUE DURMIO:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {somnolency.somnolency?.timeSleep}{" "}
            </Text>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>EVIDENCIAS NOTABLES EN EL OPERADOR</Text>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, { width: "25px" }, styles.cell]}>
                N°:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                CRITERIO
              </Text>
              <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                {" "}
                RESPUESTA
              </Text>
              <Text style={[styles.text, styles.cell, { width: "120px" }]}>
                {" "}
                OBSERVACIÓN
              </Text>
            </View>
            {somnolency.questions?.slice(-10).map((el, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.text, styles.cell, { width: "25px" }]}>
                  {index + 1}
                </Text>
                <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                  {el.question?.description}
                </Text>
                <Text
                  style={[
                    styles.text,
                    styles.cell,
                    { width: "60px" },
                    { textAlign: "center" },
                  ]}
                >
                  {el.answer ? "SI" : "NO"}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "120px" }]}>
                  {el.observation}
                </Text>
              </View>
            ))}
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA DEL OPERADOR / CONDUCTOR
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA DEL EVALUADOR
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default PDFSomnolency;
