import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import { CREATE_EXTRA_DATA } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
import { EXTRA_SCHEMA_YUP } from "./YupValidation";
import Alerts from "components/Alerts";
const CreateExtraData = ({
  pensionFund,
  pensionType,
  alertCustom,
  setAlertCustom,
  id,
  setNewExtraData,
  setErrorId,
}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();

  const formikExtraData = useFormik({
    initialValues: {
      personal: "",
      ruc: "",
      ess: "",
      cusspp: "",
      type: "",
      date: new Date(),
      pensionfund: "",
    },
    validationSchema: EXTRA_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { ruc, ess, cusspp, type, date, pensionfund } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_EXTRA_DATA,
            variables: {
              personal: id,
              ruc,
              ess,
              cusspp,
              type: type ? type : null,
              date,
              pensionfund: pensionfund ? pensionfund : null,
            },
          });

          setNewExtraData(response.data.createExtra);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikExtraData.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="ruc"
          name="ruc"
          label="RUC"
          variant="outlined"
          value={formikExtraData.values.ruc}
          onChange={formikExtraData.handleChange}
          error={
            formikExtraData.touched.ruc && Boolean(formikExtraData.errors.ruc)
          }
          helperText={formikExtraData.touched.ruc && formikExtraData.errors.ruc}
        />
        <TextField
          id="ess"
          name="ess"
          label="ESS"
          variant="outlined"
          value={formikExtraData.values.ess}
          onChange={formikExtraData.handleChange}
          error={
            formikExtraData.touched.ess && Boolean(formikExtraData.errors.ess)
          }
          helperText={formikExtraData.touched.ess && formikExtraData.errors.ess}
        />
        <TextField
          id="cusspp"
          name="cusspp"
          label="CUSSPP"
          variant="outlined"
          value={formikExtraData.values.cusspp}
          onChange={formikExtraData.handleChange}
          error={
            formikExtraData.touched.cusspp &&
            Boolean(formikExtraData.errors.cusspp)
          }
          helperText={
            formikExtraData.touched.cusspp && formikExtraData.errors.cusspp
          }
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikExtraData.values.date}
            id="date"
            name="date"
            label="FECHA DE AFILIACIÓN"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikExtraData.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            FONDO DE PENSIONES
          </InputLabel>
          <Select
            id="pensionfund"
            value={formikExtraData.values.pensionfund}
            name="pensionfund"
            label="FONDO DE PENSIONES"
            onChange={formikExtraData.handleChange}
            error={
              formikExtraData.touched.pensionfund &&
              Boolean(formikExtraData.errors.pensionfund)
            }
          >
            {pensionFund?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikExtraData.touched.pensionfund &&
            formikExtraData.errors.pensionfund ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikExtraData.touched.pensionfund &&
                formikExtraData.errors.pensionfund}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">TIPO</InputLabel>
          <Select
            id="type"
            value={formikExtraData.values.type}
            name="type"
            onChange={formikExtraData.handleChange}
            error={
              formikExtraData.touched.type &&
              Boolean(formikExtraData.errors.type)
            }
            label="TIPO"
          >
            {pensionType?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikExtraData.touched.type && formikExtraData.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikExtraData.touched.type && formikExtraData.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikExtraData.isSubmitting}>
          {formikExtraData.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateExtraData;
