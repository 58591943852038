import React, { useState, useRef } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormHelperText } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import Radio from "@mui/material/Radio";
import Alerts from "components/Alerts";
import { DatePicker, TimePicker, jaJP } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Divider from "@mui/material/Divider";
import TabPanel from "@mui/lab/TabPanel";
import { VAN_SCHEMA_YUP } from "./YupVan";
import { CREATE_PREUSE_VAN } from "./QueryVan";
import { useUser } from "hooks/useUser";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import { ChangeFormatDb } from "utils/DateFormatting";
import { useSettings } from "hooks/useSettings";
import dayjs from 'dayjs';
const CreateVanPreUso = ({
  alertCustom,
  setAlertCustom,
  question,
  personal,
  declaration,
  setNewVanPreUse,
}) => {
  const { dateFormat } = useSettings(); 
  const client = useApolloClient();
  const { user } = useUser();
  const autocompletepersonalRef = useRef(null);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const lensize = [0];
  const formikVanPreUso = useFormik({
    initialValues: {
      driver: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      date: new Date(),
      time: dayjs('2022-04-17T15:30'),
      plate: "",
      kmBegin: "",
      kmEnd: "",
      activity: "",
      declarations: "",
      components: "",
    },
    validationSchema: VAN_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        driver,
        date,
        time,
        plate,
        kmBegin,
        kmEnd,
        activity,
        declarations,
        components,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_PREUSE_VAN,
          variables: {
            driver: driver.id,
            date,
            time,
            plate: plate.trim().toUpperCase(),
            kmBegin: Number(kmBegin),
            kmEnd: Number(kmEnd),
            activity: activity.trim().toUpperCase(),
            declarations,
            components,
          },
        });
        setNewVanPreUse(response.createPreUseVan);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikVanPreUso.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="20px">
          {user.admin ? (
            <Box>
              {personal?.length ? (
                <Autocomplete
                  freeSolo
                  id="driver"
                  name="driver"
                  ref={autocompletepersonalRef}
                  value={formikVanPreUso.values.driver.label}
                  disableClearable
                  options={personal?.map(
                    (option) =>
                      new Object({
                        label:                        
                          option.postulant.name +
                          "  " +                        
                          option.postulant.surname +
                          " / " +
                          "dni:" +
                          option.postulant.doi,
                        value: option.id,
                      })
                  )}
                  onChange={(event, newValue) => {
                    formikVanPreUso.setFieldValue("driver", {
                      label: newValue.label,
                      id: newValue.value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar Conductor por nombre/apellido/dni"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={
                        formikVanPreUso.touched.driver?.id &&
                        Boolean(formikVanPreUso.errors.driver?.id)
                      }
                      helperText={
                        formikVanPreUso.touched.driver?.id &&
                        formikVanPreUso.errors.driver?.id
                      }
                    />
                  )}
                />
              ) : (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  <p
                    style={{
                      border: "1px solid",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    No hay Datos para mostrar
                  </p>
                </FormHelperText>
              )}
            </Box>
          ) : null}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikVanPreUso.values.date}
              id="date"
              name="date"
              label="FECHA"
              format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
              onChange={(val) => {
                formikVanPreUso.setFieldValue("date", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              id="time"
              label="HORARIO INICIO"
              name="time"
              value={formikVanPreUso.values.time}
              onChange={(val) => {
                formikVanPreUso.setFieldValue("time", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <TextField
            id="plate"
            name="plate"
            label="PLACA"
            variant="outlined"
            value={formikVanPreUso.values.plate}
            onChange={formikVanPreUso.handleChange}
            error={
              formikVanPreUso.touched.plate &&
              Boolean(formikVanPreUso.errors.plate)
            }
            helperText={
              formikVanPreUso.touched.plate && formikVanPreUso.errors.plate
            }
          />
          <TextField
            id="kmBegin"
            name="kmBegin"
            label="KM INICIAL"
            variant="outlined"
            value={formikVanPreUso.values.kmBegin}
            onChange={formikVanPreUso.handleChange}
            error={
              formikVanPreUso.touched.kmBegin &&
              Boolean(formikVanPreUso.errors.kmBegin)
            }
            helperText={
              formikVanPreUso.touched.kmBegin && formikVanPreUso.errors.kmBegin
            }
          />
          <TextField
            id="kmEnd"
            name="kmEnd"
            label="KM FINAL"
            variant="outlined"
            value={formikVanPreUso.values.kmEnd}
            onChange={formikVanPreUso.handleChange}
            error={
              formikVanPreUso.touched.kmEnd &&
              Boolean(formikVanPreUso.errors.kmEnd)
            }
            helperText={
              formikVanPreUso.touched.kmEnd && formikVanPreUso.errors.kmEnd
            }
          />
          <TextField
            id="activity"
            name="activity"
            label="ACTIVIDAD / DESTINO"
            variant="outlined"
            value={formikVanPreUso.values.activity}
            onChange={formikVanPreUso.handleChange}
            error={
              formikVanPreUso.touched.activity &&
              Boolean(formikVanPreUso.errors.activity)
            }
            helperText={
              formikVanPreUso.touched.activity &&
              formikVanPreUso.errors.activity
            }
          />
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 500, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Si</TableCell>
                  <TableCell align="">No</TableCell>
                  <TableCell align="">Observaciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {declaration.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikVanPreUso.values.declarations?.[index]
                            ?.answer === true
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].answer`,
                            Boolean(e.target.value)
                          );
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        value={true}
                        name={`declarations[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikVanPreUso.values.declarations?.[index]
                            ?.answer === false
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].answer`,
                            Boolean(!e.target.value)
                          );
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        value={false}
                        name={`declarations[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <TextField
                        name={`declarations[${index}].observation`}
                        variant="outlined"
                        value={
                          formikVanPreUso.values.declarations?.[index]
                            ?.observation
                        }
                        onChange={(e) => {
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].observation`,
                            e.target.value
                          );
                          formikVanPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        error={
                          formikVanPreUso.touched.declarations?.[index]
                            ?.observation &&
                          Boolean(
                            formikVanPreUso.errors.declarations?.[index]
                              ?.observation
                          )
                        }
                        helperText={
                          formikVanPreUso.touched.declarations?.[index]
                            ?.observation &&
                          formikVanPreUso.errors.declarations?.[index]
                            ?.observation
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {formikVanPreUso.touched.declarations &&
          formikVanPreUso.errors.declarations?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Verica los campos esten requeridos
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Divider />
        <Box mb={"20px"}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {question.map((el, index) => {
                  lensize.push(lensize[lensize.length - 1] + el.element.length);
                  return <Tab label={el.category} value={index} />;
                })}
              </TabList>
            </Box>
            {question.map((el, index) => (
              <TabPanel
                value={index}
                sx={{
                  "&.Mui-selected": {
                    color: "#000", // Cambia el color cuando el radio button está marcado
                  },
                }}
              >
                <TableContainer style={{ maxHeight: 500, overflowY: "auto" }}>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="">N°</TableCell>
                        <TableCell align="">Criterio</TableCell>
                        <TableCell align="">Si</TableCell>
                        <TableCell align="">No</TableCell>
                        <TableCell align="">Observaciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {el.element?.map((row, idx) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="">{idx + 1}</TableCell>
                          <TableCell align="">{row.description}</TableCell>
                          <TableCell align="">
                            <Radio
                              checked={
                                formikVanPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.answer === true
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "#000", // Cambia el color cuando el radio button está marcado
                                },
                              }}
                              onChange={(e) => {
                                formikVanPreUso.setFieldValue(
                                  `components[${lensize[index] + idx}].answer`,
                                  Boolean(e.target.value)
                                );
                                formikVanPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              value={true}
                              name={`components[${
                                lensize[index] + idx
                              }].question`}
                            />
                          </TableCell>
                          <TableCell align="">
                            <Radio
                              checked={
                                formikVanPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.answer === false
                              }
                              onChange={(e) => {
                                formikVanPreUso.setFieldValue(
                                  `components[${lensize[index] + idx}].answer`,
                                  Boolean(!e.target.value)
                                );
                                formikVanPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#000", // Cambia el color cuando el radio button está marcado
                                },
                              }}
                              value={false}
                              name={`components[${
                                lensize[index] + idx
                              }].question`}
                            />
                          </TableCell>
                          <TableCell align="">
                            <TextField
                              name={`components[${
                                lensize[index] + idx
                              }].observation`}
                              variant="outlined"
                              value={
                                formikVanPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.observation
                              }
                              onChange={(e) => {
                                formikVanPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].observation`,
                                  e.target.value
                                );
                                formikVanPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              error={
                                formikVanPreUso.touched.components?.[
                                  lensize[index] + idx
                                ]?.observation &&
                                Boolean(
                                  formikVanPreUso.errors.components?.[
                                    lensize[index] + idx
                                  ]?.observation
                                )
                              }
                              helperText={
                                formikVanPreUso.touched.components?.[
                                  lensize[index] + idx
                                ]?.observation &&
                                formikVanPreUso.errors.components?.[
                                  lensize[index] + idx
                                ]?.observation
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </TabContext>
          {formikVanPreUso.touched.components &&
          formikVanPreUso.errors.components?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Verica los campos esten requeridos
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikVanPreUso.isSubmitting}
          >
            {formikVanPreUso.isSubmitting ? <RotatingLoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateVanPreUso;
