import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_PERSONAL_BY_ID = gql`
  query GetPersonalRequerimentById($getPersonalRequerimentByIdId: ID) {
    getPersonalRequerimentById(id: $getPersonalRequerimentByIdId) {
      id
      duration
      subject {
        description
      }
      remuneration
      ngoal
      incorporationdate
      state {
        description
      }
      area {
        description
      }
      term {
        description
      }
      workplace {
        description
      }
      position {
        description
      }
    }
  }
`;

export const UPDATE_ADMIN_PERSONAL_BY_ID = gql`
  mutation UpdateRequerimentPersonal(
    $updateRequerimentPersonalId: ID
    $type: Int
    $observation: String
  ) {
    updateRequerimentPersonal(id: $updateRequerimentPersonalId, type: $type, observation: $observation) {
      id
      incorporationdate
      ngoal
      duration
    }
  }
`;
