import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";
import Alerts from "components/Alerts";

import { Box, useTheme, useMediaQuery } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  GET_DOCUMENT_VEHICLES_STATES,
  GET_LIST_DOCUMENT_VEHICLES,
} from "./QueryDocument";

const StateDocument = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();

  const [documentPersonal, setDocumentPersonal] = useState(null);
  const [documentListPersonal, setDocumentListPersonal] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  useEffect(() => {   

    const FUNCTION_GET_DOCUMENT_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_VEHICLES_STATES,
          fetchPolicy: "network-only",
        });
        setDocumentPersonal(data.getDocumentVehicles);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_DOCUMENT_LIST_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_LIST_DOCUMENT_VEHICLES,
          fetchPolicy: "network-only",
        });
        setDocumentListPersonal(data.getDocumentListVehicles);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_DOCUMENT_LIST_PERSONAL();
    FUNCTION_GET_DOCUMENT_PERSONAL();
  }, []);

  return (
    <>
      {alertCustom?.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/documentos" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box p="1rem">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 900 }}>
                        Activo{" "}
                      </TableCell>
                      {documentListPersonal?.map((el) => (
                        <TableCell style={{ fontWeight: 900 }}>
                          {el.description}{" "}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentPersonal?.map((row) => (
                      <TableRow
                        key={row.active.description}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.active.description}
                        </TableCell>
                        {documentListPersonal?.map((el) => {
                          let idx = row.document.findIndex(
                            (item) => item.document.id == el.id
                          );
                          return idx !== -1 ? (
                            <TableCell>
                              <Box
                                borderRadius={"50%"}
                                width={"30px"}
                                height={"30px"}
                                backgroundColor={
                                  row.document[idx].state.description ==
                                  "VIGENTE"
                                    ? "#28a745"
                                    : row.document[idx].state.description ==
                                      "POR ACTUALIZAR"
                                    ? "#ffc107"
                                    : row.document[idx].state.description ==
                                      "POR VENCER"
                                    ? "#dc3545"
                                    : "#000"
                                }
                              ></Box>
                            </TableCell>
                          ) : <TableCell>{""} </TableCell>;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StateDocument;
