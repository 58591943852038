import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { MuiFileInput } from "mui-file-input";
import { Box, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import { useFormik } from "formik";
import Alerts from "components/Alerts";
import { FormHelperText } from "@mui/material";
import { DOCUMENT_SCHEMA_YUP } from "./YupValidation";
import { CREATE_DOCUMENT, CREATE_TRAINING } from "./QueryDocument";
import SaveIcon from "@mui/icons-material/Save";
const CreateDocument = ({
  newDocument,
  setNewDocument,
  dataPersonal,
  alertCustom,
  setAlertCustom,
  id,
  dataTraining,
}) => {
  const [filesPersonal, setFilesPersonal] = useState(
    dataPersonal.map(() => null)
  );
  const [filesTraining, setFilesTraining] = useState(
    dataTraining.map(() => null)
  );
  const FILE_SIZE = 1024 * 1024; // 2MB
  const [loading, setLoading] = useState(false);
  const [errorPersonal, setErrorPersonal] = useState(null);
  const [errorTraining, setErrorTraining] = useState(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE_ERROR_MESSAGE = "El archivo debe ser menor a 2MB";
  const FILE_FORMAT_ERROR_MESSAGE = "Formato de archivo no soportado";
  const handleFileChangePersonal = (newFile, index) => {
    setFilesPersonal((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = newFile;
      return newFiles;
    });
  };
  const handleFileChangeTraining = (newFile, index) => {
    setFilesTraining((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = newFile;
      return newFiles;
    });
  };
  const client = useApolloClient();
  const checkFile = (file) => {
   
    if(SUPPORTED_FORMATS.includes(file.type) && file.size < FILE_SIZE) {
      return true;
    }
    return false;
  };
  const handleSubmitPersonal = async (index, document, kind) => {
    if (filesPersonal[index]) {
      if (!checkFile(filesPersonal[index])) {
        setErrorPersonal(true);
        console.log("formato o peso superior a 2MB");
      } else {
        try {
         
          setErrorPersonal(false);
          setLoading(true);       
          const response = await client.mutate({
            mutation: kind == "PERSONAL" ? CREATE_DOCUMENT : CREATE_TRAINING,
            variables: {
              personal: id,
              document: document,
              filex: filesPersonal[index],
            },
          });        

          setFilesPersonal((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = null;
            return newFiles;
          });

          setNewDocument(
            kind == "PERSONAL"
              ? response.data.createPersonalDocument
              : response.data.createPersonalTraining
          );
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setLoading(false);
        }
      }
    } else {
      setErrorPersonal(true);
      console.log("no tiene filesPersonal");
    }
  };
  const handleSubmitTraining = async (index, document, kind) => {
    if (filesTraining[index]) {
      if (!checkFile(filesTraining[index])) {
        setErrorTraining(true);
        console.log("formato o peso superior a 2MB");
      } else {
        try {
          setErrorTraining(false);
          setLoading(true);
          const response = await client.mutate({
            mutation: kind == "PERSONAL" ? CREATE_DOCUMENT : CREATE_TRAINING,
            variables: {
              personal: id,
              document: document,
              filex: filesTraining[index],
            },
          });    

          setFilesTraining((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = null;
            return newFiles;
          });

          setNewDocument(
            kind == "PERSONAL"
              ? response.data.createPersonalDocument
              : response.data.createPersonalTraining
          );
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setLoading(false);
        }
      }
    } else {
      setErrorTraining(true);
      console.log("no tiene filesPersonal");
    }
  };
  return (
    <>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        <Box
          fontSize={"12px"}
          fontWeight={"700"}
          textAlign={"center"}
          py="20px"
        >
          INGRESAR DOCUMENTOS
        </Box>
        <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="">N°</TableCell>
                <TableCell align="">Documento</TableCell>
                <TableCell align="">Tipo</TableCell>
                <TableCell align="">Archivo</TableCell>
                <TableCell align=""></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPersonal.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="">{index + 1}</TableCell>
                  <TableCell align="">{row.description}</TableCell>
                  <TableCell align="">{row.kind}</TableCell>
                  <TableCell align="">
                    <MuiFileInput
                      value={filesPersonal[index]}
                      onChange={(newFile) =>
                      {
                        console.log(newFile)
                        return   handleFileChangePersonal(newFile, index)}
                      }
                    />
                  </TableCell>
                  <TableCell align="">
                    {!loading && (
                      <Button
                        onClick={() =>
                          handleSubmitPersonal(index, row.id, row.kind)
                        }
                        color="success"
                      >
                        <SaveIcon sx={{ cursor: "pointer" }} />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {errorPersonal && (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Verica los campos esten requeridos o el tamaño del archivo
            </p>
          </FormHelperText>
        )}
        <TableContainer
          style={{ maxHeight: 400, overflowY: "auto", marginTop: "50px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="">N°</TableCell>
                <TableCell align="">Documento</TableCell>
                <TableCell align="">Tipo</TableCell>
                <TableCell align="">Archivo</TableCell>
                <TableCell align=""></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTraining.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="">{index + 1}</TableCell>
                  <TableCell align="">{row.description}</TableCell>
                  <TableCell align="">{row.kind}</TableCell>
                  <TableCell align="">
                    <MuiFileInput
                      value={filesTraining[index]}
                      onChange={(newFile) =>
                        handleFileChangeTraining(newFile, index)
                      }
                    />
                  </TableCell>
                  <TableCell align="">
                    {!loading && (
                      <Button
                        onClick={() =>
                          handleSubmitTraining(index, row.id, row.kind)
                        }
                        color="success"
                      >
                        <SaveIcon sx={{ cursor: "pointer" }} />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {errorTraining && (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Verica los campos esten requeridos o el tamaño del archivo
            </p>
          </FormHelperText>
        )}
      </Box>{" "}
    </>
  );
};

export default CreateDocument;
