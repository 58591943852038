import { Navigate, useLocation } from "react-router-dom";
import { items } from "consts/allowedRoles";
import { useState } from "react";
import Loader from "./Loader";
import { useQuery } from "@apollo/client";
import { GET_USER_DATA } from "graphql/UserQueries";

const Protected = ({ children }) => {

  const token = localStorage.getItem("userToken")
  const location = useLocation()
  // const [routes, setRoutes] = useState([])
  const currentRoute = location.pathname.slice(1)

  const [temp, setTemp] = useState([])

  const { loading } = useQuery(GET_USER_DATA, {

    onCompleted: (data) => {
      const IdRoutes = data.getUserByToken.submodules
      const allRoutes = items.map(module =>
        module.subItems.map(submodule =>
          IdRoutes.includes(submodule.id) ? submodule.url : null
        )
      ).flat().filter(url => url != null)
      setTemp(allRoutes)
    }
  })
/*   console.log(token)
  console.log(temp) */
  if (!token) return <Navigate to='/' replace />

  if (loading) return <Loader />

  if (!temp.includes(currentRoute)) return <Navigate to={`/${temp[0]}`} replace />

/*   console.log(children) */
  return (
    <>
      {children}
    </>
  )
}

export default Protected