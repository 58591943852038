import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import TableRow from "@mui/material/TableRow";
import { GET_VAN_BY_ID } from "./QueryVan";
const ViewVan = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [van, setVan] = useState([]);
  useEffect(() => {
    const getVan = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_VAN_BY_ID,
          variables: {
            getPreUseVanByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getPreUseVanById);
        setVan(data.getPreUseVanById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getVan(id);
  }, []);

  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">ACTIVIDAD</label>
            <TextField disabled value={van?.van?.activity} />
          </Box>

          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField
              disabled
              value={new Date(van?.van?.date).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">HORA</label>
            <TextField
              disabled
              value={new Date(van?.van?.time).toLocaleTimeString("es-ES")}
            />
          </Box>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 200, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">CRITERIO</TableCell>
                  <TableCell align="">RESPUESTA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {van.declaration.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row?.question?.description}
                      </TableCell>
                      <TableCell align="">
                        {row?.answer ? "SI" : "NO"}
                      </TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 250, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">CRITERIO</TableCell>
                  <TableCell align="">RESPUESTA</TableCell>
                  <TableCell align="">OBSERVACIÓN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {van.question.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">
                        {row?.question?.description}
                      </TableCell>
                      <TableCell align="">
                        {row?.answer ? "SI" : "NO"}
                      </TableCell>
                      <TableCell align="">{row?.observation}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewVan;
