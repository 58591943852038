import { gql } from "@apollo/client";

export const GET_MAIN_DATA = gql`
  query GetSumarizeDriver {
    getSumarizeDriver {
      amount
      lastname
      lastsurname
    }
  }
`;

export const GET_DRIVER_BY_ID = gql`
  query GetDriverByID($getDriverByIdId: ID) {
    getDriverByID(id: $getDriverByIdId) {
      id
      postulant {
        email
        doi
        name
        surname
        phone
        dob
        id
      }
    }
  }
`;
