import { gql } from "@apollo/client";

export const GET_ALL_PERSONAL = gql`
  query GetAllPersonal {
    getAllPersonal {
      postulant {
        surname
        name
        doi
      }
      id
    }
  }
`;

export const GET_ALL_DEPARTAMENT = gql`
  query GetAllDepartaments {
    getAllDepartaments {
      id
      description: nombre_ubigeo
      id_ubigeo
    }
  }
`;

export const GET_ALL_PROVINCE = gql`
  query GetProvinceByDepartamentId($departament: ID) {
    getProvinceByDepartamentId(departament: $departament) {
      id
      id_ubigeo
      description: nombre_ubigeo
    }
  }
`;

export const GET_ALL_DISTRICIT = gql`
  query GetDistrictByProvinceId($province: ID) {
    getDistrictByProvinceId(province: $province) {
      id
      description: nombre_ubigeo
    }
  }
`;

export const GET_ALL_CIVIL_STATUS = gql`
  query GetCivilStatus {
    getCivilStatus {
      id
      description
    }
  }
`;

export const GET_ALL_GENDER = gql`
  query GetGender {
    getGender {
      id
      description
    }
  }
`;

export const GET_ALL_CONTEXTURE = gql`
  query GetContexture {
    getContexture {
      id
      description
    }
  }
`;

export const GET_ALL_PROFESSION_LEVEL = gql`
  query GetProfessionLevel {
    getProfessionLevel {
      id
      description
    }
  }
`;

export const GET_ALL_PROFESSION_STATE = gql`
  query GetProfessionState {
    getProfessionState {
      id
      description
    }
  }
`;

export const GET_ALL_PENSION_FUNDS = gql`
  query GetPensionFund {
    getPensionFund {
      id
      description
    }
  }
`;
export const GET_ALL_PENSION_TYPE = gql`
  query GetPensionType {
    getPensionType {
      id
      description
    }
  }
`;

export const GET_ALL_LANGUAGE = gql`
  query GetLanguage {
    getLanguage {
      id
      description
    }
  }
`;

export const GET_ALL_LANGUAGE_LEVEL = gql`
  query GetLanguageLevel {
    getLanguageLevel {
      id
      description
    }
  }
`;

export const GET_ALL_CONTACT_TYPE = gql`
  query GetContactTypes {
    getContactTypes {
      id
      description
    }
  }
`;

export const GET_ALL_MOVILITY_TYPE = gql`
  query GetMovilityTypes {
    getMovilityTypes {
      id
      description
    }
  }
`;

export const GET_ALL_SENTENCE_POLICE_RECORD = gql`
  query GetSentencePoliceRecord {
    getSentencePoliceRecord {
      id
      description
    }
  }
`;

export const GET_FICHA_PERSONAL = gql`
  query GetFichaPersonal($getFichaPersonalId: ID) {
    getFichaPersonal(id: $getFichaPersonalId) {
      personal
      value
      type
      category
      description
    }
  }
`;

export const CREATE_PERSONAL = gql`
  mutation CreatePersonal(
    $contexture: ID
    $stature: Float
    $gender: ID
    $civilstatus: ID
    $district: ID
    $province: ID
    $departament: ID
    $postulant: ID
    $address: String
  ) {
    createPersonal(
      contexture: $contexture
      stature: $stature
      gender: $gender
      civilstatus: $civilstatus
      district: $district
      province: $province
      departament: $departament
      postulant: $postulant
      address: $address
    ) {
      id
    }
  }
`;
export const CREATE_SPOUSE = gql`
  mutation CreateSpouse(
    $phone: String
    $address: String
    $workplace: String
    $occupation: String
    $professionstate: ID
    $professionlevel: ID
    $profession: String
    $ruc: String
    $doi: Int
    $district: ID
    $province: ID
    $departament: ID
    $dob: Date
    $surname: String
    $name: String
    $personal: ID
  ) {
    createSpouse(
      phone: $phone
      address: $address
      workplace: $workplace
      occupation: $occupation
      professionstate: $professionstate
      professionlevel: $professionlevel
      profession: $profession
      ruc: $ruc
      doi: $doi
      district: $district
      province: $province
      departament: $departament
      dob: $dob
      surname: $surname
      name: $name
      personal: $personal
    ) {
      id
      name
      surname
    }
  }
`;

export const CREATE_PARENTS = gql`
  mutation CreateParent(
    $momphone: String
    $momaddress: String
    $momworkplace: String
    $momoccupation: String
    $momsurname: String
    $momname: String
    $dadphone: String
    $dadaddress: String
    $dadworkplace: String
    $dadoccupation: String
    $dadsurname: String
    $dadname: String
    $personal: ID
  ) {
    createParent(
      momphone: $momphone
      momaddress: $momaddress
      momworkplace: $momworkplace
      momoccupation: $momoccupation
      momsurname: $momsurname
      momname: $momname
      dadphone: $dadphone
      dadaddress: $dadaddress
      dadworkplace: $dadworkplace
      dadoccupation: $dadoccupation
      dadsurname: $dadsurname
      dadname: $dadname
      personal: $personal
    ) {
      id
      dadsurname
      dadname
      momname
      momsurname
    }
  }
`;

export const CREATE_EXTRA_DATA = gql`
  mutation CreateExtra(
    $pensionfund: ID
    $date: Date
    $type: ID
    $cusspp: String
    $ess: String
    $ruc: String
    $personal: ID
  ) {
    createExtra(
      pensionfund: $pensionfund
      date: $date
      type: $type
      cusspp: $cusspp
      ess: $ess
      ruc: $ruc
      personal: $personal
    ) {
      id
      ruc
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $phone: String
    $relation: ID
    $surname: String
    $name: String
    $personal: ID
  ) {
    createContact(
      phone: $phone
      relation: $relation
      surname: $surname
      name: $name
      personal: $personal
    ) {
      id
      name
      surname
    }
  }
`;

export const CREATE_MOVILITY = gql`
  mutation CreateMovility(
    $plate: String
    $year: String
    $brand: String
    $type: ID
    $licence: String
    $own: Boolean
    $personal: ID
  ) {
    createMovility(
      plate: $plate
      year: $year
      brand: $brand
      type: $type
      licence: $licence
      own: $own
      personal: $personal
    ) {
      id
      brand
      own
      plate
      year
    }
  }
`;

export const CREATE_POLICE_RECORD = gql`
  mutation CreatePoliceRecord(
    $personal: ID
    $have: Boolean
    $policerecordsentence: [policerecordsentenceinput]
  ) {
    createPoliceRecord(
      personal: $personal
      have: $have
      policerecordsentence: $policerecordsentence
    ) {
      id
      have
    }
  }
`;

export const CREATE_PROFESSION = gql`
  mutation CreateProfessions(
    $place: String
    $state: ID
    $level: ID
    $description: String
    $personal: ID
  ) {
    createProfessions(
      place: $place
      state: $state
      level: $level
      description: $description
      personal: $personal
    ) {
      id
      description
    }
  }
`;

export const CREATE_IDIOMS = gql`
  mutation CreateIdioms($level: ID, $language: ID, $personal: ID) {
    createIdioms(level: $level, language: $language, personal: $personal) {
      id
    }
  }
`;

export const CREATE_CHILDREN = gql`
  mutation CreateChildren(
    $personal: ID
    $name: String
    $surname: String
    $dob: Date
    $gender: ID
    $doi: Int
  ) {
    createChildren(
      personal: $personal
      name: $name
      surname: $surname
      dob: $dob
      gender: $gender
      doi: $doi
    ) {
      id
      name
      surname
    }
  }
`;

export const GET_PERSONAL_BY_ID = gql`
  query GetPersonalByID($getPersonalByIdId: ID) {
    getPersonalByID(id: $getPersonalByIdId) {
      postulant {
        name
        surname
        doi
        phone
        dob
        email
        state {
          description
        }
      }
      departament {
        id
        description:nombre_ubigeo 
      }
      province {
        id
        description:nombre_ubigeo 
      }
      district {
        id
        description:nombre_ubigeo 
      }
      gender {
        id
        description
      }
      stature
      mon
      dad
      contexture {
        id
        description
      }
      civilstatus {
        id
        description
      }
      address
    }
  }
`;

export const GET_SPOUSE_BY_PERSONAL_ID = gql`
  query GetSpouseByPersonalId($getSpouseByPersonalIdId: ID) {
    getSpouseByPersonalId(id: $getSpouseByPersonalIdId) {
      ruc
      surname
      name
      workplace
      phone
      profession
      occupation
      doi
      dob
      address
      province {
        id
        nombre_ubigeo
      }
      district {
        id
        nombre_ubigeo
      }
      departament {
        id
        nombre_ubigeo
      }
      professionstate {
        id
        description
      }
      professionlevel {
        id
        description
      }
    }
  }
`;
export const GET_PARENT_BY_PERSONAL_ID = gql`
  query GetParentByPersonalId($getParentByPersonalIdId: ID) {
    getParentByPersonalId(id: $getParentByPersonalIdId) {
      dadaddress
      dadname
      dadoccupation
      dadphone
      dadsurname
      dadworkplace
      momaddress
      momname
      momoccupation
      momphone
      momsurname
      momworkplace
    }
  }
`;

export const GET_EXTRA_BY_PERSONAL_ID = gql`
  query GetExtraByPersonalId($getExtraByPersonalIdId: ID) {
    getExtraByPersonalId(id: $getExtraByPersonalIdId) {
      cusspp
      date
      ess
      pensionfund {
        id
        description
      }
      ruc
      type {
        id
        description
      }
    }
  }
`;

export const GET_CONTACT_BY_PERSONAL_ID = gql`
  query GetContactByPersonalId($getContactByPersonalIdId: ID) {
    getContactByPersonalId(id: $getContactByPersonalIdId) {
      name
      phone
      relation {
        id
        description
      }
      surname
    }
  }
`;

export const GET_MOVILITY_BY_PERSONAL_ID = gql`
  query GetMovilityByPersonalId($getMovilityByPersonalIdId: ID) {
    getMovilityByPersonalId(id: $getMovilityByPersonalIdId) {
      brand
      licence
      own
      plate
      type {
        id
        description
      }
      year
    }
  }
`;

export const GET_POLICYRECORD_BY_PERSONAL_ID = gql`
  query GetPolicyRecordByPersonalId($getPolicyRecordByPersonalIdId: ID) {
    getPolicyRecordByPersonalId(id: $getPolicyRecordByPersonalIdId) {
      have
      policerecordsentence {
        answer
        sentence {
          id
          description
        }
      }
    }
  }
`;

export const GET_CHILDREN_BY_PERSONAL_ID = gql`
  query GetChildrenByPersonalId($getChildrenByPersonalIdId: ID) {
    getChildrenByPersonalId(id: $getChildrenByPersonalIdId) {
      id
      dob
      doi
      gender {
        description
      }
      name
      surname
    }
  }
`;

export const GET_PROFESSION_BY_PERSONAL_ID = gql`
  query GetProfessionByPersonalId($getProfessionByPersonalIdId: ID) {
    getProfessionByPersonalId(id: $getProfessionByPersonalIdId) {
      id
      description
      place
      level {
        description
      }
      state {
        description
      }
    }
  }
`;

export const GET_LANGUAGE_BY_PERSONAL_ID = gql`
  query GetLanguageByPersonalId($getLanguageByPersonalIdId: ID) {
    getLanguageByPersonalId(id: $getLanguageByPersonalIdId) {
      id
      language {
        description
      }
      level {
        description
      }
    }
  }
`;

export const EDIT_SPOUSE_BY_ID = gql`
  mutation EditSpouseById(
    $editSpouseByIdId: ID
    $name: String
    $surname: String
    $dob: Date
    $departament: ID
    $province: ID
    $district: ID
    $ruc: String
    $doi: Int
    $profession: String
    $professionlevel: ID
    $professionstate: ID
    $occupation: String
    $workplace: String
    $address: String
    $phone: String
  ) {
    editSpouseById(
      id: $editSpouseByIdId
      name: $name
      surname: $surname
      dob: $dob
      departament: $departament
      province: $province
      district: $district
      ruc: $ruc
      doi: $doi
      profession: $profession
      professionlevel: $professionlevel
      professionstate: $professionstate
      occupation: $occupation
      workplace: $workplace
      address: $address
      phone: $phone
    ) {
      id
    }
  }
`;

export const EDIT_EXTRA_DATA_BY_ID = gql`
  mutation EditExtraDataByIdPersonal(
    $editExtraDataByIdPersonalId: ID
    $ruc: String
    $ess: String
    $cusspp: String
    $type: ID
    $date: Date
    $pensionfund: ID
  ) {
    editExtraDataByIdPersonal(
      id: $editExtraDataByIdPersonalId
      ruc: $ruc
      ess: $ess
      cusspp: $cusspp
      type: $type
      date: $date
      pensionfund: $pensionfund
    ) {
      id
      ruc
    }
  }
`;
export const EDIT_PARENTS_BY_ID = gql`
  mutation EditParentsByIdPersonal(
    $editParentsByIdPersonalId: ID
    $dadname: String
    $dadsurname: String
    $dadoccupation: String
    $dadworkplace: String
    $dadaddress: String
    $dadphone: String
    $momname: String
    $momsurname: String
    $momoccupation: String
    $momworkplace: String
    $momaddress: String
    $momphone: String
  ) {
    editParentsByIdPersonal(
      id: $editParentsByIdPersonalId
      dadname: $dadname
      dadsurname: $dadsurname
      dadoccupation: $dadoccupation
      dadworkplace: $dadworkplace
      dadaddress: $dadaddress
      dadphone: $dadphone
      momname: $momname
      momsurname: $momsurname
      momoccupation: $momoccupation
      momworkplace: $momworkplace
      momaddress: $momaddress
      momphone: $momphone
    ) {
      id
    }
  }
`;

export const EDIT_MOVILITY_BY_ID = gql`
  mutation EditMovilityByIdPersonal(
    $editMovilityByIdPersonalId: ID
    $own: Boolean
    $licence: String
    $type: ID
    $brand: String
    $year: String
    $plate: String
  ) {
    editMovilityByIdPersonal(
      id: $editMovilityByIdPersonalId
      own: $own
      licence: $licence
      type: $type
      brand: $brand
      year: $year
      plate: $plate
    ) {
      id
      brand
    }
  }
`;

export const EDIT_CONTACT_BY_ID = gql`
  mutation EditContactByIdPersonal(
    $editContactByIdPersonalId: ID
    $name: String
    $surname: String
    $relation: ID
    $phone: String
  ) {
    editContactByIdPersonal(
      id: $editContactByIdPersonalId
      name: $name
      surname: $surname
      relation: $relation
      phone: $phone
    ) {
      id
      name
    }
  }
`;

export const EDIT_POLICE_RECORD_BY_ID = gql`
  mutation EditPoliceRecordByPersonalId(
    $editPoliceRecordByPersonalIdId: ID
    $have: Boolean
    $policerecordsentence: [policerecordsentenceinput]
  ) {
    editPoliceRecordByPersonalId(
      id: $editPoliceRecordByPersonalIdId
      have: $have
      policerecordsentence: $policerecordsentence
    ) {
      have
      id
    }
  }
`;
/**FIXED */
export const GET_CHILDREN_BY_ID = gql`
  query GetChildrenById($getChildrenByIdId: ID) {
    getChildrenById(id: $getChildrenByIdId) {
      dob
      doi
      gender {
        description
        id
      }
      id
      name
      surname
    }
  }
`;

export const GET_PROFESSION_BY_ID = gql`
  query GetProfessionById($getProfessionByIdId: ID) {
    getProfessionById(id: $getProfessionByIdId) {
      id
      description
      level {
        description
        id
      }
      place
      state {
        description
        id
      }
    }
  }
`;

export const GET_LANGUAGE_BY_ID = gql`
  query GetLanguageById($getLanguageByIdId: ID) {
    getLanguageById(id: $getLanguageByIdId) {
      id
      language {
        description
        id
      }
      level {
        id
        description
      }
    }
  }
`;

export const EDIT_PROFESSION_BY_ID = gql`
  mutation EditProfessionById(
    $editProfessionByIdId: ID
    $personal: ID
    $description: String
    $level: ID
    $state: ID
    $place: String
  ) {
    editProfessionById(
      id: $editProfessionByIdId
      personal: $personal
      description: $description
      level: $level
      state: $state
      place: $place
    ) {
      description
      id
    }
  }
`;

export const EDIT_IDIOMS_BY_ID = gql`
  mutation EditIdiomsById(
    $editIdiomsByIdId: ID
    $personal: ID
    $language: ID
    $level: ID
  ) {
    editIdiomsById(
      id: $editIdiomsByIdId
      personal: $personal
      language: $language
      level: $level
    ) {
      id
    }
  }
`;

export const EDIT_CHILDREN_BY_ID = gql`
  mutation EditChildrenById(
    $editChildrenByIdId: ID
    $personal: ID
    $name: String
    $surname: String
    $dob: Date
    $gender: ID
    $doi: Int
  ) {
    editChildrenById(
      id: $editChildrenByIdId
      personal: $personal
      name: $name
      surname: $surname
      dob: $dob
      gender: $gender
      doi: $doi
    ) {
      id
      name
      surname
    }
  }
`;
