import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
  query {
    getSettings {
      currency
      dateFormat
      rounding
    }
  }
`