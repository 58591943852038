import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoopIcon from "@mui/icons-material/Loop";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { CREATE_JOBREFERENCE_MUTATION } from "./QueryJobReference";
import { JOBREFERENCE_SCHEMA_YUP } from "./YupValidation";
const CreateJobReference = ({
  setnewJobReference,
  bossPosition,
  criterion,
  setErrorId,
  id,
  setAlertCustom,
  alertCustom,
}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const today = new Date();
  const client = useApolloClient();

  const formikJobReference = useFormik({
    initialValues: {
      name: "",
      surname: "",
      bossposition: "",
      phone: "",
      company: "",
      recomended: true,
      jobReference: [
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
        {
          answer: "",
          criterion: "",
        },
      ],
    },
    validationSchema: JOBREFERENCE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const {
          name,
          surname,
          bossposition,
          phone,
          company,
          recomended,
          jobReference,
        } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_JOBREFERENCE_MUTATION,
            variables: {
              name: name.trim().toUpperCase(),
              surname: surname.trim().toUpperCase(),
              bossposition: bossposition.trim().toUpperCase(),
              postulant: id,
              phone: phone,
              company: company.trim().toUpperCase(),
              recomended: recomended === "true",
              jobReference: jobReference.map(
                (it) =>
                  new Object({
                    criterion: it.criterion,
                    answer: it.answer.toUpperCase(),
                  })
              ),
            },
          });

          setnewJobReference(response.data);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikJobReference.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="20px">
          <TextField
            id="name"
            name="name"
            label="NOMBRES"
            variant="outlined"
            value={formikJobReference.values.name}
            onChange={formikJobReference.handleChange}
            error={
              formikJobReference.touched.name &&
              Boolean(formikJobReference.errors.name)
            }
            helperText={
              formikJobReference.touched.name && formikJobReference.errors.name
            }
          />
          <TextField
            id="surname"
            name="surname"
            label="APELLIDOS"
            variant="outlined"
            value={formikJobReference.values.surname}
            onChange={formikJobReference.handleChange}
            error={
              formikJobReference.touched.surname &&
              Boolean(formikJobReference.errors.surname)
            }
            helperText={
              formikJobReference.touched.surname &&
              formikJobReference.errors.surname
            }
          />
          <TextField
            id="company"
            name="company"
            label="COMPAÑIA"
            variant="outlined"
            value={formikJobReference.values.company}
            onChange={formikJobReference.handleChange}
            error={
              formikJobReference.touched.company &&
              Boolean(formikJobReference.errors.company)
            }
            helperText={
              formikJobReference.touched.company &&
              formikJobReference.errors.company
            }
          />
          <TextField
            id="phone"
            name="phone"
            label="TELEFONO"
            variant="outlined"
            value={formikJobReference.values.phone}
            onChange={formikJobReference.handleChange}
            error={
              formikJobReference.touched.phone &&
              Boolean(formikJobReference.errors.phone)
            }
            helperText={
              formikJobReference.touched.phone &&
              formikJobReference.errors.phone
            }
          />
          <TextField
            id="bossposition"
            name="bossposition"
            label="CARGO"
            variant="outlined"
            value={formikJobReference.values.bossposition}
            onChange={formikJobReference.handleChange}
            error={
              formikJobReference.touched.bossposition &&
              Boolean(formikJobReference.errors.bossposition)
            }
            helperText={
              formikJobReference.touched.bossposition &&
              formikJobReference.errors.bossposition
            }
          />
          {/* <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">CARGO</InputLabel>
              <Select
                id="bossposition"
                value={formikJobReference.values.bossposition}
                name="bossposition"
                onChange={formikJobReference.handleChange}
                error={
                  formikJobReference.touched.bossposition &&
                  Boolean(formikJobReference.errors.bossposition)
                }
                label="CARGO"
              >
                {bossPosition?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikJobReference.touched.bossposition &&
              formikJobReference.errors.bossposition ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikJobReference.touched.bossposition &&
                    formikJobReference.errors.bossposition}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box> */}
          <Box>
            <FormControl>
              LO RECOMIENDA
              <RadioGroup
                row
                name={"recomended"} // ajusta el nombre del campo para que coincida con la estructura de tu objeto
                value={formikJobReference.values.recomended} // establece el valor de acuerdo con la estructura de tu objeto
                onChange={formikJobReference.handleChange}

                /*  error={
                  formikJobReference.touched.recomended &&
                  Boolean(formikJobReference.errors.recomended)
                } */
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#000", // Cambia el color cuando el radio button está marcado
                        },
                      }}
                    />
                  }
                  label="SI"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#000", // Cambia el color cuando el radio button está marcado
                        },
                      }}
                    />
                  }
                  label="NO"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Respuesta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {criterion.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <TextField
                        name={`jobReference[${index}].answer`}
                        variant="outlined"
                        value={
                          formikJobReference.values.jobReference?.[index]
                            ?.answer
                        }
                        onChange={(e) => {
                          formikJobReference.setFieldValue(
                            `jobReference[${index}].answer`,
                            e.target.value
                          );
                          formikJobReference.setFieldValue(
                            `jobReference[${index}].criterion`,
                            row.id
                          );
                        }}
                        error={
                          formikJobReference.touched.jobReference?.[index]
                            ?.answer &&
                          Boolean(
                            formikJobReference.errors.jobReference?.[index]
                              ?.answer
                          )
                        }
                        helperText={
                          formikJobReference.touched.jobReference?.[index]
                            ?.answer &&
                          formikJobReference.errors.jobReference?.[index]
                            ?.answer
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"30px"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikJobReference.isSubmitting}
          >
            {formikJobReference.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateJobReference;
