import * as Yup from "yup";
export const PERSONAL_SCHEMA_YUP = Yup.object().shape({
  postulant: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  departament: Yup.string().required("Requiredo"),
  province: Yup.string().required("Requiredo"),
  district: Yup.string().required("Requiredo"),
  address: Yup.string().min(10, "Too Short!").required("Requerido"),
  civilstatus: Yup.string().required("Requiredo"),
  gender: Yup.string().required("Requiredo"),
  stature: Yup.number()
    .positive("El número debe ser positivo")
    .min(1.0, "El número debe ser mayor a 1")
    .max(3.0, "El numero debe ser menor que 3"),
  contexture: Yup.string(),
});

export const PERSONAL_EDIT_SCHEMA_YUP = Yup.object().shape({
  name: Yup.string()
    .min(3, "La longitud del campo es muy corto")
    .required("Requerido"),
  surname: Yup.string()
    .min(3, "La longitud del campo es muy corto")
    .required("Requerido"),
    doi: Yup.string()
    .min(8, "La longitud del campo es muy corto")
    .max(8, "La longitud del campo es muy corto")
    .required("Requerido"),  
  dob: Yup.date().required("Requerido"),
  email: Yup.string().email("Formato inválido"),
  phone: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .max(999999999, "El número debe tener exactamente 9 dígitos"),
  departament: Yup.string().required("Requiredo"),
  province: Yup.string().required("Requiredo"),
  district: Yup.string().required("Requiredo"),
  address: Yup.string().min(10, "Too Short!").required("Requerido"),
  civilstatus: Yup.string().required("Requiredo"),
  gender: Yup.string().required("Requiredo"),
  stature: Yup.number()
    .positive("El número debe ser positivo")
    .min(1.0, "El número debe ser mayor a 1")
    .max(3.0, "El numero debe ser menor que 3"),
  contexture: Yup.string(),
});

export const SPOUSE_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  name: Yup.string().required("Requiredo"),
  surname: Yup.string(),
  dob: Yup.date(),
  departament: Yup.string(),
  province: Yup.string(),
  district: Yup.string(),
  doi: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000, "El número debe tener exactamente 8 dígitos")
    .max(99999999, "El número debe tener exactamente 8 dígitos"),
  ruc: Yup.string(),
  profession: Yup.string(),
  professionlevel: Yup.string(),
  professionstate: Yup.string(),
  occupation: Yup.string(),
  workplace: Yup.string(),
  address: Yup.string(),
  phone: Yup.string(),
});

export const PARENTS_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  dadname: Yup.string(),
  dadsurname: Yup.string(),
  dadoccupation: Yup.string(),
  dadworkplace: Yup.string(),
  dadaddress: Yup.string(),
  dadphone: Yup.string(),
  momname: Yup.string(),
  momsurname: Yup.string(),
  momoccupation: Yup.string(),
  momworkplace: Yup.string(),
  momaddress: Yup.string(),
  momphone: Yup.string(),
});

export const EXTRA_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  ruc: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000000, "El número debe tener exactamente 11 dígitos")
    .max(99999999999, "El número debe tener exactamente 11 dígitos"),
  ess: Yup.string(),
  cusspp: Yup.string(),
  type: Yup.string().nullable(),
  date: Yup.date(),
  pensionfund: Yup.string().required("Requiredo"),
});

export const EXTRA_SCHEMA2_YUP = Yup.object().shape({
  personal: Yup.string(),
  ruc: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000000, "El número debe tener exactamente 11 dígitos")
    .max(99999999999, "El número debe tener exactamente 11 dígitos"),
  ess: Yup.string().required("Requiredo"),
  cusspp: Yup.string().required("Requiredo"),
  type: Yup.object().shape({
    id: Yup.string().required("Requiredo"),
    description: Yup.string(),
  }),
  date: Yup.date().required("Requiredo"),
  pensionfund: Yup.object().shape({
    id: Yup.string().required("Requiredo"),
    description: Yup.string(),
  }),
});
export const PROFESION_YUP = Yup.object().shape({
  personal: Yup.string(),
  description: Yup.string(),
  level: Yup.string().required("Requiredo"),
  state: Yup.string().nullable(),
  place: Yup.string(),
});

export const LANGUAGE_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  language: Yup.string().required("Requiredo"),
  level: Yup.string().nullable(),
});

export const CHILDREN_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  name: Yup.string().required("Requiredo"),
  surname: Yup.string().required("Requiredo"),
  dob: Yup.date().required("Requiredo"),
  gender: Yup.string().required("Requiredo"),
  doi: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000, "El número debe tener exactamente 8 dígitos")
    .max(99999999, "El número debe tener exactamente 8 dígitos")
    .required("Requiredo"),
});

export const CONTACTTYPE_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  name: Yup.string().required("Requiredo"),
  surname: Yup.string().required("Requiredo"),
  relation: Yup.string().required("Requiredo"),
  phone: Yup.string().required("Requiredo"),
});

export const MOVILITY_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  own: Yup.boolean("Select this checkbox a please").required("Requiredo"),
  type: Yup.string(),
  licence: Yup.string(),
  brand: Yup.string(),
  year: Yup.string(),
  plate: Yup.string(),
});

export const POLICE_RECORD_SCHEMA_YUP = Yup.object().shape({
  personal: Yup.string(),
  have: Yup.boolean("Select this checkbox a please").required("Requiredo"),
  policerecordsentence: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string(),
        sentence: Yup.string(),
      })
    )
    .required("Required"),
});
