import React from "react";
import { useState, useEffect } from "react";
import { GET_SELECTION_BY_ID } from "./QueryEvaluationSelection";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const ViewSelecion = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [selection, setselection] = useState({});
  let sum1 = 0;
  let sum2 = 0;
  let sum3 = 0;
  useEffect(() => {
    const getSelectionById = async () => {
      try {
        const { data } = await client.query({
          query: GET_SELECTION_BY_ID,
          variables: {
            getSelectionByIdId: id,
          },
        });
        console.log(data.getSelectionById);
        setselection(data.getSelectionById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSelectionById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    visibility ? (
      <>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">PROVEEDOR </label>
            <TextField disabled value={selection?.supplier.businessname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">SCORE</label>
            <TextField disabled value={selection?.score} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={selection?.type} />
          </Box>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align=""> N°</TableCell>
                  <TableCell align="">CRITERIO</TableCell>
                  {selection.questions[0].map((row) => (
                    <TableCell align=""> {row.supplier.businessname}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selection.questions.map((row, index) => {
                  sum1 += row[0]?.score;
                  sum2 += row[1]?.score;
                  sum3 += row[2]?.score;
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row[0]?.criterion.description}
                      </TableCell>
                      <TableCell align="">{row[0]?.score}</TableCell>
                      <TableCell align="">{row[1]?.score}</TableCell>
                      <TableCell align="">{row[2]?.score}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {" "}
                  <TableCell align=""> </TableCell>
                  <TableCell align=""> </TableCell>
                  <TableCell align="">
                    {(sum1 / selection.questions.length).toFixed(2)}{" "}
                  </TableCell>
                  <TableCell align="">
                    {(sum2 / selection.questions.length).toFixed(2)}{" "}
                  </TableCell>
                  <TableCell align="">
                    {(sum3 / selection.questions.length).toFixed(2)}{" "}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):(
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
    )
  );
};

export default ViewSelecion;
