import { Box, Button, Checkbox, Collapse, FormControl, IconButton, InputLabel, List, ListItemButton, MenuItem, Select, Typography, useTheme } from "@mui/material"
import { useFormik } from "formik"
import { items } from "consts/allowedRoles"
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ALL_USERS, UPDATE_USER } from "./QueryUsers";
import { USER_UPDATE_SCHEMA_YUP } from "./YupValidation";

const UserUpdate = ({ user, setUpdate }) => {

  const theme = useTheme()

  const [openList, setOpenList] = useState(Array(items.length).fill(false))
  const [checkList, setCheckList] = useState(
    Array(items.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.subItems.length
    }, 0))
      .fill(false)
      .map((_, idx) => user.submodules.includes(idx))
  )

  const [checkGroup, setCheckGroup] = useState(Array(items.length).fill(false))

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setUpdate(false)
    },
    refetchQueries: [
      {
        query: ALL_USERS
      }
    ]
  })


  const userFormik = useFormik({
    initialValues: {
      role: user.role,
      submodules: checkList
    },
    validationSchema: USER_UPDATE_SCHEMA_YUP,
    onSubmit: (values) => {

      const email = user.email
      const submodules = values.submodules.reduce((positions, val, index) => {
        if (val) positions.push(index)

        return positions
      }, [])

      updateUser({ variables: { ...values, submodules, email } })

    }
  })


  const handleCheckList = (id) => {
    const updatedCheckedList = checkList.map((item, i) => (i === id ? !item : item))

    userFormik.setFieldValue('submodules', updatedCheckedList)
    setCheckList(updatedCheckedList)
  }

  const handleAllGroup = (event, index) => {
    const InitialIndex = items[index].subItems[0].id
    const GroupSize = items[index].subItems.length

    const updatedCheckedList = checkList.map((item, i) => (i >= InitialIndex && i < InitialIndex + GroupSize ? true : item))

    setCheckGroup(checkGroup.map((group, i) => i === index ? !group : group))

    userFormik.setFieldValue('submodules', updatedCheckedList)
    setCheckList(updatedCheckedList)
  }

  const toggleArrayList = (index) => {
    const updatedOpenedList = openList.map((item, i) => (i === index ? !item : item))
    setOpenList(updatedOpenedList)
  }

  return (
    <Box
      component='form'
      onSubmit={userFormik.handleSubmit}
      border={1}
      display='flex'
      flexDirection='column'
      justifyContent='start'
      alignItems='center'
      gap={2}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: '90%',
        borderColor: theme.palette.secondary[300],
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography
        variant="h3"
        fontWeight='bold'
      >
        Actualizar Usuario
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"
          sx={{
            '&.Mui-focused, &:hover': {
              color: theme.palette.primary[500],
            },
          }}
        >
          ROL
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="role"
          name="role"
          value={userFormik.values.role}
          onChange={userFormik.handleChange}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary[500],
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary[500],
            },
          }}
        >
          <MenuItem value='ADMIN_GENERAL'>ADMIN. GENERAL</MenuItem>
          <MenuItem value='ADMIN_GESTION_DE_PERSONAS'>ADMIN. GESTION DE PERSONAS</MenuItem>
          <MenuItem value='ADMIN_LOGISTICA'>ADMIN. DE LOGISTICA</MenuItem>
          <MenuItem value='ADMIN_OPERACIONES'>ADMIN. DE OPERACIONES</MenuItem>
          <MenuItem value='ADMIN_SEGURIDAD'>ADMIN. DE SEGURIDAD</MenuItem>
          <MenuItem value='ADMIN_MANTENIMIENTO'>ADMIN. DE MANTENIMIENTO</MenuItem>
          <MenuItem value='ADMIN_REQUERIMIENTO'>ADMIN. DE REQUERIMIENTO</MenuItem>
          <MenuItem value='USUARIO_GENERICO'>USUARIO GENERICO</MenuItem>
        </Select>
      </FormControl>

      <Box
        border={1}
        width='100%'
        height='70%'
        sx={{
          borderColor: theme.palette.secondary[300],
          overflowY: 'scroll'
        }}
      >
        <List>
          {
            items.map((module, index) => {
              return (
                <Box key={module.title} width='100%'>
                  <Box display='flex' width='100%'>
                    <ListItemButton
                      onClick={() => toggleArrayList(index)}
                      sx={{
                        display: 'flex',
                        px: 0,
                        mb: 1,
                      }}
                    >
                      <Box display='flex' alignItems='center'>
                        {
                          openList[index] ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />
                        }
                        <Typography
                          variant="h6"
                        >
                          {module.title}
                        </Typography>
                      </Box>

                    </ListItemButton>
                    <IconButton aria-label="delete" onClick={(e) => handleAllGroup(e, index)}>
                      <DoneAllIcon />
                    </IconButton>
                  </Box>
                  <Collapse
                    in={openList[index]}
                    timeout='auto'
                    unmountOnExit
                    sx={{
                      padding: 0,
                    }}
                  >
                    <List disablePadding>
                      {
                        module.subItems.map(submodule => {
                          return (
                            <Box key={submodule.url} display='flex' width='100%'>
                              <ListItemButton
                                onClick={() => handleCheckList(submodule.id)}
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  pl: 5
                                }}
                              >
                                {submodule.title}
                              </ListItemButton>
                              <Checkbox
                                checked={checkList[submodule.id]}
                                onChange={() => handleCheckList(submodule.id)}
                                color="success"
                              />

                            </Box>
                          )
                        })
                      }

                    </List>

                  </Collapse>

                </Box>
              )
            })
          }
        </List>



      </Box>

      <Box display='flex' justifyContent='space-around' width='100%'>
        <Button onClick={() => setUpdate(false)} variant="contained" color="error" size="large">
          CANCELAR
        </Button>
        <Button variant="contained" color="success" type="submit" size="large">
          GUARGAR
        </Button>
      </Box>
    </Box>
  )
}

export default UserUpdate