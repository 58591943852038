import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useApolloClient } from "@apollo/client";

import ModalUtil from "components/ModalUtil";
import { GET_ITEM_REQUERIMENT } from "./../../logistic/requerimentItem/QueryItemRequeriment";
import AssignLogisticRequeriment from "./AssignLogisticRequeriment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GET_ALL_BUY_ORDER } from "./../../logistic/buyOrder/QueryItemRequeriment";
import AssignBuyOrder from "./AssignBuyOrder";
import { useLocation } from "react-router-dom";
import ViewRequeriment from "scenes/logistic/requerimentItem/ViewRequeriment";
import ViewBuyOrder from "scenes/logistic/buyOrder/ViewBuyOrder";
const Logistic = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenBuyOrder, setModalOpenBuyOrder] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [requeriment, setrequeriment] = useState([]);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  const [buyOrder, setbuyOrder] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenViewBuyOrder, setModalOpenViewBuyOrder] = useState(false);

  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModal = (id) => {
    setModalOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenBuyOrderModal = (id) => {
    setModalOpenBuyOrder(true);
    setrequerimentselected(id);
  };

  const handleCloseBuyOrderModal = () => {
    setModalOpenBuyOrder(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setrequerimentselected(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalBuyOrderView = (id) => {
    setModalOpenViewBuyOrder(true);
    setrequerimentselected(id);
  };

  const handleCloseModalBuyOrderView = () => {
    setModalOpenViewBuyOrder(false);
  };
  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {params.row.state.description == "PENDIENTE" ? (
            <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsBuyOrder = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalBuyOrderView(params.row.id)}
          />
          {params.row.state.description == "PENDIENTE" ? (
            <TouchAppIcon
              onClick={() => handleOpenBuyOrderModal(params.row.id)}
            />
          ) : null}
          {/* <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_PERSONAL_REQUERIMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ITEM_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            acro: location.pathname?.split("/")[1],
          },
        });
        console.log(data);
        setItemRequeriment(data.getItemRequeriment);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_BUY_ORDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_BUY_ORDER,
          fetchPolicy: "network-only",
        });
        setbuyOrder(data.getBuyOrder);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_GET_PERSONAL_REQUERIMENT();
    FUNCTION_GET_ALL_BUY_ORDER();
  }, [newAssignRequeriment]);

  return (
    <>
      {" "}
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={AssignLogisticRequeriment}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenBuyOrder}
        onClose={handleCloseBuyOrderModal}
        Component={AssignBuyOrder}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseBuyOrderModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewBuyOrder}
        onClose={handleCloseModalBuyOrderView}
        Component={ViewBuyOrder}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalBuyOrderView,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="ADMINISTRACION" subtitle="admin/logistica" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTOS
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              getRowId={(row) => row.id}
              rows={
                itemRequeriment?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE ORDENES DE COMPRA PENDIENTES
            </Box>
            <DataGrid
              loading={!buyOrder}
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsBuyOrder}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTOS INICIADOS / APROBADOS
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              getRowId={(row) => row.id}
              rows={
                itemRequeriment?.filter(
                  (row) =>
                    row.state.description === "INICIADO" ||
                    row.state.description === "APROBADO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE ORDENES DE COMPRA INICIADOS / APROBADOS
            </Box>
            <DataGrid
              loading={!buyOrder}
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) =>
                    row.state.description === "INICIADO" ||
                    row.state.description === "APROBADO"
                ) || []
              }
              columns={columnsBuyOrder}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE REQUERIMIENTOS RECHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              getRowId={(row) => row.id}
              rows={
                itemRequeriment?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE ORDENES DE COMPRA RECHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!buyOrder}
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsBuyOrder}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Logistic;
