import React from "react";
import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { GET_USER_BY_ID } from "./QueryUsers";
import { useApolloClient } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import { items } from "consts/allowedRoles";
import {
  Modal,
  Typography,
  Checkbox,
  ListItemButton,
  Collapse,
  List,
  IconButton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import LoopIcon from "@mui/icons-material/Loop";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import StructureModules from "./StructureModules";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { USER_SCHEMA_YUP } from "./YupValidation";
import { EDIT_USER_ROLE_BY_ID } from "./QueryUsers";
const EditRol = ({ id, onClose, params }) => {
  const { setAlertCustom, alertCustom, setNewRegister } = params;
  const [openList, setOpenList] = useState(Array(items.length).fill(false));
  const [checkList, setCheckList] = useState(
    Array(
      items.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.subItems.length;
      }, 0)
    ).fill(false)
  );

  const [checkGroup, setCheckGroup] = useState(Array(items.length).fill(false));
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();
  const [userData, setUserData] = useState();
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  const handleCheckList = (id) => {
    const updatedCheckedList = checkList.map((item, i) =>
      i === id ? !item : item
    );

    formik.setFieldValue("submodules", updatedCheckedList);
    setCheckList(updatedCheckedList);
  };
  const handleAllGroup = (event, index) => {
    const InitialIndex = items[index].subItems[0].id;
    const GroupSize = items[index].subItems.length;

    const updatedCheckedList = checkList.map((item, i) =>
      i >= InitialIndex && i < InitialIndex + GroupSize ? true : item
    );

    setCheckGroup(checkGroup.map((group, i) => (i === index ? !group : group)));

    formik.setFieldValue("submodules", updatedCheckedList);
    setCheckList(updatedCheckedList);
  };

  const toggleArrayList = (index) => {
    const updatedOpenedList = openList.map((item, i) =>
      i === index ? !item : item
    );
    setOpenList(updatedOpenedList);
  };

  useEffect(() => {
    const getRolUserId = async () => {
      try {
        const { data } = await client.query({
          query: GET_USER_BY_ID,
          variables: {
            getUserByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getUserById);
        setUserData(data.getUserById);
        formik.setValues({
          role: data.getUserById.role,
          submodules: data.getUserById.submodules,
          enabled: data.getUserById.enabled,
          admin: data.getUserById.admin,
        });
        setLoading(false);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getRolUserId();
  }, []);
  const formik = useFormik({
    initialValues: {
      role: "",
      submodules: [],
      enabled: "",
      admin: "",
    },
    validationSchema: USER_SCHEMA_YUP,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { role, submodules, enabled, admin } = values;
      try {
        console.log(values);
        const reducesubmodules = submodules.reduce((positions, val, index) => {
          if (val) positions.push(index);

          return positions;
        }, []);
        console.log("hey");
        console.log(reducesubmodules);
        const { data } = await client.mutate({
          mutation: EDIT_USER_ROLE_BY_ID,
          variables: {
            editUserRoleByIdId: id,
            role,
            submodules: reducesubmodules,
            enabled,
            admin,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.editUserRoleById);
        setNewRegister(data.editUserRoleById);

        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
        onClose();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      {!loading ? (
        <form onSubmit={formik.handleSubmit}>
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(1, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <FormControl fullWidth>
                <label for="my-input">ROL</label>
                <Select
                  id="role"
                  name="role"
                  value={formik.values.role}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="ADMIN_GENERAL">ADMIN. GENERAL</MenuItem>
                  <MenuItem value="ADMIN_GESTION_DE_PERSONAS">
                    ADMIN. GESTION DE PERSONAS
                  </MenuItem>
                  <MenuItem value="ADMIN_LOGISTICA">
                    ADMIN. DE LOGISTICA
                  </MenuItem>
                  <MenuItem value="ADMIN_OPERACIONES">
                    ADMIN. DE OPERACIONES
                  </MenuItem>
                  <MenuItem value="ADMIN_SEGURIDAD">
                    ADMIN. DE SEGURIDAD
                  </MenuItem>
                  <MenuItem value="ADMIN_MANTENIMIENTO">
                    ADMIN. DE MANTENIMIENTO
                  </MenuItem>
                  <MenuItem value="ADMIN_REQUERIMIENTO">
                    ADMIN. DE REQUERIMIENTO
                  </MenuItem>
                  <MenuItem value="USUARIO_GENERICO">USUARIO GENERICO</MenuItem>
                </Select>
                {formik.touched.role && formik.errors.role ? (
                  <FormHelperText
                    sx={{
                      color: "#bf3333",
                      marginLeft: "16px !important",
                    }}
                  >
                    {formik.touched.role && formik.errors.role}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Box>
            <Box sx={(style, { border: "2px solid rgba(0, 0, 0, 0.23)" })}>
              <List>
                {items.map((module, index) => {
                  return module.roles.includes(formik.values.role) ? (
                    <Box key={module.title} width="100%">
                      <Box display="flex" width="100%">
                        <ListItemButton
                          onClick={() => toggleArrayList(index)}
                          sx={{
                            display: "flex",
                            px: 0,
                            mb: 1,
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {openList[index] ? (
                              <IndeterminateCheckBoxOutlinedIcon />
                            ) : (
                              <AddBoxOutlinedIcon />
                            )}
                            <Typography variant="h6">{module.title}</Typography>
                          </Box>
                        </ListItemButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => handleAllGroup(e, index)}
                        >
                          <DoneAllIcon />
                        </IconButton>
                        {/* <Checkbox
                              value={checkGroup[index]}
                              onClick={(e) => handleAllGroup(e, index)}
                              color="success"
                            /> */}
                      </Box>
                      <Collapse
                        in={openList[index]}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                          padding: 0,
                        }}
                      >
                        <List disablePadding>
                          {module.subItems.map((submodule) => {
                            return (
                              <Box
                                key={submodule.url}
                                display="flex"
                                width="100%"
                              >
                                <ListItemButton
                                  onClick={() => handleCheckList(submodule.id)}
                                  sx={{
                                    margin: 0,
                                    padding: 0,
                                    pl: 5,
                                  }}
                                >
                                  {submodule.title}
                                </ListItemButton>
                                <Checkbox
                                  checked={checkList[submodule.id]}
                                  onChange={() => handleCheckList(submodule.id)}
                                  color="success"
                                />
                              </Box>
                            );
                          })}
                        </List>
                      </Collapse>
                    </Box>
                  ) : (
                    <></>
                  );
                })}
              </List>
            </Box>
            {formik.touched.submodules && formik.errors.submodules ? (
              <FormHelperText
                sx={{
                  color: "#bf3333",
                  marginLeft: "16px !important",
                }}
              >
                {formik.touched.submodules && formik.errors.submodules}
              </FormHelperText>
            ) : null}
            <Box sx={style}>
              <FormControl fullWidth>
                <label for="my-input">ADMINISTRADOR (Ideal solo para el Administrador General)</label>
                <Select
                  id="admin"
                  name="admin"
                  value={formik.values.admin}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={true}>SI</MenuItem>
                  <MenuItem value={false}>NO</MenuItem>
                </Select>
                {formik.touched.admin && formik.errors.admin ? (
                  <FormHelperText
                    sx={{
                      color: "#bf3333",
                      marginLeft: "16px !important",
                    }}
                  >
                    {formik.touched.admin && formik.errors.admin}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Box>
            <Box sx={style}>
              <FormControl fullWidth>
                <label for="my-input">HABILITAR</label>
                <Select
                  id="enabled"
                  name="enabled"
                  value={formik.values.enabled}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={true}>SI</MenuItem>
                  <MenuItem value={false}>NO</MenuItem>
                </Select>
                {formik.touched.enabled && formik.errors.enabled ? (
                  <FormHelperText
                    sx={{
                      color: "#bf3333",
                      marginLeft: "16px !important",
                    }}
                  >
                    {formik.touched.enabled && formik.errors.enabled}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"end"}
            marginTop={"30px"}
            gap={"15px"}
          >
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? <LoopIcon /> : "GUARDAR"}
            </Button>
            <Button variant="contained" onClick={onClose}>
              CERRAR
            </Button>
          </Box>
        </form>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoopIcon />
        </Box>
      )}
    </>
  );
};

export default EditRol;
