import { gql } from "@apollo/client";

export const GET_OTHER_TYPE_EXPENSE = gql`
  query GetTypeExpense {
    getTypeExpense {
      id
      description
    }
  }
`;

export const GET_TYPE_FUEL = gql`
  query GetTypeFuel {
    getTypeFuel {
      id
      description
    }
  }
`;

export const GET_ALL_FLUEL = gql`
  query GetAllFuel {
    getAllFuel {
      id
      date
      cost
      amount
      active {
        id
        item {
          active
        }
        description
      }
    }
  }
`;

export const GET_ALL_OTHER_EXPENSE = gql`
  query GetAllExpense {
    getAllExpense {
      id
      amount
      active {
        id
        description
      }
      cost
      name
    }
  }
`;

export const GET_ALL_EVALUATOR=gql`
query GetAllEvaluator {
  getAllEvaluator {
    id
    postulant {
      name
      surname
      doi
    }
  }
}`;
export const GET_ALL_DRIVER = gql`
  query GetAllDriver {
    getAllDriver {
      id
      postulant {
        name
        surname
        doi
      }
    }
  }
`;

export const GET_ALL_ACTIVE_VEHICLE = gql`
  query GetAllActiveVehicle {
    getAllActiveVehicle {
      description
      id
      plate
    }
  }
`;
export const CREATE_OTHER_EXPENSE = gql`
  mutation CreateOtherExpense(
    $total: Float
    $cost: Float
    $amount: Int
    $time: String
    $date: Date
    $reference: String
    $type: ID
    $name: String
    $driver: ID
    $active: ID
  ) {
    createOtherExpense(
      total: $total
      cost: $cost
      amount: $amount
      time: $time
      date: $date
      reference: $reference
      type: $type
      name: $name
      driver: $driver
      active: $active
    ) {
      id
      name
      time
      amount
      cost
      reference
    }
  }
`;

export const CREATE_FUEL = gql`
  mutation Mutation(
    $type: ID
    $date: Date
    $time: String
    $amount: Int
    $cost: Float
    $total: Float
    $supplier: ID
    $driver: ID
    $active: ID
  ) {
    createFuel(
      type: $type
      date: $date
      time: $time
      amount: $amount
      cost: $cost
      total: $total
      supplier: $supplier
      driver: $driver
      active: $active
    ) {
      id
      cost
      amount
      total
      time
    }
  }
`;
export const GET_MAIN_DATA = gql`
  query GetSumarizeOperation {
    getSumarizeOperation {
      amountFuel
      amountLastFuel
      amountLastOther
      amountOther
    }
  }
`;

export const GET_FUEL_BY_ID = gql`
  query GetFuelById($getFuelByIdId: ID) {
    getFuelById(id: $getFuelByIdId) {
      active {
        description
      }
      amount
      cost
      date
      id
      supplier {
        businessname
      }
      time
      total
      type {
        description
      }
    }
  }
`;

export const GET_OTHER_EXPENSE_BY_ID = gql`
  query GetOtherById($getOtherByIdId: ID) {
    getOtherById(id: $getOtherByIdId) {
      active {
        description
      }
      amount
      cost
      date
      id
      name
      reference
      time
      total
      type {
        description
      }
    }
  }
`;

export const DELETE_FUEL_BY_ID = gql`
  mutation DeleteFuelById($deleteFuelByIdId: ID) {
    deleteFuelById(id: $deleteFuelByIdId) {
      id
      cost
      amount
    }
  }
`;

export const DELETE_OTHER_EXPENSE_BY_ID = gql`
  mutation DeleteOtherExpenseById($deleteOtherExpenseByIdId: ID) {
    deleteOtherExpenseById(id: $deleteOtherExpenseByIdId) {
      id
      cost
      amount
    }
  }
`;
