import React, { useState, useEffect } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import Alerts from "components/Alerts";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBoxOne from "components/StatBoxOne";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import { DataGrid,  GridToolbarContainer,
  GridToolbarFilterButton,
  esES,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import CreatePernoct from "./CreatePernoct";
import ViewPernoct from "./ViewPernoct";
import PDFPernoct from "./PDFPernoct";
import ViewPdf from "components/ViewPdf";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import {
  GET_PERNOCT,
  GET_MAIN_DATA,
  GET_SUPERVISOR_PERSONAL,
} from "./QueryPernoct";
import AssignPernoct from "./AssignPernoct";
const Pernoct = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [mainData, setMainData] = useState([]);
  const [id, setId] = useState(null);
  const [personal, setPersonal] = useState([]);
  const [pernoct, setPernoct] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenAssign, setModalOpenAssign] = useState(false);

  const [newAssignPernoct, setnewAssignPernoct] = useState(null);
  const [newPernoct, setnewPernoct] = useState(null);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const handleOpenModalAssign = (id) => {
    setModalOpenAssign(true);
    setId(id);
  };

  const handleCloseModalAssign = () => {
    setModalOpenAssign(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setId(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
   /*  {
      field: "project",
      headerName: "PROYECTO",
      flex: 1,
      renderCell: (params) => params.row.project,
    }, */

    {
      field: "place",
      headerName: "LUGAR",
      flex: 1,
      renderCell: (params) => params.row.place,
    },
    {
      field: "datebegingrute",
      headerName: "FECHA DE RUTA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.datebegingrute).toLocaleDateString("es-ES"),
    },
    {
      field: "datebeginpernoct",
      headerName: "FECHA DE PERNOCTE",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.datebeginpernoct).toLocaleDateString("es-ES"),
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {/*    {params.row.state == 0 && ( */}
          <TouchAppIcon onClick={() => handleOpenModalAssign(params.row.id)} />
          {/*  )} */}

          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          <LocalPrintshopIcon
            onClick={() => handleOpenModalPDF(params.row.id)}
          />
          {/*  <CreateIcon />       */}
        </Box>
      ),
    },
  ];
  const FUNCTION_MAIN_DATA = async () => {
    try {
      const { data } = await client.query({
        query: GET_MAIN_DATA,
        fetchPolicy: "network-only",
      });
      setMainData(data.getSumarizePernoct);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  useEffect(() => {
    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_PERNOCT = async () => {
      try {
        const { data } = await client.query({
          query: GET_PERNOCT,
          fetchPolicy: "network-only",
        });
        console.log(data.getPernoct);
        setPernoct(data.getPernoct);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_GET_PERNOCT();
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_MAIN_DATA();
  }, [newPernoct, newAssignPernoct]);
  return (
    <>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <ModalUtil
        isOpen={modalOpenAssign}
        onClose={handleCloseModalAssign}
        Component={AssignPernoct}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setnewAssignPernoct: setnewAssignPernoct,

            setNewAssignRequeriment: null,
            personal: personal,
            handleCloseModal: handleCloseModalAssign,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewPernoct}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: PDFPernoct,
            title: "Entrevista",
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="SEGURIDAD" subtitle="seguridad/control de pernocte" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Cantidad de Pernoctes"
            value={mainData?.amount}
            /*  increase={mainData?.cargo}*/
            description={"Pernoctes Registrados"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Personas por Pernocte"
            value={mainData?.maxpersonal}
            /*  increase={mainData?.cargo}*/
            description={"Cantidad maxima"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Ultimo Poryecto"
            value={mainData?.lastproyect}
            /*  increase={mainData?.cargo}*/
            description={"Ultimo Pernocte Registrado"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Fecha del ultimo Pernocte"
            value={
              mainData?.lastdaterute
                ? new Date(Number(mainData?.lastdaterute)).toLocaleDateString(
                    "es-ES"
                  )
                : null
            }
            description={"Ultimo Pernocte Registrado"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          {/* ROW 1 */}

          {/* ROW 2 */}

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="15px"
            >
              CREAR CONTROL DE PERNOCTE
            </Box>
            <CreatePernoct
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
              personal={personal}
              setnewPernoct={setnewPernoct}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE PERNOCTES
            </Box>
            <DataGrid
              loading={!pernoct}
              getRowId={(row) => row.id}
              rows={pernoct || []}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Pernoct;
