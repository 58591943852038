import { gql } from "@apollo/client";
export const GET_INDICATORS_REPORT = gql`
  query GetIndicatorsSeriesReport(
    $all: Boolean
    $active: ID
    $month: Int
    $year: Int
    $item: ID
  ) {
    getIndicatorsSeriesReport(
      all: $all
      active: $active
      month: $month
      year: $year
      item: $item
    ) {
      data {
        x
        y
        date
        time_ini
        time_fin
        state
        type
        description
      }
      name
    }
  }
`;


export const GET_INDICATORS_ALL_REPORT= gql`
query GetIndicatorDisponibility($item: ID, $year: Int, $month: Int, $active: ID, $all: Boolean) {
  getIndicatorDisponibility(item: $item, year: $year, month: $month, active: $active, all: $all) {
    disponibility {
      data
      name
    }
    mtbfandmttr {
      name
      data
    }
    avgmtbfandmttr {
      name
      data
    }
    avgdisponibility {
      name
      data
    }
  }
}`;