import * as Yup from "yup";
export const PERSONAL_REQUERIMENT_SCHEMA_YUP = Yup.object().shape({
  personal:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  area: Yup.string(),
  term: Yup.string().required("Requiredo"),
  subject: Yup.string().required("Requiredo"),
  workplace: Yup.string().required("Requiredo"),
  duration: Yup.number().required("Requiredo")
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(1, "El número debe tener exactamente 1 dígitos")
    .max(120, "El número debe tener exactamente 120 dígitos"),
  incorporationdate: Yup.date().required("Requiredo"),
  remuneration: Yup.number()
    .min(0, "El precio no puede ser negativo")
    .max(9999.99, "El precio es demasiado alto. max 9999.99")
    .test(
      "is-decimal",
      "El precio debe ser un número decimal con 2 decimales",
      (value) => {
        return /^\d+(\.\d{1,2})?$/.test(String(value));
      }
    )
    .required("Requiredo"),
  ngoal: Yup.number().required("Requiredo")
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(1, "El número debe tener exactamente 1 dígitos")
    .max(50, "El número debe tener exactamente 100 dígitos"),
  observations: Yup.string(),
  position: Yup.string().required("Requiredo"),
});

export const ASSIGN_PERSONAL_REQUERIMENT = Yup.object().shape({
  personal: Yup.array().of(Yup.string()).min(1,"Debes seleccionar al menos 1"),
});
