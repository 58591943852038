import { gql } from "@apollo/client";


export const GET_SETTINGS = gql`
  query {
    getSettings {
      currency
      dateFormat
      rounding
    }
  }
`

export const UPDATE_SETTINGS = gql`
  mutation ($currency: CURRENCY, $dateFormat: DATEFORMAT , $rounding: Int){
    updateSettings (currency: $currency, dateFormat: $dateFormat, rounding: $rounding){
      currency
      dateFormat
      rounding
    }
  }
`