import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useFormik } from "formik";
import { DatePicker, TimePicker, jaJP } from "@mui/x-date-pickers";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { GET_PERNOCT_BY_ID ,ASSIGN_PERNOCT} from "./QueryPernoct";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ASSIGN_SCHEMA_YUP } from "./YupPernoct";
const AssignPernoct = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    personal,
    setnewAssignPernoct,
    handleCloseModal,
  } = params;


  const [visibility, setvisibility] = useState(false);
  const formikPernocte = useFormik({
    initialValues: {
      pernoct: id,
      item: [
        {
          begin: dayjs('2022-04-17T15:30'),
          end: dayjs('2022-04-17T15:30'),
          personal: "",
        },
        {
          begin: dayjs('2022-04-17T15:30'),
          end:dayjs('2022-04-17T15:30'),
          personal: "",
        },
        {
          begin: dayjs('2022-04-17T15:30'),
          end: dayjs('2022-04-17T15:30'),
          personal: "",
        },         
      ],
    },
    validationSchema: ASSIGN_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values)
       setSubmitting(true);
        const {
          pernoct,
          item,      
        } = values;
        try {
          const itemRefactor=item.map((el)=>new Object({
            begin:el.begin.$H+":"+el.begin.$m,
            end: el.end.$H+":"+el.end.$m,
            personal: el.personal,
          }))
          const response = await client.mutate({
            mutation: ASSIGN_PERNOCT,
            variables: {
              pernoct,
              item:itemRefactor,    
            },
          });
          setnewAssignPernoct(response.data.assignPernocte);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          handleCloseModal();
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
    },
  });
  const client = useApolloClient();

  const [pernoct, setpernoct] = useState([]);
  const [personalpernoct, setpersonalpernoct] = useState([]);
  useEffect(() => {
    const getpernoct = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERNOCT_BY_ID,
          variables: {
            getPernoctByIdId: id,
          },
        });
        console.log(data.getPernoctByID);
        setpernoct(data.getPernoctByID);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getpernoct(id);
  }, []);

  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <form onSubmit={formikPernocte.handleSubmit}>
        <Box
          my="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">PROYECTO</label>
            <TextField disabled value={pernoct?.pernoct?.project} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA INICIO DE RUTA</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.datebegingrute
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA INICIO PERNOCTE</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.datebeginpernoct
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA FIN PERNOCTE</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.dateendpernoct
              ).toLocaleDateString("es-ES")}
            />
          </Box>
         {/*  <Box sx={style}>
            <label for="my-input">LUGAR</label>
            <TextField disabled value={pernoct?.pernoct?.place} />
          </Box> */}
        </Box>
        <Box
          mb="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          {personal?.length && (
            <Autocomplete
              multiple
              id="s"
              name="s"
              filterSelectedOptions
              options={personal}
              getOptionDisabled={(option) =>
                personalpernoct.length >2 
              }
              getOptionLabel={(option) =>
                option.postulant?.name + " / " + option.postulant?.surname
              }
              onChange={(event, newValue) => {
              /*   formikPernocte.setFieldValue("item", newValue); */              
                setpersonalpernoct(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                /*   error={
                    formikPernocte.touched.supervisor &&
                    Boolean(formikPernocte.errors.supervisor)
                  }
                  helperText={
                    formikPernocte.touched.supervisor &&
                    formikPernocte.errors.supervisor
                  } */
                />
              )}
            />
          )}
           {formikPernocte.touched.supervisor?.id && formikPernocte.errors.supervisor?.id ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
               <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            > {formikPernocte.touched.supervisor?.id &&
              formikPernocte.errors.supervisor?.id}</p>
             
                
            </FormHelperText>
          ) : null}
        </Box>
        {console.log(formikPernocte)}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">NOMBRES</TableCell>
                  <TableCell align="">HORA INICIO</TableCell>
                  <TableCell align="">HORA FIN</TableCell>
                </TableRow>
              </TableHead>
            
              <TableBody>
                {personalpernoct?.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row?.postulant.name + "  " + row?.postulant.surname}
                      </TableCell>
                      <TableCell align="">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            id={`item[${index}].begin`}
                            name={`item[${index}].begin`}                            
                            value={
                              formikPernocte.values.item?.[index]
                                ?.begin
                            }
                            onChange={(e) => {
                              formikPernocte.setFieldValue(
                                `item[${index}].begin`,
                                e
                              );
                              formikPernocte.setFieldValue(
                                `item[${index}].personal`,
                                personalpernoct[index]?.id
                              );
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} />
                              </>
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align="">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            id={`item[${index}].end`}
                            name={`item[${index}].end`}
                            value={
                              formikPernocte.values.item?.[index]
                                ?.end
                            }                         
                            onChange={(e) => {
                              console.log(personalpernoct)
                              formikPernocte.setFieldValue(
                                `item[${index}].end`,
                                e
                              );
                              formikPernocte.setFieldValue(
                                `item[${index}].personal`,
                                personalpernoct[index]?.id
                              );
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} />
                              </>
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {formikPernocte.touched.item &&
          formikPernocte.errors.item?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Tienes que seleccionar 3 Personales y configurar las fechas
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"} gap={"15px"}>
          <Button variant="contained" color="success" type="submit" disabled={formikPernocte.isSubmitting}>
          {formikPernocte.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </form>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default AssignPernoct;
