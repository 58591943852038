import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import LoopIcon from "@mui/icons-material/Loop";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { ITEM_ORDER_BUY_SCHEMA_YUP } from "./YupValidation";
import { CREATE_BUY_ORDER,GET_ITEM_BY_REQUERIMENT_ID } from "./QueryItemRequeriment";
import { useUser } from "hooks/useUser";
const CreateBuyOrder = ({
  setAlertCustom,
  alertCustom,
  personal,
  items,
  priority,
  setNewItemRequeriment,
  requerimentfororderbuy,
  setItems
}) => {

  console.log(requerimentfororderbuy)
  console.log(items)
  const client = useApolloClient();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const theme = useTheme();
  const autocompletepersonalRef = useRef(null);
  const { user } = useUser();
  const autocompleterequerimentRef = useRef(null);
  const formikBuyOrder = useFormik({
    initialValues: {
      personal: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      requeriment: {
        label: "",
        id: "",
      },
      area: "",
      priority: "",
      subject: "",
      igv: "",
      buyorderItem: [],
    },
    validationSchema: ITEM_ORDER_BUY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const {
        personal,
        requeriment,
        area,
        priority,
        subject,
        igv,
        buyorderItem,
      } = values;
      setSubmitting(true);
      const newIncomeItem = buyorderItem.map((it) => {
        return new Object({
          item: it,
          amount: Number(document.getElementById(it).value),
        });
      });

      try {
        const response = await client.mutate({
          mutation: CREATE_BUY_ORDER,
          variables: {
            personal: personal.id,
            requeriment: requeriment.id,
            area: "LOGISTICA",
            igv,
            priority,
            subject: subject.toUpperCase(),
            buyorderItem: newIncomeItem,
          },
        });
        console.log(response.data.createBuyOrder);
        setNewItemRequeriment(response.data.createBuyOrder);
        /* handleCloseModal(); */
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        setSelectionModel([]);
        buyorderItem.forEach((it) => {
          document.getElementById(it).value = "";
        });
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        autocompleterequerimentRef.current.value = "";
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FUNCTION_GET_ITEMS_BY_REQUERIMENT_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_ITEM_BY_REQUERIMENT_ID,
        variables:{
          getRequerimentAndItemForBuyOrderByIdId:id
        },
        fetchPolicy: "network-only",
      });
     
      setItems(data.getRequerimentAndItemForBuyOrderById);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const columns = [
   /*  {
      field: "active",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.active,
    }, */
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 2,
      renderCell: (params) => params.row.description,
    },
    {
      field: "unity",
      headerName: "UNIDAD",
      flex: 1,
      renderCell: (params) =>
        params.row.unity.description + " / " + params.row.specification,
    },
    {
      field: "ope",
      headerName: "CANTIDAD",
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <TextField
            disabled={!params.api.isRowSelected(params.row.id)}
            id={params.row.id}
            required={params.api.isRowSelected(params.row.id) ? true : false}
            variant="standard"
          />
        </Box>
      ),
    },
  ];
  return (
    <form onSubmit={formikBuyOrder.handleSubmit}>
      <Box mb={"20px"}>
        {user.admin ? (
          <>
            {personal?.length ? (
              <Autocomplete
                freeSolo
                id="personal"
                name="personal"
                disableClearable
                ref={autocompletepersonalRef}
                value={formikBuyOrder.values.personal.label}
                options={personal?.map(
                  (option) =>
                    new Object({
                      label:
                        "nombres:" +
                        option.postulant.name +
                        " / " +
                        "apellidos:" +
                        option.postulant.surname +
                        " / " +
                        "dni:" +
                        option.postulant.doi,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikBuyOrder.setFieldValue("personal", {
                    label: newValue.label,
                    id: newValue.value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar por nombre/apellido/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={
                      formikBuyOrder.touched.personal?.id &&
                      Boolean(formikBuyOrder.errors.personal?.id)
                    }
                    helperText={
                      formikBuyOrder.touched.personal?.id &&
                      formikBuyOrder.errors.personal?.id
                    }
                  />
                )}
              />
            ) : (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </>
        ) : null}
      </Box>
      <Box mb={"20px"}>
        {requerimentfororderbuy?.length ? (
          <Autocomplete
            freeSolo
            id="requeriment"
            name="requeriment"
            disableClearable
            ref={autocompleterequerimentRef}
            value={formikBuyOrder.values.requeriment.label}
            options={requerimentfororderbuy?.map(
              (option) =>
                new Object({
                  label:
                    "Codido:" +
                    option.code +
                    " / " +
                    "Prioridad:" +
                    option.priority.description +
                    " / " +
                    "Estado:" +
                    option.state.description,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {

              FUNCTION_GET_ITEMS_BY_REQUERIMENT_ID(newValue.value)
              formikBuyOrder.setFieldValue("requeriment", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por fecha/prioridad/estado"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikBuyOrder.touched.requeriment?.id &&
                  Boolean(formikBuyOrder.errors.requeriment?.id)
                }
                helperText={
                  formikBuyOrder.touched.requeriment?.id &&
                  formikBuyOrder.errors.requeriment?.id
                }
              />
            )}
          />
        ) : (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        {/* <TextField
        id="ngoal"
        name="ngoal"
        label="CENTRO DE COSTOS"
        variant="outlined"
        value={formikBuyOrder.values.ngoal}
        onChange={formikBuyOrder.handleChange}
        error={
          formikBuyOrder.touched.ngoal &&
          Boolean(formikBuyOrder.errors.ngoal)
        }
        helperText={
          formikBuyOrder.touched.ngoal &&
          formikBuyOrder.errors.ngoal
        }
      /> */}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PRIORIDAD</InputLabel>
          <Select
            id="priority"
            value={formikBuyOrder.values.priority}
            name="priority"
            onChange={formikBuyOrder.handleChange}
            error={
              formikBuyOrder.touched.priority &&
              Boolean(formikBuyOrder.errors.priority)
            }
          label="PRIORIDAD"
          >
            {priority?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikBuyOrder.touched.priority && formikBuyOrder.errors.priority ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikBuyOrder.touched.priority &&
                formikBuyOrder.errors.priority}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="subject"
          name="subject"
          label="MOTIVO"
          variant="outlined"
          value={formikBuyOrder.values.subject}
          onChange={formikBuyOrder.handleChange}
          error={
            formikBuyOrder.touched.subject &&
            Boolean(formikBuyOrder.errors.subject)
          }
          helperText={
            formikBuyOrder.touched.subject && formikBuyOrder.errors.subject
          }
        />
        <TextField
          id="igv"
          name="igv"
          label="IGV (
            %
          )"
          variant="outlined"
          value={formikBuyOrder.values.igv}
          type="number"
          onChange={formikBuyOrder.handleChange}
          error={
            formikBuyOrder.touched.igv && Boolean(formikBuyOrder.errors.igv)
          }
          helperText={formikBuyOrder.touched.igv && formikBuyOrder.errors.igv}
        />
      </Box>
      <Box
        gridColumn="span 6"
        gridRow="span 3"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!items}
          getRowId={(row) => row.id}
          rows={items || []}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}                 
          pageSize={10}
          selectionModel={selectionModel}
          onSelectionModelChange={(itm) => {
            setSelectionModel(itm);
            formikBuyOrder.setFieldValue("buyorderItem", itm);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>
            ),
          }}
        />
        {formikBuyOrder.touched.buyorderItem &&
        formikBuyOrder.errors.buyorderItem ? (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Verica los campos esten requeridos
            </p>
          </FormHelperText>
        ) : null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikBuyOrder.isSubmitting}
        >
          {formikBuyOrder.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateBuyOrder;
