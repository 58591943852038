import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import { GET_SINTOMATOLOGY_BY_ID } from "./QuerySintoIncident";

const ViewSintomatology = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [sintomatology, setsintomatology] = useState([]);
  useEffect(() => {
    const getsintomatology = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_SINTOMATOLOGY_BY_ID,
          variables: {
            getSintomatologyByIdId: id,
          },
        });
        console.log(data.getSintomatologyByID);
        setsintomatology(data.getSintomatologyByID);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getsintomatology(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility? (
      <>
       <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
 <Box sx={style}>
            <label for="my-input">OPERADOR</label>
            <TextField disabled value={sintomatology?.sintomatology?.operator.postulant.name+
              " " +sintomatology?.sintomatology?.operator.postulant.surname} />
          </Box>
       
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
         
       
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">CRITERIO</TableCell>
                  <TableCell align="">RESPUESTA</TableCell>
                
                </TableRow>
              </TableHead>
              <TableBody>
                {sintomatology.questions.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row?.question?.description}
                      </TableCell>
                      <TableCell align="">
                        {row?.answer ? "SI" : "NO"}
                      </TableCell>
                     
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewSintomatology;
