import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useApolloClient } from "@apollo/client";
import { GET_DOCUMENT_ACTIVE_BY_ID } from "./QueryDocument";
const ViewDocument = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleCloseModal,
    setNewAssignRequeriment,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [documentFicha, setdocumentFicha] = useState(null);
  useEffect(() => {
    const getdocumentFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_ACTIVE_BY_ID,
          variables: {
            getDocumentActiveByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getDocumentActiveById);
        setdocumentFicha(data.getDocumentActiveById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getdocumentFicha(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility && (
      <>
        <Box
          display={"flex"}
          justifyContent={"center"}
          mb={"20px"}
          bgcolor={"#525659"}
        >
          <Box
            width={"400px"}
            height={"500px"}
            border={"2px solid #000"}
            bgcolor={"#fff"}
          >
            <img
              src={documentFicha?.url}
              alt={documentFicha?.document.description}
              loading="lazy"
              width={"100%"}
            />
          </Box>

          {/*   <PDFViewer>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  </PDFViewer> */}
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">FECHA DE EMISION</label>
            <TextField
              disabled
              value={new Date(documentFicha?.emitiondate).toLocaleDateString(
                "es-ES"
              )}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA DE CADUCIDAD</label>
            <TextField
              disabled
              value={new Date(documentFicha?.caducitydate).toLocaleDateString(
                "es-ES"
              )}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA FINAL</label>
            <TextField
              disabled
              value={new Date(documentFicha?.finaldate).toLocaleDateString(
                "es-ES"
              )}
            />
          </Box>{" "}
          <Box sx={style}>
            <label for="my-input">ESTADO</label>
            <TextField disabled value={documentFicha?.state.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input"></label>
            <Box
              borderRadius={"50%"}
              width={"30px"}
              height={"30px"}
              backgroundColor={
                documentFicha?.state.description == "VIGENTE"
                  ? "#28a745"
                  : documentFicha?.state.description == "POR ACTUALIZAR"
                  ? "#ffc107"
                  : "#dc3545"
              }
            ></Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"10px"} gap={"10px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
        </Box>{" "}
      </>
    )
  );
};

export default ViewDocument;
