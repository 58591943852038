import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StatBoxOne from "components/StatBoxOne";
import StatBox from "components/StatBox";
import { useApolloClient } from "@apollo/client";
import { DataGrid,  esES, GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import {
  GET_PRIORITIES,
  GET_REQUERIMENT_FOR_ORDER_BUY,
} from "./../requerimentItem/QueryItemRequeriment";
import { GET_MAIN_DATA } from "./QueryItemRequeriment";
import ModalUtil from "components/ModalUtil";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { GET_ALL_ITEMS } from "./../item/QueryItem";
import CreateBuyOrder from "./CreateBuyOrder";
import { GET_ALL_BUY_ORDER } from "./QueryItemRequeriment";
import AssignSupplier from "./AssignSupplier";
import ViewBuyOrder from "./ViewBuyOrder";
import { useUser } from "hooks/useUser";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Alerts from "components/Alerts";
import ViewPdf from "components/ViewPdf";
import TemplatePDF from "./TemplatePDF";
const BuyOrder = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newItemRequeriment, setNewItemRequeriment] = useState(null);
  const [items, setItems] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [priority, setPriority] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [requerimentfororderbuy, setrequerimentfororderbuy] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const { user } = useUser();
  const [
    newAssignSupplierRequeriment,
    setNewAssignSupplierRequeriment,
  ] = useState(false);
  const [id, setId] = useState(null);
  const [buyOrder, setbuyOrder] = useState([]);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModal = (id) => {
    setModalOpen(true);
    setrequerimentselected(id);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setrequerimentselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/* <CreateIcon />
          <DeleteIcon /> */}
           {params.row.state.description == "ATENDIDO" ? (
            <LocalPrintshopIcon
              onClick={() => handleOpenModalPDF(params.row.id)}
            />
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsRequerimentApproved = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 1,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {params.row.state.description == "APROBADO"&& user.admin ? (
            <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          ) : null}
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_PRIORITIES = async () => {
      try {
        const { data } = await client.query({
          query: GET_PRIORITIES,
        });
        setPriority(data.getPriority);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_REQUERIMENT_FOR_BUY_ORDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_REQUERIMENT_FOR_ORDER_BUY,
          fetchPolicy: "network-only",
        });
        setrequerimentfororderbuy(data.getItemRequerimentForBuyOrder);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
          fetchPolicy: "network-only",
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_ITEMS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEMS,
          fetchPolicy: "network-only",
        });
        setItems(data.getAllItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_BUY_ORDER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_BUY_ORDER,
          fetchPolicy: "network-only",
        });
       
        setbuyOrder(data.getBuyOrder);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
     
        setMainData(data.getSumarizeBuyOrder);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    FUNCTION_MAIN_DATA();
    FUNCTION_GET_PRIORITIES();
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_GET_ALL_ITEMS();
    FUNCTION_GET_REQUERIMENT_FOR_BUY_ORDER();
    FUNCTION_GET_ALL_BUY_ORDER();
  }, [newItemRequeriment, newAssignSupplierRequeriment]);
  return (
    <>
       {alertCustom.state && (
          <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
        )}
          <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Entrevista",
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={AssignSupplier}
        sizewidth={1200}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignSupplierRequeriment,
            handleCloseModal: handleCloseModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewBuyOrder}
        sizewidth={1000}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="LOGISTICA" subtitle="logistica/orden de compra" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Aprovados"
            value={mainData?.aproved}
           /*  increase={"Aprovados por administracion y no atendidos"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Pendientes"
            value={mainData?.pending}
         /*    increase={"Requieren ser aprovados por Adminsitracion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Iniciados"
            value={mainData?.begining}
            /* increase={"Falta alguna asignacion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTOS PENDIENTES
            </Box>
            <DataGrid
              loading={!buyOrder}
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>         
          <Box
            gridColumn="span 6"
            gridRow="span 6"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box>
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                py="5px"
              >
                GENERAR ORDEN DE COMPRA
              </Box>
              <CreateBuyOrder
                alertCustom={alertCustom}
                setAlertCustom={setAlertCustom}
                personal={personal}
                items={items}
                setItems={setItems}
                priority={priority}
                requerimentfororderbuy={requerimentfororderbuy}
                setNewItemRequeriment={setNewItemRequeriment}
              />
            </Box>
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTO INICIADOS / APROBADOS
            </Box>
            <DataGrid
              loading={!buyOrder}
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) =>
                    row.state.description === "INICIADO" ||
                    row.state.description === "APROBADO"
                ) || []
              }
              columns={columnsRequerimentApproved}
              disableSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}   
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTO REHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!buyOrder}
              disableSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}   
              getRowId={(row) => row.id}
              rows={
                buyOrder?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BuyOrder;
