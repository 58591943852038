import React from "react";
import { useState, useEffect } from "react";
import { GET_ASIGNATION_BY_ID } from "./QueryFlota";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import RotatingLoopIcon from './../../utils/RotatingLoopIcon'; 
const ViewAssignation = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [assignation, setassignation] = useState(null);
  useEffect(() => {
    const getAassignationById = async () => {
      try {
        const { data } = await client.query({
          query: GET_ASIGNATION_BY_ID,
          variables: {
            getAssignationByIdId: id,
          },
        });
        console.log(data.getAssignationById);
        setassignation(data.getAssignationById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAassignationById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">CONDUCTOR</label>
            <TextField
              disabled
              value={
                assignation?.driver.postulant.name +
                " " +
                assignation?.driver.postulant.surname
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROYECTO</label>
            <TextField disabled value={assignation?.proyect} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">DESCRIPCION</label>
            <TextField disabled value={assignation?.description} />
          </Box>

          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={assignation?.type} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA INICIAL</label>
            <TextField
              disabled
              value={new Date(assignation?.begindate).toLocaleDateString(
                "es-ES"
              )}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">HORA INICIAL</label>
            <TextField
              disabled
              value={assignation?.begintime}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA FINAL</label>
            <TextField
              disabled
              value={new Date(assignation?.enddate).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">HORA FINAL</label>
            <TextField
              disabled
              value={assignation?.endtime}
            />
          </Box>

          <Box sx={style}>
            <label for="my-input">PUNTO DE PARTIDA</label>
            <TextField disabled value={assignation?.departure} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PUNTO DE CARGA</label>
            <TextField disabled value={assignation?.load} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PUNTO DE DESCARGA</label>
            <TextField disabled value={assignation?.download} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PUNTO DE RETORNO</label>
            <TextField disabled value={assignation?.returnn} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">OBSERVACION</label>
            <TextField disabled value={assignation?.observation} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ): (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
         <RotatingLoopIcon />
      </Box>
  ));
};

export default ViewAssignation;
