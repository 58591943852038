import * as Yup from "yup";

export const FUEL_SCHEMA_YUP = Yup.object().shape({
  active: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  driver: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  supplier: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  type: Yup.string().required("Requirido"),
  date: Yup.date().required("Requirido"),
  time: Yup.string().required("Requirido"),
  amount: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  cost: Yup.number()
    .min(0, "El precio no puede ser negativo")
    .max(9999.99, "El precio es demasiado alto")
    .test(
      "is-decimal",
      "El precio debe ser un número decimal con 2 decimales",
      (value) => {
        return /^\d+(\.\d{1,2})?$/.test(String(value));
      }
    )
    .required("Requiredo"),
  /*  total: Yup.number()
    .min(0, "El precio no puede ser negativo")
    .max(9999.99, "El precio es demasiado alto")
    .test(
      "is-decimal",
      "El precio debe ser un número decimal con 2 decimales",
      (value) => {
        return /^\d+(\.\d{1,2})?$/.test(String(value));
      }
    )
    .required("Requiredo"), */
});

export const OTHER_EXPENSE_SCHEMA_YUP = Yup.object().shape({
  active: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  driver: Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  name: Yup.string().required("Requirido"),
  reference: Yup.string(),
  type: Yup.string().required("Requirido"),
  date: Yup.date().required("Requirido"),
  time: Yup.string().required("Requirido"),
  amount: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  cost: Yup.number()
    .min(0, "El precio no puede ser negativo")
    .max(9999.99, "El precio es demasiado alto")
    .test(
      "is-decimal",
      "El precio debe ser un número decimal con 2 decimales",
      (value) => {
        return /^\d+(\.\d{1,2})?$/.test(String(value));
      }
    )
    .required("Requiredo"),
});
