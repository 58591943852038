import * as Yup from "yup";
export const GROUP_SCHEMA_YUP = Yup.object().shape({
    description: Yup.string().min(5, "Debe tener minimo 5 caracteres").required("Requerido"),
    consumable:Yup.boolean("Select this checkbox a please").required("Requerido")
});

export const CLASS_SCHEMA_YUP = Yup.object().shape({
    description: Yup.string().min(5, "Debe tener minimo 5 caracteres").required("Requirido"),
    group: Yup.string().required("Requiredo"),
  });
  
  export const CLASS_EDIT_SCHEMA_YUP = Yup.object().shape({
    description: Yup.string().min(5, "Debe tener minimo 5 caracteres").required("Requirido"),
  });
  