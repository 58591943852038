import { gql } from "@apollo/client";
export const GET_DOCUMENT_TRAINING_STATES = gql`
  query GetDocumentTraining {
    getDocumentTraining {
      personal {
        name
        surname
        doi
      }
      document {
        state {
          description
          id
        }
        document {
          id
          description
          kind
        }
      }
    }
  }
`;

export const GET_DOCUMENT_PERSONAL_STATES = gql`
  query GetDocumentPersonal {
    getDocumentPersonal {
      personal {
        name
        surname
        doi
      }
      document {
        state {
          description
          id
        }
        document {
          id
          description
          kind
        }
      }
    }
  }
`;

export const GET_LIST_DOCUMENT_TRAINING = gql`
  query GetDocumentListTraining {
    getDocumentListTraining {
      id
      description
      kind
      type
      weight
    }
  }
`;
export const GET_LIST_DOCUMENT_PERSONAL = gql`
  query GetDocumentListPersonal {
    getDocumentListPersonal {
      id
      description
      kind
      type
      weight
    }
  }
`;
