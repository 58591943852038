import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Login from "scenes/login";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Postulant from "scenes/gestionpersonal/postulant";
import JobReference from "scenes/gestionpersonal/jobReference";
import Interview from "scenes/gestionpersonal/interview";
import Document from "scenes/gestionpersonal/document";
import GroupClass from "scenes/logistic/groupclass";
import StateDocument from "scenes/gestionpersonal/statedocument";
import StateDocumentVehicle from "scenes/operation/statedocument";
import Item from "scenes/logistic/item";
import Provider from "scenes/logistic/provider";
import ProviderSelectionEvaluation from "scenes/logistic/evaluationSelection";
import FichaPersonal from "scenes/gestionpersonal/FichaPersonal";
import RequerimentPersonal from "scenes/gestionpersonal/requerimentPersonal";
import Personal from "scenes/gestionpersonal/personal";
import RequerimentItem from "scenes/logistic/requerimentItem";
import BuyOrder from "scenes/logistic/buyOrder";
import Operations from "scenes/operation/operation";
import Flota from "scenes/operation/flota";
import Drivers from "scenes/operation/drivers";
import DocumentVehicle from "scenes/operation/document";
import Garantias from "scenes/mantenimiento/garantiasPM";
import OrdenTrabajo from "scenes/mantenimiento/ordentrabajo";
import RequerimentMantaince from "scenes/mantenimiento/requerimientMantaince";
import Training from "scenes/segurity/training";
import Somnolency from "scenes/segurity/somnolency";
import Van from "scenes/segurity/van";
import Cistern from "scenes/segurity/cistern";
import PersonalAd from "scenes/administration/personal";
import MaintanceAd from "scenes/administration/maintance";
import LogisticAd from "scenes/administration/logistic";
import Report from "scenes/operation/report";
import Indicators from "scenes/mantenimiento/indicadores";

import Protected from "components/Protected";

import Company from "scenes/configuration/Company";
import User from "scenes/configuration/User";
import ProfileUser from "scenes/configuration/ProfileUser";
import General from "scenes/configuration/General";
import NotFound from "components/NotFound";

import { esES as pickersBgBG } from "@mui/x-date-pickers/locales";
import { esES as coreBgBG } from "@mui/material/locale";

import SintomasEIncidentes from "scenes/segurity/sintoIncident";
import Pernoct from "scenes/segurity/pernoct";

/* import Products from "scenes/products";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown"; 
import Admin from "scenes/admin";*/
/* import Performance from "scenes/performance"; */
//import axios from 'axios'

// const AuthenticatedGPPostulant = RequireAuthorization([ADMIN_GENERAL])(Postulant)

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  //axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route
              element={
                <Protected>
                  <Layout />
                </Protected>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/gp/postulant" element={<Postulant />} />
              <Route path="/gp/interview" element={<Interview />} />
              <Route path="/gp/jobreference" element={<JobReference />} />
              <Route path="/gp/personal" element={<Personal />} />
              <Route path="/gp/fichapersonal" element={<FichaPersonal />} />
              <Route path="/gp/document" element={<Document />} />
              <Route path="/gp/statedocument" element={<StateDocument />} />

              <Route path="/gp/requeriment" element={<RequerimentPersonal />} />
              <Route path="/l/requeriment" element={<RequerimentPersonal />} />
              <Route
                path="/ope/requeriment"
                element={<RequerimentPersonal />}
              />
              <Route
                path="/man/requeriment"
                element={<RequerimentPersonal />}
              />
              <Route
                path="/seg/requeriment"
                element={<RequerimentPersonal />}
              />

              <Route path="/l/requerimentitem" element={<RequerimentItem />} />
              <Route path="/gp/requerimentitem" element={<RequerimentItem />} />
              <Route
                path="/ope/requerimentitem"
                element={<RequerimentItem />}
              />
              <Route
                path="/man/requerimentitem"
                element={<RequerimentItem />}
              />
              <Route
                path="/seg/requerimentitem"
                element={<RequerimentItem />}
              />

              <Route
                path="/ope/statedocument"
                element={<StateDocumentVehicle />}
              />

              <Route
                path="/man/requerimentmaintance"
                element={<RequerimentMantaince />}
              />
              <Route
                path="/ope/requerimentmaintance"
                element={<RequerimentMantaince />}
              />
              <Route path="/l/groupclass" element={<GroupClass />} />
              <Route path="/l/item" element={<Item />} />
              {/*  <Route path="/l/assembly" element={<Assembly/>} /> */}
              <Route path="/l/provider" element={<Provider />} />
              <Route
                path="/l/providerselectionevaluation"
                element={<ProviderSelectionEvaluation />}
              />

              {/*   <Route path="/l/requeriment" render={() => <RequerimentItem />} />
              <Route path="/gp/requerimentitem" render={() => <RequerimentItem />} /> */}
              <Route path="/l/buyorder" element={<BuyOrder />} />

              <Route path="/ope/report" element={<Report />} />
              <Route path="/ope/operation" element={<Operations />} />
              <Route path="/ope/flota" element={<Flota />} />
              <Route path="/ope/driver" element={<Drivers />} />
              <Route path="/ope/document" element={<DocumentVehicle />} />

              <Route path="/man/guarantee" element={<Garantias />} />

              <Route path="/man/joborder" element={<OrdenTrabajo />} />
              <Route path="/man/indicators" element={<Indicators />} />

              <Route path="/seg/training" element={<Training />} />
              <Route path="/seg/somnolency" element={<Somnolency />} />
              <Route path="/seg/van" element={<Van />} />
              <Route path="/seg/cistern" element={<Cistern />} />
              <Route path="/seg/sintomas" element={<SintomasEIncidentes />} />
              <Route path="/seg/pernoct" element={<Pernoct />} />

              <Route path="/adm/personal" element={<PersonalAd />} />
              <Route path="/adm/maintance" element={<MaintanceAd />} />
              <Route path="/adm/logistic" element={<LogisticAd />} />

              <Route path="/config/company" element={<Company />} />
              <Route path="/config/user" element={<User />} />
              <Route path="config/profile" element={<ProfileUser />} />
              <Route path="config/general" element={<General />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
