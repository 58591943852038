import * as Yup from "yup";

export const DOCUMENT_SHOW_SCHEMA_YUP = Yup.object().shape({
  emitiondate: Yup.date().required("Requiredo"),
  finaldate: Yup.date().required("Requiredo"),
  caducitydate: Yup.date()
    .required("Required")
    .test(
      "valid-date",
      "La fecha de caducidad no puede ser inferior a la fecha de emisión",
      function (value) {
        const emitionDate = this.resolve(Yup.ref("emitiondate"));
        return !emitionDate || !value || value >= emitionDate;
      }
    ),
});
