import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridToolbarContainer,esES,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormHelperText } from "@mui/material";
import {
  GET_PERSONAL_WITHOUT_POSITION_FOR_REQUERIMENT,
  CREATE_ASSIGN_PERSONAL_REQUERIMENT,
} from "./QueryPersonalRequeriment";
import LoopIcon from '@mui/icons-material/Loop';
import { ASSIGN_PERSONAL_REQUERIMENT } from "./YupValidation";
import Alerts from "components/Alerts";
const AssignPosition = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const [personalWithoutPosition, setPersonalWithoutPosition] = useState([]);
  const client = useApolloClient();
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [visibility, setvisibility] = useState(false);
  const columnsPosition = [
    {
      field: "N",
      headerName: "#",
      with: "3px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "postulant.name",
      headerName: "NOMBRE",
      flex: 1,
      renderCell: (params) => params.row.postulant.name,
    },
    {
      field: "postulant.surname",
      headerName: "APELLIDOS",
      flex: 1,
      renderCell: (params) => params.row.postulant.surname,
    },
    {
      field: "postulant.doi",
      headerName: "APELLIDOS",
      flex: 1,
      renderCell: (params) => params.row.postulant.doi,
    },
  ];
  useEffect(() => {
    const getPersonalWithoutPositionForRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_WITHOUT_POSITION_FOR_REQUERIMENT,
          variables: {
            getAllPersonalWithoutPositionForRequerimentId: id,
          },
          fetchPolicy: "network-only",
        });                
        setvisibility(true); 
        setPersonalWithoutPosition(
          data.getAllPersonalWithoutPositionForRequeriment
        );
      } catch (error) {
        console.log(error.message);
      }
    };
    getPersonalWithoutPositionForRequeriment(id);
  }, []);
  const formikAssignPosition = useFormik({
    initialValues: {
      personal: [],
    },
    validationSchema: ASSIGN_PERSONAL_REQUERIMENT,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if(personalWithoutPosition.requeriment.ngoal>=values.personal.length){
        setError(false)
            setSubmitting(true);
      const { personal } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_ASSIGN_PERSONAL_REQUERIMENT,
          variables: {
            personal,
            requeriment: id,
          },
        });       
        setNewAssignRequeriment(response.data.createPostulant);
        handleCloseModal();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
      }else{
        setError(true)
      }
  
    },
  });
  return (visibility?
    <form onSubmit={formikAssignPosition.handleSubmit}>    
     {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      {<Box>
        <Box>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">Posición </TableCell>                  
                  <TableCell align="">Actual</TableCell>
                  <TableCell align="">Cantidad</TableCell>
                  <TableCell align=""> Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
                <TableRow>
                  <TableCell align=""> {personalWithoutPosition.requeriment?.position?.description}</TableCell>                  
                  <TableCell align=""> {personalWithoutPosition.requeriment?.ncurrent}</TableCell>
                  <TableCell align=""> {personalWithoutPosition.requeriment?.ngoal}</TableCell>
                  <TableCell align=""> {personalWithoutPosition.requeriment?.state?.description}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>       
        <Box
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={!personalWithoutPosition.personal}
            autoHeight={true}
            checkboxSelection
            getRowId={(row) => row.id}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
            disableSelectionOnClick 
            rows={personalWithoutPosition.personal || []}
            columns={columnsPosition}
            onSelectionModelChange={(itm) =>
              formikAssignPosition.setFieldValue("personal", itm)
            }
            components={{
              Toolbar: () =>  <GridToolbarContainer>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </GridToolbarContainer>,
            }}
          />
        </Box>
        {formikAssignPosition.touched.personal && formikAssignPosition.errors.personal ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
               <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            > {formikAssignPosition.touched.personal &&
              formikAssignPosition.errors.personal}</p>
             
                
            </FormHelperText>
          ) : null}
                {error ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
               <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            > No puede seleccionar una cantidad superior a la solicitada.</p>
             
                
            </FormHelperText>
          ) : null}
        <Box mt={"25px"} display={"flex"} justifyContent={"end"} gap={"15px"}>
       
          <Button variant="contained" color="success" type="submit"  disabled={formikAssignPosition.isSubmitting}>
          {formikAssignPosition.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
          CERRAR
        </Button>
        </Box>
      </Box>}
    </form>:   <Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default AssignPosition;
