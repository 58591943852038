import { gql } from "@apollo/client";
export const GET_PERNOCT = gql`
  query GetPernoct {
    getPernoct {
      id
      dateendpernoct
      datebeginpernoct
      datebegingrute
      place
      project
      state
    }
  }
`;

export const CREATE_PERNOCT = gql`
  mutation CreatePernoct(
    $supervisor: ID
    $project: String
    $place: String
    $datebegingrute: Date
    $datebeginpernoct: Date
    $dateendpernoct: Date
  ) {
    createPernoct(
      supervisor: $supervisor
      project: $project
      place: $place
      datebegingrute: $datebegingrute
      datebeginpernoct: $datebeginpernoct
      dateendpernoct: $dateendpernoct
    ) {
      id
      dateendpernoct
      datebeginpernoct
      datebegingrute
      place
      project
    }
  }
`;

export const GET_PERNOCT_BY_ID = gql`
  query GetPernoctByID($getPernoctByIdId: ID) {
    getPernoctByID(id: $getPernoctByIdId) {
      pernoct {
        id
        dateendpernoct
        datebeginpernoct
        datebegingrute
        place
        project
        supervisor {
          postulant {
            name
            surname
          }
        }
      }
      personalTimes {
        datebegin
        dateend
        personal {
          postulant {
            name
            surname
            doi
          }
        }
      }
    }
  }
`;

export const ASSIGN_PERNOCT = gql`
  mutation AssignPernocte($pernoct: ID, $item: [ItemPernocte]) {
    assignPernocte(pernoct: $pernoct, item: $item) {
      id
      place
      project
      dateendpernoct
      datebeginpernoct
      datebegingrute
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizePernoct {
    getSumarizePernoct {
      amount
      lastdaterute
      lastproyect
      maxpersonal
    }
  }
`;

export const GET_SUPERVISOR_PERSONAL = gql`
  query GetAllSupervisor {
    getAllSupervisor {
      id
      postulant {
        doi
        name
        surname
      }
    }
  }
`;
