import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import ModalUtil from "components/ModalUtil";
import StatBoxOne from "components/StatBoxOne";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CreateSelectionSupplier from "./CreateSelectionSupplier";
import CreateEvaluationSupplier from "./CreateEvaluationSupplier";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GET_CRITERION_SELECTION,
  GET_CRITERION_EVALUATION,
} from "./QueryEvaluationSelection";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import { GET_ALL_SUPPLIER } from "./../provider/QueryProvider";
import { useState } from "react";
import {
  GET_ALL_SELECTION,
  GET_ALL_EVALUATION,
  GET_MAIN_DATA,
} from "./QueryEvaluationSelection";
import ViewEvaluation from "./ViewEvaluation";

import DialogUtil from "components/DialogUtil";
import ViewSelecion from "./ViewSelecion";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForm from "./DeleteForm";
import {
  DELETE_EVALUATION_BY_ID,
  DELETE_SELECTION_BY_ID,
} from "./QueryEvaluationSelection";
import { useUser } from "hooks/useUser";
const ProviderSelectionEvaluation = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [criterionEvaluation, setCriterionEvaluation] = useState([]);
  const [allSupplier, setAllSupplier] = useState([]);
  const [personal, setPersonal] = useState(null);
  const [selection, setSelection] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [newSelection, setnewSelection] = useState(null);
  const [newEvaluation, setnewEvaluation] = useState(null);
  const [criterionSelection, setCriterionSelection] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [modalEvaluation, setmodalEvaluation] = useState(false);
  const [modalSelection, setmodalSelection] = useState(false);
  const { user } = useUser();
  const [idEvaluation, setidEvaluation] = useState(false);
  const [idSelection, setidSelection] = useState(false);

  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const [dialogDeleteEvaluation, setdialogDeleteEvaluation] = useState(false);
  const [dialogDeleteSelection, setdialogDeleteSelection] = useState(false);
  const handleOpenModalDeleteEvaluation = (id) => {
    setdialogDeleteEvaluation(true);
    setidEvaluation(id);
  };

  const handleCloseModalDeleteEvaluation = () => {
    setdialogDeleteEvaluation(false);
  };

  const handleOpenModalDeleteSelection = (id) => {
    setdialogDeleteSelection(true);
    setidSelection(id);
  };

  const handleCloseModalDeleteSelection = () => {
    setdialogDeleteSelection(false);
  };
  const handleOpenModalSelection = (id) => {
    setmodalSelection(true);
    setidSelection(id);
  };
  const handleOpenModalEvaluation = (id) => {
    setmodalEvaluation(true);
    setidEvaluation(id);
  };
  const handleCloseModalSelection = () => {
    setmodalSelection(false);
  };
  const handleCloseModalEvaluation = () => {
    setmodalEvaluation(false);
  };

  const columnsSelection = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "supplier.businessname",
      headerName: "PROVEEDOR",
      flex: 1,
      renderCell: (params) => params.row.supplier.businessname,
    },
    {
      field: "supplier.ruc",
      headerName: "RUC",
      flex: 1,
      renderCell: (params) => params.row.supplier.ruc,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type,
    },
    {
      field: "score",
      headerName: "PUNTAJE",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row?.score,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalSelection(params.row.id)}
          />
          {user.admin ? (
            <DeleteIcon
              color="error"
              onClick={() => handleOpenModalDeleteSelection(params.row.id)}
            />
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsEvaluation = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "supplier.businessname",
      headerName: "PROVEEDOR",
      flex: 1,
      renderCell: (params) => params.row.supplier.businessname,
    },
    {
      field: "supplier.ruc",
      headerName: "RUC",
      flex: 1,
      renderCell: (params) => params.row.supplier.ruc,
    },
    {
      field: "score",
      headerName: "PUNTAJE",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row?.score,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalEvaluation(params.row.id)}
          />
          {user.admin ? (
            <DeleteIcon
              color="error"
              onClick={() => handleOpenModalDeleteEvaluation(params.row.id)}
            />
          ) : null}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_CRITERION_SELECCION = async () => {
      try {
        const { data } = await client.query({
          query: GET_CRITERION_SELECTION,
        });
        setCriterionSelection(data.getCriterionSeleccion);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_SELECTION = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SELECTION,
          fetchPolicy: "network-only",
        });
        setSelection(data.getAllSelection);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_EVALUATION = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_EVALUATION,
          fetchPolicy: "network-only",
        });
        setEvaluation(data.getAllEvaluation);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_CRITERION_EVALUATION = async () => {
      try {
        const { data } = await client.query({
          query: GET_CRITERION_EVALUATION,
        });
        setCriterionEvaluation(data.getCriterionEvaluacion);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_SUPPLIER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SUPPLIER,
          fetchPolicy: "network-only",
        });
        setAllSupplier(data.getAllSuplier);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        console.log(data.getSumarizeEvaluationAndSelection);
        setMainData(data.getSumarizeEvaluationAndSelection);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_SUPPLIER();
    FUNCTION_GET_CRITERION_SELECCION();
    FUNCTION_GET_CRITERION_EVALUATION();
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_GET_ALL_SELECTION();
    FUNCTION_GET_ALL_EVALUATION();
  }, [newSelection, newEvaluation]);
  return (
    <>
      <ModalUtil
        isOpen={modalEvaluation}
        onClose={handleCloseModalEvaluation}
        Component={ViewEvaluation}
        params={null}
        id={idEvaluation}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalSelection}
        onClose={handleCloseModalSelection}
        Component={ViewSelecion}
        params={null}
        id={idSelection}
      ></ModalUtil>

      <DialogUtil
        title={"ELIMINAR EVALUACION"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteEvaluation}
        onClose={handleCloseModalDeleteEvaluation}
        Component={DeleteForm}
        id={idEvaluation}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setnewEvaluation,
            paramsDelete: "deleteEvaluationByIdId",
            mutatitionDelete: DELETE_EVALUATION_BY_ID,
            onClose: handleCloseModalDeleteEvaluation,
          })
        }
      />
      <DialogUtil
        title={"ELIMINAR EVALUACION"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogDeleteSelection}
        onClose={handleCloseModalDeleteSelection}
        Component={DeleteForm}
        id={idSelection}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setnewSelection,
            paramsDelete: "deleteSelectionByIdId",
            mutatitionDelete: DELETE_SELECTION_BY_ID,
            onClose: handleCloseModalDeleteSelection,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header
            title="LOGISTICA"
            subtitle="logistica/seleccion y evaluacion"
          />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBox
            title="Cantidad Total"
            value={mainData?.total}
            /*   increase={mainData?.cargo} */
            description={"Selección y Evaluación"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultima Evaluación"
            value={mainData?.lastEvaluationScore + " score"}
            increase={"Proveedor"}
            description={mainData?.lastEvaluationSupplier}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultima Selección"
            value={mainData?.lastSelectionScore + " score"}
            increase={"Proveedor"}
            description={mainData?.lastSelectionSupplier}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <Box
            gridColumn="span 6"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            backgroundColor={theme.palette.background.alt}
            borderRadius="0.55rem"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              gap={"10px"}
              height={"100%"}
            >
              {" "}
              <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
                LEYENDA
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={"20px"}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "green",
                  }}
                ></div>
                <p style={{ margin: "0px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    PROVEEDOR CON EXCELENTES CARACTERISTICAS (PEX)
                  </span>{" "}
                  igual o mayor a 2.25
                </p>
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={"20px"}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "yellow",
                  }}
                ></div>
                <p style={{ margin: "0px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    PROVEEDOR ACEPTABLE (PAC)
                  </span>{" "}
                  igual o mayor 2 y menor a 2.25
                </p>
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={"20px"}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "red",
                  }}
                ></div>
                <p style={{ margin: "0px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    PROVEEDOR CON MALAS CARACTERISTICAS (PMC)
                  </span>{" "}
                  menor a 2
                </p>
              </Box>
            </Box>
          </Box>
          {/* ROW 1 */}

          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              REALIZAR SELECCIÓN
            </Box>
            <CreateSelectionSupplier
              allSupplier={allSupplier}
              criterionSelection={criterionSelection}
              allpersonal={personal}
              setnewSelection={setnewSelection}
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              REALIZAR EVALUACIÓN
            </Box>
            <CreateEvaluationSupplier
              allSupplier={allSupplier}
              criterionEvaluation={criterionEvaluation}
              allpersonal={personal}
              setnewEvaluation={setnewEvaluation}
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE SELECCIÓN
            </Box>
            <DataGrid
              loading={!selection}
              getRowId={(row) => row.id}
              rows={selection || []}
              columns={columnsSelection}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DE EVALUACIÓN
            </Box>
            <DataGrid
              loading={!evaluation}
              getRowId={(row) => row.id}
              rows={evaluation || []}
              columns={columnsEvaluation}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProviderSelectionEvaluation;
