import React from "react";
import { useState, useEffect } from "react";
import { GET_GROUP_BY_ID } from "./QueryGroup";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import LoopIcon from '@mui/icons-material/Loop';
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
const ViewGroup = ({ id, onClose,params }) => {
  const {setAlertCustom,alertCustom}=params
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [group, setgroup] = useState({});
  useEffect(() => {
    const getClassById = async () => {
      try {
        const { data } = await client.query({
          query: GET_GROUP_BY_ID,
          variables: {
            getGroupByIdId: id,
          },
          fetchPolicy: "network-only",
        });
       
        setgroup(data.getGroupById);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getClassById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap:"5px"
  };
  return (
    visibility ? (
      <>        
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">GRUPO</label>
            <TextField disabled value={group?.description} />
          </Box>        
         {/*  <Box sx={style}>
            <label for="my-input">¿CONSUMIBLE?  </label>
            <TextField disabled value={group?.consumable?"SI":"NO"} />
          </Box>  */}  
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>         
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewGroup;
