import * as Yup from "yup";
export const REQUERIMENT_MAINTANCE_SCHEMA_YUP = Yup.object().shape({
  active: Yup.object().shape({
    label: Yup.string().required("Requiredo"),
    id: Yup.string().required("Requiredo"),
  }),
  personal:Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  priority: Yup.string().required("Requiredo"),
  type: Yup.string().required("Requiredo"),
  date: Yup.date().required("Requiredo"),
  description: Yup.string().required("Requiredo"),
  comment: Yup.string(),
});
