import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StatBoxOne from "components/StatBoxOne";
import StatBox from "components/StatBox";
import { useApolloClient } from "@apollo/client";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";
import ModalUtil from "components/ModalUtil";
import AssignItem from "./AssignItem";
import TemplatePDF from "./TemplatePDF";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import CreateRequeriment from "./CreateRequeriment";
import {
  GET_PRIORITIES,
  GET_ITEM_REQUERIMENT,
  GET_MAIN_DATA,
} from "./QueryItemRequeriment";
import { GET_ALL_ITEMS } from "./../item/QueryItem";
import ViewRequeriment from "./ViewRequeriment";
import { GET_GROUPS } from "./../groupclass/QueryGroup"
import { useLocation } from "react-router-dom";
import { useUser } from "hooks/useUser";
import ViewPdf from "components/ViewPdf";
const RequerimentItem = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const location = useLocation();
  const [personal, setPersonal] = useState([]);
  const [priority, setPriority] = useState([]);
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [newItemRequeriment, setNewItemRequeriment] = useState(null);
  const [items, setItems] = useState([]);
  const { user } = useUser();
  const [mainData, setMainData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [groups, setgroups] = useState([]);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [id, setId] = useState(null);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setrequerimentselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const handleOpenModal = (id) => {
    setModalOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 2,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {params.row.state.description == "ATENDIDO" ? (
            <LocalPrintshopIcon
              onClick={() => handleOpenModalPDF(params.row.id)}
            />
          ) : null}
          {/* <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnsRequerimentApproved = [
    {
      field: "N",
      headerName: "#",
      flex: 0.5,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "code",
      headerName: "CODIGO",
      flex: 2,
      renderCell: (params) => params.row.code,
    },
    {
      field: "priority",
      headerName: "PRIORIDAD",
      flex: 1,
      renderCell: (params) => params.row.priority.description,
    },
    {
      field: "area",
      headerName: "AREA",
      flex: 1,
      renderCell: (params) => params.row.area.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {location.pathname?.split("/")[1] == "l" && user.admin ? (
            <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} />
          ) : null}
          {/*  <TouchAppIcon onClick={() => handleOpenModal(params.row.id)} /> */}
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />

          {/*   <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const getArea = (acro) => {
    const area = {
      gp: "GESTION DE PERSONAS",
      ope: "OPERACIÓN",
      l: "LOGISTICA",
      man: "MANTENIMIENTO",
      seg: "SEGURIDAD"

    };
    return area[acro]
  }
  const getAreaMinimal = (acro) => {
    const area = {
      gp: "personal",
      ope: "operacion",
      l: "logistica",
      man: "mantenimiento",
      seg: "seguridad"
    };
    return area[acro]
  }
  useEffect(() => {
    const FUNCTION_GET_PRIORITIES = async () => {
      try {
        const { data } = await client.query({
          query: GET_PRIORITIES,
        });
        setPriority(data.getPriority);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_ITEMS = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEMS,
          fetchPolicy: "network-only",
        });

        setItems(data.getAllItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_ALL_ITEMS_REQUERIMENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_ITEM_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            acro:
              location.pathname?.split("/")[1] == "l"
                ? "adm"
                : location.pathname?.split("/")[1],
          },
        });
        //console.log(location.pathname?.split("/")[1])
        console.log(data.getItemRequeriment);
        setItemRequeriment(data.getItemRequeriment);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
          variables: {
            area: location.pathname?.split("/")[1]
          },
        });
        setMainData(data.getSumarizeRequerimentActive);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_GROUP = async () => {
      try {
        const { data } = await client.query({
          query: GET_GROUPS,
          fetchPolicy: "network-only",
        });
        setgroups(data.getGroup);
      } catch (error) {
        console.log(error.message);
      }
    };

    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_GET_GROUP();
    FUNCTION_GET_PRIORITIES();
    FUNCTION_GET_ALL_ITEMS();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_ITEMS_REQUERIMENT();
  }, [newItemRequeriment, newAssignRequeriment, location.pathname]);
  return (
    <>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Requerimiento",
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpen}
        onClose={handleCloseModal}
        Component={AssignItem}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModal,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title={getArea(location.pathname.split("/")[1])} subtitle={getAreaMinimal(location.pathname.split("/")[1]) + "/requerimiento"} />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Iniciados"
            value={mainData.begining}
            /*  increase={"Falta alguna asignacion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <StatBox
            title="Total Pendientes"
            value={mainData.pending}
            /*  increase={"Requieren ser aprovados por Adminsitracion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Aprovados"
            value={mainData.aproved}
            /*   increase={"Aprovados por administracion y no atendidos"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}

            >
              HISTORIAL DE REQUERIMIENTOS PENDIENTES
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              rows={
                itemRequeriment?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          {/*  <StatBoxOne          
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />{" "}
          <StatBoxOne           
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          /> */}
          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 6"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box>
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                py="5px"
              >
                CREAR REQUERIMIENTO
              </Box>
              <CreateRequeriment
                alertCustom={alertCustom}
                areacro={location.pathname?.split("/")[1]}
                setAlertCustom={setAlertCustom}
                personal={personal}
                groups={groups}
                items={items}
                priority={priority}
                setNewItemRequeriment={setNewItemRequeriment}
              />
            </Box>
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}

            >
              HISTORIAL DE REQUERIMIENTO INICIADOS / APROBADOS
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              getRowId={(row) => row.id}
              rows={
                itemRequeriment?.filter(
                  (row) =>
                    row.state.description === "INICIADO" ||
                    row.state.description === "APROBADO"
                ) || []
              }
              columns={columnsRequerimentApproved}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}

            >
              HISTORIAL DE REQUERIMIENTO REHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!itemRequeriment}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableSelectionOnClick
              rows={
                itemRequeriment?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RequerimentItem;
