import React, { useState,useRef } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Radio from "@mui/material/Radio";
import { FormHelperText } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import { useUser } from "hooks/useUser";
import { CISTERN_SCHEMA_YUP } from "./YupCistern";
import { CREATE_PREUSE_COMPONENT_CISTERN } from "./QueryCistern";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import dayjs from 'dayjs';
const CreateCisternPreUso = ({
  alertCustom,
  setAlertCustom,
  question,
  personal,
  declaration,
  setNewCisternPreUse,
}) => {
  const { user } = useUser();
  const client = useApolloClient();
  const { dateFormat } = useSettings(); 
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const autocompletepersonalRef = useRef(null);
  const lensize = [0];
  const formikCisternPreUso = useFormik({
    initialValues: {
      driver: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      capacity: "",
      date: new Date(),
      cisternplate: "",
      tractplate: "",
      activity: "",
      kmBegin: "",
      kmEnd: "",
      timeBegin:  dayjs('2022-04-17T15:30'),
      timeEnd:  dayjs('2022-04-17T15:30'),
      declarations: "",
      components: "",
    },
    validationSchema: CISTERN_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const {
        driver,
        capacity,
        date,
        cisternplate,
        tractplate,
        activity,
        kmBegin,
        kmEnd,
        timeBegin,
        timeEnd,
        declarations,
        components,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_PREUSE_COMPONENT_CISTERN,
          variables: {
            driver:driver.id,
            capacity: Number(capacity),
            date,
            cisternplate: cisternplate.trim().toUpperCase(),
            tractplate: tractplate.trim().toUpperCase(),
            activity: activity.trim().toUpperCase(),
            kmBegin: Number(kmBegin),
            kmEnd: Number(kmEnd),
            timeBegin,
            timeEnd,
            declarations: declarations,
            components: components,
          },
        });

        setNewCisternPreUse(response.createPreUseCistern);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if(user.admin){
          autocompletepersonalRef.current.value=""
        }
        
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikCisternPreUso.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
          mb={"20px"}
        >
         {user.admin? <Box>
            {personal?.length ? (
              <Autocomplete
                freeSolo
                id="driver"
                name="driver"
                disableClearable
                ref={autocompletepersonalRef}
                value={formikCisternPreUso.values.driver.label}
                options={personal?.map(
                  (option) =>
                    new Object({
                      label:                   
                        option.postulant.name +
                        "  " +                 
                        option.postulant.surname +
                        " / " +
                        "dni:" +
                        option.postulant.doi,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikCisternPreUso.setFieldValue("driver", {
                    label: newValue.label,
                    id: newValue.value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar conductor por nombre/apellido/dni"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={
                      formikCisternPreUso.touched.driver?.id &&
                      Boolean(formikCisternPreUso.errors.driver)
                    }
                    helperText={
                      formikCisternPreUso.touched.driver?.id &&
                      formikCisternPreUso.errors.driver?.id
                    }
                  />
                )}
              />
            ):(
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>:null}
          <TextField
            id="activity"
            name="activity"
            label="ACTIVIDAD"
            variant="outlined"
            value={formikCisternPreUso.values.activity}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.activity &&
              Boolean(formikCisternPreUso.errors.activity)
            }
            helperText={
              formikCisternPreUso.touched.activity &&
              formikCisternPreUso.errors.activity
            }
          />
        </Box>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="20px">
          <TextField
            id="capacity"
            name="capacity"
            label="CAPACIDAD"
            variant="outlined"
            value={formikCisternPreUso.values.capacity}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.capacity &&
              Boolean(formikCisternPreUso.errors.capacity)
            }
            helperText={
              formikCisternPreUso.touched.capacity &&
              formikCisternPreUso.errors.capacity
            }
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikCisternPreUso.values.date}
              id="date"
              name="date"
              label="FECHA"
              format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
              onChange={(val) => {
                formikCisternPreUso.setFieldValue("date", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <TextField
            id="tractplate"
            name="tractplate"
            label="PLACA DE TRACTO"
            variant="outlined"
            value={formikCisternPreUso.values.tractplate}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.tractplate &&
              Boolean(formikCisternPreUso.errors.tractplate)
            }
            helperText={
              formikCisternPreUso.touched.tractplate &&
              formikCisternPreUso.errors.tractplate
            }
          />
          <TextField
            id="cisternplate"
            name="cisternplate"
            label="PLACA DE CISTERNA"
            variant="outlined"
            value={formikCisternPreUso.values.cisternplate}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.cisternplate &&
              Boolean(formikCisternPreUso.errors.cisternplate)
            }
            helperText={
              formikCisternPreUso.touched.cisternplate &&
              formikCisternPreUso.errors.cisternplate
            }
          />

          <TextField
            id="kmBegin"
            name="kmBegin"
            label="KM INICIAL"
            variant="outlined"
            value={formikCisternPreUso.values.kmBegin}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.kmBegin &&
              Boolean(formikCisternPreUso.errors.kmBegin)
            }
            helperText={
              formikCisternPreUso.touched.kmBegin &&
              formikCisternPreUso.errors.kmBegin
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              id="timeBegin"
              label="HORARIO INICIO"
              name="timeBegin"
              value={formikCisternPreUso.values.timeBegin}
              onChange={(val) => {
                formikCisternPreUso.setFieldValue("timeBegin", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <TextField
            id="kmEnd"
            name="kmEnd"
            label="KM FINAL"
            variant="outlined"
            value={formikCisternPreUso.values.kmEnd}
            onChange={formikCisternPreUso.handleChange}
            error={
              formikCisternPreUso.touched.kmEnd &&
              Boolean(formikCisternPreUso.errors.kmEnd)
            }
            helperText={
              formikCisternPreUso.touched.kmEnd &&
              formikCisternPreUso.errors.kmEnd
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              id="timeEnd"
              label="HORARIO FINAL"
              name="timeEnd"
              value={formikCisternPreUso.values.timeEnd}
              onChange={(val) => {
                formikCisternPreUso.setFieldValue("timeEnd", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 450, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Si</TableCell>
                  <TableCell align="">No</TableCell>
                  <TableCell align="">Observaciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {declaration.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikCisternPreUso.values.declarations?.[index]
                            ?.answer === true
                        }
                           sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }}   
                        onChange={(e) => {
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].answer`,
                            Boolean(e.target.value)
                          );
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        value={true}
                        name={`declarations[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikCisternPreUso.values.declarations?.[index]
                            ?.answer === false
                        }
                           sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }}   
                        onChange={(e) => {
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].answer`,
                            Boolean(!e.target.value)
                          );
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        value={false}
                        name={`declarations[${index}].question`}
                      />
                    </TableCell>
                    <TableCell align="">
                      <TextField
                        name={`declarations[${index}].observation`}
                        variant="outlined"
                        value={
                          formikCisternPreUso.values.declarations?.[index]
                            ?.observation
                        }
                        onChange={(e) => {
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].observation`,
                            e.target.value
                          );
                          formikCisternPreUso.setFieldValue(
                            `declarations[${index}].question`,
                            row.id
                          );
                        }}
                        /*      error={
                          formikCisternPreUso.touched.declarations?.[index]
                            ?.observation &&
                          Boolean(
                            formikCisternPreUso.errors.declarations?.[index]
                              ?.observation
                          )
                        }
                        helperText={
                          formikCisternPreUso.touched.declarations?.[index]
                            ?.observation &&
                          formikCisternPreUso.errors.declarations?.[index]
                            ?.observation
                        } */
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {formikCisternPreUso.touched.declarations &&
          formikCisternPreUso.errors.declarations?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Verica los campos esten requeridos
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Divider />
        <Box my={"20px"}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {question.map((el, index) => {
                  lensize.push(lensize[lensize.length - 1] + el.element.length);
                  return <Tab label={el.category} value={index} />;
                })}
              </TabList>
            </Box>
            {question.map((el, index) => (
              <TabPanel value={index}>
                {" "}
                <TableContainer style={{ maxHeight: 500, overflowY: "auto" }}>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="">N°</TableCell>
                        <TableCell align="">Criterio</TableCell>
                        <TableCell align="">Si</TableCell>
                        <TableCell align="">No</TableCell>
                        <TableCell align="">Observaciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {el.element?.map((row, idx) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="">{idx + 1}</TableCell>
                          <TableCell align="">{row.description}</TableCell>
                          <TableCell align="">
                            {" "}
                            <Radio
                              checked={
                                formikCisternPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.answer == true
                              }
                                 sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }}   
                              onChange={(e) => {
                                formikCisternPreUso.setFieldValue(
                                  `components[${lensize[index] + idx}].answer`,
                                  Boolean(e.target.value)
                                );
                                formikCisternPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              value={true}
                              name={`components[${
                                lensize[index] + idx
                              }].question`}
                            />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Radio
                              checked={
                                formikCisternPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.answer == false
                              }
                                 sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },                         
                        }}   
                              onChange={(e) => {
                                formikCisternPreUso.setFieldValue(
                                  `components[${lensize[index] + idx}].answer`,
                                  Boolean(!e.target.value)
                                );
                                formikCisternPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              value={false}
                              name={`components[${
                                lensize[index] + idx
                              }].question`}
                            />
                          </TableCell>
                          <TableCell align="">
                            <TextField
                              name={`components[${
                                lensize[index] + idx
                              }].observation`}
                              variant="outlined"
                              value={
                                formikCisternPreUso.values.components?.[
                                  lensize[index] + idx
                                ]?.observation
                              }
                              onChange={(e) => {
                                formikCisternPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].observation`,
                                  e.target.value
                                );
                                formikCisternPreUso.setFieldValue(
                                  `components[${
                                    lensize[index] + idx
                                  }].question`,
                                  row.id
                                );
                              }}
                              /*    error={
                                formikCisternPreUso.touched.components?.[
                                  lensize[index] + idx
                                ]?.observation &&
                                Boolean(
                                  formikCisternPreUso.errors.components?.[
                                    lensize[index] + idx
                                  ]?.observation
                                )
                              }
                              helperText={
                                formikCisternPreUso.touched.components?.[
                                  lensize[index] + idx
                                ]?.observation &&
                                formikCisternPreUso.errors.components?.[
                                  lensize[index] + idx
                                ]?.observation
                              } */
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </TabContext>
          {formikCisternPreUso.touched.components &&
          formikCisternPreUso.errors.components?.length ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                Verica los campos esten requeridos
              </p>
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="contained" color="success" type="submit"disabled={formikCisternPreUso.isSubmitting} >
          {formikCisternPreUso.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>{" "}
    </form>
  );
};

export default CreateCisternPreUso;
