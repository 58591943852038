import { Box, Grid, IconButton, Modal, useTheme } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import FlexBetween from "components/FlexBetween"
import Header from "components/Header"
import { useState } from "react"
import UserInfo from "./UserInfo";
import ImageUserForm from "./ImageUserForm";


const ProfileUser = () => {

  const theme = useTheme()
  const [open, setOpen] = useState(false)

  return (
    <Box
      m="1.5rem 2.5rem"
    >
      <FlexBetween>
        <Header title="PERFIL DEL USUARIO" subtitle="config/profile" />
      </FlexBetween>

      <Grid component='form' container spacing={3} mt='1.5rem'>
       {/*  <Grid item xs={5}>
          {
            open ? (
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ImageUserForm setOpen={setOpen} />
              </Modal>
            ) : <></>
          }
          <Box
            bgcolor="white"
            borderRadius={2}
            p='1.5rem'
            sx={{ position: 'relative' }}
          >
            <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='13rem' >
            

              <IconButton
                onClick={() => setOpen(true)}
                aria-label="edit"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
              >
                <EditIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.secondary[300]
                  }}
                />
              </IconButton>

            </Box>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <UserInfo />
        </Grid>

      </Grid>

    </Box>
  )
}

export default ProfileUser