import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import { DateFormatting } from "utils/DateFormatting";
import { useSettings } from "hooks/useSettings";
import { GET_TRAINING_BY_ID } from "./QueryTraining";
const ViewTraining = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  let sumFormation = 0;
  let sumOther = 0;
  const [visibility, setvisibility] = useState(false);
  const { currency, dateFormat } = useSettings();
  const [training, setTraining] = useState([]);
  useEffect(() => {
    const getTraining = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_TRAINING_BY_ID,
          variables: {
            getTrainingByIdId: id,
          },
        });
        console.log(data.getTrainingById);
        setTraining(data.getTrainingById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getTraining(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">TEMA</label>
            <TextField disabled value={training?.subject} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={training?.type.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">OBJETIVO</label>
            <TextField disabled value={training?.goal} />
          </Box>
          <Box sx={style}>
            <label for="my-input">HORA DE INICIO</label>
            <TextField disabled value={training?.beginTime} />
          </Box>
          <Box sx={style}>
            <label for="my-input">HORA DE FIN</label>
            <TextField disabled value={training?.endTime} />
          </Box>
          <Box sx={style}>
            <label for="my-input">EMPRESA</label>
            <TextField disabled value={training?.company} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField disabled value={DateFormatting(training?.date,dateFormat)} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">OBSERVACIONES</label>
            <TextField disabled value={training?.observation} />
          </Box>
          <Box sx={style}>
            <label for="my-input">ACUERDOS</label>
            <TextField disabled value={training?.aggrement} />
          </Box>
        </Box>
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 300, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">NOMBRE</TableCell>
                  <TableCell align="">APELLIDO</TableCell>
                  <TableCell align="">DNI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {training.asistance.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">{row?.postulant?.name}</TableCell>
                      <TableCell align="">{row?.postulant?.surname}</TableCell>
                      <TableCell align="">{row?.postulant?.doi}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewTraining;
