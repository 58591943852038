import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import ViewIndicators from "./ViewIndicators";
import IndicatorsChart from "components/IndicatorsChart";
import {
  GET_INDICATORS_REPORT,
  GET_INDICATORS_ALL_REPORT,
} from "./QueryIndicators";
import { GET_ALL_ITEMS_VEHICLE } from "./../../logistic/item/QueryItem";
import { GET_ACTIVES_BY_ITEM_ID } from "./../../operation/report/QueryReport";
import ViewIndicatorMain from "./ViewIndicatorMain";
import IndicatorsMain from "components/IndicatorsMain";
import { GET_ALL_ITEM_ONLY_VEHICLE } from "./../../logistic/item/QueryItem";
import IndicartorsBar from "components/IndicartorsBar";
const Indicators = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [yearIndicators, setyearIndicators] = useState(
    new Date().getFullYear()
  );
  const [yearIndicatorsMain, setyearIndicatorsMain] = useState(
    new Date().getFullYear()
  );
  const [monthIndicators, setmonthIndicators] = useState(
    new Date().getMonth() + 1
  );

  const [monthIndicatorsMain, setmonthIndicatorsMain] = useState(
    new Date().getMonth() + 1
  );
  const [activeIndicators, setactiveIndicators] = useState(-1);

  const [activeIndicatorsMain, setactiveIndicatorsMain] = useState(-1);
  const [itemIndicators, setitemIndicators] = useState(-1);
  const [itemIndicatorsMain, setitemIndicatorsMain] = useState(-1);
  const [itemsVehicle, setAllItemsVehicle] = useState([]);

  const [indicatorReport, setindicatorReport] = useState(null);

  const [indicatorAllReport, setindicatorAllReport] = useState(null);

  const [actives, setActives] = useState([]);
  const [activesmain, setActivesmain] = useState([]);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  useEffect(() => {
    const FUNCTION_GET_ALL_ITEMS_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEM_ONLY_VEHICLE,
          fetchPolicy: "network-only",
        });
        setAllItemsVehicle(data.getAllItemOnlyVehicle);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_INDICATORS_REPORT = async () => {
      try {
        const { data } = await client.query({
          query: GET_INDICATORS_REPORT,
          variables: {
            year: yearIndicators,
            item: itemIndicators,
            month: monthIndicators,
            active: activeIndicators,
            all: activeIndicators == -1 ? true : false,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getIndicatorsSeriesReport);
        setindicatorReport(data.getIndicatorsSeriesReport);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_INDICATORS_ALL_REPORT = async () => {
      try {
        const { data } = await client.query({
          query: GET_INDICATORS_ALL_REPORT,
          variables: {
            year: yearIndicatorsMain,
            item: itemIndicatorsMain,
            month: monthIndicatorsMain,
            active: activeIndicatorsMain,
            all: activeIndicatorsMain == -1 ? true : false,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getIndicatorDisponibility);
        setindicatorAllReport(data.getIndicatorDisponibility);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_ACTIVE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVES_BY_ITEM_ID,
          variables: {
            getActiveByItemIdId: itemIndicators,
          },
          fetchPolicy: "network-only",
        });
        setActives(data.getActiveByItemId);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ACTIVE_MAIN = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVES_BY_ITEM_ID,
          variables: {
            getActiveByItemIdId: itemIndicatorsMain,
          },
          fetchPolicy: "network-only",
        });
        setActivesmain(data.getActiveByItemId);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_ITEMS_VEHICLE();
    FUNCTION_GET_ACTIVE_MAIN()
    FUNCTION_GET_INDICATORS_REPORT();
    FUNCTION_GET_INDICATORS_ALL_REPORT();
    FUNCTION_GET_ACTIVE();
  }, [itemIndicators, yearIndicators, monthIndicators, activeIndicators,itemIndicatorsMain,yearIndicatorsMain,monthIndicatorsMain,activeIndicatorsMain]);
  return (
    <>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="MANTENIMIENTO" subtitle="mantenimiento/indicadores" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              py={"10px"}
            >
              GRÁFICA DE CONTROLADORES
            </Box>
            <Box pb={"25px"}>
              <ViewIndicators
                items={itemsVehicle}
                itemIndicators={itemIndicators}
                yearIndicators={yearIndicators}
                monthIndicators={monthIndicators}
                setItemIndicators={setitemIndicators}
                setYearIndicators={setyearIndicators}
                setMonthIndicators={setmonthIndicators}
                actives={actives}
                activeIndicators={activeIndicators}
                setActiveIndicators={setactiveIndicators}
              />
            </Box>
            {/*  <IndicatorsChart data={indicatorReport} /> */}
            {indicatorReport ? (
              <IndicatorsChart data={indicatorReport} />
            ) : (
              <Box textAlign={"center"}>No hay datos</Box>
            )}
          </Box>

          <Box
            gridColumn="span 12"
            gridRow="span 7"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}             
            >
              GRÁFICA DE INDICADORES
            </Box>
            <Box pb={"25px"}>
              <ViewIndicatorMain
                items={itemsVehicle}
                itemIndicators={itemIndicatorsMain}
                yearIndicators={yearIndicatorsMain}
                monthIndicators={monthIndicatorsMain}
                setItemIndicators={setitemIndicatorsMain}
                setYearIndicators={setyearIndicatorsMain}
                setMonthIndicators={setmonthIndicatorsMain}
                actives={activesmain}
                activeIndicators={activeIndicatorsMain}
                setActiveIndicators={setactiveIndicatorsMain}
              />
            </Box>
            
            {indicatorAllReport?.disponibility ? (<>
              <Box textAlign={"center"}>DISPONIBILIDAD</Box>
              <IndicatorsMain data={indicatorAllReport.disponibility} month={monthIndicatorsMain} /></>
            ) : (
              <Box textAlign={"center"}>No hay datos</Box>
            )}
               
{indicatorAllReport?.mtbfandmttr ? (<><Box textAlign={"center"}>MTBF y MTTR</Box>
              <IndicatorsMain data={indicatorAllReport.mtbfandmttr} month={monthIndicatorsMain} /></> 
            ) : (
              <Box textAlign={"center"}>No hay datos</Box>
            )}
            <Box  mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px">
            {indicatorAllReport?.avgdisponibility ? (
              <IndicartorsBar data={indicatorAllReport.avgdisponibility} categories={["DISPONIBILIDAD"]} />
            ) : (
              <Box textAlign={"center"}>No hay datos</Box>
            )}
              {indicatorAllReport?.avgmtbfandmttr ? (
              <IndicartorsBar data={indicatorAllReport.avgmtbfandmttr} categories={["MTBF", "MTTR"]} />
            ) : (
              <Box textAlign={"center"}>No hay datos</Box>
            )}
            </Box>


          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Indicators;
