import React, { useState, useRef } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import LoopIcon from '@mui/icons-material/Loop';
import { PERSONAL_SCHEMA_YUP } from "../FichaPersonal/YupValidation";
import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
  CREATE_PERSONAL,
} from "../FichaPersonal/QueryPersonal";
import Autocomplete from "@mui/material/Autocomplete";
import Alerts from "components/Alerts";
const CreatePersonal = ({
  alertCustom,
  setAlertCustom,
  departament,
  civilstatus,
  gender,
  contexture,
  postulant,
  setNewPersonal,
}) => {
  const client = useApolloClient();
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const autocompleteRef = useRef(null);
  const formikPersonal = useFormik({
    initialValues: {
      postulant: {
        label: "",
        id: "",
      },
      departament: "",
      province: "",
      district: "",
      address: "",
      civilstatus: "",
      gender: "",
      stature: "",
      contexture: "",
      dad: "",
      mom: "",
    },
    validationSchema: PERSONAL_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm, setValues }) => {
      setSubmitting(true);
      const {
        postulant,
        address,
        civilstatus,
        contexture,     
        departament,
        district,
        gender,        
        province,
        stature,
      } = values;
    
      try {
        const response = await client.mutate({
          mutation: CREATE_PERSONAL,
          variables: {
            address: address.toUpperCase(),
            civilstatus,
            contexture:contexture?contexture:null,
           
            departament,
            district,
            gender,
          
            postulant: postulant.id,
            province,
            stature: stature?parseFloat(stature):null,
          },
        });

        setNewPersonal(response.data.createPersonal);

        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        autocompleteRef.current.value = "";
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
      });
      console.log(response.data);
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };

  return (
    <form onSubmit={formikPersonal.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        {postulant.length ? (
          <Autocomplete
            id="postulant"
            ref={autocompleteRef}
            disableClearable
            value={formikPersonal.values.postulant.label}
            options={postulant?.map(
              (option) =>
                new Object({
                  label:           
                    option.name +
                    " " +           
                    option.surname +
                    " / " +
                    "dni:" +
                    option.doi,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikPersonal.setFieldValue("postulant", {
                label:newValue.label,
                id:newValue.value});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre/apellido/dni"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                name={"postulant"}
                error={
                  formikPersonal.touched.postulant?.id &&
                  Boolean(formikPersonal.errors.postulant?.id)
                }
                helperText={
                  formikPersonal.touched.postulant?.id &&
                  formikPersonal.errors.postulant?.id
                }
              />
            )}
          />
        ) : (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
         {formikPersonal.touched.postulant?.id && formikPersonal.errors.postulant?.id ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
               <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            > {formikPersonal.touched.postulant?.id &&
              formikPersonal.errors.postulant?.id}</p>
             
                
            </FormHelperText>
          ) : null}
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DEPARTAMENTO</InputLabel>
          <Select
            id="departament"
            value={formikPersonal.values.departament}
            name="departament"
            onChange={(e) => {
              formikPersonal.setFieldValue("departament", e.target.value);
              handleProvince(e.target.value);
            }}
            label="DEPARTAMENTO"
            error={
              formikPersonal.touched.departament &&
              Boolean(formikPersonal.errors.departament)
            }
          >
            {departament?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.departament &&
          formikPersonal.errors.departament ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.departament &&
                formikPersonal.errors.departament}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PROVINCIA</InputLabel>
          <Select
            id="province"
            value={formikPersonal.values.province}
            name="province"
            label="PROVINCIA"
            onChange={(e) => {
              formikPersonal.setFieldValue("province", e.target.value);
              handleDistrict(e.target.value);
            }}
            error={
              formikPersonal.touched.province &&
              Boolean(formikPersonal.errors.province)
            }
          >
            {province?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.province && formikPersonal.errors.province ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.province &&
                formikPersonal.errors.province}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DISTRITO</InputLabel>
          <Select
            id="district"
            value={formikPersonal.values.district}
            name="district"
            label="DISTRITO"
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.district &&
              Boolean(formikPersonal.errors.district)
            }
          >
            {district?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.district && formikPersonal.errors.district ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.district &&
                formikPersonal.errors.district}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="address"
          name="address"
          label="DIRECCION"
          variant="outlined"
          value={formikPersonal.values.address}
          onChange={formikPersonal.handleChange}
          error={
            formikPersonal.touched.address &&
            Boolean(formikPersonal.errors.address)
          }
          helperText={
            formikPersonal.touched.address && formikPersonal.errors.address
          }
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ESTADO CIVIL</InputLabel>
          <Select
            id="civilstatus"
            value={formikPersonal.values.civilstatus}
            name="civilstatus"
            label="ESTADO CIVIL"
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.civilstatus &&
              Boolean(formikPersonal.errors.civilstatus)
            }
          >
            {civilstatus?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.civilstatus &&
          formikPersonal.errors.civilstatus ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.civilstatus &&
                formikPersonal.errors.civilstatus}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">GENERO</InputLabel>
          <Select
            id="gender"
            value={formikPersonal.values.gender}
            name="gender"
            label="GENERO"
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.gender &&
              Boolean(formikPersonal.errors.gender)
            }
          >
            {gender?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.gender && formikPersonal.errors.gender ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.gender && formikPersonal.errors.gender}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="stature"
          name="stature"
          label="ESTATURA"
          variant="outlined"
          value={formikPersonal.values.stature}
          onChange={formikPersonal.handleChange}
          error={
            formikPersonal.touched.stature &&
            Boolean(formikPersonal.errors.stature)
          }
          helperText={
            formikPersonal.touched.stature && formikPersonal.errors.stature
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-name-label">CONTEXTURA</InputLabel>
          <Select
            labelId="contexture"
            id="contexture"
            name="contexture"
            value={formikPersonal.values.contexture}          
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.contexture &&
              Boolean(formikPersonal.errors.contexture)
            }
            label="CONTEXTURA"
          >
            {contexture?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikPersonal.touched.contexture &&
          formikPersonal.errors.contexture ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.contexture &&
                formikPersonal.errors.contexture}
            </FormHelperText>
          ) : null}
        </FormControl>

        {/*    <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">¿PADRE VIVE?</InputLabel>
          <Select
            id="dad"
            value={formikPersonal.values.dad}
            name="dad"
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.dad && Boolean(formikPersonal.errors.dad)
            }
          >
            <MenuItem key={true} value={true}>
              SI
            </MenuItem>
            <MenuItem key={false} value={false}>
              NO
            </MenuItem>
          </Select>
          {formikPersonal.touched.dad && formikPersonal.errors.dad ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.dad && formikPersonal.errors.dad}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">¿MADRE VIVE?</InputLabel>
          <Select
            id="mom"
            value={formikPersonal.values.mom}
            name="mom"
            onChange={formikPersonal.handleChange}
            error={
              formikPersonal.touched.mom && Boolean(formikPersonal.errors.mom)
            }
          >
            <MenuItem key={true} value={true}>
              SI
            </MenuItem>
            <MenuItem key={false} value={false}>
              NO
            </MenuItem>
          </Select>
          {formikPersonal.touched.mom && formikPersonal.errors.mom ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPersonal.touched.mom && formikPersonal.errors.mom}
            </FormHelperText>
          ) : null}
        </FormControl> */}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"5px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikPersonal.isSubmitting}>
        {formikPersonal.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreatePersonal;
