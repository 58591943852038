import React, { useState, useEffect, useMemo } from "react";
import { GET_TRAINING_BY_ID } from "./QueryTraining";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
import moment from "moment"
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  /* section: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    fontSize: "10px",
    border: "1px solid #000",
    paddingVertical: "5px",
  },
  subtitle: {
    paddingVertical: "2px",
    flexGrow: 1,
    paddingLeft: "10px",
    fontWeight: 900,
    fontSize: "10px",
  },
  text: {
    paddingVertical: "2px",
    flexGrow: 1,
    paddingLeft: "10px",
    fontSize: "10px",
  },
 
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    fontSize: "9px",
    paddingVertical: "5px",
    flexGrow: 1, // Ocupar el ancho disponible
  },
  cell: {
    backgroundColor: "#f2f2f2",
    color: "#fff",
  },
  cellSign: {
    paddingVertical: "15px",
  },
  p: {
    fontSize: "9px",
    paddingVertical: "2px",
    paddingHorizontal: "5px",
  },
  textCenter: {
    textAlign: "center",
  },
  box: {
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  }, */
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  tableColumn: {
    flexDirection: "column",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  column: {
    flexDirection: "column", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    paddingLeft: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellFlex: {
    flex: 1,
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});

const PDFTraining = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [training, setTraining] = useState([]);
  const calcdifftime = (tiempo1, tiempo2) => {
    const moment1 = moment(new Date(tiempo1));
    const moment2 = moment(new Date(tiempo2));

    // Calcula la diferencia en minutos
    const diferenciaEnMinutos = moment2.diff(moment1, "minutes");

    // Convierte la diferencia en minutos a horas y minutos
    const horas = Math.floor(diferenciaEnMinutos / 60);
    const minutos = diferenciaEnMinutos % 60;

    return horas + ":" + minutos + ":00";
  };
  useEffect(() => {
    const getTraining = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_TRAINING_BY_ID,
          variables: {
            getTrainingByIdId: id,
          },
        });
        console.log(data.getTrainingById);
        setTraining(data.getTrainingById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };

    getTraining(id);
    setvisibility(true);
  }, []);
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>REGISTRO</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>TIPO DE REGISTRO</Text>
          </View>
          {/*     <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>REUNION TRABAJO:</Text>
              <Text style={[styles.text, styles.cell]}>CAPACITACIÓN:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>INDUCCIÓN:</Text>
              <Text style={[styles.text, styles.cell]}>CHARLA 5 MINUTOS:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>REUNIÓN CLIENTES:</Text>
              <Text style={[styles.text, styles.cell]}>OTROS:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
          </View> */}
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              TIPO:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {training.type?.description}{" "}
            </Text>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>ORGANIZADO POR:</Text>
          </View>
          {/*  <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>SSOMA:</Text>
              <Text style={[styles.text, styles.cell]}>OPERACIONES:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>G.PERSONAS:</Text>
              <Text style={[styles.text, styles.cell]}>ADMINISTRACIÓN:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>MANTENIMIENTO:</Text>
              <Text style={[styles.text, styles.cell]}>LOGISTICA:</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
            </View>
          </View> */}
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              AREA:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {" "}
              {training.area?.description}{" "}
            </Text>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>INFORMACIÓN GENERAL:</Text>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                TEMAS:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.subject}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                EXPOSITOR:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.personal?.postulant?.name +
                  " " +
                  training.personal?.postulant?.surname}{" "}
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                EMPRESA DE EXPOSITOR:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.company}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                FECHA:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {new Date(training.date).toLocaleDateString("es-ES")}{" "}
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                HORA INICIO:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {new Date(training.beginTime).toLocaleTimeString("es-ES")}{" "}
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                HORA FINAL:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {new Date(training.endTime).toLocaleTimeString("es-ES")}{" "}
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                TOTAL DE HORAS:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {calcdifftime( training.beginTime,training.endTime)}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                OBJETIVOS:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.goal}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                MATERIALES USADOS:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.tools}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                LUGAR DE CAPACITACIÓN:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.place}{" "}
              </Text>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>PARTICIPANTES:</Text>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, , { flex: 1 }]}>N°:</Text>
              <Text style={[styles.text, styles.cell, { flex: 3 }]}> DNI</Text>
              <Text style={[styles.text, styles.cell, { flex: 5 }]}>
                {" "}
                NOMBRES Y APELLIDOS
              </Text>
              <Text style={[styles.text, styles.cell, { flex: 3 }]}>
                {" "}
                FIRMA
              </Text>
            </View>
            {training.asistance?.map((el, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.text, styles.cell, { flex: 1 }]}>
                  {index + 1}
                </Text>
                <Text style={[styles.text, styles.cell, { flex: 3 }]}>
                  {el.postulant?.doi}
                </Text>
                <Text style={[styles.text, styles.cell, { flex: 5 }]}>
                  {el.postulant?.name + " " + el.postulant?.surname}
                </Text>
                <Text style={[styles.text, styles.cell, { flex: 3 }]}>{}</Text>
              </View>
            ))}
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                OBSERVACIONES:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.observation}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                ACUERDOS Y COMPROMISOS:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                {training.aggrement}
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                RESPONSABLE DE CAPACITACIÓN
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default PDFTraining;
