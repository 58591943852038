import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import LoopIcon from "@mui/icons-material/Loop";
import TextField from "@mui/material/TextField";
import { GROUP_SCHEMA_YUP } from "./YupValidation";
import { useApolloClient } from "@apollo/client";
import { CREATE_GROUP_MUTATION } from "./QueryGroup";
import Alerts from "components/Alerts";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
const CreateGroup = ({ alertCustom, setAlertCustom, setNewGroup }) => {
  const client = useApolloClient();
  const formikGroup = useFormik({
    initialValues: {
      description: "",
      consumable: "",
    },
    validationSchema: GROUP_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {    
     setSubmitting(true);
      const { description, consumable } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_GROUP_MUTATION,
          variables: {
            description: description.toUpperCase(),
            consumable: consumable,
          },
        });
        setNewGroup(response.data.createGroup);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      } 
    },
  });
  return (
    <form onSubmit={formikGroup.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
          CREAR GRUPO
        </Box>
        <Box
          mt="10px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <TextField
            id="description"
            name="description"
            label="GRUPO"
            variant="outlined"
            value={formikGroup.values.description}
            onChange={formikGroup.handleChange}
            error={
              formikGroup.touched.description &&
              Boolean(formikGroup.errors.description)
            }
            helperText={
              formikGroup.touched.description && formikGroup.errors.description
            }
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">¿CONSUMIBLE?</InputLabel>
            <Select
              id="consumable"
              value={formikGroup.values.consumable}
              name="consumable"
              label="¿CONSUMIBLE?"
              onChange={formikGroup.handleChange}
              error={
                formikGroup.touched.consumable &&
                Boolean(formikGroup.errors.consumable)
              }
            >
              <MenuItem key={true} value={true}>
                SI
              </MenuItem>
              <MenuItem key={false} value={false}>
                NO
              </MenuItem>
            </Select>
            {formikGroup.touched.consumable && formikGroup.errors.consumable ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikGroup.touched.consumable &&
                  formikGroup.errors.consumable}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"15px"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikGroup.isSubmitting}
          >
            {formikGroup.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateGroup;
