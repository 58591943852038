import React from "react";
import { ACTIVE_SCHEMA_YUP } from "./YupValidation";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid,   GridToolbar,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import LoopIcon from '@mui/icons-material/Loop';
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { CREATE_ACTIVE_MUTATION, GET_BUY_ORDER_ITEMS } from "./QueryItem";
import { useState } from "react";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
const AddActive = ({ id, params }) => {
  const {
    condition,
    unity,
    buyorder,
    setAlertCustom,
    alertCustom,
    setNewActive,
    handleCloseModalAdd,
  } = params;
  const [visibility, setvisibility] = useState(false);
  const [buyordersitems, setbuyordersitems] = useState(null);
  const { dateFormat } = useSettings();  
  const columns = [
    /* {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    }, */
    {
      field: "item.description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.item.description,
    },
    {
      field: "item.active",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.item.active,
    },
    {
      field: "current",
      headerName: "ACTUAL",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row.current,
    },
    {
      field: "amount",
      headerName: "CANTIDAD",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row.amount,
    },
    {
      field: "complete",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => (params.row.complete ? "SI" : "NO"),
    },
  ];
  const client = useApolloClient();
  const today = new Date();
  const formikActive = useFormik({
    initialValues: {
      item: id,
      condition: "",
      guarante: "",
      beginguarante: new Date(today),
      amount: 1,
      brand: "",
      plate: "",
      dateadquisition: new Date(today),
      parte: "",
      serie: "",
      buyorder: "",
      checkbuyorder: "",
      itembuyorder: [],
    },
    validationSchema: ACTIVE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const {
        item,
        condition,
        guarante,
        beginguarante,
        amount,
        brand,
        plate,
        dateadquisition,
        parte,
        serie,
        buyorder,
        itembuyorder,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_ACTIVE_MUTATION,
          variables: {
            item,
            condition,
            guarante: Number(guarante),
            beginguarante,
            amount:Number(amount),
            brand: brand.toUpperCase(),
            plate: plate.toUpperCase(),
            dateadquisition,
            parte: parte.toUpperCase(),
            serie: serie.toUpperCase(),
            buyorder: buyorder ? buyorder : null,
            itemforbuyorder: itembuyorder[0],
          },
        });
     
        setNewActive(response.data.createActive);
        handleCloseModalAdd();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
       
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleBuyOrder = async (idx) => {
    try {
      const { data } = await client.query({
        query: GET_BUY_ORDER_ITEMS,
        fetchPolicy: "network-only",
        variables: {
          getBuyOrderItemByOrderIdId: idx,
        },
      });   
      setbuyordersitems(data.getBuyOrderItemByOrderId);
    /*   setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      }); */
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (
    <form onSubmit={formikActive.handleSubmit}>
      <Box mb={"20px"}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            PERTENECE A UNA ORDEN DE COMPRA
          </InputLabel>
          <Select
            id="checkbuyorder"
            value={formikActive.values.checkbuyorder}
            name="checkbuyorder"
            onChange={formikActive.handleChange}
            sx={{
              border:"1px solid rgba(0, 0, 0, 0.23)",            
            }}   
            error={
              formikActive.touched.checkbuyorder &&
              Boolean(formikActive.errors.checkbuyorder)
            }
          >
            <MenuItem key={false} value={false}>
              {"NO"}
            </MenuItem>
            <MenuItem key={true} value={true}>
              {"SI"}
            </MenuItem>
          </Select>
          {formikActive.touched.checkbuyorder &&
          formikActive.errors.checkbuyorder ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikActive.touched.checkbuyorder &&
                formikActive.errors.checkbuyorder}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      {formikActive.values.checkbuyorder && (
        <>
          <Box mb={"20px"}>
            {buyorder?.length ? (
              <Autocomplete
                freeSolo
                id="buyorder"
                name="buyorder"
                disableClearable
                options={buyorder?.map(
                  (option) =>
                    new Object({
                      label:
                        "orden de compra:" +
                        option.code +
                        " / " +
                        "prioridad:" +
                        option.priority.description,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  formikActive.setFieldValue("buyorder", newValue.value);
                  handleBuyOrder(newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar por descripcion/placa"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={
                      formikActive.touched.buyorder &&
                      Boolean(formikActive.errors.buyorder)
                    }
                    helperText={
                      formikActive.touched.buyorder &&
                      formikActive.errors.buyorder
                    }
                  />
                )}
              />
            ):(
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>
          <Box mb={"20px"}>
            {buyordersitems && (
              <DataGrid
                autoHeight={true}
                checkboxSelection
                disableRowSelectionOnClick
                loading={!buyordersitems}
                getRowId={(row) => row.id}
                rows={buyordersitems || []}
                columns={columns}
                onSelectionModelChange={(itm) =>
                  formikActive.setFieldValue("itembuyorder", itm)
                }
                components={{
                  Toolbar: () =>  <GridToolbarContainer>
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                </GridToolbarContainer>,
                }}
              />
            )}
            {formikActive.touched.itembuyorder &&
            formikActive.errors.itembuyorder ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  Verica los campos esten requeridos, debe seleccionar solamente
                  uno.
                </p>
              </FormHelperText>
            ) : null}
          </Box>
        </>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gap="20px"
      >
        <TextField
          id="serie"
          name="serie"
          label="SERIE"
          variant="outlined"
          value={formikActive.values.serie}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.serie && Boolean(formikActive.errors.serie)
          }
          helperText={formikActive.touched.serie && formikActive.errors.serie}
        />
        <TextField
          id="parte"
          name="parte"
          label="PARTE"
          variant="outlined"
          value={formikActive.values.parte}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.parte && Boolean(formikActive.errors.parte)
          }
          helperText={formikActive.touched.parte && formikActive.errors.parte}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikActive.values.dateadquisition}
            id="dateadquisition"
            name="dateadquisition"
            label="FECHA DE ADQUISICION"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikActive.setFieldValue("dateadquisition", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">CONDICION</InputLabel>
          <Select
            id="condition"
            value={formikActive.values.condition}
            name="condition"
            onChange={formikActive.handleChange}
            sx={{
              border:"1px solid rgba(0, 0, 0, 0.23)",            
            }}   
            error={
              formikActive.touched.condition &&
              Boolean(formikActive.errors.condition)
            }
          >
            {condition?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikActive.touched.condition && formikActive.errors.condition ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikActive.touched.condition && formikActive.errors.condition}
            </FormHelperText>
          ) : null}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikActive.values.beginguarante}
            id="beginguarante"
            name="beginguarante"
            label="INICIO DE GARANTIA"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikActive.setFieldValue("beginguarante", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <TextField
          id="guarante"
          name="guarante"
          label="GARANTIA (MESES)"
          type="number"
          variant="outlined"
          value={formikActive.values.guarante}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.guarante &&
            Boolean(formikActive.errors.guarante)
          }
         
          helperText={
            formikActive.touched.guarante && formikActive.errors.guarante
          }
        />     
          
       {/*  <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">UNIDAD</InputLabel>
          <Select
            id="unity"
            value={formikActive.values.unity}
            name="unity"
            onChange={formikActive.handleChange}
            sx={{
              border:"1px solid rgba(0, 0, 0, 0.23)",            
            }}   
            error={
              formikActive.touched.unity && Boolean(formikActive.errors.unity)
            }
          >
            {unity?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikActive.touched.unity && formikActive.errors.unity ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikActive.touched.unity && formikActive.errors.unity}
            </FormHelperText>
          ) : null}
        </FormControl> */}
        <TextField
          id="brand"
          name="brand"
          label="MARCA"
          variant="outlined"
          value={formikActive.values.brand}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.brand && Boolean(formikActive.errors.brand)
          }
          helperText={formikActive.touched.brand && formikActive.errors.brand}
        />
        <TextField
          id="plate"
          name="plate"
          label="PLACA"
          variant="outlined"
          value={formikActive.values.plate}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.plate && Boolean(formikActive.errors.plate)
          }
          helperText={formikActive.touched.plate && formikActive.errors.plate}
        />
         <TextField
          id="amount"
          name="amount"
          label="CANTIDAD"
          type="number"
          variant="outlined"
          value={formikActive.values.amount}
          onChange={formikActive.handleChange}
          error={
            formikActive.touched.amount &&
            Boolean(formikActive.errors.amount)
          }
         
          helperText={
            formikActive.touched.amount && formikActive.errors.amount
          }
        />   
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"5px"} gap={"15px"}>
        <Button variant="contained" color="success" type="submit"  disabled={formikActive.isSubmitting}>
        {formikActive.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
        <Button variant="contained" onClick={handleCloseModalAdd}>
          CERRAR
        </Button>
      </Box>
    </form>
  );
};

export default AddActive;
