import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import {
  GET_JOBREFERENCE_BY_POSTULANT_ID,
  GET_JOBREFERENCE_CRITERION,
  GET_MAIN_DATA,
} from "./QueryJobReference";
import { FormHelperText } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CreateIcon from "@mui/icons-material/Create";
import ModalUtil from "components/ModalUtil";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_POSTULANT_DISTINT_PERSONAL } from "./../postulant/QueryPostulant";
import { GET_BOSS_POSITION } from "./QueryJobReference";
import TextField from "@mui/material/TextField";
import CreateJobReference from "./CreateJobReference";
import ViewJobReference from "./ViewJobReference";
import DialogUtil from "components/DialogUtil";
import ViewPdf from "components/ViewPdf";
import TemplatePDF from "./TemplatePDF";
import { useUser } from "hooks/useUser";
import DeleteJobReference from "./DeleteJobReference";
const JobReference = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [jobReference, setjobReference] = useState([]);
  const [id, setId] = useState(null);
  const { user } = useUser();
  const client = useApolloClient();
  const [postulantData, setPostulantData] = useState([]);
  const [bossPosition, setBossPosition] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [errorId, setErrorId] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const [criterion, setCriterion] = useState([]);
  const [newJobReference, setnewJobReference] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setrequerimentselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const handleOpenDialog = (id) => {
    setDialogOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "company",
      headerName: "COMPAÑIA",
      flex: 1,
    },
    {
      field: "bossposition",
      headerName: "CARGO",
      flex: 1,
      renderCell: (params) => params.row.bossposition,
    },

    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {user.admin ? (
            <>
              <LocalPrintshopIcon
                color="success"
                onClick={() => handleOpenModalPDF(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDialog(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];

  const FUNCTION_GET_JOB_REFERENCE_BY_POSTULANT = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_JOBREFERENCE_BY_POSTULANT_ID,
        variables: {
          getAllJobReferenceByPostulantIdId: id,
        },
        fetchPolicy: "network-only",
      });
      setjobReference(data.getAllJobReferenceByPostulantId);
    } catch (error) {
      console.log(error.message);
      /* setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        }); */
    }
  };
  useEffect(() => {
    const FUNCTION_GET_ALL_POSTULANT = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSTULANT_DISTINT_PERSONAL,
        });
        setPostulantData(data.getPersonalPostulantWithDistintion);
      } catch (error) {
        console.log(error.message);
        /* setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          }); */
      }
    };
    const FUNCTION_GET_BOSS_POSITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_BOSS_POSITION,
        });
        setBossPosition(data.getBossPosition);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_JOBREFERENCE_CRITERION = async () => {
      try {
        const { data } = await client.query({
          query: GET_JOBREFERENCE_CRITERION,
        });
        setCriterion(data.getCriterionJobReference);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          variables: {
            getSummarizeJobReferenceId: user.admin
              ? id
              : user.personal.postulant.id,
          },
          fetchPolicy: "network-only",
        });

        setMainData(data.getSummarizeJobReference);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_POSTULANT();
    FUNCTION_GET_JOBREFERENCE_CRITERION();
    FUNCTION_GET_BOSS_POSITION();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_JOB_REFERENCE_BY_POSTULANT(
      user.admin ? id : user.personal.postulant.id
    );
  }, [newJobReference, id]);
  /* const FUNCTION_GET_JOBREFRENCE_BY_POSTULANT = async (id) => {
    console.log(id)
    try {
      const { data } = await client.query({
        query: GET_INTERVIEW_BY_POSTULANT_ID,
        variables: {
          getAllInterviewByPostulantIdId: id,
        },
        fetchPolicy:'network-only'
      });
      setInterview(data.getAllInterviewByPostulantId);
      console.log(data.getAllInterviewByPostulantId);
    } catch (error) {
      console.log(error.message);
    }
  }; */
  //FUNCTION_GET_ALL_POSTULANT(id);
  const handleCloseAutocomplete = (event, reason) => {
    if (reason === "remove-option") {
      setPostulantData([]);
    }
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setrequerimentselected(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  return (
    <>
      {" "}
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewJobReference}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Referencia Laboral",
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <DialogUtil
        title={"ELIMINAR REFERENCIA LABORAL"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        Component={DeleteJobReference}
        id={requerimentselected}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setnewJobReference,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header
            title="GESTION DE PERSONAS"
            subtitle="personal/jobreference"
          />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 6"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {user.admin ? (
              <>
                {" "}
                <Box
                  fontSize={"12px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  py="10px"
                >
                  BUSCAR POSTULANTE
                </Box>
                {postulantData.length ? (
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={postulantData?.map(
                      (option) =>
                        new Object({
                          label:                    
                            option.name +
                            " " +
                            option.surname +
                            " / " +
                            "dni:" +
                            option.doi,
                          value: option.id,
                          isPersonal: option.isPersonal,
                        })
                    )}
                    onClose={handleCloseAutocomplete}
                    onChange={(event, newValue) => {
                      setIsPersonal(newValue.isPersonal);
                      setId(newValue.value);
                      FUNCTION_GET_JOB_REFERENCE_BY_POSTULANT(newValue.value);
                      // setId(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar por nombre/apellido/dni"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                ) : (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      No hay Datos para mostrar
                    </p>
                  </FormHelperText>
                )}
                {errorId && (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      Verica los campos esten requeridos
                    </p>
                  </FormHelperText>
                )}
              </>
            ) : null}
          </Box>
          <StatBox
            title="Ultimo Referente"
            value={mainData?.company}
            increase={mainData?.cargo}
            description={mainData?.referent}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="¿Recomendado?"
            value={mainData?.recomended}
            /* increase="+21%" */
            /* description="Since last month" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Es Personal"
            value={user.admin ? (id ? (isPersonal ? "SI" : "NO") : "") : "SI"}
            // increase="+21%"
            description=""
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {user.admin ? (
              <>
                {!isPersonal ? (
                  <>
                    <Box
                      fontSize={"12px"}
                      fontWeight={"700"}
                      textAlign={"center"}
                      py="10px"
                    >
                      CREAR REFERENCIA PERSONAL
                    </Box>
                    <CreateJobReference
                      setnewJobReference={setnewJobReference}
                      alertCustom={alertCustom}
                      setAlertCustom={setAlertCustom}
                      bossPosition={bossPosition}
                      criterion={criterion}
                      id={id}
                      errorId={errorId}
                      setErrorId={setErrorId}
                    />
                  </>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    Haz seleccionado un Personal, usted no puede generar
                    referencias Laborales para un trabajador.
                  </Box>
                )}
              </>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Haz seleccionado un Personal, usted no puede generar entrevistas
                para un trabajador.
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}             
            >
              HISTORIAL DE REFERENCIAL LABORAL
            </Box>
            <DataGrid
              loading={!jobReference}
              getRowId={(row) => row.id}
              rows={jobReference || []}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default JobReference;
