import React, { useState, useEffect, useMemo } from "react";
import { GET_INTERVIEW_BY_ID } from "./QueryInterview";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
import { date } from "yup";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  /* section: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    fontSize: "10px",
    border: "1px solid #000",
    paddingVertical: "5px",
  },
  subtitle: {
    paddingVertical: "2px",
    flexGrow: 1,
    paddingLeft: "10px",
    fontWeight: 900,
    fontSize: "10px",
  },
  text: {
    paddingVertical: "2px",
    flexGrow: 1,
    paddingLeft: "10px",
    fontSize: "10px",
  },
 
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    fontSize: "9px",
    paddingVertical: "5px",
    flexGrow: 1, // Ocupar el ancho disponible
  },
  cell: {
    backgroundColor: "#f2f2f2",
    color: "#fff",
  },
  cellSign: {
    paddingVertical: "15px",
  },
  p: {
    fontSize: "9px",
    paddingVertical: "2px",
    paddingHorizontal: "5px",
  },
  textCenter: {
    textAlign: "center",
  },
  box: {
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  }, */
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  column: {
    flexDirection: "column", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});

const TemplatePDF = ({ id, client }) => {
  let sumFormation = 0;
  let sumOther = 0;
  const [visibility, setvisibility] = useState(false);
  const [interviewRequeriment, setInterviewRequeriment] = useState([]);

  useEffect(() => {
    const getInterviewRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_INTERVIEW_BY_ID,
          variables: {
            getInterviewByIdId: id,
          },
        });
        console.log(data.getInterviewById);
        setInterviewRequeriment(data.getInterviewById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getInterviewRequeriment(id);
  }, []);
  const calcularEdad = (fechaNacimiento) => {
    const fechaActual = new Date();
    const nacimiento = new Date(fechaNacimiento);

    return fechaActual.getFullYear() - nacimiento.getFullYear();
  };
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>FICHA DE ENTREVISTA</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>INFORMACION GENERAL</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                NOMBRE Y APELLIDOS:
              </Text>
              <Text style={[styles.text, styles.cell]}>DNI:</Text>
              <Text style={[styles.text, styles.cell]}>
                FECHA DE NACIMIENTO:
              </Text>
              <Text style={[styles.text, styles.cell]}>
                CORREO ELECTRONICO:
              </Text>
              <Text style={[styles.text, styles.cell]}>
                PUESTO AL QUE POSTULA:
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>
                {(
                  interviewRequeriment?.postulant.name +
                  " " +
                  interviewRequeriment?.postulant.surname
                ).toLocaleLowerCase()}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {interviewRequeriment?.postulant.doi}{" "}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {interviewRequeriment
                  ? new Date(
                      interviewRequeriment?.postulant.dob
                    ).toLocaleDateString("es-ES")
                  : null}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {(interviewRequeriment?.postulant.email).toLocaleLowerCase()+" "}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {(interviewRequeriment?.position.description).toLocaleLowerCase()}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>EDAD:</Text>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>TELEFONO:</Text>
              <Text style={[styles.text, styles.cell]}>
                PRETENCIONES SALARIALES:
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>
                {calcularEdad(interviewRequeriment?.postulant.dob)}{" "}
              </Text>
              <Text style={[styles.text, styles.cell]}> </Text>
              <Text style={[styles.text, styles.cell]}>
                {interviewRequeriment?.postulant.phone+" "}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {"S/. " + interviewRequeriment?.salary?interviewRequeriment.salary:""}{" "}
              </Text>
            </View>
          </View>
          {/*    <View style={styles.title}>
          <Text>INFORMACION RELEVANTE AL CARGO</Text>
        </View> */}
          <View style={[styles.title, styles.textTitle]}>
            <Text>SISTEMA DE GESTIÓN</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.column, { flexGrow: 3 }]}>
                <Text style={[styles.text, styles.headerCell]}>Criterios:</Text>
                {interviewRequeriment?.formationQuestion.map((it) => {
                  sumFormation += it.score;
                  return (
                    <Text style={[styles.text, styles.cell]}>
                      {it.formation.description}{" "}
                    </Text>
                  );
                })}
                <Text style={[styles.text, styles.cell]}>PROMEDIO:</Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  Excelente (3):
                </Text>
                {interviewRequeriment?.formationQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {it.score === 3 ? "X" : " "}
                  </Text>
                ))}
                <Text style={[styles.text, styles.cell]}>
                  {(
                    sumFormation / interviewRequeriment.formationQuestion.length
                  ).toFixed(2)}
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  Cumple lo necesario (2):
                </Text>
                {interviewRequeriment?.formationQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {it.score === 2 ? "X" : " "}
                  </Text>
                ))}
                <Text style={[styles.text, styles.cell]}> </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  No cumple (1):
                </Text>
                {interviewRequeriment?.formationQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {it.score === 1 ? "X" : " "}
                  </Text>
                ))}
                <Text style={[styles.text, styles.cell]}> </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>OTROS(ADICIONALES)</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.column, { flexGrow: 3 }]}>
                <Text style={[styles.text, styles.headerCell]}>Criterios:</Text>
                {interviewRequeriment?.otherQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {it.other.description}{" "}
                  </Text>
                ))}
                {/* <Text style={[styles.text, styles.cell]}>Puntualidad</Text>
              <Text style={[styles.text, styles.cell]}>
                Capacidad de comunicación
              </Text>
              <Text style={[styles.text, styles.cell]}>
                Expresión verbal y corporal
              </Text> */}
                <Text style={[styles.text, styles.cell]}>PROMEDIO:</Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  Excelente (3):
                </Text>
                {interviewRequeriment?.otherQuestion.map((it) => {
                  sumOther += it.score;
                  return (
                    <Text style={[styles.text, styles.cell]}>
                      {" "}
                      {it.score === 3 ? "X" : " "}{" "}
                    </Text>
                  );
                })}
                <Text style={[styles.text, styles.cell]}>{(
                    sumOther / interviewRequeriment.otherQuestion.length
                  ).toFixed(2)} </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  Cumple lo necesario (2):
                </Text>
                {interviewRequeriment?.otherQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {it.score === 2 ? "X" : " "}{" "}
                  </Text>
                ))}
                <Text style={[styles.text, styles.cell]}> </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.headerCell]}>
                  No cumple (1):
                </Text>
                {interviewRequeriment?.otherQuestion.map((it) => (
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {it.score === 1 ? "X" : " "}{" "}
                  </Text>
                ))}
                <Text style={[styles.text, styles.cell]}> </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>CRITERIOS</Text>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.cell, styles.textTitle]}>
                Se tomará en cuenta en los promedios, los puntajes obtenidos
                como aceptables y excelentes. (1 a 4=no aceptable, 5 a 7,
                aceptable y 7 a 9, excelent
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA ASISTENTE DE RECURSOS HUMANOS
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA DE ENCARGADO DE AREA
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
