import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  esES
} from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import ModalUtil from "components/ModalUtil";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogUtil from "components/DialogUtil";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";
import { FormHelperText } from "@mui/material";
import {
  GET_INTERVIEW_BY_POSTULANT_ID,
  GET_FORMATION,
  GET_OTHER,
  GET_MAIN_DATA,
} from "./QueryInterview";
import { GET_POSITIONS } from "./../../utils/QueryUtils";
import { GET_POSTULANT_DISTINT_PERSONAL } from "./../postulant/QueryPostulant";
import TextField from "@mui/material/TextField";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import CreateInterview from "./CreateInterview";
import ViewInterview from "./ViewInterview";
import ViewPdf from "../../../components/ViewPdf";
import TemplatePDF from "./TemplatePDF";
import DeleteInterview from "./DeleteInterview";
import { useSettings } from "hooks/useSettings";
import { useUser } from "hooks/useUser";
import { DateFormatting } from "utils/DateFormatting";
const Interview = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [interview, setInterview] = useState([]);
  const [id, setId] = useState(null);
  const client = useApolloClient();
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [postulantData, setPostulantData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isPersonal, setIsPersonal] = useState(false);
  const { user } = useUser();
  const [newInterview, setNewInterview] = useState(null);
  const [requerimentselected, setrequerimentselected] = useState(null);
  const [formation, setFormation] = useState([]);
  const [other, setOther] = useState([]);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [errorId, setErrorId] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { currency,dateFormat,isLoading } = useSettings(); 
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setrequerimentselected(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setrequerimentselected(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const handleOpenDialog = (id) => {
    setDialogOpen(true);
    setrequerimentselected(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const columns = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "position",
      headerName: "CARGO AL QUE POSTULA",
      flex: 1,
      renderCell: (params) => params.row.position.description,
    },
    {
      field: "salary",
      headerName: "SALARIO",
      align: "center",
      flex: 1,
      renderCell: (params) => currency+" " + params.row.salary?params.row.salary:"",
    },

    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {user.admin ? (
            <>
              <LocalPrintshopIcon
                color="success"
                onClick={() => handleOpenModalPDF(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDialog(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_POSTULANT = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSTULANT_DISTINT_PERSONAL,
          fetchPolicy: "network-only",
        });
        setPostulantData(data.getPersonalPostulantWithDistintion);
      } catch (error) {
        console.log(error.message);
        /* setAlertCustom({
                  ...alertCustom,
                  type: "error",
                  message: error.message,
                  state: true,
                }); */
      }
    };
    const FUNCTION_GET_FORMATION = async () => {
      try {
        const { data } = await client.query({
          query: GET_FORMATION,
        });
        setFormation(data.getFormationInterview);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_OTHER = async () => {
      try {
        const { data } = await client.query({
          query: GET_OTHER,
        });
        setOther(data.getOtherInterview);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_GET_POSITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSITIONS,
        });
        setPositions(data.getPosition);
      } catch (error) {
        console.log(error.message);
      }
    };

    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          variables: {
            getSumarizeInterviewId: user.admin
              ? id
              : user.personal.postulant.id,
          },
          fetchPolicy: "network-only",
        });      
        setMainData(data.getSumarizeInterview);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_POSTULANT();
    FUNCTION_GET_INTERVIERW_BY_POSTULANT(
      user.admin ? id : user.personal.postulant.id
    );
    FUNCTION_GET_OTHER();
    FUNCTION_GET_FORMATION();
    FUNCTION_GET_POSITION();
  }, [newInterview, id]);

  const FUNCTION_GET_INTERVIERW_BY_POSTULANT = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_INTERVIEW_BY_POSTULANT_ID,
        variables: {
          getAllInterviewByPostulantIdId: id,
        },
        fetchPolicy: "network-only",
      });
      setInterview(data.getAllInterviewByPostulantId);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      {" "}
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewInterview}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: TemplatePDF,
            title: "Entrevista",
          })
        }
        id={requerimentselected}
      ></ModalUtil>
      <DialogUtil
        title={"ELIMINAR ENTREVISTA"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        Component={DeleteInterview}
        id={requerimentselected}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewInterview,
          })
        }
      />
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="GESTION DE PERSONAS" subtitle="personal/intervieew" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 6"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {user.admin ? (
              <>
                <Box
                  fontSize={"12px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  py="10px"
                >
                  BUSCAR POSTULANTE / PERSONAL
                </Box>
                {postulantData.length ? (
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={postulantData?.map(
                      (option) =>
                        new Object({
                          label:                       
                            option.name +
                            " " +
                            option.surname +
                            " / " +
                            "dni:" +
                            option.doi,
                          value: option.id,
                          isPersonal: option.isPersonal,
                        })
                    )}
                    onChange={(event, newValue) => {
                      setIsPersonal(newValue.isPersonal);
                      setId(newValue.value);
                      console.log(user);
                      FUNCTION_GET_INTERVIERW_BY_POSTULANT(newValue.value);
                      // setId(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar por nombre/apellido/dni"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                ) : (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      No hay Datos para mostrar
                    </p>
                  </FormHelperText>
                )}

                {errorId && (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      Verica los campos esten requeridos
                    </p>
                  </FormHelperText>
                )}
              </>
            ) : null}
          </Box>
          <StatBox
            title="Ultimo cargo postulado"
            value={mainData?.lastPosition}
            //  increase="+14%"
            description=""
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultima entrevista"
            value={
              mainData?.lastDate && !isLoading
                ? DateFormatting(Number(mainData?.lastDate), dateFormat)
                : null
            }
            // increase="+21%"
            description=""
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Es Personal"
            value={user.admin ? (id ? (isPersonal ? "SI" : "NO") : "") : "SI"}
            // increase="+21%"
            description=""
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {user.admin ? (
              <>
                {!isPersonal ? (
                  <CreateInterview
                    setAlertCustom={setAlertCustom}
                    setNewInterview={setNewInterview}
                    alertCustom={alertCustom}
                    formation={formation}
                    other={other}
                    positions={positions}
                    id={id}
                    setId={setId}
                    errorId={errorId}
                    setErrorId={setErrorId}
                  />
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    Haz seleccionado un Personal, usted no puede generar
                    entrevistas para un trabajador.
                  </Box>
                )}{" "}
              </>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                Haz seleccionado un Personal, usted no puede generar entrevistas
                para un trabajador.
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}          
            >
              HISTORIAL DE ENTREVISTAS
            </Box>
            <DataGrid
              loading={!interview}
              getRowId={(row) => row.id}
              rows={interview || []}
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Interview;
