import { gql } from "@apollo/client";

export const GET_POSITIONS = gql`
  query GetPosition {
    getPosition {
      description
      id
    }
  }
`;

export const GET_ALL_TERM = gql`
  query GetTerm {
    getTerm {
      id
      description
    }
  }
`;

export const GET_ALL_SUBJECT = gql`
  query GetSubject {
    getSubject {
      id
      description
    }
  }
`;

export const GET_ALL_WORKPLACE= gql`
query GetWorkPlace {
  getWorkPlace {
    id
    description
  }
}
`;