import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import TemplatePDF from "../scenes/gestionpersonal/interview/TemplatePDF";
import { Box, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
const ViewPdf = ({ id, params }) => {
  const { Component, title } = params;
  console.log(id);
  const client = useApolloClient();
  return (
    <>
      <PDFViewer
        showToolbar={false}
        style={{
          width: "100%",
          height: "80vh",
        }}
      >
        <Component id={id} client={client} />
      </PDFViewer>
      <PDFDownloadLink
        document={<Component id={id} client={client} />}
        fileName={title + ".pdf"}
      >
        {({ loading }) =>
          loading ? (
            "Loading document..."
          ) : (
            <Box
              display={"flex"}
              justifyContent={"end"}
              mt={"10px"}
              gap={"10px"}
            >
              <Button variant="contained" color="success">
                DESCARGAR
              </Button>{" "}
            </Box>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

export default ViewPdf;
