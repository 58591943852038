import React, { useState, useEffect } from "react";
import { Box, Button, TableFooter } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoopIcon from "@mui/icons-material/Loop";
import { GET_INTERVIEW_BY_ID } from "./QueryInterview";
import { useSettings } from "hooks/useSettings";
const ViewInterview = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  let sumFormation = 0;
  let sumOther = 0;
  const [visibility, setvisibility] = useState(false);
  const { currency } = useSettings();
  const [interviewRequeriment, setInterviewRequeriment] = useState([]);
  useEffect(() => {
    const getInterviewRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_INTERVIEW_BY_ID,
          variables: {
            getInterviewByIdId: id,
          },
        });
        setInterviewRequeriment(data.getInterviewById);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getInterviewRequeriment(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    <>
      {visibility ? (
        <>
          {" "}
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label for="my-input">POSTULANTE</label>
              <TextField
                disabled
                value={
                  interviewRequeriment?.postulant.name +
                  " " +
                  interviewRequeriment?.postulant.surname
                }
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">DNI</label>
              <TextField disabled value={interviewRequeriment?.postulant.doi} />
            </Box>
            <Box sx={style}>
              <label for="my-input">POSICION</label>
              <TextField
                disabled
                value={interviewRequeriment?.position.description}
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">SALARIO</label>
              <TextField
                disabled
                value={
                  currency + " " + interviewRequeriment?.salary
                    ? interviewRequeriment.salary
                    : ""
                }
              />
            </Box>
          </Box>
          <Box my={"20px"}>
            <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="">N°</TableCell>
                    <TableCell align="">CRITERIO</TableCell>
                    <TableCell align="">PUNTAJE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interviewRequeriment.formationQuestion.map((row, index) => {
                    sumFormation += row.score;
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="">{index + 1}</TableCell>
                        <TableCell align="">
                          {row.formation.description}
                        </TableCell>
                        <TableCell align="">{row.score}</TableCell>
                      </TableRow>
                      /* </RadioGroup> */
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {" "}
                    <TableCell align="">TOTAL</TableCell>
                    <TableCell align=""></TableCell>
                    <TableCell align="">
                      {(
                        sumFormation /
                        interviewRequeriment.formationQuestion.length
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          <Box my={"20px"}>
            <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="">N°</TableCell>
                    <TableCell align="">CRITERIO</TableCell>
                    <TableCell align="">PUNTAJE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interviewRequeriment.otherQuestion.map((row, index) => {
                    sumOther += row.score;
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="">{index + 1}</TableCell>
                        <TableCell align="">{row.other.description}</TableCell>
                        <TableCell align="">{row.score}</TableCell>
                      </TableRow>
                      /* </RadioGroup> */
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {" "}
                    <TableCell align="">TOTAL</TableCell>
                    <TableCell align=""></TableCell>
                    <TableCell align="">
                      {" "}
                      {(
                        sumOther / interviewRequeriment.otherQuestion.length
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
            <Button variant="contained" onClick={handleCloseModal}>
              CERRAR
            </Button>
            {/*  */}
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoopIcon />
        </Box>
      )}
    </>
  );
};

export default ViewInterview;
