import { gql } from "@apollo/client";
export const GET_TYPE_ACTIVE = gql`
  query GetTypeActive {
    getTypeActive {
      id
      description
    }
  }
`;
export const CREATE_ODOMETER = gql`
  mutation CreateOdometer($number: Float, $active: ID) {
    createOdometer(number: $number, active: $active) {
      id
      number
    }
  }
`;
export const CREATE_COMMENT = gql`
  mutation CreateComment($active: ID, $description: String) {
    createComment(active: $active, description: $description) {
      description
      id
    }
  }
`;
export const CREATE_STATE = gql`
  mutation CreateStateActive($type: ID, $description: String, $active: ID) {
    createStateActive(type: $type, description: $description, active: $active) {
      description
      id
    }
  }
`;
export const GET_STATE_TYPE = gql`
  query GetTypeActive {
    getTypeActive {
      id
      description
    }
  }
`;
export const GET_ALL_STATE_BY_ACTIVE_ID = gql`
  query GetAllStateByIdActive($getAllStateByIdActiveId: ID) {
    getAllStateByIdActive(id: $getAllStateByIdActiveId) {
      id
      description
      type {
        description
      }
    }
  }
`;

export const GET_ALL_COMMENT_BY_ACTIVE_ID = gql`
  query GetAllCommentByIdActive($getAllCommentByIdActiveId: ID) {
    getAllCommentByIdActive(id: $getAllCommentByIdActiveId) {
      id
      description
    }
  }
`;

export const GET_ALL_ODOMETER_BY_ACTIVE_ID = gql`
  query GetAllOdometerByIdActive($getAllOdometerByIdActiveId: ID) {
    getAllOdometerByIdActive(id: $getAllOdometerByIdActiveId) {
      id
      number
    }
  }
`;

export const GET_FUEL_BY_ACTIVE = gql`
  query GetFuelByIdActive($getFuelByIdActiveId: ID) {
    getFuelByIdActive(id: $getFuelByIdActiveId) {
      id
      date
      cost
      amount
      active {
        id
        item {
          active
        }
        description
      }
    }
  }
`;

export const GET_EXPENSE_BY_ACTIVE = gql`
  query GetExpenseByIdActive($getExpenseByIdActiveId: ID) {
    getExpenseByIdActive(id: $getExpenseByIdActiveId) {
      id
      amount
      active {
        id
        description
      }
      cost
      name
    }
  }
`;

export const GET_FUEL_SERIES_BY_ACTIVE = gql`
  query GetFuelSeriesByActiveId($getFuelSeriesByActiveIdId: ID) {
    getFuelSeriesByActiveId(id: $getFuelSeriesByActiveIdId) {
      minmax
      data {
        y
        x
        name
        mode
      }
    }
  }
`;

export const GET_OTHER_EXPENSE_SERIES_BY_ACTIVE = gql`
  query GetOtherExpensiveSeriesByActiveId(
    $getOtherExpensiveSeriesByActiveIdId: ID
  ) {
    getOtherExpensiveSeriesByActiveId(
      id: $getOtherExpensiveSeriesByActiveIdId
    ) {
      data {
        y
        x
        name
        mode
      }
      minmax
    }
  }
`;
export const GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE = gql`
  query GetOneActiveNoVehicleByItemId($getOneActiveNoVehicleByItemIdId: ID) {
    getOneActiveNoVehicleByItemId(id: $getOneActiveNoVehicleByItemIdId) {
      active {
        id
        plate
        serie
        parte
        cost
        brand
        beginguarante
        guarante
        endgurante
        description
        condition {
          description
        }
        item {
          group {
            description
          }
          classs {
            description
          }
        }
      }
    }
  }
`;

export const GET_COMPONENT_FOR_ACTIVE_VEHICLE = gql`
  query GetComponentsForActiveVehicle($getComponentsForActiveVehicleId: ID) {
    getComponentsForActiveVehicle(id: $getComponentsForActiveVehicleId) {
      active {
        id
        plate
        serie
        parte
        cost
        brand
        beginguarante
        guarante
        endgurante
        description
        condition {
          description
        }
        item {
          group {
            description
          }
          classs {
            description
          }
        }
      }
      system {
        title
        data {
          type {
            description
          }
          id
          novehicle {
            id
            plate
            serie
            parte
            cost
            brand
            beginguarante
            guarante
            endgurante
            description
            condition {
              description
            }
            item {
              group {
                description
              }
              classs {
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeFlota($getSumarizeFlotaId: ID) {
    getSumarizeFlota(id: $getSumarizeFlotaId) {
      lastComment
      lastOdometer
      lastState
      lastAssign
    }
  }
`;

export const GET_STATE_BY_ID = gql`
  query GetStateById($getStateByIdId: ID) {
    getStateById(id: $getStateByIdId) {
      id
      description
      type {
        description
      }
    }
  }
`;

export const CREATE_ASIGNATION = gql`
  mutation CreateAssignation(
    $driver: ID
    $description: String
    $proyect: String
    $type: ID
    $begindate: Date
    $begintime: String
    $enddate: Date
    $endtime: String
    $load: String
    $departure: String
    $download: String
    $returnn: String
    $observation: String
    $active: ID
  ) {
    createAssignation(
      driver: $driver
      description: $description
      proyect: $proyect
      type: $type
      begindate: $begindate
      begintime: $begintime
      enddate: $enddate
      endtime: $endtime
      load: $load
      departure: $departure
      download: $download
      returnn: $returnn
      observation: $observation
      active: $active
    ) {
      id
      load
      observation
      proyect
      returnn
      type {
        description
      }
      download
      description
      begintime
      begindate
      enddate
      endtime
    }
  }
`;

export const GET_TYPE_ASIGNATION = gql`
  query GetAssignationType {
    getAssignationType {
      description
      id
    }
  }
`;

export const GET_ALL_ASIGNATION = gql`
  query GetAssignation($getAssignationId: ID) {
    getAssignation(id: $getAssignationId) {
      id
      endtime
      enddate
      download
      description
      departure
      begintime
      begindate
      load
      observation
      proyect
      returnn
      type {
        description
      }
      driver {
        postulant {
          name
          surname
          doi
        }
      }
      active {
        description
      }
    }
  }
`;

export const GET_ASIGNATION_BY_ID = gql`
  query GetAssignationById($getAssignationByIdId: ID) {
    getAssignationById(id: $getAssignationByIdId) {
      begindate
      begintime
      departure
      description
      download
      driver {
        postulant {
          name
          doi
          surname
        }
      }
      enddate
      endtime
      id
      load
      observation
      proyect
      returnn
      type {
        description
      }
      active {
        description
      }
    }
  }
`;

export const DELETE_ASSIGNATION_BY_ID = gql`
  mutation DeleteAssignationById($deleteAssignationByIdId: ID) {
    deleteAssignationById(id: $deleteAssignationByIdId) {
      id
      endtime
      enddate
    }
  }
`;
export const DELETE_ODOMETER_BY_ID = gql`
  mutation DeleteOdometerById($deleteOdometerByIdId: ID) {
    deleteOdometerById(id: $deleteOdometerByIdId) {
      id
      number
    }
  }
`;
