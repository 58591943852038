import React from "react";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { PROVIDER_CATEGORY_SCHEMA_YUP } from "./YupValidation";
import Alerts from "components/Alerts";
import LoopIcon from '@mui/icons-material/Loop';
import { CREATE_CATEGORY_SUPPLIER } from "./QueryProvider";
const CreateCategory = ({ alertCustom, setAlertCustom, setNewCategory }) => {
  const client = useApolloClient();
  const formikCategory = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: PROVIDER_CATEGORY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { description } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_CATEGORY_SUPPLIER,
          variables: {
            category: description.toUpperCase(),
          },
        });
        setNewCategory(response.data.createCategorySupplier);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikCategory.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        <Box
          fontSize={"12px"}
          fontWeight={"700"}
          textAlign={"center"}
          py={"5px"}
        >
          CREAR CATEGORIA
        </Box>
        <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap="20px">
          <TextField
            id="description"
            name="description"
            label="CATEGORIA"
            variant="outlined"
            value={formikCategory.values.description}
            onChange={formikCategory.handleChange}
            error={
              formikCategory.touched.description &&
              Boolean(formikCategory.errors.description)
            }
            helperText={
              formikCategory.touched.description &&
              formikCategory.errors.description
            }
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"15px"}>
          <Button variant="contained" color="success" type="submit"  disabled={formikCategory.isSubmitting}>
          {formikCategory.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateCategory;
