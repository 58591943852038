import { useFormik } from "formik"
import { USER_SCHEMA_YUP } from "./YupValidation"
import { GET_COMPANY_INFO, UPDATE_COMPANY_DATA } from "./QueryCompany"
import { useMutation } from "@apollo/client"

const { Box, TextField, Button } = require("@mui/material")


const CompanyUpdate = ({ name, address, region, setOpen }) => {


  const [uploadCompany] = useMutation(UPDATE_COMPANY_DATA, {
    onCompleted: () => {
      setOpen(false)
    },
    refetchQueries: [
      {
        query: GET_COMPANY_INFO
      }
    ]
  })

  const companyFormik = useFormik({
    initialValues: {
      name: name || '',
      address: address || '',
      region: region || '',
    },
    validationSchema: USER_SCHEMA_YUP,
    onSubmit: (values) => {
      // registerUser({ variables: values })
      uploadCompany({ variables: values })
    }
  })

  return (
    <Box
      component='form'
      onSubmit={companyFormik.handleSubmit}
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <TextField
        id="name"
        label="NOMBRE"
        type="name"
        name="name"
        value={companyFormik.values.name}
        onChange={companyFormik.handleChange}
        onBlur={companyFormik.handleBlur}
        error={companyFormik.touched.name && Boolean(companyFormik.errors.name)}
        helperText={companyFormik.touched.name && companyFormik.errors.name}
        fullWidth
      />
      <TextField
        id="address"
        label="DIRECCIÓN"
        type="address"
        name="address"
        value={companyFormik.values.address}
        onChange={companyFormik.handleChange}
        onBlur={companyFormik.handleBlur}
        error={companyFormik.touched.address && Boolean(companyFormik.errors.address)}
        helperText={companyFormik.touched.address && companyFormik.errors.address}
        fullWidth
      />
      <TextField
        id="region"
        label="REGION"
        type="region"
        name="region"
        value={companyFormik.values.region}
        onChange={companyFormik.handleChange}
        onBlur={companyFormik.handleBlur}
        error={companyFormik.touched.region && Boolean(companyFormik.errors.region)}
        helperText={companyFormik.touched.region && companyFormik.errors.region}
        fullWidth
      />

      <Button variant="contained" color="success" type="submit" size="large" sx={{ width: '100%' }}>
        GUARGAR
      </Button>
    </Box>
  )
}

export default CompanyUpdate