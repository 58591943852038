import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const DialogUtil = ({title,description,isOpen,onClose,Component,id,params}) => {
  return (isOpen&&(
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <Component id={id} onClose={onClose} params={params} />
    
    </Dialog>)
  );
};

export default DialogUtil;
