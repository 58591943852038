import React, { useState, useEffect, useMemo } from "react";
import { GET_BUY_ORDER_VIEW_BY_ID } from "./QueryItemRequeriment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
import { date } from "yup";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  column: {
    flexDirection: "column", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});

const TemplatePDF = ({ id, client }) => {
  let sumFormation = 0;
  let sumOther = 0;
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);

  useEffect(() => {
    const getItemRequerimentId = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_BUY_ORDER_VIEW_BY_ID,
          variables: {
            getBuyOrderItemByIdId: id,
          },
        });
   
        setItemRequeriment(data.getBuyOrderItemById);     
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequerimentId(id);
  }, []);


  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page} orientation={"landscape"}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                      justifyContent:"center"
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>ORDEN DE COMPRA</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>       
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS GENERALES</Text>
          </View>          
          <View style={styles.table}>
            <View style={styles.column}>
            <Text style={[styles.text, styles.cell]}>RUC</Text>
            <Text style={[styles.text, styles.cell]}>
                NOMBRE
              </Text>
              <Text style={[styles.text, styles.cell]}>SOLICITANTE</Text>
              <Text style={[styles.text, styles.cell]}>AREA</Text>
              <Text style={[styles.text, styles.cell]}>
                MOTIVO
              </Text>
              <Text style={[styles.text, styles.cell]}>
                PROVEEDOR
              </Text>
              <Text style={[styles.text, styles.cell]}>
                MONEDA
              </Text>
              <Text style={[styles.text, styles.cell]}>
                FORMA DE PAGO
              </Text>
              <Text style={[styles.text, styles.cell]}>
                CONDICION DE PAGO
              </Text>
              <Text style={[styles.text, styles.cell]}>
                FECHA DE ATENCIÓN
              </Text>
              <Text style={[styles.text, styles.cell]}>
                OBSERVACION
              </Text>
             
            </View>
            <View style={styles.column}>
            <Text style={[styles.text, styles.cell]}>
            20533089292
              </Text>
            <Text style={[styles.text, styles.cell]}>
            CORPORACIÓN DE SERVICIOS MOQUEGUA EIRL
              </Text>
             
              <Text style={[styles.text, styles.cell]}>
              {itemRequeriment?.personal.postulant.name +
                  " " +
                  itemRequeriment?.personal.postulant.surname}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.area.description}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.subject }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.provider?.businessname?itemRequeriment?.provider?.businessname:" " }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.currency?.description }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.formpay?itemRequeriment?.formpay:" " }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.conditionpay?itemRequeriment?.conditionpay:" " }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {new Date(itemRequeriment?.attentiondate).toLocaleDateString("es-ES") }
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.observation?itemRequeriment?.observation:" " }
              </Text>                          
            </View>         
          </View>  
          <View style={{  flexDirection: "row",borderTop: "1px solid #000",  borderLeft: "1px solid #000" }}>
             <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>              
                    <Text style={[styles.text, styles.cell]}>Nº</Text>                
              </View>
           
              <View style={[styles.column, { flexFlow: 3 }]}>               
                    <Text style={[styles.text, styles.cell]}>
                    ACTIVO
                    </Text>                 
              </View>
              <View style={[styles.column, { flexFlow: 5 }]}>             
                    <Text style={[styles.text, styles.cell]}>
                    DESCRIPCION
                    </Text>                 
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>             
                    <Text style={[styles.text, styles.cell]}>
                    UNIDAD
                    </Text>                 
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>              
                    <Text style={[styles.text, styles.cell]}>
                     CANTIDAD
                    </Text>               
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>              
                    <Text style={[styles.text, styles.cell]}>
                     VALOR UNITARIO
                    </Text>               
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>              
                    <Text style={[styles.text, styles.cell]}>
                     DESCUENTO
                    </Text>               
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>              
                    <Text style={[styles.text, styles.cell]}>
                     TOTAL
                    </Text>               
              </View>
            </View>
          
          </View>
          <View style={[styles.table]}>
        
            <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>
                {itemRequeriment?.item.map((it, index) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{index + 1}</Text>
                  </>
                ))}
              </View>
           
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.item.active.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 5 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.item.description.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.item.unity.description}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.amount}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.unitvalue}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.discount?it.discount:"0"+" %"}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 4 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.cost}
                    </Text>
                  </>
                ))}
              </View>
            </View>
          </View>

          <View style={{ flexDirection: "row",borderTop: "1px solid #000",  borderLeft: "1px solid #000" }}>
             <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>              
                    <Text style={[styles.text, styles.cell]}>IGV {itemRequeriment.igv+" %"} </Text>                
                    <Text style={[styles.text, styles.cell]}>SUBTOTAL {itemRequeriment.subtotal} </Text>                
                    <Text style={[styles.text, styles.cell]}>TOTAL {itemRequeriment.total} </Text>                
              </View>
           
            </View>
          
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                AREA DE LOGISTICA
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                V.B. ADMINISTRACIÓN
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
               V.B. GERENCIA
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>              
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
              NOTA:{"\n"}
1.-PARA ACCESORIOS Y/O RESPUESTOS ENVIAR LAS ESPECIFICACIONES TECNICAS, NECESARIAS PARA RESPALDAR ESTA COMPRA.{"\n"}
2.-RESPETAR LA FECHA DE ENTREGA PROGRAMADA PARA EVITAR RECHAZOS.{"\n"}
3.-PARA EMISORES DE FACTURAS ELECTRONICAS REMITIR LA FACTURA, ARCHIVO XML Y CDR AL E-MAIL fcentenog@corporacionmoquegua.com
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
