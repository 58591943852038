import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import LoopIcon from "@mui/icons-material/Loop";
import { FUEL_SCHEMA_YUP } from "./YupValidation";
import { useUser } from "hooks/useUser";
import { CREATE_FUEL } from "./QueryOperation";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
const CreateFuel = ({
  active,
  driver,
  supplier,
  typeFuel,
  setAlertCustom,
  alertCustom,
  setNewFuel,
}) => {

  const client = useApolloClient();
  const theme = useTheme();
  const autocompleteactiveRef = useRef(null);
  const autocompletepersonalRef = useRef(null);
  const autocompletesupplierRef = useRef(null);
  const { currency, dateFormat } = useSettings();
  const { user } = useUser();
  const formikFuel = useFormik({
    initialValues: {
      active: {
        label: "",
        id: "",
      },
      driver: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      supplier: {
        label: "",
        id: "",
      },
      type: "",
      date: new Date(),
      time: dayjs('2022-04-17T15:30'),
      amount: "",
      cost: "",
      total: "",
    },
    validationSchema: FUEL_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        active,
        driver,
        supplier,
        type,
        date,
        time,
        amount,
        cost,
      } = values;
      try {
        const xtotal = parseFloat(Number(amount) * parseFloat(cost))
        const response = await client.mutate({
          mutation: CREATE_FUEL,
          variables: {
            active: active.id,
            driver: driver.id,
            supplier: supplier.id,
            type,
            date,
            time: time.$H + ":" + time.$m,
            amount: Number(amount),
            cost: parseFloat(cost),
            total: Math.ceil(xtotal * 100) / 100,
          },
        });
        setNewFuel(response.data.createFuel);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        autocompleteactiveRef.current.value = "";
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        autocompletesupplierRef.current.value = "";
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikFuel.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box mb={"20px"}>
        {active?.length ? (
          <Autocomplete
            freeSolo
            id="active"
            name="active"
            disableClearable
            ref={autocompleteactiveRef}
            value={formikFuel.values.active.label}
            options={active?.map(
              (option) =>
                new Object({
                  label:
                    option.description +
                    " / " +
                    "placa:" +
                    option.plate,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikFuel.setFieldValue("active", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por descripcion/placa"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikFuel.touched.active?.id &&
                  Boolean(formikFuel.errors.active?.id)
                }
                helperText={
                  formikFuel.touched.active?.id && formikFuel.errors.active?.id
                }
              />
            )}
          />
        ) : (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
      </Box>
      {user.admin ? (
        <Box mb={"20px"}>
          {driver?.length ? (
            <Autocomplete
              freeSolo
              id="driver"
              name="driver"
              disableClearable
              ref={autocompletepersonalRef}
              value={formikFuel.values.driver.label}
              options={driver?.map(
                (option) =>
                  new Object({
                    label:
                      option.postulant.name +
                      " " +
                      option.postulant.surname +
                      " / " +
                      "dni:" +
                      option.postulant.doi,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                formikFuel.setFieldValue("driver", {
                  label: newValue.label,
                  id: newValue.value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={
                    formikFuel.touched.driver?.id &&
                    Boolean(formikFuel.errors.driver?.id)
                  }
                  helperText={
                    formikFuel.touched.driver?.id &&
                    formikFuel.errors.driver?.id
                  }
                />
              )}
            />
          ) : (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                No hay Datos para mostrar
              </p>
            </FormHelperText>
          )}
        </Box>
      ) : null}
      <Box mb={"20px"}>
        {supplier?.length && (
          <Autocomplete
            freeSolo
            id="supplier"
            name="supplier"
            ref={autocompletesupplierRef}
            value={formikFuel.values.supplier.label}
            disableClearable
            options={supplier?.map(
              (option) =>
                new Object({
                  label:
                    option.businessname +
                    " / " +
                    "ruc:" +
                    option.ruc,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikFuel.setFieldValue("supplier", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por nombre de proveedor/ruc"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikFuel.touched.supplier?.id &&
                  Boolean(formikFuel.errors.supplier?.id)
                }
                helperText={
                  formikFuel.touched.supplier?.id &&
                  formikFuel.errors.supplier?.id
                }
              />
            )}
          />
        )}
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            TIPO DE COMBUSTIBLE
          </InputLabel>
          <Select
            id="type"
            value={formikFuel.values.type}
            name="type"
            onChange={formikFuel.handleChange}
            label="TIPO DE COMBUSTIBLE"
            error={formikFuel.touched.type && Boolean(formikFuel.errors.type)}
          >
            {typeFuel?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikFuel.touched.type && formikFuel.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikFuel.touched.type && formikFuel.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikFuel.values.date}
            id="date"
            name="date"
            label="FECHA DE REQUERIMIENTO"
            format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
            onChange={(val) => {
              formikFuel.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>


        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            id="time"
            label="Horário"
            name="time"
            value={formikFuel.values.time}
            onChange={(val) => {
              formikFuel.setFieldValue("time", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        {/*  <LocalizationProvider dateAdapter={AdapterDayjs}>
         <TimePicker
  label="Basic example"
  value={value}
  onChange={(newValue) => {
    setValue(newValue);
  }}
  slots={{
    TextField: "hey",
  }}
/></LocalizationProvider> */}
        <TextField
          id="amount"
          name="amount"
          label="CANTIDAD (EN GALONES)"
          variant="outlined"
          value={formikFuel.values.amount}
          onChange={formikFuel.handleChange}
          error={formikFuel.touched.amount && Boolean(formikFuel.errors.amount)}
          helperText={formikFuel.touched.amount && formikFuel.errors.amount}
        />

        <FormControl>
          <InputLabel htmlFor="salary">PRECIO (POR GALONES)</InputLabel>
          <OutlinedInput
            id="cost"
            startAdornment={
              <InputAdornment position="start">{currency} </InputAdornment>
            }
            name="cost"
            label="PRECIO (POR GALONES)"
            variant="outlined"

            value={formikFuel.values.cost}
            onChange={formikFuel.handleChange}
            error={formikFuel.touched.cost && Boolean(formikFuel.errors.cost)}
          />
          {formikFuel.touched.cost && formikFuel.errors.cost ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikFuel.touched.cost && formikFuel.errors.cost}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          label="TOTAL"
          value={currency + " " + formikFuel.values.amount * formikFuel.values.cost}
          disabled
        />
        {/*    <TextField
          id="total"
          name="total"
          label="TOTAL"
          variant="outlined"
          value={formikFuel.values.total}
          onChange={formikFuel.handleChange}
          error={formikFuel.touched.total && Boolean(formikFuel.errors.total)}
          helperText={formikFuel.touched.total && formikFuel.errors.total}
        /> */}
      </Box>

      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikFuel.isSubmitting}
        >
          {formikFuel.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateFuel;
