import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
query GetUserByToken {
  getUserByToken {   
    personal {
      id
      postulant {
        id
        name
        surname
        email
      }
    }
    role
    submodules
    image
    enabled
    admin
  }
}
`