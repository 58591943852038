import { gql } from "@apollo/client";

export const GET_PREUSE_COMPONENT_VAN = gql`
  query GetPreUseComponentVan {
    getPreUseComponentVan {
      category
      element {
        type {
          description
        }
        description
        category {
          description
        }
        id
      }
    }
  }
`;

export const CREATE_PREUSE_VAN = gql`
  mutation CreatePreUseVan(
    $driver: ID
    $date: Date
    $time: Date
    $plate: String
    $kmBegin: Int
    $kmEnd: Int
    $activity: String
    $declarations: [DeclarationVan]
    $components: [ComponentVan]
  ) {
    createPreUseVan(
      driver: $driver
      date: $date
      time: $time
      plate: $plate
      kmBegin: $kmBegin
      kmEnd: $kmEnd
      activity: $activity
      declarations: $declarations
      components: $components
    ) {
      id
       driver {
        postulant {
          name
          surname
        }
      }
      date
      activity
      time
    }
  }
`;
export const GET_DECLARATION = gql`
  query GetDeclaration {
    getDeclaration {
      id
      description
    }
  }
`;

export const GET_PREUSE_VAN = gql`
  query GetPreUseVan {
    getPreUseVan {
      time
      id    
      date
      activity
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeVan {
    getSumarizeVan {
      amount
      lastdate
      lastsubject
    }
  }
`;

export const GET_VAN_BY_ID = gql`
  query GetPreUseVanById($getPreUseVanByIdId: ID) {
    getPreUseVanById(id: $getPreUseVanByIdId) {
      van {
        activity
        date
        time
        id
        driver {
          postulant {
            name
            surname
            doi
          }
        }
      }
      question {
        answer
        observation
        question {
          description
        }
      }
      declaration {
        answer
        observation
        question {
          description
        }
      }
    }
  }
`;
