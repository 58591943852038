import { gql } from "@apollo/client";


export const GET_COMPANY_INFO = gql`
  query {
    getCompanyInfo {
      name
      region
      address
      logo
    }
  }
`

export const UPDATE_COMPANY_DATA = gql`
  mutation($name: String, $region: String, $address: String){
    updateCompany(name: $name, region: $region, address: $address)
  }
`