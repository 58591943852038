import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import ModalUtil from "components/ModalUtil";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBoxOne from "components/StatBoxOne";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { DataGrid,  GridToolbarContainer,esES,
  GridToolbarFilterButton,
  GridToolbarDensitySelector, } from "@mui/x-data-grid";
import CreateSintomatology from "./CreateSintomatology";
import Alerts from "components/Alerts";
import CreateIncident from "./CreateIncident";
import {
  GET_QUESTION_SINTOMATOLOGY,
  GET_TYPE_CONTROL,
  GET_OPERATOR_PERSONAL,
  GET_INCIDENT,
  GET_SINTOMATOLOGY,
  GET_MAIN_DATA,
} from "./QuerySintoIncident";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import ViewIncident from "./ViewIncident";
import ViewPdf from "components/ViewPdf";
import PDFIncident from "./PDFIncident";
import ViewSintomatology from "./ViewSintomatology";
import PDFSintomatology from "./PDFSintomatology";
import { GET_ALL_DRIVER } from "./../../operation/operation/QueryOperation";
const SintomasEIncidentes = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [listOperator, setListOperator] = useState([]);
  const [listQuestion, setListQuestion] = useState([]);
  const [listPersonal, setListPersonal] = useState([]);
  const [newSintomatology, setNewSintomatology] = useState(null);
  const [newIncident, setNewIncident] = useState(null);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenViewSom, setModalOpenViewSom] = useState(false);
  const [modalOpenPDF, setModalOpenPDF] = useState(false);
  const [mainData, setMainData] = useState([]);

  const [sintomatology, setSintomatology] = useState([]);
  const [Incident, setIncident] = useState([]);
  const [listTypeControl, setListTypeControl] = useState([]);
  const [id, setId] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });


  const handleOpenModalViewSom = (id) => {
    setModalOpenViewSom(true);
    setId(id);
  };

  const handleCloseModalViewSom = () => {
    setModalOpenViewSom(false);
  };
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalPDF = (id) => {
    setModalOpenPDF(true);
    setId(id);
  };

  const handleCloseModalPDF = () => {
    setModalOpenPDF(false);
  };
  const columnsOperator = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "operator.postulant.name",
      headerName: "NOMBRE OPERADOR",
      flex: 1,
      renderCell: (params) => params.row.operator.postulant.name,
    },
    {
      field: "operator.postulant.surname",
      headerName: "APELLIDO OPERADOR",
      flex: 1,

      renderCell: (params) => params.row.operator.postulant.surname,
    },
    {
      field: "operator.postulant.doi",
      headerName: "DNI",
      flex: 1,
      renderCell: (params) => params.row.operator.postulant.doi,
    },
    {
      field: "ope",
      headerName: "",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalViewSom(params.row.id)}
          />
          <LocalPrintshopIcon
           onClick={() => handleOpenModalPDF(params.row.id)}
          />
          {/*  <CreateIcon />
            <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnsIncident = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCIÓN",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,

      renderCell: (params) => params.row.type.description,
    },
    {
      field: "FECHA",
      headerName: "DNI",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.date).toLocaleDateString("es-ES"),
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/*  <LocalPrintshopIcon
            onClick={() => handleOpenModalPDF(params.row.id)}
          /> */}
          {/*  <CreateIcon />
            <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
  /*   const FUNCTION_GET_OPERATOR = async () => {
      try {
        const { data } = await client.query({
          query: GET_OPERATOR_PERSONAL,
          fetchPolicy: "network-only",
        });
        setListOperator(data.getOperatorPosition);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    }; */
    const FUNCTION_GET_QUESTION_SINTOMATOLOGY = async () => {
      try {
        const { data } = await client.query({
          query: GET_QUESTION_SINTOMATOLOGY,
          fetchPolicy: "network-only",
        });
        setListQuestion(data.getQuestionSintomatology);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
  /*   const FUNCTION_GET_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
          fetchPolicy: "network-only",
        });
        setListPersonal(data.getAllPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    }; */
    const FUNCTION_GET_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DRIVER,
          fetchPolicy: "network-only",
        });
        setListPersonal(data.getAllDriver);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_TYPE_CONTROL = async () => {
      try {
        const { data } = await client.query({
          query: GET_TYPE_CONTROL,
          fetchPolicy: "network-only",
        });
        setListTypeControl(data.getTypeControl);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_INCIDENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_INCIDENT,
          fetchPolicy: "network-only",
        });
        console.log(data.getIncident);
        setIncident(data.getIncident);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_SINTOMATOLOGY = async () => {
      try {
        const { data } = await client.query({
          query: GET_SINTOMATOLOGY,
          fetchPolicy: "network-only",
        });
        console.log(data.getSintomatology);
        setSintomatology(data.getSintomatology);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        console.log(data);
        setMainData(data.getSumarizeIncidentSintomatology);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_MAIN_DATA();

    FUNCTION_GET_INCIDENT();
    FUNCTION_GET_SINTOMATOLOGY();

    //FUNCTION_GET_OPERATOR();
    FUNCTION_GET_QUESTION_SINTOMATOLOGY();
    FUNCTION_GET_PERSONAL();
    FUNCTION_GET_TYPE_CONTROL();
  }, [newSintomatology, newIncident]);
  return (
    <>
      {" "}
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewIncident}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
       <ModalUtil
        isOpen={modalOpenViewSom}
        onClose={handleCloseModalViewSom}
        Component={ViewSintomatology}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewSom,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenPDF}
        onClose={handleCloseModalPDF}
        Component={ViewPdf}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalPDF,
            Component: PDFSintomatology,
            title: "Entrevista",
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="SEGURIDAD" subtitle="seguridad/sintamas e incidentes" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Total Sintomatologia"
            value={mainData?.amountsintomatology}
            /*   increase={mainData?.cargo} */
            description={"Cantidad de total de registros"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Ultimo Operador"
            value={mainData?.personalsintomatology}
            /*   increase={mainData?.cargo}*/
            description={"Ultimo operador en la sintomatologia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Total Incidencias"
            value={mainData?.amountincident}
            /*   increase={mainData?.cargo} */
            description={"Cantidad de total de registros"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Ultimo Supervisor"
            value={mainData?.amountsintomatology}
            /*   increase={mainData?.cargo}*/
            description={"Ultimo supervisor en la incidencia"}
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 1 */}

          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              REGISTRO DE SINTOMATOLOGÍA
            </Box>
            <CreateSintomatology
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              listOperator={listPersonal}
              listQuestion={listQuestion}
              setNewSintomatology={setNewSintomatology}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="0px"
            >
              HISTORIAL DE SINTOMATOLOGÍA
            </Box>
            <DataGrid
              loading={!sintomatology}
              getRowId={(row) => row.id}
              rows={sintomatology || []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}  
              columns={columnsOperator}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              REGISTRAR INCIDENTE
            </Box>
            <CreateIncident
              alertCustom={alertCustom}
              setAlertCustom={setAlertCustom}
              listPersonal={listPersonal}
              listTypeControl={listTypeControl}
              setNewIncident={setNewIncident}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="0px"
            >
              HISTORIAL DE INCIDENTES
            </Box>
            <DataGrid
              loading={!Incident}
              getRowId={(row) => row.id}
              rows={Incident || []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              columns={columnsIncident}
              components={{
                Toolbar: () =>  <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
          {/*  <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          1
        </Box> */}
          {/*   <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          2
        </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default SintomasEIncidentes;
