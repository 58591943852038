import { gql } from "@apollo/client";
export const GET_DOCUMENTS_BY_PERSONAL_ID = gql`
  query GetDocumentPersonalByPersonalId(
    $getDocumentPersonalByPersonalIdId: ID
  ) {
    getDocumentPersonalByPersonalId(id: $getDocumentPersonalByPersonalIdId) {
      id
      url
      state {
        description
      }
      name
      document {
        kind
        type
        weight
        description
      }
      caducitydate
      emitiondate
    }
  }
`;

export const GET_TRAININGS_BY_PERSONAL_ID = gql`
  query GetDocumentTrainingByPersonalId(
    $getDocumentTrainingByPersonalIdId: ID
  ) {
    getDocumentTrainingByPersonalId(id: $getDocumentTrainingByPersonalIdId) {
      id
      caducitydate
      document {
        kind
        type
        weight
        description
      }
      emitiondate
      name
      state {
        description
      }
      url
    }
  }
`;

export const GET_LISTDOCUMENT_BY_PERSONAL_ID = gql`
  query GetDocumentListByPersonalId($getDocumentListByPersonalIdId: ID) {
    getDocumentListByPersonalId(id: $getDocumentListByPersonalIdId) {
      weight
      type
      kind
      id
      description
    }
  }
`;
export const GET_LISTDOCUMENT_BY_TRAINING_ID = gql`
  query GetDocumentListByTrainingId($getDocumentListByTrainingIdId: ID) {
    getDocumentListByTrainingId(id: $getDocumentListByTrainingIdId) {
      id
      kind
      type
      weight
      description
    }
  }
`;
export const CREATE_DOCUMENT = gql`
  mutation CreatePersonalDocument(
    $personal: ID
    $document: ID
    $filex: Upload
  ) {
    createPersonalDocument(
      personal: $personal
      document: $document
      filex: $filex
    ) {
      id
      name
      url
    }
  }
`;

export const CREATE_TRAINING = gql`
  mutation CreatePersonalTraining(
    $personal: ID
    $document: ID
    $filex: Upload
  ) {
    createPersonalTraining(
      filex: $filex
      document: $document
      personal: $personal
    ) {
      id
      name
      url
    }
  }
`;

export const GET_DOCUMENT_IMAGE_BY_PERSONAL_ID = gql`
  query GetDocumentImageByPersonalId($getDocumentImageByPersonalIdId: ID) {
    getDocumentImageByPersonalId(id: $getDocumentImageByPersonalIdId) {
      caducitydate
      document {
        description
        type
        kind
      }
      emitiondate
      id
      name
      state {
        description
      }
      url
    }
  }
`;

export const GET_TRAINING_IMAGE_BY_PERSONAL_ID = gql`
  query GetTrainingImageByPersonalId($getTrainingImageByPersonalIdId: ID) {
    getTrainingImageByPersonalId(id: $getTrainingImageByPersonalIdId) {
      caducitydate
      document {
        description
        type
        kind
      }
      emitiondate
      id
      name
      state {
        description
      }
      url
    }
  }
`;
export const UPDATE_DOCUMENT_CADUCITY_EMITION_BY_PERSONAL_ID = gql`
  mutation UpdateCaducityEmitionDatePersonal(
    $updateCaducityEmitionDatePersonalId: ID
    $emitiondate: Date
    $caducitydate: Date
  ) {
    updateCaducityEmitionDatePersonal(
      id: $updateCaducityEmitionDatePersonalId
      emitiondate: $emitiondate
      caducitydate: $caducitydate
    ) {
      id
      emitiondate
      caducitydate
      name
      state {
        description
      }
      url
    }
  }
`;

export const UPDATE_TRAINING_CADUCITY_EMITION_BY_PERSONAL_ID = gql`
  mutation UpdateCaducityEmitionDateTraining(
    $updateCaducityEmitionDateTrainingId: ID
    $emitiondate: Date
    $caducitydate: Date
  ) {
    updateCaducityEmitionDateTraining(
      id: $updateCaducityEmitionDateTrainingId
      emitiondate: $emitiondate
      caducitydate: $caducitydate
    ) {
      id
      emitiondate
      caducitydate
      name
      state {
        description
      }
      url
    }
  }
`;


export const DELETE_PERSONAL_DOCUMENT= gql`
mutation DeleteDocumentPersonal($deleteDocumentPersonalId: ID) {
  deleteDocumentPersonal(id: $deleteDocumentPersonalId) {
    id
    url
    name
  }
}`;

export const DELETE_TRAINING_DOCUMENT= gql`
mutation DeleteDocumentTraining($deleteDocumentTrainingId: ID) {
  deleteDocumentTraining(id: $deleteDocumentTrainingId) {
    id
    name
    url
  }
}`;
