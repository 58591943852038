import React, { useState, useEffect, useMemo } from "react";
import { GET_ITEM_REQUERIMENT_BY_ID } from "./QueryItemRequeriment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
/* import { useSettings } from "hooks/useSettings"; */
import { date } from "yup";
/* import { DateFormatting } from "utils/DateFormatting"; */
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row", // Organizar filas horizontalmente
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  column: {
    flexDirection: "column", // Organizar columnas verticalmente
    flex: 1, // Ocupar el espacio vertical disponible
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});

const TemplatePDF = ({ id, client }) => {
  let sumFormation = 0;
  let sumOther = 0;
  const [visibility, setvisibility] = useState(false);
  const [itemRequeriment, setItemRequeriment] = useState([]);
/*   const { dateFormat } = useSettings(); */
  useEffect(() => {
    const getItemRequerimentId = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ITEM_REQUERIMENT_BY_ID,
          variables: {
            getItemAndRequerimentByIdId: id,
          },
        });
        
        setItemRequeriment(data.getItemAndRequerimentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getItemRequerimentId(id);
  }, []);

  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page} orientation={"landscape"}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    justifyContent:"center"
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>REQUERIMIENTO</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS GENERALES</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>RUC</Text>
              <Text style={[styles.text, styles.cell]}>NOMBRE</Text>
              <Text style={[styles.text, styles.cell]}>SOLICITANTE</Text>
              <Text style={[styles.text, styles.cell]}>FECHA DE EMISIÓN</Text>
              <Text style={[styles.text, styles.cell]}>AREA</Text>
              <Text style={[styles.text, styles.cell]}>CENTRO DE COSTO</Text>
              <Text style={[styles.text, styles.cell]}>OBSERVACIONES</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.cell]}>20533089292</Text>
              <Text style={[styles.text, styles.cell]}>
                CORPORACIÓN DE SERVICIOS MOQUEGUA EIRL
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.personal.postulant.name +
                  " " +
                  itemRequeriment?.personal.postulant.surname}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {new Date(itemRequeriment?.date).toLocaleDateString("es-ES")}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.area.description}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.costcenter
                  ? itemRequeriment?.costcenter.description
                  : itemRequeriment?.area.description}
              </Text>
              <Text style={[styles.text, styles.cell]}>
                {itemRequeriment?.observation
                  ? itemRequeriment?.observation
                  : " "}
              </Text>
              {/*  <Text style={[styles.text, styles.cell]}>
              Conductor de Semitrailer
            </Text> */}
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              borderTop: "1px solid #000",
              borderLeft: "1px solid #000",
            }}
          >
            <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>
                <Text style={[styles.text, styles.cell]}>Nº</Text>
              </View>
             
              <View style={[styles.column, { flexFlow: 3 }]}>
                <Text style={[styles.text, styles.cell]}>ACTIVO</Text>
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                <Text style={[styles.text, styles.cell]}>DESCRIPCION</Text>
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                <Text style={[styles.text, styles.cell]}>UNIDAD</Text>
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                <Text style={[styles.text, styles.cell]}>CANTIDAD</Text>
              </View>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.row}>
              <View style={[styles.column, { flexFlow: 1 }]}>
                {itemRequeriment?.item.map((it, index) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{index + 1}</Text>
                  </>
                ))}
              </View>
              
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.active.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>
                      {it.description.toLowerCase()}
                    </Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{it.unity?.description}</Text>
                  </>
                ))}
              </View>
              <View style={[styles.column, { flexFlow: 3 }]}>
                {itemRequeriment?.item.map((it) => (
                  <>
                    <Text style={[styles.text, styles.cell]}>{it.amount}</Text>
                  </>
                ))}
              </View>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>              
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
              (*) Consignar Placa de la unidad, Km, Area de Trabajo, otros {"\n"}
(**)Consignar todos los datos necesarios para el servicio
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                SOLICITANTE
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                ALMACEN/COMPRAS
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>GERENTE</Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
          <View style={[styles.table]}>
            <View style={styles.column}>              
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
              Prioridad{"\n"}
                Alta = Atendidas dentro de las 48 hrs. de haber ingresado el
                requerimiento (Suministros críticos). {"\n"}
                Media = Atendidas dentro
                de las 72 horas de haber ingresado el requerimiento. {"\n"}
                Baja =
                Atendidas dentro de los 7 días de haber ingresado el
                requerimiento.
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
