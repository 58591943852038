import { gql } from "@apollo/client";
export const GET_CRITERION_SELECTION = gql`
  query GetCriterionSeleccion {
    getCriterionSeleccion {
      id
      description
    }
  }
`;
export const GET_CRITERION_EVALUATION = gql`
  query GetCriterionEvaluacion {
    getCriterionEvaluacion {
      id
      description
    }
  }
`;

export const CREATE_EVALUATION_SELECTION = gql`
  mutation CreateSelection(
    $type: String
    $evaluator: ID
    $supplier1: ID
    $criteria1: [SelectionItem]
    $supplier2: ID
    $criteria2: [SelectionItem]
    $supplier3: ID
    $criteria3: [SelectionItem]
    $winner: Winner
  ) {
    createSelection(
      type: $type
      evaluator: $evaluator
      supplier1: $supplier1
      criteria1: $criteria1
      supplier2: $supplier2
      criteria2: $criteria2
      supplier3: $supplier3
      criteria3: $criteria3
      winner: $winner
    ) {
      id
      score
      supplier {
        businessname
      }
      type
    }
  }
`;
export const CREATE_EVALUATION = gql`
  mutation CreateEvaluation(
    $winner: Winner
    $criteria3: [SelectionItem]
    $supplier3: ID
    $criteria2: [SelectionItem]
    $supplier2: ID
    $criteria1: [SelectionItem]
    $supplier1: ID
    $evaluator: ID
  ) {
    createEvaluation(
      winner: $winner
      criteria3: $criteria3
      supplier3: $supplier3
      criteria2: $criteria2
      supplier2: $supplier2
      criteria1: $criteria1
      supplier1: $supplier1
      evaluator: $evaluator
    ) {
      id
      evaluator {
        postulant {
          name
          surname
        }
      }
      score
      supplier {
        businessname
        ruc
      }
    }
  }
`;
export const GET_ALL_SELECTION = gql`
  query GetAllSelection {
    getAllSelection {
      id
      score
      supplier {
        businessname
        ruc
      }
      evaluator {
        postulant {
          name
          surname
        }
      }
      type
    }
  }
`;

export const GET_EVALUATION_BY_ID = gql`
  query GetEvaluationById($getEvaluationByIdId: ID) {
    getEvaluationById(id: $getEvaluationByIdId) {
      id
      score
      supplier {
        businessname
      }
      questions {
        id
        score
        criterion {
          description
        }
        supplier {
          businessname
        }
      }
    }
  }
`;

export const GET_SELECTION_BY_ID = gql`
  query GetSelectionById($getSelectionByIdId: ID) {
    getSelectionById(id: $getSelectionByIdId) {
      id
      score
      supplier {
        businessname
      }
      type
      questions {
        criterion {
          description
        }
        score
        id
        supplier {
          businessname
        }
      }
    }
  }
`;
export const GET_ALL_EVALUATION = gql`
  query GetAllEvaluation {
    getAllEvaluation {
      score
      id
      evaluator {
        postulant {
          name
          surname
        }
      }
      supplier {
        businessname
        ruc
      }
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizeEvaluationAndSelection {
    getSumarizeEvaluationAndSelection {
      lastEvaluationScore
      lastEvaluationSupplier
      lastSelectionScore
      lastSelectionSupplier
      total
    }
  }
`;

export const DELETE_EVALUATION_BY_ID= gql`
mutation DeleteEvaluationById($deleteEvaluationByIdId: ID) {
  deleteEvaluationById(id: $deleteEvaluationByIdId) {
    id
    score
  }
}`;


export const DELETE_SELECTION_BY_ID= gql`
mutation DeleteSelectionById($deleteSelectionByIdId: ID) {
  deleteSelectionById(id: $deleteSelectionByIdId) {
    id
    score
    type
  }
}`;