import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import LineChart from "components/LineChart";
import ModalUtil from "components/ModalUtil";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import IndicartorsBar from "components/IndicartorsBar";
import {
  GET_FUEL_REPORT,
  GET_OTHER_REPORT,
  GET_ALL_ASIGNATION_REGISTER,
  GET_OTHER_FUEL_REPORT,
} from "./QueryReport";
import { GET_ALL_ITEM_ONLY_VEHICLE } from "./../../logistic/item/QueryItem";
import ViewReportFuel from "./ViewReportFuel";
import { GET_ACTIVES_BY_ITEM_ID, GET_REPORT_FLOTA } from "./QueryReport";
import ViewReportOther from "./ViewReportOther";
import ViewAssignation from "../flota/ViewAssignation";
const Report = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();
  const [yearFuel, setYearFuel] = useState(new Date().getFullYear());
  const [monthFuel, setMonthFuel] = useState(new Date().getMonth() + 1);
  const [activeFuel, setActiveFuel] = useState(-1);
  const [itemFuel, setItemFuel] = useState(-1);

  const [itemFuelOther, setitemFuelOther] = useState(-1);
  const [yearFuelOther, setyearFuelOther] = useState(new Date().getFullYear());
  const [monthFuelOther, setmonthFuelOther] = useState(
    new Date().getMonth() + 1
  );
  const [activeFuelOther, setactiveFuelOther] = useState(-1);
  const [activesFuelOther, setactivesFuelOther] = useState([]);
  const [reportFuelOther, setreportFuelOther] = useState(null);

  const [fuelReport, setFuelReport] = useState(null);
  const [actives, setActives] = useState([]);
  const [assignation, setAssignation] = useState([]);
  const [itemsVehicle, setAllItemsVehicle] = useState([]);
  const [reportFlota, setReportFlota] = useState([]);
  const [yearOther, setYearOther] = useState(new Date().getFullYear());
  const [monthOther, setMonthOther] = useState(new Date().getMonth() + 1);
  const [activeOther, setActiveOther] = useState(-1);
  const [itemOther, setItemOther] = useState(-1);
  const [OtherReport, setOtherReport] = useState(null);
  const [activesOther, setActivesOther] = useState([]);
  const [id, setId] = useState(null);
  const [modalViewAssignation, setmodalViewAssignation] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const columnsReport = [
    {
      field: "state",
      headerName: "Vehiculos de la Flota",
      flex: 1,
      renderCell: (params) => params.row.state,
    },
    {
      field: "amount",
      headerName: "nº",
      align: "center",
      flex: 1,
      renderCell: (params) => params.row.amount,
    },
    {
      field: "color",
      headerName: "Cantidad",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Box width={"100%"} border={"1px solid #dadada"} height={"50%"}>
          <Box
            bgcolor={params.row.color}
            width={params.row.porcent + "%"}
            height={"100%"}
          >
            {" "}
          </Box>
        </Box>
      ),
    },
    {
      field: "porcent",
      headerName: "%",
      align: "center",
      flex: 1,
      renderCell: (params) => params.row.porcent + "%",
    },
  ];
  const columnsAssignation = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "proyect",
      headerName: "PROYECTO",
      flex: 1,
      renderCell: (params) => params.row.proyect,
    },
    {
      field: "driver.postulant",
      headerName: "CONDUCTOR",
      flex: 1,
      renderCell: (params) =>
        params.row.driver.postulant.name +
        " " +
        params.row.driver.postulant.surname,
    },
    {
      field: "active.description",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.active.description,
    },
    {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewAssignation(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  const handleCloseModalViewAssignation = (id) => {
    setmodalViewAssignation(false);
  };
  const handleOpenModalViewAssignation = (id) => {
    setmodalViewAssignation(true);
    setId(id);
  };
  useEffect(() => {
    const FUNCTION_GET_ASSIGNATION_BY_ID = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ASIGNATION_REGISTER,
          fetchPolicy: "network-only",
        });
        console.log(data);
        setAssignation(data.getAssignationAll);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_ITEMS_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ITEM_ONLY_VEHICLE,
          fetchPolicy: "network-only",
        });
        setAllItemsVehicle(data.getAllItemOnlyVehicle);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_REPORT_FLOTA = async () => {
      try {
        const { data } = await client.query({
          query: GET_REPORT_FLOTA,
          fetchPolicy: "network-only",
        });
        setReportFlota(data.getReportFlota);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_FUEL_REPORT = async () => {
      try {
        const { data } = await client.query({
          query: GET_FUEL_REPORT,
          variables: {
            year: yearFuel,
            item: itemFuel,
            month: monthFuel,
            active: activeFuel,
            all: activeFuel == -1 ? true : false,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getFuelSeriesReport);
        setFuelReport(data.getFuelSeriesReport);
        // setMinMaxSeriesFuel(data.getFuelSeriesReport?.minmax);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_FUEL_OTHER_REPORT = async () => {
      try {
        const { data } = await client.query({
          query: GET_OTHER_FUEL_REPORT,
          variables: {
            year: yearFuelOther,
            item: itemFuelOther,
            month: monthFuelOther,
            active: activeFuelOther,
            all: activeFuelOther == -1 ? true : false,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getSpendOperative);
        setreportFuelOther(data.getSpendOperative);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };

    const FUNCTION_GET_OTHER_REPORT = async () => {
      try {
        const { data } = await client.query({
          query: GET_OTHER_REPORT,
          variables: {
            year: yearOther,
            item: itemOther,
            month: monthOther,
            active: activeOther,
            all: activeOther == -1 ? true : false,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getOtherSeriesReport);
        setOtherReport(data.getOtherSeriesReport);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ACTIVE__FUEL_OTHER_BY_ITEM_ID = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVES_BY_ITEM_ID,
          variables: {
            getActiveByItemIdId: itemFuelOther,
          },
          fetchPolicy: "network-only",
        });
        setActives(data.getActiveByItemId);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ACTIVE_BY_ITEM_ID = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVES_BY_ITEM_ID,
          variables: {
            getActiveByItemIdId: itemFuel,
          },
          fetchPolicy: "network-only",
        });
        setActives(data.getActiveByItemId);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ACTIVE_OTHER_BY_ITEM_ID = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVES_BY_ITEM_ID,
          variables: {
            getActiveByItemIdId: itemOther,
          },
          fetchPolicy: "network-only",
        });
        setActivesOther(data.getActiveByItemId);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_ALL_ITEMS_VEHICLE();
    FUNCTION_GET_ACTIVE_BY_ITEM_ID();
    FUNCTION_GET_FUEL_REPORT();
    FUNCTION_GET_ACTIVE_OTHER_BY_ITEM_ID();
    FUNCTION_GET_OTHER_REPORT();
    FUNCTION_GET_ASSIGNATION_BY_ID();
    FUNCTION_GET_REPORT_FLOTA();
    FUNCTION_GET_ACTIVE__FUEL_OTHER_BY_ITEM_ID();
    FUNCTION_GET_FUEL_OTHER_REPORT();
  }, [
    itemFuel,
    yearFuel,
    monthFuel,
    activeFuel,
    itemOther,
    yearOther,
    monthOther,
    activeOther,
    itemFuelOther,
    yearFuelOther,
    monthFuelOther,
    activeFuelOther,
  ]);
  return (
    <>
      <ModalUtil
        isOpen={modalViewAssignation}
        onClose={handleCloseModalViewAssignation}
        Component={ViewAssignation}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setnewAssignation: null,
            handleCloseModalViewAssignation: handleCloseModalViewAssignation,
          })
        }
        id={id}
      ></ModalUtil>{" "}
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/reporte" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
            }}
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
            >
              ESTADO DE LA FLOTA
            </Box>
            <DataGrid
              loading={!reportFlota}
              getRowId={(row) => row.state}
              rows={reportFlota || []}
              columns={columnsReport}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
            }}
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
            >
              ASIGNACIONES
            </Box>
            <DataGrid
              loading={!assignation}
              getRowId={(row) => row.id}
              rows={assignation || []}
              columns={columnsAssignation}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              py={"10px"}
            >
              GRÁFICA DE COMBUSTIBLE
            </Box>
            <ViewReportFuel
              items={itemsVehicle}
              itemFuel={itemFuel}
              setItemFuel={setItemFuel}
              yearFuel={yearFuel}
              setYearFuel={setYearFuel}
              monthFuel={monthFuel}
              setMonthFuel={setMonthFuel}
              activeFuel={activeFuel}
              setActiveFuel={setActiveFuel}
              actives={actives}
              setActives={setActives}
            />
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)"
              gap="20px"
            >
              {fuelReport?.serie ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {" "}
                  <LineChart
                    data={fuelReport.serie}
                    title={"Combustible"}
                    /*  range={minmaxseriesFuel} */
                    isSingle={false}
                  />{" "}
                </Box>
              ) : (
                <Box textAlign={"center"}>No hay datos</Box>
              )}
              {fuelReport?.bar ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <IndicartorsBar
                    data={fuelReport.bar.serie}
                    categories={fuelReport.bar.categories}
                  />{" "}
                </Box>
              ) : (
                <Box textAlign={"center"}>No hay datos</Box>
              )}
            </Box>
          </Box>
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              py={"10px"}
            >
              GRÁFICA DE OTROS GASTOS
            </Box>
            <ViewReportOther
              items={itemsVehicle}
              itemFuel={itemOther}
              setItemFuel={setItemOther}
              yearFuel={yearOther}
              setYearFuel={setYearOther}
              monthFuel={monthOther}
              setMonthFuel={setMonthOther}
              activeFuel={activeOther}
              setActiveFuel={setActiveOther}
              actives={activesOther}
              setActives={setActivesOther}
            />
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)"
              gap="20px"
            >
              {OtherReport?.serie ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {" "}
                  <LineChart
                    data={OtherReport.serie}
                    title={"Combustible"}
                    /*  range={minmaxseriesFuel} */
                    isSingle={false}
                  />{" "}
                </Box>
              ) : (
                <Box textAlign={"center"}>No hay datos</Box>
              )}

              {OtherReport?.bar ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <IndicartorsBar
                    data={OtherReport.bar.serie}
                    categories={OtherReport.bar.categories}
                  />{" "}
                </Box>
              ) : (
                <Box textAlign={"center"}>No hay datos</Box>
              )}
            </Box>
            {/*   <Box
        fontSize={"12px"}
        fontWeight={"700"}
        textAlign={"center"}
        flex={"1"}
      >
        GRÁFICA DE OTROS GASTOS
      </Box>
      {id && seriesOtherExpense ? (
        <LineChart
          data={seriesOtherExpense}
          title={"Combustible"}
          range={minmaxseriesOtherExpense}
          isSingle={true}
        />
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          height={"100%"}
          alignItems={"center"}
        >
          Seleccione un activo
        </Box>
      )} */}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              py={"10px"}
            >
              GRÁFICA DE GASTO OPERATIVO
            </Box>
            <ViewReportOther
              items={itemsVehicle}
              itemFuel={itemFuelOther}
              setItemFuel={setitemFuelOther}
              yearFuel={yearFuelOther}
              setYearFuel={setyearFuelOther}
              monthFuel={monthFuelOther}
              setMonthFuel={setmonthFuelOther}
              activeFuel={activeFuelOther}
              setActiveFuel={setactiveFuelOther}
              actives={activesFuelOther}
              setActives={setactivesFuelOther}
            />
            {reportFuelOther && (
              <LineChart
                data={reportFuelOther}
                title={"Otros Gastos"}
                /*  range={minmaxseriesFuel} */
                isSingle={false}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Report;
