import React,{ useState } from "react";
import { COMMENT_SCHEMA_YUP } from "./YupValidation";
import { Box, TextField, Button } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { useFormik } from "formik";
import LoopIcon from '@mui/icons-material/Loop';
import { CREATE_COMMENT } from "./QueryFlota";
const CreateComment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewComment,
    handleCloseModalComment,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(true);
  const formikComment = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: COMMENT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { comment } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_COMMENT,
          variables: {
            description: comment.toUpperCase(),
            active: id,
          },
        });
        setNewComment(response.data.createComment);
        handleCloseModalComment();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    visibility?<form onSubmit={formikComment.handleSubmit}>     
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="20px">
        {" "}
        <TextField
          id="comment"
          name="comment"
          label="COMENTARIO"
          variant="outlined"
          value={formikComment.values.comment}
          onChange={formikComment.handleChange}
          error={
            formikComment.touched.comment &&
            Boolean(formikComment.errors.comment)
          }
          helperText={
            formikComment.touched.comment && formikComment.errors.comment
          }
        />
        <Box display={"flex"} justifyContent={"end"}>
          <Button variant="contained" color="success" type="submit" disabled={formikComment.isSubmitting}>
          {formikComment.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>:<Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
  );
};

export default CreateComment;
