import React, { useState, useEffect, useMemo } from "react";
import { GET_PERNOCT_BY_ID } from "./QueryPernoct";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useApolloClient } from "@apollo/client";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  tableColumn: {
    flexDirection: "column",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  column: {
    flexDirection: "column",
    flex: 1,
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    paddingLeft: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellFlex: {
    flex: 1,
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "8px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});
const PDFPernoct = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [Pernoct, setPernoct] = useState([]);

  useEffect(() => {
    const getPernoct = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERNOCT_BY_ID,
          variables: {
            getPernoctByIdId: id,
          },
        });
        console.log(data.getPernoctByID);
        setPernoct(data.getPernoctByID);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };

    getPernoct(id);
  }, []);
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>CONTROL DE PERNOCTE</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS GENERALES</Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              SUPERVISOR:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {Pernoct.pernoct?.supervisor?.postulant.name}{" "}{Pernoct.pernoct?.supervisor?.postulant.surname}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              FECHA INICIO RUTA PERNOCTE:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {new Date(Pernoct.pernoct?.datebegingrute).toLocaleDateString(
                "es-ES"
              )}{" "}
            </Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              PROYECTO O RUTA:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {Pernoct.pernoct.project
}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              LUGAR:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {Pernoct.pernoct.place}
            </Text>
          </View>
          <View style={styles.table}>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              FECHA INICIO PERNOCTE:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {new Date(Pernoct.pernoct?.datebeginpernoct).toLocaleDateString("es-ES")}{" "}
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              FECHA FINAL PERNOCTE:
            </Text>
            <Text style={[styles.text, styles.cell, styles.cellFlex]}>
              {new Date(Pernoct.pernoct?.dateendpernoct).toLocaleDateString("es-ES")}{" "}
            </Text>
          </View>
     
          <View style={[styles.title, styles.textTitle]}>
            <Text>DATOS DE LOS PARTICIPANTES DEL PERNOCTE :</Text>
          </View>
          <View style={styles.tableColumn}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles.text, { width: "25px" }, styles.cell]}>
                N°:
              </Text>
              <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                {" "}
                NOMBRES Y APELLIDOS
              </Text>
              <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                {" "}
                DNI
              </Text>        
              <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                {" "}
                HORA INICIO
              </Text>  
              <Text style={[styles.text, styles.cell, { width: "50px" }]}>
                {" "}
                FIRMA
              </Text>  
              <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                {" "}
                HORA FIN
              </Text>      
              <Text style={[styles.text, styles.cell, { width: "50px" }]}>
                {" "}
                FIRMA
              </Text>   
            </View>
            {Pernoct.personalTimes?.map((el, index) => (
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.text, styles.cell, { width: "25px" }]}>
                  {index + 1}
                </Text>
                <Text style={[styles.text, styles.cell, styles.cellFlex]}>
                  {el.personal?.postulant?.name +" " + el.personal?.postulant?.surname}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                  {el.personal?.postulant?.doi}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                  {el.datebegin}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "50px" }]}>
                  {" "}
                </Text>

                <Text style={[styles.text, styles.cell, { width: "60px" }]}>
                  {el.dateend}
                </Text>
                <Text style={[styles.text, styles.cell, { width: "50px" }]}>
                  {" "}
                </Text>
              </View>
            ))}
          </View>
          <View style={[styles.textTitle,{marginBottom:"3px"}]}>
            <Text>
            Tomar en cuenta:
            </Text>
          </View>
          <View style={[styles.textTitle,{border:"1px solid #000",padding:"3px"}]}>
            <Text>
            1. La escolta es responsable de hacer el control respectivo para el cumplimiento del presente documento. 
            {"\n"} 2. Los operadores deben cumplir con las normas de la zona
de pernocte. {"\n"}3. De incumplir con las normas de seguridad establecidas por Coporación de Servicios Moquegua será merecedor de una sanción pre-escrita por la
empresa, si es reincidente se solicitará su retiro inmediato
            </Text>
          </View>

          <View style={[styles.table]}>
            <View style={styles.column}>
              <Text style={[styles.headerCell, styles.textTitle]}>
                FIRMA SUPERVISOR SOMA
              </Text>
              <Text style={[styles.text, styles.cellEmpty, styles.sign]}>
                -
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  )
}

export default PDFPernoct