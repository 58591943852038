import React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import LoopIcon from '@mui/icons-material/Loop';
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { PROFESION_YUP } from "./YupValidation";
import Alerts from "components/Alerts";
import { CREATE_PROFESSION } from "./QueryPersonal";
const CreateProfesion = ({
  professionLevel,
  professionState,
  alertCustom,
  setAlertCustom,
  id,
  setNewProfession,
  setErrorId,

}) => {
  const client = useApolloClient();

  const formikProfesion = useFormik({
    initialValues: {
      personal: "",
      description: "",
      level: "",
      state: "",
      place: "",
    },
    validationSchema: PROFESION_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { description, level, state, place } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_PROFESSION,
            variables: {
              personal: id,
              description: description.toUpperCase(),
              level: level ? level : null,
              state: state ? state : null,
              place: place.toUpperCase(),
            },
          });
          setNewProfession(response.data.createProfessions);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikProfesion.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <TextField
          id="description"
          name="description"
          label="PROFESION"
          variant="outlined"
          value={formikProfesion.values.description}
          onChange={formikProfesion.handleChange}
          error={
            formikProfesion.touched.description &&
            Boolean(formikProfesion.errors.description)
          }
          helperText={
            formikProfesion.touched.description &&
            formikProfesion.errors.description
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">NIVEL</InputLabel>
          <Select
            id="level"
            value={formikProfesion.values.level}
            name="level"
            onChange={formikProfesion.handleChange}
            label="NIVEL"
            error={
              formikProfesion.touched.level &&
              Boolean(formikProfesion.errors.level)
            }
          >
            {professionLevel?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProfesion.touched.level && formikProfesion.errors.level ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProfesion.touched.level && formikProfesion.errors.level}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ESTADO</InputLabel>
          <Select
            id="state"
            value={formikProfesion.values.state}
            name="state"
            label="ESTADO"

            onChange={formikProfesion.handleChange}
            error={
              formikProfesion.touched.state &&
              Boolean(formikProfesion.errors.state)
            }
          >
            {professionState?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProfesion.touched.state && formikProfesion.errors.state ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProfesion.touched.state && formikProfesion.errors.state}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="place"
          name="place"
          label="LUGAR"
          variant="outlined"
          value={formikProfesion.values.place}
          onChange={formikProfesion.handleChange}
          error={
            formikProfesion.touched.place &&
            Boolean(formikProfesion.errors.place)
          }
          helperText={
            formikProfesion.touched.place && formikProfesion.errors.place
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikProfesion.isSubmitting}>
          {formikProfesion.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateProfesion;
