import React from "react";
import { useState, useEffect } from "react";
import { GET_SUPPLIER_BY_ID } from "./QueryProvider";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
const ViewProvider = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [provider, setprovider] = useState({});
  useEffect(() => {
    const getProviderById = async () => {
      try {
        const { data } = await client.query({
          query: GET_SUPPLIER_BY_ID,
          variables: {
            getProviderByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getProviderById)
        setprovider(data.getProviderById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProviderById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">RAZON SOCIAL</label>
            <TextField disabled value={provider?.businessname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">RUC</label>
            <TextField disabled value={provider?.ruc} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={provider?.type.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">CATEGORIA</label>
            <TextField disabled value={provider?.category.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">DEPARTAMENTO</label>
            <TextField disabled value={provider?.departament.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROVINCIA</label>
            <TextField disabled value={provider?.province.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">DISTRITO</label>
            <TextField disabled value={provider?.district.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">DIRECCION</label>
            <TextField disabled value={provider?.address} />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">BANCO EN SOLES</label>
            <TextField disabled value={provider?.banksoles?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">N° DE CUENTA</label>
            <TextField disabled value={provider?.numbersoles} />
          </Box>
          <Box sx={style}>
            <label for="my-input">CCI</label>
            <TextField disabled value={provider?.ccisoles} />
          </Box>
          <Box sx={style}>
            <label for="my-input">BANCO EN DOLARES</label>
            <TextField disabled value={provider?.bankdollar?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">N° DE CUENTA</label>
            <TextField disabled value={provider?.numberdollar} />
          </Box>
          <Box sx={style}>
            <label for="my-input">CCI</label>
            <TextField disabled value={provider?.ccidollar} />
          </Box>
        </Box>
       {/*  <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">GRUPO</label>
            <TextField
              disabled
              value={provider?.group?.map((el) => el.description + " ")}
            />
          </Box>
        </Box> */}
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):(
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoopIcon />
      </Box>
    )
  );
};

export default ViewProvider;
