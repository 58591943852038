import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormHelperText } from "@mui/material";
import {
  GET_DOCUMENT_IMAGE_BY_PERSONAL_ID,
  UPDATE_DOCUMENT_CADUCITY_EMITION_BY_PERSONAL_ID,
} from "./QueryDocument";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import { Height } from "@mui/icons-material";
import { DOCUMENT_SHOW_SCHEMA_YUP } from "./YupValidation";
// Create styles
/* const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
}); */
const AssignDocumentPersonal = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleCloseModal,
    setNewAssignRequeriment,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [documentFicha, setdocumentFicha] = useState(null);
  const formikDocument = useFormik({
    initialValues: {
      emitiondate: new Date(),
      caducitydate: new Date(),
    },
    validationSchema: DOCUMENT_SHOW_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const { emitiondate, caducitydate } = values;
      try {
        const response = await client.mutate({
          mutation: UPDATE_DOCUMENT_CADUCITY_EMITION_BY_PERSONAL_ID,
          variables: {
            updateCaducityEmitionDatePersonalId: id,
            emitiondate: emitiondate,
            caducitydate: caducitydate,
          },
          fetchPolicy: "network-only",
        });
        console.log(response.data.updateCaducityEmitionDatePersonal);
        setNewAssignRequeriment(
          response.data.updateCaducityEmitionDatePersonal
        );
        handleCloseModal();
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    console.log(id);
    const getdocumentFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_DOCUMENT_IMAGE_BY_PERSONAL_ID,
          variables: {
            getDocumentImageByPersonalIdId: id,
          },
        });
        console.log(data.getDocumentImageByPersonalId);
        setdocumentFicha(data.getDocumentImageByPersonalId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getdocumentFicha(id);
  }, []);

  return (
    visibility ? (
      <form onSubmit={formikDocument.handleSubmit}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          mb={"20px"}
          bgcolor={"#525659"}
        >
          <Box
            width={"400px"}
            height={"500px"}
            border={"2px solid #000"}
            bgcolor={"#fff"}
          >
            <img
              src={documentFicha?.url}
              alt={documentFicha?.document.description}
              loading="lazy"
              width={"100%"}
            />
          </Box>

          {/*   <PDFViewer>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
              <Text>Section #2</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer> */}
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          flexDirection={"column"}
          gap={"10px"}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikDocument.values.emitiondate}
              id="emitiondate"
              name="emitiondate"
              label="FECHA DE EMISION"
              inputFormat="dd-MM-yyyy"
              onChange={(val) => {
                formikDocument.setFieldValue("emitiondate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikDocument.values.caducitydate}
              id="caducitydate"
              name="caducitydate"
              label="FECHA DE CADUCIDAD"
              inputFormat="dd-MM-yyyy"
              onChange={(val) => {
                formikDocument.setFieldValue("caducitydate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          {formikDocument.touched.caducitydate &&
          formikDocument.errors.caducitydate ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikDocument.touched.caducitydate &&
                formikDocument.errors.caducitydate}
            </FormHelperText>
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"10px"} gap={"10px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          <Button variant="contained" color="success" type="submit" disabled={formikDocument.isSubmitting}>
          {formikDocument.isSubmitting ?<RotatingLoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </form>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default AssignDocumentPersonal;
