import React, { useState, useEffect, useMemo } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  GET_PERSONAL_BY_ID,
  GET_LANGUAGE_BY_PERSONAL_ID,
  GET_SPOUSE_BY_PERSONAL_ID,
  GET_PROFESSION_BY_PERSONAL_ID,
  GET_POLICYRECORD_BY_PERSONAL_ID,
  GET_MOVILITY_BY_PERSONAL_ID,
  GET_PARENT_BY_PERSONAL_ID,
  GET_EXTRA_BY_PERSONAL_ID,
  GET_CONTACT_BY_PERSONAL_ID,
  GET_CHILDREN_BY_PERSONAL_ID,
} from "./../FichaPersonal/QueryPersonal";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: "30px",
    paddingVertical: "20px",
  },
  table: {
    flexDirection: "row",
    marginBottom: "5px",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  column: {
    flexDirection: "column",
    flex: 1,
  },
  headerCell: {
    backgroundColor: "#d9d9d9",
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  cellEmpty: {
    paddingVertical: "3px",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
  },
  text: {
    fontSize: "9px",
  },
  sign: {
    paddingVertical: "10px",
  },
  textTitle: {
    fontSize: "9px",
  },
  textHeader: {
    fontSize: "12px",
    textAlign: "center",
  },
  title: {
    backgroundColor: "#d9d9d9",
    textAlign: "center",
    borderRight: "1px solid #000",
    borderTop: "1px solid #000",
    borderLeft: "1px solid #000",
    paddingVertical: "5px",
  },
  image: {
    width: "100%",
    height: "50pt",
  },
});
const TemplatePDF = ({ id, client }) => {
  const [visibility, setvisibility] = useState(false);
  const [personalFicha, setpersonalFicha] = useState(null);
  const [spouseFicha, setspouseFicha] = useState(null);
  const [parentFicha, setparentFicha] = useState(null);
  const [extraFicha, setextraFicha] = useState(null);
  const [contactFicha, setcontactFicha] = useState(null);
  const [movilityFicha, setmovilityFicha] = useState(null);
  const [policyRecordFicha, setpolicyRecordFicha] = useState(null);
  const [childrenFicha, setchildrenFicha] = useState(null);
  const [languageFicha, setlanguageFicha] = useState(null);
  const [professionFicha, setprofessionFicha] = useState(null);
  useEffect(() => {
    const getchildrenFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_CHILDREN_BY_PERSONAL_ID,
          variables: {
            getChildrenByPersonalIdId: id,
          },
        });
        console.log(data.getChildrenByPersonalId);
        setchildrenFicha(data.getChildrenByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getchildrenFicha(id);
    const getlanguageFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_LANGUAGE_BY_PERSONAL_ID,
          variables: {
            getLanguageByPersonalIdId: id,
          },
        });
        console.log(data.getLanguageByPersonalId);
        setlanguageFicha(data.getLanguageByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getlanguageFicha(id);
    const getprofessionFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PROFESSION_BY_PERSONAL_ID,
          variables: {
            getProfessionByPersonalIdId: id,
          },
        });
        console.log(data.getProfessionByPersonalId);
        setprofessionFicha(data.getProfessionByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getprofessionFicha(id);
    const getmovilityFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_MOVILITY_BY_PERSONAL_ID,
          variables: {
            getMovilityByPersonalIdId: id,
          },
        });
        console.log(data.getMovilityByPersonalId);
        setmovilityFicha(data.getMovilityByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getmovilityFicha(id);
    const getcontactFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_CONTACT_BY_PERSONAL_ID,
          variables: {
            getContactByPersonalIdId: id,
          },
        });
        console.log(data.getContactByPersonalId);
        setcontactFicha(data.getContactByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getcontactFicha(id);
    const getextraFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_EXTRA_BY_PERSONAL_ID,
          variables: {
            getExtraByPersonalIdId: id,
          },
        });
        console.log(data.getExtraByPersonalId);
        setextraFicha(data.getExtraByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getextraFicha(id);
    const getpolicyRecordFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_POLICYRECORD_BY_PERSONAL_ID,
          variables: {
            getPolicyRecordByPersonalIdId: id,
          },
        });
        console.log(data.getPolicyRecordByPersonalId);
        setpolicyRecordFicha(data.getPolicyRecordByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getpolicyRecordFicha(id);
    const getPersonalFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERSONAL_BY_ID,
          variables: {
            getPersonalByIdId: id,
          },
        });
        console.log(data.getPersonalByID);
        setpersonalFicha(data.getPersonalByID);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getspouseFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_SPOUSE_BY_PERSONAL_ID,
          variables: {
            getSpouseByPersonalIdId: id,
          },
        });
        console.log(data.getSpouseByPersonalId);
        setspouseFicha(data.getSpouseByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getparentFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PARENT_BY_PERSONAL_ID,
          variables: {
            getParentByPersonalIdId: id,
          },
        });
        console.log(data.getParentByPersonalId);
        setparentFicha(data.getParentByPersonalId);
      } catch (error) {
        console.log(error.message);
      }
    };
    getparentFicha(id);
    getspouseFicha(id);
    getPersonalFicha(id);
  }, []);
  const calcularEdad = (fechaNacimiento) => {
    const fechaActual = new Date();
    const nacimiento = new Date(fechaNacimiento);

    return fechaActual.getFullYear() - nacimiento.getFullYear();
  };
  return (
    visibility && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={[styles.table, { marginBottom: "20px" }]}>
            <View style={styles.row}>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 1,
                    padding: "5px",
                    borderBottom: "1px solid #000",
                  },
                ]}
              >
                <Image
                  style={[styles.image]}
                  src="https://res.cloudinary.com/dr8m7eoce/image/upload/v1695246576/corpmoquegua/logo_h17kvt.jpg"
                />
              </View>
              <View
                style={[
                  styles.column,
                  {
                    flexGrow: 4,
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                <Text style={[styles.textHeader]}>FICHA PERSONAL</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { flexGrow: 1 },
                  { borderLeft: "1px solid #000" },
                ]}
              >
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Codigo:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Versión:
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  Fecha:
                </Text>
              </View>
              <View style={[styles.column, { flexGrow: 1 }]}>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  SSOMA-F-01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  01
                </Text>
                <Text style={[styles.text, styles.cell, { flexGrow: 1 }]}>
                  {new Date().toLocaleDateString("es-ES")}
                </Text>
              </View>
            </View>
          </View>
       {personalFicha ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> DATOS GENERALES</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Nombres:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    Fecha de Nacimiento:
                  </Text>
                  <Text style={[styles.text, styles.cell]}>Departamento:</Text>
                  <Text style={[styles.text, styles.cell]}>Dni:</Text>
                  <Text style={[styles.text, styles.cell]}>Direccion:</Text>
                  <Text style={[styles.text, styles.cell]}>Estado Civil:</Text>
                  <Text style={[styles.text, styles.cell]}>Sexo:</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.postulant?.name)}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {new Date(personalFicha?.postulant?.dob).toLocaleDateString(
                      "es-ES"
                    )}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.departament?.description)}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {personalFicha?.postulant?.doi}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.address)}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.civilstatus?.description)}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.gender?.description)}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}> Apellido:</Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>Provincia: </Text>
                  <Text style={[styles.text, styles.cell]}>Teléf. Fijo:</Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>Edad: </Text>
                  <Text style={[styles.text, styles.cell]}>Talla: </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {(personalFicha?.postulant?.surname)}
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {personalFicha?.province?.description}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {personalFicha?.postulant?.phone}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {calcularEdad(personalFicha?.postulant?.dob)}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {personalFicha?.stature}{" "}
                  </Text>
                </View>

                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>Distrito</Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>Contextura</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {personalFicha?.district?.description}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {personalFicha?.contexture?.description}{" "}
                  </Text>
                </View>
              </View>
            </>
          ) : null}
              {extraFicha ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> OTROS DATOS</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>RUC:</Text>
                  <Text style={[styles.text, styles.cell]}>SSALUD:</Text>
                  <Text style={[styles.text, styles.cell]}>ONP/AFP:</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {extraFicha?.ruc}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {extraFicha?.ess}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {extraFicha?.pensionfund.description}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>CUSSPP:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    FECHA AFILIACIÓN:
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {extraFicha?.cusspp}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {new Date(extraFicha?.date).toLocaleDateString("es-ES")}
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                </View>
              </View>
            </>
          ) : null}
          {professionFicha.length ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> INSTRUCCION</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Profesion:</Text>
                  {professionFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.description}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Nivel:</Text>
                  {professionFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.level.description}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Estado:</Text>
                  {professionFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.state.description}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Lugar:</Text>
                  {professionFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>{it.place} </Text>
                  ))}
                </View>
              </View>
            </>
          ) : null}
          {languageFicha.length ? (
            <>
              {" "}
              <View style={[styles.title, styles.textTitle]}>
                <Text> IDIOMA</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Idioma:</Text>
                  {languageFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.language.description.toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Nivel:</Text>
                  {languageFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.level.description.toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
              </View>
            </>
          ) : null}
          {childrenFicha.length ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> DATOS DE LOS HIJOS</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    Nombre y Apellidos:
                  </Text>
                  {childrenFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {(it.name + " " + it.surname).toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    Fecha Nacimiento:
                  </Text>
                  {childrenFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {new Date(it.dob).toLocaleDateString("es-ES")}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Edad:</Text>
                  {childrenFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {calcularEdad(it.dob)}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Sexo:</Text>
                  {childrenFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.gender.description.toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Dni:</Text>
                  {childrenFicha?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>{it.doi} </Text>
                  ))}
                </View>
              </View>
            </>
          ) : null}
          {contactFicha ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> PERSONA A LLAMAR EN CASO DE EMERGENCIA</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Nombre:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(contactFicha?.name).toLowerCase()}{" "}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Apellido:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(contactFicha?.surname).toLowerCase()}{" "}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Telefono:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    {contactFicha?.phone}{" "}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Relacion:</Text>
                  <Text style={[styles.text, styles.cell]}>
                    {(contactFicha?.relation.description).toLowerCase()}{" "}
                  </Text>
                </View>
              </View>
            </>
          ) : null}
          {movilityFicha ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> MOVILIDAD</Text>
              </View>
              <View
                style={[
                  styles.text,
                  {
                    borderRight: "1px solid #000",
                    borderLeft: "1px solid #000",
                    borderTop: "1px solid #000",
                    paddingVertical: "3px",
                  },
                ]}
              >
                <Text>Posee Movilidad Propia:</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Si</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {movilityFicha?.own == true ? "X" : ""}{" "}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>No</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {movilityFicha?.own == false ? "X" : ""}{" "}
                  </Text>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    Nro. De Licencia de Conducir:
                  </Text>
                  <Text style={[styles.text, styles.cell]}> Marca</Text>
                  <Text style={[styles.text, styles.cell]}> Placa</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {movilityFicha?.licence}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {(movilityFicha?.brand).toLowerCase()}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {movilityFicha?.plate}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    Tipo de vehículo:
                  </Text>
                  <Text style={[styles.text, styles.cell]}> Año</Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {(movilityFicha?.type.description).toLowerCase()}{" "}
                  </Text>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {movilityFicha?.year}
                  </Text>
                  <Text style={[styles.text, styles.cell]}> </Text>
                </View>
              </View>
            </>
          ) : null}
          {policyRecordFicha ? (
            <>
              <View style={[styles.title, styles.textTitle]}>
                <Text> ANTECEDENTES: Policiales y/o Penal:</Text>
              </View>
              <View
                style={[
                  styles.text,
                  {
                    borderRight: "1px solid #000",
                    borderLeft: "1px solid #000",
                    borderTop: "1px solid #000",
                    paddingVertical: "3px",
                  },
                ]}
              >
                <Text>Posee:</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Si</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {policyRecordFicha?.have == true ? "X" : ""}{" "}
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>No</Text>
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>
                    {" "}
                    {policyRecordFicha?.have == false ? "X" : ""}{" "}
                  </Text>
                </View>
              </View>
              <View style={[styles.table, { marginTop: "60px" }]}>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Descripcion:</Text>
                  {policyRecordFicha?.register?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.sentence.description.toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
                <View style={styles.column}>
                  <Text style={[styles.text, styles.cell]}>Respuesta:</Text>
                  {policyRecordFicha?.register?.map((it, index) => (
                    <Text style={[styles.text, styles.cell]}>
                      {it.answer.toLowerCase()}{" "}
                    </Text>
                  ))}
                </View>
              </View>
            </>
          ) : null} 
        </Page>
      </Document>
    )
  );
};

export default TemplatePDF;
