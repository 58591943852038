import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  userId: null,
  userRole: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload
    }
  },
});

export const { setMode, setUserId, setUserRole } = globalSlice.actions;

export default globalSlice.reducer;