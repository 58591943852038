import React from "react";
import { useState, useEffect } from "react";
import { GET_CLASS_BY_ID } from "./QueryClass";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import LoopIcon from '@mui/icons-material/Loop';
import { Box } from "@mui/material";
const ViewClass = ({ id, onClose,params }) => {
  const {setAlertCustom,alertCustom}=params
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [classs, setclasss] = useState({});

  useEffect(() => {
    const getClassById = async () => {
      try {
        const { data } = await client.query({
          query: GET_CLASS_BY_ID,
          variables: {
            getClassByIdId: id,
          },
        });
      
        setclasss(data.getClassById);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getClassById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    visibility ?(
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">CLASE</label>
            <TextField disabled value={classs?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">CODIGO</label>
            <TextField disabled value={classs?.code} />
          </Box>
          <Box sx={style}>
            <label for="my-input">ACRONIMO</label>
            <TextField disabled value={classs?.acro} />
          </Box>
          <Box sx={style}>
            <label for="my-input">GRUPO</label>
            <TextField disabled value={classs?.group.description} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewClass;
