import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_ACTIVE_VEHICLE_BY_ITEM_ID } from "./QueryItem";
import LoopIcon from "@mui/icons-material/Loop";
import {
  GET_COMPONENT_FOR_ACTIVE_VEHICLE,
  GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE,
} from "./../../operation/flota/QueryFlota";
import ViewActive from "scenes/operation/flota/ViewActive";
import ViewComponent from "scenes/operation/flota/ViewComponent";
const ViewActiveVehicle = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModalView } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [activecomponent, setActiveComponent] = useState([]);
  const [codegroup, setcodegroup] = useState(null);
  useEffect(() => {
    const getPostulantById = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVE_VEHICLE_BY_ITEM_ID,
          fetchPolicy: "network-only",
          variables: {
            getActiveVehicleByItemIdId: id,
          },
        });
        setActiveVehicle(data.getActiveVehicleByItemId);
        console.log(data.getActiveVehicleByItemId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPostulantById();
  }, []);
  const FUNCTION_GET_COMPONENT_FOR_ACTIVE_VEHICLE = async (activeId, code) => {
    try {
      const customVariable =
        code == 10
          ? "getComponentsForActiveVehicleId"
          : "getOneActiveNoVehicleByItemIdId";
      const variables = {
        [customVariable]: activeId, // Usar el nombre de variable dinámico
      };
      setcodegroup(code);
      const { data } = await client.query({
        query:
          code == 10
            ? GET_COMPONENT_FOR_ACTIVE_VEHICLE
            : GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE,
        variables: variables,
        fetchPolicy: "network-only",
      });
      console.log(data);
      setActiveComponent(
        code == 10
          ? data.getComponentsForActiveVehicle
          : data.getOneActiveNoVehicleByItemId
      );
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return visibility ? (
    <>
      <Box>
        <Box mb={"20px"}>
          {activeVehicle?.length ? (
            <Autocomplete
              freeSolo
              id="vehicle"
              name="vehicle"
              disableClearable
              options={activeVehicle?.map(
                (option) =>
                  new Object({
                    label:
                      "active:" +
                      option.description +
                      " / " +
                      "placa:" +
                      option.plate,
                    value: option.id,
                    code: option.item.group.code,
                  })
              )}
              onChange={(event, newValue) => {
                console.log(newValue.value);
                setActiveId(newValue.value);
                FUNCTION_GET_COMPONENT_FOR_ACTIVE_VEHICLE(
                  newValue.value,
                  newValue.code
                );
                //formikAssembly.setFieldValue("vehicle", newValue.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por descripcion/placa"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          ) : (
            <p>No existe Activos para el Item </p>
          )}
        </Box>
        <Box>
          {activeId && activeVehicle.length ? (
            codegroup == 10 ? (
              <ViewActive activecomponent={activecomponent} />
            ) : (
              <ViewComponent activecomponent={activecomponent} />
            )
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              height={"100%"}
              alignItems={"center"}
            >
              Selecciona un activo
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={"25px"} display={"flex"} justifyContent={"end"} gap={"15px"}>
        <Button variant="contained" onClick={handleCloseModalView}>
          CERRAR
        </Button>
      </Box>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LoopIcon />
    </Box>
  );
};

export default ViewActiveVehicle;
