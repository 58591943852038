import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import Autocomplete from "@mui/material/Autocomplete";
import { PERNOCT_SCHEMA_YUP } from "./YupPernoct";
import { CREATE_PERNOCT } from "./QueryPernoct";
import { useUser } from "hooks/useUser";
const CreatePernoct = ({
  setAlertCustom,
  alertCustom,
  personal,
  setnewPernoct,
}) => {
  const client = useApolloClient();
  const { user } = useUser();
  const autocompletepersonalRef = useRef(null);
  const formikTraining = useFormik({
    initialValues: {
      supervisor: {
        label: "",
        id: !user.admin ? user.personal.id : "",
      },
      project: "",
      place: "",
      datebegingrute: new Date(),
      datebeginpernoct: new Date(),
      dateendpernoct: new Date(),
    },
    validationSchema: PERNOCT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const {
        supervisor,
        project,
        place,
        datebegingrute,
        datebeginpernoct,
        dateendpernoct,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_PERNOCT,
          variables: {
            supervisor: supervisor.id,
            project,
            place,
            datebegingrute,
            datebeginpernoct,
            dateendpernoct,
          },
        });
        setnewPernoct(response.data.createPernoct);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikTraining.handleSubmit}>
      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
      >
        {user.admin?<>
          {personal?.length ? (
            <Autocomplete
              freeSolo
              id="supervisor"
              name="supervisor"
              ref={autocompletepersonalRef}
              value={formikTraining.values.supervisor.label}
              disableClearable
              options={personal?.map(
                (option) =>
                  new Object({
                    label:
                      "nombres:" +
                      option.postulant.name +
                      " / " +
                      "apellidos:" +
                      option.postulant.surname +
                      " / " +
                      "dni:" +
                      option.postulant.doi,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                formikTraining.setFieldValue("supervisor", {
                  label: newValue.label,
                  id: newValue.value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={
                    formikTraining.touched.supervisor?.id &&
                    Boolean(formikTraining.errors.supervisor?.id)
                  }
                  helperText={
                    formikTraining.touched.supervisor?.id &&
                    formikTraining.errors.supervisor?.id
                  }
                />
              )}
            />
          ) : (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                No hay Datos para mostrar
              </p>
            </FormHelperText>
          )}
        </>:null}
        <TextField
          id="project"
          fullWidth
          name="project"
          label=" PROYECTO O RUTA"
          variant="outlined"
          value={formikTraining.values.project}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.project &&
            Boolean(formikTraining.errors.project)
          }
          helperText={
            formikTraining.touched.project && formikTraining.errors.project
          }
        />
      </Box>
      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        {" "}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikTraining.values.datebegingrute}
            id="datebegingrute"
            name="datebegingrute"
            label="FECHA DE INICIO  DE RUTA"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikTraining.setFieldValue("datebegingrute", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikTraining.values.datebeginpernoct}
            id="datebeginpernoct"
            name="datebeginpernoct"
            label="FECHA DE INICIO  DE PERNOCTE"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikTraining.setFieldValue("datebeginpernoct", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikTraining.values.dateendpernoct}
            id="dateendpernoct"
            name="dateendpernoct"
            label="FECHA DE FIN DE PERNOCTE"
            inputFormat="dd-MM-yyyy"
            onChange={(val) => {
              formikTraining.setFieldValue("dateendpernoct", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
      >
        {" "}
        <TextField
          id="place"
          fullWidth
          name="place"
          label="LUGAR"
          variant="outlined"
          value={formikTraining.values.place}
          onChange={formikTraining.handleChange}
          error={
            formikTraining.touched.place && Boolean(formikTraining.errors.place)
          }
          helperText={
            formikTraining.touched.place && formikTraining.errors.place
          }
        />
      </Box>

      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikTraining.isSubmitting}
        >
          {formikTraining.isSubmitting ? <RotatingLoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreatePernoct;
