import React, { useState, useEffect, useMemo } from "react";

import { GET_ALL_SUPPLIER } from "./../provider/QueryProvider";
import { GET_BUY_ORDER_BY_ID } from "./../../administration/logistic/QueryAdminLogistic";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useFormik } from "formik";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useApolloClient } from "@apollo/client";
import { FormHelperText } from "@mui/material";
import { GET_CONDITION, GET_CURRENCY } from "./../item/QueryItem";
import { BUY_ORDER_ASSIGN_SCHEMA_YUP } from "./YupValidation";
import { UPDATE_BUY_ORDER_TO_INIT } from "./QueryItemRequeriment";
import LoopIcon from "@mui/icons-material/Loop";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
const AssignSupplier = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const [allSupplier, setAllSupplier] = useState([]);
  const [visibility, setvisibility] = useState(false);
  const [allCondition, setAllCondition] = useState([]);
  const [allCurrency, setAllCurrency] = useState([]);
  const [error, setError] = useState(false);
  const { currency, dateFormat } = useSettings();
  const [buyorderRequeriment, setBuyorderRequeriment] = useState([]);
  const client = useApolloClient();

  let total = 0;
  /* const calculatePrice = (value, amount, discount, itens) => {
    const total = parseFloat(value * amount);

    subtotal = 0;
    console.log(itens);
    itens
      .filter((el) => el.id != "")
      .forEach((el) => {
        subtotal += el.total;
      });
   
    return total - total * (discount / 100);
  }; */

  const calcCostByItem = (amount, value, discount) => {
    const suxtotal = Number(amount) * Number(value);
    const res =
      discount > 100 ? suxtotal : suxtotal - suxtotal * (discount / 100);
    return res
  };

  const calcsubtotal = () => {
    let subtotal = 0;
    formikAsignSupplier.values.items
      .filter((el) => el.id != "")
      .forEach((el) => {
        subtotal += el.total;
      });
    return subtotal.toFixed(2);
  };

  const calctotal = () => {
    let subtotal = 0;
    formikAsignSupplier.values.items
      .filter((el) => el.id != "")
      .forEach((el) => {
        subtotal += el.total;
      });

    return subtotal
      ? (subtotal + subtotal * (buyorderRequeriment.igv / 100)).toFixed(2)
      : subtotal;
  };
  const formikAsignSupplier = useFormik({
    initialValues: {
      subtotal: "",
      total: "",
      currenc: "",
      emitiondate: new Date(),
      attentiondate: new Date(),
      formpay: "",
      conditionpay: "",
      provider: "",
      items: [
        /*ONLY WORK FOR 10*/
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",

          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",

          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",

          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
        {
          id: "",
          discount: 0,
          condition: "",
          unitvalue: 0,
          amount: 0,
          total: 0,
        },
      ],
    },
    validationSchema: BUY_ORDER_ASSIGN_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
         setSubmitting(true);
      const { conditionpay, currenc, emitiondate, attentiondate, items ,formpay,provider} = values;
      let subtotal = 0;

      const newItems = items.filter((el) => {
        subtotal += el.total;
        return el.id != "";
      });
   
      try {
        const response = await client.mutate({
          mutation: UPDATE_BUY_ORDER_TO_INIT,
          variables: {
            updateBuyOrderStateToInitId: id,
            conditionpay,
            formpay,
            provider,
            currenc,
            emitiondate,
            attentiondate,
            subtotal: parseFloat(subtotal),
            total: parseFloat(
              subtotal + subtotal * (buyorderRequeriment.igv / 100)
            ),
            items: newItems.map(
              (el) =>
                new Object({
                  id: el.id,
                  unitvalue: parseFloat(el.unitvalue),
                  cost: el.total,                 
                  condition: el.condition,
                  discount:Number(el.discount)
                })
            ),
          },
        });
        setNewAssignRequeriment(response.data.updateBuyOrderStateToInit);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        handleCloseModal();
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    const FUNCTION_GET_ALL_SUPPLIER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_SUPPLIER,
          fetchPolicy: "network-only",
        });
        setAllSupplier(data.getAllSuplier);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const getBuyorderRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_BUY_ORDER_BY_ID,
          variables: {
            getBuyOrderByIdId: id,
          },
        });
        console.log(data.getBuyOrderById);
        setBuyorderRequeriment(data.getBuyOrderById);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_CONDITION = async () => {
      try {
        const { data } = await client.query({
          query: GET_CONDITION,
          fetchPolicy: "network-only",
        });
        console.log(data.getCondition);
        setAllCondition(data.getCondition);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_CURRENCY = async () => {
      try {
        const { data } = await client.query({
          query: GET_CURRENCY,
          fetchPolicy: "network-only",
        });
        console.log(data.getcurrency);
        setAllCurrency(data.getcurrency);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_CONDITION();
    getBuyorderRequeriment(id);
    FUNCTION_GET_CURRENCY();
    FUNCTION_GET_ALL_SUPPLIER();
  }, []);
  return visibility ? (
    <form onSubmit={formikAsignSupplier.handleSubmit}>
      <Box>
      
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="20px"
        >
          {/*  <TextField
            id="requeriment"
            name="requeriment"
            label="REQUERIMIENTO"
            variant="outlined"
            value={buyorderRequeriment?.requeriment.id}
            disabled
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={buyorderRequeriment?.createdAt}
              id="createdAt"
              name="createdAt"
              label="FECHA DE CREACIÓN"
              inputFormat="dd-MM-yyyy"
              disabled
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <TextField
            id="area"
            name="area"
            label="AREA"
            variant="outlined"
            value={buyorderRequeriment?.area.description}
            disabled
          />
          <TextField
            id="priority"
            name="priority"
            label="PRIORIDAD"
            variant="outlined"
            value={buyorderRequeriment?.priority.description}
            disabled
          />
          <TextField
            id="subject"
            name="subject"
            label="MOTIVO"
            variant="outlined"
            value={buyorderRequeriment?.subject}
            disabled
          />
          {/*  <TextField
            id="igv"
            name="igv"
            label="IGV (%)"
            variant="outlined"
            value={buyorderRequeriment?.igv}
            disabled
          /> */}
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          {console.log(formikAsignSupplier)}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikAsignSupplier.values.emitiondate}
              id="emitiondate"
              name="emitiondate"
              label="FECHA DE EMISION"
              format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
              onChange={(val) => {
                formikAsignSupplier.setFieldValue("emitiondate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formikAsignSupplier.values.attentiondate}
              id="attentiondate"
              name="attentiondate"
              label="FECHA DE ATENCION"
              format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
              onChange={(val) => {
                formikAsignSupplier.setFieldValue("attentiondate", val);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">PROVEEDOR</InputLabel>
            <Select
              id="provider"
              value={formikAsignSupplier.values.provider}
              name="provider"
              label="PROVEEDOR"
              onChange={formikAsignSupplier.handleChange}
              error={
                formikAsignSupplier.touched.provider &&
                Boolean(formikAsignSupplier.errors.provider)
              }
            >
              {allSupplier?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.businessname}
                  </MenuItem>
                );
              })}
            </Select>
            {formikAsignSupplier.touched.provider &&
            formikAsignSupplier.errors.provider ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikAsignSupplier.touched.provider &&
                  formikAsignSupplier.errors.provider}
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">MONEDA</InputLabel>
            <Select
              id="currenc"
              value={formikAsignSupplier.values.currenc}
              name="currenc"
              onChange={formikAsignSupplier.handleChange}
              error={
                formikAsignSupplier.touched.currenc &&
                Boolean(formikAsignSupplier.errors.currenc)
              }
              label="MONEDA"
            >
              {allCurrency?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikAsignSupplier.touched.currenc &&
            formikAsignSupplier.errors.currenc ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikAsignSupplier.touched.currenc &&
                  formikAsignSupplier.errors.currenc}
              </FormHelperText>
            ) : null}            
          </FormControl>
          <TextField
            id="conditionpay"
            name="conditionpay"
            label="CONDICION DE PAGO"
            variant="outlined"
            value={formikAsignSupplier.values.conditionpay}
            onChange={formikAsignSupplier.handleChange}
            error={
              formikAsignSupplier.touched.conditionpay &&
              Boolean(formikAsignSupplier.errors.conditionpay)
            }
            helperText={
              formikAsignSupplier.touched.conditionpay &&
              formikAsignSupplier.errors.conditionpay
            }
          />
          <TextField
            id="formpay"
            name="formpay"
            label="FORMA DE PAGO"
            variant="outlined"
            value={formikAsignSupplier.values.formpay}
            onChange={formikAsignSupplier.handleChange}
            error={
              formikAsignSupplier.touched.formpay &&
              Boolean(formikAsignSupplier.errors.formpay)
            }
            helperText={
              formikAsignSupplier.touched.formpay &&
              formikAsignSupplier.errors.formpay
            }
          />
        </Box>

        <Box mt={"15px"}>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="">Item </TableCell> */}

                  <TableCell align="">Descripcion</TableCell>
                  <TableCell align="">Unidad</TableCell>
                  <TableCell align="">Cantidad</TableCell>
                  <TableCell align="" width={"200px"}>
                    Condicion
                  </TableCell>

                  <TableCell align="" width={"120px"}>
                    Valor Unitario
                  </TableCell>
                  <TableCell align="" width={"120px"}>
                    Descuento (%)
                  </TableCell>
                  <TableCell align="" width={"120px"}>
                    Costo Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buyorderRequeriment.item.map((el, index) => (
                  <TableRow
                    key={el.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/*  <TableCell align="">{el.active} </TableCell> */}
                    <TableCell align="">{el.description}</TableCell>
                    <TableCell align=""> {el.unity?.description}</TableCell>
                    <TableCell align=""> {el.amount}</TableCell>
                    <TableCell align="">
                      {" "}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          CONDICION
                        </InputLabel>
                        <Select
                          label="CONDICION"
                          onChange={(e) => {
                            formikAsignSupplier.setFieldValue(
                              `items[${index}].condition`,
                              e.target.value
                            );
                            formikAsignSupplier.setFieldValue(
                              `items[${index}].id`,
                              el.id
                            );
                          }}
                          required
                        >
                          {allCondition?.map((option) => {
                            return (
                              <MenuItem key={option.id} value={option.id}>
                                {option.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="">
                      {" "}
                      <TextField
                        id={`items[${index}].unitvalue`}
                        name={`items[${index}].unitvalue`}
                        label=""
                        variant="outlined"
                        type="number"
                        required
                        value={
                          formikAsignSupplier.values.items?.[index]?.unitvalue
                        }
                        onChange={(e) => {
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].unitvalue`,
                            e.target.value
                          );
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].id`,
                            el.id
                          );
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].amount`,
                            el.amount
                          );
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].total`,
                            calcCostByItem(
                              el.amount,
                              e.target.value,
                              formikAsignSupplier.values.items[index].discount
                            )
                          );
                        }}
                        error={
                          formikAsignSupplier.touched.items?.[index]
                            ?.unitvalue &&
                          Boolean(
                            formikAsignSupplier.errors.items?.[index]?.unitvalue
                          )
                        }
                        helperText={
                          formikAsignSupplier.touched.items?.[index]
                            ?.unitvalue &&
                          formikAsignSupplier.errors.items?.[index]?.unitvalue
                        }
                      />
                    </TableCell>
                    <TableCell align="">
                      {" "}
                      <TextField
                        id={`items[${index}].discount`}
                        name={`items[${index}].discount`}
                        variant="outlined"
                        type="number"
                        required
                        value={
                          formikAsignSupplier.values.items?.[index]?.discount
                        }
                        onChange={(e) => {
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].discount`,
                            e.target.value
                          );
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].id`,
                            el.id
                          );
                          formikAsignSupplier.setFieldValue(
                            `items[${index}].total`,
                            calcCostByItem(
                              el.amount,
                              formikAsignSupplier.values.items[index].unitvalue,
                              e.target.value
                            )
                          );
                        }}
                        error={
                          formikAsignSupplier.touched.items?.[index]
                            ?.discount &&
                          Boolean(
                            formikAsignSupplier.errors.items?.[index]?.discount
                          )
                        }
                        helperText={
                          formikAsignSupplier.touched.items?.[index]
                            ?.discount &&
                          formikAsignSupplier.errors.items?.[index]?.discount
                        }
                      />
                    </TableCell>
                    <TableCell align="">
                      {" "}
                      <TextField
                        id={`items[${index}].total`}
                        name={`items[${index}].total`}
                        value={formikAsignSupplier.values.items?.[index]?.total}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <TextField
            label="SUBTOTAL"
            variant="outlined"
            disabled
            value={calcsubtotal()}
          />
          <TextField
            id="igv"
            name="igv"
            label="I.G.V"
            variant="outlined"
            value={buyorderRequeriment.igv + " %"}
            disabled
          />
          <TextField
            label="TOTAL"
            variant="outlined"
            disabled
            value={calctotal()}
          />
        </Box>
        <Box mt={"25px"} display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={formikAsignSupplier.isSubmitting}
          >
            {formikAsignSupplier.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LoopIcon />
    </Box>
  );
};

export default AssignSupplier;
