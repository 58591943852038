import React,{ useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useApolloClient } from "@apollo/client";
import {DELETE_REQUERIMENT_BY_ID} from "./QueryPersonalRequeriment";
import LoopIcon from '@mui/icons-material/Loop';
const DeleteRequeriment = ({ onClose,params,id }) => {
    const {setAlertCustom,alertCustom,setNewPostulant}=params;
    const client = useApolloClient();
    const [loading, setLoading] = useState(true);
    const onAcept = async () => {
      try {
        const response = await client.mutate({
          mutation: DELETE_REQUERIMENT_BY_ID,
          variables: {
            deleteRequerimentPersonalByIdId: id,        
          },
        });
        setNewPostulant(response.data.deleteRequerimentPersonalById);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        setLoading(false); 
        onClose();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
  
  return (
    <>
    <DialogActions>
      <Button onClick={onClose} variant="contained">
        CANCELAR
      </Button>
      <Button onClick={onAcept} variant="contained" color="error"  disabled={!loading}>
      {!loading ?<LoopIcon/>: "ACEPTAR"}
      </Button>
    </DialogActions>
  </>
  )
}

export default DeleteRequeriment