import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StatBoxOne from "components/StatBoxOne";
import StatBox from "components/StatBox";
import { useApolloClient } from "@apollo/client";
import {
  DataGrid, GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalUtil from "components/ModalUtil";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";
import {
  GET_TYPE_MAINTANCE,
  GET_ALL_MAINTANCE_REQUERIMENT,
  GET_MAIN_DATA,
} from "./QueryMaintance";
import { GET_ALL_ACTIVE_VEHICLE } from "./../../operation/operation/QueryOperation";
import CreateRequeriment from "./CreateRequeriment";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import { GET_PRIORITIES } from "./../../logistic/requerimentItem/QueryItemRequeriment";
import ViewRequeriment from "./ViewRequeriment";
import AssignRequeriment from "./AssignRequeriment";
import { useLocation } from "react-router-dom";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
const RequerimentMantaince = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [typeMaintance, setTypeMaintance] = useState([]);
  const [active, setActive] = useState([]);
  const location = useLocation();
  const [mainData, setMainData] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [priority, setPriority] = useState([]);
  const { currency, dateFormat } = useSettings();
  const [id, setId] = useState(null);
  const [maintance, setMaintance] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenAssign, setModalOpenAssign] = useState(false);
  const [maintanceRequeriment, setNewMaintanceRequeriment] = useState([]);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const columnsRequeriment = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) =>
        DateFormatting(params.row.date, dateFormat),
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/*    <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };
  const handleOpenModalAssign = (id) => {
    setModalOpenAssign(true);
    setId(id);
  };

  const handleCloseModalAssign = () => {
    setModalOpenAssign(false);
  };
  const columnsRequerimentApproved = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.date).toLocaleDateString("es-ES"),
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "type",
      headerName: "TIPO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/*   <TouchAppIcon onClick={() => handleOpenModalAssign(params.row.id)} /> */}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_TYPE_MAINTANCE = async () => {
      try {
        const { data } = await client.query({
          query: GET_TYPE_MAINTANCE,
        });
        setTypeMaintance(data.getTypeMaintance);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_ACTIVE_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        setActive(data.getAllActiveVehicle);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonal(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_PRIORITIES = async () => {
      try {
        const { data } = await client.query({
          query: GET_PRIORITIES,
        });
        setPriority(data.getPriority);
      } catch (error) {
        console.log(error.message);
      }
    };
    const FUNCTION_GET_REQUERIMENT_MAINTANCE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_MAINTANCE_REQUERIMENT,
          fetchPolicy: "network-only",
          variables: {
            acro:
              location.pathname?.split("/")[1] == "man"
                ? "adm"
                : location.pathname?.split("/")[1],
          },
        });
        setMaintance(data.getMaintanceRequeriment);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
          variables: {
            area: location.pathname?.split("/")[1]
          },
        });
        setMainData(data.GetSumarizeRequerimentMaintance);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_GET_REQUERIMENT_MAINTANCE();
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_PRIORITIES();
    FUNCTION_GET_ALL_TYPE_MAINTANCE();
    FUNCTION_GET_ALL_ACTIVE_VEHICLE();
  }, [maintanceRequeriment, location.pathname]);
  const getArea = (acro) => {
    const area = {
      gp: "GESTION DE PERSONAS",
      ope: "OPERACIÓN",
      l: "LOGISTICA",
      man: "MANTENIMIENTO",
      seg: "SEGURIDAD"

    };
    return area[acro]
  }
  const getAreaMinimal = (acro) => {
    const area = {
      gp: "personal",
      ope: "operacion",
      l: "logistica",
      man: "mantenimiento",
      seg: "seguridad"
    };
    return area[acro]
  }

  return (
    <>
      {" "}
      <ModalUtil
        isOpen={modalOpenAssign}
        onClose={handleCloseModalAssign}
        Component={AssignRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalAssign,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title={getArea(location.pathname.split("/")[1])} subtitle={getAreaMinimal(location.pathname.split("/")[1]) + "/requerimiento"} />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Aprovados"
            value={mainData.aproved}
            /*  increase={"Aprovados por administracion y no atendidos"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Pendientes"
            value={mainData.pending}
            /*  increase={"Requieren ser aprovados por Adminsitracion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Total Rechazados"
            value={mainData.begining}
            /*    increase={"Rechazados por Adminsitracion"} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />

          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTOS PENDIENTES
            </Box>
            <DataGrid
              loading={!maintance}
              getRowId={(row) => row.id}
              rows={
                maintance?.filter(
                  (row) => row.state.description === "PENDIENTE"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => <GridToolbarContainer>
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                </GridToolbarContainer>,
              }}
            />
          </Box>
          {/*    <StatBoxOne  
          icon={
            <Email
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />{" "}
        <StatBoxOne  
          icon={
            <Email
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}
          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="10px"
            >
              CREAR REQUERIMIENTO DE MANTENIMIENTO
            </Box>
            <CreateRequeriment
              typeMaintance={typeMaintance}
              active={active}
              areacro={location.pathname?.split("/")[1]}
              personal={personal}
              priority={priority}
              setAlertCustom={setAlertCustom}
              alertCustom={alertCustom}
              setNewMaintanceRequeriment={setNewMaintanceRequeriment}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTO APROBADOS
            </Box>
            <DataGrid
              loading={!maintance}
              getRowId={(row) => row.id}
              rows={
                maintance?.filter(
                  (row) =>
                    row.state.description === "APROBADO" ||
                    row.state.description === "INICIADO"
                ) || []
              }
              columns={columnsRequerimentApproved}
              components={{
                Toolbar: () => <GridToolbarContainer>
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                </GridToolbarContainer>,
              }}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {" "}
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE REQUERIMIENTO REHAZADOS / ATENDIDOS
            </Box>
            <DataGrid
              loading={!maintance}
              getRowId={(row) => row.id}
              rows={
                maintance?.filter(
                  (row) =>
                    row.state.description === "RECHAZADO" ||
                    row.state.description === "ATENDIDO"
                ) || []
              }
              columns={columnsRequeriment}
              components={{
                Toolbar: () => <GridToolbarContainer>
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RequerimentMantaince;
