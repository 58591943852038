import React from "react";
import { useState, useEffect } from "react";
import { GET_FUEL_BY_ID } from "./QueryOperation";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useSettings } from "hooks/useSettings";
import { DateFormatting } from "utils/DateFormatting";
import { Box } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
const ViewFuel = ({ id, onClose }) => {
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [fuel, setfuel] = useState({});
  const { currency, dateFormat } = useSettings();
  useEffect(() => {
    const getFuelById = async () => {
      try {
        const { data } = await client.query({
          query: GET_FUEL_BY_ID,
          variables: {
            getFuelByIdId: id,
          },
        });
        console.log(data.getFuelById);
        setfuel(data.getFuelById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getFuelById();
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">ACTIVO</label>
            <TextField disabled value={fuel?.active?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROVEEDOR</label>
            <TextField disabled value={fuel?.supplier?.businessname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField disabled value={fuel?.type.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField
              disabled
              value={DateFormatting(fuel?.date,dateFormat)}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIEMPO</label>
            <TextField
              disabled
              value={fuel?.time}
            />
          </Box>
        </Box>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">CANTIDAD</label>
            <TextField disabled value={fuel?.amount} />
          </Box>
          <Box sx={style}>
            <label for="my-input">COSTO</label>
            <TextField disabled value={currency+" " + fuel?.cost} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TOTAL</label>
            <TextField disabled value={currency+" " + fuel?.total} />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={onClose}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewFuel;
