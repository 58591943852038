import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import LoopIcon from '@mui/icons-material/Loop';
import { GET_PARENT_BY_PERSONAL_ID } from "./QueryPersonal";
const ViewParents = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [parentFicha, setparentFicha] = useState(null);
  useEffect(() => {
    const getparentFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PARENT_BY_PERSONAL_ID,
          variables: {
            getParentByPersonalIdId: id,
          },
          fetchPolicy: "network-only",
        });      
        setparentFicha(data.getParentByPersonalId);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getparentFicha(id);
  }, []);
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">NOMBRE</label>
            <TextField
              disabled
              value={parentFicha?.dadname}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">APELLIDO</label>
            <TextField disabled value={parentFicha?.dadsurname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">OCUPACION</label>
            <TextField disabled value={parentFicha?.dadoccupation} />
          </Box>
          <Box sx={style}>
            <label for="my-input">LUGAR DE TRABAJO</label>
            <TextField disabled value={parentFicha?.dadworkplace} />
          </Box>

          <Box sx={style}>
            <label for="my-input">DIRECCION</label>
            <TextField disabled value={parentFicha?.dadaddress} />
          </Box>
          <Box sx={style}>
            <label for="my-input">TELEFONO</label>
            <TextField disabled value={parentFicha?.dadphone} />
          </Box>


          <Box sx={style}>
            <label for="my-input">NOMBRE</label>
            <TextField disabled value={parentFicha?.momname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">APELLIDO</label>
            <TextField disabled value={parentFicha?.momsurname} />
          </Box>
          <Box sx={style}>
            <label for="my-input">OCUPACION</label>
            <TextField disabled value={parentFicha?.momoccupation} />
          </Box>
          <Box sx={style}>
            <label for="my-input">LUGAR DE TRABAJO</label>
            <TextField
              disabled
              value={parentFicha?.momworkplace}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">DIRECCION</label>
            <TextField
              disabled
              value={parentFicha?.momaddress}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TELEFONO</label>
            <TextField
              disabled
              value={parentFicha?.momphone}
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewParents;
