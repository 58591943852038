import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import LoopIcon from "@mui/icons-material/Loop";
import { Box, TextField, Button } from "@mui/material";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { FormHelperText } from "@mui/material";
import { INTERVIEW_SCHEMA_YUP } from "./YupValidation";
import { CREATE_INTERVIEW_MUTATION } from "./QueryInterview";
import Alerts from "components/Alerts";
import { useSettings } from "hooks/useSettings";
const CreateInterview = ({
  setAlertCustom,
  setNewInterview,
  alertCustom,
  formation,
  other,
  positions,
  id,
  setId,
  setErrorId,
}) => {
  const client = useApolloClient();
  const { currency } = useSettings(); 
  const formikInterview = useFormik({
    initialValues: {
      salary: "",
      position: "",
      postulant: "",
      formation: [
        {
          score: 0,
          formations: "",
        },
        {
          score: 0,
          formations: "",
        },
        {
          score: 0,
          formations: "",
        },
      ],
      other: [
        {
          score: 0,
          others: "",
        },
        {
          score: 0,
          others: "",
        },
        {
          score: 0,
          others: "",
        },
      ],
    },
    validationSchema: INTERVIEW_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { salary, position, other, formation } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_INTERVIEW_MUTATION,
            variables: {
              salary: parseFloat(salary),
              position: position,
              postulant: id,
              formation: formation.map(
                (it) =>
                  new Object({
                    formation: it.formations,
                    score: parseFloat(it.score),
                  })
              ),
              other: other.map(
                (it) =>
                  new Object({ other: it.others, score: parseFloat(it.score) })
              ),
            },
          });
          console.log(response.data.createInterview);
          setNewInterview(response.data.createInterview);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikInterview.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        fontSize={"12px"}
        fontWeight={"700"}
        textAlign={"center"}
        pt={"10px"}
      >
        CREAR ENTREVISTAS
      </Box>
      <Box my="20px" display={"flex"} flexDirection={"column"}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
          mb={"10px"}
        >
          <FormControl fullWidth>
            <InputLabel>CARGO</InputLabel>
            <Select
              id="position"
              label="CARGO"
              value={formikInterview.values.position}
              name="position"
              onChange={formikInterview.handleChange}
              error={
                formikInterview.touched.position &&
                Boolean(formikInterview.errors.position)
              }
            
            >
              {positions?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikInterview.touched.position &&
            formikInterview.errors.position ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikInterview.touched.position &&
                  formikInterview.errors.position}
              </FormHelperText>
            ) : null}
          </FormControl>
         {/*  <TextField
            id="salary"
            name="salary"
            label="PRETENCIONES SALARIALES"
            variant="outlined"
            value={formikInterview.values.salary}
            onChange={formikInterview.handleChange}
            error={
              formikInterview.touched.salary &&
              Boolean(formikInterview.errors.salary)
            }
            helperText={
              formikInterview.touched.salary && formikInterview.errors.salary
            }
          /> */}
           <FormControl fullWidth>
          <InputLabel htmlFor="remuneration">PRETENCIONES SALARIALES</InputLabel>
          <OutlinedInput
            id="salary"
            name="salary"
            type="number"           
            onChange={formikInterview.handleChange}
            value={formikInterview.values.salary}     
            startAdornment={
              <InputAdornment position="start">{currency} </InputAdornment>
            }
            label="PRETENCIONES SALARIALES"
          />
           {formikInterview.touched.salary &&
          formikInterview.errors.salary ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikInterview.touched.salary &&
                formikInterview.errors.salary}
            </FormHelperText>
          ) : null}
        </FormControl>
        </Box>
        <Box>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Cuenta con lo indicado(3)</TableCell>
                  <TableCell align="">Cuenta con lo necesario(2)</TableCell>
                  <TableCell align="">No cumple(1)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formation.map((row, index) => (
                  /*  <RadioGroup
                    name={`formation[${index}].score`} // ajusta el nombre del campo para que coincida con la estructura de tu objeto
                    value={formikInterview.values.formation[index].score} // establece el valor de acuerdo con la estructura de tu objeto
                    onChange={(e) => {
                      formikInterview.setFieldValue(
                        `formation[${index}].score`,
                        e.target.value
                      );
                      formikInterview.setFieldValue(
                        `formation[${index}].formations`,
                        row.id
                      );
                    }}
                  > */
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.formation?.[index]?.score ===
                          "3"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `formation[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `formation[${index}].formations`,
                            row.id
                          );
                        }}
                        value={"3"}
                        name={`formation[${index}].formations`}
                      />
                      {/*  <FormControlLabel value="3" control={<Radio />} /> */}
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.formation?.[index]?.score ===
                          "2"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `formation[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `formation[${index}].formations`,
                            row.id
                          );
                        }}
                        value={"2"}
                        name={`formation[${index}].formations`}
                      />
                      {/*    <FormControlLabel value="2" control={<Radio />} /> */}
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.formation?.[index]?.score ===
                          "1"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `formation[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `formation[${index}].formations`,
                            row.id
                          );
                        }}
                        value={"1"}
                        name={`formation[${index}].formations`}
                      />
                      {/*  <FormControlLabel value="1" control={<Radio />} /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {formikInterview.touched.formation?.find(
              (it) => it?.score == true
            ) &&
            formikInterview.errors.formation?.find(
              (it) => it?.score != undefined
            ) ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  Verica los campos esten requeridos
                </p>
              </FormHelperText>
            ) : null}
          </TableContainer>
        </Box>
        <Box>
          <TableContainer style={{ maxHeight: 400, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Excelente</TableCell>
                  <TableCell align="">Cuenta con lo necesario(2)</TableCell>
                  <TableCell align="">No cumple(1)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {other.map((row, index) => (
                  /*   <RadioGroup
                    name={`other[${index}].score`} // ajusta el nombre del campo para que coincida con la estructura de tu objeto
                    value={formikInterview.values.other[index].score} // establece el valor de acuerdo con la estructura de tu objeto
                    onChange={(e) => {
                      formikInterview.setFieldValue(
                        `other[${index}].score`,
                        e.target.value
                      ); // actualiza el valor del campo
                      formikInterview.setFieldValue(
                        `other[${index}].others`,
                        row.id
                      );
                      console.log(formikInterview);
                    }}
                  > */
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.other?.[index]?.score === "3"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `other[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `other[${index}].others`,
                            row.id
                          );
                        }}
                        value={"3"}
                        name={`other[${index}].others`}
                      />
                      {/* <FormControlLabel value="3" control={<Radio />} /> */}
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.other?.[index]?.score === "2"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `other[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `other[${index}].others`,
                            row.id
                          );
                        }}
                        value={"2"}
                        name={`other[${index}].others`}
                      />
                      {/*  <FormControlLabel value="2" control={<Radio />} /> */}
                    </TableCell>
                    <TableCell align="">
                      <Radio
                        checked={
                          formikInterview.values.other?.[index]?.score === "1"
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#000", // Cambia el color cuando el radio button está marcado
                          },
                        }}
                        onChange={(e) => {
                          formikInterview.setFieldValue(
                            `other[${index}].score`,
                            e.target.value
                          );
                          formikInterview.setFieldValue(
                            `other[${index}].others`,
                            row.id
                          );
                        }}
                        value={"1"}
                        name={`other[${index}].others`}
                      />
                      {/*  <FormControlLabel value="1" control={<Radio />} /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {formikInterview.touched.other?.find((it) => it?.score == true) &&
            formikInterview.errors.other?.find(
              (it) => it?.score != undefined
            ) ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  Verica los campos esten requeridos
                </p>
              </FormHelperText>
            ) : null}
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
          {
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={formikInterview.isSubmitting}
            >
              {formikInterview.isSubmitting ? <LoopIcon /> : "GUARDAR"}
            </Button>
          }
        </Box>
      </Box>
    </form>
  );
};

export default CreateInterview;
