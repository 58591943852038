import * as Yup from "yup";
export const ODOMETER_SCHEMA_YUP = Yup.object().shape({
  odometer: Yup.number()  
    .positive("El número debe ser positivo")
    .min(1, "El numero debe ser mayor a 1")
    .required("Requiredo"),
});

export const COMMENT_SCHEMA_YUP = Yup.object().shape({
  comment: Yup.string().required("Requirido"),
});

export const STATE_SCHEMA_YUP = Yup.object().shape({
  state: Yup.string().required("Requirido"),
  subject: Yup.string().required("Requirido"),
});

export const ASIGNATION_SCHEMA_YUP = Yup.object().shape({  
  driver:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  proyect: Yup.string().required("Requirido"),
  description: Yup.string().required("Requirido"),
  type: Yup.string().required("Requirido"),

  begindate: Yup.date().required("Requiredo"),
  begintime: Yup.string().required("Requiredo"),
  enddate: Yup.date().required("Requiredo"),
  endtime: Yup.string().required("Requiredo"),

  departure: Yup.string(),
  load: Yup.string(),
  download: Yup.string(),
  returnn: Yup.string(),

  observation: Yup.string(),
});
