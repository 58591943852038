import { gql } from "@apollo/client";

export const GET_ALL_ITEMS = gql`
  query GetAllItems {
    getAllItems {
      id
      description
      correlative
      coloquial
      amount
      amountfree
      specification
      classs {
        description
      }
      group {
        description
      }
      unity{
        description
      }
      active
    }
  }
`;
export const GET_CLASS_BY_GROUP_ID = gql`
  query GetClassByGroupId($group: ID) {
    getClassByGroupId(group: $group) {
      acro
      code
      id
      description
    }
  }
`;

export const GET_UNITY = gql`
  query GetUnity {
    getUnity {
      id
      description
    }
  }
`;

export const GET_CONDITION = gql`
  query GetCondition {
    getCondition {
      id
      description
    }
  }
`;
export const GET_CURRENCY = gql`
  query Getcurrency {
    getcurrency {
      id
      description
      symbol
    }
  }
`;
export const CREATE_ITEM_MUTATION = gql`
  mutation CreateItem(
    $group: ID
    $classs: ID
    $description: String
    $coloquial: String
    $unity: ID
    $specification: String
  ) {
    createItem(
      group: $group
      classs: $classs
      description: $description
      coloquial: $coloquial
      unity: $unity
      specification: $specification
    ) {
      active
      id
      description
      correlative
    }
  }
`;
export const GET_BUY_ORDER_BEGIN = gql`
  query GetBuyOrderStateBegin {
    getBuyOrderStateBegin {
      id
      igv
      code
      priority {
        description
      }
      state {
        description
      }
      createdAt
    }
  }
`;

export const GET_BUY_ORDER_ITEMS = gql`
  query GetBuyOrderItemByOrderId($getBuyOrderItemByOrderIdId: ID) {
    getBuyOrderItemByOrderId(id: $getBuyOrderItemByOrderIdId) {
      id
      amount
      current
      item {
        description
        active
      }

      observation
      complete
    }
  }
`;
export const CREATE_ACTIVE_MUTATION = gql`
  mutation CreateActive(
    $amount: Int
    $brand: String
    $plate: String
    $beginguarante: Date
    $buyorder: ID
    $guarante: Int
    $condition: ID
    $item: ID
    $dateadquisition: Date
    $parte: String
    $serie: String
    $itemforbuyorder: ID
  ) {
    createActive(
      amount: $amount
      brand: $brand
      plate: $plate
      beginguarante: $beginguarante
      buyorder: $buyorder
      guarante: $guarante
      condition: $condition
      item: $item
      dateadquisition: $dateadquisition
      parte: $parte
      serie: $serie
      itemforbuyorder: $itemforbuyorder
    ) {
      id
      cost
      description
    }
  }
`;


export const GET_ALL_ITEM_ONLY_VEHICLE= gql`
query GetAllItemOnlyVehicle {
  getAllItemOnlyVehicle {
     id
     description
     correlative
     coloquial
     classs {
       description
     }
     group {
       description
     }
     unity {
       description
     }
     active
     amount
     amountfree
 }
}
`;

export const GET_ALL_ITEMS_VEHICLE = gql`
 query GetAllActiveNoConsumable {
  getAllActiveNoConsumable {
      id
      description
      correlative
      coloquial
      classs {
        description
      }
      group {
        description
      }
      unity {
        description
      }
      active
      amount
      amountfree
  }
}
`;

export const GET_ALL_ITEMS_NO_VEHICLE = gql`
query GetAllActiveConsumable {
  getAllActiveConsumable {
    id
      description
      correlative
      coloquial
      classs {
        description
      }
      group {
        description
      }
      unity {
        description
      }
      active
      amount
      amountfree
  }
}

`;

export const GET_ALL_ACTIVE_VEHICLE = gql`
  query GetAllActiveVehicle {
    getAllActiveVehicle {
      id
      description
      plate
      item {
        description
      }
    }
  }
`;

export const GET_ALL_ACTIVE_NO_VEHICLE = gql`
  query GetAllActiveNoVehicle {
    getAllActiveNoVehicle {
      id
      description
      plate
      item {
        description
      }
    }
  }
`;

export const GET_SYSTEMTYPE = gql`
  query GetTypeSystem {
    getTypeSystem {
      id
      description
    }
  }
`;

export const CREATE_VEHICLE_SYSTEM = gql`
  mutation CreateVehicleSystem($novehicle: ID, $vehicle: ID, $type: ID) {
    createVehicleSystem(novehicle: $novehicle, vehicle: $vehicle, type: $type) {
      id
      type {
        description
      }
    }
  }
`;

export const GET_ACTIVE_VEHICLE_BY_ITEM_ID = gql`
  query GetActiveVehicleByItemId($getActiveVehicleByItemIdId: ID) {
    getActiveVehicleByItemId(id: $getActiveVehicleByItemIdId) {
      id
      description
      plate
    item {
      group {
        code
        id
      }
    }
    }
  }
`;
export const GET_ACTIVE_NO_VEHICLE_BY_ITEM_ID = gql`
  query GetActiveNoVehicleByItemId($getActiveNoVehicleByItemIdId: ID) {
    getActiveNoVehicleByItemId(id: $getActiveNoVehicleByItemIdId) {
      id
      description
      plate
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumanrizeItemActive {
    getSumanrizeItemActive {
      novehicle
      item
      active
      vehicle
    }
  }
`;

export const GET_ACTIVE_BY_ID = gql`
query GetActiveById($getActiveByIdId: ID) {
  getActiveById(id: $getActiveByIdId) {
    
    guarante    
    guarante    
    dateadquisition
    cost    
    condition {
      id
      description
    }
    brand
    beginguarante
    parte
    plate
    serie
    unity {
      description
      id
    }   
  }
}`;

export const EDIT_ACTIVE_BY_ID= gql`
mutation EditActiveById($editActiveByIdId: ID, $serie: String, $parte: String, $condition: ID, $dateadquisition: Date, $guarante: Int, $beginguarante: Date, $unity: ID, $brand: String, $plate: String) {
  editActiveById(id: $editActiveByIdId, serie: $serie, parte: $parte, condition: $condition, dateadquisition: $dateadquisition, guarante: $guarante, beginguarante: $beginguarante, unity: $unity, brand: $brand, plate: $plate) {
    id
    parte
    plate
    serie
    cost
    guarante
  }
}`;