import React, { useState, useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import { GET_SPOUSE_BY_PERSONAL_ID } from "./QueryPersonal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
} from "../FichaPersonal/QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
import { EDIT_SPOUSE_BY_ID } from "./QueryPersonal";
import { SPOUSE_SCHEMA_YUP } from "./YupValidation";
const EditSpouse = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    handleCloseModal,
    departament,
    professionLevel,
    professionState,
    setNewRegister,
  } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [spouseFicha, setspouseFicha] = useState(null);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
        fetchPolicy: "network-only",
      });
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  useEffect(() => {
    const getspouseFicha = async (id) => {
      console.log(id)
      try {
        const { data } = await client.query({
          query: GET_SPOUSE_BY_PERSONAL_ID,
          variables: {
            getSpouseByPersonalIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data);
        setspouseFicha(data.getSpouseByPersonalId);

        formikSpouse.setValues({
          name: data.getSpouseByPersonalId.name,
          surname: data.getSpouseByPersonalId?.surname,
          dob: data.getSpouseByPersonalId.dob ? new Date(data.getSpouseByPersonalId.dob) : null,
          departament: data.getSpouseByPersonalId.departament?.id,
          province: data.getSpouseByPersonalId.province?.id,
          district: data.getSpouseByPersonalId.district?.id,
          doi: data.getSpouseByPersonalId.doi,
          ruc: data.getSpouseByPersonalId.ruc,
          profession: data.getSpouseByPersonalId.profession,
          professionlevel: data.getSpouseByPersonalId.professionlevel?.id,
          professionstate: data.getSpouseByPersonalId.professionstate?.id,
          occupation: data.getSpouseByPersonalId.occupation,
          workplace: data.getSpouseByPersonalId.workplace,
          address: data.getSpouseByPersonalId.address,
          phone: data.getSpouseByPersonalId.phone,
        });
        handleProvince(data.getSpouseByPersonalId.departament?.id);
        handleDistrict(data.getSpouseByPersonalId.province?.id);

        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getspouseFicha(id);
  }, []);

  const formikSpouse = useFormik({
    initialValues: {
      name: "",
      surname: "",

      dob: new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      ),
      departament: "",
      province: "",
      district: "",
      doi: "",
      ruc: "",

      profession: "",
      professionlevel: "",
      professionstate: "",
      occupation: "",
      workplace: "",
      address: "",
      phone: "",
    },
    validationSchema: SPOUSE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      const {
        name,
        surname,
        dob,
        departament,
        province,
        district,
        doi,
        ruc,
        profession,
        professionlevel,
        professionstate,
        occupation,
        workplace,
        address,
        phone,
      } = values;

      try {
        const response = await client.mutate({
          mutation: EDIT_SPOUSE_BY_ID,
          variables: {
            editSpouseByIdId: id,
            name: name.toUpperCase(),
            surname: surname.toUpperCase(),
            dob,
            departament,
            province,
            district,
            doi: Number(doi),
            ruc: ruc.toUpperCase(),
            profession: profession.toUpperCase(),
            professionlevel,
            professionstate,
            occupation: occupation.toUpperCase(),
            workplace: workplace.toUpperCase(),
            address: address.toUpperCase(),
            phone: phone.toUpperCase(),
          },
        });
        console.log(response.data.editSpouseById);
        setNewRegister(response.data.editSpouseById);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });

        resetForm();
        handleCloseModal();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <form onSubmit={formikSpouse.handleSubmit}>
        {" "}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">NOMBRES</label>
            <TextField
              id="name"
              name="name"
              variant="outlined"
              value={formikSpouse.values.name}
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.name && Boolean(formikSpouse.errors.name)
              }
              helperText={formikSpouse.touched.name && formikSpouse.errors.name}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">APELLIDOS</label>
            <TextField
              id="surname"
              name="surname"
              variant="outlined"
              value={formikSpouse.values.surname}
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.surname &&
                Boolean(formikSpouse.errors.surname)
              }
              helperText={
                formikSpouse.touched.surname && formikSpouse.errors.surname
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">DNI</label>
            <TextField
              id="doi"
              name="doi"
              variant="outlined"
              type="number"
              value={formikSpouse.values.doi}
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.doi && Boolean(formikSpouse.errors.doi)
              }
              helperText={formikSpouse.touched.doi && formikSpouse.errors.doi}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA DE NACIMIENTO</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={formikSpouse.values.dob}
                id="dob"
                name="dob"
                minDate={mindate}
                maxDate={
                  new Date(
                    today.getFullYear() - minAge,
                    today.getMonth(),
                    today.getDate()
                  )
                }
                inputFormat="dd-MM-yyyy"
                onChange={(val) => {
                  formikSpouse.setFieldValue("dob", val);
                }}
                renderInput={(params) => (
                  <>
                    <TextField {...params} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={style}>
            <label for="my-input">TELEFONO</label>
            <TextField // value={postulant?.email}
              id="phone"
              name="phone"
              value={formikSpouse.values.phone}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.phone && Boolean(formikSpouse.errors.phone)
              }
              helperText={
                formikSpouse.touched.phone && formikSpouse.errors.phone
              }
            />
          </Box>

          <Box sx={style}>
            <label for="my-input">RUC</label>
            <TextField
              id="ruc"
              name="ruc"
              value={formikSpouse.values.ruc}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.ruc && Boolean(formikSpouse.errors.ruc)
              }
              helperText={formikSpouse.touched.ruc && formikSpouse.errors.ruc}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">LUGAR DE TRABAJO</label>
            <TextField
              id="workplace"
              name="workplace"
              value={formikSpouse.values.workplace}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.workplace &&
                Boolean(formikSpouse.errors.workplace)
              }
              helperText={
                formikSpouse.touched.workplace && formikSpouse.errors.workplace
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">DIRECCION</label>
            <TextField
              id="address"
              name="address"
              value={formikSpouse.values.address}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.address &&
                Boolean(formikSpouse.errors.address)
              }
              helperText={
                formikSpouse.touched.address && formikSpouse.errors.address
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">OCUPACION</label>
            <TextField
              id="occupation"
              name="occupation"
              value={formikSpouse.values.occupation}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.occupation &&
                Boolean(formikSpouse.errors.occupation)
              }
              helperText={
                formikSpouse.touched.occupation &&
                formikSpouse.errors.occupation
              }
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PROFESION</label>
            <TextField
              id="profession"
              name="profession"
              value={formikSpouse.values.profession}
              variant="outlined"
              onChange={formikSpouse.handleChange}
              error={
                formikSpouse.touched.profession &&
                Boolean(formikSpouse.errors.profession)
              }
              helperText={
                formikSpouse.touched.profession &&
                formikSpouse.errors.profession
              }
            />
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">DEPARTAMENTOO</label>
              <Select
                id="departament"
                value={formikSpouse.values.departament}
                name="departament"
                onChange={(e) => {
                  formikSpouse.setFieldValue("departament", e.target.value);
                  handleProvince(e.target.value);
                }}
                error={
                  formikSpouse.touched.departament &&
                  Boolean(formikSpouse.errors.departament)
                }
              >
                {departament?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikSpouse.touched.departament &&
                formikSpouse.errors.departament ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikSpouse.touched.departament &&
                    formikSpouse.errors.departament}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">PROVINCIA</label>
              <Select
                id="province"
                value={formikSpouse.values.province}
                name="province"
                onChange={(e) => {
                  formikSpouse.setFieldValue("province", e.target.value);
                  console.log(e.target.value);
                  handleDistrict(e.target.value);
                }}
                error={
                  formikSpouse.touched.province &&
                  Boolean(formikSpouse.errors.province)
                }
              >
                {province?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikSpouse.touched.province && formikSpouse.errors.province ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikSpouse.touched.province &&
                    formikSpouse.errors.province}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">DISTRITO</label>
              <Select
                id="district"
                value={formikSpouse.values.district}
                name="district"
                onChange={formikSpouse.handleChange}
                error={
                  formikSpouse.touched.district &&
                  Boolean(formikSpouse.errors.district)
                }
              >
                {district?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikSpouse.touched.district && formikSpouse.errors.district ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikSpouse.touched.district &&
                    formikSpouse.errors.district}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">NIVEL DE PROFESION</label>
              <Select
                id="professionlevel"
                value={formikSpouse.values.professionlevel}
                name="professionlevel"
                onChange={(e) => {
                  formikSpouse.setFieldValue("professionlevel", e.target.value);
                }}
                error={
                  formikSpouse.touched.professionlevel &&
                  Boolean(formikSpouse.errors.professionlevel)
                }
              >
                {professionLevel?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikSpouse.touched.professionlevel &&
                formikSpouse.errors.professionlevel ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikSpouse.touched.professionlevel &&
                    formikSpouse.errors.professionlevel}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          <Box sx={style}>
            <FormControl fullWidth>
              <label for="my-input">ESTADO DE PROFESION</label>
              <Select
                id="professionstate"
                value={formikSpouse.values.professionstate}
                name="professionstate"
                onChange={formikSpouse.handleChange}
                error={
                  formikSpouse.touched.professionstate &&
                  Boolean(formikSpouse.errors.professionstate)
                }
              >
                {professionState?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.description}
                    </MenuItem>
                  );
                })}
              </Select>
              {formikSpouse.touched.professionstate &&
                formikSpouse.errors.professionstate ? (
                <FormHelperText
                  sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                >
                  {formikSpouse.touched.professionstate &&
                    formikSpouse.errors.professionstate}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"20px"}
        >
          <Button variant="contained" color="success" type="submit" disabled={formikSpouse.isSubmitting}>
            {formikSpouse.isSubmitting ? <LoopIcon /> : "GUARDAR"}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </form>
    ) : <Box sx={{ display: "flex", justifyContent: "center" }}><LoopIcon /></Box>
  );
};

export default EditSpouse;
