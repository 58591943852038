import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { REQUERIMENT_MAINTANCE_SCHEMA_YUP } from "./YupValidation";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon";
import { useLocation } from "react-router-dom";
import { CREATE_MAINTANCE } from "./QueryMaintance";
import { useUser } from "hooks/useUser";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
const CreateRequeriment = ({
  setNewMaintanceRequeriment,
  typeMaintance,
  active,
  personal,
  priority,
  areacro,
  setAlertCustom,
  alertCustom,
}) => {
  const client = useApolloClient();
  const location = useLocation();
  const autocompleteactiveRef = useRef(null);
  const autocompletepersonalRef = useRef(null);
  const { currency,dateFormat } = useSettings();
  const { user } = useUser();
  const initialValues = {
    active: {
      label: "",
      id: "",
    },
    personal: {
      label: "",
      id: !user.admin ? user.personal.id : "",
    },
    priority: "",
    type: "",
    area: areacro,
    date: new Date(),
    description: "",
    comment: "",
  };
  const formikMaintance = useFormik({
    initialValues,
    validationSchema: REQUERIMENT_MAINTANCE_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        active,
        personal,
        priority,
        area,
        type,
        date,
        description,
        comment,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_MAINTANCE,
          variables: {
            active: active.id,
            personal: personal.id,
            area,
            priority,
            type,
            date,
            description: description.toUpperCase(),
            comment: comment.toUpperCase(),
          },
        });
        setNewMaintanceRequeriment(response.data.createMaintanceRequeriment);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        autocompleteactiveRef.current.value = "";
        if (user.admin) {
          autocompletepersonalRef.current.value = "";
        }

        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    formikMaintance.resetForm({ values: initialValues });
  }, [location.pathname]);
  return (
   
    <form onSubmit={formikMaintance.handleSubmit}>
       {console.log(formikMaintance)}
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box mb={"20px"}>
        {active?.length ? (
          <Autocomplete
            freeSolo
            id="active"
            name="active"
            disableClearable
            ref={autocompleteactiveRef}
            value={formikMaintance.values.active.label}
            options={active?.map(
              (option) =>
                new Object({
                  label:                 
                    option.description +
                    " / " +
                    "placa:" +
                    option.plate,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              formikMaintance.setFieldValue("active", {
                label: newValue.label,
                id: newValue.value,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por descripcion/placa"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                error={
                  formikMaintance.touched.active?.id &&
                  Boolean(formikMaintance.errors.active?.id)
                }
                helperText={
                  formikMaintance.touched.active?.id &&
                  formikMaintance.errors.active?.id
                }
              />
            )}
          />
        ) : (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              No hay Datos para mostrar
            </p>
          </FormHelperText>
        )}
      </Box>
      {user.admin ? (
        <Box mb={"20px"}>
          {personal?.length ? (
            <Autocomplete
              freeSolo
              id="personal"
              name="personal"
              disableClearable
              ref={autocompletepersonalRef}
              value={formikMaintance.values.personal.label}
              options={personal?.map(
                (option) =>
                  new Object({
                    label:                 
                      option.postulant.name +
                      "  " +           
                      option.postulant.surname +
                      " / " +
                      "dni:" +
                      option.postulant.doi,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                formikMaintance.setFieldValue("personal", {
                  label: newValue.label,
                  id: newValue.value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={
                    formikMaintance.touched.personal?.id &&
                    Boolean(formikMaintance.errors.personal?.id)
                  }
                  helperText={
                    formikMaintance.touched.personal?.id &&
                    formikMaintance.errors.personal?.id
                  }
                />
              )}
            />
          ) : (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                No hay Datos para mostrar
              </p>
            </FormHelperText>
          )}
        </Box>
      ) : null}
      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            TIPO DE MANTENIMIENTO
          </InputLabel>
          <Select
            id="type"
            value={formikMaintance.values.type}
            name="type"
            onChange={formikMaintance.handleChange}
            label="TIPO DE MANTENIMIENTO"
            error={
              formikMaintance.touched.type &&
              Boolean(formikMaintance.errors.type)
            }
          >
            {typeMaintance?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikMaintance.touched.type && formikMaintance.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikMaintance.touched.type && formikMaintance.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PRIORIDAD</InputLabel>
          <Select
            id="priority"
            value={formikMaintance.values.priority}
            name="priority"
            onChange={formikMaintance.handleChange}
            label="PRIORIDAD"
            error={
              formikMaintance.touched.priority &&
              Boolean(formikMaintance.errors.priority)
            }
          >
            {priority?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikMaintance.touched.type && formikMaintance.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikMaintance.touched.type && formikMaintance.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikMaintance.values.date}
            id="date"
            name="date"
            label="FECHA DE REQUERIMIENTO"
            format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
            onChange={(val) => {
              formikMaintance.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>

        {/*    <TextField
        id="total"
        name="total"
        label="TOTAL"
        variant="outlined"
        value={formikMaintance.values.total}
        onChange={formikMaintance.handleChange}
        error={formikMaintance.touched.total && Boolean(formikMaintance.errors.total)}
        helperText={formikMaintance.touched.total && formikMaintance.errors.total}
      /> */}
        <TextField
          id="description"
          fullWidth
          name="description"
          label="DESCRIPCION"
          variant="outlined"
          value={formikMaintance.values.description}
          onChange={formikMaintance.handleChange}
          error={
            formikMaintance.touched.description &&
            Boolean(formikMaintance.errors.description)
          }
          helperText={
            formikMaintance.touched.description &&
            formikMaintance.errors.description
          }
        />
      </Box>

      <Box mb={"20px"}>
        <TextField
          id="comment"
          fullWidth
          name="comment"
          label="COMENTARIO"
          variant="outlined"
          multiline
          rows={6}
          value={formikMaintance.values.comment}
          onChange={formikMaintance.handleChange}
          error={
            formikMaintance.touched.comment &&
            Boolean(formikMaintance.errors.comment)
          }
          helperText={
            formikMaintance.touched.comment && formikMaintance.errors.comment
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikMaintance.isSubmitting}
        >
          {formikMaintance.isSubmitting ? <RotatingLoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateRequeriment;
