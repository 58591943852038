import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Alerts from "components/Alerts";
import { POLICE_RECORD_SCHEMA_YUP } from "./YupValidation";
import { CREATE_POLICE_RECORD } from "./QueryPersonal";
import LoopIcon from '@mui/icons-material/Loop';
const CreatePoliceRecord = ({
  sentencePoliceRecord,
  id,
  setNewPoliceRecord,
  setAlertCustom,
  alertCustom,
  setErrorId,
}) => {

  const client = useApolloClient();

  const formikPoliceRecord = useFormik({
    initialValues: {
      personal: "",
      have: true,
      policerecordsentence: 
      [{ answer: "", sentence: "" },
      { answer: "", sentence: "" },
      { answer: "", sentence: "" },
      { answer: "", sentence: "" }
      ]
      /* sentencePoliceRecord.map(
        (el) => new Object({ answer: "", sentence: "" })
      ) */,
    },
    validationSchema: POLICE_RECORD_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
    
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { have, policerecordsentence } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_POLICE_RECORD,
            variables: {
              personal: id,
              have,
              policerecordsentence,
            },
          });

          setNewPoliceRecord(response.data.createPoliceRecord);
          //setId(response.data.createPersonal.id);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikPoliceRecord.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )} 
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            ¿TIENE ANTECEDENTES?
          </InputLabel>
          <Select
            id="have"
            value={formikPoliceRecord.values.have}
            name="have"
            onChange={formikPoliceRecord.handleChange}
            label="¿TIENE ANTECEDENTES?"  
            error={
              formikPoliceRecord.touched.have &&
              Boolean(formikPoliceRecord.errors.have)
            }
          >
            <MenuItem key={true} value={true}>
              SI
            </MenuItem>
            <MenuItem key={false} value={false}>
              NO
            </MenuItem>
          </Select>
          {formikPoliceRecord.touched.have && formikPoliceRecord.errors.have ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikPoliceRecord.touched.have &&
                formikPoliceRecord.errors.have}
            </FormHelperText>
          ) : null}
        </FormControl>
        {formikPoliceRecord.values.have?
        <Box>
          <TableContainer style={{ maxHeight: 300, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">Criterio</TableCell>
                  <TableCell align="">Respuesta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sentencePoliceRecord.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="">{index + 1}</TableCell>
                    <TableCell align="">{row.description}</TableCell>
                    <TableCell align="">
                      <TextField
                        name={`policerecordsentence[${index}].answer`}
                        variant="outlined"
                        value={
                          formikPoliceRecord.values.policerecordsentence?.[
                            index
                          ]?.answer
                        }
                        onChange={(e) => {
                          formikPoliceRecord.setFieldValue(
                            `policerecordsentence[${index}].answer`,
                            e.target.value
                          );
                          formikPoliceRecord.setFieldValue(
                            `policerecordsentence[${index}].sentence`,
                            row.id
                          );
                        }}
                        error={
                          formikPoliceRecord.touched.policerecordsentence?.[
                            index
                          ]?.answer &&
                          Boolean(
                            formikPoliceRecord.errors.policerecordsentence?.[
                              index
                            ]?.answer
                          )
                        }
                        helperText={
                          formikPoliceRecord.touched.policerecordsentence?.[
                            index
                          ]?.answer &&
                          formikPoliceRecord.errors.policerecordsentence?.[
                            index
                          ]?.answer
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>:null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikPoliceRecord.isSubmitting}>
        {formikPoliceRecord.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreatePoliceRecord;
