import React from "react";
import { useState, useEffect } from "react";
import { GET_POSTULANT_BY_ID, EDIT_POSTULNAT_BY_ID } from "./QueryPostulant";
import { useApolloClient } from "@apollo/client";
import TextField from "@mui/material/TextField";
import { POSTULANT_SCHEMA_YUP } from "./YupValidation";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { useFormik } from "formik";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
const EditPostulant = ({ id, onClose, params }) => {
  const { setAlertCustom, alertCustom, setNewPostulant } = params;
  const [loading, setLoading] = useState(true);
  const { currency,dateFormat } = useSettings();
  const [postulant, setPostulant] = useState({});
  const mindate = new Date("01/01/1950");
  const today = new Date();
  const minAge = 18;
  const client = useApolloClient();  
  useEffect(() => {
    const getPostulantById = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSTULANT_BY_ID,
          variables: {
            getPostulantByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        setPostulant(data.getPostulantById);

        formikPostulant.setValues({
          name: postulant?.name,
          surname: postulant?.surname,
          doi: postulant?.doi,
          dob: new Date(postulant?.dob),
          email: postulant?.email,
          phone: postulant?.phone,
        });
        setLoading(false);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getPostulantById();
  }, [postulant]);
  const formikPostulant = useFormik({
    initialValues: {
      name: "", //postulant?.name,
      surname: "", //postulant?.surname,
      doi: "", //postulant?.doi,
      dob: new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      ),
      email: "", //postulant?.email,
      phone: "", //postulant?.phone,
    },
    validationSchema: POSTULANT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { name, surname, doi, dob, email, phone } = values;

      try {
        const response = await client.mutate({
          mutation: EDIT_POSTULNAT_BY_ID,
          variables: {
            editPostulnatByIdId: id,
            name: name.trim().toUpperCase(),
            surname: surname.trim().toUpperCase(),
            doi: doi,
            dob,
            email: email.trim().toLowerCase(),
            phone: phone,
          },
        });
        setNewPostulant(response.data.editPostulnatById);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });

        resetForm();
        onClose();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    <>
      {!loading ? (
        <form onSubmit={formikPostulant.handleSubmit}>
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap="20px"
          >
            <Box sx={style}>
              <label for="my-input">NOMBRES</label>
              <TextField
                id="name"
                name="name"
                variant="outlined"
                value={formikPostulant.values.name}
                onChange={formikPostulant.handleChange}
                error={
                  formikPostulant.touched.name &&
                  Boolean(formikPostulant.errors.name)
                }
                helperText={
                  formikPostulant.touched.name && formikPostulant.errors.name
                }
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">APELLIDOS</label>
              <TextField
                id="surname"
                name="surname"
                variant="outlined"
                //  value={postulant?.surname}
                value={formikPostulant.values.surname}
                onChange={formikPostulant.handleChange}
                error={
                  formikPostulant.touched.surname &&
                  Boolean(formikPostulant.errors.surname)
                }
                helperText={
                  formikPostulant.touched.surname &&
                  formikPostulant.errors.surname
                }
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">DNI</label>
              <TextField
                id="doi"
                name="doi"
                variant="outlined"
                /* type="number" */
                //value={postulant?.doi}
                value={formikPostulant.values.doi}
                onChange={formikPostulant.handleChange}
                error={
                  formikPostulant.touched.doi &&
                  Boolean(formikPostulant.errors.doi)
                }
                helperText={
                  formikPostulant.touched.doi && formikPostulant.errors.doi
                }
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">TELEFONO</label>
              <TextField
                // value={postulant?.email}
                id="phone"
                name="phone"
                value={formikPostulant.values.phone}
                variant="outlined"
                onChange={formikPostulant.handleChange}
                error={
                  formikPostulant.touched.phone &&
                  Boolean(formikPostulant.errors.phone)
                }
                helperText={
                  formikPostulant.touched.phone && formikPostulant.errors.phone
                }
              />
            </Box>
            <Box sx={style}>
              <label for="my-input">FECHA DE NACIMIENTO</label>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={formikPostulant.values.dob}
                  id="dob"
                  name="dob"
                  minDate={mindate}
                  maxDate={
                    new Date(
                      today.getFullYear() - minAge,
                      today.getMonth(),
                      today.getDate()
                    )
                  }                
                  format={dateFormat?ChangeFormatDb(dateFormat):"MM/dd/yyyy"}
                  onChange={(val) => {
                    formikPostulant.setFieldValue("dob", val);
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={style}>
              <label for="my-input">EMAIL</label>
              <TextField
                //value={postulant?.email}
                id="email"
                name="email"
                variant="outlined"
                value={formikPostulant.values.email}
                onChange={formikPostulant.handleChange}
                error={
                  formikPostulant.touched.email &&
                  Boolean(formikPostulant.errors.email)
                }
                helperText={
                  formikPostulant.touched.email && formikPostulant.errors.email
                }
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"end"}
            marginTop={"30px"}
            gap={"15px"}
          >
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={formikPostulant.isSubmitting}
            >
              {formikPostulant.isSubmitting ? <LoopIcon /> : "GUARDAR"}
            </Button>
            <Button variant="contained" onClick={onClose}>
              CERRAR
            </Button>
            {/*  */}
          </Box>
        </form>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoopIcon />
        </Box>
      )}
    </>
  );
};

export default EditPostulant;
