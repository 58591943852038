import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      enabled
      firstlogin
      id
      role
      submodules
      personal {
        postulant {
          name
          surname
          email
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation($email: String!) {
    deleteUser(email: $email)
  }
`;

export const CREATE_USER = gql`
  mutation(
    $email: String!
    $password: String!
    $fullname: String!
    $lastname: String!
    $role: UserRole!
    $submodules: [Int]!
  ) {
    createUser(
      email: $email
      password: $password
      fullname: $fullname
      lastname: $lastname
      role: $role
      submodules: $submodules
    ) {
      email
      fullname
      role
      submodules
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($email: String!, $role: UserRole, $submodules: [Int]) {
    updateAnyUser(email: $email, role: $role, submodules: $submodules)
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($getUserByIdId: ID) {
    getUserById(id: $getUserByIdId) {
      admin
      enabled
      firstlogin
      id
      image
      personal {
        postulant {
          name
          surname
          email
          id
        }
      }
      role
      submodules
    }
  }
`;

export const EDIT_USER_ROLE_BY_ID = gql`
  mutation EditUserRoleById(
    $editUserRoleByIdId: ID
    $role: String
    $submodules: [Int]
    $enabled: Boolean
    $admin: Boolean
  ) {
    editUserRoleById(
      id: $editUserRoleByIdId
      role: $role
      submodules: $submodules
      enabled: $enabled
      admin: $admin
    ) {
      id
      firstlogin
      enabled
      image
      admin
    }
  }
`;
