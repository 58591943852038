import * as Yup from "yup";
export const USER_SCHEMA_YUP = Yup.object().shape({
  email: Yup.string()
    .email("Formato de correo electrónico inválido")
    .required("El correo electrónico es obligatorio")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Formato de correo electrónico inválido"
    ),
  password: Yup.string()
    .min(6, "La contraseña debe tener al menos 6 caracteres")
    .required("La contraseña es obligatoria")
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}\[:\];<>,.?~\-]+$/,
      "Formato de contraseña inválido"
    ),
});
