import React, { useState } from "react";
import { PROVIDER_SCHEMA_YUP } from "./YupValidation";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import FormControl from "@mui/material/FormControl";
import Alerts from "components/Alerts";
import {
  GET_ALL_PROVINCE,
  GET_ALL_DISTRICIT,
} from "./../../gestionpersonal/FichaPersonal/QueryPersonal";

import { CREATE_SUPPLIER } from "./QueryProvider";
const CreateProveedor = ({
  alertCustom,
  setAlertCustom,
  allTypes,
  allCategories,
  allDepartaments,
  allBank,
  allGroups,
  setNewProvider,
}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const handleProvince = async (departament) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_PROVINCE,
        variables: {
          departament: departament,
        },
      });
      console.log(response.data);
      setProvince(response.data.getProvinceByDepartamentId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleDistrict = async (province) => {
    try {
      const response = await client.mutate({
        mutation: GET_ALL_DISTRICIT,
        variables: {
          province: province,
        },
      });
      setDistrict(response.data.getDistrictByProvinceId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const formikProveedor = useFormik({
    initialValues: {
      type: "",
      category: "",
      departament: "",
      province: "",
      district: "",
      ruc: "",
      businessname: "",
      address: "",
      group: [],
      phone: "",
      numbersoles: "",
      ccisoles: "",
      banksoles: "",
      numberdollar: "",
      ccidollar: "",
      bankdollar: "",
      numberdetraction: "",
    },
    validationSchema: PROVIDER_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const {
        address,
        bankdollar,
        banksoles,
        businessname,
        category,
        ccidollar,
        ccisoles,
        departament,
        district,
        group,
        numberdetraction,
        numberdollar,
        numbersoles,
        phone,
        province,
        ruc,
        type,
      } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_SUPPLIER,
          variables: {
            address: address.toUpperCase(),
            bankdollar:bankdollar==""?null:bankdollar,
            banksoles:banksoles==""?null:banksoles,
            businessname: businessname.toUpperCase(),
            category,
            ccidollar,
            ccisoles,
            departament,
            district,
            group,
            numberdetraction,
            numberdollar,
            numbersoles,
            phone,
            province,
            ruc,
            type,
          },
        });

        setNewProvider(response.data.createSupplier);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikProveedor.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"} py={"5px"}>
        CREAR PROVEEDOR
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="20px">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">TIPO</InputLabel>
          <Select
            id="type"
            value={formikProveedor.values.type}
            name="type"
           label="TIPO"
            onChange={formikProveedor.handleChange}
            error={
              formikProveedor.touched.type &&
              Boolean(formikProveedor.errors.type)
            }
          >
            {allTypes?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.type && formikProveedor.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.type && formikProveedor.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">CATEGORIA</InputLabel>
          <Select
            id="category"
            value={formikProveedor.values.category}
            name="category"
            label="CATEGORIA"
            onChange={formikProveedor.handleChange}
            error={
              formikProveedor.touched.category &&
              Boolean(formikProveedor.errors.category)
            }
          >
            {allCategories?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.category &&
          formikProveedor.errors.category ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.category &&
                formikProveedor.errors.category}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DEPARTAMENTO</InputLabel>
          <Select
            id="departament"
            value={formikProveedor.values.departament}
            name="departament"
            label="DEPARTAMENTO" 
            onChange={(e) => {
              formikProveedor.setFieldValue("departament", e.target.value);
              handleProvince(e.target.value);
            }}
            error={
              formikProveedor.touched.departament &&
              Boolean(formikProveedor.errors.departament)
            }
          >
            {allDepartaments?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.departament &&
          formikProveedor.errors.departament ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.departament &&
                formikProveedor.errors.departament}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PROVINCIA</InputLabel>
          <Select
            id="province"
            label="PROVINCIA" 
            value={formikProveedor.values.province}
            name="province"
            onChange={(e) => {
              formikProveedor.setFieldValue("province", e.target.value);
              handleDistrict(e.target.value);
            }}
            error={
              formikProveedor.touched.province &&
              Boolean(formikProveedor.errors.province)
            }
          >
            {province?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.province &&
          formikProveedor.errors.province ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.province &&
                formikProveedor.errors.province}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">DISTRITO</InputLabel>
          <Select
            id="district"
            value={formikProveedor.values.district}
            name="district"
            label="DISTRITO" 
            onChange={formikProveedor.handleChange}
            error={
              formikProveedor.touched.district &&
              Boolean(formikProveedor.errors.district)
            }
          >
            {district?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.district &&
          formikProveedor.errors.district ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.district &&
                formikProveedor.errors.district}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-name-label">GRUPO</InputLabel>
          <Select
            labelId="group"
            id="group"
            name="group"
            label="GRUPO" 
            multiple
            value={formikProveedor.values.group}
            onChange={formikProveedor.handleChange}
            error={
              formikProveedor.touched.group &&
              Boolean(formikProveedor.errors.group)
            }
          >
            {allGroups.map((name) => (
              <MenuItem
                key={name.id}
                value={name.id}
                /*  style={getStyles(name, personName, theme)} */
              >
                {name.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="ruc"
          name="ruc"
          label="RUC"
          variant="outlined"
          value={formikProveedor.values.ruc}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.ruc && Boolean(formikProveedor.errors.ruc)
          }
          helperText={formikProveedor.touched.ruc && formikProveedor.errors.ruc}
        />
        <TextField
          id="businessname"
          name="businessname"
          label="RAZON SOCIAL"
          variant="outlined"
          value={formikProveedor.values.businessname}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.businessname &&
            Boolean(formikProveedor.errors.businessname)
          }
          helperText={
            formikProveedor.touched.businessname &&
            formikProveedor.errors.businessname
          }
        />
        <TextField
          id="address"
          name="address"
          label="DIRECCION"
          variant="outlined"
          value={formikProveedor.values.address}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.address &&
            Boolean(formikProveedor.errors.address)
          }
          helperText={
            formikProveedor.touched.address && formikProveedor.errors.address
          }
        />
        <TextField
          id="phone"
          name="phone"
          label="TELEFONO"
          variant="outlined"
          value={formikProveedor.values.phone}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.phone &&
            Boolean(formikProveedor.errors.phone)
          }
          helperText={
            formikProveedor.touched.phone && formikProveedor.errors.phone
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">BANCO EN SOLES</InputLabel>
          <Select
            id="banksoles"
            value={formikProveedor.values.banksoles}
            name="banksoles"
            onChange={formikProveedor.handleChange}
            label="BANCO EN SOLES"
            error={
              formikProveedor.touched.banksoles &&
              Boolean(formikProveedor.errors.banksoles)
            }
          >
            {allBank?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.banksoles &&
          formikProveedor.errors.banksoles ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.banksoles &&
                formikProveedor.errors.banksoles}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="numbersoles"
          name="numbersoles"
          label="N° DE CUENTA EN SOLES"
          variant="outlined"
          value={formikProveedor.values.numbersoles}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.numbersoles &&
            Boolean(formikProveedor.errors.numbersoles)
          }
          helperText={
            formikProveedor.touched.numbersoles &&
            formikProveedor.errors.numbersoles
          }
        />
        <TextField
          id="ccisoles"
          name="ccisoles"
          label="CCI DE CUENTA EN SOLES"
          variant="outlined"
          value={formikProveedor.values.ccisoles}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.ccisoles &&
            Boolean(formikProveedor.errors.ccisoles)
          }
          helperText={
            formikProveedor.touched.ccisoles && formikProveedor.errors.ccisoles
          }
        />
        <TextField
          id="numberdetraction"
          name="numberdetraction"
          label="N° DE CUENTA DE DETRACCIONES"
          variant="outlined"
          value={formikProveedor.values.numberdetraction}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.numberdetraction &&
            Boolean(formikProveedor.errors.numberdetraction)
          }
          helperText={
            formikProveedor.touched.numberdetraction &&
            formikProveedor.errors.numberdetraction
          }
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            BANCO EN DOLARES
          </InputLabel>
          <Select
            id="bankdollar"
            value={formikProveedor.values.bankdollar}
            name="bankdollar"
            label="BANCO EN DOLARES"
            onChange={formikProveedor.handleChange}
            error={
              formikProveedor.touched.bankdollar &&
              Boolean(formikProveedor.errors.bankdollar)
            }
          >
            {allBank?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikProveedor.touched.bankdollar &&
          formikProveedor.errors.bankdollar ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikProveedor.touched.bankdollar &&
                formikProveedor.errors.bankdollar}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="numberdollar"
          name="numberdollar"
          label="N° DE CUENTA EN DOLARES"
          variant="outlined"
          value={formikProveedor.values.numberdollar}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.numberdollar &&
            Boolean(formikProveedor.errors.numberdollar)
          }
          helperText={
            formikProveedor.touched.numberdollar &&
            formikProveedor.errors.numberdollar
          }
        />
        <TextField
          id="ccidollar"
          name="ccidollar"
          label="CCI DE CUENTA EN DOLARES"
          variant="outlined"
          value={formikProveedor.values.ccidollar}
          onChange={formikProveedor.handleChange}
          error={
            formikProveedor.touched.ccidollar &&
            Boolean(formikProveedor.errors.ccidollar)
          }
          helperText={
            formikProveedor.touched.ccidollar &&
            formikProveedor.errors.ccidollar
          }
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"5px"}>
        <Button variant="contained" color="success" type="submit"  disabled={formikProveedor.isSubmitting}>
        {formikProveedor.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateProveedor;
