import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ModalUtil from "components/ModalUtil";
import { useApolloClient } from "@apollo/client";
import StatBoxOne from "components/StatBoxOne";
import DialogUtil from "components/DialogUtil";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Autocomplete from "@mui/material/Autocomplete";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { GET_ALL_PERSONAL } from "./../FichaPersonal/QueryPersonal";
import { FormHelperText } from "@mui/material";
import {
  GET_DOCUMENTS_BY_PERSONAL_ID,
  GET_TRAININGS_BY_PERSONAL_ID,
  GET_LISTDOCUMENT_BY_PERSONAL_ID,
  GET_LISTDOCUMENT_BY_TRAINING_ID,
} from "./QueryDocument";
import TextField from "@mui/material/TextField";
import CreateDocument from "./CreateDocument";
import AssignDocumentPersonal from "./AssignDocumentPersonal";
import ViewDocumentPersonal from "./ViewDocumentPersonal";
import AssignTrainingPersonal from "./AssignTrainingPersonal";
import { useUser } from "hooks/useUser";
import ViewTrainingPersonal from "./ViewTrainingPersonal";
import DeletedDocumentPerosnal from "./DeletedDocumentPerosnal";
import DeletedDocumentTraining from "./DeletedDocumentTraining";
const Document = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { user } = useUser();
  const [id, setId] = useState(null);
  const client = useApolloClient();
  const [personalData, setPersonalData] = useState([]);

  const [trainings, setTrainings] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [modalOpenAssign, setModalOpenAssign] = useState(false);
  const [modalOpenAssignTraining, setModalOpenAssignTraining] = useState(false);
  const [modalOpenAssignViewPersonal, setModalOpenAssignViewPersonal] =
    useState(false);
  const [modalOpenAssignViewTraining, setModalOpenAssignViewTraining] =
    useState(false);
  const [listDocumentPersonal, setlistDocumentPersonal] = useState([]);
  const [requerimentselectedAssign, setrequerimentselectedAssign] =
    useState(null);
  const [newAssignRequeriment, setNewAssignRequeriment] = useState(false);
  const [listDocumentTraining, setlistDocumentTraining] = useState([]);
  const [newDocument, setNewDocument] = useState(null);
  const [deletedDocumentPersonal, setdeletedDocumentPersonal] = useState(false);
  const [deletedDocumentTraining, setdeletedDocumentTraining] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const handleOpenModalAssign = (id) => {
    setModalOpenAssign(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseModalAssign = () => {
    setModalOpenAssign(false);
  };
  const handleOpenModalAssignTraining = (id) => {
    setModalOpenAssignTraining(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseModalAssignTraining = () => {
    setModalOpenAssignTraining(false);
  };
  const handleOpenModalAssignViewPersonal = (id) => {
    setModalOpenAssignViewPersonal(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseModalAssignViewPersonal = () => {
    setModalOpenAssignViewPersonal(false);
  };
  const handleOpenModalAssignViewTraining = (id) => {
    setModalOpenAssignViewTraining(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseModalAssignViewTraining = () => {
    setModalOpenAssignViewTraining(false);
  };
  const columnsDocuments = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "document",
      headerName: "DOCUMENTO",
      flex: 1,
      renderCell: (params) => params.row.document.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "-",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        switch (params.row.state.description) {
          case "VIGENTE":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#28a745"}
              ></Box>
            );
          case "POR ACTUALIZAR":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#ffc107"}
              ></Box>
            );
          case "POR VENCER":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#dc3545"}
              ></Box>
            );
          default:
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#000"}
              ></Box>
            );
        }
      },
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {params.row.state.description == "INASIGNADO" ? (
            <TouchAppIcon
              onClick={() => handleOpenModalAssign(params.row.id)}
            />
          ) : !user.admin ? (
            <VisibilityIcon
              onClick={() => handleOpenModalAssignViewPersonal(params.row.id)}
            />
          ) : (
            <>
              <VisibilityIcon
                onClick={() => handleOpenModalAssignViewPersonal(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDeletedDocument(params.row.id)}
              />
            </>
          )}
        </Box>
      ),
    },
  ];
  const handleOpenDeletedDocument = (id) => {
    setdeletedDocumentPersonal(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseDeletedDocument = () => {
    setdeletedDocumentPersonal(false);
  };
  const handleOpenDeletedTraining = (id) => {
    setdeletedDocumentTraining(true);
    setrequerimentselectedAssign(id);
  };

  const handleCloseDeletedTraining = () => {
    setdeletedDocumentTraining(false);
  };
  const columnsTrainings = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "document",
      headerName: "DOCUMENTO",
      flex: 1,
      renderCell: (params) => params.row.document.description,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      align: "center",
      renderCell: (params) => params.row.state.description,
    },
    {
      field: "-",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        switch (params.row.state.description) {
          case "VIGENTE":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#28a745"}
              ></Box>
            );
          case "POR ACTUALIZAR":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#ffc107"}
              ></Box>
            );
          case "POR VENCER":
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#dc3545"}
              ></Box>
            );
          default:
            return (
              <Box
                borderRadius={"50%"}
                width={"15px"}
                height={"15px"}
                backgroundColor={"#000"}
              ></Box>
            );
        }
      },
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          {params.row.state.description == "INASIGNADO" ? (
            <TouchAppIcon
              onClick={() => handleOpenModalAssignTraining(params.row.id)}
            />
          ) : !user.admin ? (
            <VisibilityIcon
              onClick={() => handleOpenModalAssignViewTraining(params.row.id)}
            />
          ) : (
            <>
              {" "}
              <VisibilityIcon
                onClick={() => handleOpenModalAssignViewTraining(params.row.id)}
              />
              <DeleteIcon
                color="error"
                onClick={() => handleOpenDeletedTraining(params.row.id)}
              />
            </>
          )}
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setPersonalData(data.getAllPersonal);
      } catch (error) {
        console.log(error.message);
        /* setAlertCustom({
                  ...alertCustom,
                  type: "error",
                  message: error.message,
                  state: true,
                }); */
      }
    };
    FUNCTION_GET_ALL_PERSONAL();
    FUNCTION_GET_DOCUMENT_BY_POSTULANT(user.admin ? id : user.personal.id);
  }, [newDocument, id, newAssignRequeriment]);

  const FUNCTION_GET_DOCUMENT_BY_POSTULANT = async (id) => {
    console.log(id);
    try {
      const documents = await client.query({
        query: GET_DOCUMENTS_BY_PERSONAL_ID,
        variables: {
          getDocumentPersonalByPersonalIdId: id,
        },
        fetchPolicy: "network-only",
      });

      const trainings = await client.query({
        query: GET_TRAININGS_BY_PERSONAL_ID,
        variables: {
          getDocumentTrainingByPersonalIdId: id,
        },
        fetchPolicy: "network-only",
      });

      const listDocumentPersonal = await client.query({
        query: GET_LISTDOCUMENT_BY_PERSONAL_ID,
        variables: {
          getDocumentListByPersonalIdId: id,
        },
        fetchPolicy: "network-only",
      });

      const listDocumentTraining = await client.query({
        query: GET_LISTDOCUMENT_BY_TRAINING_ID,
        variables: {
          getDocumentListByTrainingIdId: id,
        },
        fetchPolicy: "network-only",
      });
      setDocuments(documents.data.getDocumentPersonalByPersonalId);
      setTrainings(trainings.data.getDocumentTrainingByPersonalId);
      console.log(documents.data);
      setlistDocumentPersonal(
        listDocumentPersonal.data.getDocumentListByPersonalId
      );
      setlistDocumentTraining(
        listDocumentTraining.data.getDocumentListByTrainingId
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      {" "}
      <DialogUtil
        title={"ELIMINAR DOCUMENTO PERSONAL"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={deletedDocumentPersonal}
        onClose={handleCloseDeletedDocument}
        Component={DeletedDocumentPerosnal}
        id={requerimentselectedAssign}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewDocument,
          })
        }
      />
      <DialogUtil
        title={"ELIMINAR DOCUMENTO DE ENTRENAMIENTO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={deletedDocumentTraining}
        onClose={handleCloseDeletedTraining}
        Component={DeletedDocumentTraining}
        id={requerimentselectedAssign}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewDocument,
          })
        }
      />
      <ModalUtil
        isOpen={modalOpenAssign}
        onClose={handleCloseModalAssign}
        Component={AssignDocumentPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModalAssign,
          })
        }
        id={requerimentselectedAssign}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenAssignViewPersonal}
        onClose={handleCloseModalAssignViewPersonal}
        Component={ViewDocumentPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalAssignViewPersonal,
          })
        }
        id={requerimentselectedAssign}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenAssignTraining}
        onClose={handleCloseModalAssignTraining}
        Component={AssignTrainingPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: setNewAssignRequeriment,
            handleCloseModal: handleCloseModalAssignTraining,
          })
        }
        id={requerimentselectedAssign}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenAssignViewTraining}
        onClose={handleCloseModalAssignViewTraining}
        Component={ViewTrainingPersonal}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalAssignViewTraining,
          })
        }
        id={requerimentselectedAssign}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
        <Header title="GESTION DE PERSONAS" subtitle="personal/documentos" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 6"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {user.admin ? (
              <>
                <Box
                  fontSize={"12px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  py="10px"
                >
                  BUSCAR PERSONAL
                </Box>
                {personalData.length ? (
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={personalData?.map(
                      (option) =>
                        new Object({
                          label:
                            option.postulant.name +
                            "  " +
                            option.postulant.surname +
                            " / " +
                            "dni:" +
                            option.postulant.doi,
                          value: option.id,
                        })
                    )}
                    onChange={(event, newValue) => {
                      setId(newValue.value);
                      FUNCTION_GET_DOCUMENT_BY_POSTULANT(newValue.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar por nombre/apellido/dni"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                ) : (
                  <FormHelperText
                    sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                    <p
                      style={{
                        border: "1px solid",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      No hay Datos para mostrar
                    </p>
                  </FormHelperText>
                )}
              </>
            ) : null}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DOCUMENTOS PERSONALES
            </Box>
            <DataGrid
              loading={!documents}
              getRowId={(row) => row.id}
              rows={documents || []}
              columns={columnsDocuments}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>

          {/* ROW 2 */}
          <Box
            gridColumn="span 6"
            gridRow="span 6"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box>
              <CreateDocument
                newDocument={newDocument}
                setNewDocument={setNewDocument}
                dataPersonal={listDocumentPersonal}
                dataTraining={listDocumentTraining}
                alertCustom={alertCustom}
                setAlertCustom={setAlertCustom}
                id={user.admin ? id : user.personal.id}
              />
              {/*  <CreateJobReference bossPosition={bossPosition} criterion={criterion} /> */}
            </Box>
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
              HISTORIAL DOCUMENTOS DE ENTRENAMIENTO
            </Box>
            <DataGrid
              loading={!trainings}
              getRowId={(row) => row.id}
              rows={trainings || []}
              columns={columnsTrainings}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}      
              disableSelectionOnClick 
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          {/*  <StatBoxOne
          title="Total Customers"
          value={"12"}
          increase="+14%"
          description="Since last month"
          icon={
            <Email
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBoxOne
          title="Sales Today"
          value={"12"}
          increase="+21%"
          description="Since last month"
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}
        </Box>
      </Box>
    </>
  );
};

export default Document;
