import * as Yup from "yup";

export const PROVIDER_CATEGORY_SCHEMA_YUP = Yup.object().shape({
  description: Yup.string()
    .min(5, "Debe tener minimo 5 caracteres")
    .required("Requerido"),
});
export const PROVIDER_SCHEMA_YUP = Yup.object().shape({
  type: Yup.string().required("Requirido"),
  category: Yup.string().required("Requirido"),
  departament: Yup.string().required("Requirido"),
  province: Yup.string().required("Requirido"),
  district: Yup.string().required("Requirido"),
  ruc: Yup.number()
    .integer("El número debe ser un número entero")
    .positive("El número debe ser positivo")
    .min(10000000000, "El número debe tener exactamente 11 dígitos")
    .max(99999999999, "El número debe tener exactamente 11 dígitos")
    .required("Requiredo"),
  businessname: Yup.string().required("Requirido"),
  address: Yup.string().required("Requirido"),
  phone: Yup.string().required("Requirido"),
  group: Yup.array().of(Yup.string()),

  numbersoles: Yup.string(),
  ccisoles: Yup.string(),
  banksoles: Yup.string(),

  numberdollar: Yup.string(),
  ccidollar: Yup.string(),
  bankdollar: Yup.string(),

  numberdetraction: Yup.string(),
});
