import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import LoopIcon from "@mui/icons-material/Loop";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alerts from "components/Alerts";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import { ITEM_REQUERIMENT_SCHEMA_YUP } from "./YupValidation";
import { useUser } from "hooks/useUser";
import { CREATE_ITEM_REQUERIMENT } from "./QueryItemRequeriment";
import { useSettings } from "hooks/useSettings";
import { ChangeFormatDb } from "utils/DateFormatting";
import { GET_CLASS_BY_GROUP_ID } from "./../item/QueryItem";
import {
  GET_ITEMS_BY_CLASS_ID,
  GET_ACTIVES_BY_ITEM_ID,
} from "./../../operation/report/QueryReport";
const CreateRequeriment = ({
  setAlertCustom,
  alertCustom,
  personal,
  areacro,
  items,
  groups,
  priority,
  setNewItemRequeriment,
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const client = useApolloClient();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState([]);
  const theme = useTheme();
  const { currency, dateFormat } = useSettings();
  const { user } = useUser();
  const dataGridRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [classs, setAllClasss] = useState([]);
  const [item, setAllitem] = useState([]);
  const [active, setAllactive] = useState([]);
  const handleClass = async (group) => {
    try {
      const response = await client.mutate({
        mutation: GET_CLASS_BY_GROUP_ID,
        
        variables: {
          group: group,
        },
      });
      setAllClasss(response.data.getClassByGroupId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleItem = async (classs) => {
    try {
      const response = await client.mutate({
        mutation: GET_ITEMS_BY_CLASS_ID,
        variables: {
          getItemsByClassIdId: classs,
        },
        fetchPolicy: "network-only",
      });
      console.log(response.data.getItemsByClassId);
      setAllitem(response.data.getItemsByClassId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handleActive = async (item) => {
    try {
      const response = await client.mutate({
        mutation: GET_ACTIVES_BY_ITEM_ID,
        variables: {
          getActiveByItemIdId: item,
        },
        fetchPolicy: "network-only",
      });
      console.log(response.data.getActiveByItemId);
      setAllactive(response.data.getActiveByItemId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const initialValues = {
    personal: {
      label: "",
      id: !user.admin ? user.personal.id : "",
    },
    area: areacro,
    date: new Date(),
    priority: "",
    subject: "",
    costcenter: "",
    incomeRequerimentItem: items.map(
      (el) =>
        new Object({
          item: "",
          amount: "",
        })
    ),
  };
  const formikItemRequeriment = useFormik({
    initialValues,
    validationSchema: ITEM_REQUERIMENT_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const {
        personal,
        date,
        priority,
        area,
        subject,
        incomeRequerimentItem,
        costcenter,
      } = values;
      setSubmitting(true);
      const newIncomeItem = incomeRequerimentItem.map((it) => {
        return new Object({
          item: it,
          amount: Number(document.getElementById(it).value),
        });
      });

      try {
        const response = await client.mutate({
          mutation: CREATE_ITEM_REQUERIMENT,
          variables: {
            personal: personal.id,
            area,
            date,
            costcenter:costcenter?costcenter:null,
            priority,
            subject: subject.toUpperCase(),
            incomeRequerimentItem: newIncomeItem,
          },
        });
        console.log(response.data.createRequerimentIncome);
        setNewItemRequeriment(response.data.createRequerimentIncome);
        /* handleCloseModal(); */
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        setSelectionModel([]);
        incomeRequerimentItem.forEach((it) => {
          document.getElementById(it).value = "";
        });
        if (user.admin) {
          autocompleteRef.current.value = "";
        }
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const columns = [
    /* {
      field: "active",
      headerName: "ACTIVO",
      flex: 1,
      renderCell: (params) => params.row.active,
    }, */
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 2,
      renderCell: (params) => params.row.description,
    },
    {
      field: "unity",
      headerName: "UNIDAD",
      flex: 1,
      renderCell: (params) =>
        params.row.unity.description + " / " + params.row.specification,
    },
    {
      field: "ope",
      headerName: "CANTIDAD",
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <TextField
            disabled={!params.api.isRowSelected(params.row.id)}
            id={params.row.id}
            required={params.api.isRowSelected(params.row.id) ? true : false}
            variant="standard"
            className="input-requeriment-logistic"
          />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    formikItemRequeriment.resetForm({ values: initialValues });
    setSelectionModel([]);
  }, [location.pathname]);
  return (
    <form onSubmit={formikItemRequeriment.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      {user.admin ? (
        <Box mb={"20px"}>
          {personal?.length ? (
            <Autocomplete
              freeSolo
              id="personal"
              name="personal"
              disableClearable
              ref={autocompleteRef}
              value={formikItemRequeriment.values.personal.label}
              options={personal?.map(
                (option) =>
                  new Object({
                    label:
                      option.postulant.name +
                      "  " +
                      option.postulant.surname +
                      " / " +
                      "dni:" +
                      option.postulant.doi,
                    value: option.id,
                  })
              )}
              onChange={(event, newValue) => {
                formikItemRequeriment.setFieldValue("personal", {
                  label: newValue.label,
                  id: newValue.value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar por nombre/apellido/dni"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={
                    formikItemRequeriment.touched.personal?.id &&
                    Boolean(formikItemRequeriment.errors.personal?.id)
                  }
                  helperText={
                    formikItemRequeriment.touched.personal?.id &&
                    formikItemRequeriment.errors.personal?.id
                  }
                />
              )}
            />
          ) : (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              <p
                style={{
                  border: "1px solid",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                No hay Datos para mostrar
              </p>
            </FormHelperText>
          )}
        </Box>
      ) : null}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        {/* <TextField
          id="ngoal"
          name="ngoal"
          label="CENTRO DE COSTOS"
          variant="outlined"
          value={formikItemRequeriment.values.ngoal}
          onChange={formikItemRequeriment.handleChange}
          error={
            formikItemRequeriment.touched.ngoal &&
            Boolean(formikItemRequeriment.errors.ngoal)
          }
          helperText={
            formikItemRequeriment.touched.ngoal &&
            formikItemRequeriment.errors.ngoal
          }
        /> */}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">PRIORIDAD</InputLabel>
          <Select
            id="priority"
            value={formikItemRequeriment.values.priority}
            name="priority"
            onChange={formikItemRequeriment.handleChange}
            error={
              formikItemRequeriment.touched.priority &&
              Boolean(formikItemRequeriment.errors.priority)
            }
            label="PRIORIDAD"
          >
            {priority?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikItemRequeriment.touched.priority &&
          formikItemRequeriment.errors.priority ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikItemRequeriment.touched.priority &&
                formikItemRequeriment.errors.priority}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="subject"
          name="subject"
          label="MOTIVO"
          variant="outlined"
          value={formikItemRequeriment.values.subject}
          onChange={formikItemRequeriment.handleChange}
          error={
            formikItemRequeriment.touched.subject &&
            Boolean(formikItemRequeriment.errors.subject)
          }
          helperText={
            formikItemRequeriment.touched.subject &&
            formikItemRequeriment.errors.subject
          }
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={formikItemRequeriment.values.date}
            id="date"
            name="date"
            label="FECHA DE REQUERIMIENTO"
            format={dateFormat ? ChangeFormatDb(dateFormat) : "MM/dd/yyyy"}
            onChange={(val) => {
              formikItemRequeriment.setFieldValue("date", val);
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">GRUPO</InputLabel>
          <Select
            id="group"
            name="group"
            label="GRUPO"
            onChange={(e) => {
              handleClass(e.target.value);
            }}
          >
            {groups?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">CLASE</InputLabel>
          <Select
            id="classs"
            label="CLASE"
            name="classs"
            onChange={(e) => {
              handleItem(e.target.value);
            }}
          >
            {classs?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ITEM</InputLabel>
          <Select
            id="itemx"
            label="ITEM"
            name="itemx"
            onChange={(e) => {
              handleActive(e.target.value);
            }}
          >
            {item?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ACTIVO</InputLabel>
          <Select
            id="costcenter"
            value={formikItemRequeriment.values.costcenter}
            name="costcenter"
            label="ACTIVO"
            onChange={(e) => {
              formikItemRequeriment.setFieldValue("costcenter", e.target.value);
            }}
            error={
              formikItemRequeriment.touched.costcenter &&
              Boolean(formikItemRequeriment.errors.costcenter)
            }
          >
            {active?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikItemRequeriment.touched.costcenter &&
          formikItemRequeriment.errors.costcenter ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikItemRequeriment.touched.costcenter &&
                formikItemRequeriment.errors.costcenter}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      <Box
        gridColumn="span 6"
        gridRow="span 3"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!items}
          getRowId={(row) => row.id}
          rows={items || []}
          ref={dataGridRef}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}                 
          autoHeight={true}
          pageSize={10}
          sx={{
            "&.Mui-checked": {
              color: "#000", // Cambia el color cuando el radio button está marcado
            },
          }}
          onSelectionModelChange={(itm) => {
            setSelectionModel(itm);
            formikItemRequeriment.setFieldValue("incomeRequerimentItem", itm);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>
            ),
          }}
        />
        {formikItemRequeriment.touched.incomeRequerimentItem &&
        formikItemRequeriment.errors.incomeRequerimentItem ? (
          <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
          >
            <p
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Verica los campos esten requeridos
            </p>
          </FormHelperText>
        ) : null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={formikItemRequeriment.isSubmitting}
        >
          {formikItemRequeriment.isSubmitting ? <LoopIcon /> : "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateRequeriment;
