import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { MOVILITY_SCHEMA_YUP } from "./YupValidation";
import { CREATE_MOVILITY } from "./QueryPersonal";
import Alerts from "components/Alerts";
import LoopIcon from '@mui/icons-material/Loop';
const CreateMovility = ({
  movilityType,
  id,
  setNewMovilty,
  setAlertCustom,
  alertCustom,
  setErrorId,
}) => {
  
  const client = useApolloClient();

  const formikMovility = useFormik({
    initialValues: {
      personal: "",
      type: "",
      own: true,
      licence: "",
      brand: "",
      year: "",
      plate: "",
    },
    validationSchema: MOVILITY_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (id) {
        setErrorId(false);
        setSubmitting(true);
        const { type, own, licence, brand, year, plate } = values;
        try {
          const response = await client.mutate({
            mutation: CREATE_MOVILITY,
            variables: {
              personal: id,
              type:type==''?null:type,
              own,
              licence: licence?.toUpperCase(),
              brand: brand?.toUpperCase(),
              year: year?.toUpperCase(),
              plate: plate?.toUpperCase(),
            },
          });
          setNewMovilty(response.data.createMovility);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          resetForm();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        setErrorId(true);
      }
    },
  });
  return (
    <form onSubmit={formikMovility.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="20px"
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            ¿POSEE MOVILIDAD?
          </InputLabel>
          <Select
            id="own"
            value={formikMovility.values.own}
            name="own"
            label="¿POSEE MOVILIDAD?"
            onChange={formikMovility.handleChange}
            error={
              formikMovility.touched.own && Boolean(formikMovility.errors.own)
            }
          >
            <MenuItem key={true} value={true}>
              SI
            </MenuItem>
            <MenuItem key={false} value={false}>
              NO
            </MenuItem>
          </Select>
          {formikMovility.touched.own && formikMovility.errors.own ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikMovility.touched.own && formikMovility.errors.own}
            </FormHelperText>
          ) : null}
        </FormControl>

       { formikMovility.values.own?<>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            TIPO DE VEHICULO
          </InputLabel>
          <Select
            id="type"
            value={formikMovility.values.type}
            name="type"
            label="TIPO DE VEHICULO"
            onChange={formikMovility.handleChange}
            error={
              formikMovility.touched.type && Boolean(formikMovility.errors.type)
            }
          >
            {movilityType?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              );
            })}
          </Select>
          {formikMovility.touched.type && formikMovility.errors.type ? (
            <FormHelperText
              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
            >
              {formikMovility.touched.type && formikMovility.errors.type}
            </FormHelperText>
          ) : null}
        </FormControl>
        <TextField
          id="licence"
          name="licence"
          label="LICENCIA"
          variant="outlined"
          value={formikMovility.values.licence}
          onChange={formikMovility.handleChange}
          error={
            formikMovility.touched.licence &&
            Boolean(formikMovility.errors.licence)
          }
          helperText={
            formikMovility.touched.licence && formikMovility.errors.licence
          }
        />

        <TextField
          id="brand"
          name="brand"
          label="MARCA"
          variant="outlined"
          value={formikMovility.values.brand}
          onChange={formikMovility.handleChange}
          error={
            formikMovility.touched.brand && Boolean(formikMovility.errors.brand)
          }
          helperText={
            formikMovility.touched.brand && formikMovility.errors.brand
          }
        />
        <TextField
          id="year"
          name="year"
          label="AÑO"
          variant="outlined"
          value={formikMovility.values.year}
          onChange={formikMovility.handleChange}
          error={
            formikMovility.touched.year && Boolean(formikMovility.errors.year)
          }
          helperText={formikMovility.touched.year && formikMovility.errors.year}
        />
        <TextField
          id="plate"
          name="plate"
          label="PLACA"
          variant="outlined"
          value={formikMovility.values.plate}
          onChange={formikMovility.handleChange}
          error={
            formikMovility.touched.plate && Boolean(formikMovility.errors.plate)
          }
          helperText={
            formikMovility.touched.plate && formikMovility.errors.plate
          }
        /></>:null}
      </Box>
      <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
        <Button variant="contained" color="success" type="submit" disabled={formikMovility.isSubmitting}>
        {formikMovility.isSubmitting ?<LoopIcon/>: "GUARDAR"}
        </Button>
      </Box>
    </form>
  );
};

export default CreateMovility;
