import { gql } from "@apollo/client";

export const GET_PERSONAL_POSITION = gql`
  query GetPersonalPosition {
    getPersonalPosition {
      id
      postulant {
        id
        doi
        name
        surname
      }
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizePersonal {
    getSumarizePersonal {
      lastDate
      lastPersonal
      personal
    }
  }
`;

export const GET_POSITION_PERSONAL_BY_ID = gql`
  query GetPositionbyPersonalId($getPositionbyPersonalIdId: ID) {
    getPositionbyPersonalId(id: $getPositionbyPersonalIdId) {
      id
      personal {
        postulant {
          name
          surname
          doi
        }
      }
      position {
        description
      }
      personalrequeriment {
        area {
          description
        }
        duration
        position {
          description
        }
        remuneration
        workplace {
          description
        }
      }
    }
  }
`;

export const EDIT_PERSONAL_BY_ID = gql`
  mutation EditPersonalById(
    $editPersonalByIdId: ID
    $name: String
    $surname: String
    $doi: String
    $dob: Date
    $email: String
    $phone: Int
    $address: String
    $stature: Float
    $departament: ID
    $province: ID
    $district: ID
    $civilstatus: ID
    $gender: ID
    $contexture: ID
  ) {
    editPersonalById(
      id: $editPersonalByIdId
      name: $name
      surname: $surname
      doi: $doi
      dob: $dob
      email: $email
      phone: $phone
      address: $address
      stature: $stature
      departament: $departament
      province: $province
      district: $district
      civilstatus: $civilstatus
      gender: $gender
      contexture: $contexture
    ) {
      id
    }
  }
`;

export const  DELETE_PERSONAL_BY_ID= gql`
mutation DeletePersonalById($deletePersonalByIdId: ID) {
  deletePersonalById(id: $deletePersonalByIdId) {
    id
  }
}`;