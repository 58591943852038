import React,{ useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoopIcon from '@mui/icons-material/Loop';
import { useApolloClient } from "@apollo/client";
import { DELETE_JOBREFERENCE_BY_ID } from "./QueryJobReference";
const DeleteJobReference = ({ onClose,params,id }) => {
    const {setAlertCustom,alertCustom,setNewRegister}=params;
    const [loading, setLoading] = useState(true);
    const client = useApolloClient();
    const onAcept = async () => {
        try {
          const response = await client.mutate({
            mutation: DELETE_JOBREFERENCE_BY_ID,
            variables: {
                deleteJobReferenceId: id,        
            },
          });
          setNewRegister(response.data.deleteJobReference);
          setAlertCustom({
            type: "success",
            message: "Registro ingresado",
            state: true,
          });
          setLoading(false); 
          onClose();
        } catch (error) {
          setAlertCustom({
            ...alertCustom,
            type: "error",
            message: error.message,
            state: true,
          });
        }
      };
  return (
    <>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button onClick={onAcept} variant="contained" color="error" disabled={!loading}>
        {!loading ?<LoopIcon/>: "ACEPTAR"}
        </Button>
      </DialogActions>
    </>
  )
}

export default DeleteJobReference