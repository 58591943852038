import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useApolloClient } from "@apollo/client";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import StatBoxOne from "components/StatBoxOne";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalUtil from "components/ModalUtil";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { DataGrid,  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from "@mui/x-data-grid";
import {
  GET_MAINTANCE_PREVENT,
  GET_ACTIVE_VEHICLE,
  GET_MAIN_DATA,
} from "./QueryGuarante";
import ViewRequeriment from "./ViewRequeriment";
import ViewActive from "./ViewActive";
const Garantias = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [active, setActive] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [modalOpenView, setModalOpenView] = useState(false);
  const [modalOpenViewActive, setModalOpenViewActive] = useState(false);
  const [id, setId] = useState(null);
  const [maintancePrevent, setMaintancePrevent] = useState([]);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });
  const columnsActive = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "beginguarante",
      headerName: "INICIO DE GARANTIA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.beginguarante).toLocaleDateString("es-ES"),
    },
    {
      field: "endgurante",
      headerName: "FIN DE GARANTIA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.endgurante).toLocaleDateString("es-ES"),
    },
    {
      field: "guarante",
      headerName: "GARANTIA",
      flex: 1,
      renderCell: (params) => params.row.guarante,
    },
    {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => {
        const startDate = new Date();
        const endDate = new Date(params.row.endgurante);

        const yearDiff = endDate.getFullYear() - startDate.getFullYear();
        const monthDiff = endDate.getMonth() - startDate.getMonth();

        const totalMonths = yearDiff * 12 + monthDiff;
        if (totalMonths < 0) {
          return <Box>Garantia Vencida</Box>;
        }
        const porc = (totalMonths * 100) / params.row.guarante;

        return (
          <Box width={"100%"} height={"20px"} border={"1px solid #ccc"}>
            <Box width={porc} height={"100%"} backgroundColor={"#1aaf91"}>
              {porc.toFixed(0) + "%"}{" "}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewActive(params.row.id)}
          />
          {/*  <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const columnsMaintancePrevent = [
    {
      field: "N",
      headerName: "#",
      with: "5px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.date).toLocaleDateString("es-ES"),
    },
    {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    /* {
      field: "state",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.state.description,
    }, */
    {
      field: "comment",
      headerName: "COMENTARIO",
      flex: 1,
      renderCell: (params) => params.row.comment,
    },
    {
      field: "ope",
      headerName: "",
      align: "center",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"}>
          <VisibilityIcon onClick={() => handleOpenModalView(params.row.id)} />
          {/* <CreateIcon />
          <DeleteIcon /> */}
        </Box>
      ),
    },
  ];
  const handleOpenModalView = (id) => {
    setModalOpenView(true);
    setId(id);
  };

  const handleCloseModalView = () => {
    setModalOpenView(false);
  };

  const handleOpenModalViewActive = (id) => {
    setModalOpenViewActive(true);
    setId(id);
  };

  const handleCloseModalViewActive = () => {
    setModalOpenViewActive(false);
  };
  useEffect(() => {
    const FUNCTION_GET_ALL_ACTIVES = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        setActive(data.getActiveVehicle);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_ALL_MAINTANCE_PREVENT = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAINTANCE_PREVENT,
          fetchPolicy: "network-only",
        });
        setMaintancePrevent(data.getMaintanceRequerimentPrevent);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_MAIN_DATA = async () => {
      try {
        const { data } = await client.query({
          query: GET_MAIN_DATA,
          fetchPolicy: "network-only",
        });
        setMainData(data.getSumarizeGuarante);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_MAIN_DATA();
    FUNCTION_GET_ALL_ACTIVES();
    FUNCTION_GET_ALL_MAINTANCE_PREVENT();
  }, []);
  return (
    <>
      <ModalUtil
        isOpen={modalOpenView}
        onClose={handleCloseModalView}
        Component={ViewRequeriment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalView,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewActive}
        onClose={handleCloseModalViewActive}
        Component={ViewActive}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewActive,
          })
        }
        id={id}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header
            title="MANTENIMIENTO"
            subtitle="mantenimiento/garantia y PM"
          />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <StatBoxOne
            title="Total Mantenimiento"
            value={mainData?.preventCount}
            increase={"Cantidad de Mantenimiento Preventivo"}
            /*     description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Total  Requerimiento"
            value={mainData?.preventDescription}
            increase={"Ultima descripcion de Requerimiento Preventivo"}
            /*  description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Total de Activos"
            value={mainData?.guaranteNoguarante}
            increase={"Cantidad de Activos con garantia"}
            /*   description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBoxOne
            title="Ultimo Activo"
            value={mainData?.guaranteguarante}
            increase={"Descripcion del ultimo activo"}
            /*  description={mainData?.referent} */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          {/* ROW 1 */}

          {/* ROW 2 */}

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE MANTENIMIENTOS PREVENTIVOS
            </Box>
            <DataGrid
              loading={!maintancePrevent}
              getRowId={(row) => row.id}
              rows={maintancePrevent || []}
              columns={columnsMaintancePrevent}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              py="5px"
            >
              HISTORIAL DE GARANTIAS
            </Box>
            <DataGrid
              loading={!active}
              getRowId={(row) => row.id}
              rows={active || []}
              columns={columnsActive}
              components={{
                Toolbar: () => <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
              </GridToolbarContainer>,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Garantias;
