
const { useQuery } = require("@apollo/client")
const { GET_USER_DATA } = require("graphql/UserQueries")


export const useUser = () => {

  const { data, loading } = useQuery(GET_USER_DATA)

  return {
    user: data?.getUserByToken,
    loading
  }

}