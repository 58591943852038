import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TableFooter,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useFormik } from "formik";
import { DatePicker, TimePicker, jaJP } from "@mui/x-date-pickers";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RotatingLoopIcon from "scenes/utils/RotatingLoopIcon"
import { GET_PERNOCT_BY_ID } from "./QueryPernoct";
const ViewPernoct = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    personal,
    setnewAssignPernoct,
    handleCloseModal,
  } = params;
  const [visibility, setvisibility] = useState(false);
  const client = useApolloClient();
  const [pernoct, setpernoct] = useState([]);
  useEffect(() => {
    const getpernoct = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PERNOCT_BY_ID,
          variables: {
            getPernoctByIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getPernoctByID);
        setpernoct(data.getPernoctByID);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getpernoct(id);
  }, []);

  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility ? (
      <>
        <Box
          my="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">PROYECTO</label>
            <TextField disabled value={pernoct?.pernoct?.project} />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA INICIO DE RUTA</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.datebegingrute
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA INICIO PERNOCTE</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.datebeginpernoct
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA FIN PERNOCTE</label>
            <TextField
              disabled
              value={new Date(
                pernoct?.pernoct?.dateendpernoct
              ).toLocaleDateString("es-ES")}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">LUGAR</label>
            <TextField disabled value={pernoct?.pernoct?.place} />
          </Box>
        </Box>

        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">NOMBRES</TableCell>
                  <TableCell align="">HORA INICIO</TableCell>
                  <TableCell align="">HORA FIN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pernoct.personalTimes?.map((row, index) => {
                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">
                        {row?.personal.postulant.name +
                          "  " +
                          row?.personal.postulant.surname}
                      </TableCell>
                      <TableCell align="">{row?.datebegin}</TableCell>
                      <TableCell align="">{row?.dateend}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>       
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"15px"}
        >    
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><RotatingLoopIcon/></Box>
  );
};

export default ViewPernoct;
