import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import LoopIcon from '@mui/icons-material/Loop';  
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CLASS_SCHEMA_YUP } from "./YupValidation";
import { CREATE_CLASS_MUTATION } from "./QueryClass";
import { useApolloClient } from "@apollo/client";
import Alerts from "components/Alerts";
import { FormHelperText } from "@mui/material";

const CreateClass = ({ alertCustom, setAlertCustom, groups, setNewClass }) => {
  const client = useApolloClient();
  const formikGroup = useFormik({
    initialValues: {
      description: "",
      group: "",
    },
    validationSchema: CLASS_SCHEMA_YUP,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { description, group } = values;
      try {
        const response = await client.mutate({
          mutation: CREATE_CLASS_MUTATION,
          variables: {
            description: description.toUpperCase(),
            group: group,
          },
        });
        setNewClass(response.data.createClass);
        setAlertCustom({
          type: "success",
          message: "Registro ingresado",
          state: true,
        });
        resetForm();
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formikGroup.handleSubmit}>
      {alertCustom.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
      <Box>
        <Box fontSize={"12px"} fontWeight={"700"} textAlign={"center"}>
          CREAR CLASE
        </Box>
        <Box
          mt="10px"
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
          gap="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">GRUPO</InputLabel>
            <Select
              id="group"
              value={formikGroup.values.group}
              name="group"
              onChange={formikGroup.handleChange}
              error={
                formikGroup.touched.group && Boolean(formikGroup.errors.group)
              }
            >
              {groups?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                );
              })}
            </Select>
            {formikGroup.touched.group && formikGroup.errors.group ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {formikGroup.touched.group && formikGroup.errors.group}
              </FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            id="description"
            name="description"
            label="CLASE"
            variant="outlined"
            value={formikGroup.values.description}
            onChange={formikGroup.handleChange}
            error={
              formikGroup.touched.description &&
              Boolean(formikGroup.errors.description)
            }
            helperText={
              formikGroup.touched.description && formikGroup.errors.description
            }
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
          <Button variant="contained" color="success" type="submit" disabled={formikGroup.isSubmitting}>
          {formikGroup.isSubmitting ?<LoopIcon/>: "GUARDAR"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateClass;
