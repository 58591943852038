import React from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import { styled } from '@mui/system';

const RotatingIcon = styled(LoopIcon)`
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;


function RotatingLoopIcon() {
    return <RotatingIcon />;
  }
export default RotatingLoopIcon;
