import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import { useApolloClient } from "@apollo/client";
import Header from "components/Header";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import StatBox from "components/StatBox";
import { Email, PointOfSale } from "@mui/icons-material";
import Alerts from "components/Alerts";
import TextField from "@mui/material/TextField";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { FormHelperText } from "@mui/material";
import Chart from "react-apexcharts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Autocomplete from "@mui/material/Autocomplete";
import StatBoxOne from "components/StatBoxOne";
import CreateOdometer from "./CreateOdometer";
import CreateComment from "./CreateComment";
import CreateState from "./CreateState";
import { GET_MAIN_DATA } from "./QueryFlota";
import { GET_ALL_ACTIVE_VEHICLE } from "./../operation/QueryOperation";
import ViewOdometer from "./ViewOdometer";
import {
  GET_ALL_ODOMETER_BY_ACTIVE_ID,
  GET_ALL_COMMENT_BY_ACTIVE_ID,
  GET_ALL_STATE_BY_ACTIVE_ID,
  GET_FUEL_BY_ACTIVE,
  GET_EXPENSE_BY_ACTIVE,
  GET_FUEL_SERIES_BY_ACTIVE,
  GET_OTHER_EXPENSE_SERIES_BY_ACTIVE,
  GET_COMPONENT_FOR_ACTIVE_VEHICLE,
  GET_STATE_TYPE,
} from "./QueryFlota";
import DialogUtil from "components/DialogUtil";
import LineChart from "components/LineChart";
import ViewActive from "./ViewActive";
import ModalUtil from "components/ModalUtil";
import ViewFuel from "../operation/ViewFuel";
import ViewOther from "../operation/ViewOther";
import ViewState from "./ViewState";
import CreateAsignation from "./CreateAsignation";
import { GET_TYPE_ASIGNATION, GET_ALL_ASIGNATION,DELETE_ASSIGNATION_BY_ID,DELETE_ODOMETER_BY_ID } from "./QueryFlota";
import { GET_ALL_PERSONAL } from "./../../gestionpersonal/FichaPersonal/QueryPersonal";
import { GET_ALL_DRIVER } from "./../operation/QueryOperation";
import ViewAssignation from "./ViewAssignation";
import { useUser } from "hooks/useUser";
import DeleteForm from "./DeleteForm";
const Flota = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const client = useApolloClient();
  const [active, setActive] = useState([]);
  const [id, setId] = useState(null);
  const [odometer, setOdometer] = useState([]);
  const [comment, setComents] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [expense, setExpense] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [assignation, setAssignation] = useState([]);
  const { user } = useUser();
  const [state, setState] = useState([]);
  const [modalOpenOdometer, setModalOpenOdometer] = useState(false);
  const [modalOpenComment, setModalOpenComment] = useState(false);
  const [modalOpenState, setModalOpenState] = useState(false);
  const [seriesFuel, setSeriesFuel] = useState(null);
  const [minmaxseriesFuel, setMinMaxSeriesFuel] = useState(null);
  const [seriesOtherExpense, setSeriesOtherExpense] = useState(null);
  const [newOdometer, setNewOdometer] = useState(null);
  const [newAssignation, setnewAssignation] = useState(null);
  const [newComment, setNewComment] = useState(null);
  const [newState, setNewState] = useState(null);
  const [typeState, setTypeState] = useState(null);
  const [minmaxseriesOtherExpense, setMinMaxSeriesOtherExpense] = useState(
    null
  );
  const [modalOpenViewFuel, setModalOpenViewFuel] = useState(false);
  const [modalOpenViewOther, setModalOpenViewOther] = useState(false);

  const [modalViewState, setmodalViewState] = useState(null);
  const [modalViewAssignation, setmodalViewAssignation] = useState(null);

  const [activecomponent, setActiveComponent] = useState([]);

  const [modalOpenAssignation, setmodalOpenAssignation] = useState(false);

  const [driver, setDriver] = useState(null);
  const [typeAsignation, setTypeAsignation] = useState(null);

  const [idfuelother, setidfuelother] = useState(null);
  const [alertCustom, setAlertCustom] = useState({
    type: "success",
    message: "",
    state: false,
  });

  const [modalOpenDeleteOdometer, setModalOpenDeleteOdometer] = useState(false);
  const [modalOpenDeleteAsignation, setModalOpenDeleteAsignation] = useState(false);

  const handleOpenModalAssignation = () => {
    setId(id);
    setmodalOpenAssignation(true);
  };
  const handleCloseModalAssignation = () => {
    setmodalOpenAssignation(false);
  };

  const handleOpenModalOdometer = () => {
    setId(id);
    setModalOpenOdometer(true);
  };
  const handleCloseModalOdometer = () => {
    setModalOpenOdometer(false);
  };
  const handleOpenModalComment = () => {
    setId(id);
    setModalOpenComment(true);
  };
  const handleCloseModalComment = () => {
    setModalOpenComment(false);
  };
  const handleOpenModalState = () => {
    setId(id);
    setModalOpenState(true);
  };
  const handleCloseModalState = () => {
    setModalOpenState(false);
  };

  const handleCloseModalFuel = () => {
    setModalOpenViewFuel(false);
  };

  const handleOpenModalViewFuel = (id) => {
    setModalOpenViewFuel(true);
    setidfuelother(id);
  };
  const handleCloseModalOther = () => {
    setModalOpenViewOther(false);
  };

  const handleOpenModalViewOther = (id) => {
    setModalOpenViewOther(true);
    setidfuelother(id);
  };

  const handleCloseModalViewState = (id) => {
    setmodalViewState(false);
  };
  const handleOpenModalViewState = (id) => {
    setmodalViewState(true);
    setidfuelother(id);
  };

  const handleCloseModalViewAssignation = (id) => {
    setmodalViewAssignation(false);
  };
  const handleOpenModalViewAssignation = (id) => {
    setmodalViewAssignation(true);
    setidfuelother(id);
  };


  const handleOpenModalDeleteOdometer = (id) => {
    setidfuelother(id);
    setModalOpenDeleteOdometer(true);
  };
  const handleCloseModalDeleteOdometer = () => {
    setModalOpenDeleteOdometer(false);
  };

  const handleOpenModalDeleteAsignation = (id) => {
    setidfuelother(id);
    setModalOpenDeleteAsignation(true);
  };
  const handleCloseModalDeleteAsignation = () => {
    setModalOpenDeleteAsignation(false);
  };

  /* const [series, setSeries] = useState([
    {
      "name": "ACTIVE-1",
      "mode": "lines+markers",
      "x": [
        "2023-05-16",
        "2023-05-17",        
        "2023-05-18"
      ],
      "y": [
        2750,
        2500,
        37500
      ]
    }    
  ]); */
  /*  const [layout, setLayout] = useState(); */
  /* const [config, setConfig] = useState(); */
  const columnsAssignation = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "proyect",
      headerName: "PROYECTO",
      flex: 1,
      renderCell: (params) => params.row.proyect,
    },
    {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewAssignation(params.row.id)}
          />
           {user.admin ? (
            <>
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteAsignation(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsOdometer = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "number",
      headerName: "ODOMETRO",
      flex: 1,
      renderCell: (params) => params.row.number + " Km",
    },
    {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>     
           {user.admin ? (
            <>
              <DeleteIcon
                color="error"
                onClick={() => handleOpenModalDeleteOdometer(params.row.id)}
              />
            </>
          ) : null}
        </Box>
      ),
    },
  ];
  const columnsComment = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "description",
      headerName: "COMENTARIO",
      flex: 1,
      renderCell: (params) => params.row.description,
    },
    /*  {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon />
        </Box>
      ),
    }, */
  ];
  const columnsState = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    /*  {
      field: "description",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.description,
    }, */
    {
      field: "type.description",
      headerName: "ESTADO",
      flex: 1,
      renderCell: (params) => params.row.type.description,
    },
    /* {
      field: "description",
      headerName: "DESCRIPCION",
      flex: 1,
      renderCell: (params) => params.row.description,
    }, */
    {
      field: "ope",
      headerName: "",
      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewState(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  const columnsFuel = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "date",
      headerName: "FECHA",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.date).toLocaleDateString("es-ES"),
    },
    {
      field: "cost",
      headerName: "COSTO",
      flex: 1,
      renderCell: (params) => "S/." + params.row.cost,
    },
    {
      field: "amount",
      headerName: "CANTIDAD",
      flex: 1,
      renderCell: (params) => params.row.amount,
    },
    {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewFuel(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  const columnsExpense = [
    {
      field: "N",
      headerName: "#",
      with: "10px",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "amount",
      headerName: "CANTIDAD",
      flex: 1,
      renderCell: (params) => params.row.amount,
    },
    {
      field: "cost",
      headerName: "COSTO",
      flex: 1,
      renderCell: (params) => "S/. " + params.row.cost,
    },
    {
      field: "name",
      headerName: "NOMBRE",
      flex: 1,
      renderCell: (params) => params.row.name,
    },
    {
      field: "ope",
      headerName: "",

      renderCell: (params) => (
        <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
          <VisibilityIcon
            onClick={() => handleOpenModalViewOther(params.row.id)}
          />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    const FUNCTION_GET_ALL_ACTIVE_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ACTIVE_VEHICLE,
          fetchPolicy: "network-only",
        });
        console.log(data.getAllActiveVehicle)
        setActive(data.getAllActiveVehicle);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    const FUNCTION_GET_TYPE_STATE = async () => {
      try {
        const { data } = await client.query({
          query: GET_STATE_TYPE,
          fetchPolicy: "network-only",
        });
        setTypeState(data.getTypeActive);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
      const FUNCTION_DRIVER = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_DRIVER,
          fetchPolicy: "network-only",
        });
        setDriver(data.getAllDriver);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
  /*   const FUNCTION_GET_ALL_PERSONAL = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_PERSONAL,
        });
        setDriver(data.getAllPersonal);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    }; */
    const FUNCTION_TYPE_ASIGNATION = async () => {
      try {
        const { data } = await client.query({
          query: GET_TYPE_ASIGNATION,
          fetchPolicy: "network-only",
        });
        console.log(data);
        setTypeAsignation(data.getAssignationType);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    FUNCTION_DRIVER();
    FUNCTION_TYPE_ASIGNATION();

    FUNCTION_GET_ALL_ACTIVE_VEHICLE();
    FUNCTION_GET_TYPE_STATE();
    FUNCTION_GET_ODOMETER_BY_ID(id);
    FUNCTION_GET_COMENTS_BY_ID(id);
    FUNCTION_GET_ASSIGNATION_BY_ID(id);
    FUNCTION_GET_STATE_BY_ID(id);
    FUNCTION_MAIN_DATA(id);
  }, [newOdometer, newComment, newState, newAssignation]);
  const FUNCTION_MAIN_DATA = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_MAIN_DATA,
        fetchPolicy: "network-only",
        variables: {
          getSumarizeFlotaId: id,
        },
      });
      console.log(data.getSumarizeFlota);
      setMainData(data.getSumarizeFlota);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_ODOMETER_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_ODOMETER_BY_ACTIVE_ID,
        variables: {
          getAllOdometerByIdActiveId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setOdometer(data.getAllOdometerByIdActive);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };

  const FUNCTION_GET_ASSIGNATION_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_ASIGNATION,
        variables: {
          getAssignationId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setAssignation(data.getAssignation);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_COMENTS_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_COMMENT_BY_ACTIVE_ID,
        variables: {
          getAllCommentByIdActiveId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setComents(data.getAllCommentByIdActive);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_STATE_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_STATE_BY_ACTIVE_ID,
        variables: {
          getAllStateByIdActiveId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setState(data.getAllStateByIdActive);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };

  const FUNCTION_GET_FUEL_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_FUEL_BY_ACTIVE,
        variables: {
          getFuelByIdActiveId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setFuel(data.getFuelByIdActive);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_EXPENSE_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_EXPENSE_BY_ACTIVE,
        variables: {
          getExpenseByIdActiveId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setExpense(data.getExpenseByIdActive);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_SERIES_FUEL_ACTIVE_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_FUEL_SERIES_BY_ACTIVE,
        variables: {
          getFuelSeriesByActiveIdId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setSeriesFuel(data.getFuelSeriesByActiveId?.data);
      setMinMaxSeriesFuel(data.getFuelSeriesByActiveId?.minmax);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const FUNCTION_GET_SERIES_OTHER_EXPENSE_ACTIVE_BY_ID = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_OTHER_EXPENSE_SERIES_BY_ACTIVE,
        variables: {
          getOtherExpensiveSeriesByActiveIdId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setSeriesOtherExpense(data.getOtherExpensiveSeriesByActiveId?.data);
      setMinMaxSeriesOtherExpense(
        data.getOtherExpensiveSeriesByActiveId?.minmax
      );
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };

  const FUNCTION_GET_COMPONENT_FOR_ACTIVE_VEHICLE = async (id) => {
    try {
      const { data } = await client.query({
        query: GET_COMPONENT_FOR_ACTIVE_VEHICLE,
        variables: {
          getComponentsForActiveVehicleId: id,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setActiveComponent(data.getComponentsForActiveVehicle);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (
    <>
      {alertCustom?.state && (
        <Alerts alert={alertCustom} setAlertCustom={setAlertCustom}></Alerts>
      )}
       <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={modalOpenDeleteOdometer}
        onClose={handleCloseModalDeleteOdometer}
        Component={DeleteForm}
        id={idfuelother}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setNewOdometer,
            paramsDelete: "deleteOdometerByIdId",
            mutatitionDelete: DELETE_ODOMETER_BY_ID,
            onClose: handleCloseModalDeleteOdometer,
          })
        }
      />
       <DialogUtil
        title={"ELIMINAR GRUPO"}
        description={"ESTAS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO"}
        isOpen={modalOpenDeleteAsignation}
        onClose={handleCloseModalDeleteAsignation}
        Component={DeleteForm}
        id={idfuelother}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewRegister: setnewAssignation,
            paramsDelete: "deleteAssignationByIdId",
            mutatitionDelete: DELETE_ASSIGNATION_BY_ID,
            onClose: handleCloseModalDeleteAsignation,
          })
        }
      />
      <ModalUtil
        isOpen={modalViewAssignation}
        onClose={handleCloseModalViewAssignation}
        Component={ViewAssignation}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setnewAssignation: null,
            handleCloseModalViewAssignation: handleCloseModalViewAssignation,
          })
        }
        id={idfuelother}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenAssignation}
        onClose={handleCloseModalAssignation}
        Component={CreateAsignation}
        sizewidth={900}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setnewAssignation: setnewAssignation,
            handleCloseModalAssignation: handleCloseModalAssignation,
            type: typeAsignation,
            driver: driver,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenOdometer}
        onClose={handleCloseModalOdometer}
        Component={CreateOdometer}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewOdometer: setNewOdometer,
            handleCloseModalOdometer: handleCloseModalOdometer,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenComment}
        onClose={handleCloseModalComment}
        Component={CreateComment}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewComment: setNewComment,
            handleCloseModalComment: handleCloseModalComment,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenState}
        onClose={handleCloseModalState}
        Component={CreateState}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewState: setNewState,
            handleCloseModalState: handleCloseModalState,
            typeState: typeState,
          })
        }
        id={id}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewFuel}
        onClose={handleCloseModalFuel}
        Component={ViewFuel}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalFuel,
          })
        }
        id={idfuelother}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalOpenViewOther}
        onClose={handleCloseModalOther}
        Component={ViewOther}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalOther,
          })
        }
        id={idfuelother}
      ></ModalUtil>
      <ModalUtil
        isOpen={modalViewState}
        onClose={handleCloseModalViewState}
        Component={ViewState}
        params={
          new Object({
            setAlertCustom: setAlertCustom,
            alertCustom: alertCustom,
            setNewAssignRequeriment: null,
            handleCloseModal: handleCloseModalViewState,
          })
        }
        id={idfuelother}
      ></ModalUtil>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title="OPERACIÓN" subtitle="operación/flota" />
        </FlexBetween>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 4"
            gridRow="span 1"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {active.length ? (
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={active?.map(
                  (option) =>
                    new Object({
                      label:
                        option.description + " / " + "placa:" + option.plate,
                      value: option.id,
                    })
                )}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  setId(newValue.value);
                  FUNCTION_GET_ODOMETER_BY_ID(newValue.value);
                  FUNCTION_GET_COMENTS_BY_ID(newValue.value);
                  FUNCTION_GET_ASSIGNATION_BY_ID(newValue.value);
                  FUNCTION_GET_STATE_BY_ID(newValue.value);
                  FUNCTION_GET_FUEL_BY_ID(newValue.value);
                  FUNCTION_GET_EXPENSE_BY_ID(newValue.value);
                  FUNCTION_GET_SERIES_FUEL_ACTIVE_BY_ID(newValue.value);
                  FUNCTION_GET_SERIES_OTHER_EXPENSE_ACTIVE_BY_ID(
                    newValue.value
                  );
                  FUNCTION_GET_COMPONENT_FOR_ACTIVE_VEHICLE(newValue.value);
                  FUNCTION_MAIN_DATA(newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar por descripcion/placa"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            ) : (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                <p
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  No hay Datos para mostrar
                </p>
              </FormHelperText>
            )}
          </Box>
          <StatBox
            title="Ultimo Odometro"
            value={mainData?.lastOdometer + " Km"}
            /*   increase="+14%" */
            /*   description="Aun estan en proceso de entrevista" */
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultimo Comentario"
            value={mainData?.lastComment?.slice(0, 7) + "..."}
            /*  increase="+21%" */
            /*  description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultimo Estado"
            value={mainData?.lastState}
            /*  increase="+21%" */
            /*    description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Ultima Asignación"
            value={mainData?.lastAssign?.slice(0, 7) + "..."}
            /*  increase="+21%" */
            /*    description="Ya tienen registrados su ficha personal" */
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <Box
            gridColumn="span 12"
            gridRow="span 5"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {id ? (
              <ViewActive activecomponent={activecomponent} />
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                height={"100%"}
                alignItems={"center"}
              >
                Selecciona un activo
              </Box>
            )}
          </Box>
          {/* ROW 2 */}
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={"#FFF"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              py="5px"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                flex={"1"}
              >
                HISTORIAL DE ODOMETRO
              </Box>
              {id && (
                <Box borderRadius={"50%"} mt={"5px"} mr={"5px"}>
                  <AddCircleIcon onClick={() => handleOpenModalOdometer(id)} />
                </Box>
              )}
            </Box>
            <DataGrid
              loading={!odometer}
              getRowId={(row) => row.id}
              rows={odometer || []}
              columns={columnsOdometer}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={"#FFF"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              py="5px"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                flex={"1"}
              >
                HISTORIAL DE COMENTARIO
              </Box>
              {id && (
                <Box borderRadius={"50%"} mt={"5px"} mr={"5px"}>
                  <AddCircleIcon onClick={() => handleOpenModalComment(id)} />
                </Box>
              )}
            </Box>

            <DataGrid
              loading={!comment}
              getRowId={(row) => row.id}
              rows={comment || []}
              columns={columnsComment}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={"#FFF"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              py="5px"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                flex={"1"}
              >
                HISTORIAL DE ESTADOS
              </Box>
              {id && (
                <Box borderRadius={"50%"} mt={"5px"} mr={"5px"}>
                  <AddCircleIcon onClick={() => handleOpenModalState(id)} />
                </Box>
              )}
            </Box>

            <DataGrid
              loading={!state}
              getRowId={(row) => row.id}
              rows={state || []}
              columns={columnsState}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            py="5px"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              py="5px"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                fontSize={"12px"}
                fontWeight={"700"}
                textAlign={"center"}
                flex={"1"}
              >
                HISTORIAL DE ASIGNACIÓN
              </Box>
              {id && (
                <Box borderRadius={"50%"} mt={"5px"} mr={"5px"}>
                  <AddCircleIcon
                    onClick={() => handleOpenModalAssignation(id)}
                  />
                </Box>
              )}
            </Box>
            <DataGrid
              loading={!assignation}
              getRowId={(row) => row.id}
              rows={assignation || []}
              columns={columnsAssignation}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            mt={"40px"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              p="1rem"
            >
              HISTORIAL DE COMBUSTIBLE
            </Box>
            <DataGrid
              loading={!fuel}
              getRowId={(row) => row.id}
              rows={fuel || []}
              columns={columnsFuel}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            mt={"40px"}
            backgroundColor={theme.palette.background.alt}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
              p="1rem"
            >
              HISTORIAL DE OTROS GASTOS
            </Box>
            <DataGrid
              loading={!expense}
              getRowId={(row) => row.id}
              rows={expense || []}
              columns={columnsExpense}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            mt={"50px"}
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
            >
              GRÁFICA DE COMBUSTIBLE
            </Box>
            {id && seriesFuel ? (
              <LineChart
                data={seriesFuel}
                title={"Combustible"}
                range={minmaxseriesFuel}
                isSingle={true}
              />
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                height={"100%"}
                alignItems={"center"}
              >
                Seleccione un activo
              </Box>
            )}
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            mt={"50px"}
            borderRadius="0.55rem"
          >
            <Box
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              flex={"1"}
            >
              GRÁFICA DE OTROS GASTOS
            </Box>
            {id && seriesOtherExpense ? (
              <LineChart
                data={seriesOtherExpense}
                title={"Combustible"}
                range={minmaxseriesOtherExpense}
                isSingle={true}
              />
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                height={"100%"}
                alignItems={"center"}
              >
                Seleccione un activo
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Flota;
