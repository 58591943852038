import { gql } from "@apollo/client";

export const CREATE_POSTULANT_MUTATION = gql`
  mutation CreatePostulant(
    $name: String!
    $surname: String!
    $doi: String!
    $dob: String!
    $email: String!
    $phone: String
  ) {
    createPostulant(
      name: $name
      surname: $surname
      doi: $doi
      dob: $dob
      email: $email
      phone: $phone
    ) {
      name
      surname
    }
  }
`;
export const GET_ALL_POSTULANT = gql`
  query GetAllPostulant {
    getAllPostulant {
      id
      name
      surname
      email
      doi
      state {
        description
      }
    }
  }
`;

export const GET_ALL_POSTULANT_FOR_PERSONAL = gql`
query GetAllPostulantforPersonal {
  getAllPostulantforPersonal {
    id
    name
    surname
    doi
  }
}`;

export const GET_POSTULANT_DISTINT_PERSONAL = gql`
  query GetPersonalPostulantWithDistintion {
    getPersonalPostulantWithDistintion {
      doi
      name
      surname
      phone
      dob
      email
      id
      isPersonal
    }
  }
`;
export const GET_ALL_POSTULANT_AND_PERSONAL = gql`
  query GetAllPostulantandPersonal {
    getAllPostulantandPersonal {
      id
      name
      surname
      email
      doi
      state {
        description
      }
    }
  }
`;
export const GET_POSTULANT_BY_ID = gql`
  query GetPostulantById($getPostulantByIdId: ID) {
    getPostulantById(id: $getPostulantByIdId) {
      name
      surname
      email
      phone
      doi
      dob
      state {
        description
      }
    }
  }
`;

export const GET_MAIN_DATA = gql`
  query GetSumarizePostulant {
    getSumarizePostulant {
      lastDate
      lastPostulant
      postulant
    }
  }
`;

export const DELETE_POSTULANT_BY_ID = gql`
mutation DeletePostulantById($deletePostulantByIdId: ID) {
  deletePostulantById(deletePostulantByIdId: $deletePostulantByIdId) {
    id
  }
}
`;

export const EDIT_POSTULNAT_BY_ID = gql`
mutation EditPostulnatById($phone: String, $email: String, $dob: Date, $doi: String, $surname: String, $name: String, $editPostulnatByIdId: ID) {
  editPostulnatById(phone: $phone, email: $email, dob: $dob, doi: $doi, surname: $surname, name: $name, editPostulnatByIdId: $editPostulnatByIdId) {
    id
  }
}
`;
