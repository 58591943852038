import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import { useApolloClient } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GET_PROFESSION_BY_PERSONAL_ID } from "./QueryPersonal";
const ViewProfession = ({ id, params }) => {
  const { setAlertCustom, alertCustom, handleCloseModal } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [professionFicha, setprofessionFicha] = useState(null);
  useEffect(() => {
    const getprofessionFicha = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_PROFESSION_BY_PERSONAL_ID,
          variables: {
            getProfessionByPersonalIdId: id,
          },
          fetchPolicy: "network-only",
        });
        console.log(data.getProfessionByPersonalId);
        setprofessionFicha(data.getProfessionByPersonalId);
        setvisibility(true);
      } catch (error) {
        setAlertCustom({
          ...alertCustom,
          type: "error",
          message: error.message,
          state: true,
        });
      }
    };
    getprofessionFicha(id);
  }, []);
  return (
    visibility ? (
      <>
        {" "}
        <Box my={"20px"}>
          <TableContainer style={{ maxHeight: 550, overflowY: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="">N°</TableCell>
                  <TableCell align="">CARRERA</TableCell>
                  <TableCell align="">CENTRO DE ESTUDIOS</TableCell>
                  <TableCell align="">NIVEL</TableCell>
                  <TableCell align="">ESTADO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {professionFicha.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="">{index + 1}</TableCell>
                      <TableCell align="">{row.description}</TableCell>
                      <TableCell align="">{row.place}</TableCell>
                      <TableCell align="">{row.level.description}</TableCell>
                      <TableCell align="">{row.state?.description}</TableCell>
                    </TableRow>
                    /* </RadioGroup> */
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display={"flex"} justifyContent={"end"} marginTop={"30px"}>
          <Button variant="contained" onClick={handleCloseModal}>
            CERRAR
          </Button>
          {/*  */}
        </Box>
      </>
    ):<Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewProfession;
