import * as Yup from "yup";
export const VAN_SCHEMA_YUP = Yup.object().shape({
  driver:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  date: Yup.date().required("Requiredo"),
  time: Yup.string().required("Requiredo"),
  plate: Yup.string().required("Requiredo"),
  kmBegin: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  kmEnd: Yup.number()
    .positive("El número debe ser positivo")
    .required("Requiredo"),
  activity: Yup.string().required("Requiredo"),
  declarations: Yup.array().of(
    Yup.object().shape({
      answer: Yup.string().required("Requiredo"),
      question: Yup.string(),
      observation: Yup.string(),
    })
  ).required("Requiredo").min(10),
  components: Yup.array().of(
    Yup.object().shape({
      answer: Yup.string().required("Requiredo"),
      question: Yup.string(),
      observation: Yup.string(),
    })
  ).required("Requiredo").min(44),
});
