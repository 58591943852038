import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useApolloClient } from "@apollo/client";
import {
  Box,
} from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';
import Autocomplete from "@mui/material/Autocomplete";
import { GET_ACTIVE_NO_VEHICLE_BY_ITEM_ID } from "./QueryItem";
import { GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE } from "./../../operation/flota/QueryFlota";
import { Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import ViewComponent from "scenes/operation/flota/ViewComponent";
const ViewActiveNoVehicle = ({ id, params }) => {

  const { setAlertCustom, alertCustom, handleCloseModalView } = params;
  const client = useApolloClient();
  const [visibility, setvisibility] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const theme = useTheme();
  const [activecomponent, setActiveComponent] = useState([]);
  useEffect(() => {
    const FUNCTION_GET_ACTIVE_NO_VEHICLE = async () => {
      try {
        const { data } = await client.query({
          query: GET_ACTIVE_NO_VEHICLE_BY_ITEM_ID,
          fetchPolicy: "network-only",
          variables: {
            getActiveNoVehicleByItemIdId: id,
          },
        });
        setActiveVehicle(data.getActiveNoVehicleByItemId);
        console.log(data.getActiveNoVehicleByItemId);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    FUNCTION_GET_ACTIVE_NO_VEHICLE();
  }, []);
  const FUNCTION_GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE = async (activeId) => {
    try {
      const { data } = await client.query({
        query: GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE,
        variables: {
          getOneActiveNoVehicleByItemIdId: activeId,
        },
        fetchPolicy: "network-only",
      });
      console.log(data);
      setActiveComponent(data.getOneActiveNoVehicleByItemId);
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  return (visibility? (<>
    <Box>
      {" "}
      <Box mb={"20px"}>
        {activeVehicle?.length ? (
          <Autocomplete
            freeSolo
            id="vehicle"
            name="vehicle"
           
            p="1rem"
            disableClearable
            options={activeVehicle?.map(
              (option) =>
                new Object({
                  label:
                    "active:" +
                    option.description +
                    " / " +
                    "placa:" +
                    option.plate,
                  value: option.id,
                })
            )}
            onChange={(event, newValue) => {
              console.log(newValue.value);
              setActiveId(newValue.value);
              FUNCTION_GET_COMPONENT_FOR_ACTIVE_NO_VEHICLE(newValue.value);
              //formikAssembly.setFieldValue("vehicle", newValue.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar por descripcion/placa"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        ) : (
          <p>No existe Activos para el Item </p>
        )}
      </Box>
      <Box>
        {activeId && activeVehicle.length ? (
          <ViewComponent activecomponent={activecomponent} />
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            height={"100%"}
            alignItems={"center"}
          >
            Selecciona
          </Box>
        )}
      </Box>
    </Box>
    <Box mt={"25px"} display={"flex"} justifyContent={"end"} gap={"15px"}>
                
          <Button variant="contained" onClick={handleCloseModalView}>
          CERRAR
        </Button>
        </Box></>):   <Box sx={{display:"flex",justifyContent:"center"}}><LoopIcon/></Box>
  );
};

export default ViewActiveNoVehicle;
