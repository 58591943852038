import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button, 
} from "@mui/material";
import TextField from "@mui/material/TextField";
import LoopIcon from '@mui/icons-material/Loop';
import {
  GET_ALL_ADMIN_MAINTANCE_BY_ID,
  UPDATE_ADMIN_MAINTANCE_BY_ID,
} from "./QueryAdminMaintance";
import { useApolloClient } from "@apollo/client";
const AssignMaintanceRequeriment = ({ id, params }) => {
  const {
    setAlertCustom,
    alertCustom,
    setNewAssignRequeriment,
    handleCloseModal,
  } = params;
  const client = useApolloClient();
  const [saved, setsaved] = useState(true);
  const [visibility, setvisibility] = useState(false);
  const [maintanceRequeriment, setMaintanceRequeriment] = useState([]);
  useEffect(() => {
    const getMaintanceRequeriment = async (id) => {
      try {
        const { data } = await client.query({
          query: GET_ALL_ADMIN_MAINTANCE_BY_ID,
          variables: {
            getMaintanceRequerimentByIdId: id,
          },
        });
        console.log(data.getMaintanceRequerimentById);
        setMaintanceRequeriment(data.getMaintanceRequerimentById);
        setvisibility(true);
      } catch (error) {
        console.log(error.message);
      }
    };
    getMaintanceRequeriment(id);
  }, []);
  const handlePersonalRequerimentReject = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_MAINTANCE_BY_ID,
        variables: {
          updateRequerimentMaintanceId: id,
          type: 0,
        },
      });
      console.log(response.data.updateRequerimentMaintance);
      setNewAssignRequeriment(response.data.updateRequerimentMaintance);

      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const handlePersonalRequerimentApproved = async () => {
    try {
      setsaved(false)
      const response = await client.mutate({
        mutation: UPDATE_ADMIN_MAINTANCE_BY_ID,
        variables: {
          updateRequerimentMaintanceId: id,
          type: 1,
        },
      });
      console.log(response.data.updateRequerimentMaintance);
      setNewAssignRequeriment(response.data.updateRequerimentMaintance);

      setAlertCustom({
        type: "success",
        message: "Registro ingresado",
        state: true,
      });
      setsaved(true)
      handleCloseModal();
    } catch (error) {
      setAlertCustom({
        ...alertCustom,
        type: "error",
        message: error.message,
        state: true,
      });
    }
  };
  const style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  };
  return (
    visibility && (
      <>
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap="20px"
        >
          <Box sx={style}>
            <label for="my-input">ACTIVO</label>
            <TextField
              disabled
              value={maintanceRequeriment?.active.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">PLACA</label>
            <TextField disabled value={maintanceRequeriment?.active.plate} />
          </Box>
          <Box sx={style}>
            <label for="my-input">PRIORIDAD</label>
            <TextField
              disabled
              value={maintanceRequeriment?.priority.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">TIPO</label>
            <TextField
              disabled
              value={maintanceRequeriment?.type.description}
            />
          </Box>
          <Box sx={style}>
            <label for="my-input">FECHA</label>
            <TextField
              disabled
              value={new Date(maintanceRequeriment.date).toLocaleDateString(
                "es-ES"
              )}
            />{" "}
          </Box>
          <Box sx={style}>
            <label for="my-input">DESCRIPCION</label>
            <TextField disabled value={maintanceRequeriment?.description} />
          </Box>
          <Box sx={style}>
            <label for="my-input">COMENTARIO</label>
            <TextField disabled value={maintanceRequeriment?.comment} />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"end"}
          marginTop={"30px"}
          gap={"20px"}
        >
          <Button
            variant="contained"
            color="error"
             disabled={!saved}
            onClick={handlePersonalRequerimentReject}
          >
             {!saved ?<LoopIcon/>: "DESAPROBAR"}
          </Button>
          <Button
            variant="contained"
            color="success"
             disabled={!saved}
            onClick={handlePersonalRequerimentApproved}
          >
             {!saved ?<LoopIcon/>: "APROBAR"}
          </Button>
          {/*  */}
        </Box>
      </>
    )
  );
};

export default AssignMaintanceRequeriment;
