import * as Yup from "yup";
export const PERNOCT_SCHEMA_YUP = Yup.object().shape({
  supervisor:  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string().required("Requiredo"),
  }),
  project: Yup.string().required("Requiredo"),
  place: Yup.string(),
  datebegingrute: Yup.date().required("Requirido"),
  datebeginpernoct: Yup.date().required("Requirido"),
  dateendpernoct: Yup.date().required("Requirido"),
});

export const ASSIGN_SCHEMA_YUP = Yup.object().shape({
  pernoct: Yup.string().required("Requiredo"),
  item: Yup.array()
    .of(
      Yup.object().shape({
        begin: Yup.string().required("Requiredo"),
        end: Yup.string().required("Requiredo"),
        personal: Yup.string().required("Requiredo"),
      })
    ).max(3),  
});
