import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_MAINTANCE_BY_ID = gql`
  query GetMaintanceRequerimentById($getMaintanceRequerimentByIdId: ID) {
    getMaintanceRequerimentById(id: $getMaintanceRequerimentByIdId) {
      active {
        plate
        description
      }
      comment
      date
      description
      priority {
        description
      }
      type {
        description
      }
    }
  }
`;

export const UPDATE_ADMIN_MAINTANCE_BY_ID = gql`
  mutation UpdateRequerimentMaintance(
    $type: Int
    $updateRequerimentMaintanceId: ID
  ) {
    updateRequerimentMaintance(type: $type, id: $updateRequerimentMaintanceId) {
      id
      description
      date
      comment
    }
  }
`;
